/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import Yup from 'yup';

import { Consumer as CommunityContextConsumer } from '../../../../contexts/CommunityContext';
import { Consumer as PageConsumer } from '../../../../contexts/PageContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ConfirmationPopup from '../../../ConfirmationPopup/ConfirmationPopup';
import FlexPopup from '../../../Elements/FlexPopup/FlexPopup';
import SVGFromCloudinary from '../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';

import DiscussionForm from '../DiscussionForm/DiscussionForm';
import LinkForm from '../LinkForm/LinkForm';
import PhotoForm from '../PhotoForm/PhotoForm';
import Tabs from '../Tabs/Tabs';

import './CreateContentPopup.scss';

export const CONTENT_TYPES = {
    SAY_SOMETHING_FORM: 'SAY_SOMETHING_FORM',
    SHARE_PHOTO_FORM: 'SHARE_PHOTO_FORM',
    SHARE_LINK_FORM: 'SHARE_LINK_FORM',
};

const tabs = Object.keys(CONTENT_TYPES);

/**
 * @deprecated
 */
export class CreateContentPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            communityContentKey: '',
            contentType: props.contentType || CONTENT_TYPES.SAY_SOMETHING_FORM,
            defaultTopicId: null,
            defaultPostLocation: props.defaultPostLocation,
            isPostSuccessful: false,
            selectedTopicRedirectUrl: '',
            topics: [],
        };
    }

    static propTypes = {
        /**
         * object of topic page user is currently on
         */
        defaultPostLocation: PropTypes.object,
        /**
         * a default anonymous boolean to set the state of the checkbox
         */
        defaultIsAnonymous: PropTypes.bool,
        /**
         * used for firing metrics
         */
        location: PropTypes.string,
        /**
         * boolean to determine whether modal should be opened
         */
        modal: PropTypes.bool.isRequired,
        /**
         * function to open/close modal
         */
        toggle: PropTypes.func.isRequired,
        /**
         * array of topics for the select dropdown
         */
        topics: PropTypes.array.isRequired,
        contentType: PropTypes.string,
        editMode: PropTypes.bool,
        onEditSubmit: PropTypes.func,
    };

    static defaultProps = {
        /**
         * topic set to null
         */
        defaultPostLocation: null,
        /**
         * defaultIsAnonymous set to false
         */
        defaultIsAnonymous: false,
        /**
         * default location is "topicList"
         */
        location: 'topicList',
        editMode: false,
    };

    getCommunityTopicUrlById = (topicId) => {
        const topic = topicId && topicId.id ? topicId.id : topicId;
        const numeralizedId = parseInt(topic);
        const filteredTopic = this.props.topics.filter(
            ({ id }) => id === numeralizedId
        );

        if (!filteredTopic.length)
            return console.error('No topic found for given id:', topic);
        if (!filteredTopic[0].url)
            return console.error('Url missing from given topic', topic);

        return filteredTopic[0].url;
    };

    handleSuccess = (selectedTopicId, communityContentKey, destinationType) => {
        /*
            TODO: Replace this hacky fix for redirect issue.
            Upon post success, this function should receive the post destination type (topic, group)
            rather than manually checking typeof
        */

        const postLocation = {
            id: selectedTopicId,
            type: typeof selectedTopicId === 'number' ? 'Topic' : 'Group',
        };

        const selectedTopicRedirectUrl =
            postLocation.type === 'Topic'
                ? this.getCommunityTopicUrlById(selectedTopicId)
                : '';

        this.setState(
            {
                defaultPostLocation: postLocation,
                isPostSuccessful: true,
                selectedTopicRedirectUrl: selectedTopicRedirectUrl,
                communityContentKey,
            },
            this.handleMetricSuccess
        );
    };

    handleMetricSuccess = () => {
        const isDefaultToTopic =
            this.state.defaultPostLocation &&
            this.state.defaultPostLocation.type === 'Topic';

        if (!this.state.defaultPostLocation || isDefaultToTopic) {
            EmitMetric({
                content_created: 1,
                community_content_key: this.state.communityContentKey,
                community_content_location: this.props.location,
            });
        } else {
            EmitMetric({
                content_created: 1,
                community_content_key: this.state.communityContentKey,
                community_group_hash: this.state.defaultPostLocation.id,
            });
        }
    };

    handleClose = () => {
        const isDefaultToTopic = !(
            this.props.defaultPostLocation &&
            this.props.defaultPostLocation.type &&
            this.props.defaultPostLocation.type.toLowerCase().includes('group')
        );

        if (!this.state.isPostSuccessful && isDefaultToTopic) {
            EmitMetric({
                misc_event_type: 'community-create-content-closed',
                misc_event_count: 1,
            });
        } else if (!this.state.isPostSuccessful && !isDefaultToTopic) {
            EmitMetric({
                misc_event_type: 'group-create-content-closed',
                misc_event_count: 1,
                community_group_hash:
                    this.props.defaultPostLocation.id ||
                    this.props.defaultPostLocation.hash,
            });
        }

        this.props.toggle();
    };

    handleOpen = () => {
        const isDefaultToTopic = !(
            this.props.defaultPostLocation &&
            this.props.defaultPostLocation.type &&
            this.props.defaultPostLocation.type.toLowerCase().includes('group')
        );

        if (!this.props.defaultPostLocation || isDefaultToTopic) {
            EmitMetric({
                misc_event_type: 'community-create-content-opened',
                misc_event_count: 1,
            });
        } else {
            EmitMetric({
                misc_event_type: 'group-create-content-opened',
                misc_event_count: 1,
                community_group_hash:
                    this.props.defaultPostLocation.id ||
                    this.props.defaultPostLocation.hash,
            });
        }
    };

    handleFormRedirect = () => {
        const { defaultPostLocation, selectedTopicRedirectUrl } = this.state;

        const { type, id } = defaultPostLocation || {};

        const isPostingToGroup = type === 'Group' || type === 'community-group';

        if (isPostingToGroup) {
            window.location = `/groups/${id}`;
        } else {
            window.location = `/community/${selectedTopicRedirectUrl}`;
        }
    };

    handleTabChange = (contentType) => () => this.setState({ contentType });

    clearError = (key) => {
        return (previousState) => ({
            errors: {
                ...previousState.errors,
                [key]: '',
            },
        });
    };

    validateForm = (fieldsToValidate, formSchema) =>
        Yup.object()
            .shape(formSchema)
            .validate(fieldsToValidate, { abortEarly: false });

    renderErrors = (errors) => {
        const errorState = {};
        if (errors.inner) {
            errors.inner.forEach((validationError) => {
                const { errors, path } = validationError;

                const [errorMessage] = errors;
                errorState[path] = errorMessage;
            });
        }

        return (previousState) => ({
            isCreating: false,
            errors: {
                ...previousState.errors,
                ...errorState,
            },
        });
    };

    getPostIdKeyValue = (postLocation) => {
        const { type, id } = postLocation || {};

        const isPostingToGroup = type === 'Group' || type === 'community-group';

        // will either send back topicId or groupHash depending on type
        const postLocationKey = isPostingToGroup
            ? 'communityGroupHash'
            : 'communityTopicId';
        const postLocationValue = id && isPostingToGroup ? id : parseInt(id);

        return { postLocationKey, postLocationValue };
    };

    getCheckBoxLabel = (isEditMode, isAnon) => {
        if (isEditMode) {
            return 'Make anonymous';
        }

        return isAnon ? 'Sharing anonymously.' : 'Select to share anonymously.';
    };

    showCreateContentPopUp = () => {
        return (
            <FlexPopup
                hidden={!this.props.modal}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                closeOnOutsideClick={false}
            >
                <div className="modal-body-content">
                    <span className="modal-heading">Share Your Thoughts</span>
                    <SVGIconWrapper
                        handleClickSVGWrapper={this.handleClose}
                        SVGWrapperCssClass="svg-close-icon"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#C4C8CC"
                                fillRule="nonzero"
                                d="M21.597 2.403A1.375 1.375 0 0 0 19.653.458L11 9.111 2.347.458A1.375 1.375 0 1 0 .403 2.403l8.652 8.653-8.652 8.652a1.375 1.375 0 0 0 1.944 1.945L11 13l8.653 8.653a1.375 1.375 0 1 0 1.944-1.945l-8.652-8.652 8.652-8.653z"
                            />
                        </svg>
                    </SVGIconWrapper>
                    <Tabs
                        editMode={this.props.editMode}
                        onChange={
                            this.props.editMode
                                ? () => {}
                                : this.handleTabChange
                        }
                        tabs={tabs}
                        selected={this.state.contentType}
                    />
                    {this.renderForm()}
                    <SVGFromCloudinary
                        className={'cartoon-image'}
                        cloudinaryID={'v1554931960/SVGs/post-pop-girl.svg'}
                    />
                </div>
            </FlexPopup>
        );
    };

    showSuccessPopUp = () => {
        return (
            <ConfirmationPopup
                onClose={this.handleClose}
                heading={'Great!'}
                subheading={'Thanks for sharing.'}
                buttonMetas={[
                    {
                        onClickHandler: this.handleFormRedirect,
                        buttonText: 'Continue',
                        buttonType: 'purple-medium-white',
                    },
                ]}
            />
        );
    };

    //@TODO add Feature Flag Support
    renderForm = () => {
        let topic = null;
        let moderationOptions = null;
        let groupObject = null;
        if (this.state.defaultPostLocation) {
            if (this.state.defaultPostLocation.type === 'Group') {
                groupObject = {
                    id: this.state.defaultPostLocation.id,
                    name:
                        this.state.defaultPostLocation.title ||
                        this.state.defaultPostLocation.label ||
                        this.state.defaultPostLocation.name,
                    image: this.state.defaultPostLocation.image || null,
                    type: 'Group',
                };
            }
            topic = {
                id: this.state.defaultPostLocation.id,
                name:
                    this.state.defaultPostLocation.title ||
                    this.state.defaultPostLocation.label ||
                    this.state.defaultPostLocation.name,
                image: this.state.defaultPostLocation.image || null,
                type: this.state.defaultPostLocation.type || 'Topic',
            };
        }
        if (this.props.content) {
            if (this.props.content.topic) {
                topic = {
                    id: this.props.content.topic.id,
                    name: this.props.content.topic.label,
                    image: this.props.content.topic.image,
                    type: 'Topic',
                };
            }
            if (this.props.content.moderationOption) {
                moderationOptions = this.props.content.moderationOption;
            }
        }

        return (
            <React.Fragment>
                {this.state.contentType ===
                    CONTENT_TYPES.SAY_SOMETHING_FORM && (
                    <DiscussionForm
                        editMode={this.props.editMode}
                        contentProps={
                            this.props.content &&
                            this.props.content.type === 'community-discussion'
                                ? this.props.content
                                : null
                        }
                        clearError={this.clearError}
                        anon={
                            (this.props.content && this.props.content.isAnon) ||
                            this.props.defaultIsAnonymous
                        }
                        postLocation={groupObject || topic}
                        topics={this.state.topics}
                        onSuccess={this.handleSuccess}
                        onEditSubmit={this.props.onEditSubmit}
                        renderErrors={this.renderErrors}
                        validateForm={this.validateForm}
                        moderationOptions={moderationOptions}
                        getPostIdKeyValue={this.getPostIdKeyValue}
                        getCheckBoxLabel={this.getCheckBoxLabel}
                        featureFlags={this.props.featureFlags}
                    />
                )}
                {this.state.contentType === CONTENT_TYPES.SHARE_PHOTO_FORM && (
                    <PhotoForm
                        editMode={this.props.editMode}
                        contentProps={
                            this.props.content &&
                            this.props.content.type === 'community-photo'
                                ? this.props.content
                                : null
                        }
                        clearError={this.clearError}
                        anon={
                            (this.props.content && this.props.content.isAnon) ||
                            this.props.defaultIsAnonymous
                        }
                        onSuccess={this.handleSuccess}
                        onEditSubmit={this.props.onEditSubmit}
                        renderErrors={this.renderErrors}
                        validateForm={this.validateForm}
                        postLocation={groupObject || topic}
                        moderationOptions={moderationOptions}
                        getPostIdKeyValue={this.getPostIdKeyValue}
                        getCheckBoxLabel={this.getCheckBoxLabel}
                        featureFlags={this.props.featureFlags}
                    />
                )}
                {this.state.contentType === CONTENT_TYPES.SHARE_LINK_FORM && (
                    <LinkForm
                        editMode={this.props.editMode}
                        contentProps={
                            this.props.content &&
                            this.props.content.type === 'community-link'
                                ? this.props.content
                                : null
                        }
                        clearError={this.clearError}
                        anon={
                            (this.props.content && this.props.content.isAnon) ||
                            this.props.defaultIsAnonymous
                        }
                        topics={this.state.topics}
                        topicObject={
                            this.props.content && this.props.content.topic
                        }
                        postLocation={groupObject || topic}
                        onSuccess={this.handleSuccess}
                        onEditSubmit={this.props.onEditSubmit}
                        renderErrors={this.renderErrors}
                        validateForm={this.validateForm}
                        moderationOptions={moderationOptions}
                        getPostIdKeyValue={this.getPostIdKeyValue}
                        getCheckBoxLabel={this.getCheckBoxLabel}
                        featureFlags={this.props.featureFlags}
                    />
                )}
            </React.Fragment>
        );
    };

    render() {
        const newVersionClass = 'newStyling';

        return (
            <div
                className={`component-Content-CreateContentWidgetV2-CreateContentPopup ${newVersionClass}`}
            >
                {this.state.isPostSuccessful && this.showSuccessPopUp()}
                {!this.state.isPostSuccessful && this.showCreateContentPopUp()}
            </div>
        );
    }
}

//Add current topic from context
export default (props) => (
    <PageConsumer>
        {({ pageProps }) => {
            return (
                <CommunityContextConsumer>
                    {(context) => {
                        return (
                            <CreateContentPopup
                                {...props}
                                location={context.fetchMode}
                                session={pageProps.session}
                            />
                        );
                    }}
                </CommunityContextConsumer>
            );
        }}
    </PageConsumer>
);
