import React from 'react';

const SunIcon = ({ height = 80, width = 80 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            height={height}
            width={width}
        >
            <defs />
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <circle cx="43.5" cy="44.5" r="21.5" fill="#F8E683" />
                <circle cx="38" cy="39" r="16" fill="#FBF2C1" />
                <g
                    stroke="#2A4473"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                >
                    <path d="M17.2 39.01a21.77 21.77 0 1043.53 0 21.77 21.77 0 00-43.54 0zM38.96 10.06V0M53.3 13.86l4.98-8.74M63.87 24.26l8.66-5.13M67.91 38.53l10.06-.17M64.35 52.93l8.82 4.83M54.13 63.67l5.27 8.57M39.93 67.95L40.26 78M25.47 64.63l-4.69 8.9M14.56 54.59L6.08 60M10.05 40.46L0 40.96M13.12 25.95l-8.97-4.54M22.98 14.87l-5.55-8.39" />
                </g>
            </g>
        </svg>
    );
};

SunIcon.displayName = 'SunIcon';

export default React.memo(SunIcon);
