/* eslint import/no-cycle: 0 */
import React from 'react';
import { Consumer as PageConsumer } from './PageContext';

const wrapJobContextWithPageContext = (PureJobContextProvider) =>
    class WrapperProvider extends PureJobContextProvider {
        render() {
            return (
                <PageConsumer>
                    {(context) => {
                        return (
                            <PureJobContextProvider
                                {...this.props}
                                pageContext={context}
                            />
                        );
                    }}
                </PageConsumer>
            );
        }
    };

export default wrapJobContextWithPageContext;
