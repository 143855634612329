import React from 'react';
import PropTypes from 'prop-types';

import './CandidateLayout.scss';
import { MessageContext } from '../../../../../contexts/ContextCreator';
import Button from '../../../../Input/Button/Button';
import HoverCallToAction from '../../HoverCallToAction';
import UserImage from '../../../../Content/User/UserDisplay/UserImage/UserImage';

export default class CandidateLayout extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        candidate: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    static contextType = MessageContext;

    renderMainContent = () => {
        const { candidate } = this.props;
        const { userCommunityData } = candidate;
        return (
            <section className={'user-info-section'}>
                <UserImage
                    image={{
                        src: '/v1/avatar_black_160_vtg7lw.svg',
                        alt: 'testRecruiter image',
                    }}
                    isVip={false}
                    iconSize={72}
                />
                <div className="user-info-display">
                    <p className="username-display">
                        {candidate.professionalUsername}
                    </p>
                    {!this.context.isLoggedInUser(candidate.userId) && (
                        <a
                            className={'view-profile-link'}
                            href={candidate.jobProfileUrl}
                        >
                            View Profile
                        </a>
                    )}
                </div>
            </section>
        );
    };

    render() {
        const { style } = this.props;
        return (
            <div
                style={style}
                className="component-CallToAction-HoverCallToAction-CandidateLayout-CandidateLayout"
            >
                <HoverCallToAction
                    className={'user-hover-component'}
                    mainContent={this.renderMainContent()}
                />
            </div>
        );
    }
}
