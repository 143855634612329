import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TopBar from '../../Modules/TopBar/TopBar';
import { ellipsify } from '../../../../../utilities/helperFunctions';

import './DropdownLayout.scss';

const DropdownLayout = ({
    shouldShowDropdown,
    headerText,
    onClose,
    profileImage,
    children,
    cssClass,
}) => {
    const [wrapperRef, setWrapperRef] = useState(null);
    const handleClickOutside = (event) => {
        if (
            shouldShowDropdown &&
            wrapperRef &&
            !wrapperRef.contains(event.target)
        ) {
            onClose();
        }
    };
    useEffect(() => {
        setTimeout(() => {
            document.addEventListener('click', handleClickOutside);
        }, 20);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [wrapperRef]);

    const truncatedDisplayName = ellipsify(headerText, 16);
    return (
        <div
            className={`component-Layout-Header-Layout-DropdownLayout ${cssClass}`}
        >
            <div ref={setWrapperRef}>
                <TopBar
                    handleClose={onClose}
                    headerText={truncatedDisplayName}
                    profileImage={profileImage}
                />
                {children}
            </div>
        </div>
    );
};

DropdownLayout.propTypes = {
    /**
     * any element to be rendered underneath topbar
     */
    children: PropTypes.element.isRequired,
    /**
     * custom css class for easier styling
     */
    cssClass: PropTypes.string,
    /**
     * either heading text or username
     */
    headerText: PropTypes.string,
    /**
     * function to handle closing of dropdown
     */
    onClose: PropTypes.func.isRequired,
    /**
     * optional, string of user's logo or default avatar image
     */
    profileImage: PropTypes.string,
    /**
     * boolean to check whether dropdown should render
     */
    shouldShowDropdown: PropTypes.bool.isRequired,
};

DropdownLayout.defaultProps = {
    cssClass: '',
    profileImage: null,
    headerText: null,
};

export default DropdownLayout;
