import PropTypes from 'prop-types';
import React from 'react';
/* Contexts */
import { MessageConsumer } from '../../../contexts/MessageContext';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
/* Components */
import MessageBubbleTip from '../../Icons/Messages/MessageBubbleTip';
import TrashCan from '../../Icons/Messages/TrashCan/TrashCan';
import SVGIconWrapper from '../../Icons/SVGIconWrapper/SVGIconWrapper';
import { messageTypes } from '../../Includes/variables';
/* Stylesheets */
import './MessageBubble.scss';

const rolesWithDeletePermission = ['owner', 'admin', 'moderator'];

export class MessageBubble extends React.PureComponent {
    static propTypes = {
        /**
         * custom css class
         */
        cssClass: PropTypes.string,
        /**
         * content wrapped inside message bubble
         */
        children: PropTypes.node,
        /**
         * what bubble's background color is
         */
        bubbleBackgroundColor: PropTypes.oneOf([
            'gray-bubble',
            'purple-bubble',
            'pink-bubble',
            'blue-bubble',
        ]),
        /**
         * what position is the bubble tip vertically
         */
        bubbleTipVerticalPosition: PropTypes.oneOf(['top', 'bottom']),
        /**
         * what position is the bubble tip horizontally
         */
        bubbleTipHorizontalPosition: PropTypes.oneOf(['left', 'right']),
        /**
         * enum message type of group or community
         */
        messageType: PropTypes.oneOf([
            messageTypes.GROUP,
            messageTypes.COMMUNITY,
            messageTypes.EMPLOYER,
        ]),
        /**
         * number of authorId of message
         */
        authorId: PropTypes.number.isRequired,
        /**
         * enum of user role
         */
        userRole: PropTypes.oneOf(['owner', 'admin', 'moderator', 'member'])
            .isRequired,
        /**
         * number of current user id
         */
        userId: PropTypes.number,
        /**
         * disable delete functionality, mainly for group right rail preview for now
         */
        shouldDisableDelete: PropTypes.bool,
    };

    static defaultProps = {
        cssClass: '',
        children: <span />,
        bubbleBackgroundColor: 'purple-bubble',
        bubbleTipVerticalPosition: 'bottom',
        bubbleTipHorizontalPosition: 'left',
        messageType: messageTypes.COMMUNITY,
        shouldDisableDelete: false,
    };

    handleDelete = () => {
        this.props.deleteMessage(
            this.props.messageId,
            this.props.messageType,
            parseInt(this.props.authorId)
        );
    };

    renderDeleteIcon = () => {
        const {
            messageType,
            userRole,
            authorId,
            userId,
            shouldDisableDelete,
        } = this.props;

        const isGroupChatMessage = messageType === messageTypes.GROUP;
        const isAdminOrModerator =
            rolesWithDeletePermission.indexOf(userRole) !== -1;
        const isUserTheAuthor = userId === parseInt(authorId);

        const shouldShowDelete =
            isGroupChatMessage &&
            (isAdminOrModerator || isUserTheAuthor) &&
            !shouldDisableDelete;

        return (
            shouldShowDelete && (
                <SVGIconWrapper
                    handleClickSVGWrapper={this.handleDelete}
                    SVGWrapperCssClass="svg-trash-can-wrapper"
                >
                    <TrashCan />
                </SVGIconWrapper>
            )
        );
    };

    render() {
        const {
            cssClass,
            children,
            bubbleBackgroundColor,
            bubbleTipVerticalPosition,
            bubbleTipHorizontalPosition,
        } = this.props;
        const displayOnTop = bubbleTipVerticalPosition === 'top';
        const displayOnBottom = bubbleTipVerticalPosition === 'bottom';
        return (
            <div
                className={`component-Elements-MessageBubble ${bubbleBackgroundColor} ${cssClass}`}
            >
                {displayOnTop && (
                    <span
                        className={`bubble-tip ${bubbleBackgroundColor} ${bubbleTipVerticalPosition} ${bubbleTipHorizontalPosition}`}
                    >
                        {MessageBubbleTip}
                    </span>
                )}
                <span className="content-body">{children}</span>
                {displayOnBottom && (
                    <span
                        className={`bubble-tip ${bubbleBackgroundColor} ${bubbleTipVerticalPosition} ${bubbleTipHorizontalPosition}`}
                    >
                        {MessageBubbleTip}
                    </span>
                )}
                {this.renderDeleteIcon()}
            </div>
        );
    }
}

export default (props) => (
    <PageConsumer>
        {({ pageProps = {} }) => {
            return (
                <MessageConsumer>
                    {({ deleteMessage, getSelectedType, currentChannel }) => {
                        let userRole = 'member';
                        const userId =
                            pageProps.session && pageProps.session.id;
                        const messageType =
                            getSelectedType && getSelectedType();

                        if (currentChannel) {
                            const members =
                                currentChannel.channel.state.members || {};
                            const { role } =
                                Object.keys(members).length && members[userId]
                                    ? members[userId]
                                    : {};
                            userRole = role;
                        }

                        return (
                            <MessageBubble
                                {...props}
                                authorId={parseInt(props.authorId)}
                                deleteMessage={deleteMessage}
                                messageType={messageType}
                                userRole={userRole}
                                userId={userId}
                            />
                        );
                    }}
                </MessageConsumer>
            );
        }}
    </PageConsumer>
);
