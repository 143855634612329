import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from '../../../../utilities/helperFunctions';

import {
    pageDisplayModePropTypes,
    userRolePropTypes,
} from '../../../../pages/defaultProps';
import { PAGE_DISPLAY_MODE, ROLE_TYPES } from '../../../../pages/constants';
import {
    employerNavDefaultMenuKeys,
    employerNavAdminMenuKeys,
    employerNavAdminManageMenuKeys,
    employerNavRecruiterMenuKeys,
    userNavDefaultMenuKeys,
    fullUserMenu,
    userNavAdminMenuKeys,
    userNavContributorMenuKeys,
    userNavEmployerMenuKeys,
    employerNavFgbAdminMenuKeys,
} from '../constants/constants';

export default class UserMenuProvider extends React.PureComponent {
    constructor(props) {
        super(props);

        const [
            userDrawerMenu,
            userDropDownMenu,
            hasEmployerMessageInUserMenu,
        ] = this.getUserMenu(
            props.userRole,
            props.pageDisplayMode,
            props.isParticipatedInQuickApply
        );

        this.state = {
            userDrawerMenu,
            userDropDownMenu,
            hasEmployerMessageInUserMenu,
        };
    }

    componentDidUpdate(previousProps) {
        const { badgeCountData: previousBadgeCountData } = previousProps;
        const { badgeCountData: currentBadgeCountData } = this.props;

        const badgeDidNotUpdate =
            isEmpty(previousBadgeCountData) ||
            isEmpty(currentBadgeCountData) ||
            JSON.stringify(previousBadgeCountData) ===
                JSON.stringify(currentBadgeCountData);

        if (!badgeDidNotUpdate) {
            this.setUserMenu();
        }
    }

    setUserMenu = () => {
        const {
            userRole,
            pageDisplayMode,
            isParticipatedInQuickApply,
        } = this.props;

        const [userDrawerMenu, userDropDownMenu] = this.getUserMenu(
            userRole,
            pageDisplayMode,
            isParticipatedInQuickApply
        );

        this.setState({ userDrawerMenu, userDropDownMenu });
    };

    getUserMenu = (userRole, pageDisplayMode, isParticipatedInQuickApply) => {
        if (!userRole || !pageDisplayMode) {
            return [[], []];
        }

        const endCandidateDatabaseAccessFlag = this.props.featureFlags
            .endCandidateDatabaseAccess;

        const hasEmployerMessageInUserMenu = this.getHasEmployerMessageInUserMenu(
            userRole,
            pageDisplayMode,
            isParticipatedInQuickApply
        );
        const userMenuItemKeys = this.getRequiredMenuItemKeys(
            userRole,
            pageDisplayMode,
            hasEmployerMessageInUserMenu,
            endCandidateDatabaseAccessFlag
        );
        const userDrawerMenu = this.constructUserMenu(
            userMenuItemKeys,
            fullUserMenu,
            'displayInDrawer'
        );
        const userDropDownMenu = this.constructUserMenu(
            userMenuItemKeys,
            fullUserMenu,
            'displayInDropdown'
        );

        return [userDrawerMenu, userDropDownMenu, hasEmployerMessageInUserMenu];
    };

    /**
     * user menu item "employer-message" would display under following 3 conditions
     * 1. page display mode is employer nav
     * 2. user has role in company that is participating in quick apply
     * 3. user is either an employer admin or recruiter
     */
    getHasEmployerMessageInUserMenu = (
        userRole = ROLE_TYPES.UNAUTH,
        pageDisplayMode = PAGE_DISPLAY_MODE.USER_DEFAULT,
        isParticipatedInQuickApply = false
    ) =>
        pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT &&
        isParticipatedInQuickApply &&
        (userRole === ROLE_TYPES.EMPLOYER_ADMIN ||
            userRole === ROLE_TYPES.EMPLOYER_RECRUITER);

    getRequiredMenuItemKeys = (
        userRole,
        pageDisplayMode,
        hasEmployerMessageInUserMenu = false,
        endCandidateDatabaseAccess = false
    ) => {
        if (pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT) {
            let employerMenuKeys = employerNavDefaultMenuKeys;

            if (userRole === ROLE_TYPES.EMPLOYER_ADMIN) {
                employerMenuKeys = employerNavAdminManageMenuKeys;
            }

            if (
                userRole === ROLE_TYPES.EMPLOYER_ADMIN &&
                !endCandidateDatabaseAccess
            ) {
                employerMenuKeys = employerNavAdminMenuKeys;
            }

            if (
                userRole === ROLE_TYPES.EMPLOYER_RECRUITER &&
                !endCandidateDatabaseAccess
            ) {
                employerMenuKeys = employerNavRecruiterMenuKeys;
            }

            if (userRole === ROLE_TYPES.USER_ADMIN) {
                employerMenuKeys = employerNavFgbAdminMenuKeys;
            }

            if (!hasEmployerMessageInUserMenu) {
                employerMenuKeys = employerMenuKeys.filter(
                    (menuKey) => menuKey !== 'employer-messages'
                );

                return employerMenuKeys;
            }

            return employerMenuKeys;
        }

        if (pageDisplayMode === PAGE_DISPLAY_MODE.USER_DEFAULT) {
            if (userRole === ROLE_TYPES.USER_CONTRIBUTOR) {
                return userNavContributorMenuKeys;
            }

            if (userRole === ROLE_TYPES.USER_ADMIN) {
                return userNavAdminMenuKeys;
            }

            if (
                userRole === ROLE_TYPES.EMPLOYER_DEFAULT ||
                userRole === ROLE_TYPES.EMPLOYER_RECRUITER ||
                userRole === ROLE_TYPES.EMPLOYER_ADMIN
            ) {
                return userNavEmployerMenuKeys;
            }

            return userNavDefaultMenuKeys;
        }

        return [];
    };

    constructUserMenu = (
        requiredMenuItemKeys = [],
        usermenu = fullUserMenu,
        displayInLocation = 'displayInDrawer'
    ) => {
        if (!Array.isArray(requiredMenuItemKeys)) {
            return [];
        }

        const constructedUserMenu = requiredMenuItemKeys
            .map((requiredMenuItemKey) => {
                const rawMenuItem = usermenu.filter(
                    (menuItem) =>
                        menuItem.key === requiredMenuItemKey &&
                        menuItem[displayInLocation]
                );

                const menuItem = this.insertBadgeCount(rawMenuItem[0]);

                return menuItem;
            })
            .filter((menuItem) => !isEmpty(menuItem));

        return constructedUserMenu;
    };

    insertBadgeCount = (rawMenuItem) => {
        const menuItem = { ...rawMenuItem };
        const {
            badgeCountData: { pendingConnections, unreadMessageCount },
        } = this.props;

        if (menuItem.key === 'my-network' && pendingConnections > 0) {
            menuItem.count = pendingConnections;
        }
        if (
            (menuItem.key === 'my-messages' ||
                menuItem.key === 'employer-messages') &&
            unreadMessageCount > 0
        ) {
            menuItem.count = unreadMessageCount;
        }

        return menuItem;
    };

    render() {
        const {
            userDrawerMenu,
            userDropDownMenu,
            hasEmployerMessageInUserMenu,
        } = this.state;
        const { children } = this.props;

        return typeof children === 'function'
            ? children({
                  ...this.props,
                  userDrawerMenu,
                  userDropDownMenu,
                  hasEmployerMessageInUserMenu,
              })
            : React.Children.map(children, (child) =>
                  React.cloneElement(child, {
                      ...this.props,
                      userDrawerMenu,
                      userDropDownMenu,
                      hasEmployerMessageInUserMenu,
                  })
              );
    }
}

UserMenuProvider.propTypes = {
    /**
     * Type of user's role
     */
    userRole: userRolePropTypes.isRequired,
    /**
     * Value of the view layout the page is in
     */
    pageDisplayMode: pageDisplayModePropTypes.isRequired,
    /**
     * data for badge
     */
    badgeCountData: PropTypes.shape({
        pendingConnections: PropTypes.number,
        unreadMessageCount: PropTypes.number,
    }),
    featureFlags: PropTypes.shape({
        endCandidateDatabaseAccess: PropTypes.bool,
    }),
    /**
     * is user has role in company that participated in quick apply
     */
    isParticipatedInQuickApply: PropTypes.bool,
    children: PropTypes.func,
};

UserMenuProvider.defaultProps = {
    featureFlags: {
        endCandidateDatabaseAccess: false,
    },
    isParticipatedInQuickApply: false,
    badgeCountData: {
        pendingConnections: 0,
        unreadMessageCount: 0,
    },
    children: <span />,
};
