import React from 'react';
import PropTypes from 'prop-types';

import './JobSeekerExperienceLayout.scss';
import Button from '../../../Input/Button/Button';
import FormElementWithLabel from '../../../Input/FormElementWithLabel/FormElementWithLabel';
import InputField from '../../../Input/InputField/InputField';
import CompanyTypeahead from '../../../Input/CompanyTypeahead/CompanyTypeahead';
import LocationInput from '../../../Input/LocationInput/LocationInput';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';
import CheckBox from '../../../Content/Elements/CheckBox/CheckBox';
import { MonthDropdownOptions, renderYearsOptions } from '../JobSeekerModal';
import { generateYearsArray } from '../../../Utilities/TimeFunctions';
import { jobSeekerExperienceSchema } from '../../../Includes/FormSchema/JobProfileSchema';
import {
    hasLocationCheck,
    getLocationProps,
} from '../../../Utilities/LocationFunctions';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';

export const jobTypesOptions = [
    {
        label: 'Full-time',
        value: 'full-time',
    },
    {
        label: 'Part-time',
        value: 'part-time',
    },
    {
        label: 'Self-employed',
        value: 'self-employed',
    },
    {
        label: 'Freelance',
        value: 'freelance',
    },
    {
        label: 'Contract',
        value: 'contract',
    },
    {
        label: 'Internship',
        value: 'internship',
    },
    {
        label: 'Apprenticeship',
        value: 'apprenticeship',
    },
    {
        label: 'Volunteer',
        value: 'volunteer',
    },
];

export default class JobSeekerExperienceLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title || '',
            startMonth: props.startMonth || null,
            startYear: props.startYear || null,
            endMonth: props.endMonth || null,
            endYear: props.endYear || null,
            isCurrent: props.isCurrent || false,
            company: props.company || null,
            location: props.location || null,
            isRemote: props.isRemote || false,
            jobType: props.jobType || null,
            description: props.description || null,
            error: props.error || {},
        };
    }

    static propTypes = {
        title: PropTypes.string,
        startMonth: PropTypes.number,
        startYear: PropTypes.number,
        endMonth: PropTypes.number,
        endYear: PropTypes.number,
        isCurrent: PropTypes.bool,
        company: PropTypes.shape({
            companyName: PropTypes.string,
            companyId: PropTypes.number,
        }),
        location: PropTypes.shape({
            name: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        isRemote: PropTypes.bool,
        jobType: PropTypes.string,
        description: PropTypes.string,
        handleSubmit: PropTypes.func,
        handleDelete: PropTypes.func,
        error: PropTypes.object,
        getLocationProps: PropTypes.func,
    };

    static defaultProps = {};

    getFieldLabelProps = (type) => {
        let label;
        let shouldShowTooltip = false;
        let tooltipText = null;
        switch (type) {
            case 'title':
                label = 'Title*';
                break;
            case 'employment-type':
                label = 'Employment type';
                break;
            case 'company':
                label = 'Company*';
                break;
            case 'location':
                label = 'Location*';
                break;
            case 'start-date':
                label = 'Start date*';
                break;
            case 'end-date':
                label = 'End date*';
                break;
            case 'description':
                label = 'Description';
                break;
        }
        return {
            label,
            shouldShowTooltip,
            tooltipText,
        };
    };

    hasCompany = () => {
        return !!(
            this.state.company &&
            this.state.company.companyName &&
            this.state.company.companyName.length &&
            this.state.company.companyId &&
            this.state.company.companyId
        );
    };

    handleChange = (key, value) => {
        const newState = Object.assign({}, this.state);
        newState[key] = value;

        if (key === 'isRemote' && value === true) {
            newState.location = '';
            if (this.state.error) {
                newState.error.location = null;
            }
        }
        if (key === 'isCurrent' && value === true) {
            newState.endYear = null;
            newState.endMonth = null;
            if (this.state.error) {
                newState.error.endYear = null;
                newState.error.endMonth = null;
            }
        }

        if (this.props.hasError(key, this.state.error)) {
            newState.error[key] = null;
        }

        this.setState(newState);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const {
            title,
            jobType,
            company,
            location,
            isRemote,
            isCurrent,
            startMonth,
            startYear,
            endMonth,
            endYear,
            description,
        } = this.state;
        const locationProps = getLocationProps(location);
        const experienceObject = {
            title,
            jobType,
            company: this.hasCompany()
                ? {
                      companyName: company.companyName,
                      companyId: company.companyId,
                  }
                : null,
            location:
                hasLocationCheck(this.state.location) && !isRemote
                    ? {
                          name: locationProps.value,
                          lat: locationProps.lat,
                          lng: locationProps.lng,
                      }
                    : null,
            isRemote,
            isCurrent,
            startMonth:
                typeof startMonth === 'string'
                    ? parseInt(startMonth)
                    : startMonth,
            startYear:
                typeof startYear === 'string' ? parseInt(startYear) : startYear,
            endMonth:
                typeof endMonth === 'string' ? parseInt(endMonth) : endMonth,
            endYear: typeof endYear === 'string' ? parseInt(endYear) : endYear,
            description,
        };

        let error = Object.assign({}, this.state.error);
        const isValid = await this.props.validateForm(
            experienceObject,
            jobSeekerExperienceSchema,
            error,
            this.setErrorState
        );

        //@TODO remove after finished hooking up to page.
        // console.log("title", experienceObject.title);
        // console.log("jobType", experienceObject.jobType);
        // console.log("company", experienceObject.company);
        // console.log("location", experienceObject.location);
        // console.log('isRemote', experienceObject.isRemote);
        // console.log("Current", experienceObject.isCurrent);
        // console.log("startMonth",experienceObject.startMonth);
        // console.log("Start Year",experienceObject.startYear);
        // console.log("endMonth", experienceObject.endMonth);
        // console.log("End Year", experienceObject.endYear);
        // console.log("description", experienceObject.description);

        if (!isValid) {
            return;
        }
        this.props.handleSubmit(experienceObject);
    };

    setErrorState = (error) => {
        this.setState({
            error,
        });
    };

    render() {
        const {
            title,
            jobType,
            company,
            location,
            isRemote,
            isCurrent,
            startMonth,
            startYear,
            endMonth,
            endYear,
            description,
        } = this.state;
        const locationValue =
            location && (location.value || location.name || '');
        const startMonthError = this.props.errorStylingCheck(
            this.props.hasError('startMonth', this.state.error)
        );
        const startYearError = this.props.errorStylingCheck(
            this.props.hasError('startYear', this.state.error)
        );
        const endMonthError = this.props.errorStylingCheck(
            this.props.hasError('endMonth', this.state.error)
        );
        const endYearError = this.props.errorStylingCheck(
            this.props.hasError('endYear', this.state.error)
        );
        return (
            <form
                className="component-Modals-JobSeekerModal-JobSeekerExperienceLayout"
                onSubmit={(e) => this.handleSubmit(e)}
            >
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('title')}
                    elementValue={title}
                    inputMaxLength={200}
                    error={this.props.renderErrorString(
                        'title',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('title', this.state.error)
                    )}`}
                >
                    <InputField
                        name={'title'}
                        inputValue={title}
                        onChange={(value) => this.handleChange('title', value)}
                    />
                </FormElementWithLabel>
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('employment-type')}
                    error={this.props.renderErrorString(
                        'jobType',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('jobType', this.state.error)
                    )}`}
                >
                    <BasicDropdown
                        options={jobTypesOptions}
                        defaultLabel={'Select'}
                        defaultValueIsNull={true}
                        selectedValue={jobType}
                        onChange={(value) =>
                            this.handleChange('jobType', value)
                        }
                        shouldDisableDefault={true}
                        qaDataAttr={'jobType'}
                    />
                </FormElementWithLabel>
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('company')}
                    error={this.props.renderErrorString(
                        'company',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('company', this.state.error)
                    )}`}
                >
                    <CompanyTypeahead
                        placeholder={'Start typing'}
                        items={company ? [company] : []}
                        onUpdate={(value) =>
                            this.handleChange('company', value)
                        }
                        addOption={true}
                    />
                </FormElementWithLabel>
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('location')}
                    error={this.props.renderErrorString(
                        'location',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('location', this.state.error)
                    )} ${this.props.disabledStylingCheck(isRemote)}`}
                    haveErrorSpace={false}
                >
                    <LocationInput
                        value={locationValue}
                        onChange={(value) =>
                            this.handleChange('location', value)
                        }
                        disabled={isRemote}
                    />
                </FormElementWithLabel>
                <CheckBox
                    id={'remote-checkbox'}
                    handleChange={(value) =>
                        this.handleChange('isRemote', value)
                    }
                    defaultChecked={isRemote}
                    label={'Remote'}
                />
                <fieldset>
                    <legend>
                        {this.getFieldLabelProps('start-date').label}
                    </legend>
                    <div className={'double-dropdown-styling'}>
                        <BasicDropdown
                            options={MonthDropdownOptions}
                            defaultLabel={'Month'}
                            defaultValueIsNull={true}
                            selectedValue={startMonth}
                            cssClass={`first-dropdown ${startMonthError}`}
                            onChange={(value) =>
                                this.handleChange('startMonth', value)
                            }
                            shouldDisableDefault={true}
                            ariaLabel={'start month'}
                            qaDataAttr={'start month'}
                        />
                        <BasicDropdown
                            options={renderYearsOptions(
                                generateYearsArray(60, 0).reverse()
                            )}
                            defaultLabel={'Year'}
                            defaultValueIsNull={true}
                            selectedValue={startYear}
                            cssClass={startYearError}
                            onChange={(value) =>
                                this.handleChange('startYear', value)
                            }
                            ariaLabel={'start year'}
                            qaDataAttr={'start year'}
                        />
                    </div>
                    <ErrorLabel
                        error={
                            this.props.renderErrorString(
                                'startMonth',
                                this.state.error
                            ) ||
                            this.props.renderErrorString(
                                'startYear',
                                this.state.error
                            )
                        }
                    />
                </fieldset>
                <fieldset
                    className={`${this.props.disabledStylingCheck(isCurrent)}`}
                >
                    <legend>{this.getFieldLabelProps('end-date').label}</legend>
                    <div className={'double-dropdown-styling'}>
                        <BasicDropdown
                            options={MonthDropdownOptions}
                            defaultLabel={'Month'}
                            defaultValueIsNull={true}
                            selectedValue={endMonth}
                            cssClass={`first-dropdown ${endMonthError}`}
                            onChange={(value) =>
                                this.handleChange('endMonth', value)
                            }
                            shouldDisableDefault={true}
                            disabled={isCurrent}
                            ariaLabel={'end month'}
                            qaDataAttr={'end month'}
                        />
                        <BasicDropdown
                            options={renderYearsOptions(
                                generateYearsArray(60, 0).reverse()
                            )}
                            defaultLabel={'Year'}
                            defaultValueIsNull={true}
                            selectedValue={endYear}
                            cssClass={endYearError}
                            onChange={(value) =>
                                this.handleChange('endYear', value)
                            }
                            shouldDisableDefault={true}
                            disabled={isCurrent}
                            ariaLabel={'end year'}
                            qaDataAttr={'end year'}
                        />
                    </div>
                    {this.props.renderErrorString(
                        'endMonth',
                        this.state.error
                    ) ||
                    this.props.renderErrorString(
                        'endYear',
                        this.state.error
                    ) ? (
                        <ErrorLabel
                            error={
                                this.props.renderErrorString(
                                    'endMonth',
                                    this.state.error
                                ) ||
                                this.props.renderErrorString(
                                    'endYear',
                                    this.state.error
                                )
                            }
                        />
                    ) : null}
                </fieldset>
                <CheckBox
                    id={'experience-current'}
                    defaultChecked={isCurrent}
                    label={'I am currently working in this role'}
                    handleChange={(value) =>
                        this.handleChange('isCurrent', value)
                    }
                />
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('description')}
                    inputMaxLength={2000}
                    error={this.props.renderErrorString(
                        'description',
                        this.state.error
                    )}
                    elementValue={description}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('description', this.state.error)
                    )}`}
                >
                    <TextAreaInput
                        name={'description'}
                        value={description}
                        onChange={(value) =>
                            this.handleChange('description', value)
                        }
                    />
                </FormElementWithLabel>
                <div className="bottom-button-container">
                    <div className="delete-button-container">
                        <Button
                            value={'Delete'}
                            inputStyle={'wired-blue'}
                            onClick={this.props.handleDelete}
                        />
                    </div>
                    <div className="done-button-container">
                        <Button
                            value={'Done'}
                            inputStyle="solid"
                            formAction={'submit'}
                        />
                    </div>
                </div>
            </form>
        );
    }
}
