import PropTypes from 'prop-types';
import React from 'react';
import { SINGLE_USER_PROPTYPES } from '../../CallToAction/CommunityUserCallToActions/constants';

import { truncateWithEllipsis } from '../../../utilities/ui_utilities';
import CommunityUserCallToActions from '../../CallToAction/CommunityUserCallToActions/CommunityUserCallToActions';

import UserImage from '../../Content/User/UserDisplay/UserImage/UserImage';

import './UserListItem.scss';

export default class UserListItem extends React.PureComponent {
    static propTypes = {
        /**
         * user
         */
        user: PropTypes.shape(SINGLE_USER_PROPTYPES),
        /**
         * call to action buttons
         */
        callToActions: PropTypes.arrayOf(
            PropTypes.shape({
                onClick: PropTypes.func,
                type: PropTypes.string,
                value: PropTypes.string,
                inputStyle: PropTypes.string,
            })
        ),
        /**
         * boolean to check if new CTA buttons should be rendered
         */
        displayConnBtns: PropTypes.bool,
        /**
         * string of custom css class
         */
        className: PropTypes.string,
        /**
         * string of connection type for recommendation
         */
        connectionType: PropTypes.string,
        /**
         * location from which metrics are fired
         */
        metricLocation: PropTypes.string,
        /**
         * links to groups open new tab
         */
        openLinksInNewTab: PropTypes.bool,
        /**
         * group prop for when using invite to group
         */
        group: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }),
        onClickHandlers: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),
        modalTriggerParameters: PropTypes.shape({
            modalName: PropTypes.string,
            modalData: PropTypes.object,
        }),
    };

    static defaultProps = {
        callToActions: [],
        displayConnBtns: true,
        className: '',
        connectionType: null,
        metricLocation: '',
        openLinksInNewTab: false,
    };

    renderConnectionType() {
        const { connectionType } = this.props;
        return connectionType === 'interests'
            ? '2nd Degree Connection'
            : 'FGB VIP';
    }

    render() {
        const { openLinksInNewTab, user } = this.props;

        return (
            <div
                className={`component-Users-UserListItem ${this.props.className}`}
            >
                <a
                    className="profile-url"
                    href={user.publicUrl}
                    rel={openLinksInNewTab ? 'noopener noreferrer' : ''}
                    target={openLinksInNewTab ? '_blank' : ''}
                >
                    <div className="user-image">
                        <UserImage image={user.image} iconSize={36} />
                    </div>
                </a>
                <div className="user-info-container">
                    <a
                        className="profile-url"
                        href={user.publicUrl}
                        rel={openLinksInNewTab ? 'noopener noreferrer' : ''}
                        target={openLinksInNewTab ? '_blank' : ''}
                    >
                        <div className="user-info">
                            <h3 className="username-text">{user.username}</h3>
                            {user.credential ? (
                                <div className="profile-credential">
                                    {truncateWithEllipsis(user.credential, 46)}
                                </div>
                            ) : null}
                            {this.props.connectionType && (
                                <div className="connection-type">
                                    {this.renderConnectionType()}
                                </div>
                            )}
                        </div>
                    </a>
                    <div className="call-to-action-section">
                        {this.props.displayConnBtns ? (
                            <CommunityUserCallToActions
                                group={this.props.group}
                                user={user}
                                wrapperCssClass={'callToActionButton-div'}
                                buttonsToRender={this.props.buttonsToRender}
                                connectionStatus={this.props.connectionStatus}
                                isFollowing={this.props.followStatus}
                                onClickHandlers={this.props.onClickHandlers}
                                modalTriggerParameters={
                                    this.props.modalTriggerParameters
                                }
                                clickedAction={this.props.clickedAction}
                                metricLocation={this.props.metricLocation}
                                userSearchMessage={this.props.userSearchMessage}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
