import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import { COMPANY_PROPTYPES } from '../components/Includes/CompanyProps';
import { CompanyContext as Context } from './ContextCreator';

export const API_FOLLOW_COMPANY = '/api/user/alerts/company/follow';
export const API_UNFOLLOW_COMPANY = '/api/user/alerts/company/unfollow';
export const API_GET_FOLLOWED_COMPANIES = '/api/company/followed-employers';

export class Provider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: props.company,
            userId: props.userId,
            followCompany: this.followCompany,
            unfollowCompany: this.unfollowCompany,
        };
    }

    static propTypes = {
        /**
         * See above of content structure
         */
        company: PropTypes.shape(COMPANY_PROPTYPES),
        /**
         *  session user id
         */
        userId: PropTypes.number,

        //GOOGLE SEO REVIEW MARKUP
    };

    followCompany = async (companyId, userId, isJobFollow = true) => {
        try {
            const res = (
                await axios.post(API_FOLLOW_COMPANY, { companyId, isJobFollow })
            ).data.data;

            if (userId && this.props.company) {
                try {
                    await EmitMetric({
                        company_id: companyId,
                        misc_event_type: 'follow-company',
                        misc_event_count: 1,
                        userId,
                    });
                }
                catch(e) {
                    console.log(e);
                }
                
                this.updateIsFollowed(companyId);
            } else {
                try {
                    await EmitMetric({
                        company_id: companyId,
                        misc_event_type: 'follow-company',
                        misc_event_count: 1,
                    });
                }
                catch(e) {
                    console.log(e);
                }
                
            }
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    updateIsFollowed = async (companyId) => {
        try {
            const res = await axios.get(API_GET_FOLLOWED_COMPANIES);
            const isFollowed = res.data.some(
                (co) => co.companyId === companyId
            );
            const newState = Object.assign(this.state.company, {
                isFollowed: isFollowed,
            });
            this.setState({
                company: newState,
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    unfollowCompany = async (notificationId, userId) => {
        try {
            await axios.post(API_UNFOLLOW_COMPANY, {
                notificationId: notificationId,
            });
            if (userId && this.props.company) {
                await EmitMetric({
                    company_id: this.props.company.companyId,
                    misc_event_type: 'unfollow-company',
                    misc_event_count: 1,
                    userId,
                });
                this.updateIsFollowed(this.props.company.companyId);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    componentDidMount() {
        if (this.props.userId) {
            this.updateIsFollowed(this.props.company.companyId);
        }
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const Consumer = Context.Consumer;
