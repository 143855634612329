import React from 'react';

export default (
    <svg
        width="61px"
        height="63px"
        viewBox="0 0 61 63"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Events-&amp;-Webinars-Marketing-mocks-(may-2019)"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Event-Lander-FGB-EVENT-one-sponsor"
                transform="translate(-521.000000, -1363.000000)"
            >
                <g id="Group-19" transform="translate(517.000000, 1354.000000)">
                    <g id="calendar" transform="translate(5.000000, 10.500000)">
                        <g
                            id="Group-7"
                            transform="translate(5.000000, 27.988235)"
                        >
                            <rect
                                id="Rectangle"
                                fill="#FBF2C1"
                                x="19.2857143"
                                y="0"
                                width="12.8571429"
                                height="11.4823529"
                            ></rect>
                            <rect
                                id="Rectangle-Copy-5"
                                fill="#F2ECFE"
                                x="0"
                                y="21.5294118"
                                width="12.8571429"
                                height="11.4823529"
                            ></rect>
                            <rect
                                id="Rectangle-Copy-7"
                                fill="#EAC9EF"
                                x="42.1428571"
                                y="10.0470588"
                                width="12.8571429"
                                height="11.4823529"
                            ></rect>
                        </g>
                        <path
                            d="M0.182571429,16.2766659 L55.4568571,16.2766659"
                            id="Stroke-105"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M0.182571429,26.0960153 L55.4568571,26.0960153"
                            id="Stroke-106"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M0.182571429,36.0652812 L55.4568571,36.0652812"
                            id="Stroke-107"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M0.182571429,46.0345471 L55.4568571,46.0345471"
                            id="Stroke-108"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M41.1759286,5.46222706 L14.4680714,5.46222706"
                            id="Stroke-109"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <polyline
                            id="Stroke-110"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            points="7.80571429 5.46222706 0 5.46222706 0 55.9766859 55.7142857 55.9766859 55.7142857 5.46222706 48.0371429 5.46222706"
                        ></polyline>
                        <polygon
                            id="Stroke-111"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            points="41.3992857 10.1016 47.7435714 10.1016 47.7435714 8.8817842e-16 41.3992857 8.8817842e-16"
                        ></polygon>
                        <polygon
                            id="Stroke-112"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            points="7.97071429 10.1016 14.315 10.1016 14.315 8.8817842e-16 7.97071429 8.8817842e-16"
                        ></polygon>
                        <path
                            d="M11.1431429,16.5808765 L11.1431429,55.5498294"
                            id="Stroke-113"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M22.2860714,16.5808765 L22.2860714,55.5498294"
                            id="Stroke-114"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M33.4286429,16.5808765 L33.4286429,55.5498294"
                            id="Stroke-115"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M44.5715714,16.5808765 L44.5715714,55.5498294"
                            id="Stroke-116"
                            stroke="#2A4473"
                            strokeWidth="1.28571429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
