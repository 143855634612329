import Yup from 'yup';
import { meetsMinWordCount, uniqueAnswersTest } from './SchemaHelpers';

export const surveyStatus = {
    SUBMIT: 'SUBMIT', // submit answer and go to next step (autoadvance);
    CAN_SUBMIT: 'CAN_SUBMIT', //allow to go to the next step but don't autoadvance;
    CANT_SUBMIT: 'CANT_SUBMIT', //can submit but don't go to next step
    ALLOW_SUB_STEP: 'ALLOW_SUB_STEP', // you CAN go to substep but don't autoadvance until you hit next button
    GO_SUB_STEP: 'GO_SUB_STEP', //ie. hit other and automatically go to substep
};
export const companySchema = {
    id: Yup.number().integer().positive().nullable(),
    name: Yup.string().nullable().required(),
    logo: Yup.string().nullable(),
};

export const employerLocationSchema = {
    name: Yup.string().nullable().required(),
    longitude: Yup.number().nullable().required(),
    latitude: Yup.number().nullable().required(),
};

export const jobSatisfactionSchema = {
    rating: Yup.mixed().oneOf([1, 2, 3, 4, 5]).nullable().required(),
};

export const treatedEquallySchema = {
    treatedEqually: Yup.boolean().nullable().required(),
    unequalAttributes: Yup.array().when('treatedEqually', {
        is: true,
        then: Yup.array().test(
            'is-null',
            'if treated equally, there should be no unequal attributes',
            (v) => v === null || v === undefined
        ),
        otherwise: Yup.array()
            .of(
                Yup.mixed().oneOf([
                    'pay',
                    'promotion',
                    'hiring',
                    'evaluation',
                    'other',
                ])
            )
            .nullable()
            .required()
            .test(
                'one-of-each',
                'only one of each item allowed',
                uniqueAnswersTest
            ),
    }),
};

export const recommendationSchema = {
    recommend: Yup.mixed().oneOf(['yes', 'maybe', 'no']).nullable().required(),
    recommendComment: Yup.string().when('recommend', {
        is: 'maybe',
        then: Yup.string().min(1).max(5000).nullable().required(),
        otherwise: Yup.string().test(
            'is-null',
            'if recommend is not maybe, there should be no comment',
            (v) => v === null || v === undefined
        ),
    }),
};

export const makeBetterSchema = {
    makeBetter: Yup.mixed()
        .oneOf([
            'notMuch',
            'promoteWomen',
            'improveWorkLife',
            'improveBenefits',
            'improveCompensation',
            'other',
        ])
        .nullable()
        .required(),
    makeBetterComment: Yup.string().when('makeBetter', {
        is: 'other',
        then: Yup.string().min(1).max(5000).nullable().required(),
        otherwise: Yup.string().test(
            'is-null',
            'if make better is not other, there should be no comment',
            (v) => v === null || v === undefined
        ),
    }),
};

export const workLifeBalanceSchema = {
    workLifeBalance: Yup.array()
        .min(1)
        .of(Yup.mixed().oneOf(['hours', 'culture', 'policies']))
        .nullable()
        .test(
            'one-of-each',
            'only one of each item allowed',
            uniqueAnswersTest
        ),
};

export const flexibilitySchema = {
    flexibilityRating: Yup.mixed().oneOf([1, 2, 3, 4, 5]).nullable().required(),
    flexibilityComment: Yup.string().min(1).max(5000).nullable(),
};

export const commentSchema = {
    comment: Yup.string()
        .max(65000)
        .nullable()
        .required()
        .test(
            '10-min-word-count',
            'Answer to this question is required - 10 word minimum',
            meetsMinWordCount(10)
        ),
};

export const managerSchema = {
    managerRating: Yup.mixed().oneOf([1, 2, 3, 4, 5]).nullable().required(),
    managerComment: Yup.string().min(1).max(5000).nullable(),
};

export const ceoGenderDiversitySchema = {
    ceoGenderDiversity: Yup.mixed()
        .oneOf(['yes', 'no', 'unsure'])
        .nullable()
        .required(),
};

export const benefitSchema = {
    benefits: Yup.array()
        .min(1)
        .of(
            Yup.mixed().oneOf([
                'parentalLeave',
                'fertility',
                'pension',
                'childcare',
                'healthcare',
            ])
        )
        .nullable()
        .test(
            'one-of-each',
            'only one of each item allowed',
            uniqueAnswersTest
        ),
    benefitComment: Yup.string().min(1).max(5000).nullable(),
};

export const parentalLeaveSchema = {
    offered: Yup.boolean().nullable().required(),
    paid: Yup.number().when('offered', {
        is: true,
        then: Yup.number().min(0).max(52).nullable().required(),
        otherwise: Yup.number().nullable(),
    }),
    unpaid: Yup.number().when('offered', {
        is: true,
        then: Yup.number().min(0).max(52).nullable().required(),
        otherwise: Yup.number().nullable(),
    }),
    parentalLeaveComment: Yup.string().min(1).max(5000).nullable(),
};

export const salarySchema = {
    salary: Yup.mixed()
        .oneOf([
            '0-25k',
            '25k-50k',
            '50k-80k',
            '80k-100k',
            '100k-150k',
            '>150k',
            null,
        ])
        .nullable(),
    isPublic: Yup.boolean().nullable().required(),
};

export const bonusSchema = {
    bonus: Yup.mixed()
        .oneOf(['0', '0-10k', '10k-20k', '20k-50k', '50k-100k', '>100k', null])
        .nullable(),
    isPublic: Yup.boolean().nullable().required(),
};

export const positionSchema = {
    jobTitle: Yup.string().min(1).max(200).nullable(),
    departmentId: Yup.number().integer().positive().nullable(),
    // atLeastOne: Yup.mixed().when(['jobTitle', 'departmentId'], {
    //     is: (jobTitle, departmentId) => (jobTitle === null || jobTitle === undefined) && (departmentId === null || departmentId === undefined),
    //     then: Yup.mixed().test('at-least-one', 'at least one of job title and department id is required', () => false),
    //     otherwise: Yup.mixed().test(() => true)
    // }),
    isPublic: Yup.boolean().nullable(),
};

export const ethnicitySchema = {
    ethnicity: Yup.mixed()
        .oneOf([
            'mixed',
            'caucasian',
            'undisclosed',
            'hispanic',
            'black',
            'asian',
            'other',
            'native',
            null,
        ])
        .nullable(),
};

export const specialGroupSchema = {
    specialGroups: Yup.array()
        .min(1)
        .of(
            Yup.mixed().oneOf([
                'parent',
                'caregiver',
                'lgbtq',
                'veteran',
                'disabled',
                null,
            ])
        )
        .nullable()
        .test(
            'one-of-each',
            'only one of each item allowed',
            uniqueAnswersTest
        ),
};
