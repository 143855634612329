/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import PropTypes from 'prop-types';
import React from 'react';
import { truncateWithEllipsis } from '../../../../../utilities/ui_utilities';
import FacebookButton from '../../SocialMediaButtons/FacebookButton/FacebookButton';
import GoogleButton from '../../SocialMediaButtons/GoogleButton/GoogleButton';
import EmailLoginForm from '../EmailLoginForm/EmailLoginForm';
import { LOGIN_LAYOUT } from './constants';
import './LogInLayout.scss';

class LogInLayout extends React.PureComponent {
    render() {
        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-LogInLayout">
                <div className="login-subText-call-to-action-component">
                    {truncateWithEllipsis(this.props.callToActionSubTitle, 80)}
                </div>
                <FacebookButton onClick={this.props.onLogin} />
                <GoogleButton onClick={this.props.onLogin} />
                <EmailLoginForm
                    location={LOGIN_LAYOUT}
                    onClick={this.props.onLogin}
                    handleEmailInput={this.props.handleEmailInput}
                    handlePasswordInput={this.props.handlePasswordInput}
                    email={this.props.email}
                    password={this.props.password}
                    emailError={this.props.emailError}
                    passwordError={this.props.passwordError}
                    generalError={this.props.generalError}
                    loaded={this.props.loaded}
                />
                <div className="forgot-password-link">
                    <a
                        type="button"
                        role="button"
                        onKeyPress={this.props.resetPasswordCallBack}
                        onClick={this.props.resetPasswordCallBack}
                    >
                        Forgot Password{'>'}
                    </a>
                </div>
            </div>
        );
    }
}

LogInLayout.propTypes = {
    /**
     * callback to switch forms to reset password
     */
    resetPasswordCallBack: PropTypes.func.isRequired,
    /**
     * function to handle login
     */
    onLogin: PropTypes.func.isRequired,
    /**
     * function to handle email input
     */
    handleEmailInput: PropTypes.func.isRequired,
    /**
     * function to handle password input
     */
    handlePasswordInput: PropTypes.func.isRequired,
    /**
     * email value
     */
    email: PropTypes.string,
    /**
     * password value
     */
    password: PropTypes.string,
    /**
     * email error
     */
    emailError: PropTypes.string,
    /**
     * password error
     */
    passwordError: PropTypes.string,
    /**
     * general Error
     */
    generalError: PropTypes.string,
    /**
     * The secondary subtitle text
     */
    callToActionSubTitle: PropTypes.string,
    /**
     * state passed down on whether the call has finished processing
     */
    loaded: PropTypes.bool,
};

LogInLayout.defaultProps = {
    callToActionSubTitle:
        "We're millions of women sharing advice, finding jobs and making connections.",
    email: undefined,
    password: undefined,
    emailError: undefined,
    passwordError: undefined,
    generalError: undefined,
    loaded: PropTypes.bool,
};

export default LogInLayout;
