import PropTypes from 'prop-types';
import React from 'react';

import './ThreeRowModalLayout.scss';

export default class ThreeRowModalLayout extends React.PureComponent {
    static propTypes = {
        /*
         * components that will be rendered inside ThreeRowModalLayout
         */
        children: PropTypes.node.isRequired,
        /*
         * custom styling that would override the defaults
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    static Top = ({ children, className = '' }) => (
        <div className={`modal-top ${className}`}>{children}</div>
    );

    static Content = ({ children, className = '' }) => (
        <div className={`modal-main-content ${className}`}>{children}</div>
    );

    static Bottom = ({ children, className = '' }) => (
        <div className={`modal-bottom ${className}`}>{children}</div>
    );

    render() {
        const { className, children } = this.props;

        return (
            <div
                className={`component-Modals-Layout-ThreeRowModalLayout ${className}`}
            >
                {children}
            </div>
        );
    }
}
