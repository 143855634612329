/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';

import { FlowContext, getStepOfStepText } from './shared';

import { buildImagePath } from '../../../Content/User/UserDisplay/UserImage/UserImage';
import { constructCn } from '../../../../utilities/helperFunctions';

import './FlowShell.scss';

const closeIconTransforms = {
    default: 'c_scale,f_auto,q_auto,w_22,h_22',
};

const closeIconSrc = buildImagePath(
    'v1/Close_X_sw0ud2.png',
    'default',
    closeIconTransforms
);

const flowShellPropTypes = {
    children: PropTypes.node.isRequired,
    // schema for how the modal should flow
    tree: PropTypes.objectOf(PropTypes.object).isRequired,
    // step name/key to begin modal flow
    initialStep: PropTypes.string.isRequired,
    // common data useful for determining navigation (i.e. jobProfileSnippet)
    initialFlowData: PropTypes.objectOf(PropTypes.object),
    // fn to close modal. if provided, flow modal will show X close button otherwise will not
    closeModal: PropTypes.func,
    // title to display at the top of the modal in line with the close x button
    flowTitle: PropTypes.string,
    // override the default shell padding and set your own
    hasNoPadding: PropTypes.bool,
    // override title text with a "X of X" step counter title
    useCountTitle: PropTypes.bool,
    // In case Css Override its needed
    className: PropTypes.string,
};

export const FlowShell = ({
    children,
    tree,
    initialStep,
    initialFlowData,
    closeModal,
    flowTitle,
    hasNoPadding,
    useCountTitle,
    className,
}) => {
    // warn if initialStep prop is not a valid key in tree prop
    React.useEffect(() => {
        const allSteps = Object.keys(tree);
        if (!initialStep)
            console.warn(`No initial step provided in modal flow shell`);
        if (!allSteps.includes(initialStep)) {
            console.warn(
                `Initial step: ${initialStep} is not found as a key in the provided tree`
            );
        }
    }, [initialStep, tree]);

    // ensure all keys provided in tree have valid defined destination paths
    React.useEffect(() => {
        const allSteps = Object.keys(tree);
        Object.entries(tree).forEach(([key, destinations]) => {
            destinations.forEach((d) => {
                if (!allSteps.includes(d)) {
                    console.warn(
                        `Tree schema includes path to ${d} from ${key}, but ${d} is not defined in the tree schema. Resolve by adding ${d} to schema.`
                    );
                }
            });
        });
    }, [tree]);

    const [step, setStep] = React.useState(initialStep);
    const [flowData, setFlowData] = React.useState(initialFlowData);

    const getFlowControls = () => {
        const possibleSteps = tree[step];
        const destinations = possibleSteps.reduce((acc, possibleStep) => {
            const next = {
                [possibleStep]: (data = null) => {
                    setStep(possibleStep);
                    if (data)
                        setFlowData((prevData) => ({ ...prevData, ...data }));
                },
            };

            return { ...acc, ...next };
        }, {});

        destinations.nextStep = (data = null, onNext = null) => {
            if (onNext) onNext();
            if (possibleSteps.length === 0) {
                // no possible steps specified, close modal
                return closeModal();
            }

            // default to the first possibility when we have one or more destination for this step
            setStep(possibleSteps[0]);
            if (data) setFlowData((prevData) => ({ ...prevData, ...data }));
        };

        return destinations;
    };

    const stepOfStepText = getStepOfStepText(tree, step);

    const getTitle = () => {
        if (useCountTitle)
            return <h5 className="flow-title">{stepOfStepText}</h5>;
        if (flowTitle) return <h5 className="flow-title">{flowTitle}</h5>;
        return null;
    };

    const titleElement = getTitle();

    const closeModalIcon =
        closeModal !== null ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
                className="close-modal-icon"
                alt="close-modal-icon"
                src={closeIconSrc}
                onClick={closeModal}
                onKeyPress={closeModal}
            />
        ) : null;

    const titleBar = (
        <div className="modal-title-bar">
            <span className="icon-holder" />
            {titleElement}
            <span className="icon-holder">{closeModalIcon}</span>
        </div>
    );

    const shellCn = constructCn(
        'component-Modals-Flows-FlowShell',
        hasNoPadding && 'no-padding-shell'
    );

    return (
        <FlowContext.Provider
            value={{
                tree,
                step,
                setStep,
                getFlowControls,
                flowData,
                setFlowData,
            }}
        >
            <div className={`${className} ${shellCn}`}>
                {titleBar}
                {children}
            </div>
        </FlowContext.Provider>
    );
};

FlowShell.propTypes = flowShellPropTypes;

FlowShell.defaultProps = {
    flowTitle: '',
    closeModal: null,
    initialFlowData: {},
    hasNoPadding: false,
    useCountTitle: false,
    className: '',
};

FlowShell.displayName = 'FlowShell';

export default React.memo(FlowShell);
