/* eslint-disable no-console */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { MessageConsumer } from '../../../contexts/MessageContext';
// eslint-disable-next-line import/no-cycle
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
// eslint-disable-next-line import/no-cycle
import CommunityInviteButton from '../../Community/CommunityInviteButton/CommunityInviteButton';
import Button from '../../Input/Button/Button';
import { callToActions, APIS, SINGLE_USER_PROPTYPES } from './constants';

import './CommunityUserCallToActions.scss';

const initialState = {
    isAcceptButtonClicked: false,
    isFollowButtonClicked: false,
    isConnectButtonClicked: false,
    isRemoveButtonClicked: false,
    isIgnoreButtonClicked: false,
    isCancelButtonClicked: false,
    isUnfollowButtonClicked: false,
};

export class CommunityUserCallToActions extends React.PureComponent {
    /**
     * all buttons will be rendered in a row,
     * custom style in parent component with cssClass,
     * refer to Request, Invitation, and Connection for usage
     */
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const { clickedAction } = this.props;

        switch (clickedAction) {
            case 'ACCEPTED':
                this.setState({
                    isAcceptButtonClicked: true,
                    isConnectButtonClicked: true,
                });
                break;
            case 'DENIED':
                this.setState({ isIgnoreButtonClicked: true });
                break;
            case 'CONNECTED':
                this.setState({ isConnectButtonClicked: true });
                break;
            case 'FOLLOWED':
                this.setState({ isFollowButtonClicked: true });
                break;
            default:
                break;
        }
    }

    // this gets called when there is pagination
    // user is updated after a button is clicked
    componentDidUpdate(prevProps) {
        if (this.props.user.userId !== prevProps.user.userId) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ ...initialState });
        }
    }

    // onClick handlers
    // handleFollow and handleConnect are passed in to ensure
    // imply metrics are fired - otherwise, we do the API calls in here
    handleFollow = () => {
        const { isLoggedIn, onClickHandlers } = this.props;

        if (!isLoggedIn()) {
            return;
        }

        if (onClickHandlers.handleFollow) {
            if (onClickHandlers) {
                onClickHandlers.handleFollow();
            }
            this.setState({ isFollowButtonClicked: !!isLoggedIn() });
        } else {
            this.followConnection(isLoggedIn);
            if (onClickHandlers) {
                onClickHandlers.onFollowCallback();
            }
        }
    };

    handleUnfollow = () => {
        const { onClickHandlers } = this.props;
        this.unfollowUser();

        if (onClickHandlers.onUnfollowCallback) {
            onClickHandlers.onUnfollowCallback();
        }
    };

    handleConnect = () => {
        const { isLoggedIn, connectionStatus, onClickHandlers } = this.props;
        if (connectionStatus === 'invited') {
            this.acceptConnection();
        } else if (isLoggedIn() && onClickHandlers.handleConnect) {
            onClickHandlers.handleConnect();
            this.setState({ isConnectButtonClicked: !!isLoggedIn() });
        } else {
            this.addConnection(isLoggedIn);
        }

        if (onClickHandlers.onConnectCallback) {
            onClickHandlers.onConnectCallback();
        }
    };

    handleRemove = () => {
        this.removeConnection();

        if (this.props.onClickHandlers.onRemoveCallback) {
            this.props.onClickHandlers.onRemoveCallback();
        }
    };

    handleIgnore = () => {
        if (this.props.onClickHandlers.handleIgnore) {
            this.props.onClickHandlers.handleIgnore();
            this.setState({ isIgnoreButtonClicked: true });
        } else {
            this.ignoreInvitation();
        }

        if (this.props.onClickHandlers.onDenyCallback) {
            this.props.onClickHandlers.onDenyCallback();
        }
    };

    handleCancel = () => {
        if (this.props.onClickHandlers.handleCancel) {
            this.props.onClickHandlers.handleCancel();
            this.setState({ isCancelButtonClicked: true });
        } else {
            this.cancelRequest();
        }

        if (this.props.onClickHandlers.onCancelCallback) {
            this.props.onClickHandlers.onCancelCallback();
        }
    };

    getApiCall = (key) => {
        return APIS[key];
    };

    // API calls
    acceptConnection = async () => {
        try {
            const payload = { fromUserId: this.props.user.userId };
            const apiCall = this.getApiCall('API_ACCEPT_INVITATION');

            await axios.post(apiCall, payload);

            this.setState({
                isAcceptButtonClicked: true,
                isConnectButtonClicked: true,
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    addConnection = async (isLoggedIn) => {
        try {
            const payload = { toUserId: this.props.user.userId };
            const apiCall = this.getApiCall('API_REQUEST_CONNECTION');

            await axios.post(apiCall, payload);

            this.setState({ isConnectButtonClicked: !!isLoggedIn() });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    removeConnection = async () => {
        try {
            const payload = { data: { toUserId: this.props.user.userId } };
            const apiCall = this.getApiCall('API_REMOVE_CONNECTION');

            await axios.delete(apiCall, payload);

            this.setState({ isRemoveButtonClicked: true });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    followConnection = async (isLoggedIn) => {
        try {
            const payload = { userIdToFollow: this.props.user.userId };
            const apiCall = this.getApiCall('API_FOLLOW_USER');

            await axios.post(apiCall, payload);

            this.setState({ isFollowButtonClicked: !!isLoggedIn() });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    unfollowUser = async () => {
        try {
            const payload = {
                data: { userIdToUnfollow: this.props.user.userId },
            };
            const apiCall = this.getApiCall('API_UNFOLLOW_USER');

            await axios.delete(apiCall, payload);

            this.setState({ isUnfollowButtonClicked: true });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    ignoreInvitation = async () => {
        try {
            const payload = { data: { fromUserId: this.props.user.userId } };
            const apiCall = this.getApiCall('API_REJECT_INVITATION');

            await axios.delete(apiCall, payload);

            this.setState({ isIgnoreButtonClicked: true });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    cancelRequest = async () => {
        try {
            const payload = { data: { toUserId: this.props.user.userId } };
            const apiCall = this.getApiCall('API_CANCEL_REQUEST');

            await axios.delete(apiCall, payload);

            this.setState({ isCancelButtonClicked: true });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    shouldMessageButtonBeDisabled = (session) => {
        const { userCompaniesRoles, isCommunityManager } = session || {};
        const { connectionStatus } = this.props;
        const isNotConnected =
            !connectionStatus ||
            connectionStatus === 'requested' ||
            connectionStatus === 'invited' ||
            connectionStatus === 'invited-hidden';
        const isEmployer =
            userCompaniesRoles && !!Object.keys(userCompaniesRoles).length;

        return (
            !isEmployer &&
            !isCommunityManager &&
            isNotConnected &&
            !this.state.isAcceptButtonClicked
        );
    };

    getConnectCtaText = () => {
        const connectionStatus = this.props.connectionStatus || '';

        if (this.state.isConnectButtonClicked) {
            if (this.state.isAcceptButtonClicked) {
                return 'CONNECTED';
            }
            return 'REQUESTED';
        }
        // HOTFIX to cover "invited-hidden" and "requested-hidden" cases. Figure out the desired UX for these cases -MSH 091919
        if (connectionStatus.includes('invited')) {
            return 'INVITED';
        }

        if (connectionStatus.includes('requested')) {
            return 'REQUESTED';
        }

        return 'CONNECT';
    };

    updateConnectRequestState = () => {
        const { isLoggedIn } = this.props;
        this.setState({ isConnectButtonClicked: !!isLoggedIn() });
        this.props.onClickHandlers.onConnectCallback();
    };

    getFollowCtaText = (buttonToRenderType) => {
        switch (buttonToRenderType) {
            case 'FOLLOW_ANON':
                return this.props.isFollowing ||
                    this.state.isFollowButtonClicked
                    ? 'FOLLOWING_ANON'
                    : 'FOLLOW_ANON';
            case 'FOLLOW':
            default:
                return this.props.isFollowing ||
                    this.state.isFollowButtonClicked
                    ? 'FOLLOWING'
                    : 'FOLLOW';
        }
    };

    // Render CTA button based on user's action and initial rendering
    renderCallToActionButton = (ctaButton) => {
        const { wrapAuth } = this.props;
        const capitalizedCtaButton = ctaButton.toUpperCase();

        const { buttonMeta, callToActionHash, loginTrigger } = callToActions[
            capitalizedCtaButton
        ];

        switch (capitalizedCtaButton) {
            case 'INVITE_TO_GROUP': {
                const {
                    value,
                    cssClass,
                    isDisabled,
                    inputStyle,
                    buttonType,
                } = buttonMeta;
                return (
                    <CommunityInviteButton
                        group={this.props.group}
                        key={`${ctaButton}-${callToActionHash}`}
                        user={this.props.user}
                        buttonType={buttonType}
                        inviteType="group"
                        value={value}
                        className={cssClass}
                        disabled={isDisabled}
                        inputStyle={inputStyle}
                        callToActionHash={callToActionHash}
                        loginTrigger={loginTrigger}
                        metricEventType={`invite-to-group-click-${this.props.metricLocation}`}
                    />
                );
            }
            case 'INVITE_TO_GROUP_SOLID': {
                const {
                    value,
                    cssClass,
                    isDisabled,
                    inputStyle,
                    buttonType,
                } = buttonMeta;
                return (
                    <CommunityInviteButton
                        group={this.props.group}
                        key={`${ctaButton}-${callToActionHash}`}
                        user={this.props.user}
                        buttonType={buttonType}
                        inviteType="group"
                        value={value}
                        className={cssClass}
                        disabled={isDisabled}
                        inputStyle={inputStyle}
                        callToActionHash={callToActionHash}
                        loginTrigger={loginTrigger}
                        modalTriggerParameters={
                            this.props.modalTriggerParameters
                        }
                        metricEventType={`invite-to-group-click-${this.props.metricLocation}`}
                        userSearchMessage={this.props.userSearchMessage}
                    />
                );
            }
            case 'FOLLOW_ANON': // eslint-disable-line no-fallthrough
            case 'FOLLOW': {
                const ctaText = this.getFollowCtaText(capitalizedCtaButton);
                const { text, isDisabled, cssClass, inputStyle } = buttonMeta[
                    ctaText
                ];

                // If there are button styles passed in, apply those
                const customInputStyle = this.props.inputStyle;
                const buttonType = this.props.buttonType
                    ? this.props.buttonType
                    : cssClass;

                return wrapAuth(
                    callToActionHash,
                    <Button
                        key={`${ctaButton}-${callToActionHash}`}
                        type={buttonType}
                        disabled={isDisabled}
                        value={text}
                        inputStyle={`${inputStyle} ${customInputStyle}`}
                        onClick={this.handleFollow}
                    />,
                    { login_trigger: loginTrigger }
                );
            }
            case 'UNFOLLOW': {
                const ctaText = this.state.isUnfollowButtonClicked
                    ? 'UNFOLLOWED'
                    : 'UNFOLLOW';
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    ctaText
                ];

                return (
                    <Button
                        key={`${ctaButton}-${callToActionHash}`}
                        type={cssClass}
                        disabled={isDisabled}
                        value={text}
                        inputStyle={inputStyle}
                        onClick={this.handleUnfollow}
                    />
                );
            }
            case 'MESSAGE': {
                const { text, cssClass } = buttonMeta.MESSAGE;
                const { isLoggedIn, pageProps, inputStyle } = this.props;
                const shouldShowDrawer =
                    !this.state.isRemoveButtonClicked && isLoggedIn();

                return (
                    shouldShowDrawer &&
                    wrapAuth(
                        callToActionHash,
                        <MessageConsumer
                            key={`${ctaButton}-${callToActionHash}`}
                        >
                            {({ openMessageDrawer }) => {
                                const onMessageClick = () => {
                                    EmitMetric({
                                        misc_event_type:
                                            'user-profile-other-user-message-click',
                                        misc_event_count: 1,
                                    });
                                    openMessageDrawer(this.props.user.userId);
                                };

                                const onMessageHover = () => {
                                    EmitMetric({
                                        misc_event_type:
                                            'user-profile-other-user-message-click',
                                        misc_event_count: 1,
                                    });
                                };

                                return (
                                    <div
                                        onMouseEnter={
                                            this.shouldMessageButtonBeDisabled(
                                                pageProps.session
                                            )
                                                ? onMessageHover
                                                : null
                                        }
                                    >
                                        <Button
                                            type={cssClass}
                                            value={text}
                                            class="message-button"
                                            inputStyle={inputStyle}
                                            onClick={onMessageClick}
                                            disabled={this.shouldMessageButtonBeDisabled(
                                                pageProps.session
                                            )}
                                        />
                                    </div>
                                );
                            }}
                        </MessageConsumer>,
                        { login_trigger: loginTrigger }
                    )
                );
            }
            case 'INVITED_HIDDEN':
            case 'CONNECT': {
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    this.getConnectCtaText()
                ];

                const customInputStyle = this.props.inputStyle;
                const buttonType = this.props.buttonType
                    ? this.props.buttonType
                    : cssClass;

                const onClickHandler =
                    this.props.connectionStatus &&
                    this.props.connectionStatus.includes('invited')
                        ? this.acceptConnection
                        : null;

                /* TODO: pass thru connectionStatus to CommunityInviteButton and handle all cases
                    if connectionStatus == null, trigger the modal(this is the button's default behavior)
                    if connectionStatus == "invited", call the accept API
                    if connectionStatus == "requested", no action
                    if connectionStatus == "connected", no action
                */

                return (
                    <CommunityInviteButton
                        user={this.props.user}
                        inviteType="connection"
                        buttonType={buttonType}
                        disabled={isDisabled}
                        value={text}
                        callToActionHash={callToActionHash}
                        loginTrigger={loginTrigger}
                        inputStyle={`${inputStyle} ${customInputStyle}`}
                        onSuccess={this.updateConnectRequestState}
                        onClick={onClickHandler}
                    />
                );
            }
            case 'REMOVE': {
                const ctaText = this.state.isRemoveButtonClicked
                    ? 'REMOVED'
                    : 'REMOVE';
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    ctaText
                ];

                return (
                    <Button
                        key={ctaButton}
                        type={cssClass}
                        disabled={isDisabled}
                        value={text}
                        inputStyle={inputStyle}
                        onClick={this.handleRemove}
                    />
                );
            }
            case 'ACCEPT': {
                const ctaText = this.state.isAcceptButtonClicked
                    ? 'ACCEPTED'
                    : 'ACCEPT';
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    ctaText
                ];

                return (
                    !this.state.isIgnoreButtonClicked && (
                        <Button
                            key={ctaButton}
                            type={cssClass}
                            disabled={isDisabled}
                            value={text}
                            inputStyle={inputStyle}
                            onClick={this.handleConnect}
                        />
                    )
                );
            }
            case 'IGNORE': {
                const ctaText = this.state.isIgnoreButtonClicked
                    ? 'IGNORED'
                    : 'IGNORE';
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    ctaText
                ];
                return !this.state.isConnectButtonClicked ? (
                    <Button
                        key={ctaButton}
                        type={cssClass}
                        disabled={isDisabled}
                        value={text}
                        inputStyle={inputStyle}
                        onClick={this.handleIgnore}
                    />
                ) : null;
            }
            case 'CANCEL': {
                const ctaText = this.state.isCancelButtonClicked
                    ? 'CANCELLED'
                    : 'CANCEL';
                const { text, cssClass, isDisabled, inputStyle } = buttonMeta[
                    ctaText
                ];

                return (
                    <Button
                        key={ctaButton}
                        type={cssClass}
                        disabled={isDisabled}
                        value={text}
                        inputStyle={inputStyle}
                        onClick={this.handleCancel}
                    />
                );
            }
            default:
                return null;
        }
    };

    render() {
        return (
            <div
                className={`component-CallToAction-CommunityUserCallToActions ${this.props.wrapperCssClass}`}
            >
                {this.props.buttonsToRender.map((button) => (
                    <div key={`${button}`}>
                        {this.renderCallToActionButton(button)}
                    </div>
                ))}
            </div>
        );
    }
}

export default (props) => (
    <PageConsumer>
        {({ wrapAuth, pageProps, isLoggedIn }) => (
            <CommunityUserCallToActions
                {...props}
                wrapAuth={wrapAuth}
                pageProps={pageProps}
                isLoggedIn={isLoggedIn}
            />
        )}
    </PageConsumer>
);

CommunityUserCallToActions.propTypes = {
    /**
     * send buttons in order to render for each row/component
     * e.g. if connectionStatus === 'connected', set to
     * ['REMOVE', 'MESSAGE'] on search results page
     */
    buttonsToRender: PropTypes.arrayOf(
        PropTypes.oneOf([
            'ACCEPT',
            'CONNECT',
            'FOLLOW',
            'MESSAGE',
            'REMOVE',
            'IGNORE',
            'CANCEL',
            'UNFOLLOW',
            'INVITE_TO_GROUP',
            'INVITE_TO_GROUP_SOLID',
        ])
    ),
    /**
     * string for connection status, not required for Unfollowing
     */
    connectionStatus: PropTypes.string,
    /**
     * boolean for isFollowing for rendering follow button
     */
    isFollowing: PropTypes.bool,
    /**
     * boolean for message button hover on community feed
     */
    // eslint-disable-next-line react/no-unused-prop-types
    shouldShowMessageHover: PropTypes.bool,
    /**
     * custom wrapper css class
     */
    wrapperCssClass: PropTypes.string,
    /**
     * custom button input css styling
     */
    inputStyle: PropTypes.string,
    /**
     * button type to override the default type
     */
    buttonType: PropTypes.string,
    /**
     * int for userId of user action acted on
     */
    user: PropTypes.shape(SINGLE_USER_PROPTYPES),
    /**
     * object of click handlers fn for relations
     */
    onClickHandlers: PropTypes.shape({
        handleAccept: PropTypes.func,
        handleFollow: PropTypes.func,
        handleConnect: PropTypes.func,
        handleRemove: PropTypes.func,
        handleIgnore: PropTypes.func,
        handleCancel: PropTypes.func,
        onConnectCallback: PropTypes.func,
        onRemoveCallback: PropTypes.func,
        onFollowCallback: PropTypes.func,
        onUnfollowCallback: PropTypes.func,
        onDenyCallback: PropTypes.func,
        onCancelCallback: PropTypes.func,
    }),
    /**
     * function to see if user is logged in
     */
    isLoggedIn: PropTypes.func.isRequired,
    /**
     * Page Props, for feature flags and sessions and stuff
     */
    // eslint-disable-next-line react/forbid-prop-types
    pageProps: PropTypes.object,
    /**
     * pageContext.wrapAuth
     */
    wrapAuth: PropTypes.func.isRequired,
    /**
     * location from which metrics are fired
     */
    metricLocation: PropTypes.string,
    /**
     * group prop for group
     */
    group: PropTypes.shape({
        logo: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        name: PropTypes.string,
        id: PropTypes.number,
        hash: PropTypes.string,
        description: PropTypes.string,
    }),
    userSearchMessage: PropTypes.string,
    clickedAction: PropTypes.string,
    modalTriggerParameters: PropTypes.shape({
        modalName: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        modalData: PropTypes.object,
    }),
    onFollowCallback: PropTypes.func,
};

CommunityUserCallToActions.defaultProps = {
    group: null,
    userSearchMessage: null,
    buttonsToRender: [],
    onClickHandlers: {},
    shouldShowMessageHover: false,
    wrapperCssClass: '',
    metricLocation: '',
    inputStyle: '',
    buttonType: '',
    clickedAction: null,
    connectionStatus: null,
    pageProps: null,
    user: null,
    isFollowing: null,
    modalTriggerParameters: null,
    onFollowCallback: null,
};
