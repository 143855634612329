import React from 'react';
import SVGFromCloudinary from '../../../Elements/SVGFromCloudinary/SVGFromCloudinary';

import './Confirmation.scss';

const confirmationIconSrc = 'v1/Groups/confirmation-icon.svg';

export default class Confirmation extends React.PureComponent {
    render() {
        return (
            <div className="component-Modals-QuickApplyModal-Confirmation">
                <SVGFromCloudinary
                    cloudinaryID={confirmationIconSrc}
                    alt="confirmation-kite"
                    className="confirmation-kite"
                />
                <p className="confirmation-text">
                    Your quick apply application has been sent.
                </p>
                <p className="confirmation-subtext">
                    Tip: Add more details to your profile to really stand out.
                </p>
                <a
                    href="/users/profile"
                    className="confirmation-profile-button"
                >
                    Go to your profile
                </a>
            </div>
        );
    }
}
