import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import TypeaheadInputCard from '../../QuestionCards/TypeaheadInputCard/TypeaheadInputCard';
import './CompanySelect.scss';

export default class CompanySelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            company: props.name
                ? {
                      companyId: props.id,
                      companyName: props.name,
                      logo: props.logo,
                  }
                : null,
            disabled: false,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'company',
    };

    componentDidMount = () => {
        EmitMetric({
            misc_event_type: 'job-review-question-started-company-1',
            misc_event_count: 1,
        });

        const companyObject = this.state.company
            ? {
                  id: this.state.company.companyId,
                  name: this.state.company.companyName,
                  logo: this.state.company.logo,
              }
            : null;

        this.props.name
            ? this.props.onAnswer(
                  this.props.questionName,
                  companyObject,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    };

    onAnswer = (company) => {
        let nextAction = CANT_SUBMIT;
        if (company && company.companyName && company.companyName.length) {
            nextAction = CAN_SUBMIT;
        }

        const companyObject = {
            id: company.companyId,
            name: company.companyName,
            logo: company.logo,
        };

        this.props.onAnswer(
            this.props.questionName,
            companyObject,
            nextAction,
            false,
            this.props.nextStep
        );
    };

    render() {
        const props = {
            inputType: 'default',
            inputLabel: 'Company Name:',
            placeholder: 'Select one or add a new employer',
            defaultImg: {
                src:
                    this.props.logo && this.props.logo && this.props.logo.src
                        ? this.props.logo.src
                        : '/v1553090788/icons/company_2x.png',
                alt: '',
            },
            handleSelected: this.onAnswer,
            suggestionsArray: this.state.suggestionsArray,
            company: this.state.company,
            disabled: this.state.disabled,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-CompanySelect">
                <TypeaheadInputCard {...props} />
            </div>
        );
    }
}
