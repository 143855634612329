import PropTypes from 'prop-types';
import React from 'react';
import ToolTip from '../../Elements/ToolTip/ToolTip';

import './InputField.scss';

export default class InputField extends React.PureComponent {
    static propTypes = {
        /**
         * Id of the input field
         */
        idValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /**
         * Type of Input
         */
        type: PropTypes.string,
        /**
         * Name of InputField
         */
        name: PropTypes.string.isRequired,
        /**
         * border style: css border style object
         */
        border: PropTypes.object,
        /**
         * Text to be displayed inside a button
         */
        placeholder: PropTypes.string,
        /**
         * onFocus function
         */
        onFocus: PropTypes.func,
        /**
         * onBlur function ( == onFocusOut)
         */
        onBlur: PropTypes.func,
        /**
         * onChange handler
         */
        onChange: PropTypes.func,
        /**
         * onKeyPress handler
         */
        onKeyPress: PropTypes.func,
        /**
         * autoFocus
         */
        autoFocus: PropTypes.bool,
        /**
         * value to be display in input box
         */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // ref:PropTypes.node,
        handleInput: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        /**
         *  icon to show inside the input field
         */
        icon: PropTypes.oneOf([
            null,
            'location',
            'star',
            'search',
            'wing',
            'new-location',
            'magnifying-glass',
        ]),
        /**
         * children node to display on tooltip
         */
        tooltipContent: PropTypes.node,
        /**
         * qa data attribute
         */
        qaDataAttr: PropTypes.string,
        /*
         * classname to support different styling
         */
        classname: PropTypes.string,
        /*
         * dom attribute of autocomplete
         */
        autoComplete: PropTypes.string,
        /**
         * error message
         */
        errorMessage: PropTypes.string,
        /**
         * KeyDown event handler
         */
        onKeyDown: PropTypes.func,
        /**
         * KeyUp event handler
         */
        onKeyUp: PropTypes.func,
        /**
         *  Input value
         */
        inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /**
         * Whether the field should be disabled
         */
        disabled: PropTypes.bool,
        /**
         * Autocomplete setting
         */
        autocomplete: PropTypes.string,
        /**
         * Minimum numeric value, if field is a number type
         */
        min: PropTypes.number,
        /**
         * maximum numeric value, if field is a number type
         */
        max: PropTypes.number,
        hasError: PropTypes.bool,
        handleRawChangeEvent: PropTypes.func,
    };

    static defaultProps = {
        id: '',
        type: 'text ',
        border: null,
        placeholder: '',
        onFocus: () => {},
        onBlur: () => {},
        onChange: () => {},
        onKeyPress: () => {},
        onKeyDown: () => {},
        autoFocus: false,
        icon: null,
        tooltipContent: null,
        min: null,
        max: null,
        disabled: false,
        hasError: false,
        handleRawChangeEvent: null,
    };

    onChange = (e) => {
        const { handleRawChangeEvent } = this.props;
        if (handleRawChangeEvent) handleRawChangeEvent(e);
        this.props.onChange(e.target.value);
    };

    onKeydown = (e) => {
        this.props.onKeyDown(e.keyCode);
    };

    clear() {
        this.refs['input'].value = '';
    }

    renderTooltip = () => {
        return (
            <div className="tooltip-section">
                <div className="position-relative">
                    <ToolTip
                        show={this.props.tooltipContent !== null}
                        direction="up"
                    >
                        {this.props.tooltipContent}
                    </ToolTip>
                </div>
            </div>
        );
    };

    generateClassName = () => {
        if (this.props.hasError) {
            if (this.props.icon) {
                return ` hasIcon ${this.props.icon}Icon hasError`;
            }

            return ' hasNoIcon hasError';
        }

        return ' hasNoIcon';
    };

    render() {
        const inputClassName = this.generateClassName();

        return (
            <div className="component-Input-InputField">
                <input
                    disabled={this.props.disabled}
                    autoComplete={this.props.autocomplete}
                    id={this.props.idValue}
                    data-qa={this.props.qaDataAttr}
                    ref={this.props.handleInput}
                    className={`input ${inputClassName} ${this.props.classname}`.trim()}
                    name={this.props.name}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    style={this.props.border}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    onChange={this.onChange}
                    onKeyDown={this.onKeydown}
                    onKeyPress={this.props.onKeyPress}
                    autoFocus={this.props.autoFocus}
                    defaultValue={this.props.value}
                    value={this.props.inputValue}
                    min={this.props.min}
                    max={this.props.max}
                />
                {this.props.errorMessage ? (
                    <p className="text-left errorMessage">
                        {this.props.errorMessage}
                    </p>
                ) : null}
                {this.renderTooltip()}
            </div>
        );
    }
}
