import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { PageContext } from '../../../../contexts/ContextCreator';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';
import './ResumeDownloadButton.scss';

const RESUME_DOWNLOAD_API = '/api/job-seeker/download-resume';

export default class ResumeDownloadButton extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
        };

        this.downloadLink = React.createRef();
    }

    static propTypes = {
        /**
         * hash associated with user's job profile resume
         */
        userHash: PropTypes.string,
        /**
         * renders an optional element as target of click action
         */
        buttonElement: PropTypes.func,
        metricType: PropTypes.string,
        filename: PropTypes.string,
        /**
         * if present, this overrides the download API and loads resume data as an inline element
         */
        dataUrl: PropTypes.string,
        /**
         * event hash associated with user's resume
         */
        eventHash: PropTypes.string,
    };

    static defaultProps = {
        metricType: '',
        userHash: '',
        buttonElement: ({ onClick }) => <a onClick={onClick}>Download File</a>,
        dataUrl: '',
        filename: '',
        eventHash: '',
    };

    static contextType = PageContext;

    downloadResume = (e) => {
        e && e.preventDefault();

        this.setState({ errorMessage: '' }); // reset error message on each attempt

        const { userHash, eventHash, dataUrl, filename } = this.props;

        if (dataUrl) {
            this.downloadLink.current.download = filename || 'resume.pdf';
            this.downloadLink.current.href = dataUrl;
            this.downloadLink.current.click();
            this.props.metricType &&
                EmitMetric({
                    misc_event_type: this.props.metricType,
                    misc_event_count: 1,
                });
            return;
        }

        const apiUrl = eventHash
            ? `${RESUME_DOWNLOAD_API}/${userHash}?eventHash=${eventHash}`
            : `${RESUME_DOWNLOAD_API}/${userHash}`;

        axios
            .get(apiUrl)
            .then((res) => {
                const resumeUrl = res.data && res.data.url;

                if (!resumeUrl) {
                    this.setState({
                        errorMessage: 'Something went wrong. Please try again.',
                    });
                    return;
                }

                this.downloadLink.current.href = resumeUrl;
                this.downloadLink.current.click();
                this.props.metricType &&
                    EmitMetric({
                        misc_event_type: this.props.metricType,
                        misc_event_count: 1,
                    });
            })
            .catch((err) => {
                this.setState({ errorMessage: err });

                if (this.context.sentry) {
                    this.context.sentry.captureMessage(err);
                }
            });
    };

    render() {
        if (!this.props.userHash) {
            return null;
        }

        const ButtonElement = this.props.buttonElement;

        return (
            <div className="component-Users-JobProfile-ResumeDownloadButton">
                <ButtonElement onClick={this.downloadResume} />

                {this.state.errorMessage && (
                    <ErrorLabel error={this.state.errorMessage} />
                )}

                <a className="download-link" ref={this.downloadLink} />
            </div>
        );
    }
}
