export const joinMeta = {
    JOIN: {
        states: {
            join: {
                text: 'Join',
                className: 'secondary-blue-gray',
                isDisabled: false,
            },
            joined: {
                text: 'Joined',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    REQUEST: {
        states: {
            request: {
                text: 'Request',
                className: 'secondary-blue-gray',
                isDisabled: false,
            },
            requested: {
                text: 'Requested',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    LEAVE: {
        states: {
            leave: {
                text: 'Leave',
                className: 'tertiary-blue-gray',
                isDisabled: false,
            },
            left: {
                text: 'Left',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    ACCEPT: {
        states: {
            accept: {
                text: 'Accept',
                className: 'secondary-blue-gray',
                isDisabled: false,
            },
            accepted: {
                text: 'Accepted',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    DENY: {
        states: {
            deny: {
                text: 'Deny',
                className: 'tertiary-blue-gray',
                isDisabled: false,
            },
            denied: {
                text: 'Denied',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    LEAVE_DISABLED: {
        states: {
            leave: {
                text: 'Joined',
                className: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
};
