import PropTypes from 'prop-types';
import React from 'react';
import Gogirl from '../../../../Icons/JobOnboard/Gogirl';
import GroupItem from '../../../Network/GroupItem/GroupItem';

import './GroupsAdminOnboardingConfirmationScreen.scss';

export default class GroupsAdminOnboardingConfirmationScreen extends React.PureComponent {
    static propTypes = {
        adminGroup: PropTypes.shape({
            group: PropTypes.object,
            userRelation: PropTypes.object,
        }).isRequired,
    };

    static defaultProps = {
        adminGroup: {
            group: {},
            userRelation: { role: null },
        },
    };

    isMember = () =>
        this.props.adminGroup &&
        this.props.adminGroup.userRelation &&
        ['owner', 'admin', 'moderator', 'member'].includes(
            this.props.adminGroup.userRelation.role
        );

    render() {
        const { handleJoinAdminGroup, adminGroup } = this.props;

        const modalTriggerParameters = {
            modalName: 'adminOnboarding',
            modalData: {
                selectedStepIndex: 5,
                adminGroup,
                handleJoinAdminGroup,
            },
        };

        const isMember = this.isMember(adminGroup.userRelation.role);

        return (
            <div className="component-Groups-AdminOnboarding-Steps-GroupsAdminOnboardingConfirmationScreen">
                <GroupItem
                    group={adminGroup.group}
                    userRelation={adminGroup.userRelation}
                    groupNetworkType={'RECOMMENDED'}
                    openLinksInNewTab={true}
                    onClick={!isMember ? handleJoinAdminGroup : null}
                    disableLeave={true}
                    modalTriggerParameters={
                        !isMember ? modalTriggerParameters : null
                    }
                />

                <div className="content">{Gogirl}</div>
            </div>
        );
    }
}
