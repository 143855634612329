import PropTypes from 'prop-types';
import React from 'react';
import { JobContextProvider as JobProvider } from '../../../../../contexts/JobContext';

import { Consumer as PageConsumer } from '../../../../../contexts/PageContext';
import JobAlerts from '../../../../JobV3/JobAlerts/JobAlerts';
import ModalAbstraction from '../../ModalAbstraction/ModalAbstraction';

import './FollowedJobs.scss';

export default class FollowedJobs extends React.Component {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * display logic for modal
         */
        showModal: PropTypes.bool,
        /**
         * function to handle modal close
         */
        onClose: PropTypes.func,
    };

    static defaultProps = {
        showModal: false,
        onClose: null,
    };

    render() {
        return (
            <div className="component-CallToAction-LeftRailCTA-Layouts-FollowedJobs">
                <ModalAbstraction
                    title="Job Alerts"
                    description=""
                    showModal={this.props.showModal}
                    onClose={this.props.onClose}
                    allowScroll={true}
                >
                    <PageConsumer>
                        {({ jobNotifications }) => (
                            <JobProvider
                                jobNotifications={
                                    jobNotifications.notifications
                                }
                                content={[]}
                            >
                                <JobAlerts />
                            </JobProvider>
                        )}
                    </PageConsumer>
                </ModalAbstraction>
            </div>
        );
    }
}
