import React from 'react';

import FlowStep from '../../FlowStep';
import { SINGLE_EDUCATION_STEP_NAME } from '../constants';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { TextInput } from '../../../ModalParts/Inputs/TextInput/TextInput';
import { SchoolsTypeahead } from '../../../../Input/SchoolsTypeahead/SchoolsTypeahead';
import { Dropdown } from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import { degreeDropdownOpts } from '../QuickProfile/constants';
import { LocationSelector } from '../../../ModalParts/Inputs/LocationSelector/LocationSelector';
import CheckBox from '../../../../Content/Elements/CheckBox/CheckBox';
import {
    yearDropdownOpts,
    filterYearDropdownOpts,
} from '../../../ModalParts/Inputs/Dropdown/constants';
import { LongTextInput } from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import {
    EducationSubmitButton,
    EducationDeleteButton,
} from '../../SingleEducation/SingleEducation';

import './SingleEducation.scss';

export const SingleEducation = (props) => {
    const {
        stepFormProps,
        stepErrors,
        updateFormValues,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(SINGLE_EDUCATION_STEP_NAME);

    const {
        educationId,
        school,
        degree,
        location,
        field,
        isRemote,
        startYear,
        endYear,
        isCurrent,
        description,
    } = stepFormProps;

    React.useEffect(() => {
        if (isRemote) {
            const locationUpdater = updateFormValueAtPath(
                'singleEducation.location'
            );
            locationUpdater({ name: '', lat: null, lng: null });
        }

        if (isCurrent) {
            const endYearUpdater = updateFormValueAtPath(
                'singleEducation.endYear'
            );
            endYearUpdater('');
        }
    }, [isRemote, isCurrent, updateFormValueAtPath]);

    const buttonErrorHandler = updateFormValueAtPath('errors.singleEducation');

    return (
        <FlowStep name={SINGLE_EDUCATION_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-SingleEducation">
                <div className="single-education-fields">
                    <div className="field-wrapper">
                        <SchoolsTypeahead
                            error={stepErrors.school.name}
                            handleSelect={updateFormValueAtPath(
                                'singleEducation.school'
                            )}
                            className="education-school-typeahead"
                            items={school ? [school] : []}
                            allowAddNewItem
                            isRequired
                        />
                    </div>
                    <div className="field-wrapper">
                        <Dropdown
                            inputName="singleEducation.degree"
                            className="education-dropdown degree"
                            options={degreeDropdownOpts}
                            value={degree}
                            label="Degree*"
                            error={stepErrors.degree}
                            handleChange={updateFormValues}
                        />
                    </div>
                    <div className="field-wrapper">
                        <TextInput
                            inputName="singleEducation.field"
                            value={field}
                            label="Field of study"
                            placeholder="Ex. Business"
                            error={stepErrors.field}
                            handleChange={updateFormValues}
                        />
                    </div>
                    <div className="field-wrapper">
                        <LocationSelector
                            inputName="singleEducation.location"
                            label="School location*"
                            placeholder="City, State"
                            error={stepErrors.location.name}
                            value={location.name}
                            handleChange={updateFormValueAtPath}
                            disabled={isRemote}
                        />
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isRemote-toggle"
                            defaultChecked={isRemote}
                            label="Remote"
                            handleChange={updateFormValueAtPath(
                                'singleEducation.isRemote'
                            )}
                            className="remote-select"
                        />
                    </div>
                    <div className="field-wrapper">
                        <div className="year-select-wrapper">
                            <Dropdown
                                inputName="singleEducation.startYear"
                                handleChange={updateFormValues}
                                className="education-start-year"
                                options={yearDropdownOpts}
                                value={startYear}
                                defaultOptionText="Year"
                                label="Start year"
                                error={stepErrors.startYear}
                            />
                            <span className="year-dropdown-divider" />
                            <Dropdown
                                inputName="singleEducation.endYear"
                                handleChange={updateFormValues}
                                className="education-end-year"
                                defaultOptionText="Year"
                                options={filterYearDropdownOpts(startYear)}
                                disabled={isCurrent}
                                value={endYear}
                                label="End year (or expected)"
                                error={stepErrors.endYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isCurrent-toggle"
                            defaultChecked={isCurrent}
                            label="I am currently attending this school"
                            handleChange={updateFormValueAtPath(
                                'singleEducation.isCurrent'
                            )}
                            className="current-select"
                        />
                    </div>
                    <div className="field-wrapper">
                        <LongTextInput
                            inputName="singleEducation.description"
                            value={description}
                            label="Highlights"
                            type="textarea"
                            error={stepErrors.description}
                            handleChange={updateFormValues}
                            className="education-highlights"
                            rows={6}
                            maxChars={2000}
                        />
                    </div>
                </div>
                <div className="education-actions-box">
                    <EducationDeleteButton
                        educationId={educationId}
                        errorHandler={buttonErrorHandler}
                    />
                    <EducationSubmitButton
                        formValues={stepFormProps}
                        errorHandler={buttonErrorHandler}
                        clearErrors={clearStepErrors}
                    />
                </div>
            </div>
        </FlowStep>
    );
};

SingleEducation.propTypes = {};

SingleEducation.displayName = 'SingleEducation';

export default React.memo(SingleEducation);
