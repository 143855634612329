import React from 'react';

export const facebookIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g className="colored facebookIcon" fillRule="nonzero">
                <path
                    d="M16,0 L2,0 C0.895,0 0,0.895 0,2 L0,16 C0,17.105 0.895,18 2,18 L9.621,18 L9.621,11.039 L7.278,11.039 L7.278,8.314 L9.621,8.314 L9.621,6.309 C9.621,3.985 11.042,2.718 13.116,2.718 C13.815,2.716 14.513,2.752 15.208,2.823 L15.208,5.253 L13.78,5.253 C12.65,5.253 12.43,5.787 12.43,6.575 L12.43,8.31 L15.13,8.31 L14.779,11.035 L12.414,11.035 L12.414,18 L16,18 C17.105,18 18,17.105 18,16 L18,2 C18,0.895 17.105,0 16,0 Z"
                    id="Path"
                />
            </g>
        </g>
    </svg>
);
export const linkedInIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g className="colored linkedInIcon" fillRule="nonzero">
                <path
                    d="M16,0 L2,0 C0.895,0 0,0.895 0,2 L0,16 C0,17.105 0.895,18 2,18 L16,18 C17.105,18 18,17.105 18,16 L18,2 C18,0.895 17.105,0 16,0 Z M6,14 L3.477,14 L3.477,7 L6,7 L6,14 Z M4.694,5.717 C3.923,5.717 3.408,5.203 3.408,4.517 C3.408,3.831 3.922,3.317 4.779,3.317 C5.55,3.317 6.065,3.831 6.065,4.517 C6.065,5.203 5.551,5.717 4.694,5.717 Z M15,14 L12.558,14 L12.558,10.174 C12.558,9.116 11.907,8.872 11.663,8.872 C11.419,8.872 10.605,9.035 10.605,10.174 C10.605,10.337 10.605,14 10.605,14 L8.082,14 L8.082,7 L10.605,7 L10.605,7.977 C10.93,7.407 11.581,7 12.802,7 C14.023,7 15,7.977 15,10.174 L15,14 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
export const twitterIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g className="colored instagramColor" fillRule="nonzero">
                <path
                    d="M16,0 L2,0 C0.895,0 0,0.895 0,2 L0,16 C0,17.105 0.895,18 2,18 L16,18 C17.105,18 18,17.105 18,16 L18,2 C18,0.895 17.105,0 16,0 Z M14.05,6.514 C14.05,6.6 14.05,6.685 14.05,6.857 C14.05,10.114 11.564,13.886 7.021,13.886 C5.65,13.886 4.364,13.457 3.25,12.772 C3.421,12.772 3.679,12.772 3.85,12.772 C4.964,12.772 6.079,12.343 6.936,11.743 C5.822,11.743 4.965,10.972 4.622,10.029 C4.793,10.029 4.965,10.115 5.051,10.115 C5.308,10.115 5.48,10.115 5.737,10.029 C4.623,9.772 3.766,8.829 3.766,7.629 C4.109,7.8 4.452,7.886 4.88,7.972 C4.194,7.372 3.766,6.686 3.766,5.829 C3.766,5.4 3.852,4.972 4.109,4.629 C5.309,6.086 7.109,7.115 9.166,7.2 C9.166,7.029 9.08,6.857 9.08,6.6 C9.08,5.229 10.194,4.114 11.566,4.114 C12.252,4.114 12.937,4.371 13.366,4.885 C13.966,4.799 14.48,4.542 14.909,4.285 C14.738,4.885 14.309,5.314 13.795,5.656 C14.309,5.57 14.738,5.485 15.252,5.227 C14.907,5.743 14.479,6.171 14.05,6.514 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
export const instagramIcon = (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g className="instagramIcon colored" fillRule="nonzero">
                <path
                    d="M5,0 C2.239,0 0,2.239 0,5 L0,13 C0,15.761 2.239,18 5,18 L13,18 C15.761,18 18,15.761 18,13 L18,5 C18,2.239 15.761,0 13,0 L5,0 Z M15,2 C15.552,2 16,2.448 16,3 C16,3.552 15.552,4 15,4 C14.448,4 14,3.552 14,3 C14,2.448 14.448,2 15,2 Z M9,4 C11.761,4 14,6.239 14,9 C14,11.761 11.761,14 9,14 C6.239,14 4,11.761 4,9 C4,6.239 6.239,4 9,4 Z M9,6 C7.34314575,6 6,7.34314575 6,9 C6,10.6568542 7.34314575,12 9,12 C10.6568542,12 12,10.6568542 12,9 C12,7.34314575 10.6568542,6 9,6 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
