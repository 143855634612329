/**
 * Returns the next step for a job review
 * @param {object} currentAnswers
 * @param {array} skippedQuestions
 * @param {array} stepsProgressed
 * @param {array} substepsRequested
 */
export default (
    currentAnswers = null,
    skippedQuestions = [],
    stepsProgressed = [],
    substepsRequested = []
) => {
    if (
        !currentAnswers ||
        !currentAnswers.company ||
        !currentAnswers.company.id
    ) {
        return '1';
    }

    if (
        !currentAnswers.employerLocation ||
        !currentAnswers.employerLocation.name ||
        !currentAnswers.employerLocation.latitude ||
        !currentAnswers.employerLocation.longitude
    ) {
        return '2';
    }

    if (
        !currentAnswers.jobSatisfaction ||
        !currentAnswers.jobSatisfaction.rating
    ) {
        return '3';
    }

    if (
        !currentAnswers.treatedEqually ||
        currentAnswers.treatedEqually.treatedEqually === null
    ) {
        return '4';
    }

    if (
        currentAnswers.treatedEqually.treatedEqually === false &&
        (!currentAnswers.treatedEqually.unequalAttributes ||
            !currentAnswers.treatedEqually.unequalAttributes.length)
    ) {
        return '4a';
    }

    if (
        !currentAnswers.recommendation ||
        !currentAnswers.recommendation.recommend
    ) {
        return '5';
    }

    if (
        currentAnswers.recommendation.recommend === 'maybe' &&
        !currentAnswers.recommendation.recommendComment
    ) {
        return '5a';
    }

    if (!currentAnswers.makeBetter || !currentAnswers.makeBetter.makeBetter) {
        return '6';
    }

    if (
        currentAnswers.makeBetter.makeBetter === 'other' &&
        !currentAnswers.makeBetter.makeBetterComment
    ) {
        return '6a';
    }

    const flexComplete = flexibilityComplete(
        currentAnswers,
        skippedQuestions,
        stepsProgressed
    );

    if (
        !currentAnswers.workLifeBalance &&
        !flexComplete &&
        !stepsProgressed.includes('7')
    ) {
        return '7';
    }

    if (!flexComplete) {
        return '8';
    }

    if (
        flexComplete &&
        !currentAnswers.flexibility.flexibilityComment &&
        substepsRequested.includes('8a')
    ) {
        return '8a';
    }

    const comComplete = commentComplete(
        currentAnswers,
        skippedQuestions,
        stepsProgressed
    );
    if (!comComplete) {
        return '9';
    }

    const mgrComplete = managerComplete(
        currentAnswers,
        skippedQuestions,
        stepsProgressed
    );
    if (!mgrComplete) {
        return '10';
    }

    if (
        mgrComplete &&
        !currentAnswers.manager.managerComment &&
        substepsRequested.includes('10a')
    ) {
        return '10a';
    }

    if (
        !currentAnswers.ceoGenderDiversity ||
        !currentAnswers.ceoGenderDiversity.ceoGenderDiversity
    ) {
        return '11';
    }

    const leaveComplete = parentalLeaveComplete(
        currentAnswers,
        skippedQuestions,
        stepsProgressed
    );
    if (!leaveComplete && !currentAnswers.benefit) {
        return '12';
    }

    if (substepsRequested.includes('12a')) {
        return '12a';
    }

    if (!leaveComplete) {
        return '13';
    }

    if (substepsRequested.includes('13a')) {
        return '13a';
    }

    const [salComplete, bonComplete, posComplete, ethComplete, specComplete] = [
        salaryComplete(currentAnswers, skippedQuestions, stepsProgressed),
        bonusComplete(currentAnswers, skippedQuestions, stepsProgressed),
        positionComplete(currentAnswers, skippedQuestions, stepsProgressed),
        ethnicityComplete(currentAnswers, skippedQuestions, stepsProgressed),
        specialGroupComplete(currentAnswers, skippedQuestions, stepsProgressed),
    ];
    const answeredOptionalQuestion =
        salComplete ||
        bonComplete ||
        posComplete ||
        ethComplete ||
        specComplete;
    if (!stepsProgressed.includes('End') && !answeredOptionalQuestion) {
        return 'End';
    }

    if (specComplete) {
        return 'OptionalEnd';
    }

    if (ethComplete) {
        return '18';
    }

    if (posComplete) {
        return '17';
    }

    if (bonComplete) {
        return '16';
    }

    if (salComplete) {
        return '15';
    }

    return '14';
};

const flexibilityComplete = (
    currentAnswers,
    skippedQuestions,
    stepsProgressed
) => currentAnswers.flexibility && currentAnswers.flexibility.flexibilityRating;

const commentComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    currentAnswers.comment && currentAnswers.comment.comment;

const parentalLeaveComplete = (
    currentAnswers,
    skippedQuestions,
    stepsProgressed
) =>
    currentAnswers.parentalLeave &&
    currentAnswers.parentalLeave.offered !== null;

const managerComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    currentAnswers.manager && currentAnswers.manager.managerRating;

const salaryComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    skippedQuestions.includes('14') ||
    stepsProgressed.includes('14') ||
    !!(currentAnswers.salary && currentAnswers.salary.salary);

const bonusComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    skippedQuestions.includes('15') ||
    stepsProgressed.includes('15') ||
    !!(currentAnswers.bonus && currentAnswers.bonus.bonus !== null);

const positionComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    skippedQuestions.includes('16') ||
    stepsProgressed.includes('16') ||
    !!(currentAnswers.position && currentAnswers.position.jobTitle);

const ethnicityComplete = (currentAnswers, skippedQuestions, stepsProgressed) =>
    skippedQuestions.includes('17') ||
    stepsProgressed.includes('17') ||
    !!(currentAnswers.ethnicity && currentAnswers.ethnicity.ethnicity);

const specialGroupComplete = (
    currentAnswers,
    skippedQuestions,
    stepsProgressed
) =>
    skippedQuestions.includes('18') ||
    stepsProgressed.includes('18') ||
    !!(
        currentAnswers.specialGroup && currentAnswers.specialGroup.specialGroups
    );
