import PropTypes from 'prop-types';
import React from 'react';

import './SocialButtons.scss';

export default class SocialButtons extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSocialBlocked: false,
        };
    }

    static propTypes = {
        urlToShare: PropTypes.string,
    };

    static defaultProps = {
        urlToShare: 'https://www.fairygodboss.com',
    };

    componentDidMount() {
        if (
            document &&
            document.querySelector('#share-this-script') &&
            window &&
            window.__sharethis__
        ) {
            window.__sharethis__.initialize();
        }
        let script = document.createElement('script');
        script.src =
            'https://platform-api.sharethis.com/js/sharethis.js#property=5dc331fbf844c90012b926fc&product=inline-share-buttons';
        script.async = true;
        script.id = 'share-this-script';
        document.head.appendChild(script);

        document
            .getElementById('share-this-script')
            .addEventListener('load', () => {
                if (!window.__sharethis__) {
                    this.setState({ isSocialBlocked: true });
                }
            });
    }

    render() {
        const { isSocialBlocked } = this.state;

        return (
            <div className="component-CallToAction-SocialButtons">
                {isSocialBlocked ? (
                    <div className="adblock-text">
                        (It looks like you’re using an adblocker. Please
                        consider pausing it to get full use of this screen.)
                    </div>
                ) : (
                    <div
                        id={'inline-share-buttons'}
                        className="sharethis-inline-share-buttons"
                        data-url={this.props.urlToShare}
                    />
                )}
            </div>
        );
    }
}
