export const ROLE_TYPES = Object.freeze({
    UNAUTH: 'UNAUTH',
    USER_DEFAULT: 'USER_DEFAULT',
    USER_CONTRIBUTOR: 'USER_CONTRIBUTOR',
    USER_ADMIN: 'USER_ADMIN',
    EMPLOYER_DEFAULT: 'EMPLOYER_DEFAULT',
    EMPLOYER_RECRUITER: 'EMPLOYER_RECRUITER',
    EMPLOYER_ADMIN: 'EMPLOYER_ADMIN',
});

export const PAGE_DISPLAY_MODE = Object.freeze({
    USER_DEFAULT: 'USER_DEFAULT',
    EMPLOYER_DEFAULT: 'EMPLOYER_DEFAULT',
});

export const EMPLOYER_ROLES = Object.freeze({
    admin: 'admin',
    recruiter: 'recruiter',
    employee: 'employee',
    reviewer: 'reviewer',
});
