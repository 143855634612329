/* eslint-disable */
// TODO: fix linting errors
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { SnowplowClickTracker } from '../components/Analytics/Snowplow/SnowplowClickTracker';
import { buildJobImpressionsMetrics } from '../components/JobV3/Elements/JobListing/constants';

export const CONTENT_TYPE_COMMENT = 'community-comment';

export const VOTE_ACTION_VOTE = 'vote';
export const VOTE_ACTION_UNVOTE = 'unvote';
export const VOTE_TYPE_LIKE = 'like';
export const VOTE_TYPE_FLAG = 'flag';

export const ATTRIBUTION_MODE_NONE = 'none';
export const ATTRIBUTION_MODE_SINGLE = 'single';
export const ATTRIBUTION_MODE_CHERRYPICK = 'cherrypick';

export const USER_RELATIONSHIP_ATTRIBUTION_NONE = 'none';
export const USER_RELATIONSHIP_ATTRIBUTION_ON =
    'user_relationship_attribution_on';
export const API_URL_GET_USER_RELATIONS =
    '/api/user/user-community-profile-attribution';

export const LOAD_TYPE_PAGINATION = 'pagination';
export const LOAD_TYPE_INFINITE_SCROLL = 'infinite-scroll';

export default class AbstractContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.sentry = props.pageContext ? props.pageContext.sentry : null;

        let refs = {};
        const content = props.content;
        this.createHashRef(content, refs);
        this.state = {
            /**
             * Expose page props in context state
             */
            pageProps: props.pageContext && props.pageContext.pageProps,
            loggedIn: this.props.isLoggedIn,
            /**
             * A reference map of UUID hash to its content object
             */
            refs: refs,
            /**
             * author info for all content
             */
            authorRefs: {},
            /**
             * An object of sets of 'HASH->CONTENT OBJECTS' see above for how it looks
             */
            content: this.props.content,
            page: this.props.page,
            /**
             * ATTRIBUTION MODE is how we want to attribute users likes after page load
             */
            attributionMode: this.props.attributionMode,
            /**
             /**
             * Only needed in ATRIBUTION_MODE SINGLE and is the hash to do a full pull
             */
            singleAttributionContentHash: this.props
                .singleAttributionContentHash,
            /**
             * function to like/flag/save/unlike/unflag/unsave a piece of content
             */
            vote: this.vote,
            /**
             * function to get content by hash
             */
            getContent: (hash) => {
                return this.getContent(hash);
            },
            /**
             * function to get index by hash
             */ getIndex: (hash) => {
                return this.getIndex(hash);
            },
        };
    }

    static propTypes = {
        /**
         * See above of content structure
         */
        content: PropTypes.array,
        /**
         * see state for atribution mode
         */
        attributionMode: PropTypes.string,
        /**
         * ssee state for atibution mode
         */
        singleAttributionContentHash: PropTypes.string,
        /**
         * whether we should retrieve user relationship data;
         */
        userRelationshipMode: PropTypes.oneOf([
            USER_RELATIONSHIP_ATTRIBUTION_NONE,
            USER_RELATIONSHIP_ATTRIBUTION_ON,
        ]),
    };

    static defaultProps = {
        content: [],
        userRelationshipMode: USER_RELATIONSHIP_ATTRIBUTION_NONE,
        attributionMode: ATTRIBUTION_MODE_NONE,
        singleAttributionContentHash: null,
    };

    // TODO: DO NOT do attribution if not logged in
    /**
     * Attributes user likes/flags to content and comments
     * @returns {Promise.<void>}
     */
    attribution = async () => {
        this.updateAuthorRelationsToUser();

        if (this.props.pageContext && this.props.pageContext.isLoggedIn()) {
            if (this.props.attributionMode === ATTRIBUTION_MODE_SINGLE) {
                let content = this.getContent(
                    this.props.singleAttributionContentHash
                );
                let results = await this.attributionRequestSingle(
                    content.type,
                    content.hash
                );
                this.resolveAttribution(results);
            } else if (
                this.props.attributionMode === ATTRIBUTION_MODE_CHERRYPICK
            ) {
                let attributionResults = await this.attributionRequestAll();
                this.resolveAttributionAll(attributionResults);
            } else {
                console.error(
                    `${this.props.attributionMode} MODE NOT SUPPORTED YET!`
                );

                if (this.sentry) {
                    this.sentry.captureMessage(
                        `${this.props.attributionMode} MODE NOT SUPPORTED YET!`
                    );
                }
            }
        }
    };

    /**
     * api call to process attribution
     * @returns {Promise.<boolean>}
     */
    attributionRequestAll = async () => {
        let data = [];

        let getCommentHashes = (comments = []) => {
            let hashes = [];
            comments.map((comment) => {
                hashes.push(comment.hash);
                hashes.concat(getCommentHashes(comment.comments));
            });
            return hashes;
        };

        this.state.content.map((content) => {
            data.push({
                contentType: content.type,
                hash: content.hash,
                commentHashes: getCommentHashes(content.comments),
            });
        });

        try {
            let result = await axios.post(
                `/api/community/community-votes/attribute/contents`,
                { contentArray: data }
            );
            return result.data;
        } catch (error) {
            console.log('error', error);
            if (this.sentry) {
                this.sentry.captureException(error);
            }
            return false;
        }
    };

    resolveAttributionAll = (results) => {
        let data = [];
        let resolveComments = (comments) => {
            Object.keys(comments).map((commentHash) => {
                const comment = comments[commentHash];

                data.push({
                    hash: commentHash,
                    voteTypes: comment.voteTypes,
                    moderation: comment.moderation,
                    commentHashes: [],
                });
            });
        };

        results.map((r) => {
            data.push({
                hash: r.hash,
                voteTypes: r.voteTypes,
                moderation: r.moderation,
                commentHashes: [],
            });
            resolveComments(r.commentHashes);
        });

        this.resolveAttribution(data);
    };
    /**
     * @param results
     */
    resolveAttribution = (results) => {
        let updateSet = [];
        results.map((result) => {
            updateSet.push({
                hash: result.hash,
                updates: {
                    userLiked: result.voteTypes.includes(VOTE_TYPE_LIKE),
                    userFlagged: result.voteTypes.includes(VOTE_TYPE_FLAG),
                    editable: result.moderation.editable,
                    deletable: result.moderation.deletable,
                },
            });
            Object.keys(result.commentHashes).map((commentHash) => {
                let commentResult = result.commentHashes[commentHash];
                updateSet.push({
                    hash: commentHash,
                    updates: {
                        userLiked: commentResult.voteTypes.includes(
                            VOTE_TYPE_LIKE
                        ),
                        userFlagged: commentResult.voteTypes.includes(
                            VOTE_TYPE_FLAG
                        ),
                        editable: commentResult.moderation.editable,
                        deletable: commentResult.moderation.deletable,
                    },
                });
            });
        });

        this.updateContentBatch(updateSet);
    };

    /**
     * api call to process attribution
     * @param contentType
     * @param hash
     * @returns {Promise.<boolean>}
     */
    attributionRequestSingle = async (contentType, hash) => {
        try {
            let result = await axios.get(
                `/api/community/community-votes/attribute/${contentType}/${hash}`
            );
            return result.data;
        } catch (error) {
            console.log('error', error);
            if (this.sentry) {
                this.sentry.captureException(error);
            }
            return false;
        }
    };

    /**
     * Action to vote on or unvote on a piece of content
     * @param voteType
     * @param hash
     * @returns {Promise.<void>}
     */
    vote = async (hash, voteType) => {
        let content = this.getContent(hash);
        let index = this.getIndex(hash);

        const tileOrder = (this.state.page - 1) * this.state.limit + index + 1;

        const baseSnowPlow = {
            component: 'tile',
            componentTitleType: 'article',
            position: 'main',
            pageType: 'collection',
            targetPageSection: 'advice',
            pageSection: 'advice_landing',
            companyId: content.article?.companyId,
            tileEntityId: `${content.type}-${hash}`,
            tileType: 'article',
            tileOrder,
        };

        if (voteType === VOTE_TYPE_LIKE) {
            const clickMetrics = buildJobImpressionsMetrics({
                hash,
                component: 'button',
                company: content.company,
                tilePosition: tileOrder,
                jobSearchQueryID: this.props.searchQueryId,
                click: true,
            });

            if (
                await this.voteRequest(
                    content,
                    VOTE_TYPE_LIKE,
                    content.userLiked,
                    clickMetrics
                )
            ) {
                const likeDirection = content.userLiked ? -1 : 1;
                let likes = content.likes + likeDirection;
                this.updateContent(hash, {
                    likes: likes,
                    userLiked: content.userLiked !== true,
                });
                this.emitContentMetric(content, {
                    community_vote_type: VOTE_TYPE_LIKE,
                    vote: likeDirection,
                    snowPlow: baseSnowPlow,
                });
            }
        } else if (voteType === VOTE_TYPE_FLAG) {
            this.voteRequest(content, VOTE_TYPE_FLAG, content.userFlagged);
            this.updateContent(hash, { userFlagged: !content.userFlagged });
            this.emitContentMetric(content, {
                community_vote_type: VOTE_TYPE_FLAG,
                vote: content.userFlagged ? -1 : 1,
                snowPlow: baseSnowPlow,
            });
        } else {
            console.error('BAD VOTE TYPE:' + voteType);
            if (this.sentry) {
                this.sentry.captureMessage(
                    `${this.props.attributionMode} MODE NOT SUPPORTED YET!`
                );
            }
        }
    };

    /**
     * API CALL TO MAKE THE VOTE REQUEST
     * @param content
     * @param type
     * @param isUnvote
     * @returns {Promise.<boolean>}
     */
    voteRequest = async (
        content,
        type,
        isUnvote = false,
        clickMetrics = {}
    ) => {
        let verb = VOTE_ACTION_VOTE;
        let contentType = content.type;
        let hash = content.hash;
        if (isUnvote) {
            verb = VOTE_ACTION_UNVOTE;
        }
        try {
            const result = await axios.post(
                `/api/community/community-votes/${verb}/${contentType}`,
                {
                    type,
                    hash_id: hash,
                    value: 1,
                }
            );
            if (this.props.fromCompanyJobsPage && result?.data?.data) {
                SnowplowClickTracker({
                    ...clickMetrics,
                    buttonText: result?.data?.data.value ? 'Save' : 'Unsave',
                    buttonType: 'job_save',
                    fromCompanyJobsPage: this.props.fromCompanyJobsPage,
                    targetPageSection: null,
                });
            }
            return true;
        } catch (error) {
            console.log('ERROR', error);
            if (this.sentry) {
                this.sentry.captureException(error);
            }
            return false;
        }
    };

    /**
     *
     * Allows you to do a partial update of a pice of content
     * Updates that content and triggers a new state
     *
     * @param hash - The hash of the content
     * @param updates
     */
    updateContent = (hash, updates) => {
        this.updateContentBatch([{ hash, updates }]);
    };

    /**
     * Updates multiple things at once
     * @param hashUpdatesArray -  [{hash: 'theHashKey', updates: {'prop': 'newVal'}}]
     */
    updateContentBatch = (hashUpdatesArray) => {
        let newState = Object.assign({}, this.state);
        let newRefs = {};
        this.createHashRef(newState.content, newRefs);

        hashUpdatesArray.map((hashUpdate) => {
            let hash = hashUpdate.hash;
            let updates = hashUpdate.updates;

            // Run through all items found in the hash ref If not found, ignore
            // Current attribution API sends every comment hash
            Object.keys(updates).map((key) => {
                if (newRefs[hash]) {
                    newRefs[hash][key] = updates[key];
                }

                // Needed because it has to update the hash ref on deleting comments in the feed
                if (this.state.refs[hash]) {
                    this.state.refs[hash][key] = updates[key];
                }
            });
        });

        this.setState({ content: newState.content });
    };
    /**
     * Creates a non referenced object of the content of a given hash
     * @param hash - The hash of the content
     * @returns {*} - obj of the content
     */
    getContent = (hash) => {
        return Object.assign({}, this.state.refs[hash]);
    };

    getIndex = (hash) => {
        return Object.keys(this.state.refs).indexOf(hash);
    };

    emitMetricByHash = async (hash, uniqueMetrics) => {
        let content = this.getContent(hash);
        this.emitContentMetric(content, uniqueMetrics);
    };

    emitMetric = (hash, metric) => this.emitMetricByHash(hash, metric);

    /**
     * Function that you pase the content object into as well as a param to create a refrence map
     * Use for easily looking up content and editing it in place
     * @param content
     * @param refs
     */
    createHashRef = (content, refs) => {
        let doContentAssignment = (obj) => {
            refs[obj.hash] = obj;
            refs[obj.hash].userFlagged = obj.userFlagged
                ? obj.userFlagged
                : false;
            refs[obj.hash].userLiked = obj.userLiked ? obj.userLiked : false;
            refs[obj.hash].contentDate = obj.contentDate;
        };

        let doCommentAssignment = (
            obj,
            refs,
            parentType,
            contentHash,
            contentType,
            parentHash
        ) => {
            doContentAssignment(obj);

            refs[obj.hash].type = CONTENT_TYPE_COMMENT;
            refs[obj.hash].replyOpened = obj.replyOpened
                ? obj.replyOpened
                : false;
            refs[obj.hash].getTotalCount = function () {
                let TotalCommentCount = this.commentCount;
                if (this.comments) {
                    this.comments.forEach((comment) => {
                        TotalCommentCount += comment.getTotalCount();
                    });
                }
                return TotalCommentCount;
            }.bind(refs[obj.hash]);
            refs[obj.hash].isNew = false;
            refs[obj.hash].contentDate = obj.contentDate;
            refs[obj.hash].parentContentHash = contentHash;
            refs[obj.hash].parentContentType = contentType;
            refs[obj.hash].parentType = parentType;
            refs[obj.hash].parentHash = parentHash;
        };

        let createChildRef = (
            children,
            refs,
            contentHash,
            contentType,
            parentHash
        ) => {
            children.map((child) => {
                doCommentAssignment(
                    child,
                    refs,
                    CONTENT_TYPE_COMMENT,
                    contentHash,
                    contentType,
                    parentHash
                );
                if (child.comments && child.comments.length > 0) {
                    createChildRef(
                        child.comments,
                        refs,
                        contentHash,
                        contentType,
                        child.hash
                    );
                }
            });
        };

        content.map((c) => {
            doContentAssignment(c);
            if (c.comments && c.comments.length > 0) {
                c.comments.map((comment) => {
                    doCommentAssignment(
                        comment,
                        refs,
                        c.type,
                        c.hash,
                        c.type,
                        c.hash
                    );
                    if (comment.comments && comment.comments.length > 0) {
                        createChildRef(
                            comment.comments,
                            refs,
                            c.hash,
                            c.type,
                            comment.hash
                        );
                    }
                });
            }
        });
    };

    isLoggedIn = () => this.state.loggedIn;

    setLoggedIn = (isLoggedIn) => {
        this.setState({ loggedIn: isLoggedIn });
    };

    updateAuthorRelationsToUser = () => {};

    componentDidMount() {
        this.updateAuthorRelationsToUser();

        if (
            this.props.attributionMode !== ATTRIBUTION_MODE_NONE &&
            this.state.content.length > 0
        ) {
            this.attribution().catch((error) => {
                console.error(error);
                if (this.sentry) {
                    this.sentry.captureException(error);
                }
            });
        }
    }
}
