import React from 'react';
import PropTypes from 'prop-types';

import './ProfileIdentityModal.scss';
import CloudinaryImageUpload from '../../../CloudinaryImageUpload/CloudinaryImageUpload';
import FormElementWithLabel from '../../../Input/FormElementWithLabel/FormElementWithLabel';
import InputField from '../../../Input/InputField/InputField';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import UserImage from '../../../Content/User/UserDisplay/UserImage/UserImage';
import { PageContext } from '../../../../contexts/ContextCreator';
import SVGFromCloudinary from '../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import Button from '../../../Input/Button/Button';
import axios from 'axios';
import { buildSchemaShape } from '../../../Includes/FormSchema/UserProfileSchema';
import { ValidateForm } from '../../../Includes/FormValidation';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';
import { MYSTERY_WOMAN_IMAGE_PATH } from '../../../../utilities/images';
import ModalContainer from '../../ModalParts/ModalContainer/ModalContainer';
import Close from '../../../Icons/Close';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';

const cloudinaryWidgetOptions = {
    defaultCoundinaryID: 'icons/user-default',
    uploadPreset: 'user-profile',
    forceAspectRatio: 1,
    imageMinWidth: 160,
    imageMinHeight: 160,
};

//IF you need to add or change inputs, you can do it here!
//=====================================================
const FormElementValues = [
    {
        key: 'name',
        component: 'input',
        label: 'Your name*',
        placeholder: '',
        inputMaxLength: 100,
    },
    {
        key: 'credential',
        component: 'input',
        label: 'Your tagline',
        placeholder: 'For ex: "Data Engineer in Chicago"',
        inputMaxLength: 48,
    },
    {
        key: 'aboutMe',
        component: 'textarea',
        label: 'About you',
        placeholder:
            'Your elevator pitch = What you do + how you do it + the results you achieve.',
        inputMaxLength: 200,
    },
    {
        key: 'website',
        component: 'input',
        label: 'Your website',
        placeholder: 'https://www.portfolio.com/yourname',
        inputMaxLength: 200,
    },
];
export default class ProfileIdentityModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || '',
            logo: props.logo || '',
            credential: props.credential || '',
            aboutMe: props.aboutMe || '',
            website: props.website || '',
            errorname: null,
            errorlogo: null,
            errorcredential: null,
            erroraboutMe: null,
            errorwebsite: null,
            errorgeneral: null,
        };
    }

    static propTypes = {
        name: PropTypes.string,
        logo: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        credential: PropTypes.string,
    };

    static defaultProps = {};

    static contextType = PageContext;

    renderFormElementWithLabel = (elementProps) => {
        //PROPS FOR FieldElementWithLabel
        //==============================================
        const formElementProps = {
            fieldLabelProps: {
                label: elementProps.label,
                shouldShowTooltip: false,
            },
            elementValue: this.state[elementProps.key],
            inputMaxLength: elementProps.inputMaxLength,
            error: this.state[`error${elementProps.key}`],
        };

        //Basic props for input field and textarea
        //==============================================
        const basicElementProps = {
            placeholder: elementProps.placeholder,
            name: elementProps.key,
            onChange: (value) =>
                this.handleInputChange(elementProps.key, value),
        };

        //Type of input
        //==============================================
        let component = (
            <InputField
                inputValue={this.state[elementProps.key]}
                {...basicElementProps}
            />
        );
        if (elementProps.component === 'textarea') {
            component = (
                <TextAreaInput
                    value={this.state[elementProps.key]}
                    {...basicElementProps}
                />
            );
        }

        return (
            <FormElementWithLabel
                haveErrorSpace={true}
                key={`${elementProps.key}-form-element`}
                {...formElementProps}
                className={`${elementProps.key}-form-element`}
            >
                {component}
            </FormElementWithLabel>
        );
    };

    handleInputChange = (key, value) => {
        this.setState({
            [key]: value,
            [`error${key}`]: null,
        });
    };

    errorSetStateFunction = (error) => {
        this.setState(error);
    };

    onUploadUserImage = (cloudinaryID) => {
        return axios
            .post('/api/user/update-logo', { cloudinary_id: cloudinaryID })
            .then((res) => {
                this.setState({
                    logo: {
                        src: cloudinaryID,
                        alt: `${this.state.username} image`,
                    },
                });
            })
            .catch((err) => this._updateErrorMsg(err));
    };

    handleFormSubmit = async (event) => {
        event && event.preventDefault();
        const { name, logo, credential, aboutMe, website } = this.state;
        const { onSuccess } = this.props;
        const formValues = {
            name: name.length ? name : null,
            logo: logo && logo.src && logo.src.length ? logo.src : null,
            credential: credential.length ? credential : null,
            aboutMe: aboutMe.length ? aboutMe : null,
            website: website.length ? website : null,
        };

        const userProfileIdentityUpdateSchema = buildSchemaShape(formValues);

        const isValid = await ValidateForm(
            formValues,
            userProfileIdentityUpdateSchema,
            this.errorSetStateFunction
        );
        if (!isValid) {
            return;
        }

        try {
            await axios.patch('/api/user/profile', formValues);
            const updatedValues = {
                name,
                logo,
                credential,
                aboutMe,
                website,
            };

            onSuccess(updatedValues);
            this.context.closeModal();
        } catch (e) {
            this.errorSetStateFunction({
                errorgeneral: 'An unexpected error has occured.',
            });
            console.error(e);
            throw e;
        }
    };

    render() {
        const isDefaultLogo =
            this.state.logo && this.state.logo.src === MYSTERY_WOMAN_IMAGE_PATH;
        const onHoverClass = isDefaultLogo
            ? 'default-logo-styling'
            : 'has-image-styling';
        const EditImageHoverText = isDefaultLogo ? 'Add' : 'Edit';
        return (
            <div className="component-Modals-User-ProfileIdentityModal">
                <form onSubmit={this.handleFormSubmit}>
                    <SVGIconWrapper
                        handleClickSVGWrapper={this.context.closeModal}
                        SVGWrapperCssClass="svg-close-icon"
                    >
                        {Close}
                    </SVGIconWrapper>
                    <CloudinaryImageUpload
                        {...cloudinaryWidgetOptions}
                        successCallback={this.onUploadUserImage}
                        className={`${onHoverClass}`}
                    >
                        {isDefaultLogo ? (
                            <SVGFromCloudinary
                                cloudinaryID={this.state.logo.src}
                            />
                        ) : (
                            <UserImage image={this.state.logo} iconSize={130} />
                        )}
                        <span className="image-hover-text">
                            {EditImageHoverText}
                            <br />
                            Image
                        </span>
                    </CloudinaryImageUpload>
                    {FormElementValues.map((elementProp) => {
                        return this.renderFormElementWithLabel(elementProp);
                    })}
                    <ErrorLabel error={this.state.errorgeneral} />
                    <div className={'button-section'}>
                        <div className="button-width">
                            <Button
                                type={'solid'}
                                className={'confirm-button'}
                                formAction="submit"
                                value={'Done'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
