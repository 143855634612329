import PropTypes from 'prop-types';
import React from 'react';

import './PaginationNav.scss';

export default class PaginationNav extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        showPrevBtn: PropTypes.bool,
        showNextBtn: PropTypes.bool,
        onNext: PropTypes.func,
        onPrev: PropTypes.func,
    };

    static defaultProps = {
        showPrevBtn: true,
        showNextBtn: false,
        PrevText: `< Previous`,
        NextText: `Next >`,
        prevDisabled: false,
        nextDisabled: false,
    };

    render() {
        return (
            <div className="component-ReviewSurvey-Components-PaginationNav">
                {this.props.showPrevBtn ? (
                    <button
                        className="button-box prev"
                        disabled={this.props.prevDisabled}
                        onClick={this.props.onPrev}
                    >
                        {this.props.PrevText}
                    </button>
                ) : (
                    <button className="button-box" />
                )}
                {this.props.showNextBtn ? (
                    <button
                        className="button-box next"
                        disabled={this.props.nextDisabled}
                        onClick={this.props.onNext}
                    >
                        {this.props.NextText}
                    </button>
                ) : null}
            </div>
        );
    }
}
