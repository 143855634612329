import PropTypes from 'prop-types';
import React from 'react';

import './UnreadCount.scss';

export default class UnreadCount extends React.PureComponent {
    static propTypes = {
        /**
         * count of unread message in a channel
         */
        unread: PropTypes.number.isRequired,
    };

    static defaultProps = {
        unread: 0,
    };

    getUnreadCount = (unread) => (unread > 24 ? '25+' : String(unread));

    render() {
        const { unread } = this.props;
        const unreadStyle = unread > 0 ? 'unread' : '';
        const messageString = unread === 1 ? 'message' : 'messages';

        return (
            <div
                className={`component-Messages-ChannelList-Channel-UnreadCount ${unreadStyle}`}
            >
                {this.getUnreadCount(unread)} new {messageString}
            </div>
        );
    }
}
