import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import MessageCard from '../../QuestionCards/MessageCard/MessageCard';

export default class Abandoned extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        buttonStyle: PropTypes.string,
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        isAnimated: PropTypes.bool,
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        payloadKey: PropTypes.string,
        hasAuthWrapper: PropTypes.bool,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        callRulesEngine: PropTypes.func,
        closeSurvey: PropTypes.func,
        removeSurveyCookie: PropTypes.func,
        answerPayload: PropTypes.object,
        loggedIn: PropTypes.bool,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        hasAuthWrapper: false,
        buttonStyle: 'normal',
        buttonData: [
            {
                title: 'Not now',
                subtitle: "I'll come back later",
                value: false,
            },
            {
                title: 'Yes',
                value: true,
            },
        ],
        img: {
            src: '/icons/Continue_2x',
            alt: '',
        },
        isAnimated: false,
        onAnswer: (...args) => console.log(...args),
        questionName: 'abandoned',
        payloadKey: 'abandon',
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-reminder',
            misc_event_count: 1,
        });
    }

    handleUpdate = async (data) => {
        const response = data[0] || false;

        if (response === true) {
            EmitMetric({
                misc_event_type: 'job-review-resumed',
                misc_event_count: 1,
            });
            const nextStep = await this.props.callRulesEngine();
            this.props.setSurveyCookie();

            let step = nextStep;

            if (this.props.company) {
                if (
                    (this.props.answerPayload.jobReview.company &&
                        this.props.answerPayload.jobReview.company.id) !==
                    (this.props.company && this.props.company.companyId)
                ) {
                    await this.props.abandonSurvey();
                    step = 'Intro';
                }
            }

            if (step === 'OptionalEnd' && !this.props.loggedIn) {
                this.props.onSubmit(step, null, null, null, null);
            } else {
                this.props.getCurrentStep(step);
            }
        } else {
            this.props.removeSurveyCookie();
            await this.props.abandonSurvey();
            this.props.closeSurvey();
        }
    };

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Abandoned">
                <MessageCard {...this.props} onClick={this.handleUpdate} />
            </div>
        );
    }
}
