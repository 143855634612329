import PropTypes from 'prop-types';
import React from 'react';
import { prettifyNumber } from '../../../utilities/helperFunctions';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';
import './MemberCountDisplay.scss';

export default class MemberCountDisplay extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * Member count
         */
        count: PropTypes.number,
        /**
         * Array of objects containing each member image src and alt text
         */
        previewMembers: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            })
        ),
        /**
         * Text to override default 'member/members' label text
         */
        overrideText: PropTypes.string,
    };

    static defaultProps = {
        count: 0,
        previewMembers: [],
        overrideText: '',
    };

    renderMemberImages = () => {
        const { previewMembers } = this.props;
        const injectedTransform = {
            tiny: 'c_scale,f_auto,q_auto,w_30,h_30',
        };

        const members = previewMembers.map((member, idx) => {
            if (!member.src) member.src = 'Avatar_Defaultsvg_cbfqax.svg';
            if (!member.alt) member.alt = 'default logo';

            const cloudinarySrc = buildImagePath(
                member.src,
                'tiny',
                injectedTransform
            );
            const key = `${idx}-${member.src.slice(0, -3)}`;
            return {
                src: cloudinarySrc,
                alt: member.alt,
                key,
            };
        });

        return members.map((member) => {
            const { src, alt, key } = member;

            return (
                <img key={key} src={src} alt={alt} className="member-preview" />
            );
        });
    };

    getUnit = () => (this.props.count > 1 ? 'members' : 'member');

    render() {
        const { count, overrideText } = this.props;
        const labelText = !overrideText ? this.getUnit() : overrideText; // use default member text if no override text provided

        return (
            <div className="component-Groups-MemberCountDisplay">
                {this.renderMemberImages()}
                <span className="count">
                    {prettifyNumber(count)} {labelText}
                </span>
            </div>
        );
    }
}
