import PropTypes from 'prop-types';
import React from 'react';
/* COMPONENTS */
import { truncateWithEllipsis } from '../../../../utilities/ui_utilities';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import FGBLabel from '../../../Elements/FGBLabel/FGBLabel';
import SVGFromCloudinary from '../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import { ResumeDataPropShape } from '../../../Includes/JobProfileProps';
import Button from '../../../Input/Button/Button';
import ResumeDownloadButton from '../../../Users/JobProfile/ResumeDownloadButton/ResumeDownloadButton';
import { getMMDDYYTimestamp } from '../../../Utilities/TimeFunctions';
import { PageContext } from '../../../../contexts/ContextCreator';

import './ResumeUpload.scss';
import ResponsivePicture from '../../../Elements/ResponsivePicture/ResponsivePicture';

const metrics = {
    UPLOAD_INITIAL_RESUME: 'job-profile-initial-upload-resume-click',
    UPLOAD_RESUME: 'job-profile-upload-resume-click',
    UPLOAD_REPLACE_RESUME: 'job-profile-upload-resume-and-replace-click',
};

export default class ResumeUpload extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            shouldReplace: true,
            fileTooLargeError: false,
        };
    }

    openFilePicker = (shouldReplace, metric) => () => {
        EmitMetric({ misc_event_type: metric, misc_event_count: 1 });
        const fileSelector = document.querySelector('input[type=file]');

        if (!fileSelector) {
            return;
        }

        fileSelector.value = '';

        this.setState({ shouldReplace }, () => fileSelector.click());
    };

    handleResumeUpload = () => {
        const file = document.querySelector('input[type=file]').files[0];
        if (file.size > 10048576) {
            this.setState({
                fileTooLargeError: true,
            });
        } else {
            this.setState(
                {
                    fileTooLargeError: false,
                },
                () => this.props.onResumeUpload(file, this.state.shouldReplace)
            );
        }
    };

    renderDownloadResumeLink = (fileName) => ({ onClick }) => {
        return (
            <div className="resume-name" onClick={onClick}>
                {truncateWithEllipsis(fileName, 22)}
            </div>
        );
    };

    renderButton = () => {
        const { location, fileName, resumeUploadHeaderType } = this.props;
        const zeroStateResumeUploadButtonText =
            resumeUploadHeaderType === 'single-job'
                ? 'Start by uploading your resume'
                : 'Upload resume';

        // On JobProfile page and has resume
        if (location === 'jobProfile' && fileName) {
            return (
                <React.Fragment>
                    <Button
                        name="upload-resume"
                        value="Upload new resume"
                        type="secondary-blue-gray"
                        onClick={this.openFilePicker(
                            false,
                            metrics.UPLOAD_RESUME
                        )}
                        className="upload-resume"
                        isLoading={
                            this.props.isLoading && !this.state.shouldReplace
                        }
                        disabled={
                            this.props.isLoading && !this.state.shouldReplace
                        }
                    />
                    <Button
                        name="upload-replace-resume"
                        value="Upload new resume and replace all fields"
                        type="tertiary-blue-gray"
                        onClick={this.openFilePicker(
                            true,
                            metrics.UPLOAD_REPLACE_RESUME
                        )}
                        className="upload-and-replace"
                        isLoading={
                            this.props.isLoading && this.state.shouldReplace
                        }
                        disabled={
                            this.props.isLoading && this.state.shouldReplace
                        }
                    />
                    <input
                        type="file"
                        name="file-uploader"
                        accept="application/pdf"
                        onChange={this.handleResumeUpload}
                    />
                </React.Fragment>
            );
        }

        if (location === 'modal') {
            // if we have no file name, show purple button
            return !fileName ? (
                <React.Fragment>
                    <Button
                        name="upload-initial-resume"
                        value="Upload resume"
                        type="purple-medium-white"
                        onClick={this.openFilePicker(
                            true,
                            metrics.UPLOAD_INITIAL_RESUME
                        )}
                        className="upload-resume-empty"
                        isLoading={this.props.isLoading}
                        disabled={this.props.isLoading}
                    />
                    <input
                        type="file"
                        name="file-uploader"
                        accept="application/pdf"
                        onChange={this.handleResumeUpload}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Button
                        name="upload-replace-resume"
                        value="Upload new resume"
                        type="secondary-blue-gray"
                        onClick={this.openFilePicker(
                            true,
                            metrics.UPLOAD_REPLACE_RESUME
                        )}
                        className="upload-and-replace"
                        isLoading={
                            this.props.isLoading && this.state.shouldReplace
                        }
                        disabled={
                            this.props.isLoading && this.state.shouldReplace
                        }
                    />
                    <input
                        type="file"
                        name="file-uploader"
                        accept="application/pdf"
                        onChange={this.handleResumeUpload}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Button
                    name="upload-initial-resume"
                    value={zeroStateResumeUploadButtonText}
                    type="purple-medium-white"
                    onClick={this.openFilePicker(
                        true,
                        metrics.UPLOAD_INITIAL_RESUME
                    )}
                    className="upload-resume-empty"
                    isLoading={this.props.isLoading}
                    disabled={this.props.isLoading}
                />
                <input
                    type="file"
                    name="file-uploader"
                    accept="application/pdf"
                    onChange={this.handleResumeUpload}
                />
            </React.Fragment>
        );
    };

    renderResumeEmptyState = () => {
        const breakpoints = {
            mobile: {
                width: 300,
                height: 130,
                transforms: {
                    background: 'white',
                },
            },
        };
        const { resumeUploadHeaderType, showButtonsOnly } = this.props;
        const isSingleJobJobProfileFormStyling =
            resumeUploadHeaderType === 'single-job' ? 'larger-font' : '';

        return (
            <div className="empty-state">
                {this.context.wrapAuth('ResumeUpload', this.renderButton(), {
                    login_trigger: 'resume-upload',
                })}

                {!showButtonsOnly && (
                    <div className="empty-state-text-section">
                        <p
                            className={`empty-state-title ${isSingleJobJobProfileFormStyling}`}
                        >
                            No resume? No problem!
                        </p>
                        <p className="resume-subcaption">
                            Download your LinkedIn profile and save it as a PDF.
                        </p>
                        <ResponsivePicture
                            cloudinaryID="/v1/user/linkedin_resume_2x.jpg"
                            breakpoints={breakpoints}
                        />
                    </div>
                )}
            </div>
        );
    };

    renderResumeFilledLayout = () => {
        return (
            <div>
                <div className="resume-content">
                    <div className="resume-holder">
                        <SVGFromCloudinary cloudinaryID="v1582738307/SVGs/Group_2.svg" />
                        <ResumeDownloadButton
                            userHash={this.props.userHash}
                            buttonElement={this.renderDownloadResumeLink(
                                this.props.fileName
                            )}
                        />
                        <p className="upload-date">
                            Uploaded on{' '}
                            {getMMDDYYTimestamp(this.props.uploadedAt, 0)}
                        </p>
                    </div>
                    <div className="button-section">{this.renderButton()}</div>
                </div>
            </div>
        );
    };

    getResumeUploadHeader = () => {
        const { resumeUploadHeaderType, showButtonsOnly } = this.props;
        const isSingleJobJobProfileFormHeader =
            resumeUploadHeaderType === 'single-job';
        const isResumeUploaded =
            this.props.fileName && this.props.fileName.length;
        const singleJobResumeTitle = isResumeUploaded
            ? 'Complete your professional profile to Quick Apply:'
            : "You don't have a professional profile yet. You'll need to create one to Quick Apply:";

        if (showButtonsOnly) {
            return;
        }

        if (!isSingleJobJobProfileFormHeader) {
            return (
                <React.Fragment>
                    <FGBLabel className="resume-title center-align">
                        Professional Profile
                    </FGBLabel>
                    <p className="resume-subcaption">
                        This profile is visible to you and select employer
                        partners.
                    </p>
                </React.Fragment>
            );
        }

        return (
            <div className="single-job-resume-header">
                <h1
                    className={`
                single-job-resume-header__title 
                ${
                    isResumeUploaded
                        ? ''
                        : 'single-job-resume-header__title--longer-title'
                }
            `}
                >
                    {singleJobResumeTitle}
                </h1>
            </div>
        );
    };

    renderErrorMessage() {
        if (this.props.hasUploadError) {
            return (
                <div className="upload-resume-error">
                    Please upload a resume in PDF format.
                </div>
            );
        }
        if (this.state.fileTooLargeError) {
            return (
                <div className="upload-resume-error">
                    Please limit your file size to 10MB.
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <div className="component-User-JobProfile-ResumeUpload">
                    {this.getResumeUploadHeader()}

                    {this.props.fileName && this.props.fileName.length
                        ? this.renderResumeFilledLayout()
                        : this.renderResumeEmptyState()}
                </div>
                {this.renderErrorMessage()}
            </div>
        );
    }
}

ResumeUpload.propTypes = {
    resumeUploadHeaderType: PropTypes.oneOf(['default']),
    location: PropTypes.oneOf(['jobProfile', 'modal']),
    onResumeUpload: PropTypes.func,
    isLoading: PropTypes.bool,
    userHash: PropTypes.string,
    hasUploadError: PropTypes.bool,
    showButtonsOnly: PropTypes.bool,
    ...ResumeDataPropShape,
};

ResumeUpload.defaultProps = {
    location: 'jobProfile',
    onResumeUpload: () => {},
    isLoading: false,
    userHash: '',
    hasUploadError: false,
    resumeUploadHeaderType: 'default',
    showButtonsOnly: false,
};

ResumeUpload.contextType = PageContext;
