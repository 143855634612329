import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import RadioGroup from '../../../ModalParts/Inputs/Radios/RadioGroup/RadioGroup';
import Radio from '../../../ModalParts/Inputs/Radios/Radio/Radio';

import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { VETERAN_DISABILITY_STEP_NAME } from '../constants';

import './VeteranDisability.scss';

const VeteranDisability = ({ buttonNavBox }) => {
    const {
        stepFormProps,
        updateFormValueAtPath,
        stepErrors,
    } = useGetStepFormProps(VETERAN_DISABILITY_STEP_NAME);

    const handleRadioSelect = React.useCallback(
        ({ value, group }) => {
            updateFormValueAtPath(group)(value);
        },
        [updateFormValueAtPath]
    );

    const armedForcesErrorMessage = stepErrors.armedForcesChoice;
    const disabilityErrorMessage = stepErrors.disabilityChoice;

    return (
        <FlowStep name={VETERAN_DISABILITY_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-VeteranDisability">
                <div className="primary-step-text">
                    Have you ever served in active duty in the armed forces?
                    (Domestic or Foreign)
                </div>
                <RadioGroup
                    currentSelected={stepFormProps.armedForcesChoice}
                    onChange={handleRadioSelect}
                    groupName="veteranDisability.armedForcesChoice"
                    error={armedForcesErrorMessage}
                >
                    <Radio value="Yes" />
                    <Radio value="No" />
                    <Radio value="I prefer not to say" />
                </RadioGroup>
                <div className="primary-step-text">
                    Are you a person living with a physical and/or mental
                    disability?
                </div>
                <RadioGroup
                    currentSelected={stepFormProps.disabilityChoice}
                    onChange={handleRadioSelect}
                    groupName="veteranDisability.disabilityChoice"
                    error={disabilityErrorMessage}
                >
                    <Radio value="Yes" />
                    <Radio value="No" />
                    <Radio value="I prefer not to say" />
                </RadioGroup>
            </div>
            {buttonNavBox}
        </FlowStep>
    );
};

VeteranDisability.propTypes = {
    buttonNavBox: PropTypes.node.isRequired,
};

VeteranDisability.defaultProps = {};

export default React.memo(VeteranDisability);
