import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import Button from '../../Input/Button/Button';

import './CookieBanner.scss';

export default class CookieBanner extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        setYes: PropTypes.func.isRequired,
    };

    static defaultProps = {
        setYes: () => {},
    };

    render() {
        return (
            <PageConsumer>
                {(ctx) =>
                    !ctx.isLoggedIn() ? (
                        <div className="component-Layout-CookieBanner d-flex justify-content-center text-center align-items-center">
                            <div
                                className="close"
                                onClick={this.props.setYes}
                            />
                            <div className="d-inline-block textDiv">
                                Our site uses cookies to improve your
                                experience.{' '}
                                <a href={'/privacy-policy'}>Read More</a>
                            </div>
                            <div className="btnDiv">
                                <Button
                                    type={'solid'}
                                    value={'Got It!'}
                                    onClick={this.props.setYes}
                                />
                            </div>
                        </div>
                    ) : null
                }
            </PageConsumer>
        );
    }
}
