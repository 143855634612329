import PropTypes from 'prop-types';
import React from 'react';
import { GroupConsumer } from '../../../../contexts/GroupContext';

import { Consumer as PageConsumer } from '../../../../contexts/PageContext'; // eslint-disable-line import/no-cycle
import UserImage, {
    buildImagePath,
} from '../../../Content/User/UserDisplay/UserImage/UserImage';
import CharCount from '../../../Elements/CharCount/CharCount';
import TruncatableText from '../../../Elements/TruncatableText/TruncatableText';
import closeIcon from '../../../Icons/Close';
import prevIcon from '../../../Icons/prevIcon';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';
import Button from '../../../Input/Button/Button';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import CardHeader from '../../CardHeader/CardHeader';
import GroupLogo from '../../GroupLogo/GroupLogo';
import ReactRouterLink from '../../ReactRouterLink';

import './GroupJoinForm.scss';

const ANSWER_MAX_COUNT = 5000;

export default class GroupJoinForm extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            rules: {
                expanded: false,
            },
            question: {
                expanded: false,
            },
            inviteQuestionAnswer: '',
            error: '',
        };
        const arrowTransform = {
            arrow: 'c_scale,g_center,w_150,h_159',
        };

        this.joinArrow = buildImagePath(
            'Groups/IMG_5181_transparent_opt.png',
            'arrow',
            arrowTransform
        );

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const isFirstCharNewLine =
            !this.state.inviteQuestionAnswer && e.target.value === '\n';
        if (!isFirstCharNewLine) {
            this.setState({ inviteQuestionAnswer: e.target.value });
        }
    }

    async handleSubmit(e, groupContext, pageContext) {
        e.preventDefault();
        const { group } = groupContext;
        const { privacySetting } = group;

        this.setState({ error: '' });
        const trimedAnswer = this.trimNewLines(this.state.inviteQuestionAnswer);
        const result = await groupContext.requestToJoin(trimedAnswer || null);

        if (result?.meta?.success) {
            const {
                modalTriggerParameters: {
                    modalName: modalTriggerModalName,
                    modalData: modalTriggerModalProps,
                } = {},
            } = this.props;

            const data = {
                onClose: () =>
                    pageContext.modalTrigger(
                        modalTriggerModalName,
                        modalTriggerModalProps
                    ),
                heading:
                    privacySetting === 'closed'
                        ? 'Your request was sent!'
                        : "Congratulations! You're in!",
                buttonMetas: [
                    {
                        redirectUrl:
                            this.props.redirectToGroup &&
                            `/groups/${group.hash}`,
                        onClickHandler: () =>
                            pageContext.modalTrigger(
                                modalTriggerModalName,
                                modalTriggerModalProps
                            ),
                        buttonText: 'Continue',
                        buttonType: 'purple-medium-white',
                    },
                ],
            };
            pageContext.modalTrigger('ConfirmationModal', data);

            if (this.props.onSuccess) {
                this.props.onSuccess();
            }

            return;
        }

        if (result?.errors) {
            this.setState({ error: result?.errors?._general });
        }
    }

    trimNewLines = (text) => {
        return text.replace(/\n\s*\n\s*\n/g, '\n\n');
    };

    errorMessage() {
        return this.state.error ? (
            <p className="error-message" role="alert">
                {this.state.error}
            </p>
        ) : (
            ''
        );
    }

    formBlock(groupContext, pageContext) {
        if (!groupContext.group.inviteQuestion) {
            return null;
        }

        const {
            group: { inviteQuestion },
        } = groupContext;

        const value =
            this.state.inviteQuestionAnswer !== '' ? 'Send' : 'Answer required';
        const submitButton = pageContext.wrapAuth(
            'group-join-form-submit',
            <Button
                type="solid"
                formAction="submit"
                value={value}
                name="submit"
                disabled={this.state.inviteQuestionAnswer === ''}
            />,
            'group-join-form-submit'
        );

        return (
            <div className="block question">
                <h3 className="block-header">Membership question</h3>
                <TruncatableText
                    isDisplayTextTruncated={!this.state.question.expanded}
                    displayText={inviteQuestion}
                    handleShowMoreOrLessClick={() =>
                        this.setState((state) => ({
                            rules: { expanded: !state.question.expanded },
                        }))
                    }
                />
                <form
                    className="question-form"
                    onSubmit={(e) => {
                        this.handleSubmit(e, groupContext, pageContext);
                    }}
                >
                    {this.state.inviteQuestionAnswer.length ? (
                        <CharCount
                            length={this.state.inviteQuestionAnswer.length}
                            limit={ANSWER_MAX_COUNT}
                        />
                    ) : null}
                    <TextAreaInput
                        name="inviteQuestionAnswer"
                        value={this.state.inviteQuestionAnswer}
                        onChange={this.handleChange}
                        shouldUseEvent
                    />
                    <div className="question-form-ui">
                        {this.errorMessage()}
                        {submitButton}
                    </div>
                </form>
            </div>
        );
    }

    buttonBlock(groupContext, pageContext) {
        const value = groupContext.group.rules
            ? 'Accept & join'
            : 'Yes, join group';
        return (
            <div className="simple-join">
                <span className="error-message">{this.state.error}</span>
                <Button
                    type="solid"
                    name="join"
                    value={value}
                    onClick={(e) =>
                        this.handleSubmit(e, groupContext, pageContext)
                    }
                />
            </div>
        );
    }

    render() {
        return (
            <section className="component-Groups-GroupJoinForm">
                <PageConsumer>
                    {(pageContext) => (
                        <GroupConsumer>
                            {(groupContext) => {
                                if (
                                    !groupContext.group ||
                                    !groupContext.group.name
                                ) {
                                    return 'no group data';
                                }

                                const {
                                    name,
                                    rules,
                                    privacySetting,
                                    logo,
                                    inviteQuestion,
                                } = groupContext.group;

                                const ctaBlock = inviteQuestion
                                    ? this.formBlock(groupContext, pageContext)
                                    : this.buttonBlock(
                                          groupContext,
                                          pageContext
                                      );
                                const titleLabelText =
                                    privacySetting !== 'open'
                                        ? 'Request to join'
                                        : 'You are joining';
                                const { session } = pageContext.getPageProps();
                                const userLogo =
                                    (session && session.logo) ||
                                    '/v1/avatar_black_160_vtg7lw.svg';
                                const userName =
                                    (session && session.username) || 'User';

                                return (
                                    <React.Fragment>
                                        {!this.props.isModal && (
                                            <ReactRouterLink
                                                linkText="Close and return to group home"
                                                to="/"
                                            >
                                                <SVGIconWrapper SVGWrapperCssClass="svg-close-icon">
                                                    {closeIcon}
                                                </SVGIconWrapper>
                                                <SVGIconWrapper SVGWrapperCssClass="svg-prev-icon">
                                                    {prevIcon}
                                                </SVGIconWrapper>
                                            </ReactRouterLink>
                                        )}
                                        <header className="header">
                                            <CardHeader>
                                                {titleLabelText}
                                                <span className="title">
                                                    {name}
                                                </span>
                                            </CardHeader>
                                            <div className="join-graphics">
                                                <div className="logo-sizing">
                                                    <UserImage
                                                        iconSize={52}
                                                        image={{
                                                            src: userLogo,
                                                            alt: `${userName} profile picture`,
                                                        }}
                                                    />
                                                </div>
                                                <img
                                                    className="join-arrow"
                                                    src={`${this.joinArrow}`}
                                                    alt=""
                                                />
                                                <div>
                                                    <GroupLogo
                                                        logo={logo}
                                                        name={name}
                                                        iconSize="medium"
                                                    />
                                                </div>
                                            </div>
                                        </header>
                                        {rules ? (
                                            <div className="block rules">
                                                <h3 className="block-header">
                                                    Group rules
                                                </h3>
                                                <TruncatableText
                                                    isDisplayTextTruncated={
                                                        !this.state.rules
                                                            .expanded
                                                    }
                                                    displayText={rules}
                                                    handleShowMoreOrLessClick={() =>
                                                        this.setState(
                                                            (state) => ({
                                                                rules: {
                                                                    expanded: !state
                                                                        .rules
                                                                        .expanded,
                                                                },
                                                            })
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                        {ctaBlock}
                                    </React.Fragment>
                                );
                            }}
                        </GroupConsumer>
                    )}
                </PageConsumer>
            </section>
        );
    }
}

GroupJoinForm.propTypes = {
    isModal: PropTypes.bool,
    redirectToGroup: PropTypes.bool,
    onSuccess: PropTypes.func,
    modalTriggerParameters: PropTypes.shape({
        modalName: PropTypes.string,
        // TODO provide proper prop-type shape
        modalData: PropTypes.object, // eslint-disable-line
    }),
};

GroupJoinForm.defaultProps = {
    redirectToGroup: true,
    isModal: false,
    onSuccess: () => null,
    modalTriggerParameters: {
        modalName: null,
        modalData: null,
    },
};
