import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { GroupConsumer } from '../../contexts/GroupContext';

import './ReactRouterLink.scss';

export class ReactRouterLink extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * link's text supplied to title attribute for accessibility
         */
        linkText: PropTypes.string,
        /**
         * link destination
         */
        to: PropTypes.string.isRequired,
        /**
         * link destination
         */
        from: PropTypes.string,
        /**
         *  React Router's history object
         */
        history: PropTypes.object,
        /**
         * React Router's location object
         */
        location: PropTypes.object,
        /**
         * React Router's match object - contains information about how a <Route path> matched a URL
         */
        match: PropTypes.object,
        /**
         * Hash for the group
         */
        groupHash: PropTypes.string,
    };

    static defaultProps = {
        linkText: '',
        to: '/',
        from: '/',
        buildFullPath: true,
    };

    render() {
        return (
            <div className="component-Groups-ReactRouterLink">
                <GroupConsumer>
                    {({ group }) => {
                        let path = this.props.to;

                        if (this.props.buildFullPath) {
                            const groupHash = group
                                ? group.hash
                                : this.props.groupHash;
                            const params = this.props.match
                                ? this.props.match.params
                                : {};

                            path = `/groups/${groupHash}${
                                params.groupSlug
                                    ? `/${params.groupSlug}${this.props.to}`
                                    : this.props.to
                            }`;
                        }

                        return (
                            <Link
                                to={{
                                    pathname: path,
                                    state: {
                                        from: this.props.location
                                            ? this.props.location.pathname
                                            : this.props.from,
                                    },
                                }}
                                title={this.props.linkText}
                            >
                                {this.props.children}
                            </Link>
                        );
                    }}
                </GroupConsumer>
            </div>
        );
    }
}

export default withRouter(ReactRouterLink);
