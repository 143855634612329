import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';

import './TopicsTypeAheadDropdown.scss';

export const injectedTransform = {
    icon: 'c_lpad,w_36,h_36',
};
export default class TopicsTypeAheadDropdown extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: props.selection || '',
            suggestions: [],
            opened: false,
            axiosCancelTokens: [],
        };
    }

    static propTypes = {
        selection: PropTypes.shape({
            type: PropTypes.string,
            image: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        addOption: PropTypes.bool,
        selectCallback: PropTypes.func,
    };

    static defaultProps = {
        // selection: {
        //     type: "Topic",
        //     image: null,
        //     name: "Fake Name",
        //     id: 123
        // },
        selectCallback: () => {},
        defaultImage: '/v1562608422/Topic_avatar_iwecmq.svg',
        addOption: false,
        disabled: false,
    };

    //HANDLE FUNCTIONS =======================
    //Handle Change -
    handleChange = async (userInput) => {
        const limit = this.props.limit ? `?limit=${this.props.limit}` : '';
        const userInputParameter =
            userInput && userInput.length ? `?term=${userInput}` : '';

        let axiosCancelAction = this.state.axiosCancelAction;

        if (axiosCancelAction) {
            await axiosCancelAction();
        }

        try {
            const results = await axios.get(
                `/api/content-creation/typeahead${userInputParameter}${limit}`,
                {
                    cancelToken: new axios.CancelToken((c) => {
                        axiosCancelAction = c;
                    }),
                }
            );

            this.setState({
                axiosCancelAction,
                activeSuggestion: 0,
                showSuggestions: true,
                filteredSuggestions:
                    results.data && results.data.length ? results.data : [],
                userInput,
            });
        } catch (e) {
            console.log(e);
        }
    };

    handleClick = async () => {
        const limit = this.props.limit ? `?limit=${this.props.limit}` : '';
        try {
            const results = await axios.get(
                `/api/content-creation/typeahead${limit}`
            );
            this.setState({
                activeSuggestion: 0,
                showSuggestions: true,
                filteredSuggestions:
                    results.data && results.data.length ? results.data : [],
                userInput: '',
                opened: true,
            });
        } catch (e) {
            console.log(e);
        }
    };

    //FUNCTIONS RENDER DROPDOWN
    renderSuggestions = () => {
        if (this.state.showSuggestions) {
            let suggestionsList = (
                <div className={`suggestion-option`}>No matching results</div>
            );
            if (this.state.filteredSuggestions.length) {
                suggestionsList = (
                    <section className="suggestion-box">
                        {this.state.filteredSuggestions.map(
                            (suggestion, idx) => {
                                let className;
                                if (idx === this.state.activeSuggestion) {
                                    className = 'suggestion-active';
                                }
                                const suggestionObject = {
                                    id: suggestion.id || suggestion.hash,
                                    name: suggestion.name,
                                    image: suggestion.image,
                                    type: suggestion.type,
                                };
                                return (
                                    <div
                                        className={`suggestion-option ${
                                            className || ''
                                        }`}
                                        key={`suggestion-${idx}`}
                                        onClick={() =>
                                            this.handleSelectSuggestion(
                                                suggestionObject
                                            )
                                        }
                                    >
                                        <img
                                            alt={'selection-icon'}
                                            className="selection-icon"
                                            src={buildImagePath(
                                                this.getImageSrc(suggestion),
                                                'icon',
                                                injectedTransform
                                            )}
                                        />
                                        {suggestion.name}
                                    </div>
                                );
                            }
                        )}
                    </section>
                );
            }
            return suggestionsList;
        }
    };

    getImageSrc = (suggestion) => {
        let src = '';
        switch (suggestion.type.toLowerCase()) {
            case 'topic':
                src =
                    suggestion.image && suggestion.image.src
                        ? suggestion.image.src
                        : '/v1562608422/Topic_avatar_iwecmq.svg';
                break;
            case 'group':
            case 'community-group':
                src =
                    suggestion.image && suggestion.image.src
                        ? suggestion.image.src
                        : '/v1562608422/Topic_avatar_iwecmq.svg';
                break;
            default:
                src = '';
                break;
        }
        return src;
    };

    handleKeyDown = (keyCode) => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (keyCode === 13) {
            const suggestionObject = {};
            suggestionObject.name = this.state.userInput;

            if (filteredSuggestions[activeSuggestion]) {
                suggestionObject.id = filteredSuggestions[activeSuggestion].id;
                suggestionObject.name =
                    filteredSuggestions[activeSuggestion].name;
                suggestionObject.image =
                    filteredSuggestions[activeSuggestion].image;
                suggestionObject.type =
                    filteredSuggestions[activeSuggestion].type;
            }

            this.setState(
                {
                    activeSuggestion: 0,
                    showSuggestions: false,
                    userInput: suggestionObject,
                },
                this.props.selectCallback(suggestionObject)
            );
        } else if (keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        } else if (keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length + 1) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    handleSelectSuggestion = (selection) => {
        const suggestionObject = {
            id: selection.id,
            name: selection.name,
            image: selection.image,
            type: selection.type,
        };
        this.setState(
            {
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: suggestionObject,
                userHasSelected: true,
            },
            this.props.selectCallback(suggestionObject)
        );
    };

    render() {
        const disabledStyling = this.props.disabled ? 'disabled-styling' : '';
        const ArrowClass =
            this.state.userInput &&
            this.state.userInput.name &&
            this.state.userInput.length
                ? 'no-arrow-mobile'
                : '';
        const userInputValue =
            this.state.userInput && this.state.userInput.name
                ? this.state.userInput.name
                : this.state.userInput;
        const iconSrc =
            this.state.userInput && this.state.userInput.type
                ? this.getImageSrc(this.state.userInput)
                : '/v1562701409/SVGs/search-blue-36.svg';
        const style = {
            backgroundImage: this.props.disabled
                ? ''
                : `url(${buildImagePath(iconSrc, 'icon', injectedTransform)})`,
        };

        return (
            <div
                className={`component-Community-TopicsTypeAheadDropdown ${ArrowClass} ${disabledStyling}`}
            >
                <input
                    onFocus={this.handleClick}
                    style={style}
                    className={`${this.props.disabled ? '' : 'hasIcon'}`}
                    disabled={this.props.disabled}
                    name={'topics-typeahead-dropdown'}
                    onChange={(e) => this.handleChange(e.target.value)}
                    placeholder={
                        this.props.placeholder || 'Select or search...'
                    }
                    onKeyDown={(e) => this.handleKeyDown(e.keyCode)}
                    value={userInputValue}
                    autoComplete={'off'}
                />
                {this.renderSuggestions()}
            </div>
        );
    }
}
