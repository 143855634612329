import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import TextInput from '../../../ModalParts/Inputs/TextInput/TextInput';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import LocationSelector from '../../../ModalParts/Inputs/LocationSelector/LocationSelector';
import CheckBox from '../../../../Content/Elements/CheckBox/CheckBox';
import usePrevious from '../../../../../utilities/hooks/usePrevious';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { QUICK_PROFILE_STEP_NAME, getResetValuesForStep } from '../constants';
import { SchoolsTypeahead } from '../../../../Input/SchoolsTypeahead/SchoolsTypeahead';
import CompanySelector from '../../../ModalParts/Inputs/CompanySelector/CompanySelector';
import JobSelectorInput from '../../../ModalParts/Inputs/JobSelector/JobSelector';
import { EmitMetric } from '../../../../Analytics/VisibilityPixel/VisibilityPixel';

import { getStudentText, degreeDropdownOpts } from './constants';

import './QuickProfile.scss';

export const QuickProfile = ({ buttonNavBox, showName }) => {
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        updateFormValueAtPathSelector,
        stepErrors,
    } = useGetStepFormProps(QUICK_PROFILE_STEP_NAME);

    const { education, experience, name, isStudent } = stepFormProps;

    const prevIsStudent = usePrevious(isStudent);

    const studentToggler = updateFormValueAtPath('quickProfile');

    const handleStudentToggle = React.useCallback(
        () => studentToggler({ isStudent: !isStudent }),
        [isStudent, studentToggler]
    );

    // fire quick profile impression metric on quick profile step mount
    React.useEffect(() => {
        EmitMetric({
            misc_event_count: 1,
            misc_event_type: 'quick-profile-modal-impression',
        });
    }, []);

    // this effect resets all field values on student button toggle
    React.useEffect(() => {
        if (isStudent !== prevIsStudent) {
            // only reset values when isStudent changes
            const quickProfileUpdater = updateFormValueAtPath('quickProfile');
            // remove name + isStudent from defaults so we don't reset them as well
            const {
                name: defaultName,
                isStudent: defaultIsStudent,
                ...defaultQuickProfile
            } = getResetValuesForStep(QUICK_PROFILE_STEP_NAME);
            // add our users name + isStudent back in
            quickProfileUpdater({ name, isStudent, ...defaultQuickProfile });
        }
    }, [isStudent, prevIsStudent, updateFormValueAtPath, name]);

    React.useEffect(() => {
        if (experience.isRemote) {
            const locationUpdater = updateFormValueAtPath(
                'quickProfile.experience.location'
            );
            return locationUpdater({ name: '', lat: null, lng: null });
        }
    }, [experience.isRemote, updateFormValueAtPath]);

    const nameField = showName ? (
        <div className="field-wrapper">
            <TextInput
                inputName="quickProfile.name"
                value={name}
                label="Your name*"
                error={stepErrors.name}
                handleChange={updateFormValues}
                maxChars={100}
            />
        </div>
    ) : null;

    const educationInput = (
        <div className="quick-profile-fields">
            {nameField}
            <div className="field-wrapper">
                <SchoolsTypeahead
                    error={stepErrors.education.school.name}
                    handleSelect={updateFormValueAtPath(
                        'quickProfile.education.school'
                    )}
                    className="quick-profile-school-typeahead"
                    items={[]}
                    allowAddNewItem
                    isRequired
                />
            </div>
            <div className="field-wrapper">
                <Dropdown
                    inputName="quickProfile.education.degree"
                    className="quick-profile-dropdown degree"
                    options={degreeDropdownOpts}
                    value={education.degree}
                    label="Degree*"
                    error={stepErrors.education.degree}
                    handleChange={updateFormValues}
                />
            </div>
            <div className="field-wrapper">
                <LocationSelector
                    inputName="quickProfile.education.location"
                    label="School location*"
                    placeholder="City, State"
                    error={stepErrors.education.location.name}
                    value={education.location.name}
                    handleChange={updateFormValueAtPath}
                />
            </div>
        </div>
    );
    const experienceInput = (
        <div className="quick-profile-fields">
            {nameField}
            <div className="field-wrapper">
                <div className="form-label">
                    <span> Most recent job title*</span>
                </div>
                <JobSelectorInput
                    items={[]}
                    placeholder="Start typing to select your job title"
                    isMulti={false}
                    inputId="job"
                    allowAPICreation={false}
                    handleError={() => null}
                    withTags={false}
                    className="company-selector-input-form"
                    setTypeaheadInput={updateFormValueAtPath(
                        'quickProfile.experience.title'
                    )}
                    handleSelect={updateFormValueAtPathSelector(
                        'quickProfile.experience.title',
                        'title'
                    )}
                    error={stepErrors.experience.title}
                />
            </div>
            <div className="field-wrapper">
                <CompanySelector
                    inputName="quickProfile.experience.company"
                    value={experience.company}
                    label="Most recent company name*"
                    error={stepErrors.experience.company.companyName}
                    handleChange={updateFormValueAtPath}
                />
            </div>
            <div className="field-wrapper">
                <LocationSelector
                    inputName="quickProfile.experience.location"
                    label="Most recent job location*"
                    placeholder="City, State"
                    error={stepErrors.experience.location.name}
                    value={experience.location.name}
                    handleChange={updateFormValueAtPath}
                    disabled={experience.isRemote}
                />
            </div>
            <CheckBox
                id="#isRemote-toggle"
                defaultChecked={experience.isRemote}
                label="Remote"
                handleChange={updateFormValueAtPath(
                    'quickProfile.experience.isRemote'
                )}
                className="remote-select"
            />
        </div>
    );

    return (
        <FlowStep name={QUICK_PROFILE_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-QuickProfile">
                <div className="step-header">
                    <div className="step-title">
                        We need a few more details to complete your registration.
                    </div>
                    <div className="step-subtitle">
                        (You&apos;ll only have to do this once.)
                    </div>
                    <div className="hr-container">
                        <hr />
                    </div>
                </div>
                {isStudent ? educationInput : experienceInput}
                <button
                    type="button"
                    className="student-toggle"
                    onClick={handleStudentToggle}
                >
                    {getStudentText(isStudent)}
                </button>
                {buttonNavBox}
            </div>
        </FlowStep>
    );
};

QuickProfile.propTypes = {
    buttonNavBox: PropTypes.node.isRequired,
    showName: PropTypes.bool,
};

QuickProfile.defaultProps = {
    showName: true,
};

QuickProfile.displayName = 'QuickProfile';

export default React.memo(QuickProfile);
