import Yup from 'yup';
import { CONTENT_TYPE } from '../Constants/communityContent';
/* eslint-disable no-useless-escape */

export const MeetsMinWordCount = (minWordCount) => {
    return async (value) => {
        return value.trim().split(/\s+/).length >= minWordCount;
    };
};
export const MaximumLength = {
    username: 78,
    message: 500,
    longFormat: 65000,
    content: 5000,
    companyName: 255,
    credential: 48,
    jobTitle: 200,
};

export const contentTypeYupSchema = Yup.string()
    .oneOf([CONTENT_TYPE.DISCUSSION, CONTENT_TYPE.PHOTO, CONTENT_TYPE.LINK])
    .required();

/**
 * TODO: these 3 schema will be deprecated and replace by `CommunityUnifiedPostYupSchema`
 * */
export const legacyCommunityLinkYupSchema = {
    previewHash: Yup.string().nullable().required(),
    description: Yup.string()
        .nullable()
        .min(3, 'Please add a description (at least 3 characters).')
        .max(5000, 'Please limit yourself to 5,000 characters.'),
    url: Yup.string().nullable().required().url(),
    anon: Yup.boolean().required(),
    communityTopicId: Yup.number().nullable(),
    communityGroupHash: Yup.string().nullable(),
    oneOrTheOther: Yup.mixed().when(
        ['communityTopicId', 'communityGroupHash'],
        {
            is: (communityTopicId, communityGroupHash) =>
                ((communityTopicId === null ||
                    communityTopicId === undefined) &&
                    (communityGroupHash === null ||
                        communityGroupHash === undefined)) || // nothing
                (communityTopicId && communityGroupHash), // both
            then: Yup.mixed().test(
                'one-or-the-other',
                'Must have either a community topic id or a community group hash, not both.',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};

export const legacyCommunityDiscussionYupSchema = {
    title: Yup.string()
        .nullable()
        .min(3, 'Please add a title (at least 3 characters).')
        .max(
            MaximumLength.content,
            `Please limit yourself to ${MaximumLength.content.toLocaleString()} characters.`
        )
        .required(),
    description: Yup.string()
        .nullable()
        .min(3, 'Please add a description (at least 3 characters).')
        .max(
            MaximumLength.longFormat,
            `Please limit yourself to ${MaximumLength.longFormat.toLocaleString()} characters.`
        )
        .required(),
    anon: Yup.boolean().required(),
    communityTopicId: Yup.number().nullable(),
    communityGroupHash: Yup.string().nullable(),
    oneOrTheOther: Yup.mixed().when(
        ['communityTopicId', 'communityGroupHash'],
        {
            is: (communityTopicId, communityGroupHash) =>
                ((communityTopicId === null ||
                    communityTopicId === undefined) &&
                    (communityGroupHash === null ||
                        communityGroupHash === undefined)) || // nothing
                (communityTopicId && communityGroupHash), // both
            then: Yup.mixed().test(
                'one-or-the-other',
                'Must have either a community topic id or a community group hash, not both.',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};

export const CommunityUnifiedPostLinkSchema = Yup.lazy((linkObj = {}) => {
    if (linkObj.url) {
        return Yup.object({
            hash: Yup.string().nullable(),
            url: Yup.string().required(),
        });
    }
    return Yup.mixed().notRequired();
});

export const CommunityUnifiedPostYupSchema = {
    title: Yup.string().nullable().required(),
    body: Yup.string()
        .max(
            MaximumLength.longFormat,
            `Please limit yourself to ${MaximumLength.longFormat.toLocaleString()} characters.`
        )
        .nullable(),
    rawHtml: Yup.string().required(),
    isAnon: Yup.boolean().required(),
    communityGroupHash: Yup.string().nullable(),
    communityTopicId: Yup.number().nullable(),
    link: CommunityUnifiedPostLinkSchema,
};

export const legacyCommunityPhotoYupSchema = {
    title: Yup.string()
        .nullable()
        .required()
        .min(3, 'Please add a caption (at least 3 characters).')
        .max(5000, 'Please limit yourself to 5,000 characters.'),
    cloudinaryId: Yup.string().nullable().required('Image is required.'),
    anon: Yup.boolean().required(),
    communityTopicId: Yup.number().nullable(),
    communityGroupHash: Yup.string().nullable(),
    oneOrTheOther: Yup.mixed().when(
        ['communityTopicId', 'communityGroupHash'],
        {
            is: (communityTopicId, communityGroupHash) =>
                ((communityTopicId === null ||
                    communityTopicId === undefined) &&
                    (communityGroupHash === null ||
                        communityGroupHash === undefined)) || // nothing
                (communityTopicId && communityGroupHash), // both
            then: Yup.mixed().test(
                'one-or-the-other',
                'Must have either a community topic id or a community group hash, not both.',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};

export const CommunityCommentYupSchema = {
    comment: Yup.string().required(),
    anon: Yup.boolean().required(),
    parent_comment_hash_id: Yup.string().nullable(),
    content_hash_id: Yup.string().required(),
};

export const LinkEditSchema = {
    description: Yup.string()
        .nullable()
        .min(3, 'Please add a description (at least 3 characters).')
        .max(5000, 'Please limit yourself to 5,000 characters.'),
};

export const DiscussionEditSchema = {
    title: Yup.string()
        .nullable()
        .min(3, 'Please add a title (at least 3 characters).')
        .max(5000, `Please limit yourself to 5,000 characters.`),
    description: Yup.string()
        .nullable()
        .min(3, 'Please add a description (at least 3 characters).')
        .max(65000, `Please limit yourself to 65,000 characters.`),
};

export const PhotoEditSchema = {
    title: Yup.string()
        .nullable()
        .min(3, 'Please add a caption (at least 3 characters).')
        .max(5000, `Please limit yourself to 5,000 characters.`),
};

export const CommentEditSchema = {
    comment: Yup.string(),
};

export const InviteSingleEmailSchema = {
    inviteEmail: Yup.string()
        .nullable()
        .min(1)
        .max(128)
        .email('Please check the formatting and try again.')
        .required(),
    fromUsername: Yup.string()
        .max(78, 'This field is limited to 78 characters.')
        .required('Username is required'),
    message: Yup.string()
        .max(500, 'Please limit your message to 500 characters')
        .nullable(),
    isAnon: Yup.boolean().nullable(),
};

export const InviteMultipleEmailSchema = {
    inviteEmails: Yup.array()
        .of(
            Yup.string()
                .min(1)
                .max(128)
                .email(
                    'There was a problem with one or more of your email addresses. Please check the formatting and try again.'
                )
                .required()
        )
        .min(1)
        .required(),
    fromUsername: Yup.string()
        .max(78, 'This field is limited to 78 characters.')
        .required('Username is required'),
    message: Yup.string()
        .max(500, 'Please limit your message to 500 characters')
        .nullable(),
    isAnon: Yup.boolean().nullable(),
};

export const UserAmbassadorRewardSchema = {
    rewardTier: Yup.number()
        .min(1, 'Reward tier does not exist')
        .max(4, 'Reward tier does not exist')
        .required(),
    recipientName: Yup.string().nullable().required(),
    address: Yup.string().nullable().required(),
    city: Yup.string().nullable().required(),
    state: Yup.string().nullable().required(),
    zip: Yup.string().nullable().required(),
};

export const LinkFormSchema = {
    previewHash: Yup.string().nullable().required(),
    description: Yup.string()
        .min(3, 'Please add a description (at least 3 characters).')
        .max(
            MaximumLength.content,
            `Please reduce description to ${MaximumLength.content.toLocaleString()} characters.`
        )
        .required(),
    url: Yup.string().url('Please enter a valid URL.').required(),
    anon: Yup.boolean().required(),
    communityTopicId: Yup.number().nullable(),
    communityGroupHash: Yup.string().nullable(),
    oneOrTheOther: Yup.mixed().when(
        ['communityTopicId', 'communityGroupHash'],
        {
            is: (communityTopicId, communityGroupHash) =>
                ((communityTopicId === null ||
                    communityTopicId === undefined) &&
                    (communityGroupHash === null ||
                        communityGroupHash === undefined)) || // nothing
                (communityTopicId && communityGroupHash), // both
            then: Yup.mixed().test(
                'one-or-the-other',
                'Must have either a community topic id or a community group hash, not both.',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EmployerClaimSchema = {
    isSubmitted: Yup.boolean().required(),
    company: Yup.string()
        .min(3, 'Please add a company name (at least 3 characters).')
        .max(255, 'Please reduce company name to 255 characters.')
        .nullable()
        .required(),
    email: Yup.string()
        .email('Please enter a valid email.')
        .nullable()
        .required(),
    phone: Yup.string()
        .nullable()
        .matches(phoneRegExp, 'Phone number is not valid'),
    companyId: Yup.number().nullable(),
};

export const ProfileBoxSchema = {
    profileCredential: Yup.string()
        .max(
            MaximumLength.credential,
            `Please reduce to ${MaximumLength.credential} characters.`
        )
        .nullable(),
    profileBio: Yup.string()
        .max(
            MaximumLength.message,
            `Please limit your message to ${MaximumLength.message} characters.`
        )
        .nullable(),
    profileUrl: Yup.string().url('Please enter a valid URL.').nullable(),
    jobTitle: Yup.string()
        .max(
            MaximumLength.jobTitle,
            `Please limit your message to ${MaximumLength.jobTitle} characters.`
        )
        .nullable(),
};

export const GroupSchema = {
    name: Yup.string().min(1).max(60).required(),
    description: Yup.string().min(1).max(108).required(),
    image: Yup.string().nullable(),
    groupSize: Yup.string().required(),
    interests: Yup.array().of(Yup.number()).required(),
};

export const createMaxCharError = (max) =>
    `Please limit this to ${max.toLocaleString()} characters.`;
export const createRequiredError = (name) => `${name} is a required field.`;

// simple max and required validation
export const GroupEditSchema = {
    headerImage: Yup.string().max(255).nullable(),
    logo: Yup.string().max(255).nullable(),
    name: Yup.string()
        .min(4, 'Please enter at least 4 characters.')
        .max(60, createMaxCharError(60))
        .required(createRequiredError('Name'))
        .test(
            'invalid-characters',
            "Please only use letters, numbers, and special characters `!@#$%^&*()={}[]:;_+<>'-.",
            (value) => {
                const regex = /^[\w\s\[\]`!@#$%\^&*()={}:;_<>+'-.]*$/;

                return regex.test(value);
            }
        ),
    description: Yup.string()
        .max(108, createMaxCharError(108))
        .required(createRequiredError('Brief group description')),
    aboutGroup: Yup.string()
        .max(5000, createMaxCharError(5000))
        .required(createRequiredError('Full group description')),
    rules: Yup.string().max(5000, createMaxCharError(5000)).nullable(),
    inviteQuestion: Yup.string().max(1000, createMaxCharError(1000)).nullable(),
    privacySetting: Yup.string()
        .oneOf(
            ['open', 'closed', 'secret'],
            createRequiredError('Page visibility and privacy')
        )
        .required(),
    interests: Yup.array().of(Yup.number()),
    isLocationBased: Yup.boolean(),
    contentIsPrivate: Yup.boolean().nullable(),
    locations: Yup.array().nullable(),
};

export const userIdInviteSchema = {
    toUserId: Yup.number().required(),
    message: Yup.string().min(1).max(300).nullable().required(),
};

export const emailInviteSchema = {
    inviteEmails: Yup.array()
        .of(Yup.string().min(1).max(128).email().required())
        .min(1)
        .nullable()
        .required(),
    message: Yup.string().min(1).max(300).nullable().required(),
};

export const groupUserInviteSchema = {
    userId: Yup.number().required(),
    message: Yup.string().min(1).max(300).nullable().required(),
    communityGroupHash: Yup.string().nullable().required(),
};

export const groupBatchEmailInviteSchema = {
    inviteEmails: Yup.array()
        .of(Yup.string().min(1).max(128).email().required())
        .min(1)
        .nullable()
        .required(),
    message: Yup.string().min(1).max(300).nullable().required(),
    communityGroupHash: Yup.string().nullable().required(),
};

export const userShortBioUpdateSchema = {
    logo: Yup.string().min(1).max(255).nullable().required(),
    username: Yup.string()
        .min(1)
        .max(32, 'Please reduce to 32 characters.')
        .nullable()
        .required('This is a required field.'),
    jobTitle: Yup.string().min(1).max(200, createMaxCharError(200)).nullable(),
    location: Yup.object()
        .shape({
            name: Yup.string()
                .min(1)
                .max(100, createMaxCharError(100))
                .nullable(),
            lat: Yup.number().nullable(),
            lng: Yup.number().nullable(),
        })
        .nullable(),
    profileCredential: Yup.string()
        .min(1)
        .max(48, createMaxCharError(48))
        .nullable(),
    yearsOfExperience: Yup.string().nullable(),
};

export const longBioUpdateSchema = {
    profileBio: Yup.string()
        .min(1)
        .max(500, createMaxCharError(500))
        .nullable(),
    profileUrl: Yup.string()
        .url('Please enter a valid URL like https://www.example.com')
        .min(1)
        .max(200, createMaxCharError(200))
        .nullable(),
};

export const groupSearchSchema = {
    keyword: Yup.string().nullable(),
    interests: Yup.array(
        Yup.object().shape({
            id: Yup.number().nullable().required(),
            name: Yup.string().nullable().required(),
        })
    ).nullable(),
    locations: Yup.array(
        Yup.object().shape({
            name: Yup.string().nullable().required(),
            latitude: Yup.number().min(-90).max(90).nullable().required(),
            longitude: Yup.number().min(-180).max(180).nullable().required(),
        })
    ).nullable(),
};

export const employerBatchEmailInviteSchema = {
    inviteEmails: Yup.array()
        .of(Yup.string().min(1).max(128).email().required())
        .min(1)
        .required(),
    message: Yup.string().min(1).max(300).nullable().required(),
};
