export const POPUP_MODE = 'popup';
export const EMBED_MODE = 'embed';
export const CREATE_LAYOUT = 'create';
export const LOGIN_LAYOUT = 'login';
export const EMAIL_SIGNUP = 'email-signup';
export const RESET_PASSWORD_CONFIRMATION_LAYOUT = 'password-confirmation';
export const RESET_PASSWORD_LAYOUT = 'password';
export const FACEBOOK_REAUTHORIZATION_LAYOUT = 'facebook-reauthorization';
export const FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT =
    'facebook-reauthorization-confirmation';
