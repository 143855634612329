import axios from 'axios';
import { QUICK_PROFILE_STEP_NAME } from '../Steps/constants';
import { validateQuickProfilePayload } from '../../../Includes/FormSchema/UserProfileSchema';
import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';

const flowTree = {
    [QUICK_PROFILE_STEP_NAME]: [],
};

export const buildQuickProfileOnboardingDefaultState = (data = {}) =>
    buildInitialFormState(['quickProfile'], data);

export const buildQuickProfileOnboardingFlow = (initializeOptions = {}) => {
    const validators = {
        quickProfileOnboarding: validateQuickProfilePayload,
    };

    return {
        flowTree,
        initialStep: QUICK_PROFILE_STEP_NAME,
        initialFlowData: { ...initializeOptions, validators },
    };
};

export const quickProfileOnboardingFlowAPI = {
    submitQuickProfile: async (payload = {}) => {
        const endpoint = '/api/user/profile';

        try {
            const response = await axios.patch(endpoint, payload);
            const { data } = response;
            if (data.meta.success) return { ok: true, errors: null };
        } catch (error) {
            return { ok: false, errors: error };
        }
    },
};
