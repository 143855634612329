import React from 'react';
import PropTypes from 'prop-types';

import './TopBannerProfileCta.scss';
import { PageContext } from '../../../../contexts/ContextCreator';
import Button from '../../../Input/Button/Button';
import Pixel, {
    EmitMetric,
} from '../../../Analytics/VisibilityPixel/VisibilityPixel';

export default class TopBannerProfileCta extends React.PureComponent {
    constructor(props) {
        super(props);

        this.baseClassName =
            'component-CallToAction-OnSiteProfileCTAs-TopBannerProfileCta';
    }

    static propTypes = {
        /**
         * the LAC for tracking metrics
         */
        LAC: PropTypes.string,
        /**
         * the LAT for tracking metrics
         */
        LAT: PropTypes.string,
        /**
         * imply metric name for clicking on CTA
         */
        clickMetric: PropTypes.string,
        /**
         * imply metric name for impression on CTA
         */
        impressionMetric: PropTypes.string,
        /**
         * additional styling that would override default
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        LAC: '',
        LAT: '',
        clickMetric: '',
        impressionMetric: '',
        className: '',
    };

    static contextType = PageContext;

    handleClick = () => {
        const { clickMetric } = this.props;

        if (clickMetric) {
            EmitMetric({
                misc_event_type: clickMetric,
                misc_event_count: 1,
            });
        }
    };

    render() {
        const {
            LAT,
            LAC,
            clickMetric,
            impressionMetric,
            className,
        } = this.props;

        return (
            <div className={`${this.baseClassName} ${className}`}>
                {impressionMetric && (
                    <Pixel
                        metrics={{
                            misc_event_type: impressionMetric,
                            misc_event_count: 1,
                        }}
                    />
                )}

                <p className={`${this.baseClassName}__text`}>
                    Don’t miss out on new opportunities.
                </p>

                {this.context.wrapAuth(
                    'OnSite-Profile-CTA',
                    <a
                        href={`/users/profile?LAT=${LAT}&LAC=${LAC}`}
                        onClick={this.handleClick}
                        className={`${this.baseClassName}__btn-wrapper`}
                    >
                        <Button
                            className={`${this.baseClassName}__btn-wrapper__btn`}
                            inputStyle={`${this.baseClassName}__btn-wrapper__input`}
                            value="Update your profile"
                            type="purple-medium-white"
                        />
                    </a>,
                    { login_trigger: clickMetric },
                    null,
                    'login',
                    null,
                    `/users/profile?LAT=${LAT}&LAC=${LAC}`
                )}
            </div>
        );
    }
}
