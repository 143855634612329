import React from 'react';

import './TypingMessage.scss';

export default class TypingMessage extends React.PureComponent {
    render() {
        return (
            <div className="component-Messages-TypingMessage">
                <div className="bubble" />
                <div className="bubble" />
                <div className="bubble" />
            </div>
        );
    }
}
