import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

export default class Bonus extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            bonus: this.props.bonus || null,
            isPublic: this.props.isPublic,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        buttonType: PropTypes.string,
        buttonData: PropTypes.array,
        showCheckbox: PropTypes.bool,
        checkboxLabel: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'single',
        buttonData: [
            {
                title: 'Not bonus eligible',
                value: '0',
            },
            {
                title: '$0k - $10k',
                value: '0-10k',
            },
            {
                title: '$10k - $20k',
                value: '10k-20k',
            },
            {
                title: '$20k - $50k',
                value: '20k-50k',
            },
            {
                title: '$50k - $100k',
                value: '50k-100k',
            },
            {
                title: '$100k+',
                value: '>100k',
            },
        ],
        isPublic: true,
        showCheckbox: true,
        checkboxLabel: 'Please do not publish this information.',
    };

    handleUpdate = (selectedValues) => {
        const answer = {
            bonus: selectedValues[0] || null,
            isPublic: this.state.isPublic,
        };
        this.setState({ bonus: answer.bonus });
        this.submitAnswer(answer);
    };

    handleCheckBox = (isChecked) => {
        const answer = {
            isPublic: !isChecked,
            bonus: this.state.bonus,
        };
        this.setState({ isPublic: answer.isPublic });
        this.submitAnswer(answer);
    };

    submitAnswer = (answer) => {
        const bonusAnswer = answer.bonus
            ? answer
            : { bonus: null, isPublic: true };

        this.props.onAnswer(
            'bonus',
            bonusAnswer,
            CAN_SUBMIT,
            false,
            this.props.nextStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-demographic-2',
            misc_event_count: 1,
        });
        const answer = this.props.bonus
            ? {
                  bonus: this.props.bonus,
                  isPublic: this.props.isPublic,
              }
            : null;
        this.props.bonus
            ? this.props.onAnswer(
                  'bonus',
                  answer,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    render() {
        return (
            <ButtonCard
                {...this.props}
                onClick={this.handleUpdate}
                handleCheckBox={this.handleCheckBox}
                hasSubtitle={true}
                selectedItems={this.props.bonus}
                isChecked={!this.props.isPublic}
            />
        );
    }
}
