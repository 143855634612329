import React from 'react';

export default () => (
    <svg
        viewBox="0 0 50 47"
        xmlns="http://www.w3.org/2000/svg"
        className="trees"
    >
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <ellipse
                fill="#DECEFD"
                cx="37.198"
                cy="24.209"
                rx="11.074"
                ry="11.108"
            />
            <ellipse
                fill="#F5DDF9"
                cx="10.506"
                cy="21.93"
                rx="7.667"
                ry="7.69"
            />
            <g fill="#2A4473" fillRule="nonzero">
                <path d="M21.08 19.352a9.612 9.612 0 0 1-2.452.315c-5.295 0-9.586-4.276-9.586-9.549 0-5.272 4.291-9.548 9.586-9.548 4.552 0 8.45 3.184 9.38 7.562a.568.568 0 1 0 1.11-.237c-1.04-4.903-5.4-8.465-10.49-8.465-5.92 0-10.722 4.785-10.722 10.688 0 5.904 4.801 10.689 10.722 10.689.935 0 1.854-.12 2.74-.353a.57.57 0 0 0-.289-1.102zM16.819 45.17h3.618c.314 0 .568-.288.568-.643 0-.355-.254-.643-.568-.643H16.82c-.314 0-.568.288-.568.643 0 .355.254.643.568.643z" />
                <path d="M18.082 7.048v37.073c0 .315.288.57.643.57.355 0 .643-.255.643-.57V7.048c0-.314-.288-.57-.643-.57-.355 0-.643.256-.643.57z" />
                <path d="M18.944 15.189l-3.873-3.762a.567.567 0 0 0-.803.013.57.57 0 0 0 .013.806l3.873 3.761a.567.567 0 0 0 .803-.013.57.57 0 0 0-.013-.805zM19.102 12.783l3.873-3.761a.57.57 0 0 0 .013-.805.567.567 0 0 0-.803-.013l-3.873 3.76a.57.57 0 0 0-.013.806.567.567 0 0 0 .803.013zM44.703 18.79c0 6.567-5.34 11.888-11.926 11.888s-11.926-5.321-11.926-11.887c0-6.567 5.34-11.888 11.926-11.888s11.926 5.321 11.926 11.888zm-1.135 0c0-5.935-4.83-10.748-10.79-10.748-5.961 0-10.791 4.813-10.791 10.749 0 5.935 4.83 10.748 10.79 10.748 5.96 0 10.79-4.813 10.79-10.748zM30.753 45.17h4.048c.314 0 .568-.288.568-.643 0-.355-.254-.643-.568-.643h-4.048c-.313 0-.567.288-.567.643 0 .355.254.643.567.643z" />
                <path d="M32.234 15.357v28.726c0 .314.287.57.642.57.356 0 .643-.256.643-.57V15.357c0-.315-.287-.57-.643-.57-.355 0-.642.255-.642.57z" />
                <path d="M33.084 24.51l-4.333-4.206a.567.567 0 0 0-.803.013.57.57 0 0 0 .013.805l4.333 4.207a.567.567 0 0 0 .803-.013.57.57 0 0 0-.013-.805zM33.26 21.723l4.333-4.207a.57.57 0 0 0 .013-.806.567.567 0 0 0-.803-.013l-4.333 4.208a.57.57 0 0 0-.013.805.567.567 0 0 0 .803.013zM15.265 26.997c0 4.36-3.545 7.893-7.916 7.893-4.372 0-7.917-3.533-7.917-7.893 0-4.36 3.545-7.892 7.917-7.892 4.371 0 7.916 3.533 7.916 7.892zm-1.136 0c0-3.729-3.035-6.753-6.78-6.753-3.746 0-6.781 3.024-6.781 6.753 0 3.73 3.035 6.754 6.78 6.754 3.746 0 6.781-3.025 6.781-6.754zM5.325 45.17h4.047c.314 0 .568-.288.568-.643 0-.355-.254-.643-.568-.643H5.325c-.314 0-.568.288-.568.643 0 .355.254.643.568.643z" />
                <path d="M6.797 25.585v18.498c0 .314.288.57.643.57.355 0 .642-.256.642-.57V25.585c0-.315-.287-.57-.642-.57-.355 0-.643.255-.643.57z" />
                <path d="M7.687 31.334l-2.79-2.71a.567.567 0 0 0-.803.013.57.57 0 0 0 .013.806l2.79 2.71a.567.567 0 0 0 .803-.013.57.57 0 0 0-.013-.806zM7.8 29.83l2.79-2.709a.57.57 0 0 0 .013-.805.567.567 0 0 0-.803-.014l-2.79 2.71a.57.57 0 0 0-.013.805.567.567 0 0 0 .803.013z" />
            </g>
        </g>
    </svg>
);
