import {
    CREATE_LAYOUT,
    LOGIN_LAYOUT,
} from './LoginLayoutComponent/Layouts/LogInLayout/constants';

export const LOGIN_DESCRIPTIONS = {
    generic:
        "We're millions of women sharing advice, finding jobs and making connections.",
    'home-page':
        "We're millions of women sharing advice, finding jobs and making connections.",
    'marketing-link':
        "We're millions of women sharing advice, finding jobs and making connections.",
    react: 'Continue so you can react to others.',
    'unauth-login': 'Continue so you can connect and message others.',
    comment: 'Continue so you can comment away.',
    'profile-call-to-action':
        'Continue so you can get better jobs, advice, and connections.',
    'create-content': 'Continue so you can post away.',
    'set-job-alert': 'Continue so you can get job alerts.',
    'read-single-review': 'Register and leave an anonymous review to continue.',
    'follow-company': 'Continue so you can follow companies that interest you.',
    'save-job': 'Continue so you can save jobs that interest you.',
    'subscribe-to-topic': 'Continue so you can subscribe to topics.',
    'connect-user': 'Continue so you can connect with other women.',
    'follow-user': 'Continue so you can follow others.',
    'survey-completed': 'Continue to finish submitting your anonymous review.',
    'rewards-call-to-action': 'Continue so you can get rewarded.',
    'employer-login':
        'Employers may use the same email and password used as a Fairygodboss user.',
    'job-profile':
        'Continue to see your professional profile and job application updates.',
    'single-job-page':
        "We're millions of women sharing advice, finding jobs and making connections.",
};

export const LOGIN_LAYOUT_DEFAULTS = {
    generic: CREATE_LAYOUT,
    'home-page': CREATE_LAYOUT,
    'home-page-sign-in': LOGIN_LAYOUT,
    'marketing-link': CREATE_LAYOUT,
    react: CREATE_LAYOUT,
    'unauth-login': CREATE_LAYOUT,
    comment: CREATE_LAYOUT,
    'profile-call-to-action': LOGIN_LAYOUT,
    'create-content': CREATE_LAYOUT,
    'set-job-alert': CREATE_LAYOUT,
    'read-single-review': LOGIN_LAYOUT,
    'follow-company': CREATE_LAYOUT,
    'subscribe-to-topic': CREATE_LAYOUT,
    'save-job': CREATE_LAYOUT,
    'connect-user': CREATE_LAYOUT,
    'follow-user': CREATE_LAYOUT,
    'survey-completed': CREATE_LAYOUT,
    'rewards-call-to-action': LOGIN_LAYOUT,
    'register-event': LOGIN_LAYOUT,
    'jobseeker-onboard': LOGIN_LAYOUT,
    'reset-password': LOGIN_LAYOUT,
    'view-event': LOGIN_LAYOUT,
    'resume-upload': LOGIN_LAYOUT,
    'quick-apply': LOGIN_LAYOUT,
    article_join_banner_button: CREATE_LAYOUT,
    article_join_sticky_button: CREATE_LAYOUT,
    article_join_footer_button: CREATE_LAYOUT,
    article_sign_up_button: CREATE_LAYOUT,
    article_comment_signup: CREATE_LAYOUT,
    'single-job-page': CREATE_LAYOUT,
    'job-search-page': CREATE_LAYOUT,
    'advice-hub-registration': CREATE_LAYOUT,
};
