import PropTypes from 'prop-types';
import React from 'react';
import FGBLabel from '../../Elements/FGBLabel/FGBLabel';

import './ListSection.scss';

export default class ListSection extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * title
         */
        title: PropTypes.string.isRequired,
        /**
         * button in header (optional)
         */
        headerButton: PropTypes.node,
        /**
         * children
         */
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {};

    render() {
        return (
            <div className="component-Layout-ListSection">
                <div className="listsection-header-section">
                    <FGBLabel>{this.props.title}</FGBLabel>
                    {this.props.headerButton || null}
                </div>
                {this.props.children}
            </div>
        );
    }
}
