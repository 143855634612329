import React from 'react';
import PropTypes from 'prop-types';

import './UserDesktopFooter.scss';
import DesktopFooterMenu from '../../Components/DesktopFooterMenu/DesktopFooterMenu';
import FGBLogoCopyrightDisplay from '../../Components/FGBLogoCopyrightDisplay/FGBLogoCopyrightDisplay';
import SocialMediaDisplay from '../../Components/SocialMediaDisplay/SocialMediaDisplay';

export default class UserDesktopFooter extends React.PureComponent {
    static propTypes = {
        /**
         * Number of social media likes
         */
        socialLikes: PropTypes.object.isRequired,
        /**
         * meta data for social icons
         */
        socialIcons: PropTypes.array.isRequired,
        /**
         * footer's menu for navigation
         */
        footerMenuItems: PropTypes.array.isRequired,
    };

    static defaultProps = {
        footerMenuItems: [],
        socialIcons: [],
        socialLikes: {
            facebook: '',
            linkedIn: '',
            instagram: '',
            twitter: '',
        },
    };

    render() {
        const { footerMenuItems, socialIcons, socialLikes } = this.props;

        return (
            <div className="component-Layout-Footer-DesktopFooter-UserDesktopFooter">
                <section className="desktop-footer-wrapper">
                    <FGBLogoCopyrightDisplay />

                    <DesktopFooterMenu footerMenuItems={footerMenuItems} />

                    <SocialMediaDisplay
                        socialIcons={socialIcons}
                        socialLikes={socialLikes}
                    />
                </section>
            </div>
        );
    }
}
