import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ResponsivePicture from '../../Elements/ResponsivePicture/ResponsivePicture';
import BasicDropdown from '../../Input/BasicDropdown/BasicDropdown';
import Button from '../../Input/Button/Button';
import FormElementWithLabel from '../../Input/FormElementWithLabel/FormElementWithLabel';
import Pixel from '../../Analytics/VisibilityPixel/VisibilityPixel';

import './EventRegistrationSuccessModal.scss';

const successPixelMetric = {
    misc_event_count: 1,
    misc_event_type: 'vre-appointment-time-modal-impression',
};

const breakpoints = {
    mobile: {
        width: 78,
        height: 83,
    },
    smallTablet: {
        width: 78,
        height: 83,
    },
    tablet: {
        width: 78,
        height: 83,
    },
    desktop: {
        width: 78,
        height: 83,
    },
};

const fieldLabelProps = {
    label: 'Choose an option',
    shouldShowTooltip: false,
};

export default class EventRegistrationSuccessModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            eventPreferredTimeId: null,
        };
    }

    static propTypes = {
        eventHash: PropTypes.string.isRequired,
        eventTimes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
            })
        ),
        closeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        eventTimes: [],
    };

    onSelect = (eventPreferredTimeId) =>
        this.setState({ eventPreferredTimeId });

    selectAppointmentAPICall = async () => {
        const { eventPreferredTimeId } = this.state;
        const { eventHash } = this.props;

        try {
            await axios.post(`/api/event/${eventHash}/set-preferred-time`, {
                eventPreferredTimeId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    handleModalClose = async () => {
        const { closeModal, eventTimes } = this.props;

        // no time options - close modal
        if (eventTimes.length === 0) return closeModal();

        // otherwise make API call to set preferred time and close modal
        this.selectAppointmentAPICall();

        return closeModal();
    };

    render() {
        const { eventPreferredTimeId } = this.state;
        const { eventTimes } = this.props;

        return (
            <div className="component-Events-EventRegistrationSuccessModal">
                <Pixel metrics={successPixelMetric} />
                <ResponsivePicture
                    cloudinaryID={'/v1560193582/Groups/confirmation-icon.svg'}
                    breakpoints={breakpoints}
                />
                <h1 className="event-registration-success-title">Success!</h1>
                <p className="event-registration-success-description">
                    You&apos;re registered for the event.
                </p>
                {eventTimes && !!eventTimes.length && (
                    <FormElementWithLabel fieldLabelProps={fieldLabelProps}>
                        <BasicDropdown
                            options={eventTimes}
                            defaultLabel={'Select appointment time...'}
                            onChange={this.onSelect}
                            selectedValue={eventPreferredTimeId}
                        />
                    </FormElementWithLabel>
                )}
                <div className="full-width">
                    <div className="done-button">
                        <Button
                            type={'solid'}
                            value={'Done'}
                            onClick={this.handleModalClose}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
