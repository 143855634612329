import React from 'react';
import PropTypes from 'prop-types';
import FieldError from '../../../Elements/FieldError/FieldError';

import './RadioGroup.scss';

export const RadioGroup = ({
    onChange,
    currentSelected,
    groupName,
    children,
    error,
}) => {
    const handleChange = React.useCallback(
        (payload) => onChange && onChange(payload),
        [onChange]
    );

    const hasSingleOption = React.Children.toArray(children).length === 1;

    const defaultOptionProps = {
        onChange: handleChange,
        group: groupName,
    };

    const options = React.Children.map(children, (option) => {
        const childProps = option.props;

        const childDisabled = childProps.disabled || hasSingleOption;

        const optionSelected = currentSelected === childProps.value;

        const optionProps = {
            ...childProps,
            ...defaultOptionProps,
            disabled: childDisabled,
            checked: optionSelected,
        };

        return React.cloneElement(option, optionProps);
    });

    return (
        <div
            className="component-Modals-ModalParts-Inputs-Radios-RadioGroup"
            role="radiogroup"
        >
            {options}
            <FieldError text={error} />
        </div>
    );
};

RadioGroup.propTypes = {
    onChange: PropTypes.func.isRequired,
    currentSelected: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    error: PropTypes.string,
};

RadioGroup.defaultProps = {
    error: '',
};

RadioGroup.displayName = 'RadioGroup';

export default React.memo(RadioGroup);
