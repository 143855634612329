import { getFooterMenu } from './Footer/utils';

export const serializeHeaderProps = (rawHeaderProps) => {
    const {
        pageProps,
        pageDisplayMode,
        footerType,
        socialIcons,
    } = rawHeaderProps;
    const quickApplyCompanyIds =
        pageProps.quickApplyCompanies &&
        Object.keys(pageProps.quickApplyCompanies);

    return {
        quickApplyCompanyIds,
        loginWidgetProps: {
            auth0: pageProps.auth0,
            socialButtons: pageProps.socialButtons,
            redirectUri: pageProps.redirectUri,
            currentPath: pageProps.url ? pageProps.url.path : '/',
        },
        pageDisplayMode,
        badgeCountData: rawHeaderProps.badgeCountData,
        notifications:
            rawHeaderProps.notifications &&
            rawHeaderProps.notifications.notificationData
                ? rawHeaderProps.notifications.notificationData
                : {},
        session: pageProps.session,
        checkIsLoggedIn: rawHeaderProps.isLoggedIn,
        getUserStatus: rawHeaderProps.getUserStatus,
        isAdmin: rawHeaderProps.isAdmin,
        featureFlags: pageProps.featureFlags,
        footerProps: serializeFooterProps({
            ...pageProps,
            pageDisplayMode,
            footerType,
            socialIcons,
        }),
    };
};

export const serializeFooterProps = (rawFooterProps) => {
    const {
        featureFlags,
        socialIcons,
        socialLikes,
        pageDisplayMode,
        footerType,
    } = rawFooterProps;

    return {
        featureFlags,
        socialIcons,
        socialLikes,
        pageDisplayMode,
        footerMenuItems: getFooterMenu(pageDisplayMode, footerType),
    };
};
