import PropTypes from 'prop-types';
import React from 'react';
import './UserDetails.scss';
import UserImage from '../../../../../Content/User/UserDisplay/UserImage/UserImage';
import { ellipsify } from '../../../../../../utilities/helperFunctions';

export default class UserDetails extends React.PureComponent {
    static propTypes = {
        /**
         * drawer type: nav, message
         */
        drawerType: PropTypes.oneOf(['nav-bar', 'message']),
        /**
         * URL path of profile image
         */
        profileImage: PropTypes.string,
        /**
         * URL path to user profile
         */
        profileLink: PropTypes.string.isRequired,
        /**
         * username of the user
         */
        username: PropTypes.string,
        /**
         * role of user, for message drawer
         */
        userRole: PropTypes.string,
    };

    static defaultProps = {
        profileImage: '/v1/avatar_black_160_vtg7lw.svg',
    };

    render() {
        const {
            drawerType,
            profileImage,
            profileLink,
            username,
            userRole,
        } = this.props;

        const userImage = (
            <UserImage
                iconSize={24}
                image={{
                    alt: 'user profile image',
                    src: profileImage
                        ? profileImage
                        : '/v1/avatar_black_160_vtg7lw.svg',
                }}
            />
        );

        const user = profileLink ? (
            <a className="normalize-anchor" href={profileLink}>
                {userImage}
            </a>
        ) : (
            userImage
        );

        return (
            <div
                className={`component-Layout-Header-Modules-UserDetails ${drawerType}`}
            >
                {user}
                <div className="user-details">
                    <span className="heading-text">
                        {ellipsify(username, 12)}
                    </span>
                    {userRole && <span className="user-role">{userRole}</span>}
                </div>
            </div>
        );
    }
}
