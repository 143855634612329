export const hasLocationCheck = (location) => {
    if (location) {
        const hasCoordinates = !!(
            location.coordinates &&
            location.coordinates.lat &&
            location.coordinates.lng
        );
        const hasLatLng = !!(location.lat && location.lng);
        const hasValue = !!(
            (location.value && location.value.length) ||
            (location.name && location.name.length)
        );
        return !!(location && hasValue && (hasLatLng || hasCoordinates));
    }
    return false;
};

export const getLocationProps = (location) => {
    let lat = location && location.lat;
    let lng = location && location.lng;
    const value = location && (location.value || location.name || '');
    if (location && location.coordinates) {
        lat = location.coordinates.lat;
        lng = location.coordinates.lng;
    }
    return {
        value,
        lat,
        lng,
    };
};
