import PropTypes from 'prop-types';
import React from 'react';

import GroupLogo from '../../GroupLogo/GroupLogo';

import './GroupOverviewTag.scss';

export default class GroupOverviewTag extends React.PureComponent {
    static propTypes = {
        /**
         * object of logo with src and alt
         */
        logo: PropTypes.shape({
            /**
             * string of image src
             */
            src: PropTypes.string,
            /**
             * string of image alt
             */
            alt: PropTypes.string,
        }),
        /**
         * string of group name
         */
        name: PropTypes.string.isRequired,
        /**
         * string of group description
         */
        description: PropTypes.string.isRequired,
        /**
         * string of groupUrl
         */
        groupUrl: PropTypes.string,
        /**
         * links to groups open new tab
         */
        openLinksInNewTab: PropTypes.bool,
    };

    static defaultProps = {
        logo: null,
        openLinksInNewTab: false,
    };

    render() {
        const {
            openLinksInNewTab,
            logo,
            name,
            description,
            groupUrl,
        } = this.props;
        return (
            <div className="component-Groups-Network-GroupOverviewTag">
                <a
                    href={groupUrl}
                    rel={openLinksInNewTab ? 'noopener noreferrer' : ''}
                    target={openLinksInNewTab ? '_blank' : ''}
                >
                    <div className="group-logo-container">
                        <GroupLogo logo={logo} name={name} />
                    </div>

                    <div className="overview">
                        <div className="group-name">{name}</div>
                        <div className="group-description">{description}</div>
                    </div>
                </a>
            </div>
        );
    }
}
