import { triggerSnowplowVideoEvent } from '@themuse/snowplow-js-client';

export const SnowplowVideoTracker = ({
    videoAction,
    isExternal = false,
    companyId = null,
    companyName = null,
    pageSection = null,
    pageTab = null,
    pageType = null,
    mediaId = null,
    providerKey = null,
    providerName = null,
    cardType = null,
    isSponsored = false,
    cardEntityId = null,
    cardEntityUuid = null,
}) => {
    const parsedCompanyId = companyId ? String(companyId) : null;
    const baseJson = {
        // VIDEO INFO
        sp_video_type: videoAction,
        // MEDIA INFO
        sp_media_type: 'video',
        sp_media_name: `${companyName} - Job Video`,
        sp_media_id: mediaId,
        sp_media_provider_key: providerKey,
        sp_media_provider_name: providerName,
        // CARD CONTENT
        sp_card_type: cardType,
        sp_card_is_sponsored: isSponsored,
        sp_card_entity_id: cardEntityId,
        sp_card_entity_uuid: cardEntityUuid,
        sp_card_company_id: parsedCompanyId,
    };
    const userId = window.globalPageProps?.PAGE_PROPS?.session?.id;
    const index = window.dataLayer.findIndex((obj) => 'sp_page_section' in obj);
    const pageData = {
        // PAGE INFO
        sp_page_is_external: isExternal,
        sp_page_page_company_id: null,
        sp_page_type: pageType,
        sp_page_section: pageSection,
        sp_page_tab: pageTab,
        // USER INFO
        sp_user_id: userId,
        sp_user_logged_in: !!userId,
    };
    if (index >= 0) {
        window.dataLayer[index] = pageData;
    } else {
        window.dataLayer.push(pageData);
    }

    const element = document.createElement('div');
    element.setAttribute('data-tracking-props', JSON.stringify(baseJson));

    triggerSnowplowVideoEvent(videoAction, element);
};
