import PropTypes from 'prop-types';
import React from 'react';

export default class Icons extends React.PureComponent {
    static propTypes = {
        /**
         * name of icon to be rendered
         */
        name: PropTypes.string.isRequired,
    };

    static defaultProps = {
        name: '',
    };

    renderIcon = () => {
        switch (this.props.name) {
            case 'send-message':
                return (
                    <svg
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <polygon
                                id="path-1"
                                points="0 -1.97099623e-05 19.404649 -1.97099623e-05 19.404649 15.9074179 0 15.9074179"
                            ></polygon>
                        </defs>
                        <g
                            id="Events-&amp;-Webinars-Marketing-mocks-(may-2019)"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                                id="Webinar-chat-1-Copy-2"
                                transform="translate(-1189.000000, -725.000000)"
                            >
                                <g
                                    id="Group-2"
                                    transform="translate(929.000000, 711.000000)"
                                >
                                    <g
                                        id="Send-button"
                                        transform="translate(260.000000, 14.000000)"
                                    >
                                        <circle
                                            id="Oval"
                                            fill="#982EA9"
                                            cx="16"
                                            cy="16"
                                            r="16"
                                        ></circle>
                                        <g
                                            id="Group-3"
                                            strokeWidth="1"
                                            transform="translate(6.000000, 8.000000)"
                                        >
                                            <mask id="mask-2" fill="white">
                                                <use xlinkHref="#path-1"></use>
                                            </mask>
                                            <g id="Clip-2"></g>
                                            <path
                                                d="M12.6712791,14.229011 L8.38081758,10.2893187 L17.3708835,2.47551648 L12.6712791,14.229011 Z M7.18714725,13.6417582 L7.83242198,11.4634725 L9.06934505,12.5989451 L7.18714725,13.6417582 Z M6.93554286,10.0473846 L6.32332308,12.3112967 L5.49747692,8.26206593 L14.0849055,3.69432967 L7.12666374,9.74215385 C7.03382857,9.82268132 6.96754286,9.92887912 6.93554286,10.0473846 L6.93554286,10.0473846 Z M14.625389,2.0072967 L4.96015824,7.1483956 L1.94582857,4.38074725 L14.625389,2.0072967 Z M10.002444,13.4560879 L12.4947516,15.7444396 C12.8133451,16.0368352 13.3258725,15.9202637 13.4864,15.5188571 L19.3599824,0.829274725 C19.5287736,0.407120879 19.1952352,-0.0159120879 18.7613011,0.00043956044 C18.5255209,0.0243516484 18.2719824,0.0676043956 18.2719824,0.0676043956 L0.504773626,3.39331868 C0.00437802198,3.48703297 -0.174435165,4.11156044 0.200421978,4.45582418 L4.21132308,8.13846154 L5.63338901,15.1134066 C5.7365978,15.6194286 6.32455385,15.741978 6.6485978,15.458022 L10.002444,13.4560879 Z"
                                                id="Fill-1"
                                                fill="#FFFFFF"
                                                mask="url(#mask-2)"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                );
            case 'back-caret':
                return (
                    <svg
                        width="13px"
                        height="22px"
                        viewBox="0 0 13 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g
                            id="Messaging-MASTER-designs"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                                id="messaging-page-375e"
                                transform="translate(-17.000000, -155.000000)"
                                stroke="#982EA9"
                                strokeWidth="2"
                            >
                                <g
                                    id="Group-6"
                                    transform="translate(-1.000000, 142.000000)"
                                >
                                    <g
                                        id="Group-5"
                                        transform="translate(0.000000, 6.000000)"
                                    >
                                        <polyline
                                            id="Path-17"
                                            points="30 8 20 18 30 28"
                                        ></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                );
            default:
                break;
        }
    };

    render() {
        return <React.Fragment>{this.renderIcon()}</React.Fragment>;
    }
}
