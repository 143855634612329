import PropTypes from 'prop-types';
import React from 'react';
import ArrowSVG from '../../../../SVGs/ArrowSVG/ArrowSVG';

import './DesktopFooterMenu.scss';

export default class DesktopFooterMenu extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            selectedSubNavigation: '',
        };
    }

    static propTypes = {
        /**
         * footer navigation list
         */
        footerMenuItems: PropTypes.array.isRequired,
        /**
         * styling that would override default
         */
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
            .isRequired,
    };

    static defaultProps = {
        footerMenuItems: [],
        className: '',
    };

    handleSelectSubNavigation = (selectedSubNavigation) => {
        selectedSubNavigation === this.state.selectedSubNavigation
            ? this.setState({
                  selectedSubNavigation: '',
              })
            : this.setState({
                  selectedSubNavigation,
              });
    };

    renderArrowSVG = (key) =>
        this.state.selectedSubNavigation === key ? (
            <ArrowSVG direction="down" data-test={`nav-item-arrow-${key}`} />
        ) : (
            <ArrowSVG direction="right" data-test={`nav-item-arrow-${key}`} />
        );

    // menu item that has no child
    renderMenuItem = (item) => {
        return (
            <li className="nav-list-item" key={item.key}>
                <a
                    className="anchor-link nav-list-item-title nav-list-item-title-uppercase"
                    key={item.key}
                    href={item.url}
                    target={item.target || '_self'}
                >
                    {item.key}
                </a>
            </li>
        );
    };

    // menu item that has child menu
    renderExpandableMenuItem = (item) => {
        return (
            <li className="nav-list-item" key={item.key}>
                <span
                    onClick={this.handleSelectSubNavigation.bind(
                        this,
                        item.key
                    )}
                    className={`anchor-link nav-list-item-title nav-list-item-title-uppercase
                    ${
                        this.state.selectedSubNavigation == item.key
                            ? `sublist-${this.state.selectedSubNavigation}-selected`
                            : ''
                    }`}
                    data-test={`nav-expandable-item-${item.key}`}
                >
                    {item.title} {this.renderArrowSVG(item.key)}
                </span>
            </li>
        );
    };

    // child menu will be rendered when parent menu item is clicked
    renderExpandedMenuItem = () => {
        const { footerMenuItems } = this.props;
        const { selectedSubNavigation } = this.state;

        if (!selectedSubNavigation) {
            return null;
        }

        const footerMenuItem = footerMenuItems
            .filter((menuItem) => menuItem.key === selectedSubNavigation)
            .pop();

        return (
            <ul className="nav-sub-list">
                {footerMenuItem.children.map((item) => {
                    const emailToStyle =
                        item.key === 'contact us email'
                            ? 'anchor-link--email'
                            : '';

                    return (
                        <li
                            className="nav-list-item nav-sub-list-item"
                            key={item.key}
                            data-test={`expanded-item-${item.key}`}
                        >
                            <a
                                className={`anchor-link nav-list-item-title ${emailToStyle}`}
                                key={item.key}
                                href={item.url}
                                target={item.target || '_self'}
                            >
                                {item.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    };

    render() {
        return (
            <section
                className={`component-Layout-Footer-Components-DesktopFooterMenu ${this.props.className}`}
            >
                <ul className="nav-list">
                    {this.props.footerMenuItems.map((item) =>
                        item.children
                            ? this.renderExpandableMenuItem(item)
                            : this.renderMenuItem(item)
                    )}
                </ul>
                {this.state.selectedSubNavigation &&
                    this.renderExpandedMenuItem()}
            </section>
        );
    }
}
