import React from 'react';
import PropTypes from 'prop-types';

import './CheckBox.scss';

export const renderLabel = (label, styling) => {
    return label ? <div className={`label ${styling}`}>{label}</div> : null;
};

export default class CheckBox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.defaultChecked,
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.defaultChecked !== this.props.defaultChecked) {
            this.setState({ checked: nextProps.defaultChecked });
        }
    }

    // for not changing checked in state if requires user confirmation
    componentDidUpdate(prevProps) {
        if (prevProps.defaultChecked !== this.state.checked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: prevProps.defaultChecked });
        }
    }

    handleCheck = (event) => {
        const value = event.target.checked;
        this.setState({
            checked: value,
        });
        this.props.handleChange(value, this.props.inputValue);
    };

    render() {
        const boldedLabel = this.state.checked === true ? 'bolded' : null;
        const checked = this.state.checked === true ? 'checked' : '';
        const disabledStyling = this.props.disabled ? 'disabled-styling' : '';
        return (
            <div
                className={`component-Content-Elements-CheckBox d-inline-flex ${this.props.className} ${disabledStyling}`}
            >
                <label
                    data-qa="checkBoxLabel"
                    htmlFor={this.props.id}
                    className="inputLabel"
                >
                    <div
                        className={`round d-flex justify-content-center align-items-center ${checked}`}
                    >
                        <input
                            data-qa={this.props.qaDataAttr}
                            type="checkbox"
                            id={this.props.id}
                            name={this.props.id}
                            checked={this.state.checked}
                            value={this.props.inputValue}
                            onChange={this.handleCheck}
                            disabled={this.props.disabled}
                        />
                    </div>
                    {renderLabel(this.props.label, boldedLabel)}
                </label>
            </div>
        );
    }
}

CheckBox.propTypes = {
    /**
     * The id for what the input is for.
     */
    id: PropTypes.string.isRequired,
    /**
     * The words next to checkbox
     */
    label: PropTypes.string,
    /**
     * value for the checkbox
     */
    inputValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    defaultChecked: PropTypes.bool,
    /**
     * passed in on change function
     */
    handleChange: PropTypes.func,
    /**
     * classname if necessary
     */
    className: PropTypes.string,
    /**
     * value for qa data attribute
     */
    qaDataAttr: PropTypes.string,
    disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
    inputValue: 'anonymous',
    label: '',
    defaultChecked: false,
    handleChange: () => {},
    className: '',
    qaDataAttr: '',
    disabled: false,
};
