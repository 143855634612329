import PropTypes from 'prop-types';
import React from 'react';
import DropdownWithIcon from '../../Input/DropdownWithIcon/DropdownWithIcon';
import PaginationNav from '../../ReviewSurvey/Components/PaginationNav/PaginationNav';
import { getInviteConnectionsImages } from '../InvitationWidget';
import './GroupLayout.scss';

export default class GroupLayout extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            group: props.group || null,
            errorgroup: null,
        };
    }

    static propTypes = {
        group: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }),
        onNext: PropTypes.func,
        onPrev: PropTypes.func,
        groupsArray: PropTypes.array,
        showPrevBtn: PropTypes.bool,
    };

    static defaultProps = {
        group: null,
        onNext: () => {},
        onPrev: () => {},
        showPrevBtn: true,
    };

    handleSelect = (group) => {
        this.setState({ group, errorgroup: null });
    };

    onNext = () => {
        if (this.state.group && this.state.group.hash) {
            this.props.onNext('group', this.state.group);
        }
        this.setState({
            errorgroup: 'Please select a group.',
        });
    };

    render() {
        const buttonSectionProps = {
            showPrevBtn: this.props.showPrevBtn,
            showNextBtn: true,
            PrevText: `Back`,
            NextText: 'Next >',
            prevDisabled: false,
            nextDisabled: false,
            onNext: this.onNext,
            onPrev: this.props.onPrev,
        };
        const groups = this.props.groupsArray;
        return (
            <div className="component-InvitationWidget-GroupLayout">
                <div className="invitation-widget-content-container">
                    <img
                        alt={'icon'}
                        className="mail-icon"
                        src={getInviteConnectionsImages(
                            '/v1/SVGs/invite_mail.svg',
                            'icon'
                        )}
                    />
                    <div className="input-label">Invite to*</div>
                    <DropdownWithIcon
                        items={groups}
                        onSelect={this.handleSelect}
                        selection={this.state.group}
                    />
                    {this.state.errorgroup && (
                        <div className="errorMessage">
                            {this.state.errorgroup}
                        </div>
                    )}
                </div>
                <PaginationNav {...buttonSectionProps} />
            </div>
        );
    }
}
