import PropTypes from 'prop-types';
import React from 'react';
import { getCloudinaryImagePathById } from '../../../../utilities/images';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';
import CompanyTypeahead from '../../../Input/CompanyTypeahead/CompanyTypeahead';
import './TypeaheadInputCard.scss';

export default class TypeaheadInputCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            img: props.defaultImg,
        };
    }

    static propTypes = {
        inputType: PropTypes.oneOf(['location', 'default']).isRequired,
        inputLabel: PropTypes.string,
        defaultImg: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string,
        }).isRequired,
        //This is only required if the inputType is default
        createNewText: PropTypes.string,
        placeholder: PropTypes.string,
        company: PropTypes.object,
        location: PropTypes.object,
        countries: PropTypes.object,
        resetInput: PropTypes.bool,
    };

    handleSelected = (selected) => {
        this.setState(
            {
                img: selected.logo || this.props.defaultImg,
            },
            this.props.handleSelected(selected)
        );
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.resetInput && this.props.resetInput) {
            this.setState({ img: this.props.defaultImg });
        }
    };

    render() {
        const injectedPath = {
            icon: 'c_lpad,f_auto,q_auto,w_120,h_120',
        };
        return (
            <div className="component-ReviewSurvey-QuestionCards-TypeaheadInputCard">
                <div className="input-section">
                    <div className="label">{this.props.inputLabel}</div>
                    {this.props.inputType === 'location' ? (
                        <BasicDropdown
                            options={this.props.countries}
                            defaultLabel={this.props.placeholder}
                            onChange={this.handleSelected}
                            selectedValue={
                                this.props.location && this.props.location.name
                                    ? this.props.location.name
                                    : null
                            }
                        />
                    ) : (
                        <CompanyTypeahead
                            onUpdate={this.handleSelected}
                            createNewText={this.props.createNewText}
                            placeholder={this.props.placeholder}
                            items={
                                this.props.company ? [this.props.company] : []
                            }
                            addOption={this.props.addOption}
                            resetInput={this.props.resetInput}
                            allowAPICreation={false}
                        />
                    )}
                </div>
                <img
                    className={'icon'}
                    src={getCloudinaryImagePathById(
                        this.state.img.src,
                        'icon',
                        injectedPath
                    )}
                />
            </div>
        );
    }
}
