/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/sort-comp */
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import NoSSR from 'react-no-ssr';
import Visibility from 'react-visibility-sensor';
import {
    convertMetricToSnowPlowImpressionTracker,
    SnowplowImpressionTracker,
} from '../Snowplow/SnowplowImpressionViewTracker';
import { convertMetricToSnowPlowClickTracker } from '../Snowplow/SnowplowClickTracker';

const VisibilityPixel = ({
    metrics = null,
    visibilityCallback = null,
    singleFire = false,
    tracking = true,
    shouldEmitImpressionMetric = false,
    snowplowJobImpressionsMetrics = null,
}) => {
    const [hasFired, setHasFired] = useState(false);

    const onVisChange = (isVis) => {
        let alreadyFiredForSession = false;
        let snowPlowTracking = true;
        if (singleFire)
            alreadyFiredForSession = window.sessionStorage.getItem(singleFire);
        let trackingStorage = null;
        if (tracking) {
            trackingStorage = `${singleFire}${tracking}${metrics?.snowPlow?.searchId}`;
            snowPlowTracking = window.sessionStorage.getItem(trackingStorage);
        }
        if (
            isVis &&
            metrics?.snowPlow &&
            trackingStorage &&
            !snowPlowTracking
        ) {
            if (!metrics) return;
            convertMetricToSnowPlowImpressionTracker(metrics.snowPlow);
            window.sessionStorage.setItem(trackingStorage, true);
            delete metrics.snowPlow;
        }

        if (isVis && !hasFired && !alreadyFiredForSession) {
            setHasFired(true);
            if (visibilityCallback) visibilityCallback();
            if (!metrics) return;
            metrics.type = 'pixel';
            EmitMetric(metrics, 'pixel');

            if (singleFire) window.sessionStorage.setItem(singleFire, 'true');
        }

        if (isVis && shouldEmitImpressionMetric && !hasFired) {
            setHasFired(true);
            if (!snowplowJobImpressionsMetrics) return;
            SnowplowImpressionTracker({
                ...snowplowJobImpressionsMetrics,
                fromCompanyJobsPage: true,
            });
        }
    };
    return (
        <div style={{ width: '1px', height: '1px' }}>
            <NoSSR>
                <Visibility onChange={(isVis) => onVisChange(isVis)}>
                    <div style={{ width: '1px', height: '1px' }} />
                </Visibility>
            </NoSSR>
        </div>
    );
};

export const setLastActionParams = (lac, lat) => {
    const trackingPropsHsh = getTrackingPropsCookie() || {};

    trackingPropsHsh.last_action_category = lac;
    trackingPropsHsh.last_action_type = lat;

    Cookies.set('TRACKING_PROPS', `j:${JSON.stringify(trackingPropsHsh)}`, {
        expires: 1 / 12, // 2 hours
        sameSite: 'None',
        secure: true,
    });
};

export const isDebug = () => {
    if (window && window.globalPageData && window.globalPageData.analytics) {
        return window.globalPageData.analytics.debug === true;
    }
    return false;
};

export const buildMetrics = (metrics, type) => {
    metrics.url = window.location.pathname;
    if (metrics.snowPlow) delete metrics.snowPlow;
    const url = new URL(window.location);

    if (window.globalPageProps?.PAGE_PROPS?.session?.id) {
        metrics.user_id = window.globalPageProps?.PAGE_PROPS?.session?.id;
    }

    metrics.fgb_id = Cookies.get('FGB_ID');

    if (url.searchParams.get('utm_source')) {
        metrics.utmSource = url.searchParams.get('utm_source');
        metrics.utm_source = url.searchParams.get('utm_source');
    }

    if (url.searchParams.get('utm_campaign')) {
        metrics.utmCampaign = url.searchParams.get('utm_campaign');
        metrics.utm_campaign = url.searchParams.get('utm_campaign');
    }

    if (url.searchParams.get('utm_medium')) {
        metrics.utmMedium = url.searchParams.get('utm_medium');
        metrics.utm_medium = url.searchParams.get('utm_medium');
    }

    if (url.searchParams.get('LAT')) {
        metrics.last_action_type = url.searchParams.get('LAT');
    }

    if (url.searchParams.get('LAC')) {
        metrics.last_action_category = url.searchParams.get('LAC');
    }

    // Include incoming referrer if exists
    const referrer = getValueFromTrackingProps('referrer');
    if (referrer) {
        metrics.referrer = referrer;
    }

    if (isDebug()) {
        // eslint-disable-next-line no-console
        console.log(`Debug ${type} metrics:`, metrics);
    }

    const metricsString = JSON.stringify(metrics);
    return encodeURIComponent(metricsString);
};

export const getPageMetrics = () => {
    let metricsData = { source: 'frontend' };
    if (
        window &&
        window.globalPageData &&
        window.globalPageData.analytics &&
        window.globalPageData.analytics.metrics
    ) {
        metricsData = window.globalPageData.analytics.metrics;
    }

    return metricsData;
};

export const getMetricData = (metrics) => {
    const fullMetrics = { ...getPageMetrics(), ...metrics };
    return fullMetrics;
};

export const EmitPage = (metrics = {}, frontendRoutingEnabled) => {
    if (
        !window ||
        (window.__pageMetricsFired === 1 && !frontendRoutingEnabled)
    ) {
        return;
    }

    if (window) {
        window.__pageMetricsFired = 1;
    }

    Axios.get(
        `/_m/page?metrics=${buildMetrics(getMetricData(metrics), 'page')}`
    );
};

export const EmitMetric = (metrics = {}, type = 'manual') => {
    if (
        metrics?.snowPlow &&
        (metrics.click ||
            metrics.vote ||
            metrics.share ||
            metrics?.snowPlow?.click)
    )
        convertMetricToSnowPlowClickTracker(metrics.snowPlow);
    else if (metrics?.snowPlow)
        convertMetricToSnowPlowImpressionTracker(metrics.snowPlow);
    Axios.get(
        `/_m/emit?metrics=${buildMetrics(getMetricData(metrics), 'emit')}`
    );
};

export const getTrackingPropsCookie = () => {
    const trackingProps = Cookies.get('TRACKING_PROPS');

    return trackingProps ? JSON.parse(trackingProps.split('j:')[1]) : null;
};

export const getValueFromTrackingProps = (key) => {
    const trackingPropsHsh = getTrackingPropsCookie();

    return trackingPropsHsh ? trackingPropsHsh[key] : null;
};

export default VisibilityPixel;
