/* eslint-disable import/no-cycle */
import React from 'react';
import { ModalContext } from '../../../../contexts/ContextCreator';
import FlowShell from '../FlowShell/FlowShell';
import QuickProfile from '../Steps/QuickProfile/QuickProfile';
import { buildQuickProfileOnboardingFlow } from './constants';
import { buttonNavBox } from './NavButtons';
import useCookie from '../../../../utilities/hooks/useCookie';
import useMount from '../../../../utilities/hooks/useMount';

export const quickProfileOnboardingFlowType = 'quick-profile-onboarding-flow';
/**
 * if new user, overlay after registration
 */
export const QuickProfileOnboardingFlow = () => {
    const { closeModal, generalModalProps } = React.useContext(ModalContext);

    const [, , deleteOnboardingCookie] = useCookie('quickRegistrationModal');
    useMount(deleteOnboardingCookie);

    const {
        toggleJobProfileSnippetComplete,
        showSkipButton,
    } = generalModalProps;

    const initializeOptions = {
        closeModal,
        toggleJobProfileSnippetComplete,
        showSkipButton,
    };

    const {
        flowTree,
        initialStep,
        initialFlowData,
    } = buildQuickProfileOnboardingFlow(initializeOptions);

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={null}
        >
            <QuickProfile buttonNavBox={buttonNavBox} showName={false} />
        </FlowShell>
    );
};

QuickProfileOnboardingFlow.displayName = 'QuickProfileOnboardingFlow';

export default React.memo(QuickProfileOnboardingFlow);
