/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { PAGE_DISPLAY_MODE, ROLE_TYPES } from './constants';

/**
 * Value of the view layout the page is in
 */
export const pageDisplayModePropTypes = PropTypes.oneOf([
    PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT,
    PAGE_DISPLAY_MODE.USER_DEFAULT,
]);

/**
 * Type of userRoles
 */
export const userRolePropTypes = PropTypes.oneOf([
    ROLE_TYPES.UNAUTH,
    ROLE_TYPES.USER_ADMIN,
    ROLE_TYPES.USER_CONTRIBUTOR,
    ROLE_TYPES.USER_DEFAULT,
    ROLE_TYPES.EMPLOYER_DEFAULT,
    ROLE_TYPES.EMPLOYER_ADMIN,
    ROLE_TYPES.EMPLOYER_RECRUITER,
]);

/**
 * global page props
 */
export const PAGE_PROPS = PropTypes.shape({
    /**
     * Props for Header
     */
    headerProps: PropTypes.object,
    /**
     * Props from footer
     */
    footerProps: PropTypes.object,
    /**
     * social media likes count
     */
    socialLikes: PropTypes.shape({
        facebook: PropTypes.string,
        twitter: PropTypes.string,
    }),
    /**
     * bottom banner props
     */
    bottomBanner: PropTypes.shape({
        /**
         * company name
         */
        name: PropTypes.string,
        /**
         * company url
         */
        url: PropTypes.string,
        /**
         * company logo
         */
        logo: PropTypes.string,
        /**
         * quote to display on banner
         */
        quote: PropTypes.string,
        /**
         * type of banner
         */
        type: PropTypes.string,
    }),
    /**
     * meta data for social sharing
     */
    meta: PropTypes.shape({
        /**
         * host url
         */
        hostUrl: PropTypes.string,
        /**
         * title to be displayed on sharing
         */
        title: PropTypes.string,
        /**
         * description of sharing
         */
        description: PropTypes.string,
        /**
         * keywords about sharing
         */
        keywords: PropTypes.string,
        /**
         * detail information for each social media sharing
         */
        social: PropTypes.shape({
            twitter: PropTypes.shape({
                /**
                 * source url
                 */
                url: PropTypes.string,
                /**
                 * sharing title
                 */
                title: PropTypes.string,
                /**
                 * sharing description
                 */
                description: PropTypes.string,
                /**
                 * sharing brief info
                 */
                text: PropTypes.string,
                /**
                 * url of image to be displayed on sharing
                 */
                image: PropTypes.string,
            }),
            facebook: PropTypes.shape({
                /**
                 * The Facebook App Id
                 */
                appId: PropTypes.string,
                /**
                 * source url
                 */
                url: PropTypes.string,
                /**
                 * sharing title
                 */
                title: PropTypes.string,
                /**
                 * sharing discription
                 */
                description: PropTypes.string,
                /**
                 * url of image to be displayed on sharing
                 */
                image: PropTypes.string,
            }),
        }),
    }),
});

export const job = PropTypes.shape({
    /**
     * job image url & title
     */
    image: PropTypes.shape({
        src: PropTypes.string,
        title: PropTypes.string,
    }),
    /**
     * job title
     */
    title: PropTypes.string,
    /**
     * whether to highlight the job
     */
    highlight: PropTypes.oneOf([true, false]),
    /**
     * job location
     */
    location: PropTypes.string,
    /**
     * job view page url
     */
    url: PropTypes.url,
    /**
     * url to apply the job
     */
    applyUrl: PropTypes.string,
    /**
     * job category information
     */
    category: PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    /**
     * hiring company information
     */
    company: PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    /**
     * company logo url
     */
    companyLogo: PropTypes.string,
    /**
     * job description
     */
    description: PropTypes.string,
    /**
     * job general review information
     */
    review: PropTypes.shape({
        url: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
    }),
    /**
     * third party tracking pixel infos
     */
    pixels: PropTypes.shape({
        view: PropTypes.string,
    }),
    directApplyFlag: PropTypes.bool,
    datePosted: PropTypes.string,
});

export const singleTopicPropTypes = PropTypes.shape({
    id: PropTypes.number,
    isDefault: PropTypes.number,
    layout: PropTypes.string,
    qaDataAttr: PropTypes.string,
    sponsored: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    title: PropTypes.string,
    total: PropTypes.number,
    url: PropTypes.string,
});

export const singleCommentPropTypes = {
    hash: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    parentHash: PropTypes.string,
    parentType: PropTypes.string,
    parentContentType: PropTypes.string.isRequired,
    parentContentHash: PropTypes.string.isRequired,
    moderationOption: PropTypes.shape({
        status: PropTypes.string,
        editableFields: PropTypes.arrayOf(PropTypes.string),
    }),
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    contentDate: PropTypes.string.isRequired,
    /**
     * This is the nubmer of direct children for the comment
     */
    commentCount: PropTypes.number.isRequired,
    isAnon: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    /**
     * Comment Text
     */
    comment: PropTypes.string.isRequired,
    likes: PropTypes.number,
    author: PropTypes.shape({
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hash: PropTypes.string.isRequired,
        image: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string,
        }).isRequired,
        credential: PropTypes.string,
        userId: PropTypes.number.isRequired,
        location: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        userCommunityData: PropTypes.shape({}),
        relation: PropTypes.shape({
            isFollowing: PropTypes.bool,
            connectionStatus: PropTypes.string,
        }),
        isHighlightedUser: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
        publicUrl: PropTypes.string.isRequired,
        isCommunityManager: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
        karmaScore: PropTypes.string,
    }).isRequired,
    time: PropTypes.string.isRequired,
};

/**
 * default props for job PropTypes
 */
export const jobDefault = {
    image: {
        src: '',
        title: '',
    },
    title: '',
    highlight: false,
    location: '',
    url: '',
    applyUrl: '',
    category: {
        url: '',
        name: '',
    },
    company: {
        url: '',
        name: '',
    },
    description: '',
    review: {
        url: '',
        count: 0,
        rating: 0,
    },
    pixels: {},
    directApplyFlag: false,
};

export const singleCommentDefaultProps = PropTypes.shape({
    hash: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    parentHash: PropTypes.string,
    parentType: PropTypes.string,
    parentContentType: PropTypes.string.isRequired,
    parentContentHash: PropTypes.string.isRequired,
    moderationOption: PropTypes.shape({
        status: PropTypes.string,
        editableFields: PropTypes.arrayOf(PropTypes.string),
    }),
    comments: PropTypes.arrayOf(singleCommentPropTypes),
    contentDate: PropTypes.string.isRequired,
    /**
     * This is the nubmer of direct children for the comment
     */
    commentCount: PropTypes.number.isRequired,
    isAnon: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    /**
     * Comment Text
     */
    comment: PropTypes.string.isRequired,
    likes: PropTypes.number,
    author: PropTypes.shape({
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hash: PropTypes.string.isRequired,
        image: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string,
        }).isRequired,
        credential: PropTypes.string,
        userId: PropTypes.number.isRequired,
        location: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        userCommunityData: PropTypes.shape({}),
        relation: PropTypes.shape({
            isFollowing: PropTypes.bool,
            connectionStatus: PropTypes.string,
        }),
        isHighlightedUser: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
        publicUrl: PropTypes.string.isRequired,
        isCommunityManager: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
        karmaScore: PropTypes.string,
    }).isRequired,
    time: PropTypes.string.isRequired,
});

export const articleContentProps = PropTypes.shape({
    group: PropTypes.string,
    type: PropTypes.string,
    hash: PropTypes.string,
    lastId: PropTypes.number,
    ancestorHash: PropTypes.string,
    commentCount: PropTypes.number,
    comments: PropTypes.arrayOf(PropTypes.shape(singleCommentPropTypes)),
});
