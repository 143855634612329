import React from 'react';

import FlowShell from '../FlowShell/FlowShell';
import { ModalContext } from '../../../../contexts/ContextCreator';

import { buildDiversityInputFlow, confirmSuccessStepProps } from './constants';

import GenderIdentity from '../Steps/GenderIdentity/GenderIdentity';
import RaceEthnicity from '../Steps/RaceEthnicity/RaceEthnicity';
import SexualOrientation from '../Steps/SexualOrientation/SexualOrientation';
import VeteranDisability from '../Steps/VeteranDisability/VeteranDisability';
import ConfirmSuccess from '../Steps/ConfirmSuccess/ConfirmSuccess';

import {
    genderIdentityNavBox,
    raceEthnicityNavBox,
    sexualOrientationNavBox,
    veteranDisabilityNavBox,
} from './navButtons';

export const diversityInputFlowType = 'diversity-input-flow';

export const DiversityInputFlow = (props) => {
    const { closeModal, generalModalProps } = React.useContext(ModalContext);

    const initializeOptions = {
        closeModal,
        ...generalModalProps,
    };

    const { flowTree, initialStep, initialFlowData } = buildDiversityInputFlow(
        initializeOptions
    );

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={closeModal}
            hasNoPadding
        >
            <GenderIdentity buttonNavBox={genderIdentityNavBox} />
            <RaceEthnicity buttonNavBox={raceEthnicityNavBox} />
            <SexualOrientation buttonNavBox={sexualOrientationNavBox} />
            <VeteranDisability buttonNavBox={veteranDisabilityNavBox} />
            <ConfirmSuccess {...confirmSuccessStepProps} />
        </FlowShell>
    );
};

DiversityInputFlow.displayName = 'DiversityInputFlow';

export default React.memo(DiversityInputFlow);
