import PropTypes from 'prop-types';
import React from 'react';

import './SocialMediaIcons.scss';
import { facebookIcon, instagramIcon, linkedInIcon, twitterIcon } from './svgs';

export default class SocialMediaIcons extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        type: PropTypes.oneOf(['facebook', 'instagram', 'linkedIn', 'twitter']),
        url: PropTypes.string,
        count: PropTypes.string,
    };

    static defaultProps = {
        type: 'facebook',
        url: '',
        count: '',
    };

    render() {
        const sources = {
            facebook: facebookIcon,
            instagram: instagramIcon,
            linkedIn: linkedInIcon,
            twitter: twitterIcon,
        };
        return (
            <div
                className={`component-Icons-SocialMediaIcons ${this.props.type}`}
            >
                <a
                    href={this.props.url}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <div className="size-div">{sources[this.props.type]}</div>
                </a>
                {this.props.count ? (
                    <div className="count-display">{this.props.count}</div>
                ) : null}
            </div>
        );
    }
}
