import React from 'react';

export default () => (
    <svg
        width="16"
        height="19"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        className="trash-can"
    >
        <g
            stroke="#CCC"
            strokeWidth="1.6"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
        >
            <path
                strokeLinejoin="round"
                d="M11 3.622V1.788H5v1.834M2 4.585l.665 12.793h10.667L14 4.538"
            />
            <path d="M1 4.539h14" />
        </g>
    </svg>
);
