import Yup from 'yup';
import constants from '../Constants/jobSeeker';
import { validateYupSchema } from './UserProfileSchema';

const commonSchema = {
    event: Yup.object().shape(),
    email: Yup.string().when('event.requireEmail', {
        is: true,
        then: Yup.string()
            .email('Must be a valid email address')
            .required('Email is a required field.'),
        otherwise: Yup.string().nullable(),
    }),
    firstName: Yup.string().when('event.sourceType', {
        is: 'hopin vcf',
        then: Yup.string()
            .required('First name is a required field.')
            .max(50, 'Please limit this to 50 chars'),
        otherwise: Yup.string().nullable(),
    }),
    lastName: Yup.string().when('event.sourceType', {
        is: 'hopin vcf',
        then: Yup.string()
            .required('Last name is a required field.')
            .max(50, 'Please limit this to 50 chars'),
        otherwise: Yup.string().nullable(),
    }),
    isAuthorizedToWork: Yup.boolean().when('event.requireWorkAuthQuestion', {
        is: true,
        then: Yup.boolean().required('This is a required field.'),
        otherwise: Yup.boolean().nullable(),
    }),
    isRequiredSponsorship: Yup.boolean().when('event.requireSponsorQuestion', {
        is: true,
        then: Yup.boolean().required('This is a required field.'),
        otherwise: Yup.boolean().nullable(),
    }),
    isCurrentStudent: Yup.boolean().when('event.requireStudentQuestion', {
        is: true,
        then: Yup.boolean().required('This is a required field.'),
        otherwise: Yup.boolean().nullable(),
    }),
    yearsOfExperience: Yup.mixed().when('event.requireYearExpDropdown', {
        is: true,
        then: Yup.mixed()
            .oneOf(Object.values(constants.YEARS_OF_EXPERIENCE))
            .required('Years of experience is a required field.'),
        otherwise: Yup.mixed().nullable(),
    }),
};

export const eventRegistrationSchema = Yup.object().shape({
    resumeUrl: Yup.string().notRequired(),
    resumeFileName: Yup.string().when('event.requireResume', {
        is: true,
        then: Yup.string().required('This event requires a resume.'),
        otherwise: Yup.string().nullable(),
    }),
    ...commonSchema,
});

export const eventRegistrationApiSchema = Yup.object().shape({
    s3Url: Yup.string().when('event.requireResume', {
        is: true,
        then: Yup.string().required('This event requires a resume.'),
        otherwise: Yup.string().nullable(),
    }),
    resumeFileName: Yup.string().when('event.requireResume', {
        is: true,
        then: Yup.string().required('This event requires a resume.'),
        otherwise: Yup.string().nullable(),
    }),
    ...commonSchema,
});

export const validateEventRegistrationForm = async (payload) => {
    const { valid, errors } = await validateYupSchema(
        payload,
        eventRegistrationSchema
    );

    return { valid, errors };
};
