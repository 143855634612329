import StreamAnalytics from 'stream-analytics';

export const objectTypes = () => {
    return window.__GLOBAL_PAGE_PROPS__.PAGE_PROPS.objectTypes;
};

export const initializeClient = () => {
    const apikey = window.__GLOBAL_PAGE_PROPS__.PAGE_PROPS.streamAnalyticsKey;
    const apiToken =
        window.__GLOBAL_PAGE_PROPS__.PAGE_PROPS.streamAnalyticsToken;
    return new StreamAnalytics({
        apiKey: apikey,
        token: apiToken,
    });
};

export const emitEngagement = (
    { id, name },
    {
        label = 'click',
        score = 1,
        content,
        feedId = undefined,
        location = undefined,
        position = undefined,
    }
) => {
    const client = initializeClient();
    client.setUser({ id, alias: name });
    const engagement = {
        // the label for the engagement, ie click, retweet etc.
        label,
        // the ID of the content that the user clicked
        content,
        // score between 0 and 100 indicating the importance of this event
        // IE. a like is typically a more significant indicator than a click
        score,
        // (optional) the position in a list of activities
        position,
        // (optional) the feed the user is looking at
        feed_id: feedId,
        // (optional) the location in your app. ie email, profile page etc
        location,
    };

    client.trackEngagement(engagement);
};

export const emitImpression = (
    { id, name },
    { foreignId, actor, verb, object },
    feedId
) => {
    const client = initializeClient();
    client.setUser({ id, alias: name });
    const impression = {
        content_list: [
            {
                // ie 'post:42'
                foreign_id: foreignId,
                // ie. {'id': 'user:2353540'}
                actor,
                // ie. 'share'
                verb,
                // Object example: {'id': 'song:34349698'}
                object,
            },
        ],
        // feed id example 'timeline:tom'
        feed_id: feedId,
    };

    client.trackImpression(impression);
};
