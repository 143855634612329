import PropTypes from 'prop-types';
import React from 'react';

import { PageContext } from '../../../../contexts/ContextCreator';

import { MessageConsumer } from '../../../../contexts/MessageContext';
import CommunityInviteButton from '../../../Community/CommunityInviteButton/CommunityInviteButton';
import UserImage from '../../../Content/User/UserDisplay/UserImage/UserImage';
import HoverCallToAction from '../HoverCallToAction';

import './UserLayout.scss';

export default class UserLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        creator: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        requestConnection: PropTypes.func.isRequired,
        updateConnectStatus: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    static contextType = PageContext;

    getUserTypeText = () => {
        const { creator } = this.props;
        const { userCommunityData } = creator;

        const isVip = (userCommunityData && userCommunityData.isVip) || false;
        if (isVip) return 'FGB VIP';
        if (
            creator.username === 'Anonymous' ||
            creator.username === 'Fairygodboss'
        )
            return 'FGB MEMBER';
        return null;
    };

    getUserDisplayName = () => {
        const { creator } = this.props;
        const { name, username } = creator;

        if (name) return name;
        return username;
    };

    // Basic User Info Section
    renderUserLayout = () => {
        const { creator } = this.props;

        const { userCommunityData, credential } = creator;

        const isVip = (userCommunityData && userCommunityData.isVip) || false;

        const isAnonymous = creator.username === 'Anonymous';
        const hasCredential = credential && credential.length > 0;

        const userTypeText = this.getUserTypeText();

        const userDisplayName = this.getUserDisplayName();

        const userTypeSection = userTypeText ? (
            <div className="user-type-display">{userTypeText}</div>
        ) : null;

        const credentialDisplay =
            hasCredential && !isAnonymous ? (
                <div className="credential-display">{credential}</div>
            ) : null;

        return (
            <section className="user-info-section">
                <UserImage image={creator.image} isVip={isVip} iconSize={72} />
                <div className="user-info-display">
                    <div className="username-display">{userDisplayName}</div>
                    {userTypeSection}
                    {credentialDisplay}
                </div>
            </section>
        );
    };

    // Button Section
    renderButtonSection = (toggleMsgDrawer) => {
        const { pageProps, groups } = this.context;
        const { creator, user } = this.props;

        const isAnonymous = creator && creator.username === 'Anonymous';
        const isSameUser = creator.userId && user && user.id === creator.userId;

        if (isAnonymous || isSameUser || !(creator && creator.userId))
            return null;

        const isGroupAdmin = pageProps.session.id && groups.isGroupAdmin();

        const groupInviteButton = (
            <CommunityInviteButton
                metricEventType="invite-to-group-click-user-hover"
                inviteType="group"
                user={creator}
                buttonType="wired-blue"
                value="Invite to group"
                callToActionHash="invite-to-group"
                loginTrigger="invite-to-group"
            />
        );

        const viewButton = (
            <a
                href={creator.publicUrl}
                className="view-button call-to-action-button"
            >
                View
            </a>
        );

        const networkButtonProps = this.getNetworkButtonProps(toggleMsgDrawer);

        const networkButton = (
            <CommunityInviteButton
                inviteType="connection"
                user={creator}
                {...networkButtonProps}
            />
        );

        const viewButtonCn = `view-button-wrapper ${
            isGroupAdmin ? 'small-width' : ''
        }`.trim();

        return (
            <section className="button-section">
                {isGroupAdmin ? (
                    <div className="group-invite-button-wrapper">
                        {groupInviteButton}
                    </div>
                ) : null}

                <div className={viewButtonCn}>{viewButton}</div>

                <div className={`${networkButtonProps.className}`}>
                    {networkButton}
                </div>
            </section>
        );
    };

    // Connect or Message Button Logic
    getNetworkButtonProps = (toggleMsgDrawer) => {
        const { updateConnectStatus, creator } = this.props;
        let value;
        let buttonType;
        let disabled;
        let className;
        let onClick = null;
        let onSuccess;
        switch (creator.relation.connectionStatus) {
            case 'requested':
                value = 'Requested';
                buttonType = 'primary-blue-gray';
                disabled = true;
                className = 'network-button requested-button';
                break;
            case 'connected':
                value = 'Message';
                buttonType = 'solid';
                className = 'network-button message-user-button';
                onClick = () => toggleMsgDrawer(creator.userId);
                break;
            case 'invited':
                value = 'Invited';
                buttonType = 'primary-blue-gray';
                className = 'network-button invited-button';
                disabled = true;
                break;
            default:
                value = 'Connect';
                buttonType = 'solid';
                disabled = false;
                className = 'network-button connect-button';
                onClick = null;
                onSuccess = updateConnectStatus;
        }

        return {
            className,
            value,
            onClick,
            buttonType,
            callToActionHash: 'connect-user',
            loginTrigger: 'connect-user',
            disabled,
            onSuccess,
        };
    };

    render() {
        const { style } = this.props;

        return (
            <div
                style={style}
                className="component-CallToAction-HoverCallToAction-UserLayout"
            >
                <MessageConsumer>
                    {({ openMessageDrawer }) => {
                        const callToActionSection = this.renderButtonSection(
                            openMessageDrawer
                        );
                        return (
                            <HoverCallToAction
                                className={'user-hover-component'}
                                mainContent={this.renderUserLayout()}
                                callToActionSection={callToActionSection}
                            />
                        );
                    }}
                </MessageConsumer>
            </div>
        );
    }
}
