import PropTypes from 'prop-types';
import React from 'react';
import { PageContext } from '../../../../contexts/ContextCreator';
import { isEmpty } from '../../../../utilities/helperFunctions';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import Close from '../../../Icons/Close';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';

import './CloseModalIcon.scss';

export default class CloseModalIcon extends React.PureComponent {
    static propTypes = {
        /**
         * imply metrics attributes
         */
        metrics: PropTypes.shape({
            misc_event_type: PropTypes.string,
            misc_event_count: PropTypes.number,
        }),
    };

    static defaultProps = {
        metrics: {},
    };

    static contextType = PageContext;

    handleClick = () => {
        const { metrics } = this.props;
        const { closeModal } = this.context;

        closeModal();

        if (!isEmpty(metrics)) {
            EmitMetric(metrics);
        }
    };

    render() {
        return (
            <SVGIconWrapper
                handleClickSVGWrapper={this.handleClick}
                SVGWrapperCssClass="component-Modals-ModalParts-CloseModalIcon"
            >
                {Close}
            </SVGIconWrapper>
        );
    }
}
