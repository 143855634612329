import PropTypes from 'prop-types';
import React from 'react';
import StepsControlSection from '../../../../Elements/StepsControlSection/StepsControlSection';
import ThreeRowModalLayout from '../../../../Modals/Layout/ThreeRowModalLayout/ThreeRowModalLayout';

import './AdminOnboardingModalBottom.scss';

export default class AdminOnboardingModalBottom extends React.PureComponent {
    static propTypes = {
        /**
         * index of selected step user is on, start from 0
         */
        selectedStepIndex: PropTypes.number.isRequired,
        /**
         * handler for click on next button
         */
        handleNext: PropTypes.func.isRequired,
        /**
         * handler for click on skip button
         */
        handleSkip: PropTypes.func.isRequired,
        /**
         * handler for click on finish button
         */
        handleFinish: PropTypes.func.isRequired,
        /**
         * total steps of the onboarding process
         */
        totalSteps: PropTypes.number.isRequired,
        /**
         * descriptions of individual steps
         */
        stepDefinition: PropTypes.object.isRequired,
    };

    static defaultProps = {
        handleSkip: () => {},
        handleNext: () => {},
        handleFinish: () => {},
        totalSteps: 0,
        stepDefinition: {},
    };

    render() {
        const {
            selectedStepIndex,
            handleSkip,
            handleNext,
            handleFinish,
            totalSteps,
            stepDefinition,
        } = this.props;

        const { hasSkip, hasNext, hasFinish } = stepDefinition;

        const finalStepStyling =
            selectedStepIndex === totalSteps - 1 ? 'final-step' : '';
        const showStepsControl = hasSkip || hasNext || hasFinish;

        return (
            <ThreeRowModalLayout.Bottom className="component-Groups-AdminOnboarding-AdminOnboardingModal-AdminOnboardingModalBottom">
                {showStepsControl && (
                    <StepsControlSection
                        selectedStepIndex={selectedStepIndex}
                        totalSteps={totalSteps}
                        handleNext={handleNext}
                        handleSkip={handleSkip}
                        handleFinish={handleFinish}
                        finalBtnValue="Done"
                        className={finalStepStyling}
                    />
                )}
            </ThreeRowModalLayout.Bottom>
        );
    }
}
