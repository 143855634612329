import PropTypes from 'prop-types';
import React from 'react';
import DropdownLayout from '../../../Layout/DropdownLayout/DropdownLayout';
import Menu from '../../Menu/Menu';

import './UserDropdown.scss';

export default class UserDropdown extends React.PureComponent {
    render() {
        const {
            isDropdownOpen,
            profileImage,
            headerText,
            onClose,
            menuItems,
            className,
        } = this.props;

        if (!isDropdownOpen) {
            return null;
        }
        return (
            <DropdownLayout
                cssClass={`component-Layout-Header-Modules-Dropdowns-UserDropdown ${className}`}
                shouldShowDropdown
                profileImage={profileImage}
                headerText={headerText}
                onClose={onClose}
            >
                <Menu menuItems={menuItems} />
            </DropdownLayout>
        );
    }
}

UserDropdown.propTypes = {
    /**
     * Whether the dropdown is open
     */
    isDropdownOpen: PropTypes.bool.isRequired,
    /**
     * handler for closing
     */
    onClose: PropTypes.func.isRequired,
    /**
     * text to be display on topbar
     */
    headerText: PropTypes.string.isRequired,
    /**
     * image src to be displayed on top bar
     */
    profileImage: PropTypes.string.isRequired,
    /**
     * list of items to be displayed in user menu
     */
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            displayInDrawer: PropTypes.bool,
            displayInDropdown: PropTypes.bool,
            key: PropTypes.string,
            title: PropTypes.string,
            url: PropTypes.string,
        })
    ),

    /**
     * style to override default
     */
    className: PropTypes.string,
};

UserDropdown.defaultProps = {
    className: '',
    menuItems: [],
};
