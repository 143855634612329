import PropTypes from 'prop-types';
import React from 'react';

import './NextArrow.scss';

export default class NextArrow extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {};

    render() {
        return (
            <div className="component-Icons-NextArrow d-inline-flex">
                <i className="material-icons">chevron_right</i>
            </div>
        );
    }
}
