import PropTypes from 'prop-types';
import React from 'react';

import './RatingWatch.scss';

export default class RatingWatch extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        size: PropTypes.string,
        active: PropTypes.bool.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        number: PropTypes.number.isRequired,
    };

    static defaultProps = {
        size: 'large',
    };

    onMouseOver = () => {
        this.props.onMouseOver(this.props.number);
    };

    onClick = () => {
        this.props.onClick(this.props.number);
    };

    render() {
        const activeClass = this.props.active ? 'active' : '';
        return (
            <div
                className={`component-ReviewSurvey-Components-RatingWatch ${this.props.size} ${activeClass} ${this.props.number}`}
                data-ratings={this.props.number}
                onMouseOver={this.onMouseOver}
                onClick={this.onClick}
            >
                <div className="size-container">
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 47 62"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g>
                            <g
                                className="watch"
                                transform="translate(1.000000, 1.000000)"
                            >
                                <path
                                    d="M12,38.154227 C12,47.0757297 19.2327244,54.3076923 28.154227,54.3076923 C37.0749679,54.3076923 44.3076923,47.0757297 44.3076923,38.154227 C44.3076923,29.2327244 37.0749679,22 28.154227,22 C19.2327244,22 12,29.2327244 12,38.154227 Z"
                                    className="colordot"
                                    fill="#F2ECFE"
                                />
                                <path
                                    d="M4.61538462,30.0003809 C4.61538462,38.9218835 11.848109,46.1538462 20.7696116,46.1538462 C29.6903526,46.1538462 36.9230769,38.9218835 36.9230769,30.0003809 C36.9230769,21.0788782 29.6903526,13.8461538 20.7696116,13.8461538 C11.848109,13.8461538 4.61538462,21.0788782 4.61538462,30.0003809 Z"
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M-1.38555833e-13,30.3846154 C-1.38555833e-13,41.6423077 9.12615385,50.7692308 20.3846154,50.7692308 C31.6423077,50.7692308 40.7692308,41.6423077 40.7692308,30.3846154 C40.7692308,19.1261538 31.6423077,10 20.3846154,10 C9.12615385,10 -1.38555833e-13,19.1261538 -1.38555833e-13,30.3846154 Z"
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <polyline
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="11.5384615 11.5384615 11.5384615 1.7408297e-13 30 1.7408297e-13 30 11.5384615"
                                />
                                <polyline
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="41.5384615 27.6923077 44.6153846 27.6923077 44.6153846 32.3076923 41.5384615 32.3076923"
                                />
                                <polyline
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="30 48.4615385 30 60 11.5384615 60 11.5384615 48.4615385"
                                />
                                <polyline
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="27.6923077 30 20.7692308 30 20.7692308 23.0769231"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}
