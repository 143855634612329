import React from 'react';
import PropTypes from 'prop-types';

import './FieldError.scss';

export const FieldError = ({ text }) => {
    return (
        <div className="component-Modals-ModalParts-Elements-FieldError">
            {text}
        </div>
    );
};

FieldError.propTypes = {
    text: PropTypes.string,
};

FieldError.defaultProps = {
    text: '',
};

FieldError.displayName = 'FieldError';

export default React.memo(FieldError);
