import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import OpenFieldAndDropdownCard from '../../QuestionCards/OpenFieldAndDropdownCard/OpenFieldAndDropdownCard';
import './PositionCard.scss';

export default class PositionCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobTitle: props.jobTitle || null,
            departmentId: props.departmentId || null,
            isPublic: props.isPublic !== false,
            dropdownOptions: [],
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'position',
    };

    componentDidMount() {
        this.getDropDownItems();
        EmitMetric({
            misc_event_type: 'job-review-question-started-demographic-3',
            misc_event_count: 1,
        });

        const PositionObject =
            this.props.jobTitle && this.props.departmentId
                ? {
                      jobTitle: this.props.jobTitle,
                      departmentId: this.props.departmentId,
                      isPublic: this.props.isPublic,
                  }
                : null;

        this.props.jobTitle && this.props.departmentId
            ? this.props.onAnswer(
                  this.props.questionName,
                  PositionObject,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    handleInputValue = (jobTitle) => {
        const newState = Object.assign({}, this.state);
        newState.jobTitle = jobTitle.length ? jobTitle : '';
        this.setState(newState, this.onAnswer);
    };

    handleDropdownValue = (departmentId) => {
        const newState = Object.assign({}, this.state);
        newState.departmentId = Number(departmentId) || null;
        this.setState(newState, this.onAnswer);
    };

    handleCheckbox = () => {
        const newState = Object.assign({}, this.state);
        newState.isPublic = !this.state.isPublic;
        this.setState(newState, this.onAnswer);
    };

    onAnswer = () => {
        const { jobTitle, departmentId, isPublic } = this.state;
        let nextAction = CAN_SUBMIT;
        const payload =
            jobTitle || departmentId
                ? {
                      jobTitle: jobTitle || null,
                      departmentId,
                      isPublic: isPublic,
                  }
                : { jobTitle: null, departmentId: null, isPublic: true };

        this.props.onAnswer(this.props.questionName, payload, nextAction);
    };

    getDropDownItems = async () => {
        try {
            let dropdownOptionsAPI = await axios.get(
                `/api/company/department/`
            );
            let dropdownOptions = dropdownOptionsAPI.data;
            this.setState({
                dropdownOptions,
            });
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const props = {
            hasInputField: true,
            hasDropdown: true,
            hasCheckbox: true,
            handleCheckbox: this.handleCheckbox,
            handleInputValue: this.handleInputValue,
            handleDropdownValue: this.handleDropdownValue,
            inputLabel: 'Job title:',
            inputFieldDefaultValue:
                this.props.jobTitle || this.props.inputFieldDefaultValue,
            dropdownDefaultText: 'Select Department',
            checkboxLabel: 'Please do not publish this information',
            prevAnswer: this.props.departmentId,
            defaultChecked: !this.state.isPublic,
        };

        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-PositionCard">
                <OpenFieldAndDropdownCard
                    {...props}
                    dropdownOptions={this.state.dropdownOptions}
                />
            </div>
        );
    }
}
