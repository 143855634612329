import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
    GO_SUB_STEP,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

export default class MakeBetter extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'single',
        buttonData: [
            {
                title: "Not much, it's a great place to work",
                value: 'notMuch',
            },
            {
                title: 'Promote more women into leadership positions',
                value: 'promoteWomen',
            },
            {
                title: 'Improve work-life balance and related policies',
                value: 'improveWorkLife',
            },
            {
                title: 'Improve my benefits',
                value: 'improveBenefits',
            },
            {
                title: 'Improve my compensation',
                value: 'improveCompensation',
            },
            {
                title: 'Other',
                subtitle: '(Please explain.)',
                value: 'other',
            },
        ],
    };

    handleUpdate = (data) => {
        const response = typeof data[0] === 'undefined' ? null : data[0];
        const MakeBetterObject = { makeBetter: response };

        let nextAction = CANT_SUBMIT;
        if (this.props.makeBetterComment) {
            MakeBetterObject.makeBetterComment = this.props.makeBetterComment;
        }

        if (response === 'other') {
            nextAction = GO_SUB_STEP;
        } else {
            if (this.props.makeBetterComment)
                delete MakeBetterObject.makeBetterComment;
            nextAction = response ? SUBMIT : CANT_SUBMIT;
        }

        this.props.onAnswer(
            'makeBetter',
            MakeBetterObject,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        const MakeBetterObject = { makeBetter: this.props.makeBetter };

        let nextAction = CAN_SUBMIT;

        if (this.props.makeBetterComment) {
            MakeBetterObject.makeBetterComment = this.props.makeBetterComment;
            nextAction = ALLOW_SUB_STEP;
        } else if (MakeBetterObject.makeBetter === 'other') {
            MakeBetterObject.makeBetterComment = null;
            nextAction = ALLOW_SUB_STEP;
        }

        this.props.makeBetter !== undefined
            ? this.props.onAnswer(
                  'makeBetter',
                  MakeBetterObject,
                  nextAction,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-MakeBetter h-100">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={this.props.makeBetter}
                />
            </div>
        );
    }
}
