import PropTypes from 'prop-types';
import React from 'react';

import './UnderlineNavigation.scss';

export default class UnderlineNavigation extends React.PureComponent {
    static propTypes = {
        menuItems: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                url: PropTypes.string,
                active: PropTypes.bool,
                total: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                handleClickFunc: PropTypes.func,
            })
        ),
        /**
         * whether menu items are render as anchor tags or not
         */
        isAnchorTag: PropTypes.bool,
    };

    static defaultProps = {
        menuItems: [],
        isAnchorTag: true,
    };

    renderTotal = (total) => {
        if (total) {
            return `(${total})`;
        }
    };

    render() {
        const { isAnchorTag, menuItems } = this.props;
        return (
            <div className="component-Elements-UnderlineNavigation">
                <div className="row justify-content-center">
                    {menuItems.map((menuItem, i) => {
                        const menuClass = menuItem.active ? 'selectedMenu' : '';
                        const separator = i !== 0 ? 'separator' : '';
                        const handleClickFunc = menuItem.handleClickFunc
                            ? menuItem.handleClickFunc
                            : () => {};
                        return isAnchorTag ? (
                            <div
                                key={menuItem.text}
                                className={`${separator} text-center d-inline-block`}
                            >
                                <a
                                    href={menuItem.url}
                                    className={`menu-item text-center ${menuClass}`}
                                    onClick={handleClickFunc}
                                >
                                    {menuItem.text}{' '}
                                    {this.renderTotal(menuItem.total)}
                                </a>
                            </div>
                        ) : (
                            <div
                                key={menuItem.text}
                                className={`${separator} text-center d-inline-block`}
                            >
                                <span
                                    className={`menu-item text-center ${menuClass}`}
                                    onClick={handleClickFunc}
                                >
                                    {menuItem.text}{' '}
                                    {this.renderTotal(menuItem.total)}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
