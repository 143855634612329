import PropTypes from 'prop-types';
import React from 'react';
import Tag from '../../Elements/Tag/Tag';
import InputField from '../InputField/InputField';

import './LocationInput.scss';

const inputType45 = 'inputType45';
const inputType40 = 'inputType40';

export default class LocationInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            coordinates: {
                lat: null,
                lng: null,
            },
            value: props.value,
            name: props.value,
            remote: false,
            addressComponents: null,
            height: 45,
        };
        this.handleChange = this.handleChange.bind(this);
        this.inputNameHash = Math.random().toString(36).substring(2, 15);
    }

    componentDidMount = () => {
        if (this.divElement) {
            const height = this.divElement.clientHeight;
            this.setState({ height });
        }
        if (!window.google && !window._googleMapsStartedLoading) {
            window._googleMapsStartedLoading = true;
            this.loadGoogleScript();
        } else {
            setTimeout(() => {
                this.setUpGoogle();
            }, 2000);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.divElement &&
            prevState.height !== this.divElement.clientHeight
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ height: this.divElement.clientHeight });
        }

        if (this.props.value !== prevProps.value) {
            const newState = { ...this.state, value: this.props.value };
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ ...newState });
        }
    }

    loadGoogleScript = () => {
        const script = document.createElement('script');
        script.onload = () => {
            this.setUpGoogle();
        };
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBFts9V0cwwsvKe6JdP8VcD9FLuYp5iWxU&libraries=places`;
        document.head.appendChild(script);
    };

    setUpGoogle = () => {
        if (window.google && window.google.maps) {
            const element = this.inputElement;
            // eslint-disable-next-line no-undef
            const autocomplete = new google.maps.places.Autocomplete(element, {
                types: this.props.googlePlaceAPITypes,
                componentRestrictions: this.props
                    .googlePlaceAPIComponentRestrictions,
            });

            autocomplete.setFields([
                'address_components',
                'formatted_address',
                'geometry',
            ]);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                const value = place.formatted_address;
                this.setState({
                    coordinates: {
                        lat,
                        lng,
                    },
                    value,
                    name: value,
                    remote: false,
                    addressComponents: place.address_components,
                });

                if (this.props.canSelectMulti) {
                    this.handleMultiSelect(lat, lng, value, false);
                } else {
                    this.sendInfoToSearch(lat, lng, value, false);
                }
            });
        }
    };

    handleChange = (location) => {
        this.setState({
            coordinates: {
                lat: null,
                lng: null,
            },
            value: location,
            remote: false,
        });
        const val = location;
        const stateLat = this.state.lat;
        const stateLng = this.state.lng;
        const formattedInputVal = val.toLowerCase();
        const remote =
            formattedInputVal.trim() === 'remote' &&
            stateLat == null &&
            stateLng == null;

        this.sendInfoToSearch(stateLat, stateLng, val, remote);
    };

    handleMultiSelect = (lat, lng, value) => {
        const isLocationSelectedAlready = this.props.selectedLocations.some(
            (location) => location.value === value || location.name === value
        );

        if (!isLocationSelectedAlready) {
            this.props.onChange([
                ...this.props.selectedLocations,
                {
                    coordinates: {
                        lat,
                        lng,
                    },
                    value,
                },
            ]);
        }

        this.setState({
            coordinates: {
                lat: null,
                lng: null,
            },
            value: '',
            remote: null,
            addressComponents: null,
        });
    };

    handleInputClear = () => {
        this.setState({
            coordinates: {
                lat: null,
                lng: null,
            },
            value: '',
            remote: null,
            addressComponents: null,
        });
        this.sendInfoToSearch(null, null, '', null);
    };

    sendInfoToSearch = (lat, lng, value, remote) => {
        const searchInfo = {
            coordinates: {
                lat,
                lng,
            },
            value,
            remote,
            addressComponents: this.state.addressComponents,
        };

        if (!this.props.canSelectMulti) {
            this.props.onChange({ ...searchInfo, name: value, lat, lng });
        }
    };

    handleRemove = (item) => {
        const newSelectedLocations = this.props.selectedLocations.filter(
            (location) => {
                return location.value !== item.value;
            }
        );

        this.props.onChange(newSelectedLocations);
    };

    renderTag = (item, index) => (
        <Tag key={`item-${index}`} onRemove={() => this.handleRemove(item)}>
            {item.value}
        </Tag>
    );

    getCloseButtonTopMargin = () => {
        const closeButtonHeight = 37;
        const marginTop = (this.state.height - closeButtonHeight) / 2;
        return `${marginTop - 1}px`;
    };

    renderTags() {
        return (
            !!this.props.selectedLocations.length && (
                <div className="tags-container">
                    {this.props.selectedLocations.map(this.renderTag)}
                </div>
            )
        );
    }

    render() {
        return (
            <div
                className={`component-Input-LocationInput ${
                    this.state.height === 45 ? inputType45 : inputType40
                }`}
                ref={(divElement) => {
                    this.divElement = divElement;
                }}
            >
                <InputField
                    idValue={this.props.idValue}
                    handleInput={(el) => {
                        this.inputElement = el;
                    }}
                    name={this.props.inputName || this.inputNameHash}
                    type="text"
                    placeholder={this.props.placeholder}
                    inputValue={this.state.value}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                    onKeyPress={this.props.onKeyPress}
                    classname={this.props.className}
                    icon={this.props.hasIcon ? this.props.icon : null}
                    hasError={this.props.hasError}
                    disabled={this.props.disabled}
                    qaDataAttr="locationInput"
                />

                {this.props.withTags && this.renderTags()}
                {this.props.showClearButton &&
                    this.state.value &&
                    this.state.value !== '' && (
                        <i
                            className="clear-search"
                            onClick={this.handleInputClear}
                            onKeyPress={() => {}}
                            role="button"
                            aria-hidden="true"
                            style={{
                                top: this.getCloseButtonTopMargin(),
                            }}
                        >
                            &times;
                        </i>
                    )}
            </div>
        );
    }
}

LocationInput.defaultProps = {
    onChange: () => {},
    onKeyPress: () => {},
    placeholder: 'City and/or State',
    googlePlaceAPITypes: ['(regions)'],
    googlePlaceAPIComponentRestrictions: { country: [] },
    value: '',
    onBlur: () => {},
    hasIcon: true,
    icon: 'location',
    withTags: false,
    selectedLocations: [],
    disabled: false,
    className: '',
    canSelectMulti: false,
    idValue: '',
    hasError: false,
    showClearButton: true,
    inputName: 'locationInput',
};

LocationInput.propTypes = {
    /**
     * if the user can select multy line
     */
    canSelectMulti: PropTypes.bool,
    /**
     * input Value
     */
    value: PropTypes.string,
    /**
     * custom class
     */
    className: PropTypes.string,
    /**
     * onChange handler
     */
    onChange: PropTypes.func,
    /**
     * onChange handler
     */
    onKeyPress: PropTypes.func,
    /**
     * Placeholder for the input field
     */
    placeholder: PropTypes.string,
    /**
     * the types field for the google places autocomplete variable. (i.e. ['(regions)'])
     */
    googlePlaceAPITypes: PropTypes.arrayOf(PropTypes.string),
    /**
     * any restrictions for the google places api definiton (i.e. {country: "us"})
     */
    googlePlaceAPIComponentRestrictions: PropTypes.oneOfType([
        PropTypes.object,
    ]),
    /**
     * default value of the input
     */
    onBlur: PropTypes.func,
    idValue: PropTypes.string,
    /**
     * if this input has an icon
     */
    hasIcon: PropTypes.bool,
    /**
     * the icon for this input
     */
    icon: PropTypes.string,
    /**
     * indicates if tags should be rendered
     */
    withTags: PropTypes.bool,
    /**
     * the locations that has been selected
     */
    selectedLocations: PropTypes.arrayOf(PropTypes.object),
    /**
     * name of input element, autofill would be trigger depends on the input name
     */
    inputName: PropTypes.string,
    /**
     * if this input shoukd be in an error state
     */
    hasError: PropTypes.bool,
    /**
     * indicates the disabled state of the input
     */
    disabled: PropTypes.bool,
    /**
     * Indicates where if the clear button should be present
     */
    showClearButton: PropTypes.bool,
};
