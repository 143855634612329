import React from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
/* eslint-disable-next-line */
import { useGetStepFormProps } from '../../../../contexts/Includes/modalContextHelpers';
import { useFlowControls } from '../FlowShell/controls';
import { QUICK_PROFILE_STEP_NAME } from '../Steps/constants';
/* eslint-disable-next-line */
import { registrationFlow } from './constants';

const { registrationFlowAPI } = registrationFlow;

const handleNoReqsApiCall = async (eventHash) => {
    const {
        ok,
        data,
        error,
    } = await registrationFlowAPI.submitEventRegistration({
        eventHash,
        registerPayload: {},
    });

    if (ok) {
        return {
            registerSuccess: true,
            registerData: data,
            registerErrors: null,
        };
    }

    return {
        registerSuccess: false,
        registerData: null,
        registerErrors: error,
    };
};

export const QuickProfileProgressButton = React.memo(() => {
    const { destinations, flowData } = useFlowControls();
    const {
        validators,
        toggleJobProfileSnippetComplete,
        event,
        setUserAsRegistered,
    } = flowData;

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(QUICK_PROFILE_STEP_NAME);

    const validationPayload = {
        name: formResponses.name,
        ...(formResponses.isStudent
            ? { experience: null, education: formResponses.education }
            : {
                  experience: {
                      ...formResponses.experience,
                      title: formResponses.experience?.title?.trim(),
                  },
                  education: null,
              }),
    };

    const errorHandler = updateFormValueAtPath('errors.quickProfile');

    const apiRequestPayload = {
        name: formResponses.name,
        ...(formResponses.isStudent
            ? { educations: [formResponses.education] }
            : { experiences: [formResponses.experience] }),
    };

    const hasEventRequirements = flowData.requiredSteps.eventRequirementsInput;

    const goToRequirements = destinations.eventRequirementsInput;
    const goToSelectTime = destinations.selectTime;
    const goToConfirmSuccess = destinations.confirmSuccess;

    const handleQuickProfileSubmit = React.useCallback(async () => {
        // handle click metric
        EmitMetric({
            misc_event_count: 1,
            misc_event_type: 'quick-profile-modal-submit-click',
        });

        clearStepErrors(QUICK_PROFILE_STEP_NAME);

        const { valid, validationErrors } = await validators.quickProfile(
            validationPayload
        );

        if (!valid) return errorHandler(validationErrors);

        // submit api request
        const { ok } = await registrationFlowAPI.submitQuickProfile(
            apiRequestPayload
        );

        if (ok) {
            toggleJobProfileSnippetComplete();

            if (hasEventRequirements) return goToRequirements();

            // no requirements - submit registration and check pref times
            const { registerSuccess, registerData } = await handleNoReqsApiCall(
                event.hash
            );

            if (registerSuccess) {
                const { eventPreferredTimes } = registerData;

                if (eventPreferredTimes && eventPreferredTimes.length > 0) {
                    return goToSelectTime({
                        preferredTimeOptions: eventPreferredTimes,
                    });
                }

                setUserAsRegistered();
                return goToConfirmSuccess();
            }

            // TODO: handle bad registrations
        }
    }, [
        clearStepErrors,
        validators,
        validationPayload,
        apiRequestPayload,
        errorHandler,
        toggleJobProfileSnippetComplete,
        goToRequirements,
        goToSelectTime,
        goToConfirmSuccess,
        hasEventRequirements,
        event.hash,
        setUserAsRegistered,
    ]);

    return (
        <button
            type="button"
            className="quick-profile-finish-button"
            onClick={handleQuickProfileSubmit}
        >
            Next
        </button>
    );
});

const buttonNavBox = (
    <div className="quick-profile-button-box">
        <QuickProfileProgressButton />
    </div>
);

export default buttonNavBox;
