import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

import './WorkLifeBalance.scss';

export default class WorkLifeBalance extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'multi',
        buttonData: [
            {
                title: 'Hours',
                subtitle: null,
                value: 'hours',
            },
            {
                title: 'Culture',
                subtitle: null,
                value: 'culture',
            },
            {
                title: 'Policies',
                subtitle: null,
                value: 'policies',
            },
            {
                title: 'None apply',
                subtitle: null,
                isExclusive: true,
                value: null,
            },
        ],
    };

    handleUpdate = (data) => {
        const hasItems = data && !!data.length;
        let answer;
        if (!hasItems) {
            answer = {
                workLifeBalance: undefined,
            };
        } else {
            answer = {
                workLifeBalance: hasItems && data[0] !== null ? data : null,
            };
        }

        const answerAction = hasItems ? CAN_SUBMIT : CANT_SUBMIT;
        this.props.onAnswer(
            'workLifeBalance',
            answer,
            answerAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        if (
            typeof this.props.workLifeBalance !== 'undefined' ||
            this.props.stepsProgressed.includes('8')
        ) {
            const answer = {
                workLifeBalance: this.props.workLifeBalance,
            };
            this.props.onAnswer(
                'workLifeBalance',
                answer,
                CAN_SUBMIT,
                false,
                this.props.nextStep
            );
        }
    }

    render() {
        let selectedItems = this.props.workLifeBalance
            ? this.props.workLifeBalance
            : this.props.stepsProgressed.includes('8')
            ? null
            : undefined;

        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-WorkLifeBalance">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={selectedItems}
                />
            </div>
        );
    }
}
