/* eslint-disable no-useless-escape */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import * as Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import { FGBLocalStorage } from '../../../utilities/helperFunctions';
import Pixel, {
    getTrackingPropsCookie,
} from '../../Analytics/VisibilityPixel/VisibilityPixel';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';
import {
    POPUP_MODE,
    EMBED_MODE,
    CREATE_LAYOUT,
    LOGIN_LAYOUT,
    RESET_PASSWORD_CONFIRMATION_LAYOUT,
    RESET_PASSWORD_LAYOUT,
    FACEBOOK_REAUTHORIZATION_LAYOUT,
    FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
} from './Layouts/LogInLayout/constants';
import CreateAccountLayout from './Layouts/CreateAccountLayout/CreateAccountLayout';
import FBReauthorizationConfirmationLayout from './Layouts/FBReauthorizationConfirmationLayout/FBReauthorizationConfirmationLayout';
import FBReauthorizationLayout from './Layouts/FBReauthorizationLayout/FBReauthorizationLayout';
import LogInLayout from './Layouts/LogInLayout/LogInLayout';
import ResetPasswordConfirmationLayout from './Layouts/ResetPasswordConfirmationLayout/ResetPasswordConfirmationLayout';
import ResetPasswordLayout from './Layouts/ResetPasswordLayout/ResetPasswordLayout';
import './LoginLayoutComponent.scss';
import { loginSchema } from '../../../validation/loginSchema';
import { errorMessagesMap } from '../../Modals/ForceResetPasswordModal/constants';

const LOGIN_TRIGGER_COOKIE = 'LOGIN-TRIGGER';
const LOGIN_PAGE_COOKIE = 'LOGIN-PLACEMENT';

const propTypes = {
    /**
     * Whether or not the component will be displayed in
     */
    displayMode: PropTypes.oneOf([POPUP_MODE, EMBED_MODE]).isRequired,
    /**
     * The secondary subtitle text
     */
    callToActionSubTitle: PropTypes.string,
    /**
     * Whether or not the component will be displayed in
     */
    layoutType: PropTypes.oneOf([
        CREATE_LAYOUT,
        LOGIN_LAYOUT,
        RESET_PASSWORD_LAYOUT,
        RESET_PASSWORD_CONFIRMATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
    ]).isRequired,
    /**
     * callback function to close modal, is required if in POPUP_MODE
     */
    hidePopup: PropTypes.func,
    /**
     * auth0 config
     */
    auth0: PropTypes.shape({
        domain: PropTypes.string,
        clientId: PropTypes.string,
        db: PropTypes.string,
    }).isRequired,
    /**
     * sign up success redirect uri
     */
    loginRedirectPath: PropTypes.string.isRequired,
    /**
     * redirectURI
     */
    redirectUri: PropTypes.string.isRequired,
    /**
     * Object containing any additional analytics metrics we need to fire with the auth open pixel
     */
    loginMetrics: PropTypes.object,
    /**
     * if user filled out a survey, she will have an temp survey id
     */
    incompleteRegistrationId: PropTypes.string,
    /**
     * if placement name
     */
    placementName: PropTypes.string,
    /**
     * if user is invited by other user, she will have and invitation token
     */
    invitationToken: PropTypes.string,
    /**
     *  true: show the close-button, false: do not show the close-button
     */
    allowClosing: PropTypes.bool,
    inviteHash: PropTypes.string,
    groupInviteHash: PropTypes.string,
    connectionInviteHash: PropTypes.string,
    /**
     *  true: render login at bottom of window
     */
    leadInLogin: PropTypes.bool,
    /**
     *  toggle popup for login if banner
     */
    setLeadLoginInPopup: PropTypes.func,
    /**
     *  toggle popup through lead in login
     */
    leadInPopup: PropTypes.bool,
    employerInviteHash: PropTypes.string,
};

const defaultProps = {
    loginMetrics: {
        login_trigger: 'generic',
    },
    groupInviteHash: null,
    employerInviteHash: null,
    connectionInviteHash: null,

    callToActionSubTitle: null,
    incompleteRegistrationId: null,
    allowClosing: true,
    inviteHash: undefined,
    invitationToken: null,
    placementName: null,
    leadInPopup: null,
    hidePopup: null,
    setLeadLoginInPopup: null,
    leadInLogin: null,
};

export default class LoginLayoutComponent extends React.PureComponent {
    constructor(props) {
        super();

        this.state = {
            layoutType: props.layoutType,
            email: null,
            password: null,
            username: null,
            name: null,
            usernameError: null,
            nameError: null,
            emailError: null,
            passwordError: null,
            generalError: null,
            loaded: true,
        };
    }

    componentDidMount() {
        if (this.props.loginMetrics) {
            Cookies.set(
                LOGIN_TRIGGER_COOKIE,
                this.props.loginMetrics.login_trigger,
                { expires: 1095 }
            );
            FGBLocalStorage.setItem(
                'last_login_metrics',
                this.props.loginMetrics
            );
        }
        Cookies.set(LOGIN_PAGE_COOKIE, window.location.pathname, {
            expires: 2095,
        });

    }

    changeLayoutState = (layoutType) => {
        this.setState({
            layoutType,
            password: null,
            nameError: null,
            emailError: null,
            usernameError: null,
            passwordError: null,
            generalError: null,
        });
    };

    handleEmailInput = (email) => {
        const trimmedEmail = email.replace(/ /g, '');
        this.setState({
            email: trimmedEmail,
            emailError: null,
            nameError: null,
            usernameError: null,
            passwordError: null,
            generalError: null,
        });
    };

    validateCreateForm = async (pass, emailAddress) => {
        let valid = true;
        const emailTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailTest.test(String(emailAddress).toLowerCase())) {
            valid = false;
            this.setState({ emailError: 'invalid email address' });
        }
        const yupSchema = await loginSchema();
        try {
            await yupSchema.validate(
                {
                    password: pass,
                },
                { abortEarly: false }
            );
        } catch (error) {
            const passwordErrors = [];
            error.errors.forEach((element) => {
                passwordErrors.push(errorMessagesMap[element]);
            });
            this.setState({ passwordError: passwordErrors });
            return false;
        }
        return valid;
    };

    handleCreateLayoutEmailInput = (email) => {
        const trimmedEmail = email.replace(/ /g, '');
        this.setState({
            email: trimmedEmail,
            emailError: null,
        });
    };

    handlePasswordInput = (password) => {
        this.setState({
            password,
            emailError: null,
            nameError: null,
            usernameError: null,
            passwordError: null,
            generalError: null,
        });
    };

    handleCreateLayoutPasswordInput = (password) => {
        this.setState({
            password,
            passwordError: null,
        });
    };

    handleCreateLayoutBlur = () => {
        this.validateCreateForm(this.state.password, this.state.email);
    };

    handleUsernameInput = (username) => {
        this.setState({
            username: username.trim(),
            nameError: null,
            usernameError: null,
            emailError: null,
            passwordError: null,
            generalError: null,
        });
    };

    handleNameInput = (name) => {
        this.setState({
            name: name.trim(),
            nameError: null,
        });
    };

    resetErrorState = () => {
        this.setState({
            usernameError: null,
            emailError: null,
            nameError: null,
            passwordError: null,
            generalError: null,
        });
    };

    setupRedirectUri = (successUri) => {
        const uriEncodedSuccessUri = encodeURIComponent(successUri);
        let redirectUri = `${this.props.redirectUri}?redirect=${uriEncodedSuccessUri}`;
        const char = uriEncodedSuccessUri.indexOf('?') >= 0 ? '&' : '?';

        // TODO: delete after launch new survey
        if (this.props.incompleteRegistrationId) {
            redirectUri += `${char}incompleteRegistrationId=${this.props.incompleteRegistrationId}`;
        }

        const TrackingPropsCookie = getTrackingPropsCookie();
        const loginMetrics = TrackingPropsCookie
            ? Object.assign(TrackingPropsCookie, this.props.loginMetrics)
            : this.props.loginMetrics;
        redirectUri += `&loginMetrics=${encodeURI(
            JSON.stringify(loginMetrics)
        )}`;

        return redirectUri;
    };

    handleAuth0Error = (err, operation) => {
        let error = {};
        switch (err.code) {
            case `error in email - email format validation failed: ${this.state.email}`:
                error = {
                    message: 'Invalid email address.',
                    type: 'user',
                };
                break;
            case 'invalid_password_length':
                error = {
                    message: 'Password must be at least 8 characters.',
                    type: 'password',
                };
                // this.setState({ showPasswordHint: true });
                break;
            case 'password_missing_uppercase':
                error = {
                    message: 'Password must have at least 1 uppercase letter.',
                    type: 'password',
                };
                break;
            case 'password_missing_lowercase':
                error = {
                    message: 'Password must have at least 1 lowercase letter.',
                    type: 'password',
                };
                break;
            case 'password_missing_number':
                error = {
                    message: 'Password must have at least one number.',
                    type: 'password',
                };
                break;
            case 'password_missing_special_character':
                error = {
                    message:
                        'Password must have at least one special character.',
                    type: 'password',
                };
                break;
            case 'invalid email address':
                error = {
                    message: 'Invalid email address.',
                    type: 'user',
                };
                break;
            case 'user_exists':
                error = {
                    message: 'The user already exists.',
                    type: 'user',
                };
                break;
            case 'access_denied':
                error = {
                    message: 'Invalid user credentials.',
                    type: 'general',
                };
                break;
            case 'blocked_user':
                error = {
                    message: 'User is blocked.',
                    type: 'general',
                };
                break;
            case 'password_leaked':
                error = {
                    message:
                        "This login attempt has been blocked because the password you're using was previously disclosed through a data breach (not in this application).",
                    type: 'general',
                };
                break;
            case 'rate_limited':
                error = {
                    message:
                        'You have made to many login requests please wait a few minutes to try again.',
                    type: 'general',
                };
                break;
            case 'too_many_attempts':
                error = {
                    message:
                        'Your account has been blocked after multiple consecutive login attempts. We’ve sent you an email with instructions on how to unblock it.',
                    type: 'general',
                };
                break;
            case 'username_exists':
                error = {
                    message: 'Username already exists.',
                    type: 'username',
                };
                break;
            case 'invalid_username':
                error = {
                    message: 'Username must be at most 32 characters.',
                    type: 'username',
                };
                break;
            case 'invalid_name':
                error = {
                    message: 'Your name is limited to 100 characters.',
                    type: 'name',
                };
                break;
            default:
                error = `${operation}  failed`;
        }

        // eslint-disable-next-line no-console
        console.log(error);
        this.setAuth0ErrorMsg(error);
    };

    setAuth0ErrorMsg = (auth0ErrorMSg) => {
        let errorType = 'generalError';
        switch (auth0ErrorMSg.type) {
            case 'general':
                errorType = 'generalError';
                break;
            case 'user':
                errorType = 'emailError';
                break;
            case 'password':
                errorType = 'passwordError';
                break;
            case 'username':
                errorType = 'usernameError';
                break;
            case 'name':
                errorType = 'nameError';
                break;
            default:
                errorType = 'generalError';
        }

        this.setState({
            [errorType]: auth0ErrorMSg.message,
        });
    };

    serializeMetaData = () =>
        JSON.stringify({
            inviteHash: this.props.inviteHash,
            connectionInviteHash: this.props.connectionInviteHash,
            groupInviteHash: this.props.groupInviteHash,
            employerInviteHash: this.props.employerInviteHash,
        });

    setLoginMeta = async (redirectPath, serializedMetaData) => {
        const url = `/auth-v2/init-reg?rp=${encodeURIComponent(
            redirectPath
        )}&md=${encodeURIComponent(serializedMetaData)}`;
        try {
            const result = await axios.post(url);
            if (
                result.data &&
                result.data.success === true &&
                result.data.cookieId
            ) {
                Cookies.set('IAK', result.data.cookieId, { expires: 1095 });
                Cookies.set('IAK-DEBUG', result.data.cookieId, {
                    expires: 1095,
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    onLoginInternal = async (option = 'local', customRedirect = null) => {
        const { leadInPopup, leadInLogin } = this.props;
        this.setState({ loaded: false });
        let redirectPath = customRedirect || this.props.loginRedirectPath;

        if (leadInLogin || leadInPopup) {
            // remove # from url and append scroll query
            redirectPath = redirectPath.slice(0, -1);
            const scroll = window.scrollY;
            redirectPath += `?scroll=${scroll}`;
        }

        const serializedMetaData = this.serializeMetaData();

        switch (option) {
            case 'google':
            case 'facebook':
                await this.setLoginMeta(redirectPath, serializedMetaData);
                window.location = `/auth-v2/init-social/${option}?rp=${encodeURIComponent(
                    redirectPath
                )}&md=${encodeURIComponent(serializedMetaData)}`;
                break;
            default:
                try {
                    const result = await axios.post(
                        `/auth-v2/user-pass/login`,
                        {
                            email: this.state.email,
                            password: this.state.password,
                        }
                    );

                    const { data } = result;
                    this.setLoadedStateForLoaderComponent(true);
                    if (data.success === true) {
                        this.redirectToPath(redirectPath);
                    } else {
                        this.handleAuth0Error({ code: data.err }, 'Login');
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
        }
    };

    onInternalSignup = (option = 'local', customRedirect = null) => {
        if (option !== 'local') {
            this.onLoginInternal(option, customRedirect);
            return;
        }
        this.redirectToPath(customRedirect);
    };

    redirectToPath = (redirectPath) => {
        const { origin } = window?.location;
        const newState = {
            origin,
            pathName: redirectPath,
        };

        window.history.pushState(newState, '', `${origin}${redirectPath}`);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    handleInternalAuthError = (error, type, operation) => {
        switch (error) {
            case 'email is not valid':
                this.setAuth0ErrorMsg({
                    type,
                    message:
                        'The email in invalid. Please enter a valid email.',
                });
                break;
            case 'email does not exist':
                this.setAuth0ErrorMsg({
                    type,
                    message: 'The user does not exist.',
                });
                break;
            case 'user has been deactivated':
                this.setAuth0ErrorMsg({
                    type,
                    message: 'User has been deactivated.',
                });
                break;
            case 'server failed processing request':
                this.setAuth0ErrorMsg({
                    type,
                    message: 'Something went wrong. Please try again.',
                });
                break;
            default:
                this.setAuth0ErrorMsg({
                    type,
                    message: `${operation} failed.`,
                });
                break;
        }
    };

    internalPasswordReset = async () => {
        this.setState({ loaded: false });

        const payload = { email: this.state.email };

        try {
            await axios.post('/api/auth-v2/forgot-password', payload);
            this.setState({ loaded: true });

            this.changeLayoutState(RESET_PASSWORD_CONFIRMATION_LAYOUT);
            this.resetErrorState();
        } catch (error) {
            this.setState({ loaded: true });

            const { data } = error.response || {};
            const { email: emailErrors, _general: generalError } =
                data.errors || {};
            const emailError = (emailErrors && emailErrors[0]) || generalError;

            this.handleInternalAuthError(emailError, 'user', 'Password Reset');
        }
    };

    onResendFBPermissionInternal = (successUri) => {
        window.location = `/auth-v2/init-social/facebook-reauth?rp=${encodeURIComponent(
            successUri
        )}&md=${encodeURIComponent(this.serializeMetaData())}`;
    };

    setLoadedStateForLoaderComponent = (value) => {
        this.setState({ loaded: value });
    };

    onSignUpSuccess = (loginRedirect) => {
        setTimeout(() => this.onInternalSignup('local', loginRedirect), 1000);
    };

    onClick = async () => {
        const validation = await this.validateCreateForm(
            this.state.password,
            this.state.email
        );
        if (validation) {
            this.setLoadedStateForLoaderComponent(false);
            const redirectPath = this.props.loginRedirectPath;

            const metaData = {
                inviteHash: this.props.inviteHash,
                groupInviteHash: this.props.groupInviteHash,
                connectionInviteHash: this.props.connectionInviteHash,
                employerInviteHash: this.props.employerInviteHash,
                redirectPath,
            };

            try {
                const payload = {
                    email: this.state.email,
                    password: this.state.password,
                    name: this.state.name,
                    metaData,
                };

                const result = await axios.post(
                    `/auth-v2/user-pass/register`,
                    payload
                );

                const { data } = result;
                if (data.success === true) {
                    const loginRedirect = data.redirectPath
                        ? data.redirectPath
                        : redirectPath || '/';
                    this.onSignUpSuccess(loginRedirect);
                } else {
                    this.setLoadedStateForLoaderComponent(true);
                    this.handleAuth0Error({ code: data.err }, 'Sign Up');
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                this.setLoadedStateForLoaderComponent(true);
            }
        }
    };

    renderComponents = () => {
        let titleText = null;
        let optionChangeComponent = null;
        let Component = null;
        const { leadInLogin, setLeadLoginInPopup } = this.props;
        const onCreateLayoutPress = () => {
            if (leadInLogin && setLeadLoginInPopup) {
                setLeadLoginInPopup(true, true);
            }
            this.changeLayoutState(LOGIN_LAYOUT);
        };

        switch (this.state.layoutType) {
            case CREATE_LAYOUT:
                titleText = 'Create an account';
                optionChangeComponent = (
                    <div className="switch-layout-component">
                        Already a member?{' '}
                        <a
                            type="button"
                            role="button"
                            onKeyPress={onCreateLayoutPress}
                            onClick={onCreateLayoutPress}
                        >
                            Log in {!leadInLogin ? '>' : ''}
                        </a>
                    </div>
                );

                Component = (
                    <CreateAccountLayout
                        changeLayoutState={this.changeLayoutState}
                        leadInLogin={leadInLogin}
                        auth0={this.props.auth0}
                        setAuth0ErrorMsg={this.resetErrorState}
                        auth0ErrorMSg={this.state.auth0ErrorMSg}
                        emailError={this.state.emailError}
                        usernameError={this.state.usernameError}
                        nameError={this.state.nameError}
                        handleNameInput={this.handleNameInput}
                        handleNameInputBlur={this.handleCreateLayoutBlur}
                        onClick={this.onClick}
                        passwordError={this.state.passwordError}
                        generalError={this.state.generalError}
                        handleEmailInput={this.handleCreateLayoutEmailInput}
                        handleEmailInputBlur={this.handleCreateLayoutBlur}
                        handleUsernameInput={this.handleUsernameInput}
                        handlePasswordInput={
                            this.handleCreateLayoutPasswordInput
                        }
                        handlePasswordInputBlur={this.handleCreateLayoutBlur}
                        setErrorState={this.setErrorState}
                        email={this.state.email}
                        username={this.state.username}
                        name={this.state.name}
                        password={this.state.password}
                        onLogin={this.onInternalSignup}
                        loaded={this.state.loaded}
                        callToActionSubTitle={this.props.callToActionSubTitle}
                        invitationToken={this.props.invitationToken}
                        loginMetrics={this.props.loginMetrics}
                        incompleteRegistrationId={
                            this.props.incompleteRegistrationId
                        }
                    />
                );
                break;
            case LOGIN_LAYOUT:
                titleText =
                    this.props.loginMetrics &&
                    this.props.loginMetrics.login_trigger === 'employer-login'
                        ? 'Employer Login'
                        : 'Log In';
                optionChangeComponent = (
                    <div className="switch-layout-component small-screen-fix">
                        Not a member yet?{' '}
                        <a
                            type="button"
                            role="button"
                            onKeyPress={() =>
                                this.changeLayoutState(CREATE_LAYOUT)
                            }
                            onClick={() =>
                                this.changeLayoutState(CREATE_LAYOUT)
                            }
                        >
                            Join us {'>'}
                        </a>
                    </div>
                );
                Component = (
                    <LogInLayout
                        resetPasswordCallBack={() =>
                            this.changeLayoutState(RESET_PASSWORD_LAYOUT)
                        }
                        onLogin={this.onLoginInternal}
                        emailError={this.state.emailError}
                        passwordError={this.state.passwordError}
                        generalError={this.state.generalError}
                        handleEmailInput={this.handleEmailInput}
                        handlePasswordInput={this.handlePasswordInput}
                        email={this.state.email}
                        password={this.state.password}
                        loaded={this.state.loaded}
                        callToActionSubTitle={this.props.callToActionSubTitle}
                    />
                );
                break;
            case RESET_PASSWORD_LAYOUT:
                titleText = 'Reset your password';
                optionChangeComponent = (
                    <div className="switch-layout-component bottom-spacing">
                        Back to{' '}
                        <a
                            type="button"
                            role="button"
                            onKeyPress={() =>
                                this.changeLayoutState(LOGIN_LAYOUT)
                            }
                            onClick={() => this.changeLayoutState(LOGIN_LAYOUT)}
                        >
                            Login {'>'}
                        </a>
                    </div>
                );
                Component = (
                    <ResetPasswordLayout
                        onClick={this.internalPasswordReset}
                        handleEmailInput={this.handleEmailInput}
                        email={this.state.email}
                        emailError={this.state.emailError}
                        loaded={this.state.loaded}
                        callToActionSubTitle={this.props.callToActionSubTitle}
                    />
                );
                break;
            case RESET_PASSWORD_CONFIRMATION_LAYOUT:
                titleText = 'Reset your password';
                optionChangeComponent = null;
                Component = (
                    <ResetPasswordConfirmationLayout
                        onClick={() => this.changeLayoutState(LOGIN_LAYOUT)}
                    />
                );
                break;
            case FACEBOOK_REAUTHORIZATION_LAYOUT:
                titleText = 'Just one thing...';
                optionChangeComponent = null;
                Component = (
                    <FBReauthorizationLayout
                        onClick={() =>
                            this.onResendFBPermissionInternal(
                                this.props.loginRedirectPath
                            )
                        }
                    />
                );
                break;
            case FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT:
                titleText = 'Got it';
                optionChangeComponent = null;
                Component = (
                    <FBReauthorizationConfirmationLayout
                        onClick={() => this.changeLayoutState(LOGIN_LAYOUT)}
                    />
                );
                break;
            default:
                // eslint-disable-next-line no-console
                console.error(
                    `No title set for given type: ${this.props.layoutType}`
                );
        }
        const title = (
            <div className="login-layout-main-header">{titleText}</div>
        );
        return {
            title,
            Component,
            optionChangeComponent,
        };
    };

    showPopupHeaders = (allowClosing = true) => {
        const { leadInLogin, leadInPopup } = this.props;

        const imageSources = [
            {
                src: '/v1528477802/icons/logo_new.png',
                size:
                    leadInLogin && !leadInPopup
                        ? 'leadInHeaderLogo'
                        : 'headerLogo',
            },
            {
                src: '/v1/Close_X_sw0ud2.png',
                size: 'close',
                onClick: this.props.hidePopup,
            },
        ];
        const imageTransforms = {
            leadInHeaderLogo: 'c_scale,f_auto,q_auto,h_28',
            headerLogo: 'c_scale,f_auto,q_auto,h_35',
            close: 'c_scale,f_auto,q_auto,w_22,h_22',
        };

        return (
            <div className="popup-layout-header-components">
                {imageSources.map((image, idx) => {
                    const src = buildImagePath(
                        image.src,
                        image.size,
                        imageTransforms
                    );
                    if (image.size === 'close') {
                        return allowClosing ? (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <img
                                alt=""
                                // eslint-disable-next-line react/no-array-index-key
                                key={idx}
                                className={image.size}
                                src={src}
                                onKeyPress={image.onClick}
                                onClick={image.onClick}
                            />
                        ) : null;
                    }

                    return (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <img
                            alt=""
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            className={image.size}
                            src={src}
                            onKeyPress={image.onClick}
                            onClick={image.onClick}
                        />
                    );
                })}
            </div>
        );
    };

    render() {
        const { allowClosing, displayMode, loginMetrics } = this.props;

        const components = this.renderComponents();
        const pixelMetrics = { ...loginMetrics, login_opened: 1 };
        const loginTrigger = Cookies.get('EVENT-LOGIN-TRIGGER');
        if (loginTrigger) {
            pixelMetrics.login_trigger = loginTrigger;
            Cookies.remove('EVENT-LOGIN-TRIGGER');
        }

        return (
            <div className="component-Login-LoginLayoutComponent">
                {displayMode === POPUP_MODE
                    ? this.showPopupHeaders(allowClosing)
                    : null}
                {components.title}
                {components.Component}
                {components.optionChangeComponent}
                {this.state.layoutType === CREATE_LAYOUT ? (
                    <div className="disclosure-text-component">
                        <div className="text">
                            By registering, I am agreeing to the
                            <a
                                className="ml-1 mr-1"
                                href="/terms-and-conditions"
                            >
                                Terms and Conditions
                            </a>
                            and{' '}
                            <a className="ml-1 mr-1" href="/privacy-policy">
                                Privacy Policy
                            </a>{' '}
                            of this site.
                        </div>
                    </div>
                ) : null}
                {loginMetrics ? <Pixel metrics={pixelMetrics} /> : null}
            </div>
        );
    }
}

LoginLayoutComponent.propTypes = propTypes;
LoginLayoutComponent.defaultProps = defaultProps;
