/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React from 'react';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import TextInput from '../../../ModalParts/Inputs/TextInput/TextInput';
import CompanySelector from '../../../ModalParts/Inputs/CompanySelector/CompanySelector';
import LocationSelector from '../../../ModalParts/Inputs/LocationSelector/LocationSelector';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import CheckBox from '../../../../Content/Elements/CheckBox/CheckBox';
import InputLabel from '../../../ModalParts/Elements/InputLabel/InputLabel';
import {
    yearDropdownOpts,
    filterYearDropdownOpts,
    monthDropdownOpts,
} from '../../../ModalParts/Inputs/Dropdown/constants';
import { getFieldName, convertDropdownValue } from './constants';

const ExpVariant = ({ stepName }) => {
    const [extraFieldsVisible, setExtraFieldVisible] = React.useState(false);
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
    } = useGetStepFormProps(stepName);

    const { values, errors } = stepFormProps;

    const showExtraFields = React.useCallback(
        () => setExtraFieldVisible(true),
        [setExtraFieldVisible]
    );

    React.useEffect(() => {
        if (values.isRemote) {
            const locationUpdater = updateFormValueAtPath(
                getFieldName(stepName, 'location')
            );
            locationUpdater({ name: '', lat: null, lng: null });
        }

        if (values.isCurrent) {
            const endDatesUpdater = updateFormValueAtPath(`${stepName}.values`);
            endDatesUpdater({ endMonth: '', endYear: '' });
        }
    }, [values.isRemote, values.isCurrent, updateFormValueAtPath, stepName]);

    React.useEffect(() => {
        if (
            errors.description ||
            errors.startYear ||
            errors.endYear ||
            errors.startMonth ||
            errors.endMonth
        ) {
            setExtraFieldVisible(true);
        }
    }, [
        errors.description,
        errors.startYear,
        errors.endYear,
        errors.startMonth,
        errors.endMonth,
        setExtraFieldVisible,
    ]);

    return (
        <div className="enrichment-variant exp">
            <div className="field-wrapper">
                <TextInput
                    inputName={getFieldName(stepName, 'title')}
                    value={values.title}
                    label="Title*"
                    placeholder="Ex. Marketing Director"
                    error={errors.title}
                    handleChange={updateFormValues}
                    maxChars={200}
                />
            </div>
            <div className="field-wrapper">
                <CompanySelector
                    inputName={getFieldName(stepName, 'company')}
                    value={values.company}
                    label="Company*"
                    error={errors.company.companyName}
                    handleChange={updateFormValueAtPath}
                />
            </div>
            <div className="field-wrapper">
                <LocationSelector
                    inputName={`${stepName}.values.location`}
                    label="Job location*"
                    placeholder="City, State"
                    error={errors.location.name}
                    value={values.location.name}
                    handleChange={updateFormValueAtPath}
                    disabled={values.isRemote}
                />
            </div>
            <div className="field-wrapper checkbox">
                <CheckBox
                    id="#isRemote-toggle"
                    defaultChecked={values.isRemote}
                    label="Remote"
                    handleChange={updateFormValueAtPath(
                        `${stepName}.values.isRemote`
                    )}
                    className="remote-select"
                />
            </div>
            {!extraFieldsVisible && (
                <button
                    type="button"
                    onClick={showExtraFields}
                    className="extra-fields-toggle"
                >
                    Show additional fields &gt;
                </button>
            )}
            {extraFieldsVisible && (
                <React.Fragment>
                    <div className="field-wrapper">
                        <LongTextInput
                            inputName={`${stepName}.values.description`}
                            value={values.description}
                            label="Your key accomplishments"
                            type="textarea"
                            error={errors.description}
                            handleChange={updateFormValues}
                            className="experience-highlights"
                            rows={6}
                            maxChars={2000}
                        />
                    </div>
                    <div className="field-wrapper">
                        <InputLabel
                            text="Start date"
                            type="select"
                            htmlFor="#start-date-dropdown-section"
                        />
                        <div
                            className="date-select-wrapper"
                            id="start-date-dropdown-section"
                        >
                            <Dropdown
                                inputName={`${stepName}.values.startMonth`}
                                handleChange={updateFormValues}
                                className="dropdown-month"
                                options={monthDropdownOpts}
                                value={convertDropdownValue(values.startMonth)}
                                defaultOptionText="Month"
                                error={errors.startMonth}
                            />
                            <span className="date-dropdown-divider" />
                            <Dropdown
                                inputName={`${stepName}.values.startYear`}
                                handleChange={updateFormValues}
                                className="dropdown-year"
                                defaultOptionText="Year"
                                options={yearDropdownOpts}
                                disabled={
                                    !values.startMonth && !values.startYear
                                }
                                value={convertDropdownValue(values.startYear)}
                                error={errors.startYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper">
                        <InputLabel
                            text="End date"
                            type="select"
                            htmlFor="#end-date-dropdown-section"
                        />
                        <div
                            className="date-select-wrapper"
                            id="end-date-dropdown-section"
                        >
                            <Dropdown
                                inputName={`${stepName}.values.endMonth`}
                                handleChange={updateFormValues}
                                className="dropdown-month"
                                options={monthDropdownOpts}
                                value={convertDropdownValue(values.endMonth)}
                                defaultOptionText="Month"
                                disabled={
                                    values.isCurrent ||
                                    (!values.startYear && !values.endMonth)
                                }
                                error={errors.endMonth}
                            />
                            <span className="date-dropdown-divider" />
                            <Dropdown
                                inputName={`${stepName}.values.endYear`}
                                handleChange={updateFormValues}
                                className="dropdown-year"
                                defaultOptionText="Year"
                                options={filterYearDropdownOpts(
                                    values.startYear
                                )}
                                disabled={
                                    values.isCurrent ||
                                    (!values.endMonth && !values.endYear)
                                }
                                value={convertDropdownValue(values.endYear)}
                                error={errors.endYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isCurrent-toggle"
                            defaultChecked={values.isCurrent}
                            label="I am currently working in this role"
                            handleChange={updateFormValueAtPath(
                                `${stepName}.values.isCurrent`
                            )}
                            className="current-select"
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

ExpVariant.displayName = 'ExpVariant';

export default React.memo(ExpVariant);
