import React from 'react';

function appendScript(setScriptLoaded, setLoadError) {
    if (window.hbspt) return setScriptLoaded(true);

    const onLoad = () => setScriptLoaded(true);
    const onError = () => setLoadError(true);

    const script = document.createElement('script');
    script.src = `https://js.hsforms.net/forms/embed/v2.js`;
    script.async = true;
    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);
    // append script to mkto_embed_form
    const mktoEmbedFormElement = document.getElementById('mkto_embed_form');
    if (mktoEmbedFormElement) {
        mktoEmbedFormElement.appendChild(script);
    }
}

export function addChiliPiper() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.chilipiper.com/marketing.js';
    script.async = true;
    document.body.appendChild(script);
    const script2 = document.createElement('script');
    script2.textContent = `
    window.hbspt.forms.create({
    region: "na1",
    portalId: "45412959",
    formId: "3ee85555-6b13-462a-8e08-1c4df6fa4cb4",
    target: "#mkto_embed_form",
    css: ''
});
    var cpTenantDomain = "themuse"; // replace with your subdomain
    var cpRouterName = "inbound-router"; // replace with the router's name
    var cpHSDataFormIDs = ["3ee85555-6b13-462a-8e08-1c4df6fa4cb4"];
    window.addEventListener("message", function (event) {
       if (cpHSDataFormIDs.length > 0 && !cpHSDataFormIDs.includes(event.data.id)) return;
       if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormSubmitted") {
           var lead=event.data.data.submissionValues;
           for (var key in lead) {if (Array.isArray(lead[key])) {lead[key] = lead[key].toString().replaceAll(",",";");}}
           ChiliPiper.submit(cpTenantDomain, cpRouterName, {map: true, lead: lead});
       }
    });`;
    document.head.appendChild(script2);
}

export default function useHubSpot() {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const [loadError, setLoadError] = React.useState(false);

    React.useEffect(() => {
        if (scriptLoaded) {
            addChiliPiper();
            return;
        }

        appendScript(setScriptLoaded, setLoadError);
    }, [scriptLoaded]);

    return [loadError];
}
