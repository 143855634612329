/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';

import { useFlowControls } from '../FlowShell/controls';
import { QUICK_PROFILE_STEP_NAME } from '../Steps/constants';
import { quickProfileOnboardingFlowAPI } from './constants';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import { PageContext } from '../../../../contexts/ContextCreator';
import { useGetStepFormProps } from '../../../../contexts/Includes/modalContextHelpers';

export const QuickProfileOnboardingSubmitButton = React.memo(() => {
    const { fetchJobNotifications } = useContext(PageContext);
    const { flowData } = useFlowControls();
    const {
        validators,
        toggleJobProfileSnippetComplete,
        closeModal,
    } = flowData;
    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(QUICK_PROFILE_STEP_NAME);

    const validationPayload = {
        name: formResponses.name,
        ...(formResponses.isStudent
            ? { experience: null, education: formResponses.education }
            : {
                  experience: {
                      ...formResponses.experience,
                      title: formResponses.experience?.title?.trim(),
                  },
                  education: null,
              }),
    };

    const errorHandler = updateFormValueAtPath('errors.quickProfile');

    const apiRequestPayload = {
        name: formResponses.name,
        ...(formResponses.isStudent
            ? { educations: [formResponses.education] }
            : { experiences: [formResponses.experience] }),
    };

    const handleQuickProfileSubmit = React.useCallback(async () => {
        // handle click metric
        EmitMetric({
            misc_event_count: 1,
            misc_event_type: 'quick-profile-modal-submit-click',
        });

        clearStepErrors(QUICK_PROFILE_STEP_NAME);

        const {
            valid,
            validationErrors,
        } = await validators.quickProfileOnboarding(validationPayload);

        if (!valid) return errorHandler(validationErrors);

        // submit api request
        const { ok } = await quickProfileOnboardingFlowAPI.submitQuickProfile(
            apiRequestPayload
        );
        if (ok) {
            fetchJobNotifications();
            toggleJobProfileSnippetComplete();
            return closeModal();
        }
    }, [
        validationPayload,
        apiRequestPayload,
        clearStepErrors,
        closeModal,
        errorHandler,
        toggleJobProfileSnippetComplete,
        validators,
        fetchJobNotifications,
    ]);

    return (
        <button
            type="button"
            className="quick-profile-finish-button"
            onClick={handleQuickProfileSubmit}
        >
            Finish
        </button>
    );
});

export const QuickProfileOnboardingSkipButton = React.memo(() => {
    const { flowData } = useFlowControls();
    const { showSkipButton, closeModal } = flowData;

    if (!showSkipButton) return null;

    return (
        <button type="button" className="skip-button" onClick={closeModal}>
            Skip for now
        </button>
    );
});

export const buttonNavBox = (
    <div className="quick-profile-button-box">
        <QuickProfileOnboardingSubmitButton />
        <QuickProfileOnboardingSkipButton />
    </div>
);
