import React from 'react';
import { cheapDeepClone, set, isPlainObject } from '../../helpers/object';
import { getDefaultErrorsForStep } from '../../../components/Modals/Flows/Steps/constants';
import { toPath } from '../../helpers/form';
import deepmerge from 'deepmerge';

const getChildData = (obj, key) => obj[key];

const convertSelectValue = (selectValue) => {
    // convert stringified bool dropdown responses to actual bool values
    // if (selectValue === 'true' || selectValue === 'false' || selectValue === '1' || selectValue === '0') {
    //     return Boolean(selectValue);
    // }

    return selectValue;
};

const useForm = (initialFormState = {}) => {
    const [formValues, setFormValues] = React.useState(initialFormState);

    const updateFormValueAtPath = React.useCallback(
        (path) => (newValue) => {
            setFormValues((formData) => {
                const dataClone = cheapDeepClone(formData);
                const keys = toPath(path);
                const parentKeys = keys.slice(0, -1);
                const [updateKey] = keys.slice(-1);
                const data = parentKeys.reduce(getChildData, dataClone);

                // if our property to update is an object and our new values are an object - merge them
                if (isPlainObject(data[updateKey]) && isPlainObject(newValue)) {
                    data[updateKey] = deepmerge(data[updateKey], newValue);
                } else {
                    data[updateKey] = newValue;
                }
                return dataClone;
            });
        },
        []
    );

    const updateFormValueAtPathSelector = React.useCallback(
        (path, value) => (newValue) => {
            setFormValues((formData) => {
                const dataClone = cheapDeepClone(formData);
                const keys = toPath(path);
                const parentKeys = keys.slice(0, -1);
                const [updateKey] = keys.slice(-1);
                const data = parentKeys.reduce(getChildData, dataClone);

                data[updateKey] = newValue[value];
                return dataClone;
            });
        },
        []
    );

    const updateFormValues = React.useCallback(({ currentTarget }) => {
        const { name, value, checked, type } = currentTarget;
        const isCheckbox = type === 'checkbox';

        const isDropdown = type === 'select-one';

        setFormValues((formData) => {
            const dataClone = cheapDeepClone(formData);
            const keys = name.split('.');
            const nestedSegments = keys.slice(0, -1);
            const [finalSegment] = keys.slice(-1);
            const data = nestedSegments.reduce(getChildData, dataClone);
            data[finalSegment] = isCheckbox
                ? checked
                : isDropdown
                ? convertSelectValue(value)
                : value;
            return dataClone;
        });
    }, []);

    const clearStepErrors = React.useCallback((step) => {
        setFormValues((formData) => {
            const defaultStepErrors = getDefaultErrorsForStep(step);
            const dataClone = cheapDeepClone(formData);
            const replacedErrors = set(dataClone, {
                basePath: ['errors'],
                propName: step,
                newValue: defaultStepErrors,
            });
            return replacedErrors;
        });
    }, []);

    return [
        formValues,
        updateFormValues,
        setFormValues,
        updateFormValueAtPath,
        updateFormValueAtPathSelector,
        clearStepErrors,
    ];
};

export default useForm;
