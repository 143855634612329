import PropTypes from 'prop-types';
import React from 'react';

import './BasicDropdown.scss';

class BasicDropdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        };
    }

    toggleDropdown = () => {
        this.setState((state) => ({ opened: !state.opened }));
    };

    onChange = (e) => {
        const { handleRawChangeEvent } = this.props;
        if (handleRawChangeEvent) handleRawChangeEvent(e);
        this.setState(
            {
                opened: false,
            },
            this.props.onChange(e.target.value)
        );
    };

    render() {
        const {
            inputName,
            cssClass,
            hasError,
            selectedValue,
            controlLabel,
        } = this.props;

        const { opened } = this.state;

        const arrowClass = opened ? 'up' : 'down';
        const errorStyling = hasError ? 'hasError' : '';
        const placeholderStyling =
            selectedValue === '' ? 'placeholder-styling' : '';
        const cn = cssClass ? `${cssClass}-select` : '';

        const selectCn = [cn, errorStyling, placeholderStyling].join(' ');
        return (
            <div
                className={`component-Input-BasicDropdown ${arrowClass} ${cssClass}`}
            >
                {controlLabel}
                <select
                    name={inputName}
                    className={selectCn}
                    onClick={this.toggleDropdown}
                    onChange={this.onChange}
                    id={this.props.idValue}
                    value={this.props.selectedValue}
                    disabled={this.props.disabled}
                    aria-label={this.props.ariaLabel}
                    data-qa={this.props.qaDataAttr}
                >
                    {this.props.defaultLabel ? (
                        <option
                            disabled={this.props.shouldDisableDefault}
                            className="description-value"
                            hidden
                            value={
                                this.props.selectedValue
                                    ? ''
                                    : this.props.defaultLabel
                            }
                        >
                            {this.props.defaultLabel}
                        </option>
                    ) : null}
                    {this.props.options.map((item) =>
                        item.url ? (
                            <a key={item.value} href={item.url}>
                                <option value={item.value}>{item.label}</option>
                            </a>
                        ) : (
                            <option
                                key={`${item.value}_${item.label}`}
                                value={item.value}
                            >
                                {item.label}
                            </option>
                        )
                    )}
                </select>
            </div>
        );
    }
}

export default BasicDropdown;

BasicDropdown.propTypes = {
    cssClass: PropTypes.string,
    controlLabel: PropTypes.element,
    defaultLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]).isRequired,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]).isRequired,
            // Optional: if it has a url,
            url: PropTypes.string,
            // Optional: If it has an image please provide
            img: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        })
    ).isRequired,
    onChange: PropTypes.func,
    selectedValue: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    shouldDisableDefault: PropTypes.bool,
    idValue: PropTypes.string,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    qaDataAttr: PropTypes.string,
    handleRawChangeEvent: PropTypes.func,
    inputName: PropTypes.string,
    hasError: PropTypes.bool,
};

BasicDropdown.defaultProps = {
    cssClass: '',
    controlLabel: null,
    defaultLabel: null,
    onChange: () => {},
    shouldDisableDefault: false,
    idValue: null,
    disabled: false,
    ariaLabel: '',
    qaDataAttr: null,
    handleRawChangeEvent: null,
    inputName: 'dropdown',
    selectedValue: '',
    hasError: false,
};
