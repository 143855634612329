import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import RatingsCard from '../../QuestionCards/RatingsCard/RatingsCard';
import './ManagerCard.scss';

export default class ManagerCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            checkboxActive: !!props.managerComment,
            rating: props.managerRating,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'manager',
    };

    componentDidMount = () => {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        const ManagerObject = { managerRating: this.props.managerRating };
        let nextAction = CAN_SUBMIT;
        if (this.props.managerComment) {
            ManagerObject.managerComment = this.props.managerComment;
            nextAction = ALLOW_SUB_STEP;
        }
        this.props.managerRating
            ? this.props.onAnswer(
                  this.props.questionName,
                  ManagerObject,
                  nextAction,
                  false,
                  this.props.nextStep,
                  this.props.subStep
              )
            : null;
    };

    handleRating = (rating) => {
        this.setState({ rating }, () => this.checkAction());
    };

    handleCheck = () => {
        this.setState(
            {
                checkboxActive: !this.state.checkboxActive,
            },
            () => this.checkAction()
        );
    };

    checkAction = () => {
        let nextAction = CANT_SUBMIT;
        let answerData = { managerRating: this.state.rating };
        if (this.state.rating) {
            nextAction = CAN_SUBMIT;
            if (this.state.checkboxActive) {
                if (this.props.managerComment) {
                    answerData.managerComment = this.props.managerComment;
                }
                nextAction = ALLOW_SUB_STEP;
            }
        }
        this.props.onAnswer(
            this.props.questionName,
            answerData,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    render() {
        const props = {
            ratingType: 'RatingShield',
            handleRating: this.handleRating,
            handleCheck: this.handleCheck,
            descriptions: {
                low: 'Not Supportive',
                mid: 'Neutral',
                high: 'Very Supportive',
            },
            showCheckbox: true,
            rating: this.state.rating,
            defaultChecked: this.state.checkboxActive,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-ManagerCard">
                <RatingsCard {...props} />
            </div>
        );
    }
}
