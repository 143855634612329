export const countries = [
    {
        label: 'United States',
        value: 'United States',
        latitude: 38,
        longitude: -97,
    },
    {
        label: 'Andorra',
        value: 'Andorra',
        latitude: 42.5,
        longitude: 1.5,
    },
    {
        label: 'Afghanistan',
        value: 'Afghanistan',
        latitude: 33,
        longitude: 65,
    },
    {
        label: 'Antigua and Barbuda',
        value: 'Antigua and Barbuda',
        latitude: 17.05,
        longitude: -61.8,
    },
    {
        label: 'Anguilla',
        value: 'Anguilla',
        latitude: 18.25,
        longitude: -63.17,
    },
    {
        label: 'Albania',
        value: 'Albania',
        latitude: 41,
        longitude: 20,
    },
    {
        label: 'Armenia',
        value: 'Armenia',
        latitude: 40,
        longitude: 45,
    },
    {
        label: 'Netherlands Antilles',
        value: 'Netherlands Antilles',
        latitude: 12.25,
        longitude: -68.75,
    },
    {
        label: 'Angola',
        value: 'Angola',
        latitude: -12.5,
        longitude: 18.5,
    },
    {
        label: 'Antarctica',
        value: 'Antarctica',
        latitude: -90,
        longitude: 0,
    },
    {
        label: 'Argentina',
        value: 'Argentina',
        latitude: -34,
        longitude: -64,
    },
    {
        label: 'American Samoa',
        value: 'American Samoa',
        latitude: -14.33,
        longitude: -170,
    },
    {
        label: 'Austria',
        value: 'Austria',
        latitude: 47.33,
        longitude: 13.33,
    },
    {
        label: 'Australia',
        value: 'Australia',
        latitude: -27,
        longitude: 133,
    },
    {
        label: 'Aruba',
        value: 'Aruba',
        latitude: 12.5,
        longitude: -69.97,
    },
    {
        label: 'Azerbaijan',
        value: 'Azerbaijan',
        latitude: 40.5,
        longitude: 47.5,
    },
    {
        label: 'Bosnia and Herzegovina',
        value: 'Bosnia and Herzegovina',
        latitude: 44,
        longitude: 18,
    },
    {
        label: 'Barbados',
        value: 'Barbados',
        latitude: 13.17,
        longitude: -59.53,
    },
    {
        label: 'Bangladesh',
        value: 'Bangladesh',
        latitude: 24,
        longitude: 90,
    },
    {
        label: 'Belgium',
        value: 'Belgium',
        latitude: 50.83,
        longitude: 4,
    },
    {
        label: 'Burkina Faso',
        value: 'Burkina Faso',
        latitude: 13,
        longitude: -2,
    },
    {
        label: 'Bulgaria',
        value: 'Bulgaria',
        latitude: 43,
        longitude: 25,
    },
    {
        label: 'Bahrain',
        value: 'Bahrain',
        latitude: 26,
        longitude: 50.55,
    },
    {
        label: 'Burundi',
        value: 'Burundi',
        latitude: -3.5,
        longitude: 30,
    },
    {
        label: 'Benin',
        value: 'Benin',
        latitude: 9.5,
        longitude: 2.25,
    },
    {
        label: 'Bermuda',
        value: 'Bermuda',
        latitude: 32.33,
        longitude: -64.75,
    },
    {
        label: 'Brunei Darussalam',
        value: 'Brunei Darussalam',
        latitude: 4.5,
        longitude: 114.67,
    },
    {
        label: 'Bolivia',
        value: 'Bolivia',
        latitude: -17,
        longitude: -65,
    },
    {
        label: 'Brazil',
        value: 'Brazil',
        latitude: -10,
        longitude: -55,
    },
    {
        label: 'Bahamas',
        value: 'Bahamas',
        latitude: 24.25,
        longitude: -76,
    },
    {
        label: 'Bhutan',
        value: 'Bhutan',
        latitude: 27.5,
        longitude: 90.5,
    },
    {
        label: 'Bouvet Island',
        value: 'Bouvet Island',
        latitude: -54.43,
        longitude: 3.4,
    },
    {
        label: 'Botswana',
        value: 'Botswana',
        latitude: -22,
        longitude: 24,
    },
    {
        label: 'Belarus',
        value: 'Belarus',
        latitude: 53,
        longitude: 28,
    },
    {
        label: 'Belize',
        value: 'Belize',
        latitude: 17.25,
        longitude: -88.75,
    },
    {
        label: 'Canada',
        value: 'Canada',
        latitude: 60,
        longitude: -95,
    },
    {
        label: 'Cocos (Keeling) Islands',
        value: 'Cocos (Keeling) Islands',
        latitude: -12.5,
        longitude: 96.83,
    },
    {
        label: 'The Democratic Republic of the Congo',
        value: 'The Democratic Republic of the Congo',
        latitude: 0,
        longitude: 25,
    },
    {
        label: 'Central African Republic',
        value: 'Central African Republic',
        latitude: 7,
        longitude: 21,
    },
    {
        label: 'Congo',
        value: 'Congo',
        latitude: -1,
        longitude: 15,
    },
    {
        label: 'Switzerland',
        value: 'Switzerland',
        latitude: 47,
        longitude: 8,
    },
    {
        label: "Cote d'Ivoire",
        value: "Cote d'Ivoire",
        latitude: 8,
        longitude: -5,
    },
    {
        label: 'Cook Islands',
        value: 'Cook Islands',
        latitude: -21.23,
        longitude: -159.77,
    },
    {
        label: 'Chile',
        value: 'Chile',
        latitude: -30,
        longitude: -71,
    },
    {
        label: 'Cameroon',
        value: 'Cameroon',
        latitude: 6,
        longitude: 12,
    },
    {
        label: 'China',
        value: 'China',
        latitude: 35,
        longitude: 105,
    },
    {
        label: 'Colombia',
        value: 'Colombia',
        latitude: 4,
        longitude: -72,
    },
    {
        label: 'Costa Rica',
        value: 'Costa Rica',
        latitude: 10,
        longitude: -84,
    },
    {
        label: 'Cuba',
        value: 'Cuba',
        latitude: 21.5,
        longitude: -80,
    },
    {
        label: 'Cape Verde',
        value: 'Cape Verde',
        latitude: 16,
        longitude: -24,
    },
    {
        label: 'Christmas Island',
        value: 'Christmas Island',
        latitude: -10.5,
        longitude: 105.67,
    },
    {
        label: 'Cyprus',
        value: 'Cyprus',
        latitude: 35,
        longitude: 33,
    },
    {
        label: 'Czech Republic',
        value: 'Czech Republic',
        latitude: 49.75,
        longitude: 15.5,
    },
    {
        label: 'Germany',
        value: 'Germany',
        latitude: 51,
        longitude: 9,
    },
    {
        label: 'Djibouti',
        value: 'Djibouti',
        latitude: 11.5,
        longitude: 43,
    },
    {
        label: 'Denmark',
        value: 'Denmark',
        latitude: 56,
        longitude: 10,
    },
    {
        label: 'Dominica',
        value: 'Dominica',
        latitude: 15.42,
        longitude: -61.33,
    },
    {
        label: 'Dominican Republic',
        value: 'Dominican Republic',
        latitude: 19,
        longitude: -70.67,
    },
    {
        label: 'Algeria',
        value: 'Algeria',
        latitude: 28,
        longitude: 3,
    },
    {
        label: 'Ecuador',
        value: 'Ecuador',
        latitude: -2,
        longitude: -77.5,
    },
    {
        label: 'Estonia',
        value: 'Estonia',
        latitude: 59,
        longitude: 26,
    },
    {
        label: 'Egypt',
        value: 'Egypt',
        latitude: 27,
        longitude: 30,
    },
    {
        label: 'Western Sahara',
        value: 'Western Sahara',
        latitude: 24.5,
        longitude: -13,
    },
    {
        label: 'Eritrea',
        value: 'Eritrea',
        latitude: 15,
        longitude: 39,
    },
    {
        label: 'Spain',
        value: 'Spain',
        latitude: 40,
        longitude: -4,
    },
    {
        label: 'Ethiopia',
        value: 'Ethiopia',
        latitude: 8,
        longitude: 38,
    },
    {
        label: 'Europe',
        value: 'Europe',
        latitude: 47,
        longitude: 8,
    },
    {
        label: 'Finland',
        value: 'Finland',
        latitude: 64,
        longitude: 26,
    },
    {
        label: 'Fiji',
        value: 'Fiji',
        latitude: -18,
        longitude: 175,
    },
    {
        label: 'Falkland Islands',
        value: 'Falkland Islands',
        latitude: -51.75,
        longitude: -59,
    },
    {
        label: 'Micronesia',
        value: 'Micronesia',
        latitude: 6.92,
        longitude: 158.25,
    },
    {
        label: 'Faroe Islands',
        value: 'Faroe Islands',
        latitude: 62,
        longitude: -7,
    },
    {
        label: 'France',
        value: 'France',
        latitude: 46,
        longitude: 2,
    },
    {
        label: 'Gabon',
        value: 'Gabon',
        latitude: -1,
        longitude: 11.75,
    },
    {
        label: 'United Kingdom',
        value: 'United Kingdom',
        latitude: 54,
        longitude: -2,
    },
    {
        label: 'Grenada',
        value: 'Grenada',
        latitude: 12.12,
        longitude: -61.67,
    },
    {
        label: 'Georgia',
        value: 'Georgia',
        latitude: 42,
        longitude: 43.5,
    },
    {
        label: 'French Guiana',
        value: 'French Guiana',
        latitude: 4,
        longitude: -53,
    },
    {
        label: 'Ghana',
        value: 'Ghana',
        latitude: 8,
        longitude: -2,
    },
    {
        label: 'Gibraltar',
        value: 'Gibraltar',
        latitude: 36.18,
        longitude: -5.37,
    },
    {
        label: 'Greenland',
        value: 'Greenland',
        latitude: 72,
        longitude: -40,
    },
    {
        label: 'Gambia',
        value: 'Gambia',
        latitude: 13.47,
        longitude: -16.57,
    },
    {
        label: 'Guinea',
        value: 'Guinea',
        latitude: 11,
        longitude: -10,
    },
    {
        label: 'Guadeloupe',
        value: 'Guadeloupe',
        latitude: 16.25,
        longitude: -61.58,
    },
    {
        label: 'Equatorial Guinea',
        value: 'Equatorial Guinea',
        latitude: 2,
        longitude: 10,
    },
    {
        label: 'Greece',
        value: 'Greece',
        latitude: 39,
        longitude: 22,
    },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'South Georgia and the South Sandwich Islands',
        latitude: -54.5,
        longitude: -37,
    },
    {
        label: 'Guatemala',
        value: 'Guatemala',
        latitude: 15.5,
        longitude: -90.25,
    },
    {
        label: 'Guam',
        value: 'Guam',
        latitude: 13.47,
        longitude: 144.78,
    },
    {
        label: 'Guinea-Bissau',
        value: 'Guinea-Bissau',
        latitude: 12,
        longitude: -15,
    },
    {
        label: 'Guyana',
        value: 'Guyana',
        latitude: 5,
        longitude: -59,
    },
    {
        label: 'Hong Kong',
        value: 'Hong Kong',
        latitude: 22.25,
        longitude: 114.17,
    },
    {
        label: 'Heard Island and McDonald Islands',
        value: 'Heard Island and McDonald Islands',
        latitude: -53.1,
        longitude: 72.52,
    },
    {
        label: 'Honduras',
        value: 'Honduras',
        latitude: 15,
        longitude: -86.5,
    },
    {
        label: 'Croatia',
        value: 'Croatia',
        latitude: 45.17,
        longitude: 15.5,
    },
    {
        label: 'Haiti',
        value: 'Haiti',
        latitude: 19,
        longitude: -72.42,
    },
    {
        label: 'Hungary',
        value: 'Hungary',
        latitude: 47,
        longitude: 20,
    },
    {
        label: 'Indonesia',
        value: 'Indonesia',
        latitude: -5,
        longitude: 120,
    },
    {
        label: 'Ireland',
        value: 'Ireland',
        latitude: 53,
        longitude: -8,
    },
    {
        label: 'Israel',
        value: 'Israel',
        latitude: 31.5,
        longitude: 34.75,
    },
    {
        label: 'India',
        value: 'India',
        latitude: 20,
        longitude: 77,
    },
    {
        label: 'British Indian Ocean Territory',
        value: 'British Indian Ocean Territory',
        latitude: -6,
        longitude: 71.5,
    },
    {
        label: 'Iraq',
        value: 'Iraq',
        latitude: 33,
        longitude: 44,
    },
    {
        label: 'Iran',
        value: 'Iran',
        latitude: 32,
        longitude: 53,
    },
    {
        label: 'Iceland',
        value: 'Iceland',
        latitude: 65,
        longitude: -18,
    },
    {
        label: 'Italy',
        value: 'Italy',
        latitude: 42.83,
        longitude: 12.83,
    },
    {
        label: 'Jamaica',
        value: 'Jamaica',
        latitude: 18.25,
        longitude: -77.5,
    },
    {
        label: 'Jordan',
        value: 'Jordan',
        latitude: 31,
        longitude: 36,
    },
    {
        label: 'Japan',
        value: 'Japan',
        latitude: 36,
        longitude: 138,
    },
    {
        label: 'Kenya',
        value: 'Kenya',
        latitude: 1,
        longitude: 38,
    },
    {
        label: 'Kyrgyzstan',
        value: 'Kyrgyzstan',
        latitude: 41,
        longitude: 75,
    },
    {
        label: 'Cambodia',
        value: 'Cambodia',
        latitude: 13,
        longitude: 105,
    },
    {
        label: 'Kiribati',
        value: 'Kiribati',
        latitude: 1.42,
        longitude: 173,
    },
    {
        label: 'Comoros',
        value: 'Comoros',
        latitude: -12.17,
        longitude: 44.25,
    },
    {
        label: 'Saint Kitts and Nevis',
        value: 'Saint Kitts and Nevis',
        latitude: 17.33,
        longitude: -62.75,
    },
    {
        label: "Democratic People's Republic of Korea",
        value: "Democratic People's Republic of Korea",
        latitude: 40,
        longitude: 127,
    },
    {
        label: 'Republic of Korea',
        value: 'Republic of Korea',
        latitude: 37,
        longitude: 127.5,
    },
    {
        label: 'Kuwait',
        value: 'Kuwait',
        latitude: 29.34,
        longitude: 47.66,
    },
    {
        label: 'Cayman Islands',
        value: 'Cayman Islands',
        latitude: 19.5,
        longitude: -80.5,
    },
    {
        label: 'Kazakhstan',
        value: 'Kazakhstan',
        latitude: 48,
        longitude: 68,
    },
    {
        label: "Lao People's Democratic Republic",
        value: "Lao People's Democratic Republic",
        latitude: 18,
        longitude: 105,
    },
    {
        label: 'Lebanon',
        value: 'Lebanon',
        latitude: 33.83,
        longitude: 35.83,
    },
    {
        label: 'Saint Lucia',
        value: 'Saint Lucia',
        latitude: 13.88,
        longitude: -61.13,
    },
    {
        label: 'Liechtenstein',
        value: 'Liechtenstein',
        latitude: 47.17,
        longitude: 9.53,
    },
    {
        label: 'Sri Lanka',
        value: 'Sri Lanka',
        latitude: 7,
        longitude: 81,
    },
    {
        label: 'Liberia',
        value: 'Liberia',
        latitude: 6.5,
        longitude: -9.5,
    },
    {
        label: 'Lesotho',
        value: 'Lesotho',
        latitude: -29.5,
        longitude: 28.5,
    },
    {
        label: 'Lithuania',
        value: 'Lithuania',
        latitude: 56,
        longitude: 24,
    },
    {
        label: 'Luxembourg',
        value: 'Luxembourg',
        latitude: 49.75,
        longitude: 6.17,
    },
    {
        label: 'Latvia',
        value: 'Latvia',
        latitude: 57,
        longitude: 25,
    },
    {
        label: 'Libyan Arab Jamahiriya',
        value: 'Libyan Arab Jamahiriya',
        latitude: 25,
        longitude: 17,
    },
    {
        label: 'Morocco',
        value: 'Morocco',
        latitude: 32,
        longitude: -5,
    },
    {
        label: 'Monaco',
        value: 'Monaco',
        latitude: 43.73,
        longitude: 7.4,
    },
    {
        label: 'Moldova, Republic of',
        value: 'Moldova, Republic of',
        latitude: 47,
        longitude: 29,
    },
    {
        label: 'Montenegro',
        value: 'Montenegro',
        latitude: 42,
        longitude: 19,
    },
    {
        label: 'Madagascar',
        value: 'Madagascar',
        latitude: -20,
        longitude: 47,
    },
    {
        label: 'Marshall Islands',
        value: 'Marshall Islands',
        latitude: 9,
        longitude: 168,
    },
    {
        label: 'Macedonia',
        value: 'Macedonia',
        latitude: 41.83,
        longitude: 22,
    },
    {
        label: 'Mali',
        value: 'Mali',
        latitude: 17,
        longitude: -4,
    },
    {
        label: 'Myanmar',
        value: 'Myanmar',
        latitude: 22,
        longitude: 98,
    },
    {
        label: 'Mongolia',
        value: 'Mongolia',
        latitude: 46,
        longitude: 105,
    },
    {
        label: 'Macao',
        value: 'Macao',
        latitude: 22.17,
        longitude: 113.55,
    },
    {
        label: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
        latitude: 15.2,
        longitude: 145.75,
    },
    {
        label: 'Martinique',
        value: 'Martinique',
        latitude: 14.67,
        longitude: -61,
    },
    {
        label: 'Mauritania',
        value: 'Mauritania',
        latitude: 20,
        longitude: -12,
    },
    {
        label: 'Montserrat',
        value: 'Montserrat',
        latitude: 16.75,
        longitude: -62.2,
    },
    {
        label: 'Malta',
        value: 'Malta',
        latitude: 35.83,
        longitude: 14.58,
    },
    {
        label: 'Mauritius',
        value: 'Mauritius',
        latitude: -20.28,
        longitude: 57.55,
    },
    {
        label: 'Maldives',
        value: 'Maldives',
        latitude: 3.25,
        longitude: 73,
    },
    {
        label: 'Malawi',
        value: 'Malawi',
        latitude: -13.5,
        longitude: 34,
    },
    {
        label: 'Mexico',
        value: 'Mexico',
        latitude: 23,
        longitude: -102,
    },
    {
        label: 'Malaysia',
        value: 'Malaysia',
        latitude: 2.5,
        longitude: 112.5,
    },
    {
        label: 'Mozambique',
        value: 'Mozambique',
        latitude: -18.25,
        longitude: 35,
    },
    {
        label: 'Namibia',
        value: 'Namibia',
        latitude: -22,
        longitude: 17,
    },
    {
        label: 'New Caledonia',
        value: 'New Caledonia',
        latitude: -21.5,
        longitude: 165.5,
    },
    {
        label: 'Niger',
        value: 'Niger',
        latitude: 16,
        longitude: 8,
    },
    {
        label: 'Norfolk Island',
        value: 'Norfolk Island',
        latitude: -29.03,
        longitude: 167.95,
    },
    {
        label: 'Nigeria',
        value: 'Nigeria',
        latitude: 10,
        longitude: 8,
    },
    {
        label: 'Nicaragua',
        value: 'Nicaragua',
        latitude: 13,
        longitude: -85,
    },
    {
        label: 'Netherlands',
        value: 'Netherlands',
        latitude: 52.5,
        longitude: 5.75,
    },
    {
        label: 'Norway',
        value: 'Norway',
        latitude: 62,
        longitude: 10,
    },
    {
        label: 'Nepal',
        value: 'Nepal',
        latitude: 28,
        longitude: 84,
    },
    {
        label: 'Nauru',
        value: 'Nauru',
        latitude: -0.53,
        longitude: 166.92,
    },
    {
        label: 'Niue',
        value: 'Niue',
        latitude: -19.03,
        longitude: -169.87,
    },
    {
        label: 'New Zealand',
        value: 'New Zealand',
        latitude: -41,
        longitude: 174,
    },
    {
        label: 'Oman',
        value: 'Oman',
        latitude: 21,
        longitude: 57,
    },
    {
        label: 'Panama',
        value: 'Panama',
        latitude: 9,
        longitude: -80,
    },
    {
        label: 'Peru',
        value: 'Peru',
        latitude: -10,
        longitude: -76,
    },
    {
        label: 'French Polynesia',
        value: 'French Polynesia',
        latitude: -15,
        longitude: -140,
    },
    {
        label: 'Papua New Guinea',
        value: 'Papua New Guinea',
        latitude: -6,
        longitude: 147,
    },
    {
        label: 'Philippines',
        value: 'Philippines',
        latitude: 13,
        longitude: 122,
    },
    {
        label: 'Pakistan',
        value: 'Pakistan',
        latitude: 30,
        longitude: 70,
    },
    {
        label: 'Poland',
        value: 'Poland',
        latitude: 52,
        longitude: 20,
    },
    {
        label: 'Saint Pierre and Miquelon',
        value: 'Saint Pierre and Miquelon',
        latitude: 46.83,
        longitude: -56.33,
    },
    {
        label: 'Puerto Rico',
        value: 'Puerto Rico',
        latitude: 18.25,
        longitude: -66.5,
    },
    {
        label: 'Palestinian Territory',
        value: 'Palestinian Territory',
        latitude: 32,
        longitude: 35.25,
    },
    {
        label: 'Portugal',
        value: 'Portugal',
        latitude: 39.5,
        longitude: -8,
    },
    {
        label: 'Palau',
        value: 'Palau',
        latitude: 7.5,
        longitude: 134.5,
    },
    {
        label: 'Paraguay',
        value: 'Paraguay',
        latitude: -23,
        longitude: -58,
    },
    {
        label: 'Qatar',
        value: 'Qatar',
        latitude: 25.5,
        longitude: 51.25,
    },
    {
        label: 'Reunion',
        value: 'Reunion',
        latitude: -21.1,
        longitude: 55.6,
    },
    {
        label: 'Romania',
        value: 'Romania',
        latitude: 46,
        longitude: 25,
    },
    {
        label: 'Serbia',
        value: 'Serbia',
        latitude: 44,
        longitude: 21,
    },
    {
        label: 'Russian Federation',
        value: 'Russian Federation',
        latitude: 60,
        longitude: 100,
    },
    {
        label: 'Rwanda',
        value: 'Rwanda',
        latitude: -2,
        longitude: 30,
    },
    {
        label: 'Saudi Arabia',
        value: 'Saudi Arabia',
        latitude: 25,
        longitude: 45,
    },
    {
        label: 'Solomon Islands',
        value: 'Solomon Islands',
        latitude: -8,
        longitude: 159,
    },
    {
        label: 'Seychelles',
        value: 'Seychelles',
        latitude: -4.58,
        longitude: 55.67,
    },
    {
        label: 'Sudan',
        value: 'Sudan',
        latitude: 15,
        longitude: 30,
    },
    {
        label: 'Sweden',
        value: 'Sweden',
        latitude: 62,
        longitude: 15,
    },
    {
        label: 'Singapore',
        value: 'Singapore',
        latitude: 1.37,
        longitude: 103.8,
    },
    {
        label: 'Saint Helena',
        value: 'Saint Helena',
        latitude: -15.93,
        longitude: -5.7,
    },
    {
        label: 'Slovenia',
        value: 'Slovenia',
        latitude: 46,
        longitude: 15,
    },
    {
        label: 'Svalbard and Jan Mayen',
        value: 'Svalbard and Jan Mayen',
        latitude: 78,
        longitude: 20,
    },
    {
        label: 'Slovakia',
        value: 'Slovakia',
        latitude: 48.67,
        longitude: 19.5,
    },
    {
        label: 'Sierra Leone',
        value: 'Sierra Leone',
        latitude: 8.5,
        longitude: -11.5,
    },
    {
        label: 'San Marino',
        value: 'San Marino',
        latitude: 43.77,
        longitude: 12.42,
    },
    {
        label: 'Senegal',
        value: 'Senegal',
        latitude: 14,
        longitude: -14,
    },
    {
        label: 'Somalia',
        value: 'Somalia',
        latitude: 10,
        longitude: 49,
    },
    {
        label: 'Suriname',
        value: 'Suriname',
        latitude: 4,
        longitude: -56,
    },
    {
        label: 'Sao Tome and Principe',
        value: 'Sao Tome and Principe',
        latitude: 1,
        longitude: 7,
    },
    {
        label: 'El Salvador',
        value: 'El Salvador',
        latitude: 13.83,
        longitude: -88.92,
    },
    {
        label: 'Syrian Arab Republic',
        value: 'Syrian Arab Republic',
        latitude: 35,
        longitude: 38,
    },
    {
        label: 'Swaziland',
        value: 'Swaziland',
        latitude: -26.5,
        longitude: 31.5,
    },
    {
        label: 'Turks and Caicos Islands',
        value: 'Turks and Caicos Islands',
        latitude: 21.75,
        longitude: -71.58,
    },
    {
        label: 'Chad',
        value: 'Chad',
        latitude: 15,
        longitude: 19,
    },
    {
        label: 'French Southern Territories',
        value: 'French Southern Territories',
        latitude: -43,
        longitude: 67,
    },
    {
        label: 'Togo',
        value: 'Togo',
        latitude: 8,
        longitude: 1.17,
    },
    {
        label: 'Thailand',
        value: 'Thailand',
        latitude: 15,
        longitude: 100,
    },
    {
        label: 'Tajikistan',
        value: 'Tajikistan',
        latitude: 39,
        longitude: 71,
    },
    {
        label: 'Tokelau',
        value: 'Tokelau',
        latitude: -9,
        longitude: -172,
    },
    {
        label: 'Turkmenistan',
        value: 'Turkmenistan',
        latitude: 40,
        longitude: 60,
    },
    {
        label: 'Tunisia',
        value: 'Tunisia',
        latitude: 34,
        longitude: 9,
    },
    {
        label: 'Tonga',
        value: 'Tonga',
        latitude: -20,
        longitude: -175,
    },
    {
        label: 'Turkey',
        value: 'Turkey',
        latitude: 39,
        longitude: 35,
    },
    {
        label: 'Trinidad and Tobago',
        value: 'Trinidad and Tobago',
        latitude: 11,
        longitude: -61,
    },
    {
        label: 'Tuvalu',
        value: 'Tuvalu',
        latitude: -8,
        longitude: 178,
    },
    {
        label: 'Taiwan',
        value: 'Taiwan',
        latitude: 23.5,
        longitude: 121,
    },
    {
        label: 'Tanzania',
        value: 'Tanzania',
        latitude: -6,
        longitude: 35,
    },
    {
        label: 'Ukraine',
        value: 'Ukraine',
        latitude: 49,
        longitude: 32,
    },
    {
        label: 'Uganda',
        value: 'Uganda',
        latitude: 1,
        longitude: 32,
    },
    {
        label: 'United Arab Emirates',
        value: 'United Arab Emirates',
        latitude: 24,
        longitude: 54,
    },
    {
        label: 'United States Minor Outlying Islands',
        value: 'United States Minor Outlying Islands',
        latitude: 19.28,
        longitude: 166.6,
    },
    {
        label: 'Uruguay',
        value: 'Uruguay',
        latitude: -33,
        longitude: -56,
    },
    {
        label: 'Uzbekistan',
        value: 'Uzbekistan',
        latitude: 41,
        longitude: 64,
    },
    {
        label: 'Holy See (Vatican City State)',
        value: 'Holy See (Vatican City State)',
        latitude: 41.9,
        longitude: 12.45,
    },
    {
        label: 'Saint Vincent and the Grenadines',
        value: 'Saint Vincent and the Grenadines',
        latitude: 13.25,
        longitude: -61.2,
    },
    {
        label: 'Venezuela',
        value: 'Venezuela',
        latitude: 8,
        longitude: -66,
    },
    {
        label: 'British Virgin Islands',
        value: 'British Virgin Islands',
        latitude: 18.5,
        longitude: -64.5,
    },
    {
        label: 'U.S. Virgin Islands',
        value: 'U.S. Virgin Islands',
        latitude: 18.33,
        longitude: -64.83,
    },
    {
        label: 'Vietnam',
        value: 'Vietnam',
        latitude: 16,
        longitude: 106,
    },
    {
        label: 'Vanuatu',
        value: 'Vanuatu',
        latitude: -16,
        longitude: 167,
    },
    {
        label: 'Wallis and Futuna',
        value: 'Wallis and Futuna',
        latitude: -13.3,
        longitude: -176.2,
    },
    {
        label: 'Samoa',
        value: 'Samoa',
        latitude: -13.58,
        longitude: -172.33,
    },
    {
        label: 'Yemen',
        value: 'Yemen',
        latitude: 15,
        longitude: 48,
    },
    {
        label: 'Mayotte',
        value: 'Mayotte',
        latitude: -12.83,
        longitude: 45.17,
    },
    {
        label: 'South Africa',
        value: 'South Africa',
        latitude: -29,
        longitude: 24,
    },
    {
        label: 'Zambia',
        value: 'Zambia',
        latitude: -15,
        longitude: 30,
    },
    {
        label: 'Zimbabwe',
        value: 'Zimbabwe',
        latitude: -20,
        longitude: 30,
    },
];
