import React from 'react';
import {
    initSnowplowTracker,
    triggerSnowplowPageViewEvent,
} from '@themuse/snowplow-js-client';

export const PAGE_VIEW_SECTIONS = Object.freeze({
    ADVICE: 'advice',
    ADVICE_LANDING: 'advice_landing',
    COMMUNITY: 'community',
    COMPANY_PROFILE: 'company_profile',
    COMPANY_REVIEWS: 'company_reviews',
    EMPLOYER_HUB: 'employer_hub',
    EMPLOYER_RESOURCES: 'employer_resources',
    EVENTS: 'events',
    GROUPS: 'groups',
    HOMEPAGE: 'homepage',
    JOB_DETAIL: 'job_detail',
    LOGIN: 'login',
    RESEARCH: 'research',
    SEARCH: 'search',
    USER_PORTAL: 'user_portal',
    OTHER: 'other',
});

export const PAGE_VIEW_TYPES = Object.freeze({
    COLLECTION: 'collection',
    CONTENT: 'content',
    FEED: 'feed',
    LANDING_PAGE: 'landing_page',
    MESSAGING: 'messaging',
    USER_INPUT: 'user_input',
    OTHER: 'other',
});

export const PAGE_TABS = Object.freeze({
    BEST_CEO: 'best_ceo',
    BEST_COMPANY: 'best_company',
    BEST_CONSULTING: 'best_consulting',
    BEST_FINANCE: 'best_finance',
    BEST_TECH: 'best_tech',
    BEST_FLEXIBILITY: 'best_flexibility',
    BEST_MANANGER: 'best_manager',
    CLAIM_COMPANY: 'claim_company',
    COMPANY_BENEFITS: 'company_benefits',
    COMPANY_JOBS: 'company_jobs',
    COMPANY_OVERVIEW: 'company_overview',
    COMPANY_PARENTAL_LEAVE: 'company_parental_leave',
    COMPANY_REVIEWS: 'company_reviews',
    COMPANY_WORK_LIFE_BALANCE: 'company_work_life_balance',
    OVERVIEW: 'overview',
    RESOURCES: 'resources',
});

export const SnowplowPageViewTracker = ({
    pageCompanyId = null,
    pageEntityId = null,
    pageSection = PAGE_VIEW_TYPES.OTHER,
    pageTab = null,
    pageType = PAGE_VIEW_TYPES.OTHER,
}) => {
    React.useEffect(() => {
        const snowplowCollector =
            window.globalPageProps?.PAGE_PROPS?.snowplow?.collectorEndpoint;
        const snowplowAppId =
            window.globalPageProps?.PAGE_PROPS?.snowplow?.appId;
        const userId = window.globalPageProps?.PAGE_PROPS?.session?.id;

        if (!snowplowCollector || !snowplowAppId) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            sp_page_sponsor_id: pageCompanyId ? String(pageCompanyId) : null,
            sp_page_entity_id: pageEntityId ? String(pageEntityId) : null,
            sp_page_is_external: false,
            sp_page_section: pageSection,
            sp_page_tab: pageTab,
            sp_page_type: pageType,
            sp_user_id: userId,
            sp_user_logged_in: !!userId,
        });

        initSnowplowTracker(snowplowCollector, snowplowAppId, true);
        triggerSnowplowPageViewEvent();
    }, []);

    return null;
};
