import React from 'react';
import { View, useViewport } from '@fairygodboss/ui';
import moment from 'moment-timezone';
import FlowStep from '../../FlowStep';
import { CONFIRM_SUCCESS_STEP_NAME } from '../constants';
import SuccessIllustration from './SuccessIllustration';
import SocialShareBarV2 from '../../../../CallToAction/SocialShareBarV2/SocialShareBarV2';

import './ConfirmSuccessV2.scss';

export const ConfirmSuccessV2 = ({ event }) => {
    const { startTime } = event;
    const urlToShare = `https://fairygodboss.com${event?.viewUrl}`;
    const momentStartTime = moment(new Date(moment.tz(startTime, 'UTC'))).tz(
        'America/New_York'
    );

    const { isXS } = useViewport();

    const getTime = () => {
        const timeFormatted = momentStartTime.format('h:mmA');
        return timeFormatted;
    };

    const getDate = () => {
        const month = momentStartTime.format('MMMM D');
        return month;
    };

    return (
        <FlowStep name={CONFIRM_SUCCESS_STEP_NAME}>
            <View
                fontFamily="Open Sans"
                css={{
                    width: !isXS ? '309px' : 'auto',
                    height: !isXS ? '500px' : 'auto',
                    paddingTop: !isXS ? '50px' : 'auto',
                }}
            >
                <View
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '600',
                        marginTop: '40px',
                    }}
                >
                    <SuccessIllustration />
                </View>
                <View
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '600',
                        fontSize: '20px',
                        marginTop: '12px',
                        color: '#353535',
                    }}
                >
                    {event.eventType === 'Virtual Recruiting Event'
                        ? 'Thanks for Registering!'
                        : 'Success, you’re registered!'}
                </View>
                <View
                    css={{
                        textAlign: 'center',
                        fontSize: '16px',
                        marginTop: '8px',
                        color: '#353535',
                    }}
                >
                    {event.eventType === 'Virtual Recruiting Event' ? (
                        <p>
                            A member of our team is currently reviewing your
                            registration. We look forward to seeing you on{' '}
                            <b style={{ fontWeight: '600' }}>{getDate()}</b> at{' '}
                            <b style={{ fontWeight: '600' }}>{getTime()}</b>.
                            Know someone else interested?{' '}
                            <b style={{ fontWeight: '600' }}>
                                Spread the word.
                            </b>
                        </p>
                    ) : (
                        <p>
                            We look forward to seeing you on{' '}
                            <b style={{ fontWeight: '600' }}>{getDate()}</b> at{' '}
                            <b style={{ fontWeight: '600' }}>{getTime()}</b>.
                            Know someone else interested?{' '}
                            <b style={{ fontWeight: '600' }}>
                                Spread the word.
                            </b>
                        </p>
                    )}
                </View>
                <View
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        marginTop: '8px',
                        marginBottom: '40px',
                    }}
                >
                    <SocialShareBarV2
                        hash={event.hash}
                        type={event.type}
                        urlToShare={urlToShare}
                    />
                </View>
            </View>
        </FlowStep>
    );
};
