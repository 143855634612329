import PropTypes from 'prop-types';
import React from 'react';

import './CopyToClipboardInput.scss';

export default class CopyToClipboardInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            copied: false,
        };
    }

    static propTypes = {
        urlToShare: PropTypes.string.isRequired,
        labelText: PropTypes.string,
    };

    static defaultProps = {
        labelText: 'Or copy this link:',
    };

    copyTextToClipboard = () => {
        if (document) {
            var copyText = document.getElementById('share-url-input');
            copyText.select();
            document.execCommand('copy');
            this.setState(
                {
                    copied: true,
                },
                this.closeCopiedText
            );
        }
    };

    closeCopiedText = () => {
        setTimeout(() => {
            this.setState({
                copied: false,
            });
        }, 2000);
    };

    render() {
        return (
            <div className="component-Input-CopyToClipboardInput">
                <div className="label-section">
                    <label className="label-for-group-url" htmlFor={'copy-url'}>
                        {this.props.labelText}
                    </label>
                    {this.state.copied && (
                        <p className="copied-text-alert">Copied to clipboard</p>
                    )}
                </div>
                <div className="input-section">
                    <input
                        id={'share-url-input'}
                        type="text"
                        name={'copy-url'}
                        value={this.props.urlToShare}
                        placeholder={this.props.urlToShare}
                        readOnly
                        className="copy-url-input"
                    />
                    <div
                        className="copy-url-icon"
                        onClick={this.copyTextToClipboard}
                    />
                </div>
            </div>
        );
    }
}
