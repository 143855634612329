/**
 * THIS BE REDUX + REDUX-THUNK
 * THIS IS THE REDUCER, ACTIONS, AND STORE IN ONE GIANT FILE
 * THIS is NOT the standard way to build a redux store but This is just compacted for the form layer of our site
 */

import axios from 'axios';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';

import thunk from 'redux-thunk';

// OUR ACTIONS
export const submitFormStart = () => ({
    type: 'SUBMIT_FORM_START',
});

/**
 * Action that will submit our form to a certain URL
 * @param url
 * @returns {function(*, *)}
 */
export const submitForm = (url) => {
    // We return a function instead of an action object

    return (dispatch, getState) => {
        dispatch(submitFormStart());
        return axios
            .post(url, getState().form)
            .then((response) => {
                return dispatch(submitFormFinished(response.data));
            })
            .catch(function (error) {
                return dispatch(submitFormFinished(null, error));
            });
    };
};

/**
 * Callback after form is submited
 * @param data
 * @param error
 */
export const submitFormFinished = (data, error) => ({
    type: 'SUBMIT_FORM_FINISHED',
    data,
    error,
});

/**
 * Update/Add a element at the name space
 * @param name
 * @param isValid
 * @param value
 * @param errors
 * @param label
 */
export const updateFormElement = (name, isValid, value, errors, label) => ({
    type: 'UPDATE_FORM_ELEMENT',
    name,
    isValid,
    value,
    errors,
    label,
});

/**
 * This updates a set of elements at a namespace
 * @param name
 * @param isValid
 * @param elementSet
 * @param errors
 * @param label
 */
export const updateFormElementSet = (
    name,
    isValid,
    elementSet,
    errors,
    label
) => ({
    type: 'UPDATE_FORM_ELEMENT_SET',
    name,
    isValid,
    elementSet,
    errors,
    label,
});

/**
 * Delete/Remove a form element
 * @param name
 */
export const removeFormElement = (name) => ({
    type: 'REMOVE_FORM_ELEMENT',
    name,
});

// OUR REDUCER
export const formReducer = (state = {}, action) => {
    let ns = Object.assign({}, state);
    switch (action.type) {
        case 'SUBMIT_FORM_START':
            if (!ns._meta) {
                ns._meta = {
                    isProcessing: true,
                };
            } else {
                ns._meta.isProcessing = true;
            }
            return ns;

        case 'SUBMIT_FORM_FINISHED':
            ns._meta.isProcessing = false;
            if (!action.data) {
                ns._meta.hasServerError = true;
                return ns;
            }
            ns._meta.hasServerError = false;
            ns = action.data;
            return ns;

        case 'UPDATE_FORM_ELEMENT':
            let ele = constructElement(
                action.value,
                action.label,
                action.valid,
                action.errors
            );
            updateAtRef(action.name, ns, ele, null);
            return ns;

        case 'UPDATE_FORM_ELEMENT_SET':
            let set = constructElementSet(
                action.elementSet,
                action.label,
                action.valid,
                action.errors
            );
            updateAtRef(action.name, ns, set, null);
            return ns;

        case 'REMOVE_FORM_ELEMENT':
            updateAtRef(action.name, ns, false, null);
            return ns;

        default:
            return state;
    }
};

//HELPER FUNCTIONS
let constructElement = (
    value = null,
    label = null,
    isValid = true,
    errors = [],
    children = {}
) => {
    return {
        _value: value,
        _label: label,
        _isValid: isValid,
        _errors: errors,
        _children: children,
    };
};

let constructElementSet = (
    elementSet = {},
    label = null,
    isValid = true,
    errors = []
) => {
    let formedSet = {
        _label: label,
        _isValid: isValid,
        _errors: errors,
        _children: {},
    };
    Object.keys(elementSet).map((key) => {
        let ele = elementSet[key];
        formedSet._children[key] = constructElement(
            ele.value,
            ele.label,
            ele.isValid,
            ele.errors
        );
    });
};

let updateAtRef = (name, state, data, type) => {
    let namePices = name.split(':');
    let piece = namePices.shift();

    if (!state[piece]) {
        state[piece] = {
            _errors: [],
            _children: {},
        };
    } else if (!state[piece]._children) {
        state[piece]._children = {};
    }

    if (namePices.length === 0) {
        if (data === false) {
            delete state[piece];
            return;
        }

        state[piece] = data;
        return;
    }
    updateAtRefPart(
        namePices.shift(),
        namePices,
        state[piece]._children,
        data,
        type
    );
};

let updateAtRefPart = (piece, nextPices, state, data, type) => {
    if (!state[piece]) {
        state[piece] = {
            _errors: [],
            _children: {},
        };
    } else if (!state[piece]._children) {
        state[piece]._children = {};
    }

    if (nextPices.length !== 0) {
        let nextName = nextPices.shift();
        if (state[piece]._children === {}) {
            state[piece]._children = isNaN(nextName) ? {} : [];
        }
        updateAtRefPart(
            nextName,
            nextPices,
            state[piece]._children,
            data,
            type
        );
    } else {
        if (data === false) {
            state[piece] = {};
            return;
        }
        state[piece] = Object.assign(state[piece], data);
        return;
    }
    return;
};

export const reducers = combineReducers({
    form: formReducer,
});

// OUR "STORE"
export const configureStore = (initialState = {}) => {
    const store = createStore(
        reducers,
        initialState,
        applyMiddleware(thunk, logger)
    );
    return store;
};

export const store = configureStore();
