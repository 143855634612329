import * as Sentry from '@sentry/react';
// TODO temporarily disabled
// import { Integrations } from '@sentry/tracing';

import configurations from './configurations';

const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

if (process.env.isSentryFEEnabled && canUseDOM) {
    Sentry.init({
        dsn: process.env.SentryReactDSN,
        environment: process.env.environment,
        // TODO temporarily disabled
        // integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0, // should reduce sample rate to 0 (default, if omitted, is 1)
        // NOTE: hint is the original exception that was thrown, may be useful for filtering/reporting purposes
        // eslint-disable-next-line no-unused-vars
        beforeSend(event, hint) {
            // TODO: add potential filtering here, if desired
            return event;
        },
        ...configurations,
    });

    // TODO - need to modify webpack config
    Sentry.configureScope((scope) => {
        scope.setTag('NODE_ENV', process.env.env);
    });
}
