import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import ExpVariant from './ExpVariant';
import EduVariant from './EduVariant';

import { useFlowControls } from '../../FlowShell/controls';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { getDefaultErrorsForStep } from '../constants';
import { enrichmentUpdateApi } from '../../ProfileEnrichment/constants';

import './Enrichment.scss';

const deriveEnrichmentTypeFromPayload = (payload = {}) => {
    if (!payload.company && !payload.school) return '';
    if (!payload.company) {
        return 'education';
    } else if (!payload.school) {
        return 'experience';
    }

    return '';
};

const getDefaultErrors = (variant) =>
    variant === 'experience'
        ? getDefaultErrorsForStep('singleExperience')
        : getDefaultErrorsForStep('singleEducation');

const AddButton = React.memo((props) => {
    const { destinations, flowData, step } = useFlowControls();
    const { stepFormProps, updateFormValueAtPath } = useGetStepFormProps(step);
    const { validators, setHistoryByType, getCurrentExpEduData } = flowData;

    const variant = deriveEnrichmentTypeFromPayload(stepFormProps.values);
    const isExperience = variant === 'experience';

    const resetErrors = getDefaultErrors(variant);
    const errorSetter = updateFormValueAtPath(`${step}.errors`);

    const clearStepErrors = React.useCallback(() => errorSetter(resetErrors), [
        errorSetter,
        resetErrors,
    ]);

    const validate = isExperience
        ? validators.experience
        : validators.education;

    const handleAdd = React.useCallback(async () => {
        clearStepErrors();

        const {
            currentExperiences,
            currentEducations,
        } = getCurrentExpEduData(); // get latest from page state

        const currentList = isExperience
            ? currentExperiences
            : currentEducations;

        // validate
        const { valid, errors } = await validate(stepFormProps.values);

        if (!valid) {
            return errorSetter(errors);
        }

        // make api request
        const {
            ok,
            newItems,
            apiErrors,
        } = await enrichmentUpdateApi.addEnrichment(
            variant,
            stepFormProps.values,
            currentList
        );

        if (!ok) {
            return; // TODO: handle error
        }

        setHistoryByType(variant)(newItems);

        // go to next step
        return destinations.nextStep();
    }, [
        destinations,
        stepFormProps,
        validate,
        errorSetter,
        clearStepErrors,
        getCurrentExpEduData,
        setHistoryByType,
        variant,
        isExperience,
    ]);

    return (
        <button type="button" className="primary-flow-btn" onClick={handleAdd}>
            Add
        </button>
    );
});

const SkipButton = React.memo((props) => {
    const { destinations, flowData, step } = useFlowControls();
    const { enrichments } = flowData;

    const handleSkip = React.useCallback(async () => {
        const enrichment = enrichments.find((v) => v.stepName === step);

        const exclusionHash = enrichment.exclusionHash;

        // make api request
        const ok = await enrichmentUpdateApi.skipEnrichment(exclusionHash);

        if (!ok) console.log('SKIP ENRICHMENT REQ FAILED'); // still go to next step

        // go to next step
        return destinations.nextStep();
    }, [destinations, enrichments, step]);

    return (
        <button
            type="button"
            className="secondary-flow-btn"
            onClick={handleSkip}
        >
            Skip
        </button>
    );
});

export const Enrichment = ({ stepName, variant }) => {
    const enrichmentVariant =
        variant === 'exp' ? (
            <ExpVariant stepName={stepName} />
        ) : (
            <EduVariant stepName={stepName} />
        );

    return (
        <FlowStep name={stepName}>
            <div className="component-Modals-Flows-Steps-Enrichment">
                {enrichmentVariant}
            </div>
            <div className="flow-nav-box">
                <SkipButton />
                <AddButton />
            </div>
        </FlowStep>
    );
};

Enrichment.propTypes = {
    stepName: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['exp', 'edu']).isRequired,
};

Enrichment.displayName = 'Enrichment';

export default React.memo(Enrichment);
