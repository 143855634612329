import PropTypes from 'prop-types';

export const SINGLE_USER_PROPTYPES = {
    /**
     * username
     */
    username: PropTypes.string,
    /**
     * user's id
     */
    userId: PropTypes.number,
    /**
     * user's image
     */
    image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
    /**
     * user's profile credential (optional)
     */
    credential: PropTypes.string,
};

// new APIs
export const APIS = {
    API_REQUEST_CONNECTION: '/api/network/connection/request',
    API_ACCEPT_INVITATION: '/api/network/connection/accept',
    API_ACCEPT_ALL_INVITATIONS: '/api/network/connection/accept-all',
    API_CANCEL_REQUEST: '/api/network/connection/cancel-request',
    API_REMOVE_CONNECTION: '/api/network/connection/cancel-connection',
    API_REJECT_INVITATION: '/api/network/connection/reject',
    API_FOLLOW_USER: '/api/network/following/follow-user',
    API_UNFOLLOW_USER: '/api/network/following/unfollow-user',
    API_INVITE_VIA_EMAIL: '/api/network/connection/email-invite',
    API_GET_ALL_CONNECTIONS: '/api/network/connection/following-users',
    API_GET_PENDING_CONNECTIONS: '/api/network/connection/pending-connections',
    API_GET_PENDING_INVITATIONS: '/api/network/connection/invitations',
};

export const callToActions = {
    CONNECT: {
        buttonMeta: {
            CONNECT: {
                text: 'Connect',
                cssClass: 'secondary-blue-gray',
                isDisabled: false,
            },
            INVITED: {
                text: 'Accept',
                cssClass: 'secondary-blue-gray',
                isDisabled: false,
            },
            CONNECTED: {
                text: 'Connected',
                cssClass: 'primary-blue-gray',
                isDisabled: true,
            },
            REQUESTED: {
                text: 'Requested',
                cssClass: 'primary-blue-gray',
                isDisabled: true,
            },
        },
        callToActionHash: 'request-to-connect',
        loginTrigger: 'connect-user',
    },
    FOLLOW: {
        buttonMeta: {
            FOLLOW: {
                text: 'Follow',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            FOLLOWING: {
                text: 'Following',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
        callToActionHash: 'follow-user',
        loginTrigger: 'follow-user',
    },
    FOLLOW_ANON: {
        buttonMeta: {
            FOLLOW_ANON: {
                text: 'Follow Anonymously',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            FOLLOWING_ANON: {
                text: 'Following',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    UNFOLLOW: {
        buttonMeta: {
            UNFOLLOW: {
                text: 'Unfollow',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            UNFOLLOWED: {
                text: 'Unfollowed',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    INVITE_TO_GROUP: {
        buttonMeta: {
            type: 'group',
            buttonClass: 'group-invite-button',
            buttonType: 'wired-blue',
            value: 'Invite to group',
            onClick: null,
            callToActionHash: 'invite-to-group',
            loginTrigger: 'invite-to-group',
        },
    },
    INVITE_TO_GROUP_SOLID: {
        buttonMeta: {
            type: 'group',
            cssClass: 'group-invite-button_solid',
            buttonType: 'tertiary-blue-gray',
            value: 'Invite',
            onClick: null,
            callToActionHash: 'invite-to-group',
            loginTrigger: 'invite-to-group',
        },
    },
    MESSAGE: {
        buttonMeta: {
            MESSAGE: {
                text: 'Message',
                cssClass: 'purple-medium-white',
            },
        },
        loginTrigger: 'message-user',
        callToActionHash: 'message-connected',
    },
    REMOVE: {
        buttonMeta: {
            REMOVE: {
                text: 'Remove',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            REMOVED: {
                text: 'Removed',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    ACCEPT: {
        buttonMeta: {
            ACCEPT: {
                text: 'Accept',
                cssClass: 'secondary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            ACCEPTED: {
                text: 'Accepted',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    IGNORE: {
        buttonMeta: {
            IGNORE: {
                text: 'Deny',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            IGNORED: {
                text: 'Denied',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
    CANCEL: {
        buttonMeta: {
            CANCEL: {
                text: 'Cancel',
                cssClass: 'tertiary-blue-gray',
                inputStyle: '',
                isDisabled: false,
            },
            CANCELLED: {
                text: 'Cancelled',
                cssClass: 'tertiary-blue-gray',
                inputStyle: 'btn-italic',
                isDisabled: true,
            },
        },
    },
};
