import React from 'react';

import FlowStep from '../../FlowStep';
import TextInput from '../../../ModalParts/Inputs/TextInput/TextInput';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import LocationSelector from '../../../ModalParts/Inputs/LocationSelector/LocationSelector';
import CheckBox from '../../../../Content/Elements/CheckBox/CheckBox';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import ErrorLabel from '../../../../Elements/ErrorLabel/ErrorLabel';
import InputLabel from '../../../ModalParts/Elements/InputLabel/InputLabel';
import {
    ExperienceDeleteButton,
    ExperienceSubmitButton,
} from '../../SingleExperience/SingleExperience';
import { CompanyTypeahead } from '../../../../Input/CompanyTypeahead/CompanyTypeahead';
import { SINGLE_EXPERIENCE_STEP_NAME } from '../constants';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import {
    yearDropdownOpts,
    filterYearDropdownOpts,
    monthDropdownOpts,
} from '../../../ModalParts/Inputs/Dropdown/constants';

import './SingleExperience.scss';

export const SingleExperience = (props) => {
    const {
        stepFormProps,
        stepErrors,
        updateFormValues,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(SINGLE_EXPERIENCE_STEP_NAME);

    const {
        experienceId,
        title,
        company,
        location,
        isRemote,
        startYear,
        endYear,
        startMonth,
        endMonth,
        isCurrent,
        description,
    } = stepFormProps;

    React.useEffect(() => {
        if (isRemote) {
            const locationUpdater = updateFormValueAtPath(
                'singleExperience.location'
            );
            locationUpdater({ name: '', lat: null, lng: null });
        }

        if (isCurrent) {
            const endDatesUpdater = updateFormValueAtPath('singleExperience');
            endDatesUpdater({ endMonth: '', endYear: '' });
        }
    }, [isRemote, isCurrent, updateFormValueAtPath]);

    const buttonErrorHandler = updateFormValueAtPath('errors.singleExperience');

    return (
        <FlowStep name={SINGLE_EXPERIENCE_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-SingleExperience">
                <div className="single-experience-fields">
                    <div className="field-wrapper">
                        <TextInput
                            inputName="singleExperience.title"
                            value={title}
                            label="Title*"
                            placeholder="Ex. Marketing Director"
                            error={stepErrors.title}
                            handleChange={updateFormValues}
                        />
                    </div>
                    <div className="field-wrapper">
                        <InputLabel
                            text="Company*"
                            type="text"
                            htmlFor="#experience-company-input"
                        />
                        <CompanyTypeahead
                            className="experience-company-typeahead"
                            placeholder="Start typing to select a company or add a new one"
                            items={[company]}
                            handleSelect={updateFormValueAtPath(
                                'singleExperience.company'
                            )}
                            inputId="experience-company-input"
                            addOption
                        />
                        <ErrorLabel
                            error={stepErrors.company.companyName}
                            cssClass="field-error company-input-error"
                        />
                    </div>
                    <div className="field-wrapper">
                        <LocationSelector
                            inputName="singleExperience.location"
                            label="Job location*"
                            placeholder="City, State"
                            error={stepErrors.location.name}
                            value={location.name}
                            handleChange={updateFormValueAtPath}
                            disabled={isRemote}
                        />
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isRemote-toggle"
                            defaultChecked={isRemote}
                            label="Remote"
                            handleChange={updateFormValueAtPath(
                                'singleExperience.isRemote'
                            )}
                            className="remote-select"
                        />
                    </div>
                    <div className="field-wrapper">
                        <LongTextInput
                            inputName="singleExperience.description"
                            value={description}
                            label="Your key accomplishments"
                            type="textarea"
                            error={stepErrors.description}
                            handleChange={updateFormValues}
                            className="experience-highlights"
                            rows={6}
                            maxChars={2000}
                        />
                    </div>
                    <div className="field-wrapper">
                        <InputLabel
                            text="Start date"
                            type="select"
                            htmlFor="#start-date-dropdown-section"
                        />
                        <div
                            className="date-select-wrapper"
                            id="start-date-dropdown-section"
                        >
                            <Dropdown
                                inputName="singleExperience.startMonth"
                                handleChange={updateFormValues}
                                className="dropdown-month"
                                options={monthDropdownOpts}
                                value={startMonth}
                                defaultOptionText="Month"
                                error={stepErrors.startMonth}
                            />
                            <span className="date-dropdown-divider" />
                            <Dropdown
                                inputName="singleExperience.startYear"
                                handleChange={updateFormValues}
                                className="dropdown-year"
                                defaultOptionText="Year"
                                options={yearDropdownOpts}
                                disabled={!startMonth && !startYear}
                                value={startYear}
                                error={stepErrors.startYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper">
                        <InputLabel
                            text="End date"
                            type="select"
                            htmlFor="#end-date-dropdown-section"
                        />
                        <div
                            className="date-select-wrapper"
                            id="end-date-dropdown-section"
                        >
                            <Dropdown
                                inputName="singleExperience.endMonth"
                                handleChange={updateFormValues}
                                className="dropdown-month"
                                options={monthDropdownOpts}
                                value={endMonth}
                                defaultOptionText="Month"
                                disabled={
                                    isCurrent || (!startYear && !endMonth)
                                }
                                error={stepErrors.endMonth}
                            />
                            <span className="date-dropdown-divider" />
                            <Dropdown
                                inputName="singleExperience.endYear"
                                handleChange={updateFormValues}
                                className="dropdown-year"
                                defaultOptionText="Year"
                                options={filterYearDropdownOpts(startYear)}
                                disabled={isCurrent || (!endMonth && !endYear)}
                                value={endYear}
                                error={stepErrors.endYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isCurrent-toggle"
                            defaultChecked={isCurrent}
                            label="I am currently working in this role"
                            handleChange={updateFormValueAtPath(
                                'singleExperience.isCurrent'
                            )}
                            className="current-select"
                        />
                    </div>
                </div>
                <div className="experience-actions-box">
                    <ExperienceDeleteButton
                        experienceId={experienceId}
                        errorHandler={buttonErrorHandler}
                    />
                    <ExperienceSubmitButton
                        formValues={stepFormProps}
                        errorHandler={buttonErrorHandler}
                        clearErrors={clearStepErrors}
                    />
                </div>
            </div>
        </FlowStep>
    );
};

SingleExperience.propTypes = {};

SingleExperience.displayName = 'SingleExperience';

export default React.memo(SingleExperience);
