import React, { useContext } from 'react';
import { PageContext } from '../../../../../contexts/ContextCreator';
import ButtonWithChildren from '../../../../Input/ButtonWithChildren/ButtonWithChildren';
import { buildImagePath } from '../../../../JobComponents/Company/Image/logo';
import FacebookButton from '../../SocialMediaButtons/FacebookButton/FacebookButton';
import GoogleButton from '../../SocialMediaButtons/GoogleButton/GoogleButton';
import './LeadInLogin.scss';

const LeadInLogin = ({ onLogin }) => {
    const { setLeadLoginInPopup } = useContext(PageContext);
    const iconSrc = buildImagePath('/v1541017253/Login/Mail.png', 'button');

    return (
        <>
            <div className="component-LeadInLogin-subheader">
                <b>Sign up to continue reading.</b> <br />
                <b>Fairygodboss</b> is the largest career community for women.
                We help you achieve your career goals by providing community
                advice, connections, jobs, events and exclusive company intel
                from other women.
            </div>
            <div className="component-LeadInLogin-body">
                <FacebookButton onClick={onLogin} />
                <GoogleButton onClick={onLogin} />
                <ButtonWithChildren
                    className="email-button"
                    onClick={() => setLeadLoginInPopup(true)}
                >
                    <div className="button-content-container">
                        <img alt="" src={iconSrc} />
                        <div
                            className="call-to-action-button-text"
                            data-qa="continue"
                        >
                            Continue with email
                        </div>
                    </div>
                </ButtonWithChildren>
            </div>
        </>
    );
};

export default LeadInLogin;
