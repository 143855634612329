import PropTypes from 'prop-types';
import React from 'react';
import {
    CAN_SUBMIT,
    CANT_SUBMIT,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import RatingsCard from '../../QuestionCards/RatingsCard/RatingsCard';

import './JobSatisfactionCard.scss';

export default class JobSatisfactionCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            rating: props.rating || null,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'jobSatisfaction',
    };

    componentDidMount = () => {
        EmitMetric({
            misc_event_type: 'job-review-question-started-company-3',
            misc_event_count: 1,
        });
        const RatingObject = this.props.rating
            ? {
                  rating: this.props.rating,
              }
            : null;
        this.props.rating
            ? this.props.onAnswer(
                  this.props.questionName,
                  RatingObject,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    };

    handleRating = (rating) => {
        let nextAction = CANT_SUBMIT;
        if (rating > 0 && rating <= 5) {
            nextAction = SUBMIT;
        }
        const ratingObject = {
            rating,
        };
        this.props.onAnswer(
            this.props.questionName,
            ratingObject,
            nextAction,
            false,
            this.props.nextStep
        );
    };

    render() {
        const props = {
            ratingType: 'RatingWing',
            handleRating: this.handleRating,
            descriptions: {
                low: 'Very Unsatisfied',
                mid: 'Neutral',
                high: 'Very Satisfied',
            },
            showCheckbox: false,
            rating: this.state.rating,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-JobSatisfactionCard">
                <RatingsCard {...props} />
            </div>
        );
    }
}
