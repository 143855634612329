import PropTypes from 'prop-types';
import React from 'react';

import LeftRailExpandableMenuItem from '../../../Footer/Components/LeftRailExpandableMenuItem/LeftRailExpandableMenuItem';

import './Menu.scss';
import MenuItem from './MenuItem/MenuItem';

export default class Menu extends React.PureComponent {
    renderMenuItem = ({ key, title, url, children, count }, index) => {
        if (children) {
            return (
                <LeftRailExpandableMenuItem
                    key={`item-${index}`}
                    parentItem={title}
                    submenuItems={children}
                />
            );
        }

        if (key === 'employer-messages') {
            return (
                <MenuItem
                    type="star"
                    key={`item-${index}`}
                    url={url}
                    count={count ? '*' : count}
                    title={title}
                />
            );
        }

        return (
            <MenuItem
                key={`item-${index}`}
                url={url}
                count={count}
                title={title}
            />
        );
    };

    render() {
        return (
            <div
                className={`component-Layout-Header-Menu ${this.props.cssClass}`}
            >
                {this.props.menuItems.map(this.renderMenuItem)}
            </div>
        );
    }
}

Menu.propTypes = {
    /**
     * custom class selector
     */
    cssClass: PropTypes.string,
    /**
     * an object of items rendered in menu
     */
    // eslint-disable-next-line react/forbid-prop-types
    menuItems: PropTypes.array.isRequired,
};

Menu.defaultProps = {
    cssClass: '',
};
