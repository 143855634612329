import React from 'react';

const LinkedinIcon = ({ width = '32px', height = '32px' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="1" fill="#3375B0" />
        <path
            d="M6.57185 16H4.19783V7.29526H6.57185V16ZM5.38484 6.29185C4.62041 6.29185 4 5.66669 4 4.89592C4 4.12516 4.62041 3.5 5.38484 3.5C6.14928 3.5 6.76969 4.12516 6.76969 4.89592C6.76969 5.66669 6.15007 6.29185 5.38484 6.29185ZM16.0679 16H13.6939V11.5653C13.6939 8.9001 10.5286 9.10189 10.5286 11.5653V16H8.15453V7.29526H10.5286V8.69198C11.6333 6.64558 16.0679 6.49443 16.0679 10.6513V16Z"
            fill="white"
        />
    </svg>
);

export default LinkedinIcon;
