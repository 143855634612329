import React from 'react';

import { Counter } from '../../Modules/Counter/Counter';
import IconTitleItemLayout from '../../Layout/IconTitleItemLayout/IconTitleItemLayout';
import UserDropdown from '../../Modules/Dropdowns/UserDropdown/UserDropdown';
import VerticalLine from '../../Modules/NavbarItems/VerticalLine/VerticalLine';
import WideFgbLogo from '../../Modules/NavbarItems/WideFgbLogo/WideFgbLogo';
import Hamburger from '../../Modules/NavbarItems/Hamburger/Hamburger';

export default [
    {
        metaData: {
            key: 'hamburger',
            title: null,
            url: null,
            imgSrc: '576004112/navbar/hamburger-w.svg',
            metricEvent: 'mobile-hamburger-click',
            position: 'top',
            className: 'hamburger',
        },
        Component: ({ metaData, setActionState, handleMetricEvent }) => (
            <Hamburger
                metaData={metaData}
                setActionState={setActionState}
                handleMetricEvent={handleMetricEvent}
            />
        ),
    },

    {
        metaData: {
            key: 'wide-fgb-logo',
            title: null,
            url: '/community/feed',
            imgSrc: '576004259/navbar/wide_logo.svg',
            metricEvent: null,
            position: 'top',
            className: '',
        },
        Component: ({ metaData, featureFlags }) => {
            return (
                <WideFgbLogo
                    {...metaData}
                    url={'/employers/dashboard'}
                    showEmployerTag={true}
                />
            );
        },
    },

    {
        metaData: {
            key: 'employer-portal-tag',
            title: 'EMPLOYER PORTAL',
            url: null,
            imgSrc: null,
            metricEvent: null,
            position: 'top',
            className: 'employer-portal-tag',
        },
        Component: ({ metaData, handleMetricEvent }) => (
            <IconTitleItemLayout
                itemKey={metaData.key}
                className={metaData.className}
                url={metaData.url}
                title={metaData.title}
                imgSrc={metaData.imgSrc}
                handleClick={handleMetricEvent(metaData.metricEvent)}
            />
        ),
    },

    {
        metaData: {
            key: 'alert-left',
            title: null,
            url: '/employers/notifications',
            imgSrc: '576251212/navbar/notification-w.svg',
            metricEvent: 'top-nav-employer-alerts-click',
            position: 'top',
            className: 'alert-left',
        },
        Component: ({ metaData, notifications, handleMetricEvent }) => {
            return (
                <IconTitleItemLayout
                    itemKey={metaData.key}
                    className={metaData.className}
                    url={metaData.url}
                    title={metaData.title}
                    imgSrc={metaData.imgSrc}
                    handleClick={handleMetricEvent(metaData.metricEvent)}
                >
                    {!!notifications.unseenCount && (
                        <Counter
                            cssClass="header-badges header-badges--alert-badge header-badges--alert-left"
                            countNumber={notifications.unseenCount}
                        />
                    )}
                </IconTitleItemLayout>
            );
        },
    },

    {
        metaData: {
            key: 'for-users-left',
            title: 'For Users',
            imgSrc: '575995136/navbar/employer-w.svg',
            url: '/community/feed',
            metricEvent: 'top-nav-for-users-click',
            position: 'bottom',
            className: 'for-users-left',
        },
        Component: ({ metaData, handleMetricEvent }) => (
            <IconTitleItemLayout
                itemKey={metaData.key}
                className={metaData.className}
                url={metaData.url}
                title={metaData.title}
                imgSrc={metaData.imgSrc}
                handleClick={handleMetricEvent(metaData.metricEvent)}
            />
        ),
    },

    {
        metaData: {
            key: 'vertical-line-divider-left',
            title: null,
            imgSrc: null,
            url: null,
            metricEvent: null,
            position: 'bottom',
            className: 'vertical-line-divider vertical-line-divider--left',
        },
        Component: ({ metaData }) => (
            <VerticalLine className={metaData.className} />
        ),
    },

    {
        metaData: {
            key: 'dashboard',
            title: 'Dashboard',
            imgSrc: '575995039/navbar/dashboard.svg',
            url: '/employers/database',
            metricEvent: 'top-nav-employer-dashboard-click',
            position: 'bottom',
            className: 'dashboard',
        },
        Component: ({ metaData, handleMetricEvent, featureFlags }) => {
            return (
                <IconTitleItemLayout
                    itemKey={metaData.key}
                    className={metaData.className}
                    url={'/employers/dashboard'}
                    title={metaData.title}
                    imgSrc={metaData.imgSrc}
                    handleClick={handleMetricEvent(metaData.metricEvent)}
                    featureFlags={featureFlags}
                />
            );
        },
    },

    {
        metaData: {
            key: 'alert-right',
            title: 'Alerts',
            url: '/employers/notifications',
            imgSrc: '576251212/navbar/notification-w.svg',
            metricEvent: 'top-nav-employer-alerts-click',
            position: 'bottom',
            className: 'alert-right',
        },
        Component: ({ metaData, notifications, handleMetricEvent }) => {
            return (
                <IconTitleItemLayout
                    itemKey={metaData.key}
                    className={metaData.className}
                    url={metaData.url}
                    title={metaData.title}
                    imgSrc={metaData.imgSrc}
                    handleClick={handleMetricEvent(metaData.metricEvent)}
                >
                    {!!notifications.unseenCount && (
                        <Counter
                            cssClass="header-badges header-badges--alert-badge"
                            countNumber={notifications.unseenCount}
                        />
                    )}
                </IconTitleItemLayout>
            );
        },
    },

    {
        metaData: {
            key: 'user-auth',
            title: 'Me',
            imgSrc: '/v1/avatar_black_160_vtg7lw.svg',
            url: null,
            metricEvent: 'top-nav-me-click',
            position: 'bottom',
            className: 'user-auth',
        },
        Component: ({
            metaData,
            setActionState,
            getActionState,
            handleMetricEvent,
            viewWidth,
            userDropdownProps,
            userImgSrc,
            featureFlags,
            hasEmployerMessageInUserMenu,
            badgeCountData,
        }) => {
            const handleClick = () => {
                const actionState =
                    viewWidth > 992 ? 'isUserDropdownOpen' : 'isUserDrawerOpen';
                return setActionState(
                    actionState,
                    !getActionState(actionState),
                    getActionState(actionState) === false
                        ? handleMetricEvent(metaData.metricEvent)
                        : () => {}
                );
            };
            const { unreadMessageCount } = badgeCountData;
            const displayCounter =
                !!unreadMessageCount && hasEmployerMessageInUserMenu;

            return (
                <IconTitleItemLayout
                    itemKey={metaData.key}
                    className={metaData.className}
                    url={metaData.url}
                    title={metaData.title}
                    imgSrc={userImgSrc || metaData.imgSrc}
                    handleClick={handleClick}
                >
                    {displayCounter && (
                        <Counter
                            cssClass="header-badges header-badges--message"
                            countNumber="*"
                            type="star"
                        />
                    )}
                    <UserDropdown {...userDropdownProps} />
                </IconTitleItemLayout>
            );
        },
    },

    {
        metaData: {
            key: 'vertical-line-divider',
            title: null,
            imgSrc: null,
            url: null,
            metricEvent: null,
            position: 'bottom',
            className: 'vertical-line-divider',
        },
        Component: ({ metaData, featureFlags }) => (
            <VerticalLine className={metaData.className} />
        ),
    },

    {
        metaData: {
            key: 'for-users-right',
            title: 'For Users',
            imgSrc: '575995136/navbar/employer-w.svg',
            url: '/community/feed',
            metricEvent: 'top-nav-for-users-click',
            position: 'bottom',
            className: 'for-users-right',
        },
        Component: ({ metaData, handleMetricEvent }) => (
            <IconTitleItemLayout
                itemKey={metaData.key}
                className={metaData.className}
                url={metaData.url}
                title={metaData.title}
                imgSrc={metaData.imgSrc}
                handleClick={handleMetricEvent(metaData.metricEvent)}
            />
        ),
    },
];
