import React from 'react';
import FlowStep from '../../FlowStep';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import { useFlowControls } from '../../FlowShell/controls';
// eslint-disable-next-line import/no-cycle
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import SVGFromCloudinary from '../../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import TextInput from '../../../ModalParts/Inputs/TextInput/TextInput';
import ResumeUploadButton from '../../../../User/JobProfile/ResumeUpload/ResumeUpload';
import { EVENT_REQUIREMENTS_INPUT_STEP_NAME } from '../constants';
import {
    boolDropdownOptions,
    yearsOfExperienceOptions,
    emailLabel,
    workAuthLabel,
    yearsExpLabel,
    sponsorshipLabel,
    studentLabel,
    buildRegistrationPayload,
    firstNameLabel,
    lastNameLabel,
} from './constants';

// eslint-disable-next-line import/no-cycle
import {
    registrationFlow,
    checkHasRequirements,
} from '../../EventRegistration/constants';
import FieldError from '../../../ModalParts/Elements/FieldError/FieldError';
import { buildFileDataUrl } from '../../../../../utilities/helpers/form';
import { eventStatusTypes } from '../../../../Includes/Constants/events';
import { getEventStatus } from '../../../../Events/helperFunctions';

import './EventRequirementsInput.scss';

const { registrationFlowAPI } = registrationFlow;

const EventRegisterButton = () => {
    // TODO: validate inputs, handle api calls, determine next step, go to step
    const { destinations, flowData } = useFlowControls();
    const { validators, event, setUserAsRegistered } = flowData;
    const { startTime, endTime, currentTime, eventType } = event;

    const eventStatus = getEventStatus(
        startTime,
        endTime,
        currentTime,
        eventType
    );

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(EVENT_REQUIREMENTS_INPUT_STEP_NAME);

    const errorHandler = updateFormValueAtPath('errors.eventRequirementsInput');

    const eventRequirements = event.vreAdditionalInfo;
    eventRequirements.sourceType = event.sourceType;
    const handleRegisterClick = async () => {
        clearStepErrors(EVENT_REQUIREMENTS_INPUT_STEP_NAME);
        // validate data
        const payload = buildRegistrationPayload(
            eventRequirements,
            formResponses
        );

        const { valid, errors } = await validators.eventRequirementsInput({
            ...payload.registrationResponses,
            event: eventRequirements,
        });

        if (!valid) {
            return errorHandler(errors);
        }

        // submit api request
        const { ok, data } = await registrationFlowAPI.submitEventRegistration({
            eventHash: event.hash,
            registerPayload: payload,
        });

        if (ok) {
            const { eventPreferredTimes } = data;

            if (eventPreferredTimes && eventPreferredTimes.length > 0) {
                return destinations.selectTime({
                    preferredTimeOptions: eventPreferredTimes,
                });
            }

            setUserAsRegistered();
            if (
                window &&
                (eventStatus === eventStatusTypes.IN_PROGRESS ||
                    eventStatus === eventStatusTypes.STARTING_SOON)
            ) {
                window.location.reload();
                return;
            }
            return destinations.confirmSuccess();
        }

        // TODO: handle errors
    };

    return (
        <button
            type="button"
            className="event-register-button"
            onClick={handleRegisterClick}
        >
            Register
        </button>
    );
};

const EligibilityNote = React.memo(({ noteText }) => {
    if (!noteText || noteText.length === 0) return null;
    return (
        <div className="event-eligibility-note">
            <SVGFromCloudinary
                cloudinaryID="v1590552787/icons/eligibility-note-icon.svg"
                className="event-req-icon note-icon"
                alt="eligibility-note-icon"
            />
            <p className="note-text">{noteText}</p>
        </div>
    );
});

const handleResumeUpload = (updaterFn) => async (file) => {
    const dataUrl = await buildFileDataUrl(file);

    const updatePayload = {
        resumeFileName: file.name,
        resumeUrl: dataUrl,
    };

    return updaterFn(updatePayload);
};

const RequirementResumeComponent = React.memo(() => {
    const {
        stepFormProps,
        stepErrors,
        updateFormValueAtPath,
    } = useGetStepFormProps(EVENT_REQUIREMENTS_INPUT_STEP_NAME);
    const { resumeFileName } = stepFormProps;

    const resumeUpdater = updateFormValueAtPath('eventRequirementsInput');
    const uploadHandler = handleResumeUpload(resumeUpdater);

    const content =
        !resumeFileName || resumeFileName.length === 0 ? (
            <div className="resume-input-section">
                <ResumeUploadButton
                    fileName={resumeFileName}
                    onResumeUpload={uploadHandler}
                    location="modal"
                    showButtonsOnly
                />
            </div>
        ) : (
            <div className="resume-input-section">
                <div className="resume-filename-display">
                    <SVGFromCloudinary
                        cloudinaryID="v1590554263/icons/resume-icon.svg"
                        className="event-req-icon resume-icon"
                        alt="resume-display-icon"
                    />
                    <span className="resume-filename-text">
                        {resumeFileName}
                    </span>
                </div>
                <ResumeUploadButton
                    fileName={resumeFileName}
                    onResumeUpload={uploadHandler}
                    location="modal"
                    showButtonsOnly
                />
            </div>
        );
    return (
        <div className="event-resume-input">
            <h6 className="resume-input-label">
                Your resume is required for this event.
            </h6>
            {content}
            <FieldError text={stepErrors.resumeFileName} />
        </div>
    );
});

const getVreFields = (
    requirements,
    stepProps,
    updateFormValues,
    updateFormValueAtPath,
    errors
) => {
    const fields = [];

    if (requirements.eligibilityNote)
        fields.push(
            <EligibilityNote
                key="elig-note"
                noteText={requirements.eligibilityNote}
            />
        );

    if (requirements.requireResume)
        fields.push(<RequirementResumeComponent key="resume-upload" />);
    if (requirements.requireName)
        fields.push(
            <TextInput
                key="firstName-input"
                inputName="eventRequirementsInput.firstName"
                value={stepProps.firstName}
                label={firstNameLabel}
                maxChars={50}
                error={errors.firstName}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireName)
        fields.push(
            <TextInput
                key="lastName-input"
                inputName="eventRequirementsInput.lastName"
                value={stepProps.lastName}
                label={lastNameLabel}
                maxChars={50}
                error={errors.lastName}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireEmail)
        fields.push(
            <TextInput
                key="email-input"
                inputName="eventRequirementsInput.email"
                value={stepProps.email}
                label={emailLabel}
                error={errors.email}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireWorkAuthQuestion)
        fields.push(
            <Dropdown
                key="work-auth"
                inputName="eventRequirementsInput.isAuthorizedToWork"
                className="event-reg-dropdown work-auth"
                options={boolDropdownOptions}
                value={stepProps.isAuthorizedToWork}
                label={workAuthLabel}
                error={errors.isAuthorizedToWork}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireSponsorQuestion)
        fields.push(
            <Dropdown
                key="sponsorship-q"
                inputName="eventRequirementsInput.isRequiredSponsorship"
                className="event-reg-dropdown sponsorship"
                options={boolDropdownOptions}
                value={stepProps.isRequiredSponsorship}
                label={sponsorshipLabel}
                error={errors.isRequiredSponsorship}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireStudentQuestion)
        fields.push(
            <Dropdown
                key="student-q"
                inputName="eventRequirementsInput.isCurrentStudent"
                className="event-reg-dropdown student"
                options={boolDropdownOptions}
                value={stepProps.isCurrentStudent}
                label={studentLabel}
                error={errors.isCurrentStudent}
                handleChange={updateFormValues}
            />
        );
    if (requirements.requireYearExpDropdown)
        fields.push(
            <Dropdown
                key="experience-years-q"
                inputName="eventRequirementsInput.yearsOfExperience"
                className="event-reg-dropdown student"
                options={yearsOfExperienceOptions}
                value={stepProps.yearsOfExperience}
                label={yearsExpLabel}
                error={errors.yearsOfExperience}
                handleChange={updateFormValues}
            />
        );
    return fields;
};

const defaultAdditionalInfo = { eligibilityNote: null };

export const EventRequirementsInput = () => {
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        stepErrors,
    } = useGetStepFormProps(EVENT_REQUIREMENTS_INPUT_STEP_NAME);
    const { flowData } = useFlowControls();

    const { event = {} } = flowData;
    const { vreAdditionalInfo } = event;

    const requiredFields = vreAdditionalInfo || defaultAdditionalInfo;

    const { eligibilityNote, ...restRequirements } = requiredFields;

    const onlyHasEligibilityNote =
        eligibilityNote && !checkHasRequirements(restRequirements);

    const vreFields = getVreFields(
        requiredFields,
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        stepErrors
    );

    const stepLayout = onlyHasEligibilityNote ? (
        <div className="minimal-content-step">
            <EligibilityNote noteText={eligibilityNote} />
            <EventRegisterButton />
        </div>
    ) : (
        <div className="default-content-step">
            {vreFields}
            <EventRegisterButton />
        </div>
    );

    return (
        <FlowStep name={EVENT_REQUIREMENTS_INPUT_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-EventRequirementsInput">
                <div className="step-title">Event requirements:</div>
                {stepLayout}
            </div>
        </FlowStep>
    );
};

EventRequirementsInput.propTypes = {};

EventRequirementsInput.displayName = 'EventRequirementsInput';

export default React.memo(EventRequirementsInput);
