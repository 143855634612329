import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../../Elements/InputLabel/InputLabel';
import FieldError from '../../Elements/FieldError/FieldError';
import InputField from '../../../../Input/InputField/InputField';
import { constructCn } from '../../../../../utilities/helperFunctions';

import './TextInput.scss';

export const TextInput = ({
    inputName,
    label,
    placeholder,
    error,
    value,
    handleChange,
    className,
    maxChars,
}) => {
    const idName = `#modal-input-${inputName.split('.').slice(-1)[0]}`;
    const inputCn = constructCn(
        'component-Modals-ModalParts-TextInput',
        error && 'input-error'
    );
    return (
        <div className={inputCn}>
            <InputLabel
                text={label}
                type="text"
                htmlFor={idName}
                charCount={value.length}
                maxChars={maxChars}
            />
            <InputField
                name={inputName}
                placeholder={placeholder}
                idValue={idName}
                type="text"
                handleRawChangeEvent={handleChange}
                inputValue={value}
                classname={className}
                autoComplete="off"
            />
            <FieldError text={error} />
        </div>
    );
};

TextInput.propTypes = {
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    maxChars: PropTypes.number,
};

TextInput.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    error: '',
    className: 'modal-text-input',
    maxChars: null,
};

TextInput.displayName = 'TextInput';

export default React.memo(TextInput);
