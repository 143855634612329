import React from 'react';

const CopyLinkBlue = ({ width = '32px', height = '32px' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        style={{
            fill: 'rgb(255, 255, 255)',
            width,
            height,
        }}
    >
        <path d="M23.476 20.663c0-.324-.114-.6-.34-.825l-2.524-2.524a1.124 1.124 0 00-.826-.34c-.34 0-.63.13-.873.388.024.024.1.1.23.225s.217.212.26.26c.046.05.106.126.183.23a.976.976 0 01.2.644c0 .325-.113.6-.34.827-.226.226-.5.34-.825.34-.12 0-.23-.015-.332-.043a.976.976 0 01-.31-.158 2.89 2.89 0 01-.23-.182 7.506 7.506 0 01-.26-.26l-.226-.23c-.267.25-.4.545-.4.885 0 .322.113.597.34.824l2.5 2.512c.218.218.493.328.825.328.323 0 .598-.106.825-.316l1.784-1.772a1.11 1.11 0 00.34-.813zm-8.532-8.556c0-.323-.113-.598-.34-.825l-2.5-2.512a1.124 1.124 0 00-.825-.34c-.316 0-.59.11-.826.328L8.67 10.53a1.11 1.11 0 00-.34.813c0 .323.113.598.34.825l2.524 2.524c.22.22.494.328.825.328.34 0 .63-.126.873-.376-.024-.025-.1-.1-.23-.225a7.506 7.506 0 01-.26-.262 2.89 2.89 0 01-.183-.23.976.976 0 01-.2-.644c0-.323.113-.598.34-.825.226-.227.5-.34.824-.34a.976.976 0 01.643.2c.106.077.183.137.23.182.05.044.137.13.262.26s.2.207.224.23c.267-.25.4-.545.4-.885zm10.862 8.556c0 .97-.344 1.792-1.032 2.464L22.99 24.9c-.67.67-1.492 1.006-2.463 1.006-.98 0-1.805-.344-2.476-1.032l-2.5-2.512c-.67-.67-1.006-1.493-1.006-2.463 0-.997.356-1.842 1.068-2.538l-1.068-1.068c-.696.712-1.538 1.068-2.525 1.068-.97 0-1.797-.34-2.476-1.02L7.02 13.82C6.34 13.138 6 12.314 6 11.343c0-.97.344-1.792 1.032-2.464l1.784-1.773c.67-.67 1.492-1.007 2.463-1.007.978 0 1.803.344 2.475 1.032l2.5 2.512c.67.67 1.007 1.492 1.007 2.463 0 .995-.356 1.84-1.068 2.537l1.068 1.068c.696-.712 1.537-1.068 2.524-1.068.97 0 1.797.34 2.476 1.02l2.524 2.523c.68.68 1.02 1.505 1.02 2.476z" />
    </svg>
);

export default CopyLinkBlue;
