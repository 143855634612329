import {
    eventStatusTypes,
    monthNames,
    userEventStatusTypes,
    registrationSourceTypes,
} from '../Includes/Constants/events';
import { isEmpty } from '../../utilities/helperFunctions';
import { countDown as newCountDown } from './EventLandingPage/components/helpers';

/**
 * @param {string} currentTime the time that use to generate current month and year
 * @param {number} numToGenerate how many consecutive months to generate
 * @param {boolean} futureMonths whether to generate consecutive months toward future or past
 * @param {boolean} orderByRecent whether to order generated months by most recent or the reverse
 *
 * example input: ({numToGenerate : 6, futureMonths : true, orderByRecent : true })
 * example output: [
 *    {
 *      text: "June 2019",
 *      queryParams: [{ queryName: "month", queryValue: 6 }, { queryName: "year", queryValue: 2019 }]
 *    },
 *    ... July, Aug, Sept, Oct,
 *    {
 *      text: "November 2019",
 *      queryParams: [{ queryName: "month", queryValue: 11 }, { queryName: "year", queryValue: 2019 }]
 *    }
 *  ]
 */

export const getConsecutiveMonthParams = ({
    currentTime = new Date(),
    numToGenerate = 6,
    futureMonths = true,
    orderByRecent = true,
}) => {
    if (numToGenerate < 1 || numToGenerate > 12) {
        return [];
    }
    const currentMonth = currentTime.getMonth() + 1;
    const currentYear = currentTime.getFullYear();
    const consecutiveMonths = [];
    if (futureMonths) {
        for (
            let monthIndex = currentMonth;
            monthIndex < currentMonth + numToGenerate;
            monthIndex += 1
        ) {
            // add 1 year if monthIndex exceed 12
            const year = monthIndex > 12 ? currentYear + 1 : currentYear;
            // modulus of 12 is 0, therefore set month to "12" if monthIndex is 12
            const month = monthIndex === 12 ? 12 : monthIndex % 12;
            consecutiveMonths.push({
                text: `${monthNames[month - 1]} ${year}`,
                queryParams: [
                    {
                        queryName: 'month',
                        queryValue: month,
                    },
                    {
                        queryName: 'year',
                        queryValue: year,
                    },
                ],
            });
        }
    } else {
        for (
            let monthIndex = currentMonth;
            monthIndex > currentMonth - numToGenerate;
            monthIndex -= 1
        ) {
            const year = monthIndex < 1 ? currentYear - 1 : currentYear;
            let month;
            // when monthIndex is less than 1, add 12 to the monthIndex in order to calculate modulus 12 of monthIndex
            if (monthIndex < 1) {
                month = monthIndex === 0 ? 12 : (monthIndex + 12) % 12;
            } else {
                month = monthIndex === 12 ? 12 : monthIndex % 12;
            }
            consecutiveMonths.push({
                text: `${monthNames[month - 1]} ${year}`,
                queryParams: [
                    {
                        queryName: 'month',
                        queryValue: month,
                    },
                    {
                        queryName: 'year',
                        queryValue: year,
                    },
                ],
            });
        }
    }

    return orderByRecent ? consecutiveMonths : consecutiveMonths.reverse();
};

export const getEventStatus = (
    startISOString,
    endISOString,
    currentTime = null,
    eventType = null
) => {
    const countdownObject = newCountDown(startISOString);
    let days;
    let hours;
    let minutes;

    if (countdownObject) {
        days = countdownObject.days;
        hours = countdownObject.hours;
        minutes = countdownObject.minutes;
    }

    const currentUnixTime = currentTime
        ? Math.round(new Date(currentTime).getTime() / 1000)
        : Math.round(new Date().getTime() / 1000);
    const startUnixTime = Math.round(new Date(startISOString).getTime() / 1000);
    const endUnixTime = Math.round(new Date(endISOString).getTime() / 1000);
    let Status = eventStatusTypes.NOT_STARTED;
    if (
        eventType === 'Webinar' &&
        !days &&
        !hours &&
        typeof minutes === 'number'
    ) {
        Status = eventStatusTypes.STARTING_SOON;
    } else if (
        currentUnixTime >= startUnixTime &&
        currentUnixTime <= endUnixTime
    ) {
        Status = eventStatusTypes.IN_PROGRESS;
    } else if (currentUnixTime > endUnixTime) {
        Status = eventStatusTypes.EVENT_OVER;
    }
    return Status;
};

export const startingSoon = (startISOString, currentTime = null) => {
    const currentUnixTime = currentTime
        ? Math.round(new Date(currentTime).getTime() / 1000)
        : Math.round(new Date().getTime() / 1000);
    const startUnixTime = Math.round(new Date(startISOString).getTime() / 1000);
    const tenMinBeforeTime = startUnixTime - 600;
    return (
        currentUnixTime > tenMinBeforeTime && currentUnixTime < startUnixTime
    );
};

/**
 * @param {ISO String} currentTime
 * @param {ISO String} eventStartTime
 * @param {ISO String} registrationDeadline - if undefined, defaults to eventStartTime
 * @returns {Boolean} - whether or not registration deadline has passed
 */
export const isEventRegistrationClosed = (
    currentTime = null,
    eventStartTime,
    registrationDeadline
) => {
    if (!registrationDeadline) {
        return false;
    }
    const timeNow = currentTime ? new Date(currentTime) : new Date();

    return timeNow >= new Date(registrationDeadline || eventStartTime);
};

export const countDown = (startISOString, currentTimeISOstring = null) => {
    const eventStartTime = new Date(startISOString).getTime();
    const currentTime = currentTimeISOstring
        ? new Date(currentTimeISOstring).getTime()
        : new Date().getTime();
    const distance = eventStartTime - currentTime;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let countdownDisplay = `${days} days, ${hours} hours, ${minutes} minutes `;
    if (distance < 0 || (days === 0 && hours === 0 && minutes === 0)) {
        countdownDisplay = '';
    }

    return countdownDisplay;
};

export const getUserEventStatus = (
    event,
    currentTime,
    isUserRegistered,
    isLiveEvent
) => {
    const {
        startTime,
        endTime,
        registrationDeadline,
        registrationDeactivated,
        eventType,
    } = event;

    const eventStatus = getEventStatus(
        startTime,
        endTime,
        currentTime,
        eventType
    );

    if (eventStatus === eventStatusTypes.EVENT_OVER) {
        return userEventStatusTypes.EVENT_OVER;
    }

    if (registrationDeactivated) {
        return userEventStatusTypes.DEACTIVE;
    }

    if (
        isEventRegistrationClosed(currentTime, startTime, registrationDeadline)
    ) {
        if (!isUserRegistered) {
            return userEventStatusTypes.REGISTRATION_CLOSED;
        }

        if (eventStatus === eventStatusTypes.IN_PROGRESS) {
            return userEventStatusTypes.REGISTERED_IN_PROGRESS;
        }

        return userEventStatusTypes.USER_REGISTERED_EVENT_OPEN;
    }

    if (isUserRegistered) {
        if (eventStatus === eventStatusTypes.STARTING_SOON) {
            return userEventStatusTypes.REGISTERED_STARTING_SOON;
        }
        return registrationDeadline
            ? userEventStatusTypes.USER_REGISTERED
            : userEventStatusTypes.USER_REGISTERED_EVENT_OPEN;
    }

    if (!isUserRegistered && eventStatus === eventStatusTypes.STARTING_SOON) {
        return userEventStatusTypes.NOT_REGISTERED_STARTING_SOON;
    }

    if (!isUserRegistered && isLiveEvent) {
        return userEventStatusTypes.NOT_REGISTERED_INPROGRESS;
    }

    return userEventStatusTypes.USER_NOT_REGISTERED;
};

export const checkShouldHideButton = ({
    event,
    sourceType,
    registrationUrl,
    userEventStatus,
}) =>
    isEmpty(event) ||
    !registrationUrl ||
    userEventStatus === userEventStatusTypes.DEACTIVE ||
    (sourceType !== registrationSourceTypes.VFAIRS && !registrationUrl) ||
    (sourceType !== registrationSourceTypes.HOPIN && !registrationUrl);
