import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../Input/Button/Button';
import Close from '../../Icons/Close';
import SVGIconWrapper from '../../Icons/SVGIconWrapper/SVGIconWrapper';
import './PopUp.scss';

export default class PopUp extends React.PureComponent {
    render() {
        if (!this.props.showPopUp) return null;

        const positionClass = this.props.absolutePosition
            ? 'fixed'
            : 'relative';

        const style = {
            minHeight: this.props.minHeight,
            minWidth: this.props.minWidth,
            maxHeight: this.props.maxHeight,
            maxWidth: this.props.maxWidth,
        };

        const onClickOutside = () => {
            if (this.props.clickOutside) {
                this.props.cancel();
            }
        };

        return (
            <div className="component-Elements-PopUp">
                <div
                    style={style}
                    className={`text-center ${positionClass} whiteSpace ${this.props.className}`}
                    data-qa="popup"
                >
                    {this.props.cancel && !this.props.hideCloseButton && (
                        <div className="close">
                            <SVGIconWrapper
                                handleClickSVGWrapper={this.props.cancel}
                                SVGWrapperCssClass="component-Modals-ModalParts-CloseModalIcon"
                            >
                                {Close}
                            </SVGIconWrapper>
                        </div>
                    )}

                    {this.props.title ? <h4>{this.props.title}</h4> : null}

                    <div className="allContent">
                        {this.props.showLogo ? (
                            <div className="msg-panel">
                                <div className={this.props.logoType} />
                            </div>
                        ) : null}

                        <div className="content popupChildren">
                            {this.props.children}
                        </div>

                        {this.props.buttonText ? (
                            <div className="button-div">
                                <Button
                                    className="popup-button test"
                                    onClick={this.props.close}
                                    type="solid"
                                    value={this.props.buttonText}
                                    disabled={!this.props.close}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>

                {this.props.showPopUp && this.props.showOverlay && (
                    <div
                        className="overlay"
                        onClick={onClickOutside}
                        onKeyPress={onClickOutside}
                        role="button"
                        tabIndex={0}
                        aria-label="overlay"
                    />
                )}
            </div>
        );
    }
}

PopUp.propTypes = {
    /**
     * whether to show popup
     */
    showPopUp: PropTypes.bool.isRequired,
    /**
     * PopUp Title, if null, don't render title
     */
    title: PropTypes.string,
    /**
     * text to be displayed on button, if null, don't render button
     */
    buttonText: PropTypes.string,
    /**
     * child node to be render in the body
     */
    children: PropTypes.node,
    /**
     * custom width
     */
    minWidth: PropTypes.string,
    /**
     * custom height
     */
    minHeight: PropTypes.string,
    /**
     * custom width
     */
    maxWidth: PropTypes.string,
    /**
     * custom height
     */
    maxHeight: PropTypes.string,
    /**
     * absolute position
     */
    absolutePosition: PropTypes.bool,
    /**
     * close function
     */
    close: PropTypes.func,
    /**
     * actual function to close the popup
     */
    cancel: PropTypes.func,
    /**
     * whether to display the bubble logo
     */
    showLogo: PropTypes.bool,
    /**
     * logo type
     */
    logoType: PropTypes.oneOf(['fgb-bubble-logo', 'fgb-logo']),
    /**
     * whether to have overlay behind popup
     */
    showOverlay: PropTypes.bool,
    className: PropTypes.string,
    /**
     * whether want to close the popup on clicking outside of popup
     */
    clickOutside: PropTypes.bool,
    /**
     * whether want to show the X on the top right of popup
     */
    hideCloseButton: PropTypes.bool,
};

PopUp.defaultProps = {
    title: '',
    buttonText: '',
    children: <span />,
    minWidth: '',
    minHeight: '',
    absolutePosition: true,
    showLogo: true,
    close: () => {},
    cancel: () => {},
    logoType: 'fgb-bubble-logo',
    showOverlay: false,
    clickOutside: true,
    maxWidth: '',
    maxHeight: '',
    className: '',
    hideCloseButton: false,
};
