import PropTypes from 'prop-types';
import React from 'react';

import './Logo.scss';

export default class Logo extends React.PureComponent {
    static propTypes = {
        logoStyle: PropTypes.oneOf([
            'default',
            'white-logo',
            'white-logo-large',
            'purple-white-logo',
            'default-mobile',
            'white-logo-xlarge',
        ]),
    };

    static defaultProps = {
        logoStyle: 'default',
    };

    render() {
        return (
            <div className={`component-Layout-Logo ${this.props.logoStyle}`}>
                <div className={`${this.props.logoStyle}`} />
            </div>
        );
    }
}
