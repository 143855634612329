import axios from 'axios';
import React from 'react';
import { PageContext } from '../../../../contexts/ContextCreator';
import { isEmpty } from '../../../../utilities/helperFunctions';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import { groupBatchEmailInviteSchema } from '../../../Includes/FormSchema/ContentSchema';
import { ValidateForm } from '../../../Includes/FormValidation';
import { INVITE_GROUP_VIA_EMAIL } from '../../../InvitationWidget/InvitationWidget';
import ThreeRowModalLayout from '../../../Modals/Layout/ThreeRowModalLayout/ThreeRowModalLayout';
import stepDefinitions from '../Steps/stepDefinitions';

import './AdminOnboardingModal.scss';
import AdminOnboardingModalBottom from './AdminOnboardingModalBottom/AdminOnboardingModalBottom';
import AdminOnboardingModalContent from './AdminOnboardingModalContent/AdminOnboardingModalContent';
import AdminOnboardingModalTop from './AdminOnboardingModalTop/AdminOnboardingModalTop';

export default class AdminOnboardingModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedStepIndex: props.selectedStepIndex || 0,
            isLoading: false,
            apiError: {},
            inviteEmails: null,
            message: null,
        };
    }

    static contextType = PageContext;

    setRef = (node) => {
        this.adminModalContent = node;
    };

    setIsLoading = (isLoading = false) => {
        this.setState({ isLoading });
    };

    increaseSelectedStep = (increaseNumber = 1) => {
        this.setState({
            selectedStepIndex: this.state.selectedStepIndex + increaseNumber,
        });
    };

    decreaseSelectedStep = () => {
        this.setState({ selectedStepIndex: this.state.selectedStepIndex - 1 });
    };

    handleStart = () => {
        this.increaseSelectedStep();
        EmitMetric({
            misc_event_type: `admin-onboarding-step-0-start-click`,
            misc_event_count: 1,
        });
    };

    handleFinish = () => {
        const { closeModal } = this.context;
        closeModal();
        EmitMetric({
            misc_event_type: `admin-onboarding-step-5-done-click`,
            misc_event_count: 1,
        });
    };

    handleSkip = () => {
        const { selectedStepIndex } = this.state;

        if (selectedStepIndex === 2) {
            this.increaseSelectedStep(2);
            EmitMetric({
                misc_event_type: `admin-onboarding-step-2-skipped`,
                misc_event_count: 1,
            });
            EmitMetric({
                misc_event_type: `admin-onboarding-step-3-skipped`,
                misc_event_count: 1,
            });
            return;
        }

        this.increaseSelectedStep();
        EmitMetric({
            misc_event_type: `admin-onboarding-step-${selectedStepIndex}-skipped`,
            misc_event_count: 1,
        });
    };

    handleNext = async () => {
        const { selectedStepIndex } = this.state;

        if (this.state.isLoading) return;

        this.setIsLoading(true);
        try {
            this.setIsLoading(false);
            if (
                this.adminModalContent &&
                this.adminModalContent.currentComponent
            ) {
                await this.adminModalContent.currentComponent.onNext();
                return;
            }

            this.increaseSelectedStep();
            EmitMetric({
                misc_event_type: `admin-onboarding-step-${selectedStepIndex}-completed`,
                misc_event_count: 1,
            });
        } catch (e) {
            console.error(e);
            this.setIsLoading(false);
        }
    };

    handleSubmitModalForm = async (key, value, submitForm = false) => {
        const { selectedStepIndex } = this.state;
        this.setState({
            [key]: value,
            apiError: null,
        });

        if (!submitForm) {
            this.increaseSelectedStep();
            return;
        }

        const message = key === 'message' && value;
        const formValues = {
            inviteEmails: this.state.inviteEmails,
            message,
            communityGroupHash: this.props.group.hash,
        };
        const isValid = await ValidateForm(
            formValues,
            groupBatchEmailInviteSchema,
            this.errorSetStateFunction
        );
        if (isValid) {
            try {
                await axios.post(INVITE_GROUP_VIA_EMAIL, formValues);
                this.increaseSelectedStep();
                this.setIsLoading(false);
                EmitMetric({
                    misc_event_type: `admin-onboarding-step-${selectedStepIndex}-completed`,
                    misc_event_count: 1,
                });
            } catch (e) {
                this.setState({
                    isLoading: false,
                    apiError: e,
                });
                this.errorSetStateFunction(e);
            }
        }
    };

    errorSetStateFunction = (errors) => {
        this.setState({ apiError: { ...this.state.apiError, ...errors } });
    };

    serializeApiError = (errorObj) => {
        if (isEmpty(errorObj)) {
            return '';
        }
        return Object.values(errorObj).map((val, index) => {
            return (
                <p>
                    {index + 1}. {val}
                    <br />
                </p>
            );
        });
    };

    render() {
        const { selectedStepIndex, apiError } = this.state;

        const commonProps = {
            totalSteps: stepDefinitions.length,
            stepDefinition: stepDefinitions[selectedStepIndex],
            selectedStepIndex,
        };

        return (
            <ThreeRowModalLayout className="component-Groups-AdminOnboarding-AdminOnboardingModal">
                <AdminOnboardingModalTop {...commonProps} />

                <AdminOnboardingModalContent
                    {...commonProps}
                    ref={this.setRef}
                    apiError={this.serializeApiError(apiError)}
                    handleStart={this.handleStart}
                    handleSubmitModalForm={this.handleSubmitModalForm}
                    handleJoinAdminGroup={this.props.handleJoinAdminGroup}
                    group={this.props.group}
                    adminGroup={this.props.adminGroup}
                    setIsLoading={this.setIsLoading}
                    userSearchKeyword={this.props.userSearchKeyword}
                    userSearchlocation={this.props.userSearchlocation}
                    userSearchPageNumber={this.props.userSearchPageNumber}
                    userSearchMessage={this.props.userSearchMessage}
                />

                {this.state.selectedStepIndex !== 0 && (
                    <AdminOnboardingModalBottom
                        {...commonProps}
                        handleNext={this.handleNext}
                        handleSkip={this.handleSkip}
                        handleFinish={this.handleFinish}
                    />
                )}
            </ThreeRowModalLayout>
        );
    }
}
