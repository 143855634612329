export const CONTENT_TYPE = {
    DISCUSSION: 'Discussion',
    LINK: 'Link',
    PHOTO: 'Photo',
};

export const REVIEW_CONTENT_TYPE = {
    DISCUSSION: 'community-discussion',
    LINK: 'community-link',
    PHOTO: 'community-photo',
};
