import { EMPLOYER_ROLES } from '../pages/constants';

export const capitalize = (str) =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const isEmpty = (obj) => {
    if (!obj || obj.constructor !== Object) {
        return true;
    }
    return Object.entries(obj).length === 0;
};

const stripPunc = (word) =>
    word
        .replace(/[^A-Za-z0-9\s]/g, '')
        .replace(/\s{2,}/g, ' ')
        .trim();

export const ellipsify = (str, maxChars, truncateOnWords = false) => {
    if (!str || str.length === 0) return '';
    if (!maxChars) return str;

    if (str.length > maxChars) {
        if (truncateOnWords) {
            const words = str.split(' ');
            const shortened = words.reduce((acc, word) => {
                const currLength = acc.join(' ').length + word.length;
                if (currLength > maxChars) return [...acc];
                return [...acc, word];
            }, []);

            const sentence = shortened.slice(0, shortened.length - 1).join(' ');
            let lastWord = '';
            // adding check to make sure shortened legth is not undefined
            if (shortened[shortened.length - 1])
                lastWord = stripPunc(shortened[shortened.length - 1]);

            return `${sentence} ${lastWord}...`;
        }

        return `${str.slice(0, maxChars).trim()}...`;
    }

    return str;
};

/**
 * check if an element is one of an object's key
 *
 * @param {object} obj - object to be checked
 * @param {string || number} element - element to be evaluate with object's keys, falsey value numeric 0 is accepted
 * @returns {Boolean}
 */
export const getIsElementInObjectKeys = (obj, element) => {
    if (isEmpty(obj)) {
        return false;
    }

    if (element !== 0 && !element) {
        return false;
    }

    const isIncluded = Object.keys(obj)
        .map((objKey) => {
            if (typeof element === 'number') {
                return parseInt(objKey, 10);
            }

            return objKey;
        })
        .includes(element);

    return isIncluded;
};

export const getItemByKey = (items, field, key) => {
    return items.filter((item) => item[field] === key);
};

export function checkPermission(userRoles, allowedRoles) {
    if (!userRoles || !allowedRoles) {
        return false;
    }
    for (const userRole of userRoles) {
        for (const role of allowedRoles) {
            if (userRole === role) {
                return true;
            }
        }
    }
    return false;
}

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
};

export class FGBLocalStorage {
    static removeItem = (key) => {
        if (!window || !window.localStorage) {
            return null;
        }
        const myStorage = window.localStorage;
        myStorage.removeItem(key);
    };

    static getItem = (key) => {
        if (!window || !window.localStorage) {
            return null;
        }
        const myStorage = window.localStorage;
        let item = myStorage.getItem(key);
        item = item ? JSON.parse(item) : null;

        return item;
    };

    static setItem = (key, item) => {
        const myStorage = window.localStorage;

        if (!window || !window.localStorage) {
            return null;
        }

        if (key === 'companyAds') {
            const savedItems = FGBLocalStorage.getItem(key);
            const uniqueItems = new Set(savedItems);
            uniqueItems.add(item);

            const arrayItems = Array.from(uniqueItems);

            while (arrayItems.length > 2) {
                /** delete until two left */
                arrayItems.shift();
            }
            return myStorage.setItem(key, JSON.stringify(arrayItems));
        }
        return myStorage.setItem(key, JSON.stringify(item));
    };
}

export const isValidEmail = (email) => {
    if (!email) {
        return false;
    }
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
};

export const zeroPaddedNumber = (num) => (num < 10 ? `0${num}` : num);

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const removeUrlParameter = (url, parameter) => {
    const urlParts = url.split('?');

    if (urlParts.length >= 2) {
        const urlBase = urlParts.shift();
        const queryString = urlParts.join('?');
        const prefix = `${encodeURIComponent(parameter)}=`;
        const parts = queryString.split(/[&;]/g);

        for (let i = parts.length; i-- > 0; ) {
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }
        url = parts.length > 0 ? `${urlBase}?${parts.join('&')}` : urlBase;
    }
    return url;
};

export const areEqualShallow = (objA = {}, objB = {}) => {
    for (const keysA in objA) {
        if (objA.hasOwnProperty(keysA)) {
            if (objA[keysA] !== objB[keysA]) {
                return false;
            }
        }
    }
    for (const keysB in objB) {
        if (objB.hasOwnProperty(keysB)) {
            if (objA[keysB] !== objB[keysB]) {
                return false;
            }
        }
    }
    return true;
};

/*
 * Taken form underscorejs's throttle function
 * Returns a rate limiter function that will only be triggered once during a given amount of time.
 */
export const throttle = (func, wait, options) => {
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;
    if (!options) options = {};
    const later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        const now = Date.now();
        if (!previous && options.leading === false) previous = now;
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
};

const intlFormat = (num) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

/**
 * formatted (trailing 0's removed) version of the number formatter above
 *
 * e.g. 100 -> 100
 *      1234 -> 1.2k
 *      12345 -> 12.3k
 *      123456 -> 123.5k
 *      9999999 -> 10m
 *      10000000 -> 10m
 * @param {int} num
 * @returns {string}
 */

export const prettifyNumber = (num) => {
    // handle 0
    if (num === 0) return 0;

    // null/invalid input check
    if (!num || !Number.isInteger(num)) return null;
    const million = 1000000;
    const thousand = 1000;
    if (num >= million) return `${intlFormat(num / million)}M`;
    if (num >= thousand) return `${intlFormat(num / thousand)}k`;
    return intlFormat(num);
};

/**
 * @param {string} value (url)
 * @returns {boolean}
 */
export const isValidUrl = (value) => {
    const rgx = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const urlRegex = new RegExp(rgx);
    return urlRegex.test(value);
};

const matchRegex = /({{{COMPANY_AD_EMBEDED}}}|{{{COMPANY_AD_STANDALONE}}})/g;

export const stripAdPlaceholderText = (str) => str.replace(matchRegex, '');

export const stripHtml = (rawHtml) => {
    const newHtml = rawHtml.replace(/<p>/g, '\n');
    const returnHtml = newHtml.replace(/<\/?[^>]+(>|$)/g, '');
    return returnHtml.trim();
};
const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
        return `<a href="${url}">${url}</a>`;
    });
};

export const decodeRawHtml = (rawHtml) => {
    const htmlParts = rawHtml.split('\n');
    const firstPart = htmlParts[0];
    const title = firstPart.replace(/<\/?[^>]+(>|$)/g, '');
    htmlParts.shift();
    const realParts = [];
    let firstRealFound = false;
    for (let i = 0; i < htmlParts.length; i += 1) {
        if (htmlParts[i] !== '' || firstRealFound) {
            firstRealFound = true;
            realParts.push(urlify(htmlParts[i]));
        }
    }

    return {
        title,
        body: realParts.join('\n'),
    };
};

export const decodeRawHtmlText = (rawHtml) => {
    const htmlParts = rawHtml.split('</strong>');
    const firstPart = htmlParts[0];
    const title = firstPart.includes('</a>')
        ? ''
        : firstPart.replace(/<\/?[^>]+(>|$)/g, '');
    if (title !== '') htmlParts.shift();
    const remainingParts = htmlParts.join('').split('<p><br></p>');
    const realParts = [];
    let realLength = 0;
    let firstRealFound = false;
    for (let i = 0; i < remainingParts.length; i += 1) {
        if (
            (remainingParts[i] !== '' && remainingParts[i] !== '</p>') ||
            firstRealFound
        ) {
            firstRealFound = true;
            realParts.push(remainingParts[i]);
            if (
                (!remainingParts[i].trim().startsWith('<a') ||
                    !remainingParts[i].trim().endsWith('a/>')) &&
                (!remainingParts[i].trim().startsWith('</p><p>ht') ||
                    !remainingParts[i].trim().endsWith('m</p>')) &&
                (!remainingParts[i].trim().startsWith('</p><p>ht') ||
                    !remainingParts[i].trim().endsWith('m</p>')) &&
                (!remainingParts[i].trim().startsWith('</p><p><a') ||
                    !remainingParts[i].trim().endsWith('</a></p>')) &&
                (!remainingParts[i].trim().startsWith('</p><p><a') ||
                    !remainingParts[i].trim().endsWith('</a> </p>'))
            ) {
                realLength += 1;
            }
        }
    }

    return {
        title,
        body: realParts.join('<p><br></p>'),
        realLength,
    };
};

export const renderTruncatableText = (displayText, length) => {
    if (displayText.length > length) {
        /*
         * truncate display text at truncatedAtIndex if the length of first word is longer than truncatedAtIndex,
         * otherwise truncated at the last word without punctuations before truncatedAtIndex.
         */
        const lastSpaceIndex = displayText.slice(0, length).lastIndexOf(' ');
        const isFirstWordLongerThanTruncatedAtIndex =
            lastSpaceIndex === -1 || lastSpaceIndex > length;
        return displayText
            .slice(
                0,
                isFirstWordLongerThanTruncatedAtIndex ? length : lastSpaceIndex
            )
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]\s*$/, '')
            .concat('...');
    }
    return displayText;
};
// Flattens an array. d is the depth, use keyword Infinity to flatten it forever.
export function flatten(arr, d = 1) {
    return d > 0
        ? arr.reduce(
              (acc, val) =>
                  acc.concat(Array.isArray(val) ? flatten(val, d - 1) : val),
              []
          )
        : arr.slice();
}

export const getUserCompany = (PAGE_PROPS) => {
    return (
        PAGE_PROPS &&
        PAGE_PROPS.session &&
        PAGE_PROPS.session.userCompaniesRoles &&
        Object.keys(PAGE_PROPS.session.userCompaniesRoles)[0] &&
        Object.keys(PAGE_PROPS.session.userCompaniesRoles)[0].length &&
        Number(Object.keys(PAGE_PROPS.session.userCompaniesRoles)[0])
    );
};

export const getUserCompanyRole = (session = {}) => {
    if (Object.keys(session.userCompaniesRoles).length === 0) {
        return null;
    }

    const roles =
        session.userCompaniesRoles[Object.keys(session.userCompaniesRoles)[0]];

    return roles.length > 0 ? roles[0] : null;
};

export const isRecruiter = (session = {}) => {
    return Object.keys(session.userCompaniesRoles).length > 0;
};

/**
 * check all the company roles a user has
 * @param {array} companyIds - company id to be checked
 * @param {object} userCompaniesRoles - userCompaniesRoles that have company id as object keys, pairing with array of user's roles
 * @returns {object}
 */
export const checkAllUserCompaniesRolesWithCompanyIds = (
    companyIds = [],
    userCompaniesRoles = {}
) => {
    const userRoles = {
        isAdmin: false,
        isRecruiter: false,
        isEmployee: false,
    };

    companyIds.forEach((companyId) => {
        const company = userCompaniesRoles[companyId];

        if (company && company.includes(EMPLOYER_ROLES.admin)) {
            userRoles.isAdmin = true;
        }

        if (company && company.includes(EMPLOYER_ROLES.recruiter)) {
            userRoles.isRecruiter = true;
        }

        if (
            company &&
            (company.includes(EMPLOYER_ROLES.employee) ||
                company.includes(EMPLOYER_ROLES.reviewer))
        ) {
            userRoles.isEmployee = true;
        }
    });

    return userRoles;
};

export const parseCsvLine = (text) => {
    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    const a = []; // Initialize array to receive values.
    text.replace(
        re_value, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
            // Remove backslash from \' in single quoted values.
            if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
            // Remove backslash from \" in double quoted values.
            else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
            else if (m3 !== undefined) a.push(m3);
            return ''; // Return empty string.
        }
    );
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');
    return a;
};

const objToStr = (obj) => {
    let k;
    let cls = '';
    for (k in obj) {
        if (obj[k]) {
            cls && (cls += ' ');
            cls += k;
        }
    }
    return cls;
};

const toValue = (mix) => {
    let y;
    let str = '';
    if (typeof mix === 'string' || typeof mix === 'number') {
        str += mix;
    } else if (typeof mix === 'object') {
        if (Array.isArray(mix)) {
            for (const k of mix) {
                if (mix[k]) {
                    if ((y = toValue(mix[k]))) {
                        str && (str += ' ');
                        str += y;
                    }
                }
            }
        } else {
            str += objToStr(mix);
        }
    }
    return str;
};

// args:
// strings eg: ('className1', true && 'className2', false && 'className3') => "className1 className2"
// an array of strings eg: ['className1', 'className2'] => "className1 className2"
// an object of properties with true/false values
// eg: { className1: true, className2: false, className3: true } => "className1 className3"
export const constructCn = (...args) => {
    let i = 0;
    let tmp;
    let x;
    let str = '';
    while (i < args.length) {
        if ((tmp = args[i++])) {
            if ((x = toValue(tmp))) {
                str && (str += ' ');
                str += x;
            }
        }
    }
    return str;
};

/**
 * get unique objects by checking object's specified property key value
 * @param {array} arrayOfObjects - array of objects
 * @param {string} propertyKey - the property key that we are checking against
 * @returns {array}
 * EX:
 * arrayOfObjects: [{id: 1, color: red}, {id: 1, color: blue}, {id: 2, color: red}]
 * propertyKey: "id"
 * returns: [{id: 1, color: red}, {id: 2, color: red}]
 */
export const getUniqueObjectsByPropertyKeyValue = (
    arrayOfObjects = [],
    propertyKey = ''
) => {
    if (!propertyKey || !arrayOfObjects || !arrayOfObjects.length) {
        return [];
    }

    const uniqueObjects = arrayOfObjects.filter(
        ((newSet) => (item) =>
            !newSet.has(item[propertyKey]) && newSet.add(item[propertyKey]))(
            new Set()
        )
    );

    return uniqueObjects;
};
