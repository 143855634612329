/* eslint-disable import/no-cycle */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';

import { allModalTypes, getComponent } from './Includes/modalContextHelpers';
import { ModalContext } from './ContextCreator';
import useForm from '../utilities/hooks/useForm';
import AriaModal from '../components/Modals/AriaModal/AriaModal';
import { isEmpty } from '../utilities/helperFunctions';

const defaultUnderlayStyle = { padding: '60px 0' };

const getModalProviderValue = (
    formValues,
    formUpdaters,
    generalModalProps,
    commonProps
) => ({
    formValues,
    updateFormValues: formUpdaters.updateFormValues,
    updateFormValueAtPath: formUpdaters.updateFormValueAtPath,
    updateFormValueAtPathSelector: formUpdaters.updateFormValueAtPathSelector,
    generalModalProps,
    clearStepErrors: formUpdaters.clearStepErrors,
    ...commonProps,
});

const getNonClosableProps = (closeOptions = {}) => {
    const { backgroundExits = true, escapeExits = true } = closeOptions;
    return {
        underlayClickExits: !!backgroundExits,
        escapeExits: !!escapeExits,
    };
};

const ModalProvider = ({
    type,
    getApplicationNode,
    commonProps,
    stepPopulateProps,
    generalModalProps,
    data,
}) => {
    const [
        formValues,
        updateFormValues,
        setFormValues,
        updateFormValueAtPath,
        updateFormValueAtPathSelector,
        clearStepErrors,
    ] = useForm(stepPopulateProps);

    // re-initialize form values when stepPopulateProps changes
    React.useEffect(() => {
        if (!stepPopulateProps || isEmpty(stepPopulateProps)) {
            setFormValues({});
        } else {
            setFormValues(stepPopulateProps);
        }
    }, [stepPopulateProps, setFormValues]);

    const FlowComponent = getComponent(type);

    const { closeModal } = commonProps;
    const { closeOptions = {} } = generalModalProps;

    // default options are underlayClickExits: true + escapeExits: true
    const modalCloseOptions = getNonClosableProps(closeOptions);

    const flowModal = (
        <AriaModal
            titleText="modal"
            onExit={closeModal}
            getApplicationNode={getApplicationNode}
            underlayStyle={defaultUnderlayStyle}
            dialogClass="component-Modals-AriaModal"
            focusDialog
            focusTrapPaused
            {...modalCloseOptions}
        >
            <FlowComponent {...data} />
        </AriaModal>
    );

    const formUpdaters = {
        updateFormValues,
        updateFormValueAtPath,
        updateFormValueAtPathSelector,
        clearStepErrors,
    };

    const providerValue = getModalProviderValue(
        formValues,
        formUpdaters,
        generalModalProps,
        commonProps
    );

    return (
        <ModalContext.Provider value={providerValue}>
            {flowModal}
        </ModalContext.Provider>
    );
};

ModalProvider.propTypes = {
    type: PropTypes.oneOf(allModalTypes),
    getApplicationNode: PropTypes.func.isRequired,
    commonProps: PropTypes.exact({
        modalTrigger: PropTypes.func.isRequired,
        triggerFlowModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        session: PropTypes.object,
        featureFlags: PropTypes.object,
        jobProfileSnippet: PropTypes.object.isRequired,
    }).isRequired,
    stepPopulateProps: PropTypes.object.isRequired,
    generalModalProps: PropTypes.object.isRequired,
    data: PropTypes.object,
};

ModalProvider.defaultProps = {
    type: null,
    data: {},
};

ModalProvider.displayName = 'ModalProvider';

export default React.memo(ModalProvider);
