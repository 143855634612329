import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

import './SpecialGroups.scss';

export default class SpecialGroups extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        buttonType: PropTypes.string,
        buttonData: PropTypes.array,
        showCheckbox: PropTypes.bool,
        checkboxLabel: PropTypes.string,
    };
    static defaultProps = {
        questionName: 'specialGroup',
        buttonType: 'multi',
        buttonData: [
            {
                title: 'Parent',
                subtitle: null,
                value: 'parent',
            },
            {
                title: 'Caregiver',
                subtitle: null,
                value: 'caregiver',
            },
            {
                title: 'LGBTQ',
                subtitle: null,
                value: 'lgbtq',
            },
            {
                title: 'Veteran',
                subtitle: null,
                value: 'veteran',
            },
            {
                title: 'Person with disabilities',
                subtitle: null,
                value: 'disabled',
            },
            {
                title: 'Prefer not to say',
                subtitle: null,
                isExclusive: true,
                value: null,
            },
        ],
        onAnswer: () => {},
    };

    handleUpdate = (data) => {
        const answer = {
            specialGroups: data,
        };

        this.props.onAnswer(
            'specialGroup',
            answer,
            CAN_SUBMIT,
            false,
            this.props.nextStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-demographic-5',
            misc_event_count: 1,
        });
        const answer = {
            specialGroups: this.props.specialGroups,
        };
        this.props.specialGroups
            ? this.props.onAnswer(
                  'specialGroup',
                  answer,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-SpecialGroups">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={this.props.specialGroups}
                />
            </div>
        );
    }
}
