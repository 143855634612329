import PropTypes from 'prop-types';
import React from 'react';

import './ButtonTile.scss';

export default class ButtonTile extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        buttonData: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
            title: PropTypes.string,
            subtitle: PropTypes.string,
        }),
        selected: PropTypes.bool,
        handleClick: PropTypes.func,
        buttonStyle: PropTypes.string,
        rowHeight: PropTypes.string,
    };

    static defaultProps = {
        buttonData: {
            title: '',
            subtitle: '',
            value: 'value',
        },
        selected: false,
        handleClick: () => {},
        buttonStyle: '',
        rowHeight: '100%',
    };

    handleClick = (e) => {
        const event = { ...e };
        this.hoverOff(event);
        this.props.handleClick(event.currentTarget.dataset.selection);
    };

    handleKeyUp = (e) => {
        if (e.keyCode == 13 || e.keyCode == 32) {
            this.handleClick(e);
        }
    };

    hoverOn = (e) => {
        e.currentTarget.classList &&
            e.currentTarget.classList.add('mouse-hover');
    };
    hoverOff = (e) => {
        e.currentTarget.classList &&
            e.currentTarget.classList.remove('mouse-hover');
    };
    getAdditionalClasses = () =>
        `${this.props.buttonStyle} ${this.props.selected ? 'selected' : ''}`;

    render() {
        const style = { height: this.props.rowHeight };
        const { title, subtitle, value } = this.props.buttonData;
        const containerClasses = `component-ReviewSurvey-Components-ButtonTile ${this.getAdditionalClasses()}`;
        return (
            <div className={containerClasses} style={style} key={value}>
                <div
                    role="button"
                    className="button-tile"
                    data-selection={this.props.idx}
                    tabIndex="0"
                    onClick={this.handleClick}
                    onKeyUp={this.handleKeyUp}
                    onMouseEnter={this.hoverOn}
                    onMouseLeave={this.hoverOff}
                >
                    {title && <h5 className="tile-title">{title}</h5>}
                    {subtitle && <p className="tile-sub-title">{subtitle}</p>}
                </div>
            </div>
        );
    }
}
