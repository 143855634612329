import React from 'react';

export default class ActionProvider extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoginWidgetOpen: false,
            isLeftDrawerOpen: false,
            isUserDrawerOpen: false,
            isUserDropdownOpen: false,
            isMessageDrawerOpen: false,
        };
    }

    isStateNameValid = (stateName) =>
        Object.keys(this.state).includes(stateName);

    getAllActionState = () => ({ ...this.state });

    getActionState = (stateName) =>
        this.isStateNameValid(stateName) && this.state[stateName];

    setActionState = (stateName, stateValue, callback) => {
        const isUpdateValid =
            this.state[stateName] !== stateValue &&
            typeof stateValue !== 'undefined';

        if (isUpdateValid) {
            this.setState({ [stateName]: stateValue });

            const isCallbackValid = typeof callback === 'function';
            if (isCallbackValid) {
                callback();
            }
        }
    };

    render() {
        const { children } = this.props;
        const newProps = {
            ...this.props,
            ...this.state,
            getAllActionState: this.getAllActionState,
            setActionState: this.setActionState,
            getActionState: this.getActionState,
        };

        return typeof children === 'function'
            ? children({ ...newProps })
            : React.Children.map(children, (child) =>
                  React.cloneElement(child, { ...newProps })
              );
    }
}
