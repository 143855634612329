import React from 'react';
import PropTypes from 'prop-types';

import TextAreaInput from '../../../../Input/TextAreaInput/TextAreaInput';
import InputLabel from '../../Elements/InputLabel/InputLabel';
import FieldError from '../../Elements/FieldError/FieldError';

import './LongTextInput.scss';

export const LongTextInput = ({
    inputName,
    label,
    placeholder,
    error,
    value,
    handleChange,
    className,
    maxChars,
    disabled,
    rows,
}) => {
    const idName = `#modal-textarea-${inputName.split('.').slice(-1)[0]}`;
    return (
        <div className="component-Modals-ModalParts-Inputs-LongTextInput">
            <InputLabel
                text={label}
                type="textarea"
                htmlFor={idName}
                charCount={value.length}
                maxChars={maxChars}
            />
            <TextAreaInput
                name={inputName}
                value={value}
                placeholder={placeholder}
                id={idName}
                onChange={handleChange}
                class={className}
                hasError={!!error}
                disabled={disabled}
                rows={rows}
                shouldUseEvent
            />
            <FieldError text={error} />
        </div>
    );
};

LongTextInput.propTypes = {
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    maxChars: PropTypes.number,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
};

LongTextInput.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    error: '',
    className: 'modal-text-input',
    maxChars: null,
    disabled: false,
    rows: 3,
};

LongTextInput.displayName = 'LongTextInput';

export default React.memo(LongTextInput);
