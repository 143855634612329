/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import CopyLinkBlue from './CopyLinkBlue/CopyLinkBlue';
import FacebookIcon from './FacebookIcon/FacebookIcon';
import LinkCopiedIcon from './LinkCopiedIcon/LinkCopiedIcon';
import LinkedinIcon from './LinkedinIcon/LinkedinIcon';

import './SocialShareBarV2.scss';

export const getFaceBookUrl = (urlToShare) => {
    const base = 'https://www.facebook.com/sharer/sharer.php?u=';
    const article = encodeURIComponent(urlToShare);
    return `${base}${article}`;
};

export const getLinkedInUrl = (urlToShare) => {
    const base = 'https://www.linkedin.com/sharing/share-offsite/?url=';
    const article = encodeURIComponent(urlToShare);
    return `${base}${article}`;
};

const SocialShareBarV2 = ({ urlToShare, location, addToMetrics = {} }) => {
    const [isActive, setIsActive] = React.useState(false);

    const handleOnClickMetricEmit = (event, service) => {
        EmitMetric({
            ...addToMetrics,
            share_destination: service,
            misc_event_type: 'social-bar-share',
            share: 1,
            ...(location ? { location } : {}),
        });
    };

    const handleClick = async (e) => {
        if (!navigator?.clipboard) return;
        try {
            await navigator.clipboard.writeText(
                `${urlToShare}?utm_source=link&utm_medium=content-share`
            );
            handleOnClickMetricEmit(e, 'link');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
        setIsActive(true);
        setTimeout(() => setIsActive(false), 2000);
    };

    return (
        <div className="component-SocialShareBarV2">
            <a
                role="button"
                onClick={(e) => handleOnClickMetricEmit(e, 'linkedin')}
                href={getLinkedInUrl(urlToShare)}
                target="_blank"
                tabIndex="0"
                className="share-btn"
                style={{
                    backgroundColor: 'rgb(0, 119, 181)',
                }}
            >
                <LinkedinIcon />
            </a>
            <a
                target="_blank"
                onClick={(e) => handleOnClickMetricEmit(e, 'facebook')}
                href={getFaceBookUrl(urlToShare)}
                role="button"
                tabIndex="0"
                className="share-btn"
                style={{
                    backgroundColor: 'rgb(59, 89, 152)',
                }}
            >
                <FacebookIcon />
            </a>
            <div
                onClick={(e) => handleClick(e, 'link')}
                role="button"
                tabIndex="0"
                onKeyDown={() => null}
                className="share-btn"
                style={{
                    backgroundColor: 'rgb(23, 139, 244)',
                }}
            >
                {!isActive && <CopyLinkBlue />}
                {isActive && <LinkCopiedIcon />}
            </div>
        </div>
    );
};

SocialShareBarV2.displayName = 'SocialShareBarV2';

export default SocialShareBarV2;
