import PropTypes from 'prop-types';
import React from 'react';
import RatingWing from '../../ReviewSurvey/Components/RatingWing/RatingWing';
import './RatingSelectComponent.scss';

export default class RatingSelectComponent extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            selected: props.selected || 0,
            hovered: null,
        };
    }

    static propTypes = {
        selected: PropTypes.number,
        component: PropTypes.func,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        component: RatingWing,
    };

    selectedState = (selected) => {
        this.setState(
            {
                selected,
            },
            this.props.onClick(selected) || null
        );
    };

    hoverState = (hovered) => {
        this.setState({
            hovered,
        });
    };

    renderComponents = () => {
        let wings = [];
        const Component = this.props.component;
        for (let i = 0; i < 5; i++) {
            const currentNumber = i + 1;
            const highlighted = this.state.hovered || this.state.selected || 0;
            const active = currentNumber <= highlighted;
            wings.push(
                <Component
                    key={currentNumber}
                    number={currentNumber}
                    active={active}
                    onMouseOver={this.hoverState}
                    onClick={this.selectedState}
                />
            );
        }
        return wings;
    };

    render() {
        return (
            <div className="component-Form-RatingSelectComponent">
                {this.renderComponents()}
            </div>
        );
    }
}
