import PropTypes from 'prop-types';
import React from 'react';

import GroupItem from '../Network/GroupItem/GroupItem';

export default class RecommendedGroup extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * object of recommended group to user
         */
        recommendedGroup: PropTypes.object.isRequired,
        /**
         * function to be called after btn is clicked
         */
        callbackFn: PropTypes.func,
        /**
         * enum of JOINED when join btn is clicked
         */
        clickedAction: PropTypes.oneOf(['JOINED']),
        /**
         * string of user's relation to group
         */
        userRelation: PropTypes.shape({
            role: PropTypes.oneOf([
                'owner',
                'member',
                'admin',
                'moderator',
                null,
            ]),
        }),
        /**
         * string of custom css classname
         */
        className: PropTypes.string,
        /**
         * links to groups open new tab
         */
        openLinksInNewTab: PropTypes.bool,
    };

    static defaultProps = {
        className: 'network-item recommended-group',
        callbackFn: () => {},
        userRelation: { role: null },
        clickedAction: null,
        openLinksInNewTab: false,
    };

    render() {
        const {
            recommendedGroup,
            callbackFn,
            clickedAction,
            userRelation,
            className,
            openLinksInNewTab,
        } = this.props;

        return (
            <li className="component-Groups-RecommendedGroup">
                <GroupItem
                    group={recommendedGroup}
                    groupNetworkType="RECOMMENDED"
                    className={className}
                    onClick={callbackFn}
                    clickedAction={clickedAction}
                    userRelation={userRelation}
                    openLinksInNewTab={openLinksInNewTab}
                />
            </li>
        );
    }
}
