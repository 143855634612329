import Yup from 'yup';
import { loginSchema } from './loginSchema';
import { PASSWORD_MATCH } from './constants';

export const resetPasswordSchema = () =>
    loginSchema().shape({
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            PASSWORD_MATCH
        ),
    });
