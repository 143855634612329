import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../../../contexts/PageContext';
import ButtonTile from '../ButtonTile/ButtonTile';
import './ButtonGrid.scss';

const buttonTypes = {
    single: 'single',
    multi: 'multi',
};

export default class ButtonGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: this.parseSelectedValuesToObject(
                this.props.selectedItems
            ),
        };
    }

    static propTypes = {
        /*
         **  single- or mult-select mode
         */
        buttonType: PropTypes.oneOf(['single', 'multi']),
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
                subtitle: PropTypes.string,
                /*
                 **  identifier/return value of button
                 */
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        /*
         **  additional class string for styling buttons, eg 'purple'
         */
        buttonStyle: PropTypes.string,
        /*
         **  additional class string for styling button container, eg 'with-checkbox'
         */
        gridStyle: PropTypes.string,
        onClick: PropTypes.func,
        hasAuthWrapper: PropTypes.bool,
    };

    static defaultProps = {
        buttonData: [],
        buttonType: buttonTypes.single,
        gridStyle: '',
        onClick: () => {},
        hasAuthWrapper: false,
    };

    getRowHeight = () => {
        const rowCount = Math.ceil(this.props.buttonData.length / 2);
        return Math.floor(100 / rowCount) + '%';
    };

    handleClick = (idx) => {
        const value = this.props.buttonData[idx].value;
        const isExclusive = this.props.buttonData[idx].isExclusive;
        const isSingleSelect =
            this.props.buttonType === buttonTypes.single || isExclusive;

        isSingleSelect
            ? this.updateSingleSelect(value, idx)
            : this.updateMultiSelect(value, idx);
    };

    updateSingleSelect = (value, idx) => {
        return this.setState((prevState) => {
            return { selectedItems: { [idx]: !prevState.selectedItems[idx] } };
        }, this.submitChanges);
    };

    updateMultiSelect = (value, idx) => {
        const noneIndex = this.props.buttonData.findIndex(
            (item) => item.isExclusive && item.isExclusive === true
        );

        this.setState((prevState) => {
            const hasNoneOptionSelected = !!prevState.selectedItems[noneIndex];
            const newSelected = {
                ...prevState.selectedItems,
                [idx]: !prevState.selectedItems[idx],
            };

            if (hasNoneOptionSelected) {
                newSelected[noneIndex] = false;
            }

            return { selectedItems: newSelected };
        }, this.submitChanges);
    };

    submitChanges = () => {
        const selectedValues = [];
        this.props.buttonData.forEach((item, idx) => {
            if (this.state.selectedItems[idx]) {
                selectedValues.push(item.value);
            }
        });
        this.props.onClick(selectedValues);
    };

    parseSelectedValuesToObject = (values) => {
        const selectedObject = {};
        if (typeof values === 'undefined') {
            return selectedObject;
        }
        if (!Array.isArray(values)) {
            values = [values];
        }
        values.forEach((value) => {
            const idx = this.props.buttonData.findIndex(
                (btn) => btn.value === value
            );
            if (idx >= 0) {
                selectedObject[idx] = true;
            }
        });
        return selectedObject;
    };

    render() {
        const rowHeight = this.getRowHeight();
        return (
            <div
                className={`component-ReviewSurvey-Components-ButtonGrid ${this.props.gridStyle}`}
            >
                {this.props.buttonData.map((buttonData, idx) => {
                    let button = (
                        <ButtonTile
                            buttonData={buttonData}
                            handleClick={this.handleClick}
                            rowHeight={rowHeight}
                            idx={idx}
                            selected={!!this.state.selectedItems[idx]}
                            buttonStyle={this.props.buttonStyle}
                            key={idx}
                        />
                    );
                    if (
                        this.props.hasAuthWrapper &&
                        buttonData.value === false
                    ) {
                        button = (
                            <PageConsumer>
                                {(context) =>
                                    context.wrapAuth(
                                        '',
                                        <ButtonTile
                                            buttonData={buttonData}
                                            handleClick={(idx) => {
                                                context.isLoggedIn()
                                                    ? this.handleClick(idx)
                                                    : null;
                                            }}
                                            rowHeight={rowHeight}
                                            idx={idx}
                                            selected={
                                                !!this.state.selectedItems[idx]
                                            }
                                            buttonStyle={this.props.buttonStyle}
                                            key={idx}
                                        />,
                                        { login_trigger: 'survey-completed' },
                                        null,
                                        null,
                                        {
                                            name: 'ReviewSurveyStatus',
                                            value: 'complete',
                                        }
                                    )
                                }
                            </PageConsumer>
                        );
                    }
                    return button;
                })}
            </div>
        );
    }
}
