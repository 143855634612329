import PropTypes from 'prop-types';
import React from 'react';
import CharCount from '../../Elements/CharCount/CharCount';
import Tag from '../../Elements/Tag/Tag';
import CloudspongeAddressComponent from '../../Form/CloudspongeAddressComponent/CloudspongeAddressComponent';
import InputField from '../../Input/InputField/InputField';
import PaginationNav from '../../ReviewSurvey/Components/PaginationNav/PaginationNav';
import './EmailsLayout.scss';

export default class EmailsLayout extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            inviteEmails: props.inviteEmails || [],
            manualInviteEmail: '',
            addButtonClicked: false,
            errorinviteEmails: '',
        };
    }

    static propTypes = {
        onNext: PropTypes.func,
        onPrev: PropTypes.func,
        inviteEmails: PropTypes.array,
        showPrevBtn: PropTypes.bool,
    };

    static defaultProps = {
        showPrevBtn: true,
    };

    handleAddButtonClick = () => {
        const { inviteEmails, manualInviteEmail } = this.state;
        let inviteEmailInputNew = this.addToCurrentListOfEmail(
            manualInviteEmail,
            inviteEmails
        );
        this.setState({
            inviteEmails: inviteEmailInputNew,
            manualInviteEmail: '',
            errorinviteEmails: '',
        });
    };

    manualInviteEmailChange = (manualInviteEmail) => {
        this.setState(
            {
                manualInviteEmail,
                errorinviteEmails: '',
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    handleIndividualEmails = (inviteEmails) => {
        const emailArray = this.splitEmailStringToArray(inviteEmails);
        const refactoredEmails = this.addToCurrentListOfEmail(
            emailArray,
            this.state.inviteEmails
        );
        this.setState(
            {
                inviteEmails: refactoredEmails,
                addButtonClicked: false,
                errorinviteEmails: '',
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    splitEmailStringToArray = (inviteEmails) => {
        const lineBreakAndSurroundingSpaces = /\s*[\r\n]+\s*/;
        return inviteEmails.trim().split(lineBreakAndSurroundingSpaces);
    };

    addToCurrentListOfEmail = (newEmail, oldEmail) => {
        const emailConcat = oldEmail.concat(newEmail);
        //Removes dupilicates
        const uniq = (emailConcat) =>
            emailConcat.filter(
                (value, index, self) =>
                    index === self.findIndex((other) => other === value)
            );
        return uniq(emailConcat);
    };

    renderEmails = () => {
        const { inviteEmails } = this.state;
        return inviteEmails.map((email, idx) => (
            <Tag
                key={idx}
                onRemove={() => this.removeEmail(idx)}
                shouldAllowDelete={true}
            >
                {email}
            </Tag>
        ));
    };

    removeEmail = (idx = 0) => {
        const { inviteEmails } = this.state;
        inviteEmails.splice(idx, 1);
        this.setState(
            {
                inviteEmails,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    isValidEmail = () => {
        const hasManualInviteEmail = !!(
            this.state.manualInviteEmail && this.state.manualInviteEmail.length
        );
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = this.state.manualInviteEmail
            ? emailRegex.test(this.state.manualInviteEmail.toLowerCase())
            : false;
        return !!(hasManualInviteEmail && isValidEmail);
    };

    emailUnderMaxLength = () => {
        return !!(
            this.state.manualInviteEmail &&
            this.state.manualInviteEmail.length &&
            this.state.manualInviteEmail.length <= 128
        );
    };

    onNext = () => {
        if (this.state.inviteEmails && this.state.inviteEmails.length) {
            this.props.onNext('inviteEmails', this.state.inviteEmails);
        } else {
            this.setState({
                errorinviteEmails: `To proceed, enter a valid email and click the "Add" button.`,
            });
        }
    };

    handleKeyDown = (keyCode) => {
        const validEmail = this.isValidEmail() && this.emailUnderMaxLength();
        if (keyCode === 13 && validEmail) {
            this.handleAddButtonClick();
        }
    };

    render() {
        const disabledButtonClass = !!(
            this.isValidEmail() && this.emailUnderMaxLength()
        )
            ? ''
            : 'disabled-styling';
        const buttonSectionProps = {
            showPrevBtn: this.props.showPrevBtn,
            showNextBtn: true,
            PrevText: `Back`,
            NextText: `Next >`,
            prevDisabled: false,
            nextDisabled: false,
            onNext: this.onNext,
            onPrev: this.props.onPrev,
        };

        return (
            <div className="component-InvitationWidget-EmailsLayout">
                <div className="invitation-widget-content-container">
                    <CloudspongeAddressComponent
                        onChange={this.handleIndividualEmails}
                        onClick={() => {}}
                        buttonText={'Import contacts'}
                    />
                    <div className="emails-import-description">
                        from Gmail, LinkedIn, Yahoo, etc.
                    </div>
                    <section className="individual-email-section">
                        <div className="form-title-section">
                            <div className="section-label">
                                Or enter emails individually
                            </div>
                            <CharCount
                                length={this.state.manualInviteEmail.length}
                                limit={128}
                            />
                        </div>
                        <div className="input-section">
                            <InputField
                                name={'individual-emails-input'}
                                onChange={this.manualInviteEmailChange}
                                inputValue={this.state.manualInviteEmail}
                                onKeyDown={this.handleKeyDown}
                                placeholder={'email@email.com'}
                            />
                            <button
                                className={`add-individual-emails-button ${disabledButtonClass}`}
                                onClick={this.handleAddButtonClick}
                                disabled={
                                    !(
                                        this.isValidEmail() &&
                                        this.emailUnderMaxLength()
                                    )
                                }
                            >
                                Add
                            </button>
                        </div>
                    </section>
                    {this.state.inviteEmails &&
                    this.state.inviteEmails.length ? (
                        <section className="email-display-section">
                            {this.renderEmails()}
                        </section>
                    ) : null}
                    {this.state.inviteEmails && (
                        <div className={'errorMessage'}>
                            {this.state.errorinviteEmails}
                        </div>
                    )}
                </div>
                <PaginationNav {...buttonSectionProps} />
            </div>
        );
    }
}
