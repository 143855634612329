// eslint-disable-next-line max-classes-per-file
import React from 'react';
import ReactDOM from 'react-dom';

/**
 *
 * @param {React.ReactComponentElement} WrappedComponent - component to displace
 * @param {object} options - options object
 * @returns {React.React.ReactComponentElement} - displaced react component
 */
function displace(WrappedComponent, options = {}) {
    if (!global.document) {
        // eslint-disable-next-line react/prefer-stateless-function
        return class EmptyDisplace extends React.Component {
            render() {
                return false;
            }
        };
    }

    class Displaced extends React.Component {
        // eslint-disable-next-line react/sort-comp
        constructor(props) {
            super(props);

            this.container = (() => {
                if (!options.renderTo) {
                    const result = document.createElement('div');
                    document.body.appendChild(result);
                    return result;
                }
                if (typeof options.renderTo === 'string') {
                    return document.querySelector(options.renderTo);
                }
                return options.renderTo;
            })();
        }

        static WrappedComponent = WrappedComponent;

        componentWillUnmount() {
            if (!options.renderTo) {
                this.container.parentNode.removeChild(this.container);
            }
        }

        render() {
            if (this.props.mounted === false) {
                return null;
            }
            return ReactDOM.createPortal(
                React.createElement(
                    WrappedComponent,
                    this.props,
                    this.props.children
                ),
                this.container
            );
        }
    }
    Displaced.defaultProps = {
        mounted: true,
    };
    return Displaced;
}

export default displace;
