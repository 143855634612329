import React from 'react';
import PropTypes from 'prop-types';
import './JobSeekerModal.scss';
import FGBLabel from '../../Elements/FGBLabel/FGBLabel';
import JobSeekerExperienceLayout from './JobSeekerExperienceLayout/JobSeekerExperienceLayout';
import JobSeekerEducationLayout from './JobSeekerEducationLayout/JobSeekerEducationLayout';
import CloseModalIcon from '../ModalParts/CloseModalIcon/CloseModalIcon';
import Yup from 'yup';

export const MonthDropdownOptions = [
    {
        label: 'January',
        value: 1,
    },
    {
        label: 'February',
        value: 2,
    },
    {
        label: 'March',
        value: 3,
    },
    {
        label: 'April',
        value: 4,
    },
    {
        label: 'May',
        value: 5,
    },
    {
        label: 'June',
        value: 6,
    },
    {
        label: 'July',
        value: 7,
    },
    {
        label: 'August',
        value: 8,
    },
    {
        label: 'September',
        value: 9,
    },
    {
        label: 'October',
        value: 10,
    },
    {
        label: 'November',
        value: 11,
    },
    {
        label: 'December',
        value: 12,
    },
];

export const renderYearsOptions = (yearsArray = []) => {
    return yearsArray.map((year) => {
        return {
            value: year,
            label: year,
        };
    });
};

export default class JobSeekerModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: props.error || null,
        };
    }

    static propTypes = {
        type: PropTypes.oneOf(['education', 'experience']),
        experience: PropTypes.shape({
            experienceId: PropTypes.number,
            title: PropTypes.string,
            startMonth: PropTypes.number,
            startYear: PropTypes.number,
            endMonth: PropTypes.number,
            endYear: PropTypes.number,
            isCurrent: PropTypes.bool,
            company: PropTypes.shape({
                companyName: PropTypes.string,
                companyId: PropTypes.number,
            }),
            location: PropTypes.shape({
                name: PropTypes.string,
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            isRemote: PropTypes.bool,
            jobType: PropTypes.string,
            description: PropTypes.string,
        }),
        education: PropTypes.shape({
            educationId: PropTypes.number,
            school: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            location: PropTypes.shape({
                name: PropTypes.string,
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            isRemote: PropTypes.bool,
            degree: PropTypes.string,
            field: PropTypes.string,
            startYear: PropTypes.number,
            endYear: PropTypes.number,
            isCurrent: PropTypes.bool,
            description: PropTypes.string,
        }),
        onUpdate: PropTypes.func,
        onDelete: PropTypes.func,
        error: PropTypes.object,
    };

    static defaultProps = {
        // type: 'experience',
    };

    handleSubmit = (formObject) => {
        this.props.onUpdate(this.props.type, formObject);
    };

    validateForm = async (
        ObjectToValidate,
        YupSchema,
        errorState,
        setStateCallback
    ) => {
        const schema = Yup.object().shape(YupSchema);
        try {
            await schema.validate(ObjectToValidate, { abortEarly: false });
            return true;
        } catch (errors) {
            this.setState({ loaded: true });
            this.displayError(errorState, errors, setStateCallback);
            return false;
        }
    };

    displayError = (errorState, errors, setStateCallBack) => {
        if (errors.inner) {
            errors.inner.map((err) => {
                let errorMsgs = err.errors;
                let pathRaw = err.path
                    .replace(/]/gi, '')
                    .replace(/\[/gi, '.')
                    .split('.');
                let path = pathRaw[0];
                errorMsgs.map((errMsg) => {
                    errorState[path] = errMsg;
                });
            });
        }
        setStateCallBack(errorState);
    };

    hasError = (key, errorState) => {
        return (
            !!(errorState && Object.keys(errorState).length > 0) &&
            errorState[key] &&
            errorState[key].length
        );
    };

    renderErrorString = (key, errorState) => {
        if (this.hasError(key, errorState)) {
            return errorState[key];
        }
        return null;
    };

    errorStylingCheck = (hasError = false) => {
        return hasError ? 'error-styling' : '';
    };

    disabledStylingCheck = (isDisabled = false) => {
        return isDisabled ? 'disabled-styling' : '';
    };

    render() {
        return (
            <div className={'component-Modals-JobSeekerModal'}>
                <div className="header-component">
                    <FGBLabel className="black-font">
                        {this.props.type}
                    </FGBLabel>
                    <CloseModalIcon />
                </div>
                {this.props.type === 'experience' ? (
                    <JobSeekerExperienceLayout
                        handleSubmit={this.handleSubmit}
                        handleDelete={this.props.onDelete}
                        {...this.props.experience}
                        error={this.props.error}
                        validateForm={this.validateForm}
                        hasError={this.hasError}
                        renderErrorString={this.renderErrorString}
                        errorStylingCheck={this.errorStylingCheck}
                        disabledStylingCheck={this.disabledStylingCheck}
                    />
                ) : (
                    <JobSeekerEducationLayout
                        handleSubmit={this.handleSubmit}
                        handleDelete={this.props.onDelete}
                        {...this.props.education}
                        error={this.props.error}
                        validateForm={this.validateForm}
                        hasError={this.hasError}
                        renderErrorString={this.renderErrorString}
                        errorStylingCheck={this.errorStylingCheck}
                        disabledStylingCheck={this.disabledStylingCheck}
                    />
                )}
            </div>
        );
    }
}
