import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../Input/Button/Button';

import './FBReauthorizationLayout.scss';

export default class FBReauthorizationLayout extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * on click handler
         */
        onClick: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-FBReauthorizationLayout">
                <div className="FGReauthorizationLayout-subText-call-to-action-component">
                    An email is required for registration. Please add the email
                    permission from Facebook and ensure you have a primary
                    contact email address saved in&nbsp;
                    <a
                        target="_blank"
                        href="https://www.facebook.com/help/166986580029611"
                    >
                        General Account Settings
                    </a>
                </div>
                <div>
                    <Button
                        type={'solid'}
                        value={'Resend Permission'}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}
