import React from 'react';

import './EmployerDesktopFooter.scss';
import FGBLogoCopyrightDisplay from '../../Components/FGBLogoCopyrightDisplay/FGBLogoCopyrightDisplay';
import DesktopFooterMenu from '../../Components/DesktopFooterMenu/DesktopFooterMenu';

export default class EmployerDesktopFooter extends React.PureComponent {
    render() {
        return (
            <div className="component-Layout-Footer-DesktopFooter-EmployerDesktopFooter">
                <div className="employer-footer-wrapper">
                    <FGBLogoCopyrightDisplay className="employer-footer-wrapper__logo" />

                    <DesktopFooterMenu
                        footerMenuItems={this.props.footerMenuItems}
                        className="employer-footer-wrapper__menu"
                    />
                </div>
            </div>
        );
    }
}
