import PropTypes from 'prop-types';
import React from 'react';

import GroupItem from '../../Groups/Network/GroupItem/GroupItem';

export default class PendingGroupRequest extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * object of pending group
         */
        pendingGroup: PropTypes.object.isRequired,
        /**
         * object of user
         */
        user: PropTypes.object.isRequired,
        /**
         * string of message
         */
        message: PropTypes.string.isRequired,
        /**
         * function to be called on cta click
         */
        callbackFn: PropTypes.func,
        /**
         * string of custom css classname
         */
        className: PropTypes.string,
        /**
         * enum of cta button states
         */
        clickedAction: PropTypes.oneOf([
            'ACCEPTED',
            'DENIED',
            'JOINED',
            'REQUESTED',
        ]),
    };

    static defaultProps = {
        className: 'network-item group-request',
        callbackFn: () => {},
    };

    render() {
        const {
            pendingGroup,
            user,
            message,
            className,
            callbackFn,
            clickedAction,
        } = this.props;

        return (
            <li className="component-Network-PendingGroupRequest">
                <GroupItem
                    group={pendingGroup}
                    user={user}
                    bubbleMessage={message}
                    groupNetworkType="PENDING"
                    className={className}
                    onClick={callbackFn}
                    clickedAction={clickedAction}
                />
            </li>
        );
    }
}
