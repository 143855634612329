import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../../../Modals/ModalParts/ModalContainer/ModalContainer';
import HubSpotForm from '../../../Form/HubSpot/HubSpotForm';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

import './EmployerClaimForm.scss';

const MARKETO_FORM_PROPS = {
    formId: 1468,
    isHidden: false,
};

export default class EmployerClaimForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static propTypes = {
        closeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    handleCloseModal = () => {
        const { closeModal } = this.props;

        const urlParams = new URLSearchParams(window.location.search);
        const companyId = urlParams.get('id');
        const baseMetric = {
            misc_event_type: 'employer-marketing-popup-closed',
            misc_event_count: 1,
        };

        if (companyId) {
            EmitMetric({ ...baseMetric, company_id: companyId });
        } else {
            EmitMetric(baseMetric);
        }

        closeModal();
    };

    render() {
        return (
            <ModalContainer
                showButton={false}
                closeModal={this.handleCloseModal}
            >
                <div className="component-CompanyPages-Sections-EmployerClaimForm">
                    <HubSpotForm {...MARKETO_FORM_PROPS} />
                </div>
            </ModalContainer>
        );
    }
}
