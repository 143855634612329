import PropTypes from 'prop-types';

/**
 * size of image being used in UserImage component, the value will be a parameter for constructing cloudinary image url
 */
export const userImageSizeProptype = PropTypes.oneOf([
    'xSmall',
    'small',
    'medium',
    'large',
    'xLarge',
    'size24',
    'size40',
    'size56',
    'size72',
    'size84',
    'size136',
    'size150',
    'size160',
]);

export const userImageProptype = PropTypes.shape({
    /**
     * cloudinary image src
     */
    src: PropTypes.string,
    /**
     * image alt, default is generated as "username" + "image"
     */
    alt: PropTypes.string,
});

export const personalHistoryShape = PropTypes.shape({
    /**
     * item id
     */
    id: PropTypes.number.isRequired,
    /**
     * type of personal history
     */
    type: PropTypes.oneOf(['education', 'experience']),
    /**
     * item title
     */
    title: PropTypes.string.isRequired,
    /**
     * name of the organization
     */
    organizationName: PropTypes.string.isRequired,
    /**
     * item location
     */
    location: PropTypes.string.isRequired,
    /**
     * item time
     */
    time: PropTypes.string,
    /**
     * item description
     */
    description: PropTypes.string,
});

export const userProptype = PropTypes.shape({
    /**
     * username
     */
    username: PropTypes.string,
    /**
     * user's image data
     */
    image: userImageProptype,
    /**
     * user ID
     */
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * user location, nullable
     */
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * community related user data
     */
    userCommunityData: PropTypes.shape({
        /**
         * whether user is VIP, currently it could be value of 0 or 1
         */
        isVip: PropTypes.number,
        /**
         * count of connected users
         */
        connectionCount: PropTypes.number,
        /**
         * count of followed users
         */
        followerCount: PropTypes.number,
    }),
    /**
     * relation relative to currently logged in user/session
     */
    relation: PropTypes.shape({
        /**
         * whether user is following by currently logged in user/session
         */
        isFollowing: PropTypes.bool,
        /**
         * connection status
         */
        connectionStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),
    /**
     * user could be highlighted for particular status, such as VIP
     */
    isHighlightedUser: PropTypes.bool,
    /**
     * url of fgb user's profile page
     */
    publicUrl: PropTypes.string,
    /**
     * whether user is a community manager
     */
    isCommunityManager: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /**
     * user's karma score
     */
    karmaScore: PropTypes.string,
});

export const jobOnboardProfilePropType = PropTypes.shape({
    jobTitle: PropTypes.string,
    location: PropTypes.shape({
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    yearsOfExperience: PropTypes.oneOf([
        '0',
        '1-3',
        '4-6',
        '7-10',
        '11-15',
        '16+',
    ]),
});
