import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import Typeahead from '../../Input/Typeahead/Typeahead';
import WithTypeahead from '../../Input/Typeahead/WithTypeahead';

import './CompanyTypeahead.scss';

const CREATE_API = '/api/company/create';
const TYPEAHEAD_API = '/api/company/typeahead';

const getCompanyId = ({ companyId, id }) =>
    companyId ? companyId : id ? id : null;
const getCompanyName = ({ companyName, name }) =>
    companyName ? companyName : name ? name : '';

export class CompanyTypeahead extends React.PureComponent {
    static propTypes = {
        addOption: PropTypes.bool,
        allowAPICreation: PropTypes.bool,
        items: PropTypes.array,
        error: PropTypes.string,
        isMulti: PropTypes.bool,
        placeholder: PropTypes.string,
        resetInput: PropTypes.bool,
        withTags: PropTypes.bool,
        className: PropTypes.string,
        inputId: PropTypes.string,
    };

    static defaultProps = {
        addOption: true,
        allowAPICreation: true,
        items: [],
        error: '',
        isMulti: false,
        placeholder: 'Select one or add a new employer',
        resetInput: false,
        withTags: false,
        className: '',
        inputId: '',
    };

    formatCompanyResult = (company) => {
        /*
            This object formatting is required for backwards compatibility with the company typeahead created for the job review survey
            It requires mapping the same values to multiple keys e.g.
            the company id maps to both companyId and id keys.
            For all other typeaheads, the typeahead response objects should be consistently serialized
            with an id and name key -MsH 02152020
        */
        const companyId = getCompanyId(company);
        const companyName = getCompanyName(company);
        return {
            companyId,
            companyName,
            id: companyId,
            name: companyName,
            option: companyName,
            logo: company.logo,
        };
    };

    render() {
        const { items, isMulti, withTags, inputId } = this.props;
        const selectedSuggestions = items.map((c) =>
            this.formatCompanyResult(c)
        );

        return (
            <div className="component-Input-CompanyTypeahead">
                <Typeahead
                    allowAddNewItem={this.props.addOption && !this.props.error}
                    createNewItemApi={this.props.allowAPICreation && CREATE_API}
                    fetchSuggestionsApi={TYPEAHEAD_API}
                    isMulti={isMulti}
                    onChange={this.props.setTypeaheadInput}
                    onError={this.props.handleError}
                    onSelect={this.props.handleSelect}
                    placeholderText={this.props.placeholder}
                    selectedSuggestions={selectedSuggestions}
                    setUserInput={this.formatCompanyResult}
                    cssClass={this.props.className}
                    withTags={withTags}
                    qaDataAttr={'company'}
                    inputId={inputId}
                />
            </div>
        );
    }
}

export default WithTypeahead(CompanyTypeahead);
