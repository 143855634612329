import PropTypes from 'prop-types';
import React from 'react';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';
import FormCheckboxWithDescription from '../../Components/FormCheckboxWithDescription/FormCheckboxWithDescription';
import './OpenFieldAndDropdownCard.scss';

export default class OpenFieldAndDropdownCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            placeholder:
                props.inputFieldDefaultValue || props.placeholder || null,
            dropdownValue: 'Academics',
            inputValue: props.inputFieldDefaultValue || null,
        };
    }

    static propTypes = {
        //Bool if card has input field
        hasInputField: PropTypes.bool,
        //Bool if card has dropdown
        hasDropdown: PropTypes.bool,
        //Required if there is a dropdown
        dropdownOptions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]).isRequired,
                label: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]).isRequired,
                //Optional: if it has a url,
                url: PropTypes.string,
                //Optional: If it has an image please provide
                img: PropTypes.shape({
                    src: PropTypes.string,
                    alt: PropTypes.string,
                }),
            })
        ),
        //Label text if has input
        inputLabel: PropTypes.string,
        //this happens if someone hits back and it prepopulates
        inputFieldDefaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        // default text if there is a dropdown and you want it to say "select a department" etc.
        dropdownDefaultText: PropTypes.string,
        handleDropdownValue: PropTypes.func,
        handleInputValue: PropTypes.func,
        handleCheckbox: PropTypes.func,
        hasCheckbox: PropTypes.bool,
        checkboxLabel: PropTypes.string,
        prevAnswer: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
    };

    static defaultProps = {
        hasInputField: false,
        hasDropdown: false,
        hasCheckbox: false,
        inputFieldDefaultValue: null,
        handleDropdownValue: () => {},
        handleInputValue: () => {},
    };

    render() {
        return (
            <div className="component-ReviewSurvey-QuestionCards-OpenFieldAndDropdownCard">
                {this.props.hasInputField ? (
                    <div className="input-section">
                        <div className="input-label">
                            {this.props.inputLabel}
                        </div>
                        <input
                            name={'survey-input-field'}
                            value={this.props.inputFieldDefaultValue}
                            placeholder={this.props.placeholder}
                            onChange={(e) =>
                                this.props.handleInputValue(e.target.value)
                            }
                        />
                    </div>
                ) : null}
                {this.props.hasDropdown && this.props.dropdownOptions ? (
                    <BasicDropdown
                        options={this.props.dropdownOptions}
                        defaultLabel={this.props.dropdownDefaultText}
                        onChange={this.props.handleDropdownValue}
                        selectedValue={this.props.prevAnswer}
                    />
                ) : null}
                {this.props.hasCheckbox ? (
                    <FormCheckboxWithDescription
                        onClick={this.props.handleCheckbox}
                        label={this.props.checkboxLabel}
                        defaultChecked={this.props.defaultChecked}
                    />
                ) : null}
            </div>
        );
    }
}
