import Yup from 'yup';

export const loginSchema = () =>
    Yup.object().shape({
        password: Yup.string()
            .min(8, 'invalid_password_length')
            .matches(/(.*[A-Z].*)/, 'password_missing_uppercase')
            .matches(/(.*[a-z].*)/, 'password_missing_lowercase')
            .matches(/(.*\d.*)/, 'password_missing_number')
            .matches(
                /.*[[\-:;\\|<>/ {}=+_!@#$%^&*.,? "'`~)(\]].*/,
                'password_missing_special_character'
            )
            .required('Password is required'),
    });
