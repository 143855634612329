import PropTypes from 'prop-types';
import React from 'react';

import './FGBLogoCopyrightDisplay.scss';

import { getCloudinaryImagePathById } from '../../../../../utilities/images';

const currentYear = new Date().getFullYear();

const injectedTransforms = {
    default: 'w_50',
};

export default class FGBLogoCopyrightDisplay extends React.PureComponent {
    render() {
        const { className } = this.props;

        return (
            <div
                className={`component-Layout-Footer-Components-FGBLogoCopyrightDisplay ${className}`}
            >
                <img
                    alt="icon"
                    className="icon"
                    src={getCloudinaryImagePathById(
                        this.props.src,
                        'default',
                        injectedTransforms
                    )}
                />
                <div className="text">
                    &copy; {currentYear} Fairygodboss. All rights reserved.
                </div>
            </div>
        );
    }
}

FGBLogoCopyrightDisplay.propTypes = {
    src: PropTypes.string,
    /**
     * add on styling to override default styles
     */
    className: PropTypes.string,
};

FGBLogoCopyrightDisplay.defaultProps = {
    src: '/v1554222960/SVGs/FGB_icon_white_dark_purple_circle.png',
    className: '',
};
