import React from 'react';

import Typeahead from '../../../../Input/Typeahead/Typeahead';
import FieldError from '../../Elements/FieldError/FieldError';

export const TYPEAHEAD_API = '/api/job-titles/typeahead';

const JobSelectorInput = ({
    items,
    isMulti,
    withTags,
    inputId,
    error,
    setTypeaheadInput,
    handleError,
    handleSelect,
    placeholder,
    className,
}) => {
    const formatJobResult = (job) => ({
        jobId: job.id,
        jobName: job.name,
        title: job.name,
        id: job.id,
        name: job.name,
        option: job.name,
    });

    const selectedSuggestions = items.map((c) => formatJobResult(c));

    return (
        <div>
            <Typeahead
                allowAddNewItem
                createNewItemApi={false}
                fetchSuggestionsApi={TYPEAHEAD_API}
                isMulti={isMulti}
                onChange={setTypeaheadInput}
                onError={handleError}
                onSelect={handleSelect}
                placeholderText={placeholder}
                selectedSuggestions={selectedSuggestions}
                setUserInput={formatJobResult}
                cssClass={className}
                withTags={withTags}
                qaDataAttr="job-title"
                inputId={inputId}
            />
            <FieldError text={error} />
        </div>
    );
};
export default JobSelectorInput;
