import React from 'react';

const FacebookIcon = ({ width = '32px', height = '32px' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="1" fill="#425893" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4487 5.13332H13.8572V2.81669C13.6146 2.78538 12.7789 2.71423 11.8059 2.71423C9.77503 2.71423 8.38412 3.92093 8.38412 6.13795V8.17852H6.14288V10.7684H8.38412V17.2857H11.1309V10.7684H13.2815L13.6234 8.17852H11.1309V6.39409C11.1309 5.6456 11.3442 5.13332 12.4487 5.13332Z"
            fill="white"
        />
    </svg>
);

export default FacebookIcon;
