import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveTwoRowLayout from '../../Layout/ResponsiveTwoRowLayout/ResponsiveTwoRowLayout';
import NavMenuItem from '../../Modules/NavbarItems/NavMenuItem/NavMenuItem';

import './EmployerNavbar.scss';
import employerNavbarMenu from './employerNavbarMenu';

export default class EmployerNavbar extends React.Component {
    static propTypes = {
        /**
         * data for user dropdown
         */
        userDropdownProps: PropTypes.object,
        /**
         * handler for setting Header's Action state
         */
        setActionState: PropTypes.func.isRequired,
        /**
         * handler for getting Header's Action state
         */
        getActionState: PropTypes.func.isRequired,
        /**
         * new feed items, message count
         */
        badgeCountData: PropTypes.object,
        /**
         * handler for emitting metrics
         */
        handleMetricEvent: PropTypes.func,
        /**
         * alerts
         */
        notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        /**
         * whether user menu has "employer-messages"
         */
        hasEmployerMessageInUserMenu: PropTypes.bool,
        loginWidgetProps: PropTypes.object,
    };

    static defaultProps = {
        badgeCountData: {},
        handleMetricEvent: () => {},
        getActionState: () => {},
        setActionState: () => {},
        userDropdownProps: {},
        notifications: {},
        featureFlags: {},
        hasEmployerMessageInUserMenu: false,
        loginWidgetProps: {},
    };

    render() {
        const {
            handleMetricEvent,
            badgeCountData,
            setActionState,
            getActionState,
            notifications,
            userDropdownProps,
            featureFlags,
            hasEmployerMessageInUserMenu,
            loginWidgetProps,
        } = this.props;

        const { profileImage } = userDropdownProps;

        const {
            auth0,
            currentPath,
            redirectUri,
            invitationToken,
        } = loginWidgetProps;

        const loginModalProps = {
            auth0,
            loginRedirectPath: currentPath,
            redirectUri,
            invitationToken,
        };

        return (
            <ResponsiveTwoRowLayout
                className={`component-Layout-Header-Navbars-EmployerNavbar`}
            >
                {({ viewWidth }) => (
                    <React.Fragment>
                        <ResponsiveTwoRowLayout.TopRow className={`top-row`}>
                            {employerNavbarMenu.map((menuItem, index) => (
                                <NavMenuItem
                                    key={`top-${index}`}
                                    navbarPosition="top"
                                    metaData={menuItem.metaData}
                                    Component={menuItem.Component}
                                    setActionState={setActionState}
                                    getActionState={getActionState}
                                    handleMetricEvent={handleMetricEvent}
                                    viewWidth={viewWidth}
                                    userDropdownProps={userDropdownProps}
                                    userImgSrc={profileImage}
                                    notifications={notifications}
                                    featureFlags={featureFlags}
                                    loginProps={loginModalProps}
                                />
                            ))}
                        </ResponsiveTwoRowLayout.TopRow>

                        <ResponsiveTwoRowLayout.BottomRow
                            className={`bottom-row`}
                        >
                            {employerNavbarMenu.map((menuItem, index) => (
                                <NavMenuItem
                                    key={`bottom-${index}`}
                                    navbarPosition="bottom"
                                    metaData={menuItem.metaData}
                                    Component={menuItem.Component}
                                    setActionState={setActionState}
                                    getActionState={getActionState}
                                    handleMetricEvent={handleMetricEvent}
                                    viewWidth={viewWidth}
                                    userDropdownProps={userDropdownProps}
                                    userImgSrc={profileImage}
                                    notifications={notifications}
                                    featureFlags={featureFlags}
                                    badgeCountData={badgeCountData}
                                    hasEmployerMessageInUserMenu={
                                        hasEmployerMessageInUserMenu
                                    }
                                    loginProps={loginModalProps}
                                />
                            ))}
                        </ResponsiveTwoRowLayout.BottomRow>
                    </React.Fragment>
                )}
            </ResponsiveTwoRowLayout>
        );
    }
}
