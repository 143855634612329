import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../Input/Button/Button';

import './FBReauthorizationConfirmationLayout.scss';

export default class FBReauthorizationConfirmationLayout extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * on click handler
         */
        onClick: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-FBReauthorizationConfirmationLayout">
                <div className="login-subText-FGReauthorization">
                    Your email has been saved. Please log in now.
                </div>
                <div>
                    <Button
                        type={'solid'}
                        value={'Back to login'}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}
