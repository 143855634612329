import React from 'react';
import PropTypes from 'prop-types';
import FormElementWithLabel from '../../Input/FormElementWithLabel/FormElementWithLabel';
import Typeahead from '../../Input/Typeahead/Typeahead';
import WithTypeahead from '../../Input/Typeahead/WithTypeahead';
import './SchoolsTypeahead.scss';

const CREATE_API = '/api/school/create';
const TYPEAHEAD_API = '/api/school/typeahead';

export class SchoolsTypeahead extends React.PureComponent {
    static propTypes = {
        /**
         * can new item be added
         */
        allowAddNewItem: PropTypes.bool,
        /**
         * error message to show
         */
        error: PropTypes.string,
        /**
         * array of skills
         */
        items: PropTypes.array.isRequired,
        /**
         * error handling callback
         */
        onErrorUpdate: PropTypes.func,
        /**
         * function to call when item gets added or removed
         */
        onUpdate: PropTypes.func,
        className: PropTypes.string,
        isRequired: PropTypes.bool,
        /**
         * qa data attribute
         */
        qaDataAttr: PropTypes.string,
    };

    static defaultProps = {
        allowAddNewItem: true,
        error: '',
        onUpdate: () => {},
        onErrorUpdate: () => {},
        isRequired: false,
        className: '',
    };

    render() {
        return (
            <FormElementWithLabel
                fieldLabelProps={{
                    label: 'School',
                    shouldShowTooltip: false,
                    isRequired: this.props.isRequired,
                }}
                elementValue={this.props.typeaheadInput}
                error={this.props.error}
                className={this.props.className}
            >
                <Typeahead
                    allowAddNewItem={
                        this.props.allowAddNewItem && !this.props.error
                    }
                    createNewItemApi={CREATE_API}
                    fetchSuggestionsApi={TYPEAHEAD_API}
                    inputMaxLength={this.props.inputMaxLength}
                    isMulti={false}
                    onChange={this.props.setTypeaheadInput}
                    onError={this.props.handleError}
                    onSelect={this.props.handleSelect}
                    placeholderText={
                        'Start typing to select a school or add a new one'
                    }
                    selectedSuggestions={this.props.items}
                    withTags={false}
                    qaDataAttr={'school'}
                    cssClass={this.props.className}
                />
            </FormElementWithLabel>
        );
    }
}

export default WithTypeahead(SchoolsTypeahead);
