import PropTypes from 'prop-types';
import React from 'react';

import GroupItem from '../Network/GroupItem/GroupItem';

export default class JoinedGroup extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * object of user's joined group
         */
        joinedGroup: PropTypes.object.isRequired,
        /**
         * enum user's relation to the group
         * if owner, don't show leave btn
         */
        userRelation: PropTypes.shape({
            role: PropTypes.oneOf([
                'owner',
                'member',
                'admin',
                'moderator',
                null,
            ]),
        }),
        /**
         * boolean to show cta btn, not rendered on My Network page
         */
        shouldShowCtaButtons: PropTypes.bool,
        /**
         * string of custom classname
         */
        className: PropTypes.string,
        /**
         * function to call after cta btn is clicked
         */
        callbackFn: PropTypes.func,
    };

    static defaultProps = {
        shouldShowCtaButtons: true,
        className: 'network-item joined-groups',
        callbackFn: () => {},
        userRelation: { role: null },
    };

    render() {
        const {
            joinedGroup,
            userRelation,
            shouldShowCtaButtons,
            className,
            callbackFn,
        } = this.props;

        return (
            <li className="component-Groups-JoinedGroup">
                <GroupItem
                    group={joinedGroup}
                    userRelation={userRelation}
                    shouldShowCtaButtons={shouldShowCtaButtons}
                    groupNetworkType="JOINED_GROUPS"
                    className={className}
                    onClick={callbackFn}
                />
            </li>
        );
    }
}
