import axios from 'axios';
import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';
import { validateSingleExperiencePayload } from '../../../Includes/FormSchema/ExperienceSchema';
import { validateSingleEducationPayload } from '../../../Includes/FormSchema/EducationSchema';

import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

const impressionMetric = {
    misc_event_type: 'pdl-modal-view',
    misc_event_count: 1,
};

export const firePdlImpressionMetric = () => EmitMetric(impressionMetric);

export const tagAndCombineEnrichments = (enrichments) => {
    if (!enrichments) return [];
    const { experiences = [], educations = [] } = enrichments;

    const taggedExperiences = experiences.map((exp) => ({
        ...exp,
        type: 'exp',
    }));
    const taggedEducations = educations.map((edu) => ({ ...edu, type: 'edu' }));

    const combined = [
        ...sortCurrentDatesNone(taggedExperiences),
        ...sortCurrentDatesNone(taggedEducations),
    ];

    return combined.map((e, i) => ({ ...e, stepName: `enrichment-${i}` }));
};

export const buildEnrichmentFormState = (enrichments) => {
    if (enrichments == null) return {};

    // stepping through each enrichment in the combined list and
    // checking the type (exp/edu) and building the form state
    // -- each each key is ex. enrichment-0, enrichment-1 that correspond with
    // -- the item at that index in the enrichment list.
    // -- each value is an object that contains the step values + errors
    const formState = enrichments.reduce((state, curr, idx) => {
        const { type, ...rest } = curr;
        const stepKey = `enrichment-${idx}`;

        const stepType =
            type === 'exp' ? ['singleExperience'] : ['singleEducation'];

        const dataKey = stepType[0];

        const stepData = { [dataKey]: rest };

        const defaultStepState = buildInitialFormState(stepType, stepData);

        const cleanStepState = {
            values: defaultStepState[dataKey],
            errors: defaultStepState.errors[dataKey],
        };

        return { ...state, [stepKey]: cleanStepState };
    }, {});

    return formState;
};

export const buildEnrichmentFlow = (options) => {
    const { generalModalProps } = options;
    const {
        enrichments,
        setHistoryByType,
        getCurrentExpEduData,
    } = generalModalProps;

    const validators = {
        experience: validateSingleExperiencePayload,
        education: validateSingleEducationPayload,
    };

    const flowTree = enrichments.reduce((tree, curr, idx, src) => {
        const lastIdx = src.length - 1;

        const nextStepIdx = parseInt(curr.stepName.split('-')[1], 10) + 1;

        const stepKey = curr.stepName;

        const nextStepKey = `enrichment-${nextStepIdx}`;

        if (idx === lastIdx) {
            return { ...tree, [stepKey]: [] };
        }

        return { ...tree, [stepKey]: [nextStepKey] };
    }, {});

    const initialStep = Object.keys(flowTree)[0];

    return {
        flowTree,
        initialStep,
        initialFlowData: {
            validators,
            setHistoryByType,
            getCurrentExpEduData,
            enrichments,
        },
    };
};

const mapIds = (arr) => arr.map((obj) => obj.id);

const getId = (entity) => {
    if (entity.id) return entity.id;
    if (entity.experienceId) return entity.experienceId;
    if (entity.educationId) return entity.educationId;
    return null;
};

const handleAddApiRequest = async (variant, payload) => {
    const apiEndpoint = '/api/user/profile?LAT=pdl-modal&LAC=pdl-modal';
    const isExperience = variant === 'experience';
    const payloadData = isExperience
        ? { experiences: payload }
        : { educations: payload };
    try {
        const response = await axios.patch(apiEndpoint, payloadData);

        const { meta, errors, data } = response.data;

        if (!meta.success) {
            return {
                ok: false,
                updatedIds: null,
                apiErrors: errors,
            };
        }

        return {
            ok: true,
            updatedIds: isExperience
                ? mapIds(data.experiences)
                : mapIds(data.educations),
            apiErrors: null,
        };
    } catch (error) {
        return {
            ok: false,
            updatedIds: null,
            apiErrors: error,
        };
    }
};

export const enrichmentUpdateApi = {
    addEnrichment: async (variant, values, currentList) => {
        const addPayload = [...currentList, values];

        const { ok, updatedIds, apiErrors } = await handleAddApiRequest(
            variant,
            addPayload
        );

        if (ok && updatedIds.length > 0) {
            const currentIds = addPayload
                .filter((item) => getId(item) !== null)
                .map((item) => getId(item));

            const updateId = updatedIds.filter(
                (id) => !currentIds.includes(id)
            )[0];

            const newItems = addPayload.map((item) => {
                const variantIdKey =
                    variant === 'experience' ? 'experienceId' : 'educationId';
                const variantId = { [variantIdKey]: updateId };
                if (item.id === null)
                    return { ...item, id: updateId, ...variantId };

                return {
                    ...item,
                    id: getId(item),
                    [variantIdKey]: getId(item),
                };
            });

            return { ok: true, newItems, apiErrors: null };
        }

        return { ok: false, apiErrors };
    },
    // returns bool indicating skip request success/fail
    skipEnrichment: async (exclusionHash) => {
        const endpoint = '/api/user/enrichments/skip';
        const payload = { exclusionHash };

        try {
            const response = await axios.post(endpoint, payload);
            const { ok } = response.data;
            return ok;
        } catch (error) {
            console.warn('enrichment skip error: ', error);
            return false;
        }
    },
};

function sortDates(arr) {
    return arr.slice().sort((a, b) => {
        const aEnd = getDateFromMonthYear(a.endMonth, a.endYear);
        const bEnd = getDateFromMonthYear(b.endMonth, b.endYear);
        if (aEnd > bEnd) return -1;
        if (aEnd < bEnd) return 1;
        return 0;
    });
}

function getDateFromMonthYear(month = '', year = '') {
    return [year, month].filter(Boolean).join('-');
}

function getCurrent(arr) {
    return arr.some((item) => item.isCurrent)
        ? [arr.find((item) => item.isCurrent)]
        : [];
}

function getItemsWithDates(arr) {
    return arr.filter(
        (item) => getDateFromMonthYear(item.endMonth, item.endYear).length > 0
    );
}

function getItemsWithoutDates(arr) {
    return arr.filter(
        (item) =>
            getDateFromMonthYear(item.endMonth, item.endYear).length === 0 &&
            !item.isCurrent
    );
}

function sortCurrentDatesNone(arr) {
    const current = getCurrent(arr);
    const withDates = getItemsWithDates(arr);
    const noDates = getItemsWithoutDates(arr);

    return [...current, ...sortDates(withDates), ...noDates];
}
