import {
    triggerSnowplowClickEvent,
    triggerSnowplowModalActionEvent,
} from '@themuse/snowplow-js-client';
import { pagesToUse } from './snowPlowPages';

export const SnowplowClickTracker = ({
    component,
    buttonText = null,
    buttonType = null,
    insertType = null,
    cardType = null,
    linkText = null,
    position = 'main',
    modalType = null,
    modalActionType = null,
    errorType = null,
    modalScreenOrder = null,
    modalScreenName = null,
    targetPageSection = null,
    searchId = null,
    isSponsored = false,
    isExternal = false,
    companyId = null,
    pageSection = null,
    pageTab = null,
    pageType = null,
    tileType = null,
    tileEntityId = null,
    tileEntityUuid = null,
    tileOrder = 1,
    tileLocation = null,
    cardEntityId = null,
    cardEntityUuid = null,
    cardOrder = 0,
    cardLocation = null,
    fromCompanyJobsPage = false,
}) => {
    const userId = window.globalPageProps?.PAGE_PROPS?.session?.id;
    const index = window.dataLayer
        ? window.dataLayer.findIndex((obj) => 'sp_page_section' in obj)
        : null;
    const pageData = {
        sp_page_is_external: isExternal,
        sp_page_type: pageType,
        sp_page_section: pageSection,
        sp_page_tab: pageTab,
        sp_user_id: userId,
        sp_user_logged_in: !!userId,
        // populating necessary info for event COMPANY JOBS PAGE
        ...(!fromCompanyJobsPage
            ? {
                  sp_page_sponsor_id: companyId ? String(companyId) : null,
              }
            : {
                  sp_page_page_company_id: companyId ? String(companyId) : null,
              }),
    };
    if (index && index >= 0) {
        window.dataLayer[index] = pageData;
    } else if (index) {
        window.dataLayer.push(pageData);
    }

    const clickEventProps = {
        // CLICK INFO
        sp_click_component: component,
        sp_click_component_tile_type: tileType,
        sp_click_component_card_type: cardType,
        sp_click_insert_type: insertType,
        sp_click_button_type: buttonType,
        sp_click_button_text: buttonText,
        sp_click_link_text: linkText,
        sp_click_position: position,
        sp_click_search_id: searchId,
        sp_click_target_page_section: targetPageSection,
        // TILE CONTENT INFO
        sp_tile_type: tileType,
        sp_tile_entity_id: tileEntityId,
        sp_tile_entity_uuid: tileEntityUuid,
        sp_tile_order: tileOrder,
        sp_tile_location: tileLocation,
        sp_tile_is_sponsored: isSponsored,
        sp_tile_company_id: companyId ? String(companyId) : null,
        sp_impression_modal_type: modalType,
        sp_modal_type: modalType,
        sp_modal_screen_order: modalScreenOrder,
        sp_modal_screen_name: modalScreenName,
        sp_modal_error_type: errorType,
        sp_modal_action_type: modalActionType,
        // unnecessary for COMPANY JOBS PAGE
        ...(!fromCompanyJobsPage && {
            sp_user_id: userId,
            sp_user_logged_in: !!userId,
            sp_card_type: cardType,
            sp_card_entity_id: cardEntityId,
            sp_card_is_sponsored: isSponsored,
            sp_card_entity_uuid: cardEntityUuid,
            sp_card_order: cardOrder,
            sp_card_location: cardLocation,
            sp_card_company_id: companyId ? String(companyId) : null,
        }),
    };
    if (modalType) {
        triggerSnowplowModalActionEvent(clickEventProps);
    } else {
        triggerSnowplowClickEvent(clickEventProps);
    }
};

export const convertMetricToSnowPlowClickTracker = (snowPlow) => {
    const page = window.location.pathname;
    if (!pagesToUse.includes(page)) return null;
    SnowplowClickTracker({
        ...snowPlow,
    });
};
