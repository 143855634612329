import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from '../../../../utilities/helperFunctions';
import { messageTypes } from '../../../Includes/variables';

import { getFormattedTimestamp } from '../../../Utilities/TimeFunctions';
import DefaultIntroMessage from '../../DefaultIntroMessage/DefaultIntroMessage';
import DefaultIntroMessageV2 from '../../DefaultIntroMessage/DefaultIntroMessageV2';
import MessageBlock from '../../MessageBlock/MessageBlock';

const MessageItem = (props) => {
    const {
        message,
        isLoggedInUser,
        getUsersInfoById,
        messageType,
        enableHoverOver,
        index,
        isRecruiterChatCandidate,
        pageDisplayMode,
        candidateUserName,
    } = props;

    if (isEmpty(message) || message.type === 'deleted') return null;

    const {
        id: messageId,
        text,
        user,
        welcomeMessage,
        updated_at: updatedAt,
        type,
    } = message;
    const { id, credential, logo, username } = user;
    const userDisplayName = isLoggedInUser(id) ? 'You' : username;
    const timestamp = getFormattedTimestamp(updatedAt);

    if (welcomeMessage) {
        let messageContent;
        switch (welcomeMessage) {
            case 'v2':
                messageContent = <DefaultIntroMessageV2 />;
                break;
            case 'v1':
            default:
                messageContent = <DefaultIntroMessage />;
                break;
        }

        return (
            <MessageBlock
                key={`megan-welcome-${index}`}
                username="Megan Smolen"
                userTitle="Director of Community at Fairygodboss"
                timeStamp={timestamp}
                messageContent={messageContent}
                src="/v1/user/profile/mbdo0ww7l0s0d8rbch6y"
                user={getUsersInfoById(id)}
                messageId={messageId}
                authorId={user.id}
                enableHoverOver={enableHoverOver}
                messageType={messageType}
                isLoggedInUser={isLoggedInUser}
                isRecruiterChatCandidate={isRecruiterChatCandidate}
                candidateUserName={candidateUserName}
                pageDisplayMode={pageDisplayMode}
            />
        );
    }

    return (
        <MessageBlock
            key={`${messageId}-${index}`}
            username={userDisplayName}
            userTitle={credential}
            timeStamp={timestamp}
            messageContent={text}
            src={logo}
            user={getUsersInfoById(id)}
            messageId={messageId}
            authorId={user.id}
            type={type}
            enableHoverOver={enableHoverOver}
            messageType={messageType}
            isLoggedInUser={isLoggedInUser}
            isRecruiterChatCandidate={isRecruiterChatCandidate}
            candidateUserName={candidateUserName}
            pageDisplayMode={pageDisplayMode}
        />
    );
};

MessageItem.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        user: PropTypes.shape({
            id: PropTypes.number,
            credential: PropTypes.string,
            logo: PropTypes.string,
            username: PropTypes.string,
        }),
        text: PropTypes.string,
        welcomeMessage: PropTypes.string,
        updated_at: PropTypes.string,
    }),
    index: PropTypes.number.isRequired,
    isRecruiterChatCandidate: PropTypes.bool.isRequired,
    /**
     * function passed in to check if user is the current logged in user
     */
    isLoggedInUser: PropTypes.func,
    /**
     * get user meta info with user id
     */
    getUsersInfoById: PropTypes.func,
    /**
     * type of message channel
     */
    messageType: PropTypes.oneOf(Object.values(messageTypes)),
    /**
     * allow hover over
     */
    enableHoverOver: PropTypes.bool,
    /**
     * professionalUsername from channelData, if this is a recruiter chat
     */
    candidateUserName: PropTypes.string,
    pageDisplayMode: PropTypes.string.isRequired,
};

MessageItem.defaultProps = {
    message: {},
    isLoggedInUser: () => {},
    getUsersInfoById: () => {},
    messageType: messageTypes.COMMUNITY,
    enableHoverOver: true,
    candidateUserName: null,
};

export default MessageItem;
