export const degreeDropdownOpts = [
    'High School',
    'Associate Degree',
    "Bachelor's Degree",
    "Master's Degree",
    'Doctorate / PhD',
].map((v) => ({ value: v, label: v }));

export const getStudentText = (isStudent) =>
    isStudent ? 'I am not a student' : 'I am a student';

export const handleToggle = (toggler) => () => toggler((bool) => !bool);

export const quickProfileUpdateTypes = [
    'name',
    'education',
    'experience',
    'dreamCompanies',
    'interest',
];
export const pluralFields = ['education', 'experience', 'interest'];

export const buildQuickProfileUpdatePayload = (payload = {}) =>
    quickProfileUpdateTypes.reduce((acc, field) => {
        const updateFieldName = pluralFields.includes(field)
            ? `${field}s`
            : field;

        const updateTypeData = payload[field];

        if (!updateTypeData) {
            return { ...acc, [updateFieldName]: null };
        }

        if (typeof updateTypeData === 'string')
            return { ...acc, [updateFieldName]: updateTypeData }; // name shouldn't be in an array

        return { ...acc, [updateFieldName]: [updateTypeData] };
    }, {});
