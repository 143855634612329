import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
    GO_SUB_STEP,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';
import './Benefits.scss';

const emptyBenefits = { benefits: null };

export default class Benefits extends React.Component {
    constructor(props) {
        super(props);

        let benefits = this.props.benefits;
        let benefitComment = undefined;

        if (
            this.props.stepsProgressed.includes('12a') ||
            this.props.benefitComment
        ) {
            benefitComment = this.props.benefitComment;
        }

        this.state = {
            responseData: benefits,
            benefitComment: benefitComment,
            isChecked: !!benefitComment,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        buttonData: PropTypes.array,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        showCheckbox: true,
        checkboxLabel: 'Let me explain more',
        buttonType: 'multi',
        buttonData: [
            {
                title: 'Parental leave benefits',
                subtitle: null,
                value: 'parentalLeave',
            },
            {
                title: 'Fertility benefits',
                subtitle: null,
                value: 'fertility',
            },
            {
                title: '401k or pension / retirement benefits',
                subtitle: null,
                value: 'pension',
            },
            {
                title: 'Childcare benefits',
                subtitle: null,
                value: 'childcare',
            },
            {
                title: 'Healthcare benefits',
                subtitle: null,
                value: 'healthcare',
            },
            {
                title: 'None',
                subtitle: null,
                isExclusive: true,
                value: null,
            },
        ],
        onAnswer: () => {},
    };

    componentWillReceiveProps(nextProps) {
        let benefitComment;
        let hasProgressedToComment = this.props.stepsProgressed.includes('12a');

        if (nextProps.benefitComment !== this.props.benefitComment) {
            if (
                nextProps.benefitComment &&
                (this.props.benefitComment === undefined ||
                    nextProps.benefitComment === null)
            ) {
                benefitComment = nextProps.benefitComment;
            }
            if (
                (nextProps.benefitComment === undefined ||
                    nextProps.benefitComment === null) &&
                this.props.benefitComment
            ) {
                benefitComment = this.props.benefitComment;
            }
        }

        if (hasProgressedToComment) {
            benefitComment =
                this.props.benefitComment || nextProps.benefitComment;
        }

        this.setState({
            benefitComment: benefitComment,
        });
    }

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        let nextAction = CAN_SUBMIT;
        let answer = this.props.benefits
            ? { benefits: this.props.benefits }
            : emptyBenefits;

        if (this.state.isChecked) {
            answer.benefitComment = this.state.benefitComment || null;

            if (answer.benefitComment) nextAction = ALLOW_SUB_STEP;
        } else {
            delete answer.benefitComment;
        }

        typeof this.props.benefits !== 'undefined' ||
        this.props.stepsProgressed.includes(this.props.nextStep)
            ? this.props.onAnswer(
                  'benefit',
                  answer,
                  nextAction,
                  false,
                  this.props.nextStep,
                  this.props.subStep
              )
            : null;
    }

    handleUpdate = (data) => {
        const hasItems = data && !!data.length;
        let answer;
        let nextAction = CAN_SUBMIT;

        if (!hasItems) {
            answer = {
                benefits: undefined,
            };
            nextAction = CANT_SUBMIT;
        } else {
            answer = {
                benefits: hasItems && data[0] !== null ? data : null,
            };
        }

        if (this.state.isChecked) {
            answer.benefitComment = this.state.benefitComment || null;

            if (answer.benefitComment) nextAction = GO_SUB_STEP;
            else nextAction = ALLOW_SUB_STEP;
        } else {
            delete answer.benefitComment;
            nextAction = CAN_SUBMIT;
        }

        this.setState({ responseData: answer });

        this.props.onAnswer(
            'benefit',
            answer,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    handleCheckBox = (isChecked) => {
        this.setState({ isChecked }, () =>
            this.handleUpdate(this.state.responseData)
        );
    };

    render() {
        const selectedItems = this.props.benefits
            ? this.props.benefits
            : this.props.stepsProgressed.includes(this.props.nextStep)
            ? null
            : undefined;

        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Benefits">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    handleCheckBox={this.handleCheckBox}
                    selectedItems={selectedItems}
                    isChecked={this.state.isChecked}
                />
            </div>
        );
    }
}
