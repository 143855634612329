import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader';

import { truncateWithEllipsis } from '../../../../utilities/ui_utilities';
import TopicsTypeAheadDropdown from '../../../Community/TopicsTypeAheadDropdown/TopicsTypeAheadDropdown';
import CharCount from '../../../Elements/CharCount/CharCount';
import {
    LinkEditSchema,
    LinkFormSchema,
} from '../../../Includes/FormSchema/ContentSchema';

import Button from '../../../Input/Button/Button';
import InputField from '../../../Input/InputField/InputField';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';

import CheckBox from '../../Elements/CheckBox/CheckBox';
import Thumbnail from '../../Image/Thumbnail/Thumbnail';

import './LinkForm.scss';

const URL_REGEX = new RegExp('(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/\n]+)');
const DESCRIPTION_MAX_COUNT = 5000;

export default class LinkForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const previewProps = {
            previewImage: props?.contentProps?.link?.previewImage?.src || props?.contentProps?.image || '',
            previewTitle: props?.contentProps?.link?.previewTitle || props?.contentProps?.title || '',
            previewDescription: props?.contentProps?.link?.previewDescription || props.contentProps?.description || '',
        };
        
        this.state = {
            description:
                props.contentProps && props.contentProps.comment
                    ? props.contentProps.comment
                    : '',
            anon: !!props.anon,
            isCreating: false,
            hasPostError: false,
            hasPreviewLoaded: true,
            ...previewProps,
            previewHash:
                props.contentProps && props.contentProps.hash
                    ? props.contentProps.hash
                    : '',
            postLocation: props.postLocation || null,
            tokens: [],
            url:
                props.contentProps && props.contentProps.url
                    ? props.contentProps.url
                    : '',
            urlOnFocus: '',
            urlOnBlur: '',
            moderationOptions: props.moderationOptions || null,
            errors: {
                communityTopicId: '',
                url: '',
                description: '',
            },
        };
    }

    static propTypes = {
        /**
         * boolean to determine initial state of isAnonymous
         */

        anon: PropTypes.bool,
        /**
         * array of topics for select dropdown
         */
        topics: PropTypes.array.isRequired,
        /**
         * function for handling post form success
         */
        onSuccess: PropTypes.func.isRequired,
        /**
         * function for rendering errors for UI
         */
        renderErrors: PropTypes.func.isRequired,
        /**
         * function to validate the form fields using Yup
         */
        validateForm: PropTypes.func.isRequired,
        /**
         * function to remove errors from UI when user edits input
         */
        clearError: PropTypes.func.isRequired,
        editMode: PropTypes.bool,
        /**
            generates a label for anonymous checkbox
        */
        getCheckBoxLabel: PropTypes.func.isRequired,
        /**
         * function for retrieving key and value in form submission
         * key can either be communityTopicId or communityGroupHash
         */
        getPostIdKeyValue: PropTypes.func.isRequired,
    };

    static defaultProps = {
        /**
         * isAnonymous defaulted to false
         */
        anon: false,
        editMode: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.postLocation) {
            return {
                postLocation: props.postLocation,
            };
        }
        return null;
    }

    //@TODO Remove this once feature flag is on....
    handleSelect = (postLocation) => {
        this.setState({ postLocation });
        if (this.state.errors.postLocation)
            this.setState(this.props.clearError('postLocation'));
    };

    handleNewSelect = (selection) => {
        const newState = Object.assign({}, this.state);
        newState['postLocation'] = selection;
        newState.errors['oneOrTheOther'] = '';
        this.setState(newState);
    };

    handleInputChange = (key) => (value) => {
        const isUrl = key === 'url';
        const urlValue = isUrl && value ? value.trim() : value;
        this.setState({ [key]: urlValue });
        if (this.state.errors[key]) this.setState(this.props.clearError(key));
    };

    handleCheckboxChange = () =>
        this.setState((previousState) => ({ anon: !previousState.anon }));

    handleUrlFocus = (event) =>
        this.setState({ urlOnFocus: event.target.value });

    handleUrlBlur = async () => {
        if (!this.state.url) {
            this.resetPreview(this.state.url, true, '');
            return;
        }

        // to check if entered url change when user clicks on input,
        // if not, don't show loader and should not re-render preview
        if (this.state.urlOnFocus === this.state.url) return;

        try {
            await LinkFormSchema['url'].validate(this.state.url);
        } catch (error) {
            return this.resetPreview(this.state.url, true, error.message);
        }

        // remove preview when user adds a new link
        this.resetPreview(this.state.url, false, '');

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelPreviousTokens();
        this.setState({ tokens: [...this.state.tokens, source] });

        try {
            const result = await axios.post(
                '/api/community/community-links/preview',
                { url: this.state.url },
                { cancelToken: source.token }
            );
            this.state.tokens.shift();
            const { cloudinaryId, title, description, hash } = result.data.data;

            // setting url on blur here so this value can be used
            // for urlHost in renderUrlPreview as user is changing value
            // instead of just this.state.url
            this.setState((previousState) => ({
                urlOnBlur: previousState.url,
                previewImage: cloudinaryId,
                previewTitle: title,
                previewDescription: description,
                previewHash: hash,
                hasPreviewLoaded: true,
            }));
        } catch (error) {
            this.state.tokens.shift();
            this.resetPreview('', true, 'Oops, this is not a shareable link.');
        }
    };

    resetPreview = (url, hasPreviewLoaded, errorMessage) => {
        this.setState((previousState) => ({
            url,
            hasPreviewLoaded,
            previewImage: '',
            previewTitle: '',
            previewDescription: '',
            previewHash: '',
            errors: {
                ...previousState.errors,
                url: errorMessage,
            },
        }));
    };

    cancelPreviousTokens = () => {
        while (this.state.tokens.length) {
            this.state.tokens[0].cancel('Operation canceled by the user.');
            this.state.tokens.shift();
        }
    };

    handleSubmit = async () => {
        this.setState({ isCreating: true });

        const {
            postLocationKey,
            postLocationValue,
        } = this.props.getPostIdKeyValue(this.state.postLocation);

        const createFormValues = {
            url: this.state.url,
            description: this.state.description,
            anon: this.state.anon,
            previewHash: this.state.previewHash,
            [postLocationKey]: postLocationValue || this.state.postLocation,
        };

        const editFormValues = {
            description: this.state.description,
            anon: this.state.anon,
        };

        const formValues = this.props.editMode
            ? editFormValues
            : createFormValues;
        const schema = this.props.editMode ? LinkEditSchema : LinkFormSchema;
        try {
            await this.props.validateForm(formValues, schema);
            this.props.editMode
                ? await this.props.onEditSubmit(
                      'community-link',
                      editFormValues,
                      this.props.contentProps.hash
                  )
                : await this.submitAPICall(createFormValues);
        } catch (errors) {
            return this.setState(this.props.renderErrors(errors));
        }
    };

    submitAPICall = async (formValues) => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        try {
            const result = await axios.post(
                '/api/community/community-links/create',
                formValues
            );
            this.setState({ isCreating: false });
            this.props.onSuccess(
                postocationId,
                `community-link-${result.data.data.hash}`
            );
        } catch (error) {
            this.setState({
                hasPostError:
                    'There was an error publishing your post. Please try sharing again.',
                isCreating: false,
            });
        }
    };

    renderUrlPreview = () => {
        const urlHost = this.state.urlOnBlur
            ? this.state.urlOnBlur.match(URL_REGEX)[1]
            : '';

        return (
            <div className="url-preview">
                {this.state.previewImage && (
                    <Thumbnail
                        cloudinaryID={this.state.previewImage}
                        alt={this.state.previewTitle}
                        className="link-image"
                    />
                )}

                <div className={'preview-title'}>
                    {truncateWithEllipsis(this.state.previewTitle, 63)}
                </div>
                <a
                    className="link-url mt-2"
                    href={this.state.urlOnBlur}
                    target="_blank"
                >
                    {urlHost}
                </a>
            </div>
        );
    };

    hasPostLocation = () => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        return !!(postocationId && postocationId.toString().length);
    };

    hasDescription = () =>
        !!(this.state.description && this.state.description.length);

    hasUrl = () => !!(this.state.url && this.state.url.length);

    allFieldFilledOut = () =>
        !(this.hasPostLocation() && this.hasDescription() && this.hasUrl());

    setEmptyError = () => {
        this.setState({
            errors: {
                oneOrTheOther: this.hasPostLocation()
                    ? ''
                    : 'Please choose where to post to.',
                url: this.hasUrl() ? '' : 'This field is required.',
                description: this.hasDescription()
                    ? ''
                    : 'This field is required.',
            },
        });
    };

    render() {
        const { url } = this.state;
        const checkboxLabel = this.props.getCheckBoxLabel(
            this.props.editMode,
            this.state.anon
        );
        const checkbox = (
            <CheckBox
                qaDataAttr="checkBoxLabel"
                id="ContentTempID"
                className="anonymous-checkbox"
                defaultChecked={this.state.anon}
                label={checkboxLabel}
                handleChange={this.handleCheckboxChange}
            />
        );

        const editableFields =
            this.state.moderationOptions &&
            this.state.moderationOptions.editableFields;

        return (
            <div className="component-Content-CreateContentWidgetV2-LinkForm">
                <div className="form">
                    <span className="error-message">
                        {this.state.hasPostError}
                    </span>
                    <span className="section-label">
                        {this.props.editMode ? 'Posted to:' : 'Post to:*'}
                    </span>
                    <TopicsTypeAheadDropdown
                        selectCallback={this.handleNewSelect}
                        selection={this.state.postLocation}
                        disabled={this.props.editMode}
                    />
                    <span className="error-message">
                        {this.state.errors.oneOrTheOther}
                    </span>
                    <span className="section-label">Web or Video URL*</span>
                    {!this.props.editMode && (
                        <InputField
                            name="url"
                            classname="url-input"
                            value={url}
                            onChange={this.handleInputChange('url')}
                            onBlur={this.handleUrlBlur}
                            onFocus={this.handleUrlFocus}
                            disabled={this.props.editMode}
                            placeholder={'https://example.com'}
                        />
                    )}
                    <Loader loaded={this.state.hasPreviewLoaded} />
                    {this.state.previewTitle && this.renderUrlPreview()}
                    <span className="error-message">
                        {this.state.errors.url}
                    </span>

                    <div className="details-heading-row">
                        <span className="section-label">The details*</span>
                        {this.state.description.length ? (
                            <CharCount
                                length={this.state.description.length}
                                limit={DESCRIPTION_MAX_COUNT}
                            />
                        ) : null}
                    </div>
                    <TextAreaInput
                        name="details"
                        placeholder="What's on your mind?"
                        class="text-area-input description"
                        rows={3}
                        disabled={
                            this.props.editMode &&
                            !(
                                editableFields &&
                                editableFields.includes('description')
                            )
                        }
                        value={this.state.description}
                        onChange={this.handleInputChange('description')}
                    />
                    <span className="error-message">
                        {this.state.errors.description}
                    </span>
                </div>

                {checkbox}

                <Button
                    value={this.props.editMode ? 'Publish' : 'Post now'}
                    onClick={
                        this.allFieldFilledOut()
                            ? this.setEmptyError
                            : this.handleSubmit
                    }
                    type={'purple-medium-white'}
                    class="post-now-button"
                    inputStyle="post-now-button"
                    disabled={this.state.isCreating}
                />
            </div>
        );
    }
}
