import React from 'react';
import { memberTypeProptype } from '../../../../Includes/GroupPropsShape';

import './MemberTypeBadge.scss';

export default class MemberTypeBadge extends React.PureComponent {
    static propTypes = {
        /**
         * role of group member
         */
        groupMemberType: memberTypeProptype,
    };

    static defaultProps = {
        groupMemberType: 'member',
    };

    render() {
        const { groupMemberType } = this.props;
        switch (groupMemberType) {
            case 'owner':
            case 'admin':
                return (
                    <div className="component-Users-UserIconList-UserIcon-MemberTypeBadge">
                        <span className="badge admin">A</span>
                    </div>
                );
            case 'moderator':
                return (
                    <div className="component-Users-UserIconList-UserIcon-MemberTypeBadge">
                        <span className="badge moderator">M</span>
                    </div>
                );
            default:
                return null;
        }
    }
}
