/* eslint-disable no-await-in-loop */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import DeepMerge from 'deepmerge';
import { StreamChat } from 'stream-chat';
import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import {
    COMMUNITY_USER_ID,
    messageTypes,
} from '../components/Includes/variables';
import { PAGE_PROPS } from '../pages/defaultProps';
import { throttle } from '../utilities/helperFunctions';
import { API_URL_GET_USER_RELATIONS } from './AbstractContext';
import { MessageContext as Context } from './ContextCreator';
import sentryClient from '../utilities/Sentry';

const CHANNEL_LIMIT = 25;
const LOAD_MORE_MESSAGES_LIMIT = 25;

export class MessageProvider extends React.Component {
    constructor(props) {
        super(props);

        this.sentry = sentryClient(props.PAGE_PROPS.sentry);
        this._markReadThrottled = throttle(this.markRead, 500, {
            leading: true,
            trailing: true,
        });
        this._keystrokeThrottled = throttle(this.keystroke, 200, {
            leading: true,
            trailing: true,
        });
        this.state = {
            /**
             * stream chat client
             */
            chatClient: null,
            /**
             * all active channels grouped by message types
             */
            channelsByType: {
                'group-chat-room': {},
                'community-user-message': {},
                'recruiter-chat-room': {},
            },
            /**
             * to display loading shine before chatClient has been initialized
             */
            initialLoad: true,
            /**
             * current channel
             */
            channelPage: {
                'group-chat-room': 1,
                'community-user-message': 1,
                'recruiter-chat-room': 1,
            },
            /**
             * total unread message count for all active channels
             */
            unreadCount: {
                'group-chat-room': 0,
                'community-user-message': 0,
                'recruiter-chat-room': 0,
            },
            /**
             * information of current channel (the channel user is current messaging)
             */
            currentChannel: null,
            /**
             * current selected message type
             */
            selectedMessageType: props.selectedMessageType,
            /**
             * get all supported message types of current context
             */
            getMessageTypes: props.messageTypes,
            /**
             * return message type of current channel
             */
            getCurrentMessageType: this.getCurrentMessageType,
            /**
             * get all active channels detail info
             */
            getDisplayChannels: this.getDisplayChannels,
            /**
             * get the current channel information
             */
            getCurrentChannelInfo: this.getCurrentChannelInfo,
            /**
             * to create a message channel with an user
             */
            createChannel: this.createChannel,
            /**
             * set the selected channel as current channel
             */
            setCurrentChannel: this.setCurrentChannel,
            /**
             * on typing event handler
             */
            onTyping: this.onTyping,
            /**
             * send message to current channel with text store in current channel state
             */
            sendMessage: this.sendMessage,
            /**
             * function to paginate through channels
             */
            loadMoreChannels: this.loadMoreChannels,
            /**
             * function to fetch more messages for current channel, for channel message pagination
             */
            loadMoreMessagesForCurrentChannel: this
                .loadMoreMessagesForCurrentChannel,
            /**
             * total unread message count for all channels together
             */
            getUnreadCount: () => this.state.unreadCount,
            /**
             * function to check if given user is the logged in user
             */
            isLoggedInUser: this.isLoggedInUser,
            /**
             * companies info, belongs to the creator of the channel
             */
            companiesInfo: {},
            /**
             * groups info for group chat
             */
            groupsInfo: {},
            /**
             * detail info of all users in all channels, including relation data
             */
            usersInfo: {},
            /**
             * whether message drawer should be open or not
             */
            isMessageDrawerOpen: false,
            /**
             * open message drawer trigger
             */
            openMessageDrawer: this.openMessageDrawer,
            /**
             * close message drawer trigger
             */
            closeMessageDrawer: this.closeMessageDrawer,
            /**
             * used to prevent duplicate load more event
             */
            loadingMoreMessages: false,
            /**
             * used to prevent duplicate load more event
             */
            loadingMoreChannels: false,
            /*
             * if there're more channels
             */
            hasMoreChannels: false,
            /**
             * function that return the status whether there's more message for current channel
             */
            getHasMoreMessages: () =>
                this.state.currentChannel
                    ? this.state.currentChannel.hasMoreMessages
                    : false,
            /**
             * function that return the status whether there's more channels to fetch
             */
            getHasMoreChannels: () => this.state.hasMoreChannels,
            /**
             * function that return the status if we are still loading more messages
             */
            getLoadingMoreMessages: () => this.state.loadingMoreMessages,
            /**
             * function that return the status if we are still loading more channels
             */
            getLoadingMoreChannels: () => this.state.loadingMoreChannels,
            /**
             * automatically initiate chat on Provider instantiation
             */
            autoInitChat: this.props.autoInitChat,
            /**
             * function returns current selected message type
             */
            getSelectedType: () => this.state.selectedMessageType,
            /**
             * function to toggle message type
             */
            setMessageType: this.setMessageType,
            /**
             * function return a group hash from a group chat channel key, if not a group chat, returns null
             */
            getGroupHash: this.getGroupHash,
            /**
             * function to delete message by message id
             */
            deleteMessage: this.deleteMessage,
            /**
             * function to delete a selected message
             */
            getChannelInfoByKey: this.getChannelInfoByKey,
            /**
             * function to get user information store in state
             */
            getUsersInfoById: (id) => this.state.usersInfo[id],
            /**
             * function to initialize for group chat
             */
            initGroupChat: this.initGroupChat,
            /**
             * function to determine if user is the candidate in the chat
             */
            isRecruiterChatCandidate: this.isRecruiterChatCandidate,
        };
    }

    async componentDidMount() {
        if (this.state.autoInitChat) {
            await this.initChatClient();
            if (this.props.allowNewChannelCreation) {
                await this.createChannel(this.props.selectedMessageType);
            } else {
                await this.setCurrentChannel();
            }
        } else {
            this.setState({ initialLoad: false });
        }
    }

    initChatClient = async () => {
        this.setState({ initialLoad: true });
        if (
            !this.props.PAGE_PROPS.session ||
            !this.props.PAGE_PROPS.session.id
        ) {
            this.setState({ initialLoad: false });
            return;
        }

        const chatClient = new StreamChat(this.props.PAGE_PROPS.chatApiKey, {
            timeout: 10000,
        });
        const {
            id,
            username,
            name,
            logo,
            credential,
            jobTitle,
        } = this.props.PAGE_PROPS.session;

        await chatClient.setUser(
            {
                id: `${id}`,
                username: name || username,
                logo,
                credential,
                jobTitle,
                active: 1,
                role: 'user',
            },
            this.props.PAGE_PROPS.chatToken
        );
        return new Promise((resolve) => {
            try {
                this.setState({ chatClient }, async () => {
                    await this.setupClientEventListeners();
                    await this.fetchAllActiveChannels();
                    this.setState(
                        {
                            unreadCount: this.getTotalUnreadCounts(),
                            initialLoad: false,
                        },
                        () => {
                            resolve(true);
                        }
                    );
                });
            } catch (e) {
                this.setState({ initialLoad: false });
                resolve(true);
            }
        });
    };

    setupClientEventListeners = async () => {
        return new Promise((resolve) => {
            this.state.chatClient.on(async (event) => {
                /**
                 * upon receiving a new message from a channel not on the list, add it to the list
                 */
                if (event.type === 'notification.message_new') {
                    return this.onNewMessageFromNewChannelEvent(event);
                }

                /**
                 * user is being removed to a channel from client(backend), remove that channel from the list in the state
                 */
                if (event.type === 'notification.removed_from_channel') {
                    return this.removeChannel(
                        event.channel.id,
                        event.channel.type
                    );
                }

                /**
                 * user is being added to a channel from client(backend), add that channel to the list in the state
                 */
                if (event.type === 'notification.added_to_channel') {
                    return this.addNewChannel(
                        event.channel.id,
                        event.channel.type
                    );
                }

                // TODO: check the channel message type, update proper channel list
                /**
                 * if a channel is marked inactive from backend, remove it from the channel list
                 * if removed channel was the current channel, and setNextChannelAsCurrent is true, set the next available channel as the current channel
                 */
                const activeChannels = this.state.channelsByType[
                    this.state.selectedMessageType
                ];
                if (
                    event.type === 'channel.updated' &&
                    activeChannels[event.channel.id]
                ) {
                    if (!event.channel.active) {
                        return this.removeChannel(
                            event.channel.id,
                            event.channel.type
                        );
                    }
                    if (event.channel.active) {
                        return this.addNewChannel(
                            event.channel.id,
                            event.channel.type
                        );
                    }
                }

                // TODO: remove this once stream fixed the inconsistency in channel.state.message.members
                if (event.type === 'user.updated') {
                    //  return this.fetchAllActiveChannels();
                }
            });
            resolve(true);
        });
    };

    /**
     * query channels detail from chat client with pagination
     * watch all channels, and setup channel event listeners for each channel
     * @param {object} filter
     * @param {number} offset
     * @returns {Promise<array>}
     */
    queryChannelsByFilter = async (filter, offset = 0) => {
        const { sort } = this.props;
        let channels = [];

        try {
            channels = await this.state.chatClient.queryChannels(filter, sort, {
                watch: true,
                state: true,
                presence: true,
                limit: CHANNEL_LIMIT,
                offset,
            });
        } catch (e) {
            this.sentry.captureException(e);
        }

        const channelEventListenerPromises = channels.map((channel) =>
            this.setupChannelEventListeners(channel)
        );

        await Promise.all(channelEventListenerPromises);

        const channelsMap = {};
        channels.forEach((channel) => {
            channelsMap[channel.id] = this.formatChannel(channel);
        });

        return channelsMap;
    };

    formatChannel = (channel) => ({
        channel,
        active: this.validateChannelStatus(channel.state.members, channel.type),
        metaData: null, // message meta data to support sending messages other than text, eg: job card, this is different from channel meta data as channelData
        text: '',
        typing: {},
        unread: channel.countUnread(),
    });

    fetchCompaniesInfoForChannels = async (channels) => {
        const newCompanyIds = new Set();
        const keys = Object.keys(channels);

        for (let i = 0; i < keys.length; i += 1) {
            const channelKey = keys[i];
            const { channel } = channels[channelKey];
            const companyIds = channel?.data?.companyIds || [];

            if (channel.data.metaData && channel.data.metaData.companyId) {
                companyIds.push(channel.data.metaData.companyId);
            }

            companyIds.forEach((id) => {
                const companyId = Number(id);
                if (
                    !Object.prototype.hasOwnProperty.call(
                        this.state.companiesInfo,
                        companyId
                    )
                ) {
                    newCompanyIds.add(companyId);
                }
            });
        }

        return this.fetchCompanyInfoByIds([...newCompanyIds]);
    };

    fetchGroupInfoForChannels = async (channels) => {
        const groupHashes = Object.keys(channels).map((channelKey) =>
            this.getGroupHash(channelKey)
        );
        if (groupHashes.length === 0) {
            return;
        }
        try {
            const res = await axios.post('/api/groups/info', {
                hashes: groupHashes,
            });
            return res.data;
        } catch (e) {
            this.sentry.captureException(e);
            return {};
        }
    };

    /**
     * Fetches User relations by ids
     * @param {array<string>} userIds
     * @returns {Promise<null>}
     */
    fetchUserRelations = async (userIds) => {
        if (userIds.length === 0) {
            return;
        }
        try {
            const result = await axios.post(API_URL_GET_USER_RELATIONS, {
                userIds,
            });
            const usersInfo = {};
            result.data.data.forEach((user) => {
                usersInfo[user.userId] = user;
            });
            return usersInfo;
        } catch (e) {
            this.sentry.captureException(e);
            return null;
        }
    };

    fetchJobProfileAttributions = async (hashes) => {
        if (hashes.length === 0) {
            return;
        }

        const API_JOB_PROFILE_ATTRIBUTION =
            '/api/job-seeker/profile-attribution';

        try {
            const result = await axios.post(API_JOB_PROFILE_ATTRIBUTION, {
                hashes,
            });
            const jobProfileInfo = {};
            result.data.data.forEach((profile) => {
                jobProfileInfo[profile.userId] = {
                    professionalImage: profile.image,
                    professionalUsername: `${profile.firstName} ${profile.lastName}`,
                    jobProfileUrl: `/users/job-profile/${profile.profileHash}`,
                };
            });

            return jobProfileInfo;
        } catch (e) {
            this.sentry.captureException(e);
            return null;
        }
    };

    fetchUserRelationsForChannels = async (channels) => {
        const userIds = new Set();
        const jobProfileHashes = new Set();

        Object.keys(channels).forEach((channelKey) => {
            const { channel } = channels[channelKey];

            Object.keys(channel.state.members).forEach((memberKey) => {
                if (
                    channel.state.members[memberKey] &&
                    channel.state.members[memberKey].user.id
                ) {
                    userIds.add(
                        Number(channel.state.members[memberKey].user.id)
                    );
                }
            });
            const { messages } = channel.state;

            messages.forEach((message) => {
                if (message.user.id) {
                    userIds.add(Number(message.user.id));
                }
            });

            if (channel.type === messageTypes.EMPLOYER) {
                const jobProfilehash = this.getChannelEntityHash(
                    messageTypes.EMPLOYER,
                    channel.id
                );
                if (jobProfilehash) {
                    jobProfileHashes.add(jobProfilehash);
                }
            }
        });

        const [jobProfileAttributions, userRelations] = await Promise.all([
            this.fetchJobProfileAttributions([...jobProfileHashes]),
            this.fetchUserRelations([...userIds]),
        ]);

        return DeepMerge(userRelations || {}, jobProfileAttributions || {});
    };

    /**
     * fetch all active channel info
     * @returns {*}
     */
    fetchAllActiveChannels = async () => {
        const stateChannelsByType = this.state.channelsByType;
        const channelsByType = { ...stateChannelsByType };
        let newCompaniesInfo;
        let groupsInfo;
        let { usersInfo } = this.state;

        for (let i = 0; i < this.props.messageTypes.length; i += 1) {
            const messageType = this.props.messageTypes[i];

            if (this.props.channelKeysByType[messageType].length > 0) {
                const offset =
                    (this.state.channelPage[messageType] - 1) * CHANNEL_LIMIT;
                const channels = await this.queryChannelsByFilter(
                    this.getChannelFilter(messageType),
                    offset
                );

                if (
                    [messageTypes.COMMUNITY, messageTypes.EMPLOYER].includes(
                        messageType
                    )
                ) {
                    newCompaniesInfo = await this.fetchCompaniesInfoForChannels(
                        channels
                    );
                }

                if (messageType === messageTypes.GROUP) {
                    groupsInfo = await this.fetchGroupInfoForChannels(channels);
                }

                const newUsersInfo = await this.fetchUserRelationsForChannels(
                    channels
                );
                usersInfo = { ...usersInfo, ...newUsersInfo };

                channelsByType[messageType] = channels;
            }
        }

        const stateCompaniesInfo = this.state.companiesInfo;
        const stateGroupsInfo = this.state.groupsInfo;
        const stateUsersInfo = this.state.usersInfo;
        const stateSelectedMessageType = this.state.selectedMessageType;
        return new Promise((resolve) => {
            this.setState(
                {
                    channelsByType,
                    companiesInfo: {
                        ...stateCompaniesInfo,
                        ...newCompaniesInfo,
                    },
                    groupsInfo: { ...stateGroupsInfo, ...groupsInfo },
                    usersInfo: { ...stateUsersInfo, ...usersInfo },
                    hasMoreChannels:
                        Object.keys(channelsByType[stateSelectedMessageType])
                            .length === CHANNEL_LIMIT,
                },
                resolve(true)
            );
        });
    };

    /**
     * fetch companies info by company Ids, for recruiter company attribution
     * @param companyIds
     * @returns {Promise<*>}
     */
    fetchCompanyInfoByIds = async (companyIds) => {
        if (companyIds.length === 0) {
            return;
        }
        try {
            const res = await axios.post('/api/company/company-info', {
                companyIds,
            });
            return res.data;
        } catch (e) {
            this.sentry.captureException(e);
            return {};
        }
    };

    getTotalUnreadCounts = () => {
        const unreadCounts = {};
        const keys = Object.keys(this.state.channelsByType);

        for (let i = 0; i < keys.length; i += 1) {
            const messageType = keys[i];

            let sum = 0;
            Object.keys(this.state.channelsByType[messageType]).forEach(
                (channelKey) => {
                    const channelInfo = this.state.channelsByType[messageType][
                        channelKey
                    ];
                    if (channelInfo) {
                        sum += channelInfo.channel.countUnread();
                    }
                }
            );
            unreadCounts[messageType] = sum;
        }

        return unreadCounts;
    };

    /**
     * set up channel event listeners
     * @param channel
     * @returns {Promise<void>}
     */
    setupChannelEventListeners = async (channel) => {
        channel.on('message.new', (event) => {
            return this.onNewMessageEvent(event);
        });

        channel.on('message.read', (event) => {
            return this.onMarkRead(event);
        });

        channel.on('member.added', (event) => {
            return this.onMemberAdded(event);
        });

        channel.on('message.deleted', (event) => {
            return this.onMessageDeleted(event);
        });

        channel.on('typing.start', (event) => {
            return this.onTypingStartEvent(event);
        });

        channel.on('typing.stop', (event) => {
            return this.onTypingStopEvent(event);
        });
    };

    // ============== Event Dispatchers ============== //

    // =========== event helpers ==================
    addNewChannel = async (channelKey, messageType) => {
        const stateCompaniesInfo = this.state.companiesInfo;
        const stateGroupsInfo = this.state.groupsInfo;
        const stateUsersInfo = this.state.usersInfo;
        const stateChannelsByType = this.state.channelsByType;

        if (this.props.messageTypes.indexOf(messageType) < 0) {
            return;
        }

        const rawChannel = await this.state.chatClient.channel(
            messageType,
            channelKey
        );

        if (rawChannel) {
            await rawChannel.watch({ presence: true });
            await this.setupChannelEventListeners(rawChannel);
            const channel = this.formatChannel(rawChannel);
            const channelObj = {};
            channelObj[channelKey] = channel;

            const newCompaniesInfo = [
                messageTypes.COMMUNITY,
                messageTypes.EMPLOYER,
            ].includes(messageType)
                ? await this.fetchCompaniesInfoForChannels(channelObj)
                : {};
            const groupsInfo =
                messageType === messageTypes.GROUP
                    ? await this.fetchGroupInfoForChannels(channelObj)
                    : {};
            const usersInfo = await this.fetchUserRelationsForChannels(
                channelObj
            );

            const channelsByType = { ...stateChannelsByType };
            channelsByType[messageType][channel.channel.id] = channel;

            this.setState({
                channelsByType,
                companiesInfo: {
                    ...stateCompaniesInfo,
                    ...newCompaniesInfo,
                },
                groupsInfo: { ...stateGroupsInfo, ...groupsInfo },
                usersInfo: { ...stateUsersInfo, ...usersInfo },
                unreadCount: this.getTotalUnreadCounts(),
            });

            if (
                !this.state.currentChannel &&
                this.state.selectedMessageType === messageType
            ) {
                await this.setCurrentChannel();
            }
        }
    };

    removeChannel = async (channelKey, messageType) => {
        const stateChannelsByType = this.state.channelsByType;
        const channelsByType = { ...stateChannelsByType };
        // stop watching removed channel
        await channelsByType[messageType][channelKey].channel.stopWatching();
        delete channelsByType[messageType][channelKey];

        if (
            this.state.currentChannel &&
            channelKey === this.state.currentChannel.key
        ) {
            if (
                this.props.setNextChannelAsCurrent &&
                Object.keys(channelsByType[messageType]).length > 0
            ) {
                const newCurrentKey = Object.keys(
                    channelsByType[messageType]
                )[0];
                if (newCurrentKey) {
                    await this.setCurrentChannel(newCurrentKey);
                }
            } else if (this.state.currentChannel) {
                // if we removing the only channel on the list, set the current channel to null
                this.setState({ currentChannel: null });
            }
        }

        this.setState({
            channelsByType,
            unreadCount: this.getTotalUnreadCounts(),
        });
    };

    // ============= channel events ===============

    onNewMessageEvent = async (event) => {
        const [type, key] = event.cid.split(':');

        // new message event is received for new channel not on the list yet, add it to the channel list
        if (!this.state.channelsByType[type][key]) {
            return this.addNewChannel(key, type);
        }

        const { channel } = this.state.channelsByType[type][key];
        const newChannelData = {};

        const newState = { ...this.state };

        // if user's current channel is getting new messages, append the new message
        if (
            this.state.currentChannel &&
            this.state.currentChannel.key === key
        ) {
            newState.currentChannel = {
                ...this.state.currentChannel,
                messages: this.state.currentChannel.channel.state.messages.slice(
                    0
                ),
            };
        }

        // if user's current channel is getting new message which is not sent by herself, mark it as read
        if (
            !this.isLoggedInUser(event.user.id) &&
            this.state.currentChannel.key === key
        ) {
            this._markReadThrottled(channel);
        }

        // if not logged-in user, and not the current channel receiving new message
        if (
            !this.isLoggedInUser(event.user.id) &&
            this.state.currentChannel.key !== key
        ) {
            const channelsByType = { ...this.state.channelsByType };
            newChannelData.unread = channel.countUnread();

            channelsByType[type][key] = {
                ...channelsByType[type][key],
                ...newChannelData,
            };

            newState.unreadCount = this.getTotalUnreadCounts();
            newState.channelsByType = channelsByType;
        }

        this.setState(newState);
    };

    markRead = (channel) => {
        if (!channel.getConfig().read_events) {
            return;
        }
        channel.markRead();

        if (channel.type === messageTypes.EMPLOYER) {
            this.handleChatMetrics(channel, 'read-message');
        }
    };

    onMarkRead = async (event) => {
        if (!this.isLoggedInUser(event.user.id)) {
            return;
        }
        const [type, key] = event.cid.split(':');
        this.updateChannelByKey({ unread: 0 }, key, type);
        this.setState({ unreadCount: this.getTotalUnreadCounts() });
    };

    onMemberAdded = async (event) => {
        const { usersInfo } = this.state;
        const userId = Number(event.member.user.id);
        const newUsersInfo = await this.fetchUserRelations([userId]);
        this.setState({ usersInfo: { ...usersInfo, ...newUsersInfo } });
    };

    onMessageDeleted = async (event) => {
        const channelKey = event.cid.split(':')[1];
        const messages = this.state.channelsByType['group-chat-room'][
            channelKey
        ].channel.state.messages.filter(
            (message) => message.type !== 'deleted'
        );
        this.updateChannelByKey({ messages }, channelKey, 'group-chat-room');
    };

    /**
     * on 1:1 user messaging, we want typing indicator to show if the messenger is typing
     * do nothing for other type of chat
     * @param event
     * @returns {Promise<void>}
     */
    onTypingStartEvent = async (event) => {
        const [type, key] = event.cid.split(':');

        // if typing event happening on a new channel not on the channel list yet, do nothing
        if (!this.state.channelsByType[type][key]) {
            return;
        }

        if (
            !this.state.channelsByType[type][key].channel.state.members[
                event.user.id
            ]
        ) {
            return;
        }

        const typing = { ...this.state.channelsByType[type][key].typing };
        typing[event.user.id] = event.user;
        this.updateChannelByKey({ typing }, key, type);
    };

    onTypingStopEvent = async (event) => {
        const [type, key] = event.cid.split(':');

        // if typing event happening on a new channel not on the channel list yet, do nothing
        if (!this.state.channelsByType[this.state.selectedMessageType][key]) {
            return;
        }

        const typing = {
            ...this.state.channelsByType[this.state.selectedMessageType][key]
                .typing,
        };
        delete typing[event.user.id];

        this.updateChannelByKey({ typing }, key, type);
    };

    // ============= client events =============

    /**
     * if someone messages you from the channels you're not watching,
     * validate the channel type, query the new channel, watch it
     * if there no previous channel on the list, add it the the list, make it the current channel
     * else, just add it to the list
     *
     * @param event
     * @returns {Promise<void>}
     */
    onNewMessageFromNewChannelEvent = async (event) => {
        return this.addNewChannel(event.channel.id, event.channel.type);
    };

    // ============ End of Event Dispatchers =========== //

    /**
     *  the the selected channel as current channel,
     *  by default, first channel is the current channel
     *  if selected channel is not the first channel, move it to the top
     *  fire channel event to mark channel as read
     * @param channelKey
     * @param selectedMessageType
     */
    setCurrentChannel = async (
        channelKey,
        selectedMessageType = this.state.selectedMessageType
    ) => {
        // if selected channel if the current channel, just return
        if (
            this.state.currentChannel &&
            this.state.currentChannel.key === channelKey
        ) {
            return;
        }
        const activeChannels = this.state.channelsByType[selectedMessageType];

        if (Object.keys(activeChannels).length === 0) {
            this.setState({ currentChannel: null });
            return;
        }

        // setting default current channel
        if (
            !channelKey &&
            Object.keys(activeChannels).length > 0 &&
            (this.props.initialChannel.channelKey ||
                this.props.initialChannel.useFirstChannel)
        ) {
            const key =
                this.props.initialChannel.channelKey &&
                activeChannels[this.props.initialChannel.channelKey]
                    ? this.props.initialChannel.channelKey
                    : Object.keys(activeChannels)[0];

            this.setState(
                {
                    currentChannel: {
                        key,
                        channel: activeChannels[key].channel,
                        messages: activeChannels[
                            key
                        ].channel.state.messages.slice(0),
                        hasMoreMessages:
                            activeChannels[key].channel.state.messages
                                .length === LOAD_MORE_MESSAGES_LIMIT,
                    },
                    selectedMessageType,
                },
                () => {
                    if (activeChannels[key].unread) {
                        setTimeout(() => this.props.getUserStatus(), 1000);
                        this._markReadThrottled(activeChannels[key].channel);
                    }

                    if (
                        selectedMessageType !== this.state.selectedMessageType
                    ) {
                        this.handleChatMetrics(
                            activeChannels[key].channel,
                            'activates-message',
                            { community_group_hash: this.getGroupHash(key) }
                        );
                    }
                }
            );
        } else if (activeChannels[channelKey]) {
            // setting selected channel as current channel
            const selectedChannel = activeChannels[channelKey];

            if (!selectedChannel) {
                return;
            }

            if (selectedChannel.unread) {
                setTimeout(() => this.props.getUserStatus(), 1000);
                this._markReadThrottled(selectedChannel.channel);
            }
            this.setState(
                {
                    currentChannel: {
                        key: channelKey,
                        channel: selectedChannel.channel,
                        messages: activeChannels[
                            channelKey
                        ].channel.state.messages.slice(0),
                        hasMoreMessages:
                            activeChannels[channelKey].channel.state.messages
                                .length === LOAD_MORE_MESSAGES_LIMIT,
                    },
                    selectedMessageType,
                },
                () => {
                    if (
                        selectedMessageType !== this.state.selectedMessageType
                    ) {
                        this.handleChatMetrics(
                            activeChannels[channelKey].channel,
                            'activates-message',
                            {
                                community_group_hash: this.getGroupHash(
                                    channelKey
                                ),
                            }
                        );
                    }
                }
            );
        }
    };

    openMessageDrawer = async (userId) => {
        const { autoInitChat } = this.state;
        const newState = {};

        if (!autoInitChat) {
            newState.autoInitChat = true;
            await this.initChatClient();
        }
        await this.createChannel('community-user-message', { userId });
        if (!this.props.disableMessageDrawer) {
            newState.isMessageDrawerOpen = true;
        }
        this.setState(newState);
    };

    initGroupChat = async () => {
        const newState = {};
        if (!this.state.autoInitChat) {
            await this.initChatClient();
            await this.setCurrentChannel(null, 'group-chat-room');
            newState.autoInitChat = true;
            this.setState(newState);
        }
    };

    closeMessageDrawer = () => {
        this.setState({ isMessageDrawerOpen: false });
    };

    validateChannelStatus = (members, messageType) => {
        if (messageType === messageTypes.COMMUNITY) {
            const keys = Object.keys(members);

            for (let i = 0; i < keys.length; i += 1) {
                const memberId = keys[i];

                if (!members[memberId].user.active) {
                    return false;
                }
            }
        }
        return true;
    };

    // ============= create channel =====================

    createChannel = async (messageType, payload) => {
        switch (messageType) {
            case messageTypes.EMPLOYER:
                return this.createRecruiterChatChannel();
            default:
                return this.createMessageChannel(messageType, payload);
        }
    };

    /**
     * create new channel btwn a candidate and company
     * @param payload
     * @returns {Promise<void>}
     */
    createRecruiterChatChannel = async () => {
        const { candidateUserId, companyId } = this.props.channelMetaData;

        if (!candidateUserId || !companyId) {
            return;
        }

        const payload = { candidateUserId, companyId };

        return this.createMessageChannel(messageTypes.EMPLOYER, payload);
    };

    /**
     * if channel already exist between two entities, return the existing channel, and dedup the channels list
     * else create new channel
     * set the channel as current channel
     * @param messageType
     * @param payload
     * @returns {Promise<void>}
     */
    createMessageChannel = async (messageType, payload) => {
        const emitChannelCreationMetrics = (channelKey, isNewChannel) => {
            const { channel } = this.state.channelsByType[messageType][
                channelKey
            ];

            this.handleChatMetrics(
                channel,
                isNewChannel ? 'create-new-message' : 'activates-message'
            );
        };

        try {
            const apiUrl =
                messageType === messageTypes.EMPLOYER
                    ? '/api/employers/messages'
                    : '/api/messages';

            const res = await axios.post(apiUrl, {
                ...payload,
                messageType,
            });

            const {
                data: { data: { isNewChannel, channelKey } = {} } = {},
            } = res;

            const channelsByType = { ...this.state.channelsByType };
            const activeChannels = channelsByType[messageType];
            let existingChannel = null;

            const keys = Object.keys(activeChannels);

            for (let i = 0; i < keys.length; i += 1) {
                const activeChannelKey = keys[i];
                const channel = activeChannels[activeChannelKey];

                if (channel.channel.id === channelKey) {
                    existingChannel = channel;
                    break;
                }
            }

            // NOTE: if is a channel in the channel list, it can still be an old channel in the database even if not on the frontend channel list
            if (existingChannel) {
                await this.setCurrentChannel(channelKey, messageType);

                emitChannelCreationMetrics(channelKey, isNewChannel);
            } else {
                const rawChannel = await this.state.chatClient.channel(
                    messageType,
                    channelKey
                );
                if (!rawChannel) {
                    throw new Error('Fail to create new channel');
                }
                await rawChannel.watch({ presence: true });
                await this.setupChannelEventListeners(rawChannel);
                const newChannel = this.formatChannel(rawChannel);

                const channelObj = {};
                channelObj[channelKey] = newChannel;
                const newUsersInfo = await this.fetchUserRelationsForChannels(
                    channelObj
                );
                const { usersInfo } = this.state;

                activeChannels[newChannel.channel.id] = newChannel;
                channelsByType[messageType] = activeChannels;
                this.setState(
                    {
                        channelsByType,
                        usersInfo: { ...usersInfo, ...newUsersInfo },
                    },
                    async () => {
                        await this.setCurrentChannel(
                            newChannel.channel.id,
                            messageType
                        );

                        emitChannelCreationMetrics(
                            newChannel.channel.id,
                            isNewChannel
                        );
                    }
                );
            }
        } catch (e) {
            this.sentry.captureException(e);
            // TODO: what should we do if create channel fails
        }
    };

    // ============== End of create channel ==================

    /**
     *
     * @param channelInfo
     * @returns {{key: *, type: *, active: *, text: *, metaData: null, last_message_at: (number|string), members, messages: *, typing: (typing|{}|{"222"}|null|displayChannel.typing), unread: (boolean|number), creator: (data.created_by|{id}), channelData: {companies}}}
     */
    formatDisplayChannel = (channelInfo) => {
        if (!channelInfo || !channelInfo.channel || !channelInfo.channel.data) {
            return null;
        }

        const companies = {};
        let group = null;
        const recruiterChatInfo = {};

        const companyIds = channelInfo.channel.data.companyIds || [];
        companyIds.forEach((id) => {
            const companyId = Number(id);
            if (this.state.companiesInfo[companyId]) {
                companies[companyId] = this.state.companiesInfo[companyId];
            }
        });

        if (channelInfo.channel.type === messageTypes.GROUP) {
            const hash = this.getGroupHash(channelInfo.channel.id);
            if (this.state.groupsInfo[hash]) {
                group = this.state.groupsInfo[hash];
            }
        }

        if (channelInfo.channel.type === messageTypes.EMPLOYER) {
            const { companyId, candidateUserId } =
                channelInfo.channel.data.metaData || {};

            recruiterChatInfo.company = companyId
                ? this.state.companiesInfo[companyId]
                : null;
            recruiterChatInfo.candidate = candidateUserId
                ? this.state.usersInfo[candidateUserId]
                : null;
        }

        return {
            key: channelInfo.channel.id,
            type: channelInfo.channel.type,
            active: channelInfo.active,
            text: channelInfo.text, // text that user typed, not sent out yet
            metaData: null, // meta data that user want to sent, eg: job card
            last_message_at: channelInfo.channel.state.last_message_at,
            members: channelInfo.channel.state.members,
            messages: channelInfo.channel.state.messages
                .slice(0)
                .filter((message) => message.type !== 'deleted'),
            typing: channelInfo.typing,
            unread: channelInfo.unread,
            creator: channelInfo.channel.data.created_by,
            channelData: {
                companies,
                group,
                recruiterChatInfo,
            },
        };
    };

    getCurrentMessageType = () =>
        this.state.currentChannel
            ? this.state.currentChannel.channel.type
            : null;

    getDisplayChannels = () => {
        const formattedChannels = [];
        Object.keys(
            this.state.channelsByType[this.state.selectedMessageType]
        ).forEach((channelKey) => {
            const formattedChannel = this.formatDisplayChannel(
                this.state.channelsByType[this.state.selectedMessageType][
                    channelKey
                ]
            );
            if (formattedChannel) {
                formattedChannels.push(formattedChannel);
            }
        });
        return formattedChannels;
    };

    getCurrentChannelInfo = () =>
        this.state.currentChannel
            ? {
                  ...this.formatDisplayChannel(
                      this.state.channelsByType[this.state.selectedMessageType][
                          this.state.currentChannel.key
                      ]
                  ),
              }
            : null;

    getChannelInfoByKey = (channelKey, messageType) =>
        this.state.channelsByType[messageType][channelKey]
            ? {
                  ...this.formatDisplayChannel(
                      this.state.channelsByType[messageType][channelKey]
                  ),
              }
            : null;

    updateChannelByKey = (
        payload,
        key,
        type = this.state.selectedMessageType
    ) => {
        const stateChannelsByType = this.state.channelsByType;
        const channelsByType = { ...stateChannelsByType };
        channelsByType[type][key] = {
            ...channelsByType[type][key],
            ...payload,
        };

        this.setState({ channelsByType });
    };

    updateCurrentChannel = (payload) =>
        this.updateChannelByKey(payload, this.state.currentChannel.key);

    keystroke = (channel) => channel.keystroke();

    onTyping = async (text) => {
        if (!this.state.currentChannel) {
            return;
        }
        // const text = e.target.value;
        // this.updateCurrentChannel({ text });

        const { channel } = this.state.currentChannel;

        if (text) {
            this._keystrokeThrottled(channel);
        }
    };

    sendMessage = async (rawText, metaData = {}) => {
        if (!this.state.currentChannel) {
            return;
        }

        const currentChannel = this.state.channelsByType[
            this.state.selectedMessageType
        ][this.state.currentChannel.key];
        const text = rawText.replace(/\s+$/, '');
        // const text = currentChannel.text.replace(/\s+$/, '');

        if (
            !currentChannel ||
            !currentChannel.active ||
            (!text && !currentChannel.metaData)
        ) {
            return;
        }
        try {
            await currentChannel.channel.sendMessage({
                text,
                metaData,
                // metaData: currentChannel.metaData
            });

            if (this.state.selectedMessageType === messageTypes.EMPLOYER) {
                return this.handleChatMetrics(
                    this.state.currentChannel.channel,
                    'sent-message'
                );
            }

            const metricsPayload = {
                misc_event_count: 1,
                misc_event_type: 'messaging-user-sent-message',
                community_group_hash: this.getGroupHash(
                    this.state.currentChannel.key
                ),
            };

            EmitMetric(metricsPayload);

            // this.updateCurrentChannel({ text: '', metaData: null });
        } catch (e) {
            this.sentry.captureException(e);
        }
    };

    loadMoreChannels = async () => {
        if (this.state.loadingMoreChannels) {
            return;
        }
        this.setState({ loadingMoreChannels: true });
        const offset =
            this.state.channelPage[this.state.selectedMessageType] *
            CHANNEL_LIMIT;
        const stateChannelsByType = this.state.channelsByType;

        const stateCompaniesInfo = this.state.companiesInfo;
        const stateGroupsInfo = this.state.groupsInfo;
        const stateUsersInfo = this.state.usersInfo;
        const stateChannelPage = this.state.channelPage;

        try {
            const channelsByType = { ...stateChannelsByType };
            let newCompaniesInfo;
            let groupsInfo;
            let { usersInfo } = this.state;

            const channels = await this.queryChannelsByFilter(
                this.getChannelFilter(),
                offset
            );

            if (
                [messageTypes.COMMUNITY, messageTypes.EMPLOYER].includes(
                    this.state.selectedMessageType
                )
            ) {
                newCompaniesInfo = await this.fetchCompaniesInfoForChannels(
                    channels
                );
            }

            if (this.state.selectedMessageType === messageTypes.GROUP) {
                groupsInfo = await this.fetchGroupInfoForChannels(channels);
            }

            const newUsersInfo = await this.fetchUserRelationsForChannels(
                channels
            );
            usersInfo = { ...usersInfo, ...newUsersInfo };

            channelsByType[this.state.selectedMessageType] = {
                ...channelsByType[this.state.selectedMessageType],
                ...channels,
            };

            const channelPage = { ...stateChannelPage };
            channelPage[this.state.selectedMessageType] =
                this.state.channelPage[this.state.selectedMessageType] + 1;

            this.setState({
                channelsByType,
                channelPage,
                companiesInfo: {
                    ...stateCompaniesInfo,
                    ...newCompaniesInfo,
                },
                groupsInfo: { ...stateGroupsInfo, ...groupsInfo },
                usersInfo: { ...stateUsersInfo, ...usersInfo },
                loadingMoreChannels: false,
                hasMoreChannels: Object.keys(channels).length === CHANNEL_LIMIT,
            });
        } catch (e) {
            this.sentry.captureException(e);
            this.setState({ loadingMoreChannels: false });
        }
    };

    /**
     * each channel initially fetches 25 latest messages,
     * for current channel, we can pagination through older messages
     * @returns {Promise<void>}
     */
    loadMoreMessagesForCurrentChannel = async () => {
        if (!this.state.currentChannel) {
            return;
        }
        /**
         * prevent duplicate events
         */
        if (this.state.loadingMoreMessages) {
            return;
        }
        this.setState({ loadingMoreMessages: true });

        const { currentChannel, usersInfo } = this.state;
        const oldestMessageId = currentChannel.messages[0].id;

        try {
            const result = await currentChannel.channel.query({
                messages: {
                    limit: LOAD_MORE_MESSAGES_LIMIT,
                    id_lt: oldestMessageId,
                },
            });

            const olderMessages = result.messages;
            const hasMoreMessages =
                olderMessages.length === LOAD_MORE_MESSAGES_LIMIT;

            const newMessages = currentChannel.messages;
            const userIds = new Set();
            newMessages.forEach((message) => {
                const userId = Number(message.user.id);
                if (!this.state.usersInfo[userId]) {
                    userIds.add(userId);
                }
            });

            const newUsersInfo = await this.fetchUserRelations([...userIds]);

            this.setState({
                currentChannel: {
                    ...currentChannel,
                    messages: olderMessages.concat(currentChannel.messages),
                    hasMoreMessages,
                },
                loadingMoreMessages: false,
                usersInfo: { ...usersInfo, ...newUsersInfo },
            });
        } catch (e) {
            this.sentry.captureException(e);
            this.setState({
                loadingMoreMessages: false,
            });
        }
    };

    isLoggedInUser = (userId) => {
        return String(this.props.PAGE_PROPS.session.id) === String(userId);
    };

    /**
     * get channel filter by type, default filter is whatever selected in the state
     * @param type
     * @returns {*}
     */
    getChannelFilter = (type = this.state.selectedMessageType) => {
        if (type === messageTypes.GROUP || type === messageTypes.EMPLOYER) {
            return this.props.channelKeysByType[type].length > 0
                ? { id: { $in: this.props.channelKeysByType[type] } }
                : null;
        }
        const userMessageFilter =
            this.props.channelKeysByType[messageTypes.COMMUNITY].length > 0
                ? {
                      id: {
                          $in: this.props.channelKeysByType[
                              messageTypes.COMMUNITY
                          ],
                      },
                  }
                : { id: { $in: [this.props.communityManagerChannelKey] } };

        return this.props.PAGE_PROPS.session.isCommunityManager
            ? {
                  type: messageTypes.COMMUNITY,
                  members: { $in: [COMMUNITY_USER_ID.toString()] },
              }
            : userMessageFilter;
    };

    getChannelEntityHash = (messageType, channelKey) => {
        if (!channelKey) {
            return;
        }

        if (channelKey.indexOf(messageType) === -1) {
            return null;
        }

        return channelKey.split(`${messageType}-`)[1].replace(/-\d+$/, '');
    };

    getGroupHash = (channelKey) => {
        if (!channelKey) {
            return;
        }

        if (channelKey.indexOf(messageTypes.GROUP) === -1) {
            return null;
        }

        return channelKey.split(`${messageTypes.GROUP}-`)[1];
    };

    /**
     * toggle between different message types, reset the current channel when message type changes
     * @param selectedMessageType
     */
    setMessageType = async (selectedMessageType) => {
        const { channelsByType } = this.state;
        const hasMoreChannels =
            Object.keys(channelsByType[selectedMessageType]).length ===
            CHANNEL_LIMIT;

        this.setState({
            selectedMessageType,
            hasMoreChannels,
        });
        await this.setCurrentChannel(null, selectedMessageType);
    };

    /**
     * NOTE: we only support deleting group chat message
     * if logged in user is admin or moderator of current channel, or she is the author of this message
     * @param messageId
     * @param messageType
     * @param authorId
     * @returns {Promise<void>}
     */
    deleteMessage = async (messageId, messageType, authorId) => {
        if (
            !this.state.currentChannel ||
            !this.state.currentChannel.channel.state.members[
                this.props.PAGE_PROPS.session.id
            ]
        ) {
            return;
        }
        const myRole = this.state.currentChannel.channel.state.members[
            this.props.PAGE_PROPS.session.id
        ].role;
        if (
            [messageTypes.GROUP, messageTypes.EMPLOYER].includes(messageType) &&
            (['owner', 'admin', 'moderator'].includes(myRole) ||
                this.isLoggedInUser(authorId))
        ) {
            const groupHash = this.getGroupHash(this.state.currentChannel.key);
            await this.state.chatClient.deleteMessage(messageId);

            if (this.isLoggedInUser(authorId)) {
                EmitMetric({
                    misc_event_count: 1,
                    misc_event_type: 'user-delete-own-group-chat-room-message',
                    community_group_hash: groupHash,
                });
            } else {
                EmitMetric({
                    misc_event_count: 1,
                    misc_event_type:
                        'user-delete-other-group-chat-room-message',
                    community_group_hash: groupHash,
                });
            }
        }
    };

    /**
     *Looks up if the userId matches the id of the candidate in a Recruiter Chat channel
     *@param String userId
     */
    isRecruiterChatCandidate = (userId = null) => {
        const { currentChannel } = this.state;

        if (!currentChannel || !userId) {
            return false;
        }

        const candidateUserId =
            currentChannel.channel.data.metaData &&
            currentChannel.channel.data.metaData.candidateUserId;

        return candidateUserId === userId;
    };

    handleChatMetrics = (channel, metricsAction, additionalDims = null) => {
        if (!metricsAction) {
            return;
        }

        const { metaData } = channel.data;
        const companyId = metaData && metaData.companyId;

        const metricsPayload = {
            misc_event_count: 1,
            company_id: companyId ? [companyId] : [],
        };

        let actor = 'user';

        if (channel.type === messageTypes.EMPLOYER) {
            if (
                this.isRecruiterChatCandidate(this.props.PAGE_PROPS.session.id)
            ) {
                actor = 'candidate';
            } else {
                const candidateUserId = metaData && metaData.candidateUserId;
                metricsPayload.target_user_id = candidateUserId;
                actor = 'recruiter';
            }
        }

        metricsPayload.misc_event_type = `${actor}-${metricsAction}`;

        EmitMetric({ ...metricsPayload, ...additionalDims });
    };

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

MessageProvider.propTypes = {
    PAGE_PROPS,
    /**
     * map of channelKeys by message types
     */
    channelKeysByType: PropTypes.shape({
        'group-chat-room': PropTypes.arrayOf(PropTypes.string),
        'community-user-message': PropTypes.arrayOf(PropTypes.string),
        'recruiter-chat-room': PropTypes.arrayOf(PropTypes.string),
    }),
    /** Layout
     * message type of current context
     */
    messageTypes: PropTypes.arrayOf(
        PropTypes.oneOf(
            Object.keys(messageTypes).map((key) => messageTypes[key])
        )
    ).isRequired,
    /**
     * determines which channel is the default current channel, if both set, channelKey takes priority
     */
    initialChannel: PropTypes.shape({
        channelKey: PropTypes.string,
        useFirstChannel: PropTypes.bool,
    }),
    /**
     * channel sorting option, by default, sorted by the channel with latest message first
     */
    sort: PropTypes.shape({
        last_message_at: PropTypes.number,
    }),
    /**
     * function to call status api to update unread badge count
     */
    getUserStatus: PropTypes.func,
    /**
     * automatically initiate chat on Provider instantiation
     */
    autoInitChat: PropTypes.bool,
    /**
     * set next available channel as current channel if current channel is removed
     */
    setNextChannelAsCurrent: PropTypes.bool,
    /**
     * channel key of the community-user-message with community manager
     */
    communityManagerChannelKey: PropTypes.string,
    /**
     * condition to disable message drawer
     */
    disableMessageDrawer: PropTypes.bool,
    /**
     * whether allow to create new channel
     */
    allowNewChannelCreation: PropTypes.bool,
    children: PropTypes.node.isRequired,
    selectedMessageType: PropTypes.oneOf([
        messageTypes.COMMUNITY,
        messageTypes.WEBINAR,
        messageTypes.VCF,
        messageTypes.GROUP,
        messageTypes.EMPLOYER,
    ]),
    channelMetaData: PropTypes.shape({
        candidateUserId: PropTypes.number,
        companyId: PropTypes.number,
    }),
};

MessageProvider.defaultProps = {
    PAGE_PROPS: {
        session: {
            id: 1,
        },
    },
    initialChannel: {
        channelKey: null,
        userFirstChannel: true,
    },
    communityManagerChannelKey: null,
    sort: { last_message_at: -1 },
    getUserStatus: () => {},
    autoInitChat: true,
    setNextChannelAsCurrent: true,
    selectedMessageType: messageTypes.COMMUNITY,
    channelKeysByType: {
        'group-chat-room': [],
        'community-user-message': [],
        'recruiter-chat-room': [],
    },
    disableMessageDrawer: false,
    channelMetaData: {},
    allowNewChannelCreation: false,
};

export const MessageConsumer = Context.Consumer;
