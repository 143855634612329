import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../../Elements/InputLabel/InputLabel';
import FieldError from '../../Elements/FieldError/FieldError';
import BasicDropdown from '../../../../Input/BasicDropdown/BasicDropdown';
import { constructCn } from '../../../../../utilities/helperFunctions';

import './Dropdown.scss';

const addDefaultOption = (options = [], defaultOptionText) => [
    { label: defaultOptionText, value: '' },
    ...options,
];

export const Dropdown = ({
    inputName,
    label,
    defaultOptionText,
    error,
    options,
    handleChange,
    value,
    className,
    disabled,
}) => {
    const optionsWithDefault = addDefaultOption(options, defaultOptionText);
    const dropdownCn = constructCn(
        'component-Modals-ModalParts-Dropdown',
        error && 'input-error'
    );
    return (
        <div className={dropdownCn}>
            <InputLabel text={label} type="select" htmlFor="#modal-dd" />
            <BasicDropdown
                inputName={inputName}
                idValue="#modal-dd"
                selectedValue={value}
                options={optionsWithDefault}
                cssClass={className}
                handleRawChangeEvent={handleChange}
                disabled={disabled}
                shouldDisableDefault
            />
            <FieldError text={error} />
        </div>
    );
};

Dropdown.propTypes = {
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultOptionText: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
                PropTypes.number,
            ]).isRequired,
        })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
    label: '',
    defaultOptionText: 'Select...',
    error: '',
    className: '',
    value: '',
    disabled: false,
};

Dropdown.displayName = 'Dropdown';

export default React.memo(Dropdown);
