import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import TypeaheadInputCard from '../../QuestionCards/TypeaheadInputCard/TypeaheadInputCard';

import './EmployerLocationCard.scss';
import { countries } from './EmployerLocationData';

export default class EmployerLocationCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            location:
                props.name && props.longitude && props.latitude
                    ? {
                          name: props.name,
                          longitude: props.longitude,
                          latitude: props.latitude,
                      }
                    : null,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'employerLocation',
    };

    componentDidMount = () => {
        const {
            name,
            longitude,
            latitude,
            onAnswer,
            nextStep,
            questionName,
        } = this.props;

        EmitMetric({
            misc_event_type: 'job-review-question-started-company-2',
            misc_event_count: 1,
        });

        const locationObject =
            name && longitude && latitude
                ? {
                      name: this.state.location.name,
                      longitude: this.state.location.longitude,
                      latitude: this.state.location.latitude,
                  }
                : null;

        name && longitude && latitude
            ? onAnswer(
                  questionName,
                  locationObject,
                  CAN_SUBMIT,
                  false,
                  nextStep
              )
            : null;
    };

    onAnswer = (location) => {
        let nextAction = CANT_SUBMIT;
        let locationObject = {};

        countries.forEach((country) => {
            if (country.value === location) {
                locationObject.name = country.value;
                locationObject.longitude = country.longitude;
                locationObject.latitude = country.latitude;

                nextAction = CAN_SUBMIT;
            }
        });

        this.updateEmployerLocation(locationObject);

        this.props.onAnswer(
            this.props.questionName,
            locationObject,
            nextAction,
            false,
            this.props.nextStep
        );
    };

    updateEmployerLocation = (location) => {
        const newState = Object.assign({}, this.state);

        newState.location = location;

        this.setState(newState);
    };

    render() {
        const props = {
            inputType: 'location',
            inputLabel: 'Country:',
            placeholder: 'Please select a country',
            handleSelected: this.onAnswer,
            defaultImg: {
                src: '/v1553541820/icons/Location_2x.png',
                alt: '',
            },
            location: this.state.location,
            countries,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-EmployerLocationCard">
                <TypeaheadInputCard {...props} />
            </div>
        );
    }
}
