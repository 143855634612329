import PropTypes from 'prop-types';
import React from 'react';
// components
import Button from '../../../Input/Button/Button';
// helpers
import { buildImagePath } from '../../../JobComponents/Company/Image/logo';
// styles
import './ModalContainer.scss';

export default class ModalContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * contents to be rendered in main body of modal
         */
        children: PropTypes.node,
        /**
         * title to be rendered with modal
         */
        title: PropTypes.string,
        /**
         * description to be rendered with modal
         */
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /**
         * boolean to determine if the button should be shown
         */
        showButton: PropTypes.bool,
        /**
         * text to be rendered on button
         */
        buttonText: PropTypes.string,
        /**
         * function to close the modal
         */
        closeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        children: null,
        title: '',
        description: '',
        showButton: true,
        buttonText: 'Done',
    };

    renderCloseButton = () => {
        const imageId = '/v1/Close_X_sw0ud2.png';

        const imageTransform = {
            closeButton: 'c_scale,f_auto,q_auto,w_22,h_22',
        };

        const imageSrc = buildImagePath(imageId, 'closeButton', imageTransform);

        return (
            <div className="close-btn-section">
                <img
                    className="close-button"
                    src={imageSrc}
                    onClick={this.props.closeModal}
                />
            </div>
        );
    };

    render() {
        const {
            children,
            showButton,
            buttonText,
            closeModal,
            title,
            description,
        } = this.props;
        const titleSection = (
            <div className="modal-container-title">{title}</div>
        );
        const descriptionSection = (
            <div className="modal-container-description">{description}</div>
        );

        return (
            <div className="component-Modals-ModalParts-ModalContainer">
                {this.renderCloseButton()}
                <div className="modal-container-body">
                    {title ? titleSection : null}
                    {description ? descriptionSection : null}
                    {children}
                </div>
                {showButton ? (
                    <div className="modal-container-footer">
                        <div className="modal-button-container">
                            <Button
                                type="tertiary-blue-gray"
                                value={buttonText}
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
