import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import FGBLabel from '../../../Elements/FGBLabel/FGBLabel';

import './AdminMenuSection.scss';
import { PageContext } from '../../../../contexts/ContextCreator';
import GenericButton from '../../Elements/GenericButton/GenericButton';

export const ButtonTypes = {
    NORMAL: 'normal-button',
    REACTROUTERLINK: 'react-router-link',
};

const AdminMenuSection = (props) => {
    const { pageProps } = useContext(PageContext);
    const handleMetrics = (type) => {
        const miscEventType =
            type === 'EDIT' ? 'group-edit-click' : 'group-manage-members-click';

        EmitMetric({
            misc_event_type: miscEventType,
            misc_event_count: 1,
            community_group_hash: props.groupHash,
            user_id: pageProps?.session?.id,
        });
    };

    const reactRouterItems = [
        {
            title: 'Manage members',
            url: `/manage`,
            samePage: true,
            className: 'manage-members',
            onClick: () => handleMetrics('MEMBERS'),
        },
        {
            title: 'Edit group page',
            url: `/groups/edit/${props.groupHash}`,
            samePage: false,
            className: 'edit-group-page',
            onClick: () => handleMetrics('EDIT'),
        },
    ];

    const items = [
        {
            title: 'Manage members',
            url: `/groups/${props.groupHash}/manage`,
            samePage: false,
            className: 'manage-members',
            onClick: () => handleMetrics('MEMBERS'),
        },
        {
            title: 'Edit group page',
            url: `/groups/edit/${props.groupHash}`,
            samePage: false,
            className: 'edit-group-page',
            onClick: () => handleMetrics('EDIT'),
        },
    ];

    const renderButtons = () => {
        const buttonArray =
            props.buttonType === ButtonTypes.NORMAL ? items : reactRouterItems;
        return buttonArray.map((item) => (
            <GenericButton
                {...item}
                hash={props.groupHash}
                key={item.title}
                isReactRouterLink={
                    props.buttonType === ButtonTypes.REACTROUTERLINK
                }
            />
        ));
    };

    return (
        <div className="component-Groups-Modals-AdminMenuSection">
            <FGBLabel className="black">{props.title}</FGBLabel>
            {renderButtons(items)}
        </div>
    );
};

AdminMenuSection.propTypes = {
    title: PropTypes.string,
    groupHash: PropTypes.string.isRequired,
    buttonType: PropTypes.oneOf([
        ButtonTypes.NORMAL,
        ButtonTypes.REACTROUTERLINK,
    ]),
};

AdminMenuSection.defaultProps = {
    title: 'Admin Settings',
    buttonType: ButtonTypes.REACTROUTERLINK,
};

export default React.memo(AdminMenuSection);
