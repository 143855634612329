import PropTypes from 'prop-types';
import React from 'react';

import './CharCount.scss';

export default class CharCount extends React.PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        // eslint-disable-next-line react/require-default-props
        length: PropTypes.number.isRequired,
        // eslint-disable-next-line react/require-default-props
        limit: PropTypes.number.isRequired,
    };

    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        // eslint-disable-next-line react/default-props-match-prop-types
        length: 0,
        // eslint-disable-next-line react/default-props-match-prop-types
        limit: 0,
    };

    render() {
        const { length, limit } = this.props;
        const currentLabel = length.toLocaleString();
        const limitLabel = limit.toLocaleString();

        return limit ? (
            <span
                className={`component-Elements-CharCount ${
                    length > limit ? 'count-error' : ''
                }`}
            >
                {currentLabel}&#65295;{limitLabel}
            </span>
        ) : null;
    }
}
