import React from 'react';
import PropTypes from 'prop-types';

export const FlowContextPropShape = PropTypes.exact({
    tree: PropTypes.object.isRequired,
    step: PropTypes.string.isRequired,
    setStep: PropTypes.func.isRequired,
    getFlowControls: PropTypes.func.isRequired,
    flowData: PropTypes.object.isRequired,
    setFlowData: PropTypes.func.isRequired,
});

export const FlowContext = React.createContext({
    tree: {},
    step: '',
    setStep: () => {},
    getFlowControls: () => {},
    flowData: {},
    setFlowData: () => {},
});

export const getStepOfStepText = (tree = {}, step = '') => {
    const treeKeys = Object.keys(tree);

    const flowLength = treeKeys.length;

    const activeStepIndex = treeKeys.findIndex((v) => v === step);

    if (activeStepIndex < 0) {
        if (process.env.NODE_ENV !== 'production') {
            throw new Error(
                `Step: ${step} not a valid key on the provided tree object`
            );
        }

        // fall back to empty string -> no X of X text
        return '';
    }

    // our step count display is not zero based so adjust
    const displayStep = activeStepIndex + 1;

    return `${displayStep} of ${flowLength}`;
};
