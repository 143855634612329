import PropTypes from 'prop-types';
import React from 'react';
import './ButtonWithChildren.scss';

export default class ButtonWithChildren extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /*
            Optional Classes
        */
        className: PropTypes.string,
        /*
            On Click Functions
        */
        onClick: PropTypes.func,
        /**
            Children to be wrapped
        **/
        children: PropTypes.node,
        /**
            Flag to override button defaults
        **/
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        const disabledClass = this.props.disabled ? 'button-disabled' : '';

        return (
            <div
                className={`component-Input-ButtonWithChildren ${this.props.className}`}
            >
                <button onClick={this.props.onClick} className={disabledClass}>
                    {this.props.children}
                </button>
            </div>
        );
    }
}
