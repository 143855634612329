/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import { JobContextConsumer } from '../../../contexts/JobContext';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import ErrorLabel from '../../Elements/ErrorLabel/ErrorLabel';

import Button from '../../Input/Button/Button';
import InputField from '../../Input/InputField/InputField';
import LocationInput from '../../Input/LocationInput/LocationInput';
import ListItem from '../../Layout/ListSection/ListItem/ListItem';
import ListSection from '../../Layout/ListSection/ListSection';

import './JobAlerts.scss';

export default class JobAlerts extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            formError: null,
            jobSearchTerm: null,
            jobLocation: null,
            jobLat: null,
            jobLng: null,
        };
        this.algoliaTypeaheadElementId = `job-alert-${Math.random()
            .toString(36)
            .substring(2, 15)}`;
    }

    handleInputChange = (jobSearchTerm) => {
        this.setState({ jobSearchTerm });
    };

    handleLocationChange = (locationQuery) => {
        this.setState({
            jobLocation: locationQuery.value,
            jobLat: locationQuery.coordinates.lat,
            jobLng: locationQuery.coordinates.lng,
            formError: null,
        });
    };

    clearInput = () => {
        this.setState({ jobSearchTerm: '' });
    };

    isValidInput = () => {
        return (
            this.state.jobSearchTerm &&
            this.state.jobSearchTerm.length &&
            this.state.jobLocation &&
            this.state.jobLocation.length
        );
    };

    hasUserSelectedLocation = () => {
        if (this.state.jobLat && this.state.jobLng) {
            return true;
        }
        this.setState({
            formError: 'Please select an option from the dropdown.',
        });
        return false;
    };

    renderInputSection = (jobNotifications, onUpdate, followJobAlert) => {
        const followAlertHandler = async () => {
            let { notifications, notificationCount } = jobNotifications;
            const { savedJobsCount } = jobNotifications;

            if (this.isValidInput() && this.hasUserSelectedLocation()) {
                const res = await followJobAlert(this.state);

                if (res.error) {
                    this.setState({ formError: res.error });
                    return;
                }

                const jobNotification = {
                    jobNotificationId: res.jobNotificationId,
                    jobTitle: this.state.jobSearchTerm,
                    jobLocation: this.state.jobLocation,
                    jobUrl: `/jobs/find?keywords=${this.state.jobSearchTerm}&location=${this.state.jobLocation}`,
                };

                notifications = [...notifications, jobNotification];
                notificationCount += 1;
                this.clearInput();
                this.setState({ formError: null });
            }

            const newJobNotifications = {
                notifications,
                notificationCount,
                savedJobsCount,
            };

            onUpdate('jobNotifications', newJobNotifications);
        };

        return (
            <React.Fragment>
                <div className="input-section">
                    <div className="label">Search*</div>
                    <InputField
                        name="job-search"
                        onChange={this.handleInputChange}
                        placeholder="Search for Job title or Keyword"
                        inputValue={this.state.jobSearchTerm}
                        classname="job-alerts-search-input"
                    />
                </div>

                <div className="input-section">
                    <div className="label">Location</div>
                    <LocationInput
                        onChange={this.handleLocationChange}
                        placeholder="City, State or Country"
                        hasIcon
                        icon="new-location"
                        idValue={this.algoliaTypeaheadElementId}
                    />
                    {this.state.formError && (
                        <ErrorLabel
                            cssClass="fixed-position"
                            error={this.state.formError}
                        />
                    )}
                </div>

                <div className="button-div set-alert-div">
                    <Button
                        type="solid"
                        value="Add alert"
                        onClick={() => followAlertHandler()}
                        disabled={!this.isValidInput()}
                    />
                </div>
            </React.Fragment>
        );
    };

    renderFollowedJobItems = (jobNotifications, onUpdate, unfollowJobAlert) => {
        let { notifications, notificationCount } = jobNotifications;
        const { savedJobsCount } = jobNotifications;

        if (!notificationCount || !notifications || notificationCount === 0) {
            return (
                <div className="not-following-div">
                    You have no saved job searches.
                </div>
            );
        }

        return notifications.map((jobAlert) => {
            const jobProps = {
                title: {
                    text: jobAlert.jobTitle,
                },
                subtitle: {
                    text: jobAlert.jobLocation,
                },
                onClick: () => {
                    const unfollowedId = jobAlert.jobNotificationId;
                    unfollowJobAlert(unfollowedId);
                    notifications = notifications.filter(
                        (job) => job.jobNotificationId !== unfollowedId
                    );
                    notificationCount -= 1;

                    const newJobNotifications = {
                        notifications,
                        notificationCount,
                        savedJobsCount,
                    };

                    onUpdate('jobNotifications', newJobNotifications);
                },
                button: {
                    text: 'Remove',
                },
            };
            return <ListItem {...jobProps} key={jobAlert.jobNotificationId} />;
        });
    };

    render() {
        const fixedHeightClass = !this.props.modalDisplay ? 'fixed-height' : '';

        return (
            <div className={`component-JobV3-JobAlerts ${fixedHeightClass}`}>
                <PageConsumer>
                    {({ jobNotifications, onUpdate }) => {
                        const { notificationCount } = jobNotifications;

                        return (
                            <JobContextConsumer>
                                {({ followJobAlert, unfollowJobAlert }) => {
                                    let topDisplay = null;

                                    if (notificationCount < 5) {
                                        topDisplay = this.renderInputSection(
                                            jobNotifications,
                                            onUpdate,
                                            followJobAlert
                                        );
                                    } else {
                                        topDisplay = (
                                            <div className="section-subscription">
                                                You’ve maxed out your job
                                                alerts. Please remove one to add
                                                another.
                                            </div>
                                        );
                                    }

                                    return (
                                        <React.Fragment>
                                            {topDisplay}

                                            <ul>
                                                <ListSection
                                                    title={`your alerts (${
                                                        notificationCount || 0
                                                    } of 5)`}
                                                >
                                                    {this.renderFollowedJobItems(
                                                        jobNotifications,
                                                        onUpdate,
                                                        unfollowJobAlert
                                                    )}
                                                </ListSection>
                                            </ul>

                                            <ErrorLabel
                                                cssClass="error-message"
                                                error={
                                                    this.props.onboardingError
                                                }
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            </JobContextConsumer>
                        );
                    }}
                </PageConsumer>
            </div>
        );
    }
}

JobAlerts.propTypes = {
    /**
     * switch to display this component in either modal or rail format
     */
    modalDisplay: PropTypes.bool,
    /**
     * error to be displayed during job onboarding, if one exists
     */
    onboardingError: PropTypes.string,
};

JobAlerts.defaultProps = {
    modalDisplay: true,
    onboardingError: '',
};
