import PropTypes from 'prop-types';
import React from 'react';

import './ResponsiveTwoRowLayout.scss';
import { throttle } from '../../../../../utilities/helperFunctions';
import { PageContext } from '../../../../../contexts/ContextCreator';

class ResponsiveTwoRowLayout extends React.PureComponent {
    static TopRow = ({ children, className = '' }) => (
        <div className={`two-row-layout-navbar__top ${className}`}>
            {children}
        </div>
    );

    static BottomRow = ({ children, className = '' }) => (
        <div className={`two-row-layout-navbar__bottom ${className}`}>
            {children}
        </div>
    );

    constructor(props) {
        super(props);

        this.topSectionHeight = 48;
        this.mediumViewWidth = 767;
        this.throttledListenerCb = throttle(
            this.handleScrollAndResize,
            props.throttleInterval
        );

        this.state = {
            viewWidth: null,
        };
    }

    componentDidMount() {
        this.attachListener();
        this.handleScrollAndResize();
    }

    componentWillUnmount() {
        this.removeListener();
    }

    handleScrollAndResize = () => {
        const html = document.documentElement;
        const viewWidth = window.innerWidth || html.clientWidth;
        const bottomSubSection = document.querySelector(
            '.two-row-layout-navbar__bottom'
        );

        this.setState({ viewWidth });

        if (!bottomSubSection) {
            return;
        }

        if (
            window.scrollY > this.topSectionHeight ||
            viewWidth > this.mediumViewWidth
        ) {
            bottomSubSection.style.top = 0;
        } else {
            bottomSubSection.style.top = `${this.topSectionHeight}px`;
        }
    };

    attachListener() {
        window.addEventListener('scroll', this.throttledListenerCb);
        window.addEventListener('resize', this.throttledListenerCb);
    }

    removeListener() {
        window.removeEventListener('scroll', this.throttledListenerCb);
        window.removeEventListener('resize', this.throttledListenerCb);
    }

    render() {
        const { viewWidth } = this.state;
        const { className, children } = this.props;
        const { isGlobalNavSticky, leadInLogin } = this.context;
        let defaultClassName =
            'component-Layout-Header-Layout-ResponsiveTwoRowLayout';

        if (isGlobalNavSticky) {
            defaultClassName = defaultClassName.concat(
                ` ${defaultClassName}--sticky `
            );
        }

        return (
            <div className={`${defaultClassName} ${className}`}>
                <nav
                    className={`two-row-layout-navbar ${
                        leadInLogin ? 'leadInLogin' : ''
                    }`}
                >
                    {typeof children === 'function'
                        ? children({ isGlobalNavSticky, viewWidth })
                        : React.Children.map(children, (child) =>
                              React.cloneElement(child, {
                                  isGlobalNavSticky,
                                  viewWidth,
                              })
                          )}
                </nav>
            </div>
        );
    }
}

ResponsiveTwoRowLayout.contextType = PageContext;
ResponsiveTwoRowLayout.propTypes = {
    /**
     * throttle interval for rate limit
     */
    throttleInterval: PropTypes.number,
    /**
     * additional styling className
     */
    className: PropTypes.string,
    children: PropTypes.func.isRequired,
};

ResponsiveTwoRowLayout.defaultProps = {
    className: '',
    throttleInterval: 100,
};

export default ResponsiveTwoRowLayout;
