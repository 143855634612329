export const groupActions = Object.freeze({
    DELETE_GROUP: 'deleteGroup',
    EDIT_GROUP: 'editGroup',
    SHOW_ADMIN_MENU: 'showAdminMenu',
    SHOW_ADMIN_ONBOARDING: 'showAdminOnboarding',
    MANAGE_MEMBERS: 'manageMembers',
    VIEW_CONTENTS: 'viewContents',
    POST_CONTENTS: 'postContents',
    CAN_INVITE: 'inviteUsers',
    GROUP_CHAT: 'groupChat',
});
