export default (sentryConfig) => {
    let sentryClient = {
        captureException: (e) => {},
        captureMessage: (msg) => {},
        captureBreadcrumb: () => {},
        context: () => {},
        setContext: () => {},
        mergeContext: () => {},
        wrap: () => {},
        requestHandler: () => (req, res, next) => next(),
        errorHandler: () => (req, res, next) => next(),
    };

    // if (sentryConfig && sentryConfig.enabled) {
    //     Sentry.init({dsn: sentryConfig.reactDsn, environment: sentryConfig.env});
    //     sentryClient = Sentry;
    // }

    return sentryClient;
};
