import React from 'react';
import PropTypes from 'prop-types';

import SVGFromCloudinary from '../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';

import './LocationSelect.scss';

const mapImageSrc = 'v1/icons/location_map.svg';

const convertLocationsForDropdown = (locations) =>
    locations.map((l) => ({
        value: l.jobLocationId,
        label: l.locationName,
    }));

export default class LocationSelect extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        locations: PropTypes.arrayOf(
            PropTypes.exact({
                jobLocationId: PropTypes.number.isRequired,
                locationName: PropTypes.string.isRequired,
            })
        ).isRequired,
        handleJobLocationSelect: PropTypes.func.isRequired,
        handleJobLocationConfirm: PropTypes.func.isRequired,
        jobLocationSelectError: PropTypes.string,
        currentSelectionId: PropTypes.string,
    };

    static defaultProps = {
        currentSelectionId: '',
        jobLocationSelectError: null,
    };

    render() {
        const {
            locations,
            handleJobLocationSelect,
            handleJobLocationConfirm,
            jobLocationSelectError,
            currentSelectionId,
        } = this.props;

        const dropdownLocations = convertLocationsForDropdown(locations);

        return (
            <div className="component-Modals-QuickApplyModal-LocationSelect">
                <SVGFromCloudinary
                    cloudinaryID={mapImageSrc}
                    alt="location-map"
                    className="location-map-image"
                />
                <p className="location-select-description">
                    This job is available in multiple places. Please select your
                    desired location. Once submitted, you will not be able to
                    change your selection.
                </p>

                <div className="location-select-box">
                    <p className="location-dropdown-label">
                        Locations ({locations.length})
                    </p>

                    <BasicDropdown
                        defaultLabel="Select a location..."
                        selectedValue={currentSelectionId}
                        options={dropdownLocations}
                        onChange={handleJobLocationSelect}
                    />
                    <ErrorLabel
                        error={jobLocationSelectError}
                        cssClass="field-error"
                    />
                </div>
                <button
                    type="button"
                    className="location-next-btn"
                    onClick={handleJobLocationConfirm}
                >
                    Next
                </button>
            </div>
        );
    }
}
