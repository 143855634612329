import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import RadioGroup from '../../../ModalParts/Inputs/Radios/RadioGroup/RadioGroup';
import Radio from '../../../ModalParts/Inputs/Radios/Radio/Radio';

import usePrevious from '../../../../../utilities/hooks/usePrevious';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { GENDER_IDENTITY_STEP_NAME } from '../constants';
import { genderChoices } from '../../DiversityInput/constants';

import './GenderIdentity.scss';

const GenderIdentity = ({ buttonNavBox }) => {
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        stepErrors,
    } = useGetStepFormProps(GENDER_IDENTITY_STEP_NAME);

    const currentGenderChoice = stepFormProps.genderChoice;
    const prevGenderChoice = usePrevious(currentGenderChoice);

    const handleRadioSelect = React.useCallback(
        ({ value, group }) => {
            // group = step name
            updateFormValueAtPath(group)(value);
        },
        [updateFormValueAtPath]
    );

    // Reset optionalOtherText value if user changes choice from Other
    React.useEffect(() => {
        if (prevGenderChoice === 'Other' && currentGenderChoice !== 'Other') {
            updateFormValueAtPath('genderIdentity.optionalOtherText')('');
        }
    }, [prevGenderChoice, currentGenderChoice, updateFormValueAtPath]);

    return (
        <FlowStep name={GENDER_IDENTITY_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-GenderIdentity">
                <h2 className="step-header-text">
                    Help us understand and improve our community’s diversity.*
                </h2>
                <div className="primary-step-text">
                    Which best describes your gender identity?
                </div>
                <RadioGroup
                    currentSelected={stepFormProps.genderChoice}
                    onChange={handleRadioSelect}
                    groupName="genderIdentity.genderChoice"
                >
                    {genderChoices.map((gc) => (
                        <Radio key={gc} value={gc} />
                    ))}
                </RadioGroup>
                <div className="optional-other-box">
                    {stepFormProps.genderChoice === 'Other' ? (
                        <LongTextInput
                            inputName="genderIdentity.optionalOtherText"
                            value={stepFormProps.optionalOtherText}
                            placeholder="Please specify..."
                            type="textarea"
                            error={stepErrors.optionalOtherText}
                            handleChange={updateFormValues}
                            rows={1}
                            maxChars={200}
                        />
                    ) : null}
                </div>
                <div className="privacy-text">
                    *We’re committed to your privacy and will never share this
                    sensitive individual data.
                </div>
            </div>
            {buttonNavBox}
        </FlowStep>
    );
};

GenderIdentity.propTypes = {
    buttonNavBox: PropTypes.node.isRequired,
};

GenderIdentity.defaultProps = {};

export default React.memo(GenderIdentity);
