import React from 'react';
import { DEFAULT_PRICING_TABLE } from '../components/Includes/Constants/employers';
import SVGFromCloudinary from '../components/Elements/SVGFromCloudinary/SVGFromCloudinary';
import {
    getRandomIndex,
    defaultUserImages,
} from '../components/Includes/randomAuthorImageGenerator';
import { MYSTERY_WOMAN_IMAGE_PATH } from './images';

export const truncateWithEllipsis = (text, length, ellipses = true) => {
    if (!text || text.length < length) {
        return text;
    }

    const truncated = text.substr(0, length);
    const splitWord = truncated.split(' ');
    if (splitWord.length > 1) {
        delete splitWord[splitWord.length - 1];
    }
    return ellipses
        ? `${splitWord.join(' ').trim()}...`
        : `${splitWord.join(' ')}`;
};

export const removeTagsFromString = (text) => {
    if (text && typeof text === 'string' && text !== '') {
        const tagsRegex = /(?:<(?:!|\/?[a-zA-Z]+).*?\/?>)/;
        const replacedText = text.replace(/<\/p>/gm, '\n');
        return replacedText.split(tagsRegex).join('');
    }
    return text;
};

export const removeTagsFromStringNoPagagaphBreaks = (text) => {
    if (text && text !== '') {
        const tagsRegex = /(?:<(?:!|\/?[a-zA-Z]+).*?\/?>)/;
        return text.split(tagsRegex).join('');
    }
    return text;
};

/**
 * utility function for Employer Marketing's Pricing page
 */
export const getTableHeader = () => [
    {
        label: 'PROGRAM OFFERINGS',
        value: 'PROGRAM OFFERINGS',
    },
    {
        label: 'SILVER',
        value: 'SILVER',
    },
    {
        label: 'GOLD',
        value: 'GOLD',
    },
    {
        label: 'PLATINUM',
        value: 'PLATINUM',
    },
];

const purpleCheckImgSrc = '/v1579721153/icons/checkmark_active_purple.svg';
const greyCheckImgSrc = '/v1579721153/icons/checkmark_inactive_gray.svg';

export const PurpleCheck = () => (
    <SVGFromCloudinary
        className="table-boolean-true"
        alt="active checkmark"
        cloudinaryID={purpleCheckImgSrc}
    />
);

export const GreyCheck = () => (
    <SVGFromCloudinary
        className="table-boolean-false"
        alt="inactive checkmark"
        cloudinaryID={greyCheckImgSrc}
    />
);

export const formatPricingRowData = (
    rawPricingRowData = DEFAULT_PRICING_TABLE
) => {
    if (!Array.isArray(rawPricingRowData) || rawPricingRowData.length === 0) {
        return [];
    }

    const serializedRowData = rawPricingRowData.map((row) => {
        return row.map((item, j) => {
            if (j === 0) {
                return item.label;
            }

            // eslint-disable-next-line no-bitwise
            if ((row[0].type === 'boolean') & (j !== 0)) {
                return item ? <PurpleCheck /> : <GreyCheck />;
            }
            return item;
        });
    });

    return serializedRowData;
};
/**
 * end
 */

export const videoTypes = {
    YOUTUBE: 'youtube',
    CLOUDINARY: 'cloudinary',
};

export const getVideoType = (videoSrc = '') => {
    if (!videoSrc) {
        return null;
    }

    if (videoSrc.indexOf('youtube') >= 0 || videoSrc.indexOf('youtu.be') >= 0) {
        return videoTypes.YOUTUBE;
    }

    return videoTypes.CLOUDINARY;
};

export function intToMonth(int, short = false) {
    // eslint-disable-next-line no-param-reassign
    if (typeof int === 'string') int = parseInt(int, 10);
    if (int === 0) {
        return null;
    }

    if (short) {
        return [
            null,
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'June',
            'July',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ][int];
    }

    return [
        null,
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ][int];
}

/*
    @TODO - there is duplication here with the author serializer on the server.
    Once the server's serializer is refactored, we should update this code 
    so that both the server and client use a shared function to serialize a user -MSH050120
*/
export const reserializeAuthor = (author, isAnon, session) => {
    if (isAnon) {
        return {
            ...author,
            userId: null,
            username: 'Anonymous',
            image: {
                alt: 'Anonymous image',
                src:
                    defaultUserImages[getRandomIndex(defaultUserImages.length)],
            },
            credential: null,
            karmaScore: null,
        };
    }

    return {
        ...author,
        userId: session.id,
        username: session.username,
        image: {
            alt: `${session.username} image`,
            src: session.logo || MYSTERY_WOMAN_IMAGE_PATH,
        },
        credential: session.credential,
        karmaScore: session.karma_score,
    };
};

export const trimFront = (str) => {
    const realParts = [];
    if (typeof str.split === 'undefined') return '';
    const htmlParts = str.split('\n');
    let firstRealFound = false;
    for (let i = 0; i < htmlParts.length; i += 1) {
        if (htmlParts[i].trim() !== '' || firstRealFound) {
            firstRealFound = true;
            realParts.push(htmlParts[i]);
        }
    }
    return realParts.join('\n');
};

export const linkify = (str) => {
    let match;
    const RE_URL = /\w+:\/\/\S+/g;
    const results = [];
    let lastIndex = 0;
    // eslint-disable-next-line no-cond-assign
    while ((match = RE_URL.exec(str))) {
        const link = match[0];
        if (lastIndex !== match.index) {
            const text = str.substring(lastIndex, match.index);
            results.push(<span key={results.length}>{text}</span>);
        }
        results.push(
            // eslint-disable-next-line react/jsx-no-target-blank
            <a key={results.length} href={link} target="_blank">
                {link}
            </a>
        );
        lastIndex = match.index + link.length;
    }
    if (results.length === 0) {
        return str;
    }
    if (lastIndex !== str.length) {
        results.push(
            <span key={results.length}>{str.substring(lastIndex)}</span>
        );
    }
    return results;
};

export const getTitle = (initSection) => {
    const section = initSection.replace(/\n/gm, ' \n ');
    const parts = section.split(/^.*?[.!?\n\r](?:\s|$)/gs);
    let realLength = 0;
    let firstPartFound= false;
    let firstPart = [];

    for (let i = 0; i < parts.length; i += 1) {
        if (parts[i] !== '' && parts[i] !== '</p>') {
            realLength += 1;
            if(!firstPartFound){
                firstPart = parts[i];
            }
            firstPartFound=true;
        }
    }
    if (parts.length > 1) {
        const trueLength = section.length - parts[1].length - 1;
        // Currently converting this h1 for better SEO,
        // if we use user custom formatting in the future we need to refactor this
        parts[0] = section.substring(0, trueLength);
        if(parts[0].includes('http')) return { title: '', description: trimFront(initSection), realLength: 0 };
        return { title: parts[0], description: trimFront(parts[1]), realLength };
    }
    return { title: '', description: trimFront(firstPart), realLength: 0 };
};
const boldSection = (initSection) => {
    const section = initSection.replace(/\n/gm, ' \n ');
    const parts = section.split(/^.*?[.!?\n\r](?:\s|$)/gs);
    if (parts.length > 1) {
        const trueLength = section.length - parts[1].length - 1;
        // Currently converting this h1 for better SEO,
        // if we use user custom formatting in the future we need to refactor this
        if(!parts[0].includes('http'))
            parts[0] = <h1>{section.substring(0, trueLength)}</h1>;
    }
    return parts;
};

const strongSection = (initSection) => {
    const section = initSection.replace(/\n/gm, ' \n ');
    const parts = section.split(/^.*?[.!?\n\r](?:\s|$)/gs);
    if (parts.length > 1) {
        const trueLength = section.length - parts[1].length - 1;
        // Currently converting this h1 for better SEO,
        // if we use user custom formatting in the future we need to refactor this
        if(!parts[0].includes('http'))
            parts[0] = <strong>{section.substring(0, trueLength)}</strong>;
    }
    return parts;
};

export const boldify = (text) => {
    if (typeof text === 'string') {
        return boldSection(text);
    }
    if (Array.isArray(text)) {
        const parts = text;
        if (text[0].type === 'span') {
            const updateSection = boldSection(text[0].props.children);
            parts.shift();
            return updateSection.concat(parts);
        }
    }
    return text;
};

export const strongify = (text) => {
    if (typeof text === 'string') {
        return boldSection(text);
    }
    if (Array.isArray(text)) {
        const parts = text;
        if (text[0].type === 'span') {
            const updateSection = strongSection(text[0].props.children);
            parts.shift();
            return updateSection.concat(parts);
        }
    }
    return text;
};

export const truncateAndLinkifyCommentContent = (text, length) => {
    // Small text go straight forward
    if (text.length < length) {
        return false;
    }

    // Normal text with no tags
    if (!text.includes('<p>')) {
        return truncateWithEllipsis(text, length, false);
    }

    const tokens = removeTagsFromString(text).split(' ');
    let remainingChars = length;
    let commentPrefix = '';
    let resultantContent = [];
    for (let i = 0; i < tokens.length; i += 1) {
        const token = tokens[i];
        if (token.length > remainingChars) {
            if (token.startsWith('http://') || token.startsWith('https://')) {
                // Last token is a link that will be truncated
                resultantContent =
                    commentPrefix && commentPrefix !== ''
                        ? linkify(commentPrefix.trim())
                        : [];
                const truncatedLink = (
                    <>
                        <span> </span>
                        <a
                            className="truncated-link"
                            href={token}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {`${truncateWithEllipsis(
                                token,
                                remainingChars,
                                false
                            )}`}
                        </a>
                    </>
                );
                if (Array.isArray(resultantContent)) {
                    resultantContent.push(truncatedLink);
                } else {
                    resultantContent = [`${resultantContent} `, truncatedLink];
                }
            } else {
                // No link truncation, just add regular text and linkify
                commentPrefix += truncateWithEllipsis(
                    token,
                    remainingChars,
                    false
                );
                resultantContent =
                    commentPrefix && commentPrefix !== ''
                        ? linkify(commentPrefix.trim())
                        : [];
            }
            break;
        } else {
            commentPrefix += ` ${token}`;
            remainingChars -= token.length + 1;
            if (i === tokens.length - 1) {
                resultantContent = linkify(commentPrefix.trim());
            }
        }
    }
    return resultantContent;
};
