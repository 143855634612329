import PropTypes from 'prop-types';
import React from 'react';
import { GroupProvider } from '../../../../contexts/GroupContext';
import { Consumer as PageConsumer } from '../../../../contexts/PageContext';
import FlexPopup from '../../../Elements/FlexPopup/FlexPopup';
import Close from '../../../Icons/Close';
import GroupJoinForm from '../GroupJoinForm/GroupJoinForm';

import './GroupJoinModal.scss';

export default class GroupJoinModal extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        group: PropTypes.shape({
            /**
             * string of group hash
             */
            hash: PropTypes.string.isRequired,
            /**
             * object of logo
             */
            logo: PropTypes.shape({
                /**
                 * string image src
                 */
                src: PropTypes.string,
                /**
                 * string image alt
                 */
                alt: PropTypes.string,
            }),
            /**
             * string group name
             */
            name: PropTypes.string.isRequired,
            /**
             * string group description
             */
            description: PropTypes.string.isRequired,
            /**
             * number of members in group
             */
            memberCount: PropTypes.number,
            /**
             * array of preview members
             */
            previewMembers: PropTypes.arrayOf(
                PropTypes.shape({
                    /**
                     * string image src
                     */
                    src: PropTypes.string,
                    /**
                     * string image alt
                     */
                    alt: PropTypes.string,
                })
            ),
        }).isRequired,
        showModal: PropTypes.bool,
        onClose: PropTypes.func,
        onSuccess: PropTypes.func,
        modalTriggerParameters: PropTypes.shape({
            modalName: PropTypes.string,
            modalData: PropTypes.object,
        }),
    };

    render() {
        const modalTriggerModalName =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalName;
        const modalTriggerModalProps =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalData;

        return (
            <PageConsumer>
                {({ modalTrigger }) => {
                    return (
                        <div className="component-Groups-Join-GroupJoinModal">
                            <GroupProvider group={this.props.group}>
                                <FlexPopup
                                    hidden={!this.props.showModal}
                                    onClose={() =>
                                        modalTrigger(
                                            modalTriggerModalName,
                                            modalTriggerModalProps
                                        )
                                    }
                                >
                                    <div className="controlled-width">
                                        <button
                                            className={'close-icon'}
                                            onClick={() =>
                                                modalTrigger(
                                                    modalTriggerModalName,
                                                    modalTriggerModalProps
                                                )
                                            }
                                        >
                                            {Close}
                                        </button>
                                        <GroupJoinForm
                                            isModal={true}
                                            redirectToGroup={false}
                                            onSuccess={this.props.onSuccess}
                                            modalTriggerParameters={
                                                this.props
                                                    .modalTriggerParameters
                                            }
                                        />
                                    </div>
                                </FlexPopup>
                            </GroupProvider>
                        </div>
                    );
                }}
            </PageConsumer>
        );
    }
}
