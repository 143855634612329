import PropTypes from 'prop-types';
import React from 'react';
import { getInviteConnectionsImages } from '../InvitationWidget';

import './ChooseLayout.scss';

export default class ChooseLayout extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        allowGroupInvite: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        allowGroupInvite: false,
    };

    render() {
        return (
            <div className="component-InvitationWidget-ChooseLayout">
                <img
                    className="mail-icon"
                    src={getInviteConnectionsImages(
                        '/v1/SVGs/invite_mail.svg',
                        'icon'
                    )}
                    alt={'mail-icon'}
                />
                <button
                    className="choose-layout-button invite-connections-button"
                    onClick={() => this.props.onClick('type', 'connection')}
                >
                    <div className="choose-layout-choose-button-title">
                        Invite people you know
                    </div>
                    <div className="choose-layout-choose-button-description">
                        Users that accept will become your Fairygodboss
                        connections. You'll be able to message them and see
                        their posts on your feed.
                    </div>
                </button>
                {this.props.allowGroupInvite && (
                    <button
                        className="choose-layout-button invite-group-button"
                        onClick={() => this.props.onClick('type', 'group')}
                    >
                        <div className="choose-layout-choose-button-title">
                            Invite people to a group
                        </div>
                        <div className="choose-layout-choose-button-description">
                            Share your group with others.
                        </div>
                    </button>
                )}
            </div>
        );
    }
}
