import React from 'react';

import './DefaultIntroMessage.scss';

const feedLink = (
    <a
        className="welcome-msg-link"
        href="https://fairygodboss.com/community/feed?LAC=communitymessage&amp;LAT=welcomemessage"
    >
        Visit the community feed
    </a>
);

export default class DefaultIntroMessage extends React.PureComponent {
    render() {
        return (
            <div className="component-Messages-DefaultIntroMessage">
                <p>Here&apos;s a tip!</p>

                <p>
                    We know that sometimes asking a sensitive question on a
                    public forum can be intimidating. To relieve some of the
                    pressure, you can post or comment anonymously.
                </p>

                <p>
                    To create your anonymous post: <br />
                    1. {feedLink}.
                </p>

                <p>
                    2. Start typing a post or comment, a Post Anonymously option
                    will appear underneath.
                </p>

                <p>3. Click on the check box to post anonymously.</p>

                <p>4. Click the Post button.</p>
            </div>
        );
    }
}
