import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { truncateWithEllipsis } from '../../../../../utilities/ui_utilities';
import ButtonWithChildren from '../../../../Input/ButtonWithChildren/ButtonWithChildren';
import { buildImagePath } from '../../../../JobComponents/Company/Image/logo';
import FacebookButton from '../../SocialMediaButtons/FacebookButton/FacebookButton';
import GoogleButton from '../../SocialMediaButtons/GoogleButton/GoogleButton';
import EmailLoginForm from '../EmailLoginForm/EmailLoginForm';
import { PageContext } from '../../../../../contexts/ContextCreator';
import './CreateAccountLayout.scss';
import LeadInLogin from '../LeadInLogin/LeadInLogin';
import { CREATE_LAYOUT, EMAIL_SIGNUP } from '../LogInLayout/constants';

const CreateAccountLayout = (props) => {
    const context = useContext(PageContext);
    const [showEmailForm, setShowEmailForm] = useState(false);

    useEffect(() => {
        if (context?.loginWidget?.loginLayout === EMAIL_SIGNUP) {
            setShowEmailForm(true);
        }
    }, [context, setShowEmailForm]);

    const openEmailForm = () => {
        setShowEmailForm(true);
    };

    const renderEmailButton = () => {
        const iconSrc = buildImagePath('/v1541017253/Login/Mail.png', 'button');
        return !showEmailForm ? (
            <ButtonWithChildren
                className="email-button"
                onClick={openEmailForm}
            >
                <div className="button-content-container">
                    <img alt="" src={iconSrc} />
                    <div
                        className="call-to-action-button-text"
                        data-qa="continue"
                    >
                        Continue with email
                    </div>
                </div>
            </ButtonWithChildren>
        ) : null;
    };

    const { leadInLogin } = props;
    if (leadInLogin) {
        return <LeadInLogin onLogin={props.onLogin} />;
    }
    return (
        <div className="component-Login-LoginLayoutComponent-Layouts-CreateAccountLayout">
            {!showEmailForm && (
                <div className="login-subText-call-to-action-component">
                    {truncateWithEllipsis(props.callToActionSubTitle, 80)}
                </div>
            )}
            <FacebookButton onClick={props.onLogin} />
            <GoogleButton onClick={props.onLogin} />
            {renderEmailButton()}
            {showEmailForm && (
                <EmailLoginForm
                    location={CREATE_LAYOUT}
                    featureFlags={
                        context &&
                        context.pageProps &&
                        context.pageProps.featureFlags
                    }
                    showUsernameField={props.showUsernameField}
                    onClick={props.onClick}
                    handleEmailInput={props.handleEmailInput}
                    handleEmailInputBlur={props.handleEmailInputBlur}
                    handlePasswordInput={props.handlePasswordInput}
                    handlePasswordInputBlur={props.handlePasswordInputBlur}
                    handleUsernameInput={props.handleUsernameInput}
                    handleNameInput={props.handleNameInput}
                    handleNameInputBlur={props.handleNameInputBlur}
                    email={props.email}
                    username={props.username}
                    name={props.name}
                    password={props.password}
                    nameError={props.nameError}
                    emailError={props.emailError}
                    passwordError={props.passwordError}
                    usernameError={props.usernameError}
                    generalError={props.generalError}
                    loaded={props.loaded}
                />
            )}
        </div>
    );
};

CreateAccountLayout.propTypes = {
    /**
     * auth0 config
     */
    auth0: PropTypes.shape({
        domain: PropTypes.string,
        clientId: PropTypes.string,
        db: PropTypes.string,
    }).isRequired,
    /**
     * login function to call after success for sign up
     */
    onLogin: PropTypes.func.isRequired,
    /**
     * function to handle email input
     */
    handleEmailInput: PropTypes.func.isRequired,
    /**
     * function to handle email input blur
     */
    handleEmailInputBlur: PropTypes.func.isRequired,
    /**
     * function to handle password input
     */
    handlePasswordInput: PropTypes.func.isRequired,
    /**
     * function to handle password input blur
     */
    handlePasswordInputBlur: PropTypes.func.isRequired,
    /**
     * function to handle usernameInput
     */
    handleUsernameInput: PropTypes.func,
    /**
     * function to handle name input
     */
    handleNameInput: PropTypes.func,
    /**
     * function to handle name input
     */
    handleNameInputBlur: PropTypes.func.isRequired,
    /**
     * email value
     */
    email: PropTypes.string,
    /**
     * password value
     */
    password: PropTypes.string,
    /**
     * username value
     */
    username: PropTypes.string,
    /**
     * email error
     */
    emailError: PropTypes.string,
    /**
     * password error
     */
    passwordError: PropTypes.string,
    /**
     * general Error
     */
    generalError: PropTypes.string,
    /**
     * username error
     */
    usernameError: PropTypes.string,
    /**
     * name error
     */
    name: PropTypes.string,
    /**
     * The secondary subtitle text
     */
    callToActionSubTitle: PropTypes.string,
    /**
     * state passed down on whether the call has finished processing
     */
    loaded: PropTypes.bool,
    showUsernameField: PropTypes.bool,
    /**
     * state passed down on whether the call has finished processing
     */
    leadInLogin: PropTypes.bool,
    nameError: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

CreateAccountLayout.defaultProps = {
    callToActionSubTitle:
        'Join millions of women sharing advice, finding jobs and making connections.',
    showUsernameField: true,
    handleUsernameInput: undefined,
    handleNameInput: undefined,
    email: undefined,
    name: undefined,
    password: undefined,
    username: undefined,
    emailError: undefined,
    passwordError: undefined,
    generalError: undefined,
    usernameError: undefined,
    loaded: undefined,
    leadInLogin: undefined,
    nameError: undefined,
};

export default CreateAccountLayout;
