import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import SVGFromCloudinary from '../../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import SunIcon from '../../../../Icons/SunIcon';
import { CONFIRM_SUCCESS_STEP_NAME } from '../constants';

import './ConfirmSuccess.scss';
import { ModalContext } from '../../../../../contexts/ContextCreator';

const confirmationImageId = '/v1/Groups/confirmation-icon.svg'; // kite image id or src

export const ConfirmSuccess = ({
    mainText,
    subtext,
    linkUrl,
    buttonText,
    layout,
}) => {
    const { closeModal } = React.useContext(ModalContext);

    const continueButton = linkUrl ? (
        <a href={linkUrl} className="success-cta-button">
            {buttonText}
        </a>
    ) : (
        <button
            type="button"
            onClick={() => closeModal()}
            className="success-cta-button"
        >
            {buttonText}
        </button>
    );

    const defaultLayout = (
        <React.Fragment>
            <SVGFromCloudinary
                cloudinaryID={confirmationImageId}
                alt="confirmation-kite"
                className="confirmation-kite"
            />
            <h4 className="success-main-text">{mainText}</h4>
            <h6 className="success-subtext">{subtext}</h6>
            {continueButton}
        </React.Fragment>
    );

    const diversityLayout = (
        <React.Fragment>
            <div className="sun-icon-wrapper">
                <SunIcon />
            </div>
            <h4 className="success-main-text diversity-layout">{mainText}</h4>
            <h6 className="success-subtext diversity-layout">{subtext}</h6>
            <a href={linkUrl} className="success-cta-button diversity-layout">
                {buttonText}
            </a>
        </React.Fragment>
    );

    return (
        <FlowStep name={CONFIRM_SUCCESS_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-ConfirmSuccess">
                <div className="confirm-success-content-wrapper">
                    {layout === 'default' ? defaultLayout : diversityLayout}
                </div>
            </div>
        </FlowStep>
    );
};

ConfirmSuccess.propTypes = {
    mainText: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    buttonText: PropTypes.string,
    layout: PropTypes.oneOf(['default', 'diversity']),
};

ConfirmSuccess.defaultProps = {
    linkUrl: '/users/profile',
    buttonText: 'Go to profile',
    layout: 'default',
};

ConfirmSuccess.displayName = 'ConfirmSuccess';

export default React.memo(ConfirmSuccess);
