import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import OpenFieldAndDropdownCard from '../../QuestionCards/OpenFieldAndDropdownCard/OpenFieldAndDropdownCard';
import './EthnicityCard.scss';

export default class EthnicityCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            ethnicity: null,
            dropdownOptions: props.dropdownOptions,
        };
    }

    static propTypes = {
        questionName: PropTypes.string,
        dropdownOptions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]).isRequired,
                label: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]).isRequired,
                //Optional: if it has a url,
                url: PropTypes.string,
                //Optional: If it has an image please provide
                img: PropTypes.shape({
                    src: PropTypes.string,
                    alt: PropTypes.string,
                }),
            })
        ),
    };

    static defaultProps = {
        questionName: 'ethnicity',
        dropdownOptions: [
            {
                value: 'caucasian',
                label: 'Caucasian',
            },
            {
                value: 'black',
                label: 'Black',
            },
            {
                value: 'hispanic',
                label: 'Hispanic',
            },
            {
                value: 'asian',
                label: 'Asian or Pacific Islander',
            },
            {
                value: 'native',
                label: 'Native American or Alaska Native',
            },
            {
                value: 'mixed',
                label: 'Mixed',
            },
            {
                value: 'other',
                label: 'Other',
            },
            {
                value: 'undisclosed',
                label: 'Prefer not to say',
            },
        ],
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-demographic-4',
            misc_event_count: 1,
        });
        const EthnicityObject = this.props.ethnicity
            ? {
                  ethnicity: this.props.ethnicity,
              }
            : null;

        this.props.ethnicity
            ? this.props.onAnswer(
                  this.props.questionName,
                  EthnicityObject,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    handleDropdownValue = (selection) => {
        const ethnicity =
            selection.length && selection !== 'Select Race/Ethnicity'
                ? selection
                : null;

        this.setState(
            {
                ethnicity,
            },
            this.onAnswer
        );
    };

    onAnswer = () => {
        const { ethnicity } = this.state;
        let nextAction = CAN_SUBMIT;

        const payload = ethnicity
            ? {
                  ethnicity,
              }
            : { ethnicity: null };

        this.props.onAnswer(this.props.questionName, payload, nextAction);
    };

    render() {
        const props = {
            hasDropdown: true,
            handleDropdownValue: this.handleDropdownValue,
            dropdownDefaultText: 'Select Race/Ethnicity',
            prevAnswer: this.props.ethnicity,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-EthnicityCard">
                <OpenFieldAndDropdownCard
                    {...props}
                    dropdownOptions={this.state.dropdownOptions}
                />
            </div>
        );
    }
}
