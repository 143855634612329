export const fullUserMenu = [
    {
        key: 'my-profile',
        title: 'My Profile',
        url: '/users/profile',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'my-messages',
        title: 'My Messages',
        url: '/messages',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'my-network',
        title: 'My Network',
        url: '/users/connections',
        displayInDropdown: false,
        displayInDrawer: true,
    },
    {
        key: 'advice',
        title: 'Advice',
        url: '/advice',
        displayInDropdown: false,
        displayInDrawer: true,
    },
    {
        key: 'my-settings',
        title: 'My Settings',
        url: '/users/alerts',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'groups',
        title: 'Groups',
        url: '/groups',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'events',
        title: 'Events',
        url: '/events',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'my-posts',
        title: 'My Posts',
        url: '/users/contributions',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'contributor-access',
        title: 'Contributor Access',
        url: '/management/editorial-contents',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'admin-access',
        title: 'Admin Access',
        url: '/management',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'employer-settings',
        title: 'My Email Settings',
        url: '/employers/settings',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'employer-profile',
        title: 'My Profile',
        url: '/employers/profile',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'employer-messages',
        title: 'My Messages',
        url: '/employers/messages',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'employer-manage-user',
        title: 'Manage Users',
        url: '/employers/permissions',
        displayInDropdown: true,
        displayInDrawer: true,
    },
    {
        key: 'log-out',
        title: 'Log Out',
        url: '/users/logout',
        displayInDropdown: true,
        displayInDrawer: true,
    },
];

export const employerNavFgbAdminMenuKeys = ['log-out'];

export const employerNavDefaultMenuKeys = [
    'employer-profile',
    'employer-settings',
    'log-out',
];

export const employerNavRecruiterMenuKeys = [
    'employer-profile',
    'employer-settings',
    'employer-messages',
    'log-out',
];

export const employerNavAdminMenuKeys = [
    'employer-profile',
    'employer-settings',
    'employer-messages',
    'employer-manage-user',
    'log-out',
];

export const employerNavAdminManageMenuKeys = [
    'employer-profile',
    'employer-settings',
    'employer-manage-user',
    'log-out',
];

export const userNavDefaultMenuKeys = [
    'my-profile',
    'my-messages',
    'my-network',
    'my-settings',
    'groups',
    'events',
    'my-posts',
    'log-out',
];

export const userNavContributorMenuKeys = [
    'my-profile',
    'my-messages',
    'my-network',
    'my-settings',
    'groups',
    'events',
    'my-posts',
    'contributor-access',
    'log-out',
];

export const userNavAdminMenuKeys = [
    'my-profile',
    'my-messages',
    'my-network',
    'advice',
    'my-settings',
    'groups',
    'events',
    'my-posts',
    'admin-access',
    'log-out',
];

export const userNavEmployerMenuKeys = [
    'my-profile',
    'my-messages',
    'my-network',
    'advice',
    'my-settings',
    'groups',
    'events',
    'my-posts',
    'log-out',
];
