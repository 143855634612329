import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';
import './UnequalAttributes.scss';

export default class UnequalAttributes extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {};
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        buttonType: PropTypes.string,
        nextStep: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'multi',
        buttonData: [
            {
                title: 'Pay',
                value: 'pay',
            },
            {
                title: 'Promotion',
                value: 'promotion',
            },
            {
                title: 'Hiring',
                value: 'hiring',
            },
            {
                title: 'Evaluations and reviews',
                value: 'evaluation',
            },
            {
                title: 'Something else',
                value: 'other',
            },
        ],
    };

    handleUpdate = (data) => {
        const hasItems = data && !!data.length;
        const answer = {
            unequalAttributes: hasItems ? data : null,
        };
        const answerAction = hasItems ? CAN_SUBMIT : CANT_SUBMIT;

        this.props.onAnswer(
            'treatedEqually',
            answer,
            answerAction,
            true,
            this.props.nextStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-company-4a',
            misc_event_count: 1,
        });
        if (typeof this.props.unequalAttributes !== 'undefined') {
            const answer = {
                unequalAttributes: this.props.unequalAttributes,
            };
            this.props.onAnswer(
                'treatedEqually',
                answer,
                CAN_SUBMIT,
                true,
                this.props.nextStep
            );
        }
    }

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-UnequalAttributes">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={this.props.unequalAttributes}
                />
            </div>
        );
    }
}
