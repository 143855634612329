import PropTypes from 'prop-types';
import React from 'react';

import './DesktopFooter.scss';
import UserDesktopFooter from './UserDesktopFooter/UserDesktopFooter';
import EmployerDesktopFooter from './EmployerDesktopFooter/EmployerDesktopFooter';
import { pageDisplayModePropTypes } from '../../../../pages/defaultProps';
import { PAGE_DISPLAY_MODE } from '../../../../pages/constants';

const FooterController = (props) => {
    if (props.pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT) {
        return <EmployerDesktopFooter {...props} />;
    }

    return <UserDesktopFooter {...props} />;
};

export default class DesktopFooter extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * feature flags
         */
        featureFlags: PropTypes.object,
        /**
         * Value of the view layout the page is in
         */
        pageDisplayMode: pageDisplayModePropTypes.isRequired,
        /**
         * Number of social media likes
         */
        socialLikes: PropTypes.object.isRequired,
        /**
         * meta data for social icons
         */
        socialIcons: PropTypes.array.isRequired,
        /**
         * footer's menu for navigation
         */
        footerMenuItems: PropTypes.array.isRequired,
    };

    static defaultProps = {
        featureFlags: {},
        pageDisplayMode: PAGE_DISPLAY_MODE.USER_DEFAULT,
        footerMenuItems: [],
        socialIcons: [],
        socialLikes: {
            facebook: '',
            linkedIn: '',
            instagram: '',
            twitter: '',
        },
    };

    render() {
        const {
            socialLikes,
            pageDisplayMode,
            footerMenuItems,
            socialIcons,
            featureFlags,
        } = this.props;

        return (
            <div className="component-Layout-Footer-DesktopFooter">
                <FooterController
                    footerMenuItems={footerMenuItems}
                    socialIcons={socialIcons}
                    socialLikes={socialLikes}
                    pageDisplayMode={pageDisplayMode}
                    featureFlags={featureFlags}
                />
            </div>
        );
    }
}
