import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider, Header as UiHeader } from '@fairygodboss/ui';
import {
    isEmpty,
    checkAllUserCompaniesRolesWithCompanyIds,
} from '../../../utilities/helperFunctions';
import { buildUserLogo } from '../../../utilities/images';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import { ROLE_TYPES, PAGE_DISPLAY_MODE } from '../../../pages/constants';
import {
    CREATE_LAYOUT,
    LOGIN_LAYOUT,
} from '../../Login/LoginLayoutComponent/Layouts/LogInLayout/constants';
import { pageDisplayModePropTypes } from '../../../pages/defaultProps';
import EmployerNavbar from './Navbars/EmployerNavbar/EmployerNavbar';
import './Header.scss';
import { PageContext } from '../../../contexts/ContextCreator';
import ActionsProvider from './Providers/ActionsProvider';
import UserMenuProvider from './Providers/UserMenuProvider';

const Header = ({
    session,
    loginWidgetProps,
    badgeCountData,
    notifications,
    checkIsLoggedIn,
    pageDisplayMode,
    featureFlags,
    useLowDistractionModal,
    isAdmin,
}) => {
    const { userCompaniesRoles } = session;
    const userCompaniesIds =
        userCompaniesRoles && Object.keys(userCompaniesRoles);

    const pageCtx = React.useContext(PageContext);
    const { modalTrigger } = pageCtx;

    const userEmployerRoles = checkAllUserCompaniesRolesWithCompanyIds(
        userCompaniesIds,
        userCompaniesRoles
    );
    const checkIfAuthorizedForEmployerNav = (userRole) => {
        return (
            userRole === ROLE_TYPES.USER_ADMIN ||
            userRole === ROLE_TYPES.EMPLOYER_DEFAULT ||
            userRole === ROLE_TYPES.EMPLOYER_RECRUITER ||
            userRole === ROLE_TYPES.EMPLOYER_ADMIN
        );
    };

    const getUserRole = () => {
        const isAuthenticated = checkIsLoggedIn();

        if (!isAuthenticated || isEmpty(session)) {
            return ROLE_TYPES.UNAUTH;
        }

        if (
            isAdmin ||
            [
                'eventsManager',
                'adManager',
                'customerSuccess',
                'contentModerator',
                'fullEditor',
            ].some((role) => session?.additionalRoles?.includes(role))
        ) {
            return ROLE_TYPES.USER_ADMIN;
        }

        if (userEmployerRoles.isAdmin) {
            return ROLE_TYPES.EMPLOYER_ADMIN;
        }

        if (userEmployerRoles.isRecruiter) {
            return ROLE_TYPES.EMPLOYER_RECRUITER;
        }

        if (userEmployerRoles.isEmployee) {
            return ROLE_TYPES.EMPLOYER_DEFAULT;
        }

        if (session?.additionalRoles?.indexOf('author') >= 0) {
            return ROLE_TYPES.USER_CONTRIBUTOR;
        }

        return ROLE_TYPES.USER_DEFAULT;
    };

    const userRole = getUserRole();
    const sessionUsername = session?.username;
    const lowDistractionStyling = useLowDistractionModal
        ? 'low-distraction-styling-header'
        : '';
    const { pendingConnections, unreadMessageCount } = badgeCountData;
    const totalCount = pendingConnections + unreadMessageCount;
    const user =
        userRole !== ROLE_TYPES.UNAUTH
            ? {
                  email: session?.username,
                  role: userRole,
                  pendingConnections,
                  unreadMessageCount,
                  totalCount,
                  karmaScore: session?.karma_score,
                  logo: buildUserLogo(session.logo),
                  username: sessionUsername,
                  alerts: notifications?.unseenCount,
                  additionalRoles: session?.additionalRoles,
                  isClient: checkIfAuthorizedForEmployerNav(userRole),
                  isAdmin: userRole === ROLE_TYPES.USER_ADMIN,
                  isVip: session?.userCommunityData?.isVip,
              }
            : null;
    const {
        // eslint-disable-next-line react/prop-types
        auth0,
        // eslint-disable-next-line react/prop-types
        currentPath,
        // eslint-disable-next-line react/prop-types
        redirectUri,
        // eslint-disable-next-line react/prop-types
        invitationToken,
    } = loginWidgetProps;

    const loginModalProps = {
        auth0,
        loginRedirectPath: currentPath,
        redirectUri,
        loginMetrics: null,
        invitationToken,
    };
    if (pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT) {
        const athorizedForEmployerNav = checkIfAuthorizedForEmployerNav(
            userRole
        );
        const handleMetricEvent = (metricEventType = null) => () => {
            if (metricEventType) {
                EmitMetric({
                    misc_event_count: 1,
                    misc_event_type: metricEventType,
                });
            }
        };
        return (
            <header
                className={`component-Layout-Header ${lowDistractionStyling}`}
            >
                <ActionsProvider>
                    {({ setActionState, getActionState }) => (
                        <UserMenuProvider
                            userRole={userRole}
                            pageDisplayMode={pageDisplayMode}
                            badgeCountData={badgeCountData}
                            featureFlags={featureFlags}
                        >
                            {({
                                userDropDownMenu,
                                hasEmployerMessageInUserMenu,
                            }) => (
                                <React.Fragment>
                                    <EmployerNavbar
                                        userRole={userRole}
                                        session={session}
                                        setActionState={setActionState}
                                        getActionState={getActionState}
                                        badgeCountData={badgeCountData}
                                        notifications={notifications}
                                        athorizedForEmployerNav={
                                            athorizedForEmployerNav
                                        }
                                        userDropdownProps={{
                                            isDropdownOpen: getActionState(
                                                'isUserDropdownOpen'
                                            ),
                                            profileImag: session.logo,
                                            headerText: sessionUsername,
                                            onClose: () =>
                                                setActionState(
                                                    'isUserDropdownOpen',
                                                    false
                                                ),
                                            menuItems: userDropDownMenu || [],
                                        }}
                                        isLoggedIn={checkIsLoggedIn()}
                                        handleMetricEvent={handleMetricEvent}
                                        hasEmployerMessageInUserMenu={
                                            hasEmployerMessageInUserMenu
                                        }
                                        loginWidgetProps={loginWidgetProps}
                                    />
                                </React.Fragment>
                            )}
                        </UserMenuProvider>
                    )}
                </ActionsProvider>
            </header>
        );
    }
    return (
        <header className={`component-Layout-Header ${lowDistractionStyling}`}>
            <ThemeProvider>
                <UiHeader
                    className="fairygodboss-header"
                    user={user}
                    onSigninClick={() => {
                        modalTrigger('authModal', {
                            ...loginModalProps,
                            layoutType: LOGIN_LAYOUT,
                        });
                    }}
                    onSignupClick={() => {
                        modalTrigger('authModal', {
                            ...loginModalProps,
                            layoutType: CREATE_LAYOUT,
                        });
                    }}
                    emitMetric={EmitMetric}
                    useOldBreakPoints
                />
            </ThemeProvider>
        </header>
    );
};

Header.propTypes = {
    /**
     * Props for login widget component
     */
    loginWidgetProps: PropTypes.shape({}),
    /**
     * data for badge
     */
    badgeCountData: PropTypes.shape({
        pendingConnections: PropTypes.number,
        unreadMessageCount: PropTypes.number,
    }),
    /**
     * data for notifications
     */
    notifications: PropTypes.shape({ unseenCount: PropTypes.number })
        .isRequired,
    /**
     * function to check whether user is logged in or not
     */
    checkIsLoggedIn: PropTypes.func.isRequired,
    /**
     * whether user is an admin or not
     */
    isAdmin: PropTypes.bool.isRequired,
    /**
     * Props for footer
     */
    footerProps: PropTypes.shape({}).isRequired,
    /**
     * feature flags
     */
    featureFlags: PropTypes.shape({}),
    /**
     * session of current user
     */
    pageDisplayMode: pageDisplayModePropTypes,
    session: PropTypes.shape({
        id: PropTypes.number,
        additionalRoles: PropTypes.arrayOf(PropTypes.string),
        userCompaniesRoles: PropTypes.shape({}),
        logo: PropTypes.string,
        userCommunityData: PropTypes.shape({
            isVip: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        }),
        username: PropTypes.string,
        karma_score: PropTypes.number,
    }),
    /**
     * prop enables design where mobile does not have second menu bar
     */
    useLowDistractionModal: PropTypes.bool,
};

Header.defaultProps = {
    pageDisplayMode: PAGE_DISPLAY_MODE.USER_DEFAULT,
    loginWidgetProps: {},
    session: {},
    featureFlags: {},
    badgeCountData: {},
    useLowDistractionModal: false,
};

Header.displayName = 'Header';
export default Header;
