import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import RatingsCard from '../../QuestionCards/RatingsCard/RatingsCard';
import './FlexibilityCard.scss';

export default class FlexibilityCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            checkboxActive: !!props.flexibilityComment,
            rating: props.flexibilityRating,
        };
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'flexibility',
    };

    componentDidMount = () => {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        const RatingObject = {
            flexibilityRating: this.props.flexibilityRating,
        };
        let nextAction = CAN_SUBMIT;
        if (this.props.flexibilityComment) {
            RatingObject.flexibilityComment = this.props.flexibilityComment;
            nextAction = ALLOW_SUB_STEP;
        }
        this.props.flexibilityRating
            ? this.props.onAnswer(
                  this.props.questionName,
                  RatingObject,
                  nextAction,
                  false,
                  this.props.nextStep,
                  this.props.subStep
              )
            : null;
    };

    handleRating = (rating) => {
        this.setState({ rating }, () => this.checkAction());
    };

    handleCheck = () => {
        this.setState(
            {
                checkboxActive: !this.state.checkboxActive,
            },
            () => this.checkAction()
        );
    };

    checkAction = () => {
        let nextAction = CANT_SUBMIT;
        let answerData = { flexibilityRating: this.state.rating };
        if (this.state.rating) {
            nextAction = CAN_SUBMIT;
            if (this.state.checkboxActive) {
                if (this.props.flexibilityComment) {
                    answerData.flexibilityComment = this.props.flexibilityComment;
                }
                nextAction = ALLOW_SUB_STEP;
            }
        }
        this.props.onAnswer(
            this.props.questionName,
            answerData,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    render() {
        const props = {
            ratingType: 'RatingWatch',
            handleRating: this.handleRating,
            handleCheck: this.handleCheck,
            descriptions: {
                low: 'Very Inflexible',
                mid: 'Neutral',
                high: 'Very Flexible',
            },
            showCheckbox: true,
            rating: this.state.rating,
            defaultChecked: this.state.checkboxActive,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-FlexibilityCard">
                <RatingsCard {...props} />
            </div>
        );
    }
}
