import PropTypes from 'prop-types';
import React from 'react';
import CheckBox from '../../../Content/Elements/CheckBox/CheckBox';

import './FormCheckboxWithDescription.scss';

export default class FormCheckboxWithDescription extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string,
        defaultChecked: PropTypes.bool,
    };

    static defaultProps = {
        label: 'Let me explain more',
    };

    render() {
        return (
            <div className="component-ReviewSurvey-Components-FormCheckboxWithDescription">
                <div className="checkbox-container">
                    <CheckBox
                        id={'rating-survey'}
                        handleChange={this.props.onClick}
                        defaultChecked={this.props.defaultChecked}
                    />
                    <div className={'label'}>{this.props.label}</div>
                </div>
            </div>
        );
    }
}
