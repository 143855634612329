import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
    GO_SUB_STEP,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

import './TreatedEqually.scss';

export default class TreatedEqually extends React.PureComponent {
    constructor() {
        super();
        this.state = {};
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'single',
        buttonData: [
            {
                title: 'Yes',
                value: true,
            },
            {
                title: 'No',
                value: false,
            },
        ],
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-company-4',
            misc_event_count: 1,
        });
        const TreatedEquallyObject = {
            treatedEqually: this.props.treatedEqually,
        };
        let nextAction = CAN_SUBMIT;

        if (
            this.props.unequalAttributes ||
            TreatedEquallyObject.treatedEqually === false
        ) {
            TreatedEquallyObject.unequalAttributes = this.props.unequalAttributes;
            nextAction = ALLOW_SUB_STEP;
        }

        this.props.treatedEqually !== undefined
            ? this.props.onAnswer(
                  'treatedEqually',
                  TreatedEquallyObject,
                  nextAction,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    handleUpdate = (data) => {
        const response = typeof data[0] === 'undefined' ? null : data[0];
        const treatedEquallyObject = { treatedEqually: response };
        let nextAction = CANT_SUBMIT;

        if (this.props.unequalAttributes) {
            treatedEquallyObject.unequalAttributes = this.props.unequalAttributes;
        }

        if (response === true) {
            if (this.props.unequalAttributes)
                delete treatedEquallyObject.unequalAttributes;
            nextAction = SUBMIT;
        } else if (response === false) {
            nextAction = GO_SUB_STEP;
        }

        this.props.onAnswer(
            'treatedEqually',
            treatedEquallyObject,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-TreatedEqually">
                <ButtonCard
                    {...this.props}
                    selectedItems={this.props.treatedEqually}
                    onClick={this.handleUpdate}
                />
            </div>
        );
    }
}
