import PropTypes from 'prop-types';
import React from 'react';

const WithTypeahead = (WrappedComponent) => {
    return class extends React.PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                typeaheadInput: '',
            };
        }

        static propTypes = {
            onUpdate: PropTypes.func,
            onErrorUpdate: PropTypes.func,
        };

        static defaultProps = {
            onUpdate: () =>
                console.warn(
                    'no function passed by prop to Typeahead for onUpdate'
                ),
            onErrorUpdate: () => {},
        };

        handleSelect = (updatedItems) => {
            if (Array.isArray(updatedItems)) {
                this.props.onUpdate([...updatedItems]);
            } else {
                this.props.onUpdate(updatedItems);
            }

            this.handleError();
        };

        handleError = (error = '') => this.props.onErrorUpdate(error);

        setTypeaheadInput = (typeaheadInput) =>
            this.setState({ typeaheadInput });

        render() {
            return (
                <WrappedComponent
                    handleSelect={this.handleSelect}
                    handleError={this.handleError}
                    setTypeaheadInput={this.setTypeaheadInput}
                    typeaheadInput={this.state.typeaheadInput}
                    {...this.props}
                />
            );
        }
    };
};

export default WithTypeahead;
