import PropTypes from 'prop-types';
import React from 'react';
import {
    CANT_SUBMIT,
    JobReviewConsumer,
} from '../../../contexts/JobReviewContext';
import FGBLabel from '../../Elements/FGBLabel/FGBLabel';
import PaginationNav from '../Components/PaginationNav/PaginationNav';
import './ReviewSurveyCardShell.scss';

export default class ReviewSurveyCardShell extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * Text in label
         */
        label: PropTypes.string,
        /**
         * Question number and text
         */
        title: PropTypes.node,
        /**
         * subtitle text
         */
        subtitle: PropTypes.string,
        /**
         * body component
         */
        children: PropTypes.node,
        /**
         * function to submit answer IF and ONLY if, the showNext button is shown
         */
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        label: 'Review your company',
    };

    render() {
        return (
            <div className="component-ReviewSurvey-ReviewSurveyCardShell">
                <JobReviewConsumer>
                    {(jobReviewContext) => {
                        const currentCard = jobReviewContext.currentCard;
                        const Component =
                            currentCard && currentCard.component
                                ? currentCard.component
                                : null;
                        let bodyProps =
                            currentCard && currentCard.bodyProps
                                ? currentCard.bodyProps
                                : {};
                        bodyProps = Object.assign(
                            {},
                            bodyProps,
                            this.props.PAGE_PROPS
                        );
                        const prevAnswers = currentCard.question
                            ? jobReviewContext.answerPayload.jobReview[
                                  currentCard.question
                              ]
                            : {};
                        const BodyProps = {
                            loggedIn: jobReviewContext.loggedIn,
                            ...jobReviewContext,
                            ...bodyProps,
                            ...prevAnswers,
                        };
                        const cardType = currentCard.skippable
                            ? 'demographic'
                            : 'company';
                        const showNext =
                            jobReviewContext.nextAction !== CANT_SUBMIT ||
                            currentCard.skippable;
                        const skippable =
                            currentCard.skippable &&
                            !jobReviewContext.answerPayload.jobReview[
                                currentCard.question
                            ];

                        return (
                            <React.Fragment>
                                <FGBLabel>{currentCard.label}</FGBLabel>
                                <div className="content">
                                    <div className="question-title">
                                        {currentCard.title}
                                    </div>
                                    <div className="body-section">
                                        <div className="question-subtitle">
                                            {currentCard.subtitle}
                                        </div>
                                        {Component ? (
                                            <Component
                                                key={
                                                    jobReviewContext.displayQuestionKey
                                                }
                                                {...BodyProps}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <PaginationNav
                                    showNextBtn={showNext}
                                    onPrev={jobReviewContext.onPrev}
                                    showPrevBtn={currentCard.showPrev}
                                    onNext={() =>
                                        jobReviewContext.onSubmit(
                                            currentCard.bodyProps.nextStep,
                                            currentCard.bodyProps.subStep,
                                            cardType,
                                            skippable,
                                            currentCard.demographicNumber
                                        )
                                    }
                                />
                            </React.Fragment>
                        );
                    }}
                </JobReviewConsumer>
            </div>
        );
    }
}
