import PropTypes from 'prop-types';
import React from 'react';

import { Counter } from '../../../Modules/Counter/Counter';

import './MenuItem.scss';

export default class MenuItem extends React.PureComponent {
    static propTypes = {
        /**
         * custom css classname
         */
        cssClass: PropTypes.string,
        /**
         * title for menu item
         */
        title: PropTypes.string.isRequired,
        /**
         * url for title
         */
        url: PropTypes.string.isRequired,
        /**
         * count if menu item has an indicator/badge
         */
        count: PropTypes.number,
        /**
         * handler for custom onClick
         */
        onClick: PropTypes.func,
        /**
         * type of badge
         */
        type: PropTypes.string,
    };

    static defaultProps = {
        cssClass: '',
        url: '/',
        title: '',
        onClick: () => {},
        type: 'default',
    };

    render() {
        const { count, cssClass, title, url, type } = this.props;
        return (
            <a
                href={url}
                className={`component-Layout-Header-MenuItem ${cssClass}`}
                onClick={this.props.onClick}
            >
                {title}
                {count && (
                    <Counter
                        cssClass={`menu-item-${title}`}
                        countNumber={count}
                        type={type}
                    />
                )}
            </a>
        );
    }
}
