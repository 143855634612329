import PropTypes from 'prop-types';
import React from 'react';
import ResponsivePicture, {
    BREAKPOINT_SHAPE,
} from '../../../Elements/ResponsivePicture/ResponsivePicture';
import './Thumbnail.scss';

export default class Thumbnail extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * Image Source (Cloudinary Id)
         * */
        cloudinaryID: PropTypes.string.isRequired,
        /**
         * Image Alt
         * */
        alt: PropTypes.string,
        coverage: PropTypes.string,
        maxWidth: PropTypes.shape({
            // DEPRECATED, use breakpoints instead
            tablet: PropTypes.number.isRequired,
            desktop: PropTypes.number.isRequired,
            mobile: PropTypes.number.isRequired,
        }),
        breakpoints: BREAKPOINT_SHAPE,
    };

    static defaultProps = {
        alt: '',
        coverage: 'cover',
        maxWidth: {
            tablet: 350,
            desktop: 350,
            mobile: 350,
        },
        breakpoints: null,
    };

    mapMaxWidthToBreakpoints = (maxWidth) => {
        // for backwards compatibility.
        // This converts the old maxWidth prop that the ResponsiveImage component expected to the breakpoints format that ResponsivePicture uses.
        const keys = Object.keys(maxWidth);
        const output = {};
        keys.map((key) => {
            output[key] = { width: maxWidth[key] };
        });
        return output;
    };

    getBreakpoints = () => {
        return (
            this.props.breakpoints ||
            (this.props.maxWidth &&
                this.mapMaxWidthToBreakpoints(this.props.maxWidth))
        );
    };

    render() {
        return (
            <div className="component-Content-Image-Thumbnail d-flex justify-content-center align-items-center">
                <div className="imageDisplay">
                    <ResponsivePicture
                        cloudinaryID={this.props.cloudinaryID}
                        breakpoints={this.getBreakpoints()}
                    />
                </div>
            </div>
        );
    }
}
