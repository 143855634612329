import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from '../../../../../../utilities/helperFunctions';

import './Radio.scss';

export const Radio = ({ checked, value, disabled, onChange, group }) => {
    const handleChange = React.useCallback(
        (e) => onChange && onChange({ group, value: e.target.value }),
        [group, onChange]
    );

    const buttonId = `${group}[${value}]`;

    const displayValue = capitalize(value);

    return (
        <div className="component-Modals-ModalParts-Inputs-Radios-Radio">
            <input
                id={buttonId}
                type="radio"
                aria-checked={checked}
                name={group}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
            <label className="radio-button-label" htmlFor={buttonId}>
                <div className="radio-display" />
                {displayValue}
            </label>
        </div>
    );
};

Radio.propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    group: PropTypes.string.isRequired,
};

Radio.defaultProps = {
    checked: false,
    onChange: null,
    disabled: false,
};

Radio.displayName = 'Radio';

export default React.memo(Radio);
