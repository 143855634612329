import PropTypes from 'prop-types';
import React from 'react';

import UserListItem from '../../Users/UserListItem/UserListItem';

export default class ConnectedUser extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * object of the user current user is connected to
         */
        connectedUser: PropTypes.object.isRequired,
        /**
         * function to call on cta btn click
         * e.g. update the real time count edge case
         * when user does a click action and
         * only one item is on the next page
         */
        callbackFn: PropTypes.func,
        /**
         * number of index
         */
        index: PropTypes.number,
        /**
         * string of custom css classname
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        callbackFn: () => {},
        className: 'network-item',
    };

    render() {
        const { connectedUser, callbackFn, index, className } = this.props;
        return (
            <li className="component-Network-ConnectedUser">
                <UserListItem
                    key={`connectedUser-${index}`}
                    user={connectedUser}
                    buttonsToRender={['REMOVE']}
                    followStatus={false}
                    connectionStatus="connected"
                    className={className}
                    onClickHandlers={{ onRemoveCallback: callbackFn }}
                />
            </li>
        );
    }
}
