import React from 'react';

import './VerticalLine.scss';

export default class VerticalLine extends React.PureComponent {
    render() {
        return (
            <span
                className={`component-Layout-Header-Modules-NavbarItems-VerticalLine ${this.props.className}`}
            >
                |
            </span>
        );
    }
}
