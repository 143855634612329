import React from 'react';

export default (
    <svg
        className={'svg-prev-arrow'}
        width="13"
        height="22"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 1L2 11l10 10"
            stroke="#982EA9"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
        />
    </svg>
);
