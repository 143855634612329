export const socialIcons = [
    {
        type: 'facebook',
        url: 'https://www.facebook.com/Fairygodboss/',
    },
    {
        type: 'instagram',
        url: 'https://www.instagram.com/fairygodboss/',
    },
    {
        type: 'twitter',
        url: 'https://twitter.com/fairygodboss/',
    },
    {
        type: 'linkedIn',
        url: 'https://www.linkedin.com/company/fairygodboss/',
    },
];

export const FOOTER_TYPES = Object.freeze({
    DESKTOP_FOOTER: 'DESKTOP_FOOTER',
    DRAWER_FOOTER: 'DRAWER_FOOTER',
    LEFT_RAIL_FOOTER: 'LEFT_RAIL_FOOTER',
});

export const fullFooterMenu = [
    {
        key: 'about',
        title: 'about',
        children: [
            {
                key: 'about us',
                title: 'about us',
                url: '/company-overview/fairygodboss-inc',
                target: '_self',
            },
        ],
    },
    {
        key: 'careers',
        title: 'careers',
        url: '/company/jobs/fairygodboss-inc',
        target: '_self',
    },
    {
        key: 'faqs',
        title: 'FAQs',
        children: [
            {
                key: 'submissions & editorial guidelines',
                title: 'submissions & editorial guidelines',
                url: '/articles/submissions-editorial-guidelines',
                target: '_self',
            },
            {
                key: 'community guidelines',
                title: 'community guidelines',
                url: '/articles/community-guidelines',
                target: '_self',
            },
            {
                key: 'community partners',
                title: 'community partners',
                url: '/partners',
                target: '_self',
            },
        ],
    },
    {
        key: 'privacy policy',
        title: 'privacy policy',
        url: '/privacy-policy',
        target: '_self',
    },
    {
        key: 'terms & conditions',
        title: 'terms & conditions',
        url: '/terms-and-conditions',
        target: '_self',
    },
    {
        key: 'for employers',
        title: 'for employers',
        url: '/employers',
        target: '_self',
    },
    {
        key: 'contact us',
        title: 'contact us',
        children: [
            {
                key: 'contact us email',
                title: 'customer-support@fairygodboss.com',
                url: 'mailto: customer-support@fairygodboss.com',
                target: '_blank',
            },
        ],
    },
];

export const userDesktopFooterDefaultMenuKeys = [
    'about',
    'careers',
    'faqs',
    'privacy policy',
    'terms & conditions',
];

export const userDrawerFooterDefaultMenuKeys = [
    'about',
    'careers',
    'faqs',
    'privacy policy',
    'terms & conditions',
    'for employers',
];

export const userLeftRailFooterDefaultMenuKeys = [
    'about',
    'careers',
    'faqs',
    'privacy policy',
    'terms & conditions',
];

export const employerDesktopFooterDefaultMenuKeys = ['contact us'];

export const employerDrawerFooterDefaultMenuKeys = ['contact us'];

export const employerLeftRailFooterDefaultMenuKeys = ['contact us'];

export const discussionForumPostingValidTypes = [
    'community-photo',
    'community-discussion',
    'community-link',
];
