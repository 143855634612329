import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import CheckGroup from '../../../ModalParts/Inputs/CheckGroup/CheckGroup';

import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { SEXUAL_ORIENTATION_STEP_NAME } from '../constants';
import { sexualOrientationChoices } from '../../DiversityInput/constants';
import { shouldDisableRestCheckboxes } from '../RaceEthnicity/RaceEthnicity';
import usePrevious from '../../../../../utilities/hooks/usePrevious';

import './SexualOrientation.scss';

const SexualOrientation = ({ buttonNavBox }) => {
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        stepErrors,
    } = useGetStepFormProps(SEXUAL_ORIENTATION_STEP_NAME);

    const handleCheckSelect = React.useCallback(
        (checkedValues) => {
            updateFormValueAtPath('sexualOrientation.orientationChoices')(
                checkedValues
            );
        },
        [updateFormValueAtPath]
    );

    const currentOrientationChoices = stepFormProps.orientationChoices;
    const prevOrientationChoices = usePrevious(currentOrientationChoices) || [];

    const restCheckboxesAreDisabled = shouldDisableRestCheckboxes(
        currentOrientationChoices
    );

    // if use selects "I prefer not to say", remove all other selections and disable rest of checkboxes
    React.useEffect(() => {
        if (
            currentOrientationChoices.includes('I prefer not to say') &&
            !prevOrientationChoices.includes('I prefer not to say')
        ) {
            return updateFormValueAtPath(
                'sexualOrientation.orientationChoices'
            )(['I prefer not to say']);
        }
    }, [
        currentOrientationChoices,
        prevOrientationChoices,
        updateFormValueAtPath,
    ]);

    // Reset optionalOtherText when user selects Other, types text, then de-selects Other
    React.useEffect(() => {
        if (
            prevOrientationChoices.includes('Other') &&
            !currentOrientationChoices.includes('Other')
        ) {
            return updateFormValueAtPath('sexualOrientation.optionalOtherText')(
                ''
            );
        }
    }, [
        prevOrientationChoices,
        currentOrientationChoices,
        updateFormValueAtPath,
    ]);

    return (
        <FlowStep name={SEXUAL_ORIENTATION_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-SexualOrientation">
                <div className="primary-step-text">
                    What best describes your sexual orientation? (Select all
                    that apply.)
                </div>
                <CheckGroup
                    groupName="sexualOrientation.orientationChoices"
                    checkedValues={currentOrientationChoices}
                    onChange={handleCheckSelect}
                >
                    {(Checkbox) =>
                        sexualOrientationChoices.map((soc) => {
                            if (soc === 'I prefer not to say')
                                return <Checkbox key={soc} value={soc} />;

                            return (
                                <Checkbox
                                    key={soc}
                                    value={soc}
                                    disabled={restCheckboxesAreDisabled}
                                />
                            );
                        })
                    }
                </CheckGroup>
                <div className="optional-other-box">
                    {stepFormProps.orientationChoices.includes('Other') ? (
                        <LongTextInput
                            inputName="sexualOrientation.optionalOtherText"
                            value={stepFormProps.optionalOtherText}
                            placeholder="Please specify..."
                            type="textarea"
                            error={stepErrors.optionalOtherText}
                            handleChange={updateFormValues}
                            rows={1}
                            maxChars={200}
                        />
                    ) : null}
                </div>
            </div>
            {buttonNavBox}
        </FlowStep>
    );
};

SexualOrientation.propTypes = {
    buttonNavBox: PropTypes.node.isRequired,
};

SexualOrientation.defaultProps = {};

export default React.memo(SexualOrientation);
