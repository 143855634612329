import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../Input/Button/Button';

import './ResetPasswordConfirmationLayout.scss';

export default class ResetPasswordConfirmationLayout extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * on click function
         */
        onClick: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-ResetPasswordConfirmationLayout">
                <div className="reset-password-confirmation-layout-subText-call-to-action-component">
                    Great. If your account exists in our database, you will
                    receive an email with instructions on how to reset your
                    password.
                </div>
                <div>
                    <Button
                        type={'solid'}
                        value={'Back to login'}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}
