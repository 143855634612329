import {
    PASSWORD_INVALID_LENGTH,
    INVALID_PASSWORD_LENGTH,
    PASSWORD_MATCH,
    PASSWORD_MISSING_LOWERCASE,
    PASSWORD_MISSING_NUMBER,
    PASSWORD_MISSING_SPECIAL_CHARACTER,
    PASSWORD_MISSING_UPPERCASE,
} from '../../../validation/constants';

export const errorMessagesMap = {
    [PASSWORD_INVALID_LENGTH]: 'Password must be at least 8 characters',
    [INVALID_PASSWORD_LENGTH]: 'Password must be at least 8 characters',
    [PASSWORD_MATCH]: 'Passwords do not match',
    [PASSWORD_MISSING_UPPERCASE]:
        'Password must have at least 1 uppercase letter',
    [PASSWORD_MISSING_LOWERCASE]:
        'Password must have at least 1 lowercase letter',
    [PASSWORD_MISSING_NUMBER]: 'Password must have at least one number',
    [PASSWORD_MISSING_SPECIAL_CHARACTER]:
        'Password must have at least one special character',
};
