export const RESPONSIVE_MOBILE_SMALL = 0;
export const RESPONSIVE_MOBILE_LARGE = 1;
export const RESPONSIVE_TABLET_SMALL = 2;
export const RESPONSIVE_TABLET_LARGE = 3;
export const RESPONSIVE_DESKTOP = 4;

export const AUTH_WRAPPER_COOKIE = 'AuthWrapper';

export const API_FETCH_LOGIN_WALL = '/api/get-signup-wall';

export const REGWALL_ADVICE_HUB = 'advice_hub';
export const REGWALL_RECENT_DISCUSSION = 'recent_dicussion';

export const defaultJobProfileSnippet = { hash: null, isCompleted: false };

export const getBreakpointOnResize = (num) => {
    let breakpoint = null;
    let isMobile = false;

    if (num >= 1200) {
        breakpoint = RESPONSIVE_DESKTOP;
    } else if (num >= 992 && num < 1200) {
        breakpoint = RESPONSIVE_TABLET_LARGE;
        isMobile = true;
    } else if (num >= 576 && num < 992) {
        breakpoint = RESPONSIVE_TABLET_SMALL;
        isMobile = true;
    } else if (num >= 375 && num < 576) {
        breakpoint = RESPONSIVE_MOBILE_LARGE;
        isMobile = true;
    } else {
        breakpoint = RESPONSIVE_MOBILE_SMALL;
        isMobile = true;
    }

    return { breakpoint, isMobile };
};

export const getRegwallValues = (key, pathname) =>
    ({
        [REGWALL_ADVICE_HUB]: {
            allowClosing: 0,
            buttonText: 'Get started',
            delaySeconds: 2,
            description:
                'Unlock to connect and get advice on the most powerful career platform for free.',
            forceLogin: 1,
            image: '/signup-wall/ucffjgsw07q5dih8spkl',
            overlayColor: 'black',
            placementName: 'Advice Hub Testing',
            redirectUrl: pathname,
            targetUrl: null,
            title: "There's a lot more to this story",
        },
        [REGWALL_RECENT_DISCUSSION]: {
            allowClosing: 0,
            buttonText: 'Join the community',
            delaySeconds: 2,
            description:
                'Our free online career community for women is here to help you solve your toughest work challenges.',
            forceLogin: 1,
            image: '/signup-wall/ucffjgsw07q5dih8spkl',
            overlayColor: 'black',
            placementName: 'Discussion  Testing',
            redirectUrl: pathname,
            targetUrl: null,
            title: 'Get your questions answered.',
        },
    }[key]);
