import React from 'react';
import Abandoned from '../../components/ReviewSurvey/IndividualQuestionCards/Abandoned/Abandoned';
import Benefits from '../../components/ReviewSurvey/IndividualQuestionCards/Benefits/Benefits';
import Bonus from '../../components/ReviewSurvey/IndividualQuestionCards/Bonus/Bonus';
import CeoGenderDiversity from '../../components/ReviewSurvey/IndividualQuestionCards/CeoGenderDiversity/CeoGenderDiversity';
import CommentCard from '../../components/ReviewSurvey/IndividualQuestionCards/CommentCard/CommentCard';
import CompanySelect from '../../components/ReviewSurvey/IndividualQuestionCards/CompanySelect/CompanySelect';
import Continue from '../../components/ReviewSurvey/IndividualQuestionCards/Continue/Continue';
import EmployerLocationCard from '../../components/ReviewSurvey/IndividualQuestionCards/EmployerLocationCard/EmployerLocationCard';
import EthnicityCard from '../../components/ReviewSurvey/IndividualQuestionCards/EthnicityCard/EthnicityCard';
import Finish from '../../components/ReviewSurvey/IndividualQuestionCards/Finish/Finish';
import FlexibilityCard from '../../components/ReviewSurvey/IndividualQuestionCards/FlexibilityCard/FlexibilityCard';

import Intro from '../../components/ReviewSurvey/IndividualQuestionCards/Intro/Intro';
import JobSatisfactionCard from '../../components/ReviewSurvey/IndividualQuestionCards/JobSatisfactionCard/JobSatisfactionCard';
import MakeBetter from '../../components/ReviewSurvey/IndividualQuestionCards/MakeBetter/MakeBetter';
import ManagerCard from '../../components/ReviewSurvey/IndividualQuestionCards/ManagerCard/ManagerCard';
import ParentalLeaveCard from '../../components/ReviewSurvey/IndividualQuestionCards/ParentalLeaveCard/ParentalLeaveCard';
import PositionCard from '../../components/ReviewSurvey/IndividualQuestionCards/PositionCard/PositionCard';
import Recommendation from '../../components/ReviewSurvey/IndividualQuestionCards/Recommendation/Recommendation';
import Salary from '../../components/ReviewSurvey/IndividualQuestionCards/Salary/Salary';
import SpecialGroups from '../../components/ReviewSurvey/IndividualQuestionCards/SpecialGroups/SpecialGroups';
import TellOtherWomenCard from '../../components/ReviewSurvey/IndividualQuestionCards/TellOtherWomenCard/TellOtherWomenCard';
import TreatedEqually from '../../components/ReviewSurvey/IndividualQuestionCards/TreatedEqually/TreatedEqually';
import UnequalAttributes from '../../components/ReviewSurvey/IndividualQuestionCards/TreatedEqually/UnequalAttributes';
import WorkLifeBalance from '../../components/ReviewSurvey/IndividualQuestionCards/WorkLifeBalance/WorkLifeBalance';

export const LABEL = 'Review Your Employer';
export const LABELTWO = 'About You';
export const NOSUBTITLE = null;
export const CHOOSEALL = 'Choose all that apply:';
export const PLEASEEXPLAIN = 'Please explain:';
export const CLICKNEXTSKIP = '(Click Next to skip.)';

export const ReviewSurveyCardsRequiredQuestions = [
    {
        Intro: {
            label: LABEL,
            title: (
                <div>
                    Be constructive! Your <span>anonymous</span> review helps
                    other women succeed!
                </div>
            ),
            subtitle:
                'It’ll only take a few minutes and you’ll get unlimited access to job reviews!',
            bodyProps: {
                nextStep: '1',
            },
            component: Intro,
            showPrev: false,
        },
    },
    {
        1: {
            label: LABEL,
            title: (
                <div>
                    1. <span>Which employer</span> are you reviewing?*
                </div>
            ),
            subtitle:
                '(Please limit yourself to an employer within the past 3 years.)',
            bodyProps: {
                nextStep: '2',
            },
            question: 'company',
            component: CompanySelect,
            showPrev: false,
        },
    },
    {
        2: {
            label: LABEL,
            title: (
                <div>
                    2. What <span>employer location</span> are you reviewing?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '3',
            },
            question: 'employerLocation',
            component: EmployerLocationCard,
        },
    },
    {
        3: {
            label: LABEL,
            title: (
                <div>
                    3. What's your overall level of{' '}
                    <span>job satisfaction?*</span>
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '4',
            },
            question: 'jobSatisfaction',
            component: JobSatisfactionCard,
        },
    },
    {
        4: {
            label: LABEL,
            title: (
                <div>
                    4. Do you believe women and men are{' '}
                    <span>treated equally</span> here?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '5',
                subStep: '4a',
            },
            question: 'treatedEqually',
            component: TreatedEqually,
        },
    },
    {
        '4a': {
            label: LABEL,
            title: <div>4a. What's unequal?*</div>,
            subtitle: 'Please choose all that apply:',
            bodyProps: {
                nextStep: '5',
            },
            question: 'treatedEqually',
            component: UnequalAttributes,
        },
    },
    {
        5: {
            label: LABEL,
            title: (
                <div>
                    5. Would you <span>recommend this employer</span> to other
                    women?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '6',
                subStep: '5a',
            },
            question: 'recommendation',
            component: Recommendation,
        },
    },
    {
        '5a': {
            label: LABEL,
            title: (
                <div>
                    5a. Would you <span>recommend this employer</span> to other
                    women?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: '6',
                payloadKey: 'recommendComment',
                isPartTwo: true,
                questionName: 'recommendation',
            },
            question: 'recommendation',
            component: CommentCard,
        },
    },
    {
        6: {
            label: LABEL,
            title: (
                <div>
                    6. What's the <span>most important thing</span> that could
                    be done to make you stay?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '7',
                subStep: '6a',
            },
            question: 'makeBetter',
            component: MakeBetter,
        },
    },
    {
        '6a': {
            label: LABEL,
            title: (
                <div>
                    6a. What's the <span>most important thing</span> that could
                    be done to make you stay?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: '7',
                isPartTwo: true,
                questionName: 'makeBetter',
                payloadKey: 'makeBetterComment',
            },
            question: 'makeBetter',
            component: CommentCard,
        },
    },
    {
        7: {
            label: LABEL,
            title: (
                <div>
                    7. Do you think <span>work-life balance</span> is supported
                    in the following ways?*
                </div>
            ),
            subtitle: CHOOSEALL,
            bodyProps: {
                nextStep: '8',
            },
            question: 'workLifeBalance',
            component: WorkLifeBalance,
        },
    },
    {
        8: {
            label: LABEL,
            title: (
                <div>
                    8. <span>How much flexibility</span> do you think exists?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '9',
                subStep: '8a',
            },
            question: 'flexibility',
            component: FlexibilityCard,
        },
    },
    {
        '8a': {
            label: LABEL,
            title: (
                <div>
                    8a. Anything else you'd like to add about flexibility?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: '9',
                isPartTwo: true,
                questionName: 'flexibility',
                payloadKey: 'flexibilityComment',
            },
            question: 'flexibility',
            component: CommentCard,
        },
    },
    {
        9: {
            label: LABEL,
            title: (
                <div>
                    9. <span>What would you tell another woman</span> about
                    working here?*
                </div>
            ),
            subtitle: 'Please share your thoughts in at least 10 words.',
            payloadKey: 'comment',
            bodyProps: {
                nextStep: '10',
                isPartTwo: false,
            },
            question: 'comment',
            component: TellOtherWomenCard,
        },
    },
    {
        10: {
            label: LABEL,
            title: (
                <div>
                    10. <span>How supportive</span> is your manager?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '11',
                subStep: '10a',
            },
            question: 'manager',
            component: ManagerCard,
        },
    },
    {
        '10a': {
            label: LABEL,
            title: (
                <div>
                    10a. <span>How supportive</span> is your manager?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: '11',
                isPartTwo: true,
                questionName: 'manager',
                payloadKey: 'managerComment',
            },
            question: 'manager',
            component: CommentCard,
        },
    },
    {
        11: {
            label: LABEL,
            title: (
                <div>
                    11. Do you think{' '}
                    <span>your CEO supports gender diversity</span> ?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            bodyProps: {
                nextStep: '12',
            },
            question: 'ceoGenderDiversity',
            component: CeoGenderDiversity,
        },
    },
    {
        12: {
            label: LABEL,
            title: (
                <div>
                    12. Are any of the following <span>benefits</span> offered?*
                </div>
            ),
            subtitle: 'Please choose all that apply:',
            bodyProps: {
                nextStep: '13',
                subStep: '12a',
            },
            question: 'benefit',
            component: Benefits,
        },
    },
    {
        '12a': {
            label: LABEL,
            title: (
                <div>
                    12a. Are any of the following <span>benefits</span>{' '}
                    offered?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: '13',
                payloadKey: 'benefitComment',
                isPartTwo: true,
                questionName: 'benefit',
            },
            question: 'benefit',
            component: CommentCard,
        },
    },
    {
        13: {
            label: LABEL,
            title: (
                <div>
                    13. Is <span>paid parental leave</span> offered, and if so,
                    how much?*
                </div>
            ),
            subtitle: NOSUBTITLE,
            question: 'parentalLeave',
            component: ParentalLeaveCard,
            bodyProps: {
                nextStep: 'End',
                subStep: '13a',
            },
        },
    },
    {
        '13a': {
            label: LABEL,
            title: (
                <div>
                    13a. Is <span>paid parental leave</span> offered, and if so,
                    how much?*
                </div>
            ),
            subtitle: PLEASEEXPLAIN,
            bodyProps: {
                nextStep: 'End',
                isPartTwo: true,
                questionName: 'parentalLeave',
                payloadKey: 'parentalLeaveComment',
            },
            question: 'parentalLeave',
            component: CommentCard,
        },
    },
    {
        End: {
            label: LABEL,
            title: <div>Thank you for paying it forward.</div>,
            subtitle: 'Can we ask you a few more optional questions?',
            bodyProps: {
                nextStep: '14',
                subStep: 'OptionalEnd',
            },
            showPrev: false,
            component: Continue,
        },
    },
    {
        14: {
            label: LABELTWO,
            title: (
                <div>
                    1. What's your <span>most recent salary</span> at this
                    employer (including internships)?
                </div>
            ),
            subtitle: CLICKNEXTSKIP,
            bodyProps: {
                nextStep: '15',
            },
            skippable: true,
            question: 'salary',
            demographicNumber: '1',
            component: Salary,
        },
    },
    {
        15: {
            label: LABELTWO,
            title: (
                <div>
                    2. What is your <span>most recent bonus</span> at this
                    employer?
                </div>
            ),
            subtitle: CLICKNEXTSKIP,
            bodyProps: {
                nextStep: '16',
            },
            skippable: true,
            question: 'bonus',
            demographicNumber: '2',
            component: Bonus,
        },
    },
    {
        16: {
            label: LABELTWO,
            title: (
                <div>
                    3. What's your <span>title or department</span> at this
                    employer
                </div>
            ),
            subtitle: CLICKNEXTSKIP,
            bodyProps: {
                nextStep: '17',
            },
            skippable: true,
            question: 'position',
            demographicNumber: '3',
            component: PositionCard,
        },
    },
    {
        17: {
            label: LABELTWO,
            title: (
                <div>
                    4. What's your <span>race/ethnicity</span>?
                </div>
            ),
            subtitle:
                'Note: We will not display this information and only use it to analyze how ethnicity may impact the experience of women at work. Click Next to skip.',
            bodyProps: {
                nextStep: '18',
            },
            skippable: true,
            question: 'ethnicity',
            demographicNumber: '4',
            component: EthnicityCard,
        },
    },
    {
        18: {
            label: LABELTWO,
            title: (
                <div>
                    5. <span>Do you identify</span> with any of the following?
                </div>
            ),
            subtitle:
                'Note: We will not display this information and only use it to analyze how identities may impact the experience of women at work.',
            bodyProps: {
                nextStep: 'OptionalEnd',
            },
            skippable: true,
            question: 'specialGroup',
            demographicNumber: '5',
            component: SpecialGroups,
        },
    },
    {
        OptionalEnd: {
            label: LABEL,
            title: (
                <div>
                    You're the <span>BEST!</span>
                </div>
            ),
            subtitle: 'Your voice helps improve the workplace for women.',
            bodyProps: {
                nextStep: null,
            },
            showPrev: false,
            component: Finish,
        },
    },
    {
        Abandoned: {
            label: '',
            title: (
                <div>
                    Almost done! <br /> Complete your job review.
                </div>
            ),
            subtitle: NOSUBTITLE,
            showPrev: false,
            component: Abandoned,
        },
    },
];

export const flattenQuestions = (obj) => {
    return obj.reduce((acc, val) => {
        Object.assign(acc, val);
        return acc;
    }, {});
};
