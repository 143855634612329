import React from 'react';
import PropTypes from 'prop-types';

import InputField from '../../../Input/InputField/InputField';
import CharCount from '../../../Elements/CharCount/CharCount';
import LocationInput from '../../../Input/LocationInput/LocationInput';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';
import { SchoolsTypeahead } from '../../../Input/SchoolsTypeahead/SchoolsTypeahead';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';

import { degreeDropdownOpts } from '../../Flows/Steps/QuickProfile/constants';

const addDefaultDropdownOption = (
    options = [],
    defaultOptionText = 'Select...'
) => [{ label: defaultOptionText, value: '' }, ...options];

import './EducationInput.scss';

export default class EducationInput extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        name: PropTypes.string,
        school: PropTypes.exact({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        degree: PropTypes.string,
        location: PropTypes.exact({
            name: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        errors: PropTypes.exact({
            name: PropTypes.string,
            school: PropTypes.string,
            degree: PropTypes.string,
            location: PropTypes.string,
        }).isRequired,
        updateEducation: PropTypes.func.isRequired,
    };

    static defaultProps = {
        name: '',
        school: {
            id: null,
            name: '',
        },
        degree: '',
        location: {
            name: '',
            lat: null,
            lng: null,
        },
    };

    render() {
        const {
            name,
            school,
            degree,
            location,
            errors,
            updateEducation,
        } = this.props;
        const { name: schoolName } = school;

        const dropdownOpts = addDefaultDropdownOption(
            degreeDropdownOpts,
            'Select...'
        );

        return (
            <div className="component-Modals-QuickApplyModal-EducationInput">
                <section className="input-group">
                    <div className="input-group-label">
                        <label htmlFor="name" className="input-label">
                            Your name*
                        </label>
                        <CharCount
                            length={name ? name.length : 0}
                            limit={100}
                        />
                    </div>
                    <InputField
                        idValue="name"
                        autoComplete="off"
                        classname="name-input-field"
                        name="name-input"
                        inputValue={name}
                        onChange={updateEducation('name')}
                    />
                    <ErrorLabel error={errors.name} cssClass="field-error" />
                </section>
                <section
                    className={`input-group ${
                        errors.school ? 'school-error' : 'school-base'
                    }`}
                >
                    <SchoolsTypeahead
                        items={schoolName ? [schoolName] : []}
                        error={errors.school}
                        handleSelect={updateEducation('school')}
                        className="school-typeahead"
                        allowAddNewItem
                        isRequired
                    />
                </section>
                <section className="input-group">
                    <div className="input-group-label-simple">
                        <label htmlFor="name" className="input-label">
                            Degree*
                        </label>
                    </div>
                    <BasicDropdown
                        options={dropdownOpts}
                        selectedValue={degree}
                        onChange={updateEducation('degree')}
                        qaDataAttr="degree"
                        // shouldDisableDefault
                    />
                    <ErrorLabel error={errors.degree} cssClass="field-error" />
                </section>
                <section className="input-group school-input">
                    <div className="input-group-label-simple">
                        <label htmlFor="location" className="input-label">
                            School location*
                        </label>
                    </div>
                    <LocationInput
                        idValue="location"
                        placeholder="City, State"
                        classname="location-input-field"
                        name="location-input"
                        inputValue={location.name}
                        onChange={updateEducation('location')}
                    />
                    <ErrorLabel
                        error={errors.location}
                        cssClass="field-error"
                    />
                </section>
            </div>
        );
    }
}
