import * as yup from 'yup';

import { validateYupSchema } from './UserProfileSchema';
import { createMaxCharError } from './ContentSchema';

yup.addMethod(yup.number, 'nullIfEmpty', function () {
    return this.transform((v, o) =>
        (o + '').trim() === '' ? null : v
    ).nullable();
});

export const educationSchema = yup.object({
    school: yup.object().shape({
        id: yup.number().nullable(),
        name: yup.string().when('id', {
            is: (id) => !id || id.length === 0,
            then: yup
                .string()
                .min(100, 'Please add or select a school from the dropdown.')
                .required('Please add or select a school from the dropdown.'),
            otherwise: yup
                .string()
                .min(1)
                .max(100, createMaxCharError(100))
                .required('School is a required field.'),
        }),
    }),
    isRemote: yup.boolean(),
    location: yup.object().when('isRemote', {
        is: false,
        then: yup
            .object()
            .shape({
                name: yup.string().when('lat', {
                    is: (lat) => !lat || lat.length === 0,
                    then: yup
                        .string()
                        .min(
                            100,
                            'Please select a valid location from the dropdown.'
                        )
                        .required('Location is a required field.'), // workaround to require a selection from the dropdown
                    otherwise: yup
                        .string()
                        .nullable()
                        .min(1)
                        .max(100, createMaxCharError(100))
                        .required('Location is a required field.'),
                }),
                lat: yup.number().nullable().min(-90).max(90),
                lng: yup.number().nullable().min(-180).max(180),
            })
            .required('Location is a required field.'),
        otherwise: yup.object().nullable().notRequired(),
    }),
    degree: yup
        .string()
        .oneOf(
            [
                'High School',
                'Associate Degree',
                "Bachelor's Degree",
                "Master's Degree",
                'Doctorate / PhD',
                'Other',
            ],
            'Degree is a required field.'
        )
        .required('Degree is a required field.'),
    field: yup.string().nullable(),
    startYear: yup
        .number()
        .nullIfEmpty()
        .test('startYear-test', 'Please add a start year.', function (value) {
            const isCurrentEducation = this.resolve(this.parent.isCurrent);
            const endYear = this.resolve(this.parent.endYear);
            if (!value && endYear) return false;
            if (isCurrentEducation && !value) return false;
            return true;
        }),
    endYear: yup
        .number()
        .nullIfEmpty()
        .test('endYear-test', 'Please add an end year.', function (value) {
            const isCurrentEducation = this.resolve(this.parent.isCurrent);
            const startYear = this.resolve(this.parent.startYear);
            if (startYear && !isCurrentEducation && !value) return false;
            return true;
        })
        .test(
            'year-after-check',
            'End year must be after start year.',
            function (value) {
                const startYear = this.resolve(this.parent.startYear);

                if (startYear && value && startYear > value) return false;

                return true;
            }
        ),
    isCurrent: yup.boolean(),
    excluded: yup.boolean(),
    description: yup.string().max(2000, createMaxCharError(2000)).nullable(), // "highlights"
});

export const validateSingleEducationPayload = async (payload) => {
    const { valid, errors } = await validateYupSchema(payload, educationSchema);

    return {
        valid,
        errors,
    };
};
