import styled from 'styled-components';
import { View } from '@fairygodboss/ui';

export const ResetStyle = styled(View)`
    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
        text-decoration: none;
        opacity: 1;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Open Sans, sans-serif;
    }
`;
