import PropTypes from 'prop-types';
import React from 'react';
import './Tabs.scss';

//width: 100px
// height: 70px;
//fill="#B5BDD5"
export const tabSVGS = {
    SAY_SOMETHING_FORM: (
        <React.Fragment>
            <svg
                width="100px"
                height="46px"
                viewBox="0 0 100 46"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="discussion-default" fillRule="nonzero">
                        <g id="Paragraph-1">
                            <rect
                                id="Rectangle"
                                fill="#FFFFFF"
                                x="0"
                                y="0"
                                width="100"
                                height="70"
                            />
                            <path
                                className="colored-section"
                                d="M38.9331579,22.9896696 L37.8419382,25.1708204 C37.4714584,25.9117881 36.5704587,26.2121247 35.8294991,25.8416408 C35.0885395,25.4711569 34.7882062,24.5701474 35.1586861,23.8291796 L41.1586203,11.8291796 C41.7114007,10.7236068 43.2890922,10.7236068 43.8418725,11.8291796 L49.8418068,23.8291796 C50.2122866,24.5701474 49.9119534,25.4711569 49.1709938,25.8416408 C48.4300341,26.2121247 47.5290344,25.9117881 47.1585546,25.1708204 L46.0704281,22.9945435 L38.9331579,22.9896696 Z M44.5699318,19.9935181 L42.5016476,15.8569044 L40.4335319,19.9906935 L44.5699318,19.9935181 Z M53.0001314,24.5 C53.0001314,23.6715729 53.6710094,23 54.4956603,23 L63.5044712,23 C64.3304289,23 65,23.6657972 65,24.5 C65,25.3284271 64.329122,26 63.5044712,26 L54.4956603,26 C53.6697025,26 53.0001314,25.3342028 53.0001314,24.5 Z M35.0003286,30.5 C35.0003286,29.6715729 35.6689761,29 36.4982809,29 L63.5020477,29 C64.3293439,29 65,29.6657972 65,30.5 C65,31.3284271 64.3313525,32 63.5020477,32 L36.4982809,32 C35.6709847,32 35.0003286,31.3342028 35.0003286,30.5 Z M35.0003286,36.5 C35.0003286,35.6715729 35.6689761,35 36.4982809,35 L63.5020477,35 C64.3293439,35 65,35.6657972 65,36.5 C65,37.3284271 64.3313525,38 63.5020477,38 L36.4982809,38 C35.6709847,38 35.0003286,37.3342028 35.0003286,36.5 Z M53.0001314,18.5 C53.0001314,17.6715729 53.6710094,17 54.4956603,17 L63.5044712,17 C64.3304289,17 65,17.6657972 65,18.5 C65,19.3284271 64.329122,20 63.5044712,20 L54.4956603,20 C53.6697025,20 53.0001314,19.3342028 53.0001314,18.5 Z M53.0001314,12.5 C53.0001314,11.6715729 53.6710094,11 54.4956603,11 L63.5044712,11 C64.3304289,11 65,11.6657972 65,12.5 C65,13.3284271 64.329122,14 63.5044712,14 L54.4956603,14 C53.6697025,14 53.0001314,13.3342028 53.0001314,12.5 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
            <p
                className={
                    'component-Content-CreateContentWidgetV2-Tabs__tab-text'
                }
            >
                Discussion
            </p>
        </React.Fragment>
    ),
    SHARE_PHOTO_FORM: (
        <React.Fragment>
            <svg
                width="100px"
                height="46px"
                viewBox="0 0 100 46"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="photo" fillRule="nonzero">
                        <rect
                            id="Rectangle-Copy"
                            fill="#FFFFFF"
                            x="0"
                            y="0"
                            width="100"
                            height="70"
                        />
                        <g
                            className="colored-section"
                            transform="translate(31.000000, 6.000000)"
                        >
                            <path
                                d="M32.3333121,3.83333333 C34.174273,3.83333333 35.6666667,5.32682895 35.6666667,7.1682321 L35.6666667,24.6666715 C36.5875207,24.6688605 37.3333333,25.4078155 37.3333333,26.3333333 C37.3333333,27.2538079 36.5842874,28 35.6625639,28 L26.2808618,28 L29.5534172,32.908833 C30.0640046,33.6747142 29.8570481,34.7094964 29.091167,35.2200838 C28.3252858,35.7306713 27.2905036,35.5237148 26.7799162,34.7578337 L22.2746937,28 L15.7253063,28 L11.2200838,34.7578337 C10.7094964,35.5237148 9.6747142,35.7306713 8.90883301,35.2200838 C8.14295186,34.7094964 7.93599541,33.6747142 8.44658284,32.908833 L11.7191382,28 L2.33743612,28 C1.41469563,28 0.66666667,27.2602253 0.66666667,26.3333333 C0.66666667,25.4142249 1.41349085,24.668882 2.33333333,24.6666716 L2.33333333,7.1682321 C2.33333333,5.32641837 3.83035397,3.83333333 5.66668794,3.83333333 L17.3333333,3.83333333 L17.3333333,2.16508446 C17.3333333,1.24548371 18.073108,0.5 19,0.5 C19.9204746,0.5 20.6666667,1.23892196 20.6666667,2.16508446 L20.6666667,3.83333333 L32.3333121,3.83333333 Z M32.3333333,24.6666667 L32.3333333,7.1682321 L5.66666667,7.16666667 L5.66666667,24.6666667 L14.810546,24.6666667 C14.8267485,24.6664307 23.1408166,24.6666667 23.1408166,24.6666667 C23.1570373,24.6664303 32.3333333,24.6666667 32.3333333,24.6666667 Z M26.5,15.5 C25.1192881,15.5 24,14.3807119 24,13 C24,11.6192881 25.1192881,10.5 26.5,10.5 C27.8807119,10.5 29,11.6192881 29,13 C29,14.3807119 27.8807119,15.5 26.5,15.5 Z M13.0716105,12.856301 C13.5172092,12.0459204 14.3538362,11.97322 14.9347552,12.6871438 L17.4264102,15.7492778 C18.0097979,16.4662355 18.9498965,16.4622964 19.5216585,15.7461421 L19.9275195,15.2377852 C20.5013055,14.5190957 21.4486879,14.504619 22.0457606,15.2080466 L26.165624,20.0617694 C26.7617103,20.7640348 26.5065977,21.3333333 25.5734945,21.3333333 L10.0818396,21.3333333 C9.1587292,21.3333333 8.76917364,20.6808563 9.2172282,19.8660093 L13.0716105,12.856301 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
            <p
                className={
                    'component-Content-CreateContentWidgetV2-Tabs__tab-text'
                }
            >
                Photo
            </p>
        </React.Fragment>
    ),
    SHARE_LINK_FORM: (
        <React.Fragment>
            <svg
                width="100px"
                height="46px"
                viewBox="0 0 100 46"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="link" fillRule="nonzero">
                        <rect
                            id="Rectangle-Copy-2"
                            fill="#FFFFFF"
                            x="0"
                            y="0"
                            width="100"
                            height="70"
                        />
                        <g
                            id="Link"
                            className="colored-section"
                            transform="translate(35.000000, 11.000000)"
                        >
                            <path
                                d="M19.7140452,10.2859548 C20.2347443,10.8066538 20.2334329,11.6521852 19.7183844,12.1672337 L12.1672337,19.7183844 C11.6489311,20.236687 10.8102841,20.2383745 10.2859548,19.7140452 C9.7652557,19.1933462 9.7665671,18.3478148 10.2816156,17.8327663 L17.8327663,10.2816156 C18.3510689,9.763313 19.1897159,9.7616255 19.7140452,10.2859548 Z M10.2859739,25.3708804 C8.72549152,26.9313627 6.18858598,26.9303849 4.62910054,25.3708995 C3.06830038,23.8100993 3.06909074,21.274055 4.62911963,19.7140261 L8.40033671,15.942809 C8.92103576,15.42211 8.92103576,14.57789 8.40033671,14.057191 C7.87963765,13.5364919 7.03541768,13.5364919 6.51471863,14.057191 L2.74350154,17.828408 C0.14233755,20.429572 0.14102099,24.6540561 2.74348246,27.2565175 C5.34404075,29.8570758 9.569386,29.8587045 12.171592,27.2564985 L15.942809,23.4852814 C16.4635081,22.9645823 16.4635081,22.1203623 15.942809,21.5996633 C15.42211,21.0789642 14.57789,21.0789642 14.057191,21.5996633 L10.2859739,25.3708804 Z M27.2564985,12.171592 C29.8587045,9.569386 29.8570758,5.34404075 27.2565175,2.74348246 C24.6540561,0.14102099 20.429572,0.14233755 17.828408,2.74350154 L14.057191,6.51471863 C13.5364919,7.03541768 13.5364919,7.87963765 14.057191,8.40033671 C14.57789,8.92103576 15.42211,8.92103576 15.942809,8.40033671 L19.7140261,4.62911963 C21.274055,3.06909074 23.8100993,3.06830038 25.3708995,4.62910054 C26.9303849,6.18858598 26.9313627,8.72549152 25.3708804,10.2859739 L21.5996633,14.057191 C21.0789642,14.57789 21.0789642,15.42211 21.5996633,15.942809 C22.1203623,16.4635081 22.9645823,16.4635081 23.4852814,15.942809 L27.2564985,12.171592 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
            <p
                className={
                    'component-Content-CreateContentWidgetV2-Tabs__tab-text'
                }
            >
                Link
            </p>
        </React.Fragment>
    ),
};

export default class Tabs extends React.PureComponent {
    static propTypes = {
        /**
         * function for change handler
         */
        onChange: PropTypes.func.isRequired,
        /**
         * array of menu items
         */
        tabs: PropTypes.array.isRequired,
        /**
         * value of selected/active menu item
         */
        selected: PropTypes.string.isRequired,
    };

    renderTabs = (contentType) => {
        const selectedClass =
            this.props.selected === contentType ? 'selected' : '';
        return (
            <div
                className={`svg-menu-div ${selectedClass}`}
                onClick={this.props.onChange(contentType)}
                key={contentType}
            >
                {tabSVGS[contentType]}
            </div>
        );
    };

    render() {
        const editModeStyling = this.props.editMode ? 'disabled-styling' : '';

        return (
            <div
                className={`component-Content-CreateContentWidgetV2-Tabs ${editModeStyling}`}
            >
                <section className="icon-box">
                    {this.props.tabs.map(this.renderTabs)}
                </section>
            </div>
        );
    }
}
