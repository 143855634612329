import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../ModalParts/ModalContainer/ModalContainer';
import InvitationWidget from '../../InvitationWidget/InvitationWidget';

import './InviteModal.scss';

export default class InviteModal extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        type: PropTypes.oneOf(['group', 'connection', null]).isRequired,
        user: PropTypes.exact({
            username: PropTypes.string,
            userId: PropTypes.number,
            image: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            credential: PropTypes.string,
        }),
        group: PropTypes.exact({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }),
        messageType: PropTypes.oneOf(['user', 'employer']).isRequired,
        message: PropTypes.string,
        stepLabelsOverrideText: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        user: null,
        group: null,
        message: '',
        stepLabelsOverrideText: '',
    };

    render() {
        const {
            closeModal,
            user,
            group,
            type,
            messageType,
            message,
            stepLabelsOverrideText,
        } = this.props;

        const widgetProps = {
            user,
            group,
            type,
            messageType,
            message,
            stepLabelsOverrideText,
        };

        return (
            <ModalContainer showButton={false} closeModal={closeModal}>
                <div className="component-Modals-InviteModal">
                    <InvitationWidget
                        isModal={false}
                        onSuccess={closeModal}
                        {...widgetProps}
                    />
                </div>
            </ModalContainer>
        );
    }
}
