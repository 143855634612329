import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../Input/Button/Button';
import './ListItem.scss';

export default class ListItem extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /*
         * Title bold prop
         */
        title: PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.string,
        }).isRequired,
        /*
         * Subtitle itliac prop
         */
        subtitle: PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.string,
        }),
        /*
         * click handler
         */
        onClick: PropTypes.func,
        /*
         * button props
         */
        button: PropTypes.shape({
            text: PropTypes.string.isRequired,
            type: PropTypes.string,
        }),
    };

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        const titleDiv = (
            <div className="title-text">{this.props.title.text}</div>
        );
        let subtitleComponent = null;
        if (this.props.subtitle) {
            const subtitleDiv = (
                <div className="subtitle-text">{this.props.subtitle.text}</div>
            );
            subtitleComponent = subtitleDiv;
            if (this.props.subtitle.url) {
                subtitleComponent = (
                    <a className="subtitle-link" href={this.props.subtitle.url}>
                        {subtitleDiv}
                    </a>
                );
            }
        }

        return (
            <div className="component-Layout-ListSection-ListItem">
                <div className="text-section">
                    {this.props.title && this.props.title.url ? (
                        <a className="title-link" href={this.props.title.url}>
                            {titleDiv}
                        </a>
                    ) : (
                        titleDiv
                    )}
                    {subtitleComponent}
                </div>
                <div className="button-div">
                    <Button
                        type={
                            this.props.button && this.props.button.type
                                ? this.props.button.type
                                : 'tertiary-blue-gray'
                        }
                        value={
                            this.props.button && this.props.button.text
                                ? this.props.button.text
                                : 'Example'
                        }
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}
