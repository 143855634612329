import React from 'react';
import ReactDOM from 'react-dom';
import createFocusTrap from './createFocusTrap';

export default class FocusTrap extends React.PureComponent {
    constructor(props) {
        super(props);

        if (typeof document !== 'undefined') {
            this.previouslyFocusedElement = document.activeElement;
        }
    }

    static defaultProps = {
        active: true,
        paused: false,
        focusTrapOptions: {},
        _createFocusTrap: createFocusTrap,
    };

    componentDidMount() {
        const specifiedFocusTrapOptions = this.props.focusTrapOptions;
        const tailoredFocusTrapOptions = {
            returnFocusOnDeactivate: false,
        };

        for (const optionName in specifiedFocusTrapOptions) {
            if (!specifiedFocusTrapOptions.hasOwnProperty(optionName)) continue;
            if (optionName === 'returnFocusOnDeactivate') continue;
            tailoredFocusTrapOptions[optionName] =
                specifiedFocusTrapOptions[optionName];
        }

        const focusTrapElementDOMNode = ReactDOM.findDOMNode(
            this.focusTrapElement
        );
        this.focusTrap = this.props._createFocusTrap(
            focusTrapElementDOMNode,
            tailoredFocusTrapOptions
        );

        if (this.props.active) {
            this.focusTrap.activate();
        }

        if (this.props.paused) {
            this.focusTrap.pause();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.active && !this.props.active) {
            const { returnFocusOnDeactivate } = this.props.focusTrapOptions;
            const returnFocus = returnFocusOnDeactivate || false;
            const config = { returnFocus };
            this.focusTrap.deactivate(config);
        } else if (!prevProps.active && this.props.active) {
            this.focusTrap.activate();
        }

        if (prevProps.paused && !this.props.paused) {
            this.focusTrap.unpause();
        } else if (!prevProps.paused && this.props.paused) {
            this.focusTrap.pause();
        }
    }

    componentWillUnmount() {
        this.focusTrap.deactivate();

        if (
            this.props.focusTrapOptions.returnFocusOnDeactivate !== false &&
            this.previouslyFocusedElement &&
            this.previouslyFocusedElement.focus
        ) {
            this.previouslyFocusedElement.focus();
        }
    }

    setFocusTrapElement = (element) => {
        this.focusTrapElement = element;
    };

    render() {
        const child = React.Children.only(this.props.children);

        const composedRefCallback = (element) => {
            this.setFocusTrapElement(element);
            if (typeof child.ref === 'function') {
                child.ref(element);
            }
        };

        const childWithRef = React.cloneElement(child, {
            ref: composedRefCallback,
        });

        return childWithRef;
    }
}
