import TagManager from 'react-gtm-module';
import { PAGE_METRICS_DATALAYER_NAME } from './MetricsConsts';

/**
 * Initialize a new Data Layer
 * @param {string} gtmId the new data layer Id Defaults to 'GTM-KPHHR9P'
 * @param {*} dataLayerName the new Data Layer Name PAGE_METRICS_DATALAYER_NAME
 */
export const initializeGTM = (
    gtmId = null,
    dataLayerName = PAGE_METRICS_DATALAYER_NAME
) => {
    TagManager.initialize({
        gtmId: gtmId || 'GTM-KPHHR9P',
        dataLayerName,
    });
};

/**
 * Fires a new GTM Metric pushing it to the data layer
 * @param {*} eventType the type of the event
 * @param {*} pagePath the url of the page
 * @param {*} pageTitle the title of the page
 * @param {*} userMetadata the user metadata {user_id, ...}
 * @param {*} dataLayerName the name of the Data Layer defaults to PAGE_METRICS_DATALAYER_NAME
 */
export const fireGTMMetric = (
    eventType,
    pagePath,
    pageTitle,
    userMetadata,
    dataLayerName = PAGE_METRICS_DATALAYER_NAME
) => {
    if (!window[dataLayerName]) {
        initializeGTM(null, dataLayerName);
    }
    TagManager.dataLayer({
        dataLayer: {
            event: eventType,
            pagePath,
            pageTitle,
            ...userMetadata,
        },
        dataLayerName,
    });
};
