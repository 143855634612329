import React from 'react';

export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
    >
        <path
            fill="#F2ECFE"
            fillRule="evenodd"
            d="M10.616 0C8.128 4.891 4.59 7.95 0 9.179 2.588 4.969 3.727 1.909 3.42 0h7.196z"
        />
    </svg>
);
