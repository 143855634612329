/* eslint-disable complexity */
import { getTag, toPath } from './form';
import { isInteger } from './number';

export const isObject = (obj) =>
    !!obj && typeof obj === 'object' && !Array.isArray(obj);

export const cheapDeepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const getIn = (obj, key, def, p = 0) => {
    const path = toPath(key);
    while (obj && p < path.length) {
        obj = obj[path[p++]];
    }
    return obj === undefined ? def : obj;
};

export const setIn = (target, path = '', value) => {
    let res = cheapDeepClone(target);
    let resVal = res;
    let i = 0;
    let pathArray = toPath(path);

    for (; i < pathArray.length - 1; i++) {
        const currentPath = pathArray[i];
        let currentTarget = getIn(target, pathArray.slice(0, i + 1));

        if (
            currentTarget &&
            (isObject(currentTarget) || Array.isArray(currentTarget))
        ) {
            resVal = resVal[currentPath] = cheapDeepClone(currentTarget);
        } else {
            const nextPath = pathArray[i + 1];
            resVal = resVal[currentPath] =
                isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
        }
    }

    if ((i === 0 ? target : resVal)[pathArray[i]] === value) {
        return target;
    }

    if (value === undefined) {
        delete resVal[pathArray[i]];
    } else {
        resVal[pathArray[i]] = value;
    }

    if (i === 0 && value === undefined) {
        delete res[pathArray[i]];
    }

    return res;
};

export const get = (target, { basePath, propName } = {}) =>
    basePath.reduce(
        (nestedObject, currentPath) => nestedObject[currentPath] || {},
        target
    )[propName];

export const set = (
    target,
    { basePath = [], basePath: [firstPath, ...nextPaths], propName, newValue }
) =>
    basePath.length > 0
        ? {
              ...target,
              [firstPath]: set(target[firstPath] || {}, {
                  basePath: nextPaths,
                  propName,
                  newValue,
              }),
          }
        : { ...target, [propName]: newValue };

export const iterateObject = (target, updaterFn) =>
    Object.entries(target).reduce(
        (newObject, [key, value]) =>
            isObject(value)
                ? { ...newObject, [key]: iterateObject(value, updaterFn) }
                : { ...newObject, ...updaterFn([key, value]) },
        {}
    );

export const getRecursive = (object, { basePath = [] }) =>
    basePath.reduce(
        (reducedObject, currentPath) => reducedObject[currentPath],
        object
    );

export const setRecursive = (object, { basePath = [], newValue }) => {
    const [firstRoute, ...rest] = basePath;
    if (rest.length > 0) {
        return {
            ...object,
            [firstRoute]: setRecursive(object[firstRoute], {
                basePath: rest,
                newValue,
            }),
        };
    }

    return { ...object, [firstRoute]: newValue };
};

export const filter = (object, fn) =>
    Object.entries(object).reduce(
        (filteredObject, [key, value]) =>
            fn(key, value)
                ? { ...filteredObject, [key]: value }
                : filteredObject,
        {}
    );

export const reduce = (object, fn, initialValue) =>
    Object.entries(object).reduce(
        (acc, [key, value]) => fn(acc, key, value),
        initialValue
    );

const isObjectLike = (obj) => typeof obj === 'object' && !Array.isArray(obj);

export const isPlainObject = (value) => {
    if (!isObjectLike(value) || getTag(value) != '[object Object]')
        return false;

    if (Object.getPrototypeOf(value) === null) return true;

    let proto = value;

    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }

    return Object.getPrototypeOf(value) === proto;
};
