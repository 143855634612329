export default {
    positiveInt: (props, propName, component) => {
        const currentProp = props[propName];
        if (!Number.isInteger(currentProp) || currentProp < 0) {
            return new Error(
                `The ${propName} prop you provided to ${component} is not a valid integer >= 0.`
            );
        }
        return null;
    },
};
