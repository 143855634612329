import PropTypes from 'prop-types';
import React from 'react';
import DotNavigation from '../../../../Elements/DotNavigation/DotNavigation';
import ThreeRowModalLayout from '../../../../Modals/Layout/ThreeRowModalLayout/ThreeRowModalLayout';
import CloseModalIcon from '../../../../Modals/ModalParts/CloseModalIcon/CloseModalIcon';

import './AdminOnboardingModalTop.scss';

export default class AdminOnboardingModalTop extends React.PureComponent {
    static propTypes = {
        /**
         * index of selected step user is on, start from 0
         */
        selectedStepIndex: PropTypes.number.isRequired,
        /**
         * total steps of the onboarding process
         */
        totalSteps: PropTypes.number.isRequired,
        /**
         * descriptions of individual steps
         */
        stepDefinition: PropTypes.object.isRequired,
    };

    static defaultProps = {
        totalSteps: 0,
        stepDefinition: {},
    };

    getCloseModalMetrics = () => ({
        misc_event_type: `admin-onboarding-step-${this.props.selectedStepIndex}-close-click`,
        misc_event_count: 1,
    });

    render() {
        const { selectedStepIndex, totalSteps, stepDefinition } = this.props;

        const { hasClose, hasDotNavigation, description } = stepDefinition;

        const dotIndex = selectedStepIndex - 1;
        const totalDots = totalSteps - 1;

        return (
            <ThreeRowModalLayout.Top className="component-Groups-AdminOnboarding-AdminOnboardingModal-AdminOnboardingModalTop">
                <div className="top-section step-zero">
                    {hasDotNavigation && (
                        <DotNavigation
                            selectedDotIndex={dotIndex}
                            totalDots={totalDots}
                        />
                    )}
                    {hasClose && (
                        <CloseModalIcon metrics={this.getCloseModalMetrics()} />
                    )}
                </div>
                <div className="bottom-section">
                    {!!description && description}
                </div>
            </ThreeRowModalLayout.Top>
        );
    }
}
