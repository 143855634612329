import axios from 'axios';

import { IMAGE_CDN_URL } from './imageCDN';

const imageType = require('image-type'); // needs require syntax
const FileType = require('file-type/browser');

export const MYSTERY_WOMAN_IMAGE_PATH = '/v1/avatar_black_160_vtg7lw.svg';

const CLOUDINARY_VIDEO_ROOT = `${
    IMAGE_CDN_URL.split('/image/upload')[0]
}/video/upload/`;

const LOGO_IMAGE_SIZE = {
    XXS_SMALL: 'xxSmall',
    XS_SMALL: 'xSmall',
    SMALL: 'small',
    SMALL_MEDIUM: 'small-medium',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const CLOUDINARY_SIZE_TRANSFORM = {
    LOGO: {
        [LOGO_IMAGE_SIZE.XXS_SMALL]: 'c_scale,f_auto,q_auto,w_32',
        [LOGO_IMAGE_SIZE.XS_SMALL]: 'c_scale,f_auto,q_auto,w_60',
        [LOGO_IMAGE_SIZE.SMALL]: 'c_scale,f_auto,q_auto,w_70',
        [LOGO_IMAGE_SIZE.SMALL_MEDIUM]: 'c_scale,f_auto,q_auto,w_80',
        [LOGO_IMAGE_SIZE.MEDIUM]: 'c_scale,f_auto,q_auto,w_120',
        [LOGO_IMAGE_SIZE.LARGE]: 'c_scale,f_auto,q_auto,w_180',
    },
};

const DIGIT_REGEX = /\/?v[0-9]*\//;

export const getCloudinaryImagePathById = (
    cloudinaryId,
    size = LOGO_IMAGE_SIZE.SMALL,
    injectedTransforms = CLOUDINARY_SIZE_TRANSFORM.LOGO
) => {
    const formattedUrl = `/v1${cloudinaryId.replace(DIGIT_REGEX, '/')}`;
    return `${IMAGE_CDN_URL}/${injectedTransforms[size]}${formattedUrl}`;
};

export const getCloudinaryVideoPathById = (
    cloudinaryId,
    width = 320,
    height = 180
) => {
    const formattedUrl = `/v1${cloudinaryId.replace(DIGIT_REGEX, '/')}`;
    const transforms = `w_${width},h_${height},dpr_auto,q_auto`;
    return CLOUDINARY_VIDEO_ROOT + transforms + formattedUrl;
};

export const getVideoFormat = async (cloudinaryId, buffer) => {
    const videoPath = getCloudinaryVideoPathById(cloudinaryId);
    const response = buffer
        ? { data: buffer }
        : await axios.get(videoPath, { responseType: 'arraybuffer' });

    return FileType.fromBuffer(response.data);
};

export const getImageFormat = async (cloudinaryId, buffer) => {
    const response = buffer
        ? { data: buffer }
        : await axios.get(
              getCloudinaryImagePathById(cloudinaryId, 'default', {
                  default: '',
              }),
              { responseType: 'arraybuffer' }
          );
    const result = imageType(response.data);
    // {ext: 'jpg', mime: 'image/jpeg'}

    if (result.ext === 'gif') {
        // Check to see if this is an animated gif
        const gifData = new Uint8Array(response.data);
        const len = gifData.length - 9;
        let frames = 0;

        // cribbed this from https://stackoverflow.com/questions/1326890/is-it-possible-to-detect-animated-gif-images-client-side
        // eslint-disable-next-line no-plusplus,no-sequences
        for (let i = 0; i < len, frames < 2; ++i) {
            if (
                gifData[i] === 0x00 &&
                gifData[i + 1] === 0x21 &&
                gifData[i + 2] === 0xf9 &&
                gifData[i + 3] === 0x04 &&
                gifData[i + 8] === 0x00 &&
                (gifData[i + 9] === 0x2c || gifData[i + 9] === 0x21)
            ) {
                // eslint-disable-next-line no-plusplus
                frames++;
            }
        }

        result.animated = frames > 1;
        // {ext: 'gif', mime: 'image/gif', animated: ?true}
    }

    return result;
};

/**
 * if session logo is null or includes  avatar_black_160_vtg7lw then use avatar_black_160_vtg7lw
 * @param logo
 */
export const buildUserLogo = (logo = null) => {
    let extraInfo = '';
    let logoToUse = logo;
    if (logo === null || logo?.includes('avatar_black_160_vtg7lw')) {
        logoToUse = 'avatar_black_160_vtg7lw.webp';
        extraInfo = 'b_white,';
    }
    return `${IMAGE_CDN_URL}/dpr_1,${extraInfo}c_scale,g_center,r_max,q_auto,w_48,h_48/v1/${logoToUse}`;
};
