import React from 'react';

import './DefaultIntroMessage.scss';

const profileLink = (
    <a
        className="welcome-msg-link"
        href="/users/profile?LAC=communitymessage&LAT=welcomemessage"
    >
        Your FGB profile
    </a>
);
const shareLink = (
    <a
        className="welcome-msg-link"
        href="/community/feed?LAC=communitymessage&LAT=welcomemessage"
    >
        share what&apos;s on your mind
    </a>
);
const jobsLink = (
    <a
        className="welcome-msg-link"
        href="/jobs/filter?LAC=communitymessage&LAT=welcomemessage"
    >
        over 150,000 jobs
    </a>
);

export default class DefaultIntroMessage extends React.PureComponent {
    render() {
        return (
            <div className="component-Messages-DefaultIntroMessage">
                <p>Hello!</p>

                <p>
                    Welcome to the FGB Community! Your FGB experience should be
                    as unique as you are. Here&apos;s how to get started:
                </p>

                <p>
                    <span className="section-title">Introduce yourself:</span>
                    {profileLink} helps you showcase your accomplishments and
                    connect with recruiters who want to hire more women as well
                    as others who share your profession and interests.
                </p>

                <p>
                    <span className="section-title">How can we help?</span>
                    Our network of experts and coaches are here for you —{' '}
                    {shareLink} (even anonymously), and get fast feedback.
                </p>

                <p>
                    <span className="section-title">Jobs. Jobs. Jobs.</span>
                    With anonymous company reviews for and by women, get the
                    inside scoop on companies before you apply. Easily search{' '}
                    {jobsLink} from employers looking to hire more women or set
                    custom email alerts!
                </p>

                <p>Questions? Just reply to this message.</p>

                <p>
                    Cheers,
                    <br />
                    FGB Community Manager
                </p>
            </div>
        );
    }
}
