import PropTypes from 'prop-types';
import React from 'react';

import './RatingShield.scss';

export default class RatingShield extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        size: PropTypes.string,
        active: PropTypes.bool.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        number: PropTypes.number.isRequired,
    };

    static defaultProps = {
        size: 'large',
    };

    onMouseOver = () => {
        this.props.onMouseOver(this.props.number);
    };

    onClick = () => {
        this.props.onClick(this.props.number);
    };

    render() {
        const activeClass = this.props.active ? 'active' : '';
        return (
            <div
                className={`component-ReviewSurvey-Components-RatingShield ${this.props.size} ${activeClass} ${this.props.number}`}
                data-ratings={this.props.number}
                onMouseOver={this.onMouseOver}
                onClick={this.onClick}
            >
                <div className="size-container">
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 62 62"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g>
                            <g
                                className="shield"
                                transform="translate(1.000000, 1.000000)"
                            >
                                <polygon
                                    className="colorStar"
                                    fillRule="nonzero"
                                    points="35.0069231 16 30.3169231 23.1953846 22 25.4153846 27.4192308 32.0823077 26.9684615 40.6492308 35.0069231 37.5746154 43.0453846 40.6492308 42.5946154 32.0823077 48.0138462 25.4153846 39.6961538 23.1953846"
                                />
                                <polygon
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="30.0004201 13.6840577 25.3104201 20.8794423 16.993497 23.0994423 22.4127278 29.7663654 21.9619585 38.3332885 30.0004201 35.2586731 38.0388816 38.3332885 37.5881124 29.7663654 43.0073432 23.0994423 34.6896509 20.8794423"
                                />
                                <path
                                    d="M9.97865086,3.93490385 C6.57788162,5.32721154 3.13557393,7.14721154 0.000189317088,9.52567308 C0.000189317088,9.52567308 -0.35673376,44.2756731 29.9248047,60.0002885 L30.0763432,60.0002885 C60.3571124,44.2756731 60.0001893,9.52567308 60.0001893,9.52567308 C56.8655739,7.14721154 53.4240355,5.32798077 50.0232662,3.93490385 C37.2094201,-1.31201923 22.792497,-1.31124999 9.97865086,3.93490385 Z"
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.952497,8.14844231 C11.2263432,9.26382693 8.46711239,10.7230577 5.9548047,12.6292115 C5.9548047,12.6292115 5.66788162,41.2522885 29.9394201,53.8561346 L30.0609585,53.8561346 C54.332497,41.2522885 54.0463432,12.6292115 54.0463432,12.6292115 C51.5332662,10.7238269 48.7748047,9.26459616 46.0494201,8.14844231 C35.7786509,3.9430577 24.2232662,3.9430577 13.952497,8.14844231 Z"
                                    className="stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}
