/* eslint-disable react/static-property-placement */
/* eslint-disable react/sort-comp */
/* eslint-disable no-useless-constructor */
/* eslint-disable import/no-cycle */
/* eslint-disable complexity */
import React from 'react';

// component context
import { PageContext } from '../../../contexts/ContextCreator';

// v1 components
import FollowedJobs from '../../CallToAction/LeftRailCTA/Layouts/FollowedJobs/FollowedJobs';
import CompaniesModal from '../../CallToAction/LeftRailCTA/CompaniesModal/CompaniesModal';
import MobileMenuModal from '../../CallToAction/LeftRailCTA/MobileMenuModal/MobileMenuModal';
import ConfirmationPopup from '../../ConfirmationPopup/ConfirmationPopup';
import CreateContentPopup from '../../Content/CreateContentWidgetV2/CreateContentPopup/CreateContentPopup';
import RegisterSuccessModal from '../../Events/EventRegisterButton/RegisterSuccessModal/RegisterSuccessModal';
import VfairRegisterModal from '../../Events/EventRegisterButton/VfairRegisterModal/VfairRegisterModal';
import EventRegistrationIncompleteModal from '../../Events/EventRegistrationIncompleteModal/EventRegistrationIncompleteModal';
import EventRegistrationSuccessModal from '../../Events/EventRegistrationSuccessModal/EventRegistrationSuccessModal';
import AdminOnboardingModal from '../../Groups/AdminOnboarding/AdminOnboardingModal/AdminOnboardingModal';
import GroupJoinModal from '../../Groups/Join/GroupJoinModal/GroupJoinModal';
import GroupsMenuMobileModal from '../../Groups/Modals/GroupsMenuMobileModal/GroupsMenuMobileModal';
import InvitationWidgetPopup from '../../InvitationWidget/InvitationWidgetPopup/InvitationWidgetPopup';

// v2 components
import AriaModal from '../AriaModal/AriaModal';
import ModalContainer from '../ModalParts/ModalContainer/ModalContainer';
import EmployerClaimForm from '../../CompanyPages/Sections/EmployerClaimForm/EmployerClaimForm';
import JobSeekerModal from '../JobSeekerModal/JobSeekerModal';
import InviteModal from '../InviteModal/InviteModal';
import AuthModal from '../AuthModal/AuthModal';
import QuickApplyModal from '../QuickApplyModal/QuickApplyModal';
import ProfileIdentityModal from '../User/ProfileIdentityModal/ProfileIdentityModal';

// common modal shell component
import ModalShell from '../ModalParts/ModalShell/ModalShell';

// style
import './ModalManager.scss';

export const modalTypes = {
    // v1 components
    FOLLOWED_COMPANIES: 'CompaniesModal',
    REGISTER_SUCCESS: 'RegisterSuccessModal',
    FOLLOWED_JOBS: 'FollowedJobs',
    CREATE_CONTENT: 'CreateContentPopup',
    MOBILE_MENU: 'MobileMenuModal',
    GROUPS_MOBILE_MENU: 'GroupsMobileModal',
    INVITE: 'Invitation',
    JOIN_GROUP: 'GroupJoin',
    CONFIRMATION: 'ConfirmationModal',
    EMPLOYER_CLAIM_POPUP: 'EmployerClaimPopup',
    // v2 components
    CONTAINER: 'Container',
    ADMIN_ONBOARDING: 'adminOnboarding',
    VFAIR_REGISTER_MODAL: 'vfairRegisterModal',
    VCF_EVENT_REGISTRATION_SUCCESS: 'VCFEventSuccess',
    EVENT_REGISTRATION_INCOMPLETE: 'EventRegistrationIncompleteModal',
    EMPLOYER_CLAIM_FORM: 'EmployerClaimForm',
    JOB_SEEKER_SUBSECTION_MODAL: 'JobSeekerSubsectionModal',
    QUICK_APPLY_LOCATION_MODAL: 'quickApplyLocation',
    INVITE_MODAL: 'inviteModal',
    AUTH_MODAL: 'authModal',
    QUICK_APPLY: 'quickApply',
    USER_PROFILE_IDENTITY: 'userProfileIdentity',
};

export const v1ModalTypes = Object.values(modalTypes);

const defaultUnderlayStyle = { padding: '60px 0' };

export default class ModalManager extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    // page context available using this.context
    static contextType = PageContext;

    getApplicationNode = () => {
        // sets aria attribute on root element to hidden while modal is active
        return document.getElementById('root');
    };

    /**
     * Takes the active modal type and returns the current active modal component with props
     *
     * @param {string} Component - active component
     * @param {object} componentProps - props to pass into active component (default: {})
     * @param {boolean} isLegacyModal - the component is handled with the legacy modal manager (default: false)
     * @returns {object}
     */
    serializeActiveModal = (
        Component,
        componentProps = {},
        isLegacyModal = false
    ) => ({
        ModalComponent: Component,
        componentProps,
        isLegacyModal,
    });

    /**
     * Takes the active modal type and returns the current active modal component with props
     *
     * @param {string} type - type of modal
     * @returns {React.ComponentElement} Component - active modal component with props
     */
    getModalComponent = (type) => {
        const { followingCompanies, topics, closeModal } = this.context;

        if (type === null) return this.serializeActiveModal(null);

        // add component and component-specific (non-common) props
        // common = closeModal, modalTrigger, onUpdate, featureFlags, and modals.data
        switch (type) {
            case modalTypes.FOLLOWED_COMPANIES:
                return this.serializeActiveModal(
                    CompaniesModal,
                    { ...followingCompanies },
                    true
                );
            case modalTypes.REGISTER_SUCCESS:
                return this.serializeActiveModal(
                    RegisterSuccessModal,
                    {},
                    true
                );
            case modalTypes.FOLLOWED_JOBS:
                return this.serializeActiveModal(FollowedJobs, {}, true);
            case modalTypes.CREATE_CONTENT:
                return this.serializeActiveModal(
                    CreateContentPopup,
                    { topics, modal: true, toggle: closeModal },
                    true
                );
            case modalTypes.MOBILE_MENU:
                return this.serializeActiveModal(MobileMenuModal, {}, true);
            case modalTypes.GROUPS_MOBILE_MENU:
                return this.serializeActiveModal(
                    GroupsMenuMobileModal,
                    {},
                    true
                );
            case modalTypes.INVITE:
                return this.serializeActiveModal(
                    InvitationWidgetPopup,
                    {},
                    true
                );
            case modalTypes.JOIN_GROUP:
                return this.serializeActiveModal(GroupJoinModal, {}, true);
            case modalTypes.CONFIRMATION:
                return this.serializeActiveModal(ConfirmationPopup, {}, true);
            case modalTypes.CONTAINER:
                return this.serializeActiveModal(ModalContainer);
            case modalTypes.ADMIN_ONBOARDING:
                return this.serializeActiveModal(AdminOnboardingModal);
            case modalTypes.VFAIR_REGISTER_MODAL:
                return this.serializeActiveModal(VfairRegisterModal);
            case modalTypes.VCF_EVENT_REGISTRATION_SUCCESS:
                return this.serializeActiveModal(EventRegistrationSuccessModal);
            case modalTypes.EVENT_REGISTRATION_INCOMPLETE:
                return this.serializeActiveModal(
                    EventRegistrationIncompleteModal
                );
            case modalTypes.EMPLOYER_CLAIM_FORM:
                return this.serializeActiveModal(EmployerClaimForm);
            case modalTypes.JOB_SEEKER_SUBSECTION_MODAL:
                return this.serializeActiveModal(JobSeekerModal);

            case modalTypes.INVITE_MODAL:
                return this.serializeActiveModal(InviteModal);

            case modalTypes.AUTH_MODAL:
                return this.serializeActiveModal(AuthModal);

            case modalTypes.QUICK_APPLY:
                return this.serializeActiveModal(QuickApplyModal);
            case modalTypes.USER_PROFILE_IDENTITY:
                return this.serializeActiveModal(ProfileIdentityModal);
            default:
                // fail early if invalid modal type provided
                throw new Error('Invalid Modal Type');
        }
    };

    render() {
        const {
            modalActive,
            closeModal,
            modalTrigger,
            onUpdate,
            modals,
            pageProps,
        } = this.context;

        const { AUTH_MODAL } = modalTypes;

        const commonModalProps = {
            // closes modal
            closeModal,
            // trigger the modal (takes a modal type and optional data obj)
            modalTrigger,
            // to update page context
            onUpdate,
            // give all modal components access to pageProps
            featureFlags: pageProps.featureFlags,
            // provide session to modals
            session: pageProps.session,
            // modal specific data - will either be null or the data object passed in through modalTrigger
            ...modals.data,
        };

        // classNames to add to the modal shell
        const shellClasses = [];

        const noPaddingShellTypes = [AUTH_MODAL];
        const isNoPaddingShell = noPaddingShellTypes.includes(modals.type);

        const hasCustomShellClass = modals.data && modals.data.className;

        if (hasCustomShellClass) shellClasses.push(modals.data.className);
        if (isNoPaddingShell) shellClasses.push('no-padding-shell');

        const shellClassName = shellClasses.join(' ');

        const {
            ModalComponent,
            componentProps,
            isLegacyModal,
        } = this.getModalComponent(modals.type);

        // no modal component/type or modal is not active
        if (!ModalComponent || !modalActive) return null;

        // if our component is not compatible with AriaModal, fall back to legacy handling
        // TODO: remove when all modal components refactored to use AriaModal
        if (isLegacyModal) {
            return (
                <ModalComponent
                    {...commonModalProps}
                    {...componentProps}
                    onClose={closeModal}
                    showModal
                />
            );
        }

        // use v2 modal
        return (
            <AriaModal
                titleText="modal"
                onExit={closeModal}
                getApplicationNode={this.getApplicationNode}
                underlayStyle={defaultUnderlayStyle}
                dialogClass="component-Modals-ModalManager"
                focusDialog
                focusTrapPaused
            >
                <ModalShell className={shellClassName}>
                    <ModalComponent {...commonModalProps} {...componentProps} />
                </ModalShell>
            </AriaModal>
        );
    }
}
