import React from 'react';

import { ModalContext } from '../../../../contexts/ContextCreator';
import { useFlowControls } from '../FlowShell/controls';
import { useGetStepFormProps } from '../../../../contexts/Includes/modalContextHelpers';

import {
    GENDER_IDENTITY_STEP_NAME,
    RACE_ETHNICITY_STEP_NAME,
    SEXUAL_ORIENTATION_STEP_NAME,
    VETERAN_DISABILITY_STEP_NAME,
} from '../Steps/constants';

import { diversityInputFlowAPI, getIdFromOptionList } from './constants';

import './navButtons.scss';

// GENDER IDENTITY STEP BUTTONS
const GenderIdentityNextButton = React.memo((props) => {
    const { destinations, flowData } = useFlowControls();

    const { validators } = flowData;

    const validateGenderIdentityValues = validators.genderIdentityValidator;

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(GENDER_IDENTITY_STEP_NAME);

    const handleStepValidationErrors = updateFormValueAtPath(
        'errors.genderIdentity'
    );

    const handleNextClick = React.useCallback(async () => {
        clearStepErrors(GENDER_IDENTITY_STEP_NAME);
        const { valid, errors } = await validateGenderIdentityValues(
            formResponses
        );

        if (!valid) {
            return handleStepValidationErrors(errors);
        }

        const goToNextStep = destinations.raceEthnicity;

        return goToNextStep();
    }, [
        destinations,
        formResponses,
        validateGenderIdentityValues,
        handleStepValidationErrors,
        clearStepErrors,
    ]);

    return (
        <button type="button" className="next-button" onClick={handleNextClick}>
            Next &gt;
        </button>
    );
});

export const genderIdentityNavBox = (
    <div className="diversity-flow-buttons one-button-end">
        <GenderIdentityNextButton />
    </div>
);

// RACE ETHNICITY STEP BUTTONS
const RaceEthnicityBackButton = React.memo((props) => {
    const { destinations } = useFlowControls(); // TODO: get validators from flow controls

    const handleBackClick = React.useCallback(() => {
        const goToPrevStep = destinations.genderIdentity;

        return goToPrevStep();
    }, [destinations]);
    return (
        <button type="button" className="back-button" onClick={handleBackClick}>
            &lt; Back
        </button>
    );
});

const RaceEthnicityNextButton = React.memo((props) => {
    const { destinations, flowData } = useFlowControls();

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(RACE_ETHNICITY_STEP_NAME);

    const { validators } = flowData;

    const validateRaceEthnicityValues = validators.raceEthnicityValidator;

    const handleStepValidationErrors = updateFormValueAtPath(
        'errors.raceEthnicity'
    );

    const handleNextClick = React.useCallback(async () => {
        clearStepErrors(RACE_ETHNICITY_STEP_NAME);

        const { valid, errors } = await validateRaceEthnicityValues(
            formResponses
        );

        if (!valid) {
            console.log('VALIDATION ERROR!: ', errors);
            return handleStepValidationErrors(errors);
        }

        const goToNextStep = destinations.sexualOrientation;

        return goToNextStep();
    }, [
        destinations,
        validateRaceEthnicityValues,
        formResponses,
        handleStepValidationErrors,
        clearStepErrors,
    ]);

    return (
        <button type="button" className="next-button" onClick={handleNextClick}>
            Next &gt;
        </button>
    );
});

export const raceEthnicityNavBox = (
    <div className="diversity-flow-buttons">
        <RaceEthnicityBackButton />
        <RaceEthnicityNextButton />
    </div>
);

// SEXUAL ORIENTATION STEP BUTTONS
const SexualOrientationBackButton = React.memo((props) => {
    const { destinations } = useFlowControls();

    const handleBackClick = React.useCallback(() => {
        const goToPrevStep = destinations.raceEthnicity;

        return goToPrevStep();
    }, [destinations]);

    return (
        <button type="button" className="back-button" onClick={handleBackClick}>
            &lt; Back
        </button>
    );
});

const SexualOrientationNextButton = React.memo((props) => {
    const { destinations, flowData } = useFlowControls();

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(SEXUAL_ORIENTATION_STEP_NAME);

    const { validators } = flowData;

    const validateSexualOrientationValues =
        validators.sexualOrientationValidator;

    const handleStepValidationErrors = updateFormValueAtPath(
        'errors.sexualOrientation'
    );

    const handleNextClick = React.useCallback(async () => {
        clearStepErrors(SEXUAL_ORIENTATION_STEP_NAME);

        const { valid, errors } = await validateSexualOrientationValues(
            formResponses
        );

        if (!valid) {
            return handleStepValidationErrors(errors);
        }

        const goToNextStep = destinations.veteranDisability;

        return goToNextStep();
    }, [
        destinations,
        validateSexualOrientationValues,
        formResponses,
        handleStepValidationErrors,
        clearStepErrors,
    ]);

    return (
        <button type="button" className="next-button" onClick={handleNextClick}>
            Next &gt;
        </button>
    );
});

export const sexualOrientationNavBox = (
    <div className="diversity-flow-buttons">
        <SexualOrientationBackButton />
        <SexualOrientationNextButton />
    </div>
);

// VET DISABILITY STEP BUTTONS
const VeteranDisabilityBackButton = React.memo((props) => {
    const { destinations } = useFlowControls();

    const handleBackClick = React.useCallback(() => {
        const goToPrevStep = destinations.sexualOrientation;

        return goToPrevStep();
    }, [destinations]);

    return (
        <button type="button" className="back-button" onClick={handleBackClick}>
            &lt; Back
        </button>
    );
});

const DiversityFlowSubmitButton = React.memo((props) => {
    const {
        stepFormProps: vetDisabilityResponses,
        updateFormValueAtPath,
        clearStepErrors,
    } = useGetStepFormProps(VETERAN_DISABILITY_STEP_NAME);
    const { destinations, flowData } = useFlowControls();
    const {
        validators,
        setDemoInfo,
        ethnicityOptions,
        orientationOptions,
    } = flowData;

    const handleStepValidationErrors = updateFormValueAtPath(
        'errors.veteranDisability'
    );

    const validateVetDisabilityValues = validators.veteranDisabilityValidator;

    // Get all form values
    const { formValues } = React.useContext(ModalContext);
    const { errors: _, ...restFormValues } = formValues;

    const handleSubmitClick = React.useCallback(async () => {
        clearStepErrors(VETERAN_DISABILITY_STEP_NAME);

        const convertEthnicityOptionToId = getIdFromOptionList(
            ethnicityOptions
        );
        const convertOrientationOptionToId = getIdFromOptionList(
            orientationOptions
        );

        const { valid, errors } = await validateVetDisabilityValues(
            vetDisabilityResponses
        );

        if (!valid) {
            return handleStepValidationErrors(errors);
        }

        // map ethnicityChoices and orientationChoices and replace text values with ids
        const idReplacedFormValues = {
            genderIdentity: restFormValues.genderIdentity,
            raceEthnicity: {
                ...restFormValues.raceEthnicity,
                ethnicityChoices: restFormValues.raceEthnicity.ethnicityChoices.map(
                    convertEthnicityOptionToId
                ),
            },
            sexualOrientation: {
                ...restFormValues.sexualOrientation,
                orientationChoices: restFormValues.sexualOrientation.orientationChoices.map(
                    convertOrientationOptionToId
                ),
            },
            veteranDisability: restFormValues.veteranDisability,
        };

        const {
            ok,
            data,
            errors: apiErrors,
        } = await diversityInputFlowAPI.submitDiversityInput(
            idReplacedFormValues
        );

        if (!ok) return; // show general error message?

        // we can use the form values if the api call was successful
        setDemoInfo(data, ethnicityOptions, orientationOptions);

        const goToNextStep = destinations.confirmSuccess;

        return goToNextStep();
    }, [
        destinations,
        vetDisabilityResponses,
        restFormValues,
        clearStepErrors,
        handleStepValidationErrors,
        validateVetDisabilityValues,
        setDemoInfo,
        ethnicityOptions,
        orientationOptions,
    ]);

    return (
        <button
            type="button"
            className="next-button"
            onClick={handleSubmitClick}
        >
            Finish &gt;
        </button>
    );
});

export const veteranDisabilityNavBox = (
    <div className="diversity-flow-buttons">
        <VeteranDisabilityBackButton />
        <DiversityFlowSubmitButton />
    </div>
);
