import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../../Elements/InputLabel/InputLabel';
import FieldError from '../../Elements/FieldError/FieldError';
import { CompanyTypeahead } from '../../../../Input/CompanyTypeahead/CompanyTypeahead';
import { constructCn } from '../../../../../utilities/helperFunctions';

import './CompanySelector.scss';

const handleFilterSelectValues = (handler) => (values) => {
    if (typeof values === 'string')
        return handler({ companyName: values, companyId: null });

    const { companyName, companyId } = values;

    if (!companyName || companyName.length === 0)
        return handler({
            companyName: '',
            companyId: null,
        });

    return handler({
        companyName,
        companyId,
    });
};

export const CompanySelector = ({
    inputName,
    label,
    error,
    value,
    handleChange,
    className,
}) => {
    const idName = `#company-select-${inputName.split('.').slice(-1)[0]}`;

    const selectorHandler = handleChange(inputName);

    const typeaheadHandler = handleFilterSelectValues(selectorHandler);

    const inputCn = constructCn(
        'component-Modals-ModalParts-Inputs-CompanySelector',
        error && 'input-error'
    );

    return (
        <div className={inputCn}>
            <InputLabel text={label} type="text" htmlFor={idName} />
            <CompanyTypeahead
                placeholder="Start typing to select a company or add a new one"
                items={[value]}
                handleSelect={typeaheadHandler}
                setTypeaheadInput={typeaheadHandler}
            />
            <FieldError text={error} />
        </div>
    );
};

CompanySelector.propTypes = {
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.exact({
        companyName: PropTypes.string.isRequired,
        companyId: PropTypes.number,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

CompanySelector.defaultProps = {
    label: '',
    error: '',
    className: 'modal-company-selector',
};

CompanySelector.displayName = 'CompanySelector';

export default React.memo(CompanySelector);
