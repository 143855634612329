import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';

import './GroupLogo.scss';

export default class GroupLogo extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /*
            Logo image
         */
        logo: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        /*
            name of group
         */
        name: PropTypes.string.isRequired,
        /*
            whether or not isadmin
         */
        isAdmin: PropTypes.bool,
        /*
            icon size for injected transforms
         */
        iconSize: PropTypes.string,
        /*
            css class that would override the default sytling
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        logo: {
            src: null,
            alt: 'logo',
        },
        isAdmin: false,
        iconSize: 'thumbnail',
        className: '',
    };

    renderLogo = () => {
        const { logo, name, isAdmin, iconSize } = this.props;
        const defaultAdminLogoSrc =
            '/v1565122294/Groups/default-admin-logo.svg'; // default admin (edit) logo

        const injectedTransforms = {
            thumbnail: 'c_scale,f_auto,q_auto,w_100,h_100',
            xSmall: 'c_scale,f_auto,q_auto,w_32,h_32',
            medium: 'c_scale,f_auto,q_auto,w_50,h_50',
            xMedium: 'c_scale,f_auto,q_auto,w_72,h_72',
        };

        const isLogoSet = logo && logo.src;
        const isLogoDefault = isLogoSet && logo.src.indexOf('default') !== -1;

        // admin/owner has not set logo image and user is admin - display admin default "edit" logo
        if (
            (isLogoSet && isLogoDefault && isAdmin) ||
            (!isLogoSet && isAdmin)
        ) {
            return (
                <img
                    src={buildImagePath(
                        defaultAdminLogoSrc,
                        iconSize,
                        injectedTransforms
                    )}
                    alt={logo.alt}
                />
            );
        }

        // group info contains a logo, which may or may not be the default.
        // User is not admin. Display specified logo.
        if (isLogoSet) {
            return (
                <img
                    src={buildImagePath(logo.src, iconSize, injectedTransforms)}
                    alt={logo.alt}
                />
            );
        }

        // if we get here, something has gone wrong and no logo data was specified. Attempt a dynamic fallback.
        if (iconSize && name) {
            const defaultUserLogoSrc = '/v1561057515/Groups/group_default.svg'; // default user logo
            const defaultGroupLetter = name.slice(0, 1).toUpperCase();
            return (
                <React.Fragment>
                    <img
                        src={buildImagePath(
                            defaultUserLogoSrc,
                            iconSize,
                            injectedTransforms
                        )}
                        alt={''}
                    />
                    <div className="default-letter">{defaultGroupLetter}</div>
                </React.Fragment>
            );
        }

        // All has failed, fail gracefully
        return null;
    };

    render() {
        const { logo, className } = this.props;
        const defaultClass = !(logo && logo.src) ? 'default' : '';
        return (
            <div
                className={`component-Groups-GroupLogo ${this.props.iconSize} ${defaultClass} ${className}`}
            >
                {this.renderLogo()}
            </div>
        );
    }
}
