import React from 'react';

import { ModalContext } from '../../../../contexts/ContextCreator';

import FlowShell from '../FlowShell/FlowShell';
import SingleExperience from '../Steps/SingleExperience/SingleExperience';
import { buildSingleExperienceFlow, experienceUpdateApi } from './constants';
import { useFlowControls } from '../FlowShell/controls';
import { SINGLE_EXPERIENCE_STEP_NAME } from '../Steps/constants';

export const singleExperienceFlowType = 'single-experience-flow';

export const SingleExperienceFlow = (props) => {
    const { closeModal, generalModalProps } = React.useContext(ModalContext);

    const { experiences, updateHistoryList } = generalModalProps;

    const initializeOptions = {
        experiences,
        closeModal,
        updateHistoryList,
    };

    const {
        flowTree,
        initialStep,
        initialFlowData,
    } = buildSingleExperienceFlow(initializeOptions);

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={closeModal}
            flowTitle="experience"
        >
            <SingleExperience />
        </FlowShell>
    );
};

SingleExperienceFlow.displayName = 'SingleExperienceFlow';

export default React.memo(SingleExperienceFlow);

export const ExperienceSubmitButton = React.memo(
    ({ formValues, errorHandler, clearErrors }) => {
        const { flowData } = useFlowControls();
        const {
            validators,
            experiences,
            closeModal,
            updateHistoryList,
        } = flowData;
        const { singleExperienceValidator } = validators;

        const handleDoneClick = React.useCallback(async () => {
            clearErrors(SINGLE_EXPERIENCE_STEP_NAME);

            const { valid, errors } = await singleExperienceValidator(
                formValues
            );

            if (!valid) {
                return errorHandler(errors);
            }

            // since are handling done click, we are adding an experience
            const {
                ok,
                newExperiences,
                apiErrors,
            } = await experienceUpdateApi.addSingleExperience(
                formValues,
                experiences
            );

            if (!ok) {
                console.log('API ERRORS: ', apiErrors); // TODO: remove after testing
                return false; // TODO: set errors?
            }

            updateHistoryList(newExperiences);
            return closeModal();
        }, [
            formValues,
            errorHandler,
            clearErrors,
            singleExperienceValidator,
            experiences,
            closeModal,
            updateHistoryList,
        ]);

        return (
            <button
                type="button"
                className="experience-submit-button"
                onClick={handleDoneClick}
            >
                Done
            </button>
        );
    }
);

export const ExperienceDeleteButton = React.memo(
    ({ experienceId, errorHandler }) => {
        const { flowData } = useFlowControls();
        const { experiences, closeModal, updateHistoryList } = flowData;

        const handleDeleteClick = React.useCallback(async () => {
            if (!experienceId) return closeModal();

            // since are handling delete click, we are removing an experience
            const {
                ok,
                newExperiences,
                apiErrors,
            } = await experienceUpdateApi.deleteSingleExperience(
                experienceId,
                experiences
            );

            if (!ok) {
                console.log('API ERRORS: ', apiErrors); // TODO: remove after testing
                return; // TODO: set errors?
            }

            updateHistoryList(newExperiences);
            return closeModal();
        }, [experienceId, experiences, closeModal, updateHistoryList]);

        return (
            <button
                type="button"
                className="experience-delete-button"
                onClick={handleDeleteClick}
            >
                Delete
            </button>
        );
    }
);
