/* eslint-disable */
/* TODO: FIX THE BAD CODE, DON'T IGNORE IT AND FORCE IT THROUGH THE CHECKS */
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import { reserializeAuthor } from '../utilities/ui_utilities';

import { APIS } from '../components/CallToAction/CommunityUserCallToActions/constants';
import {
    CONTENT_TYPES,
    CreateContentPopup,
} from '../components/Content/CreateContentWidgetV2/CreateContentPopup/CreateContentPopup';
import AbstractContext, {
    API_URL_GET_USER_RELATIONS,
    ATTRIBUTION_MODE_NONE,
    ATTRIBUTION_MODE_SINGLE,
    CONTENT_TYPE_COMMENT,
    USER_RELATIONSHIP_ATTRIBUTION_NONE,
} from './AbstractContext';

import { CommunityContext as Context } from './ContextCreator';
import { Consumer as PageConsumer } from './PageContext';
import { emitEngagement, objectTypes } from '../utilities/getStreamAnalytics';

export const FETCH_MODE_FEED = 'feed';
export const FETCH_MODE_MINI_FEED = 'miniFeed';
export const FETCH_MODE_GROUP_FEED = 'groupFeed';
export const FETCH_MODE_TOPICLIST = 'topicList';
export const FETCH_MODE_NONE = 'none';

const MASTER_FEED_URL = `/api/community/feed/master/`;

/**
 *
 *
       isLoggedIn: true, //SET IN FRONT-END
       content: [
         {
            isAnonymous: true,
            type: 'community-discussion',
            hash: 'hash',
            title: "text",
            description: "Text",
            author: {
                username: "USERNAME OR NULL",
                image: "cloudinaryId" //OR NULL
            },
            likes: 0,
            comments: [], //ARRAY OF COMMENTS (SEE BELOW)
            commentCount: 0,
            userLiked: false, // SET IN FRONT-END
            userFlagged: false, // SET IN FRONT-END
            topic: {
                url: 'URL',
                isActive: true,
                label: "THE TOPIC NAME"
            }
        },
        {
            isAnonymous: true,
            type: 'editorial-content',
            hash: 'hash|url',
            title: "text",
            contentBody: "Text",
            wink: "TEXT",
            "contentImage" : {
                src: "cloudinaryId",
                "alt" : "text",
                "title": "TEXT"
            },
            author: {
                username: "USER OR ANON",
                image: "cloudinaryId" //OR NULL
            },
            likes: 0,
            comments: [], //ARRAY OF COMMENTS (SEE BELOW)
            commentCount: 0,
            userLiked: false, // SET IN FRONT-END
            userFlagged: false, // SET IN FRONT-END
            topic: {
                url: 'URL',
                isActive: true,
                label: "THE TOPIC NAME"
            }

    },


    // HOW COMMENTS ARRAY IS SETUP
    { comments: [
        {
           isAnonymous: true,
           contentType: "community-comment",
           commentCount: 0,
           likes: 0,
           comment: "COMMENT MESSAGE TEXT",
           author: {
                username: "USER OR ANON",
                image: "cloudinaryId" //OR NULL
           }
           parentType: 'community-comment | community-{contentType}' // FRONT-END AUGMENTATION
           parentHash: 'hashOfParentCommmentORcontent', // FRONT-END AUGMENTATION
           parentContentType: 'THE TOP LEVEL CONTENT TYPE (community-discussion | editorial-content)', // FRONT-END AUGMENTATION
           parentContentId: 'THE TOP LEVEL CONTENT HASH', // FRONT-END AUGMENTATION
           replyOpened: false, //is the reply box opened? // FRONT-END AUGMENTATION
           comments: [{
               isAnonymous: true,
               contentType: "community-comment",
               commentCount: 0,
               likes: 0,
               comment: "COMMENT MESSAGE TEXT",
               author: {
                    username: "USER OR ANON",
                    image: "cloudinaryId" //OR NULL
               }
               parentType: 'community-comment | community-{contentType}' // FRONT-END AUGMENTATION
               parentHash: 'hashOfParentCommmentORcontent', // FRONT-END AUGMENTATION
               parentContentType: 'THE TOP LEVEL CONTENT TYPE (community-discussion | editorial-content)', // FRONT-END AUGMENTATION
               parentContentId: 'THE TOP LEVEL CONTENT HASH', // FRONT-END AUGMENTATION
               replyOpened: false, //is the reply box opened? // FRONT-END AUGMENTATION
               comments:[]
               },
           ] }
        },
    ] }
 */

/**
 * This context can manage the entire state of one or many types of content.
 */

export class PureProvider extends AbstractContext {
    constructor(props) {
        super(props);
        const authorRefs = {};
        this.createUserHashRef(props.content, authorRefs);
        const newTopicsObject = props.topics
            ? props.topics.reduce((topics, singleTopic) => {
                  topics[singleTopic.id] = singleTopic;
                  return topics;
              }, {})
            : null;
        this.state = Object.assign(this.state, {
            pageContext: null,
            /**
             * The active pice of content a user is commenting on
             */
            openedCommentHash: null, // this.state.openedCommentHash,
            /**
             * whether to load more content in feed
             */
            hasNext: false,
            /**
             * Select which piece of content a users is replying to or commenting on
             * @param hash
             */
            openCommentReply: (hash) => this.openCommentReply(hash),
            /**
             * Save a comment to a comment or other piece of content
             * @param hash
             * @param comment - the string comments
             * @param isAnon - bool of if to post as an anon user
             */
            submitComment: async (hash, comment, isAnon) =>
                this.submitComment(hash, comment, isAnon),
            /**
             * Set logged In or not logged in
             */
            setLoggedIn: (bool) => this.setLoggedIn(bool),
            /**
             * Fetch more content function
             */
            fetchMoreContent: (config) => {
                this.fetchMoreContent(config);
            },

            fetchMode: this.props.fetchMode,
            emitMetric: this.emitMetric,
            // Request Connection
            requestConnection: this.requestToConnect,
            // Follow User
            followUser: this.followUser,
            // Subscribe to topic
            subscribeToTopic: this.subscribeToTopic,
            unsubscribeToTopic: this.unsubscribeToTopic,
            fetchSingleAuthor: this.fetchSingleAuthor,
            fetchSingleContent: this.fetchSingleContent,
            setHoverComponent: this.setHoverComponent,
            hoveredComponent: null,
            closeHoverComponent: this.closeHoverComponent,
            topics: newTopicsObject,
            authorRefs,
            feedPage: 1,
            sort: 'DEFAULT',
            /**
             * function setting a feed ranking algorithm
             */
            setSort: this.setSort,
            updateAuthorRelationsToUser: this.updateAuthorRelationsToUser,
            fetchUserRelations: this.fetchUserRelations,
            /**
             * function for deleting community discussion, link, photos, comments
             */
            deleteCommunityContent: this.deleteCommunityContent,
            /**
             * function for editing community dicussion, link, photos, comments
             */
            editCommunityContent: this.editCommunityContent,
            /**
             * hash for active comments currently being edited
             */
            editingCommentHash: null,
            /**
             * function for setting the active comment hash
             */
            setEditingCommentHash: this.setEditingCommentHash,
            editContentTrigger: (hash) => this.editContentTrigger(hash),
            contentBeingEdited: null,
            editSuccessPopup: false,
            defaultPostLocation: props.defaultPostLocation || null,
            /**
             * the overlay component that will be render based on standAloneHoverRef/(component being hovered on) location
             */
            standAloneOverlayComponent: null,
            /**
             * reference of stand alone component being hovered on
             */
            standAloneHoverRef: null,
            /**
             * getter for position of component being hovered on
             */
            getStandAloneHoverPosition: this.getStandAloneHoverPosition,
            /**
             * context function for rendering overlay element based on hover element's position
             */
            setStandaloneHoverOverlay: this.setStandaloneHoverOverlay,
            updateContentFromUPBResponse: this.updateContentFromUPBResponse,
            turnIsNewPostFlagOff: this.turnIsNewPostFlagOff,
            fetchMoreComments: this.fetchMoreComments,
            setContentGroup: this.setContentGroup,
        });

        this.hoverTimer = null;
    }

    /**
     * Set which comment/content has
     * @param hash
     */
    openCommentReply = (hash) => {
        this.setState({ openedCommentHash: hash });
    };

    /**
     * Add a comment to a parent
     * @param hash
     * @param comment
     * @param isAnon
     * @returns {Promise.<boolean>}
     */
    submitComment = async (hash, comment, isAnon) => {
        const content = this.getContent(hash);
        let contentHash = hash;
        let parentHash = null;
        let contentType = content.type;

        const { id, name } = this.state.pageProps.session;

        if (content.type === CONTENT_TYPE_COMMENT) {
            contentHash = content.parentContentHash;
            parentHash = hash;
            contentType = content.parentContentType;
        }

        try {
            const toSend = {
                comment,
                anon: isAnon,
                parent_comment_hash_id: parentHash,
                content_hash_id: contentHash,
            };
            const result = await axios.post(
                `/api/community/community-comments/create/${contentType}`,
                toSend
            );

            this.emitMetricByHash(hash, { comment_posted: 1 });

            const comm = result.data.data;
            this.addComment(hash, {
                hash: comm.new_comment_hash_id,
                comment: comm.comment,
                author: comm.author,
                parentHash: parentHash ? comm.parent_comment_hash_id : hash,
                isAnon: comm.anon,
                likes: 0,
                contentDate: new Date(),
            });

            emitEngagement(
                { id, name },
                {
                    label: 'comment',
                    score: 1,
                    content: {
                        foreign_id: `${
                            objectTypes()[
                                content.parentContentType || contentType
                            ]
                        }:${content.parentContentHash || hash}`,
                        comment: {
                            id: `CommunityComment:${comm.new_comment_hash_id}`,
                            label: `${
                                objectTypes()[
                                    content.parentContentType || contentType
                                ]
                            }:${content.parentContentHash || hash}`,
                        },
                    },
                }
            );

            return true;
        } catch (error) {
            console.log(error);
            this.setState({ openedCommentHash: null });
            return false;
        }
    };

    /**
     * Add a comment to a parent hash
     * @param hash
     * @param comment
     */
    addComment = (hash, comment) => {
        const hydrate = (partComment) => {
            const parent = this.getContent(comment.parentHash);
            const parentContent =
                parent.type !== CONTENT_TYPE_COMMENT
                    ? parent
                    : this.getContent(parent.parentContentHash);
            return {
                hash: partComment.hash,
                isAnon: partComment.isAnon,
                isVip: partComment.isVip,
                type: CONTENT_TYPE_COMMENT,
                commentCount: 0,
                likes: 0,
                comment: partComment.comment,
                author: partComment.author,
                parentType: parent.type,
                parentHash: parent.hash,
                parentContentType: parentContent.type,
                parentContentHash: parentContent.hash,
                isNew: true,
                getTotalCount() {
                    return 0;
                },
                replyOpened: false,
                userLiked: false,
                userFlagged: false,
                comments: [],
                editable: true,
                deletable: true,
                contentDate: new Date(),
            };
        };

        const newState = { ...this.state };
        const newRefs = {};
        this.createHashRef(newState.content, newRefs);

        const hc = hydrate(comment);
        newRefs[hc.parentHash].comments.unshift(hc);
        newRefs[hc.parentHash].commentCount += 1;
        newRefs[hc.hash] = hc;
        this.setState(
            {
                content: newState.content,
                refs: newRefs,
                openedCommentHash: null,
            },
            () => {
                const ref = `link-id-${hc.hash}`;
                if (
                    hc.parentHash !== hc.parentContentHash &&
                    window &&
                    window.document.getElementById(ref)
                ) {
                    const commentInput = window.document.getElementById(ref);
                    commentInput.scrollIntoView(true);
                    window.scrollBy(0, -100); // OFFSET
                }
            }
        );
    };

    /**
     * Add multiple comments to a parent hash. Only use with fetch more comments API
     * @param {string} hash - Either the parent commentHash or contentHash
     * @param {array<object>} comments
     */
    addComments = (comments, commentCount = 0, commentLikes = 0) => {
        const hydrate = (newComment) => {
            return {
                hash: newComment.hash,
                isAnon: newComment.isAnon,
                isVip: newComment.isVip,
                type: CONTENT_TYPE_COMMENT,
                commentCount: newComment.commentCount,
                likes: newComment.likes,
                comment: newComment.comment,
                author: newComment.author,
                parentType: newComment.parentType,
                parentHash: newComment.parentHash,
                parentContentType: newComment.parentContentType,
                parentContentHash: newComment.parentContentHash,
                moderationOption: newComment.moderationOption,
                isNew: false,
                getTotalCount() {
                    return 0;
                },
                replyOpened: false,
                userLiked: false,
                userFlagged: false,
                comments: newComment.comments
                    ? newComment.comments.map((c) => hydrate(c))
                    : [],
                editable: true,
                deletable: true,
                contentDate: newComment.contentDate,
                flaggingEnabled: newComment.flaggingEnabled,
            };
        };

        const newState = { ...this.state };
        const newRefs = {};
        this.createHashRef(newState.content, newRefs);

        const pushRefs = (comment) => {
            const hydratedComment = hydrate(comment);
            newRefs[hydratedComment.hash] = hydratedComment;
            if (
                newRefs[hydratedComment.parentHash] &&
                !newRefs[hydratedComment.parentHash].comments.some(
                    (c) => c.hash === hydratedComment.hash
                )
            ) {
                newRefs[hydratedComment.parentHash].comments.push(
                    hydratedComment
                );
            }
            (comment.comments || []).forEach((c) => pushRefs(c));
        };

        comments.forEach(pushRefs);
        newState.content[0].commentCount = commentCount;
        newState.content[0].likes = commentLikes;
        newState.content[0].preloading = false;
        this.setState({
            content: newState.content,
            refs: newRefs,
            openedCommentHash: null,
        });
    };

    setSort = (type, setIsLoading) => {
        if (type !== this.state.sort) {
            const targetObject = {
                feedGroup: 'GlobalFeed',
                feedGroupId: 'master',
                hash: '',
                setIsLoading,
            };

            this.setState(
                {
                    sort: type,
                    content: [],
                    newRefs: {},
                    authorRefs: {},
                    feedPage: 1,
                    feedLastResultCount: 0,
                    openedCommentHash: null,
                    hasNext: false,
                },
                () => {
                    const urlParams = new URLSearchParams(
                        window.location.search
                    );
                    EmitMetric({
                        community_sort_type: type?.toLowerCase(),
                        click: 1,
                    });
                    this.fetchMoreContent(targetObject);
                }
            );
        }
    };
    fetchFeed = async (url, limit, targetObject) => {
        const feedPage = this.state.feedPage;
        url += `?limit=${limit}&page=${feedPage}`;
        if (targetObject.feedGroup) {
            url += `&feedGroup=${targetObject.feedGroup}`;
        }

        if (targetObject.feedGroupId) {
            url += `&feedGroupId=${targetObject.feedGroupId}`;
        }

        const urlParams = new URLSearchParams(window.location.search);
        let sort = urlParams.get('sort');
        if (sort) url += `&sort=${sort.toUpperCase()}`;
        if (urlParams.get('post')) {
            url += `&post=${urlParams.get('post')}`;
        }
        if (urlParams.get('content')) {
            url += `&content=${urlParams.get('content')}`;
        }
        if (feedPage === 1) {
            url += `&addPost=true`;
        } else if (this.state.sort) url += `&sort=${this.state.sort}`;
        const result = await axios.get(url);

        const newState = { ...this.state };
        newState.content = newState.content.concat(result.data.result);
        const newRefs = {};
        this.createHashRef(newState.content, newRefs);

        const newAuthorRefs = {};
        this.createUserHashRef(newState.content, newAuthorRefs);

        if (url.startsWith(MASTER_FEED_URL) && feedPage === 1) {
            EmitMetric({
                community_sort_type: 'default',
                misc_event_type: 'feed-load',
                misc_event_count: 1,
            });
        }

        this.setState(
            {
                content: newState.content,
                authorRefs: newAuthorRefs,
                refs: newRefs,
                openedCommentHash: null,
                hasNext: result.data.hasNext,
                feedLastResultCount: result.data.result.length,
            },
            () => {
                this.attribution();
                targetObject.setIsLoading ? targetObject.setIsLoading() : null;
            }
        );
    };

    updateContentFromUPBResponse = async (newContent) => {
        const newState = { ...this.state };
        newState.content = [newContent, ...newState.content];

        const newRefs = {};
        this.createHashRef(newState.content, newRefs);
        const newAuthorRefs = {};
        this.createUserHashRef(newState.content, newAuthorRefs);

        await this.setState({
            content: newState.content,
            authorRefs: newAuthorRefs,
            refs: newRefs,
        });
        this.attribution();
        setTimeout(() => this.turnIsNewPostFlagOff(), 1000);
    };

    turnIsNewPostFlagOff = () => {
        this.setState((state) => ({
            ...state,
            content: state.content.map((e, i) =>
                i === 0 ? { ...e, isNewPost: false } : e
            ),
        }));
    };

    fetchMoreContent = async (targetObject) => {
        if (this.props.fetchMode === FETCH_MODE_TOPICLIST) {
            const limit = 10;
            const offset = this.state.content.length;
            const newState = { ...this.state };
            const result = await axios.get(
                `/community/${targetObject.topicUrl}?_jsonMode=true&_jsonLimit=${limit}&_jsonOffset=${offset}`
            );

            newState.content = newState.content.concat(result.data.content);
            const newRefs = {};
            this.createHashRef(newState.content, newRefs);

            const newAuthorRefs = {};
            this.createUserHashRef(newState.content, newAuthorRefs);
            this.setState(
                {
                    content: newState.content,
                    authorRefs: newAuthorRefs,
                    refs: newRefs,
                    openedCommentHash: null,
                },
                () => {
                    this.attribution();
                }
            );
        } else if (this.props.fetchMode === FETCH_MODE_MINI_FEED) {
            const miniFeedUrl = `/api/community/feed/mini/`;
            this.fetchFeed(miniFeedUrl, 5, targetObject);
        } else if (this.props.fetchMode === FETCH_MODE_FEED) {
            this.fetchFeed(MASTER_FEED_URL, 10, targetObject);
        } else if (this.props.fetchMode === FETCH_MODE_GROUP_FEED) {
            const { hash } = targetObject;
            const groupFeedUrl = `/api/community/feed/group/${hash}`;
            this.fetchFeed(groupFeedUrl, 10, targetObject);
        } else if (this.props.attributionMode === ATTRIBUTION_MODE_SINGLE) {
            const newState = { ...this.state };
            const results = (
                await axios.get(
                    `/api/community/content/${targetObject.contentType}/${targetObject.contentHash}`
                )
            ).data;

            newState.content = newState.content.concat(results);

            const newRefs = {};
            this.createHashRef(newState.content, newRefs);
            const newAuthorRefs = {};
            this.createUserHashRef(newState.content, newAuthorRefs);

            await this.setState({
                content: newState.content,
                authorRefs: newAuthorRefs,
                refs: newRefs,
            });

            this.attribution();
        }
        this.setState({ feedPage: this.state.feedPage + 1 });
    };

    /**
     * @param {string} contentType - parent content type
     * @param {string} contentHash - parent content hash
     * @param {string|null} hash - hash of parent comment, if there is one. Null if it is top level
     * @param {number|null} limit - # comments sent in each call. Defaults to 10
     * @param {number|null} lastId - Last comment id from current call. If null, pagination starts from the beginning
     * @param {boolean} fetchImmediate - used to fetch top level comments only. Defaults to false
     * @returns {Promise<void>}
     */
    fetchMoreComments = async (
        contentType,
        contentHash,
        hash = null,
        limit,
        lastId = null,
        fetchImmediate
    ) => {
        const baseUrl = `/api/community/content/${contentType}/${contentHash}/comments${
            hash ? `/${hash}` : ''
        }`;
        const qs = new URLSearchParams();
        if (lastId) {
            qs.append('lastId', lastId);
        }

        if (fetchImmediate) {
            qs.append('fetchImmediate', true);
        }

        if (limit) {
            qs.append('limit', limit);
        }
        try {
            const { data } = await axios.get(
                baseUrl + (qs.toString() ? `?${qs.toString()}` : '')
            );
            this.addComments(
                data.comments,
                data.commentCount,
                data.commentLikes
            );
            this.attribution();

            // if no more items left, the last ID is -1
            return data.lastId;
        } catch (e) {
            return -1;
        }
    };

    emitContentMetric = async (content, uniqueMetrics) => {
        const isComment = content.type === CONTENT_TYPE_COMMENT;
        const contentKey = isComment
            ? `${content.parentContentType}-${content.parentContentHash}`
            : `${content.type}-${content.hash}`;

        const metrics = {
            community_on_comment: content.type === CONTENT_TYPE_COMMENT,
            community_content_key: contentKey, // {content-type}-{hash}
            community_vote_type: null, // (like, flag)
            community_content_location: null, // (feed, list, mini-feed)
        };
        if (this.props?.fetchMode === 'feed') {
            metrics.community_sort_type = this.state.sort?.toLowerCase();
        }

        const sendMetrics = { ...metrics, ...uniqueMetrics };
        if (content.snowPlow) {
            sendMetrics.snowPlow = content.snowPlow;
            sendMetrics.snowPlow.buttonText = 'vote';
        }
        EmitMetric(sendMetrics);
    };

    createUserHashRef = (contents, authorRefs) => {
        const keys = Object.keys(contents);
        keys.map((contentKey) => {
            const content = contents[contentKey];
            if (content.author && content.author.userId) {
                if (authorRefs.hasOwnProperty(content.author.userId)) {
                    content.author = authorRefs[content.author.userId];
                } else {
                    authorRefs[content.author.userId] = content.author;
                }
            }
        });
    };

    followUser = async (userIdToFollow) => {
        const apiUrl = APIS.API_FOLLOW_USER;

        try {
            const res = await axios.post(apiUrl, { userIdToFollow });
            this.updateAuthorRelationsToUser();
            this.closeHoverComponent();
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    requestToConnect = async (toUserId) => {
        try {
            const res = await axios.post('/api/user/connection/request', {
                toUserId,
            });
            EmitMetric({
                misc_event_type: `user-request-connection`,
                misc_event_count: 1,
            });
            this.updateAuthorRelationsToUser();
            this.closeHoverComponent();
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    subscribeToTopic = async (topicId, subscribeTopicStateHandler) => {
        try {
            await axios.post('/api/user/subscription/subscribe', { topicId });
            subscribeTopicStateHandler();
            this.closeHoverComponent();
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    unsubscribeToTopic = async (topicId, unsubscribeTopicStateHandler) => {
        try {
            await axios.delete('/api/user/subscription/unsubscribe', {
                data: { topicId },
            });
            unsubscribeTopicStateHandler();
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    setHoverComponent = (contentId, type = null) => {
        if (contentId !== this.state.hoveredComponent) {
            type === 'author'
                ? EmitMetric({
                      misc_event_type: 'username_hover',
                      misc_event_count: 1,
                  })
                : null;
        }
        clearTimeout(this.hoverTimer);
        this.setState({
            hoveredComponent: contentId,
        });
    };

    setStandaloneHoverOverlay = (
        standAloneOverlayComponent = null,
        standAloneHoverRef = null
    ) => {
        this.setState(
            {
                standAloneOverlayComponent: null,
                standAloneHoverRef: null,
            },
            () => {
                this.setState({
                    standAloneOverlayComponent,
                    standAloneHoverRef,
                });
            }
        );
    };

    getStandAloneHoverPosition = () => {
        const { standAloneHoverRef } = this.state;
        if (!standAloneHoverRef || !standAloneHoverRef.getBoundingClientRect)
            return null;
        return standAloneHoverRef.getBoundingClientRect();
    };

    closeHoverComponent = (closeHoverTimeout = 1000) => {
        this.hoverTimer = setTimeout(
            () =>
                this.setState({
                    hoveredComponent: null,
                    standAloneOverlayComponent: null,
                    standAloneHoverRef: null,
                }),
            closeHoverTimeout
        );
    };

    fetchUserRelations = async (uIDs = []) => {
        try {
            const result = await axios.post(API_URL_GET_USER_RELATIONS, {
                userIds: uIDs,
            });

            return result;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    updateAuthorRelationsToUser = async () => {
        if (
            this.props.userRelationshipMode ===
            USER_RELATIONSHIP_ATTRIBUTION_NONE
        ) {
            return;
        }

        try {
            const authorRefs = {};
            this.createUserHashRef(this.state.refs, authorRefs);

            const userIds = Object.keys(authorRefs).map((userId) =>
                Number(userId)
            );

            const result =
                userIds.length > 0
                    ? await axios.post(API_URL_GET_USER_RELATIONS, { userIds })
                    : null; // await this.fetchUserRelations(userIds) : null;

            result && result.data
                ? result.data.data.map((updatedAuthor) => {
                      authorRefs[updatedAuthor.userId].relation =
                          updatedAuthor.relation;
                      authorRefs[updatedAuthor.userId].userCommunityData =
                          updatedAuthor.userCommunityData;
                      authorRefs[updatedAuthor.userId].connectWithMeText =
                          updatedAuthor.connectWithMeText;
                      authorRefs[updatedAuthor.userId].interests =
                          updatedAuthor.interests;
                  })
                : null;

            this.setState({ authorRefs });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    setEditingCommentHash = (hash) =>
        this.setState({ editingCommentHash: hash });

    deleteCommunityContent = async (contentType, hash) => {
        try {
            const json = await axios.delete(
                `/api/community/moderate/${contentType}/${hash}`
            );
            const { data: results } = json.data || {};

            const { hash: deletedHash, moderationOption } = results;

            const updatedSet = {
                hash: deletedHash,
                updates: {
                    moderationOption,
                },
            };

            this.updateContentBatch([updatedSet]);
            EmitMetric({
                community_content_key: `${contentType}-${hash}`,
                misc_event_count: 1,
                misc_event_type: 'delete_content_completed',
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    editCommunityContent = async (contentType, payload, hash, isPicture) => {
        try {
            // server only returns the updated content object (but not child comments etc)
            // so we shouldn't use the server response object to update the content
            // use payload if server returns 200
            const response = await axios.post(
                `/api/community/moderate/${contentType}/edit/${hash}`,
                payload
            );

            if (response.status !== 200) {
                // if we don't get a 200 server response -> throw error to catch block
                throw new Error(
                    `Error updating content (status code: ${response.status})`
                );
            }

            const { isAnon, author } = this.getContent(hash);
            if (response.data?.data?.moderationOption) {
                payload.moderationOption = response.data.data.moderationOption;
                payload.didEdit =
                    response.data.data.moderationOption.status.type;
                payload.updatedAt = response.data.data.updatedAt;
            }
            if (isPicture) {
                payload.description = payload.title;
                payload.title = null;
            }
            // intentionally not applying a strict inequality due to inconsistencies in number vs boolean types used
            if (payload.anon !== isAnon) {
                payload.author = reserializeAuthor(
                    author,
                    payload.anon,
                    this.state.pageProps.session
                );
                payload.isAnon = payload.anon;
            }
            // 200 -> update current content for the provided hash
            this.updateContent(hash, payload);

            EmitMetric({
                community_content_key: `${contentType}-${hash}`,
                misc_event_count: 1,
                misc_event_type: 'edit_content_completed',
            });

            this.setState({ contentBeingEdited: null });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    editContentTrigger = (hash) => {
        const content = this.getContent(hash);
        if (!content) {
            return console.error('No Hash');
        }
        this.setState({
            contentBeingEdited: hash,
        });
    };

    setContentGroup = (group) => {
        this.setState((state) => ({
            ...state,
            content: state.content.map((e) =>
                e.group && e.group.hash === group.hash
                    ? { ...e, group: group }
                    : e
            ),
        }));
    };

    renderCreateContentWidget = () => {
        const content = this.getContent(this.state.contentBeingEdited);
        let contentType;
        switch (content.type) {
            case 'community-discussion':
                contentType = CONTENT_TYPES.SAY_SOMETHING_FORM;
                break;
            case 'community-photo':
                contentType = CONTENT_TYPES.SHARE_PHOTO_FORM;
                break;
            case 'community-link':
                contentType = CONTENT_TYPES.SHARE_LINK_FORM;
                break;
            default:
                console.error('There is no type found');
        }
        return (
            <PageConsumer>
                {(pageContext) => {
                    return (
                        <CreateContentPopup
                            modal
                            toggle={this.toggleCreateContentWidget}
                            topics={this.props.topics}
                            defaultPostLocation={
                                content.topic ||
                                content.group ||
                                this.state.defaultPostLocation
                            }
                            content={content}
                            contentType={contentType}
                            featureFlags={pageContext.pageProps.featureFlags}
                            onEditSubmit={this.editCommunityContent}
                            editMode
                        />
                    );
                }}
            </PageConsumer>
        );
    };

    toggleCreateContentWidget = () =>
        this.setState({ contentBeingEdited: null });

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
                {this.state.contentBeingEdited &&
                    this.state.contentBeingEdited.length &&
                    this.renderCreateContentWidget()}
            </Context.Provider>
        );
    }
}

PureProvider.propTypes = {
    /**
     * See above of content structure
     */
    content: PropTypes.arrayOf(PropTypes.object),
    /**
     * see state for atribution mode
     */
    attributionMode: PropTypes.string,
    /**
     * ssee state for atibution mode
     */
    singleAttributionContentHash: PropTypes.string,
    /**
     * If we are logged in or not, hack for lack of PAGE_PROPS CONTEXT
     */
    isLoggedIn: PropTypes.bool,
    /**
     * FETCE MODE is how/if we can fetch more data from a source
     */
    fetchMode: PropTypes.oneOf([
        FETCH_MODE_FEED,
        FETCH_MODE_GROUP_FEED,
        FETCH_MODE_MINI_FEED,
        FETCH_MODE_TOPICLIST,
        FETCH_MODE_NONE,
    ]),
    /**
     * FETCE MODE is how/if we can fetch more data from a source
     */
    topics: PropTypes.array,
};

PureProvider.defaultProps = {
    content: [],
    attributionMode: ATTRIBUTION_MODE_NONE,
    singleAttributionContentHash: null,
    isLoggedIn: false,
    fetchMode: FETCH_MODE_NONE,
};

const wrapWithPageContext = (PureProvider) =>
    class ProviderWrap extends PureProvider {
        render() {
            return (
                <PageConsumer>
                    {(context) => {
                        return (
                            <PureProvider
                                {...this.props}
                                pageContext={context}
                            />
                        );
                    }}
                </PageConsumer>
            );
        }
    };

export const Provider = wrapWithPageContext(PureProvider);

export const { Consumer } = Context;
