import PropTypes from 'prop-types';
import React from 'react';
import SocialButtons from '../../../../CallToAction/SocialButtons/SocialButtons';
import CopyToClipboardInput from '../../../../Input/CopyToClipboardInput/CopyToClipboardInput';

import './GroupsAdminOnboardingSocial.scss';

export default class GroupsAdminOnboardingSocial extends React.PureComponent {
    static propTypes = {
        group: PropTypes.object,
    };

    static defaultProps = {
        group: { groupUrl: '' },
    };

    regularContent = (groupUrl) => {
        return (
            <React.Fragment>
                <SocialButtons urlToShare={groupUrl} />
                <CopyToClipboardInput urlToShare={groupUrl} />
            </React.Fragment>
        );
    };

    render() {
        const { groupUrl, privacySetting } = this.props.group;

        if (!groupUrl) {
            return null;
        }
        const isSecretGroup = !!(privacySetting === 'secret');
        const secretGroupClass = isSecretGroup ? 'secret-group-styling' : '';
        return (
            <div
                className={`component-Groups-AdminOnboarding-Steps-GroupsAdminOnboardingSocial ${secretGroupClass}`}
            >
                {!isSecretGroup ? (
                    this.regularContent(groupUrl)
                ) : (
                    <p className="secret-group-text">
                        Please share Fairygodboss but remember that you can only
                        invite people through email or FGB to join secret
                        groups.
                    </p>
                )}
            </div>
        );
    }
}
