import PropTypes from 'prop-types';
import React from 'react';
import ButtonGrid from '../../Components/ButtonGrid/ButtonGrid';
import FormCheckboxWithDescription from '../../Components/FormCheckboxWithDescription/FormCheckboxWithDescription';
import './ButtonCard.scss';

export default class ButtonCard extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        buttonType: PropTypes.string,
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        showCheckbox: PropTypes.bool,
        handleCheckBox: PropTypes.func,
        onClick: PropTypes.func,
        hasSubtitle: PropTypes.bool,
    };

    static defaultProps = {
        buttonData: [],
        buttonType: 'single',
        showCheckbox: false,
    };

    render() {
        const checkboxClass = this.props.showCheckbox ? 'with-checkbox' : '';
        const subtitleClass = this.props.hasSubtitle ? 'with-subtitle' : '';
        return (
            <div className="component-ReviewSurvey-QuestionCards-ButtonCard">
                <ButtonGrid
                    buttonType={this.props.buttonType}
                    buttonData={this.props.buttonData}
                    onClick={this.props.onClick}
                    selectedItems={this.props.selectedItems}
                    gridStyle={`${checkboxClass} ${subtitleClass}`}
                />
                {this.props.showCheckbox && (
                    <FormCheckboxWithDescription
                        onClick={this.props.handleCheckBox}
                        label={this.props.checkboxLabel}
                        defaultChecked={this.props.isChecked}
                    />
                )}
            </div>
        );
    }
}
