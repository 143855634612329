import PropTypes from 'prop-types';
import React from 'react';
import IconTitleItemLayout from '../../../Layout/IconTitleItemLayout/IconTitleItemLayout';

import './WideFgbLogo.scss';

export default class WideFgbLogo extends React.PureComponent {
    render() {
        return (
            <IconTitleItemLayout
                className={`component-Layout-Header-Modules-NavbarItems-WideFgbLogo ${this.props.className}`}
                url={this.props.url}
                imgSrc={this.props.imgSrc}
                size={{ width: 151, height: 24 }}
            >
                {this.props.showEmployerTag && (
                    <span className="employer-tag-logo">EMPLOYER PORTAL</span>
                )}
            </IconTitleItemLayout>
        );
    }
}

WideFgbLogo.defaultProps = {
    className: '',
    showEmployerTag: false,
    url: '',
    imgSrc: '',
};

WideFgbLogo.propTypes = {
    /**
     * additional styling
     */
    className: PropTypes.string,
    /**
     * routing url
     */
    url: PropTypes.string,
    /**
     * image src
     */
    imgSrc: PropTypes.string,
    /**
     * whether display employer tag or not
     */
    showEmployerTag: PropTypes.bool,
};
