import Yup from 'yup';
import constants from '../../../components/Includes/Constants/jobSeeker';
import { createMaxCharError, createRequiredError } from './ContentSchema';

// ============= new schemas ============

export const firstNameSchema = Yup.string()
    .nullable()
    .required()
    .min(1)
    .max(50, createMaxCharError(50));

export const lastNameSchema = Yup.string()
    .nullable()
    .required()
    .min(1)
    .max(50, createMaxCharError(50));

export const emailSchema = Yup.string()
    .email()
    .required()
    .min(1)
    .max(255, createMaxCharError(255));

export const isRemoteSchema = Yup.boolean().required(
    'This is a required field.'
);

export const locationSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(100, createMaxCharError(100))
        .nullable()
        .required('Please select your location.'),
    lat: Yup.number().nullable().required().min(-90).max(90),
    lng: Yup.number().nullable().required().min(-180).max(180),
});

export const locationWithRemoteSchema = Yup.object()
    .when('isRemote', {
        is: false,
        then: Yup.object()
            .shape({
                name: Yup.string()
                    .nullable()
                    .required()
                    .min(1)
                    .max(100, createMaxCharError(100))
                    .nullable()
                    .required('Please select your location.'),
                lat: Yup.number().nullable().required().min(-90).max(90),
                lng: Yup.number().nullable().required().min(-180).max(180),
            })
            .required('Please select your location.'),
        otherwise: Yup.object().nullable(),
    })
    .nullable();

export const willingToRelocateSchema = Yup.boolean().required();

export const jobTitleSchema = Yup.string()
    .min(1)
    .max(200, createMaxCharError(200))
    .nullable()
    .required();

export const professionalSummarySchema = Yup.string()
    .min(1)
    .max(1000, createMaxCharError(1000))
    .nullable();

export const funQuestionSchema = Yup.string()
    .min(1)
    .max(200, createMaxCharError(200))
    .nullable();

export const yearsOfExperienceSchema = Yup.mixed()
    .nullable()
    .required()
    .oneOf(Object.values(constants.YEARS_OF_EXPERIENCE));

export const highestDegreeEarnedSchema = Yup.mixed()
    .nullable()
    .required()
    .oneOf(Object.values(constants.EDUCATION_DEGREES));

export const isCurrentStudentSchema = Yup.boolean().required();

export const raceSchema = Yup.mixed()
    .nullable()
    .required()
    .oneOf(Object.values(constants.RACES));

export const genderSchema = Yup.string()
    .nullable()
    .required()
    .oneOf(Object.values(constants.GENDERS));

export const genderCustomTextSchema = Yup.string().when('gender', {
    is: (val) => val === constants.GENDERS.custom,
    then: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(255, createMaxCharError(255)),
    otherwise: Yup.string().nullable(),
});

export const requiresSponsorshipSchema = Yup.boolean().required();

export const authorizedToWorkInUSSchema = Yup.boolean(
    'This is a required field'
).required('This is a required field');

// ================ old schemas ================
export const completeSchemaOld = Yup.boolean().required();

export const firstNameSchemaOld = Yup.string().when('complete', {
    is: true,
    then: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(50, createMaxCharError(50)),
    otherwise: Yup.string().nullable().min(1).max(50, createMaxCharError(50)),
});

export const lastNameSchemaOld = Yup.string().when('complete', {
    is: true,
    then: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(50, createMaxCharError(50)),
    otherwise: Yup.string().nullable().min(1).max(50, createMaxCharError(50)),
});

export const emailSchemaOld = Yup.string().when('complete', {
    is: true,
    then: Yup.string()
        .email()
        .nullable()
        .required()
        .min(1)
        .max(255, createMaxCharError(255)),
    otherwise: Yup.string()
        .nullable()
        .email()
        .min(1)
        .max(255, createMaxCharError(255)),
});

export const locationSchemaOld = Yup.object().when('complete', {
    is: true,
    then: Yup.object()
        .shape({
            name: Yup.string()
                .nullable()
                .required()
                .min(1)
                .max(100, createMaxCharError(100)),
            lat: Yup.number().nullable().required().min(-90).max(90),
            lng: Yup.number().nullable().required().min(-180).max(180),
        })
        .required(),
    otherwise: Yup.object()
        .shape({
            name: Yup.string()
                .default(null)
                .nullable()
                .min(1)
                .max(100, createMaxCharError(100))
                .test((value) => value === null || value),
            lat: Yup.number()
                .default(null)
                .nullable()
                .min(-90)
                .max(90)
                .test((value) => value === null || value),
            lng: Yup.number()
                .default(null)
                .nullable()
                .min(-180)
                .max(180)
                .test((value) => value === null || value),
        })
        .nullable(),
});

export const willingToRelocateSchemaOld = Yup.boolean().when('complete', {
    is: true,
    then: Yup.boolean().nullable().required(),
    otherwise: Yup.boolean().nullable(),
});

export const jobTitleSchemaOld = Yup.string().when('complete', {
    is: true,
    then: Yup.string()
        .min(1)
        .max(200, createMaxCharError(200))
        .nullable()
        .required(),
    otherwise: Yup.string().min(1).max(200).nullable(),
});

export const professionalSummarySchemaOld = Yup.string()
    .min(1)
    .max(1000, createMaxCharError(1000))
    .nullable();

export const yearsOfExperienceSchemaOld = Yup.mixed().when('complete', {
    is: true,
    then: Yup.mixed()
        .nullable()
        .required()
        .oneOf(Object.values(constants.YEARS_OF_EXPERIENCE)),
    otherwise: Yup.mixed()
        .nullable()
        .oneOf(Object.values(constants.YEARS_OF_EXPERIENCE).concat([null])),
});

export const highestDegreeEarnedSchemaOld = Yup.mixed().when('complete', {
    is: true,
    then: Yup.mixed()
        .nullable()
        .required()
        .oneOf(Object.values(constants.EDUCATION_DEGREES)),
    otherwise: Yup.mixed()
        .nullable()
        .oneOf(Object.values(constants.EDUCATION_DEGREES).concat([null])),
});

export const isCurrentStudentSchemaOld = Yup.boolean().when('complete', {
    is: true,
    then: Yup.boolean().nullable().required(),
    otherwise: Yup.boolean().nullable(),
});

export const linkedinUrlSchema = Yup.string()
    .url()
    .nullable()
    .min(1)
    .max(200, createMaxCharError(200));

export const raceSchemaOld = Yup.mixed().when('complete', {
    is: true,
    then: Yup.mixed()
        .nullable()
        .required()
        .oneOf(Object.values(constants.RACES)),
    otherwise: Yup.mixed()
        .nullable()
        .oneOf(Object.values(constants.RACES).concat([null])),
});

export const genderSchemaOld = Yup.string().when('complete', {
    is: true,
    then: Yup.string()
        .nullable()
        .required()
        .oneOf(Object.values(constants.GENDERS)),
    otherwise: Yup.string()
        .nullable()
        .oneOf(Object.values(constants.GENDERS).concat([null])),
});

export const genderCustomTextSchemaOld = Yup.string().when('gender', {
    is: (val) => val === constants.GENDERS.custom,
    then: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(255, createMaxCharError(255)),
    otherwise: Yup.string()
        .nullable()
        .test(
            'must-be-null-or-undefined',
            'gender custom text should not be set',
            (val) => val === undefined || val === null
        ),
});

export const requiresSponsorshipSchemaOld = Yup.boolean().when('complete', {
    is: true,
    then: Yup.boolean().nullable().required(),
    otherwise: Yup.boolean().nullable(),
});

export const desiredEmployersSchema = Yup.array(
    Yup.object().shape({
        name: Yup.string().required(),
        id: Yup.number().required(),
    })
)
    .min(0)
    .max(5, 'Please limit this to 5 items.')
    .ensure();

export const interestSchema = Yup.array(
    Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required(),
        parent: Yup.object().nullable(),
    })
)
    .min(0)
    .max(50, 'Please limit this to 50.')
    .ensure();

export const authorizedToWorkInUSSchemaOld = Yup.boolean().when('complete', {
    is: true,
    then: Yup.boolean().nullable().required(),
    otherwise: Yup.boolean().nullable(),
});

export const JobProfileFormSchema = {
    complete: completeSchemaOld,
    firstName: firstNameSchemaOld,
    lastName: lastNameSchemaOld,
    email: emailSchemaOld,
    location: locationSchemaOld,
    willingToRelocate: willingToRelocateSchemaOld,
    jobTitle: jobTitleSchemaOld,
    professionalSummary: professionalSummarySchemaOld,
    yearsOfExperience: yearsOfExperienceSchemaOld,
    highestDegreeEarned: highestDegreeEarnedSchemaOld,
    isCurrentStudent: isCurrentStudentSchemaOld,
    linkedinUrl: linkedinUrlSchema,
    race: raceSchemaOld,
    gender: genderSchemaOld,
    genderCustomText: genderCustomTextSchemaOld,
    requiresSponsorship: requiresSponsorshipSchemaOld,
    authorizedToWorkInUS: authorizedToWorkInUSSchemaOld,
};

// ============== end of old schema ==========

export const typeSchema = Yup.string()
    .required('This is a required field')
    .oneOf(['professional-profile']);

export const jobSeekerExperienceSchema = {
    title: Yup.string()
        .min(1)
        .max(200, createMaxCharError(200))
        .required('This is a required field.'),
    startMonth: Yup.number().nullable().required('This is a required field.'),
    endMonth: Yup.number().when(
        ['startYear', 'endYear', 'isCurrent'],
        (startYear, endYear, isCurrent) => {
            if (isCurrent) {
                return Yup.number().nullable();
            }

            return startYear === endYear
                ? Yup.number()
                      .min(
                          Yup.ref('startMonth'),
                          'End date must not be before start date.'
                      )
                      .nullable()
                      .required('This is a required field.')
                : Yup.number().nullable().required('This is a required field.');
        }
    ),
    startYear: Yup.number().nullable().required('This is a required field.'),
    endYear: Yup.number().when('isCurrent', {
        is: true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
            .min(Yup.ref('startYear'), 'End year must be after start year')
            .nullable()
            .required('This is a required field.'),
    }),
    isCurrent: Yup.boolean().required('This is a required field.'),
    company: Yup.object()
        .shape({
            companyName: Yup.string()
                .min(1)
                .max(100, createMaxCharError(100))
                .nullable()
                .required('This is a required field.'),
            companyId: Yup.number()
                .nullable()
                .required('This is a required field'),
        })
        .nullable()
        .required('This is a required field'),
    isRemote: isRemoteSchema,
    location: locationWithRemoteSchema,
    jobType: Yup.string()
        .nullable()
        .oneOf([
            'full-time',
            'part-time',
            'self-employed',
            'freelance',
            'contract',
            'internship',
            'apprenticeship',
            'volunteer',
            null,
        ]),
    description: Yup.string().max(2000, createMaxCharError(2000)).nullable(),
};

export const jobSeekerEducationSchema = {
    school: Yup.object()
        .shape({
            id: Yup.number().nullable().required('This is a required field'),
            name: Yup.string()
                .min(1)
                .max(100, createMaxCharError(100))
                .required('This is a required field.'),
        })
        .nullable()
        .required('This is a required field'),
    isRemote: isRemoteSchema,
    location: locationWithRemoteSchema,
    degree: Yup.string()
        .oneOf(
            [
                'High School',
                'Associate Degree',
                "Bachelor's Degree",
                "Master's Degree",
                'Doctorate / PhD',
                'Other',
            ],
            createRequiredError('This')
        )
        .required(createRequiredError('This')),
    field: Yup.string().nullable(),
    startYear: Yup.number().nullable(),
    endYear: Yup.number().when('isCurrent', {
        is: true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
            .min(Yup.ref('startYear'), 'End year must be after start year')
            .nullable(),
    }),
    isCurrent: Yup.boolean(),
    description: Yup.string().max(2000, createMaxCharError(2000)).nullable(),
};

export const JobProfileFormSchemaV2 = {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    location: locationSchema,
    willingToRelocate: willingToRelocateSchema,
    jobTitle: jobTitleSchema,
    professionalSummary: professionalSummarySchema,
    yearsOfExperience: yearsOfExperienceSchema,
    highestDegreeEarned: highestDegreeEarnedSchema,
    desiredEmployers: desiredEmployersSchema,
    race: raceSchema,
    gender: genderSchema,
    funQuestion: funQuestionSchema,
    interest: interestSchema,
    genderCustomText: genderCustomTextSchema,
    experience: Yup.array(Yup.object().shape(jobSeekerExperienceSchema))
        .min(0)
        .max(10, 'Please limit past experience to 10 entries')
        .ensure(),
    education: Yup.array()
        .of(Yup.object().shape(jobSeekerEducationSchema))
        .min(0)
        .max(10, 'Please limit education to 10 entries')
        .ensure(),
};

export const VCFFormSchema = {
    email: emailSchema,
    isCurrentStudent: isCurrentStudentSchema,
    requiresSponsorship: requiresSponsorshipSchema,
    authorizedToWorkInUS: authorizedToWorkInUSSchema,
};

export const jobApplicantSearchSchema = {
    keyword: Yup.string().nullable(),
    name: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    location: Yup.object()
        .shape({
            name: Yup.string().max(100).nullable().required(),
            lat: Yup.number().required(),
            lng: Yup.number().required(),
        })
        .nullable()
        .default(null),
    jobTitle: Yup.string().nullable(),
    jobReqId: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    showAboutToExpire: Yup.boolean().nullable().default(false),
    showSaved: Yup.boolean().nullable().default(false),
    hideReject: Yup.boolean().nullable().default(false),
    showReject: Yup.boolean().nullable().default(false),
    onlyOneFilterAllowed: Yup.mixed().when(
        ['showAboutToExpire', 'showSaved', 'hideReject', 'showReject'],
        {
            is: (showAboutToExpire, showSaved, hideReject, showReject) => {
                let filterCount = [
                    showAboutToExpire,
                    showSaved,
                    hideReject,
                    showReject,
                ].reduce((accumulator, v) => accumulator + (v ? 1 : 0), 0);
                return filterCount > 1;
            },
            then: Yup.mixed().test(
                'only-one-filter-allowed',
                'Only one filter is allowed',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};

export const jobApplicantSearchSchema_V2 = {
    keyword: Yup.string().nullable(),
    name: Yup.string().nullable(),
    location: Yup.object()
        .shape({
            name: Yup.string().max(100).nullable().required(),
            lat: Yup.number().required(),
            lng: Yup.number().required(),
        })
        .nullable()
        .default(null),
    jobTitle: Yup.string().nullable(),
    jobReqId: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    showAboutToExpire: Yup.boolean().nullable().default(false),
    showSaved: Yup.boolean().nullable().default(false),
    hideReject: Yup.boolean().nullable().default(false),
    showReject: Yup.boolean().nullable().default(false),
    onlyOneFilterAllowed: Yup.mixed().when(
        ['showAboutToExpire', 'showSaved', 'hideReject', 'showReject'],
        {
            is: (showAboutToExpire, showSaved, hideReject, showReject) => {
                let filterCount = [
                    showAboutToExpire,
                    showSaved,
                    hideReject,
                    showReject,
                ].reduce((accumulator, v) => accumulator + (v ? 1 : 0), 0);
                return filterCount > 1;
            },
            then: Yup.mixed().test(
                'only-one-filter-allowed',
                'Only one filter is allowed',
                () => false
            ),
            otherwise: Yup.mixed().test(() => true),
        }
    ),
};
