import PropTypes from 'prop-types';
import React from 'react';
import FlexPopup from '../../../Elements/FlexPopup/FlexPopup.js';
import Button from '../../../Input/Button/Button';
import { buildImagePath } from '../../../JobComponents/Company/Image/logo';
import './ModalAbstraction.scss';

export default class ModalAbstraction extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * contents to be rendered in main body of modal
         */
        children: PropTypes.node,
        /**
         * title to be rendered with modal
         */
        title: PropTypes.string,
        /**
         * description to be rendered with modal
         */
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /**
         * display logic for modal
         */
        showModal: PropTypes.bool,
        /**
         * function to close popup display
         */
        onClose: PropTypes.func,
        allowScroll: PropTypes.bool,
        showButton: PropTypes.bool,
        /**
         * text to be rendered on button
         */
        buttonText: PropTypes.string,
    };

    static defaultProps = {
        showButton: true,
        allowScroll: false,
        title: null,
        description: null,
        showModal: false,
        children: <span></span>,
        onClose: null,
        buttonText: 'Done',
    };

    showX = () => {
        const imageSource = {
            src: '/v1/Close_X_sw0ud2.png',
            size: 'close',
            onClick: this.props.onClose,
        };
        const imageTransforms = {
            close: 'c_scale,f_auto,q_auto,w_22,h_22',
        };
        const src = buildImagePath(
            imageSource.src,
            imageSource.size,
            imageTransforms
        );

        return (
            <div className="popup-layout-header-components">
                <img
                    className={imageSource.size}
                    src={src}
                    onClick={imageSource.onClick}
                />
            </div>
        );
    };

    render() {
        return (
            <div className="component-CallToAction-LeftRailCTA-ModalAbstraction">
                <FlexPopup
                    hidden={!this.props.showModal}
                    onClose={this.props.onClose}
                    allowScroll={this.props.allowScroll}
                >
                    <div className="modal-body-content">
                        {this.showX()}
                        <div className="modal-title">{this.props.title}</div>
                        {this.props.description && (
                            <div className="modal-description">
                                {this.props.description}
                            </div>
                        )}
                        {this.props.children}
                    </div>
                    {this.props.showButton ? (
                        <div className="modalFooter">
                            <div className="modal-footer-button-div">
                                <Button
                                    type={'tertiary-blue-gray'}
                                    value={this.props.buttonText}
                                    onClick={this.props.onClose}
                                />
                            </div>
                        </div>
                    ) : null}
                </FlexPopup>
            </div>
        );
    }
}
