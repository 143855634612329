import React from 'react';

import { ModalContext } from '../../../../contexts/ContextCreator';

import FlowShell from '../FlowShell/FlowShell';
import Enrichment from '../Steps/Enrichment/Enrichment';
import useMount from '../../../../utilities/hooks/useMount';

import { buildEnrichmentFlow, firePdlImpressionMetric } from './constants';

export const profileEnrichmentFlowType = 'profile-enrichment-flow';

export const ProfileEnrichmentFlow = (props) => {
    const { closeModal, generalModalProps } = React.useContext(ModalContext);

    useMount(firePdlImpressionMetric);

    const initializeOptions = {
        closeModal,
        generalModalProps,
    };

    const { enrichments } = generalModalProps;

    const { flowTree, initialStep, initialFlowData } = buildEnrichmentFlow(
        initializeOptions
    );

    const flowSteps = enrichments.map((e) => (
        <Enrichment key={e.stepName} stepName={e.stepName} variant={e.type} />
    ));

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={closeModal}
            useCountTitle
        >
            {flowSteps}
        </FlowShell>
    );
};

ProfileEnrichmentFlow.displayName = 'ProfileEnrichmentFlow';

export default React.memo(ProfileEnrichmentFlow);
