import PropTypes from 'prop-types';
import React from 'react';
import { getCloudinaryVideoPathById } from '../../../utilities/images';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import { SnowplowVideoTracker } from '../../Analytics/Snowplow/SnowplowVideoTracker';
import { videoTypes } from '../../../utilities/ui_utilities';

export default class ResponsiveVideo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            width: this.props.width,
            // height: this.props.height,
            volume: true,
            autoPlay: this.props.autoPlay || false,
        };
    }

    componentDidMount() {
        if (window && this.props.enableAutoResizing) {
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }
    }

    updateWindowDimensions = () => {
        const videoContainer =
            window.document.getElementById('video-container') || {};
        const { clientWidth } = videoContainer;
        this.setState({ width: clientWidth });
    };

    emitMetrics = (extraMetric) => {
        EmitMetric(
            Object.assign(
                extraMetric,
                { misc_event_count: 1 },
                this.props.videoDimensions
            )
        );
    };

    onPlaying = () => {
        const metric = this.props.metric || { misc_event_type: 'video-play' };
        this.emitMetrics(metric);
    };

    onPausing = () => {
        this.emitMetrics({ misc_event_type: 'video-pause' });
    };

    onVolumeChange = () => {
        this.setState(
            (prevState) => ({ volume: !prevState.volume }),
            () => {
                const action = this.state.volume ? 'unMute' : 'mute';
                this.emitMetrics({ misc_event_type: `video-${action}` });
            }
        );
    };

    emitVideoActionMetrics = (videoAction) => {
        if (this.props.videoPlayMetric) {
            SnowplowVideoTracker({
                ...this.props.videoPlayMetric,
                videoAction,
                providerName: videoTypes.CLOUDINARY,
            });
        }
    };

    render() {
        return (
            <div className="component-Elements-Video" id="video-container">
                <video
                    width="100%"
                    height="auto"
                    controls
                    onPlaying={this.onPlaying}
                    onVolumeChange={this.onVolumeChange}
                    autoPlay={this.state.autoPlay}
                    muted
                    playsInline
                    onPlay={() => {
                        this.emitVideoActionMetrics('play');
                    }}
                    onPause={() => {
                        this.onPausing();
                        this.emitVideoActionMetrics('pause');
                    }}
                >
                    <source
                        src={getCloudinaryVideoPathById(
                            this.props.cloudinaryID,
                            this.state.width,
                            Math.floor((this.state.width * 18) / 32),
                            this.props.loadFromCloudFont
                        )}
                        type="video/mp4"
                    />
                </video>
            </div>
        );
    }
}

ResponsiveVideo.propTypes = {
    /**
     * Image Source (Cloudinary Id)
     * */
    cloudinaryID: PropTypes.string.isRequired,
    /**
     * on fly transform size of video
     * */
    width: PropTypes.number,
    /**
     * load the media from cloudfront instead of cloudinary
     */
    loadFromCloudFont: PropTypes.bool,
    autoPlay: PropTypes.bool,
    videoDimensions: PropTypes.objectOf(PropTypes.number),
    metric: PropTypes.objectOf(PropTypes.string),
    enableAutoResizing: PropTypes.bool,
    videoPlayMetric: PropTypes.objectOf(PropTypes.string),
};

ResponsiveVideo.defaultProps = {
    width: 320,
    loadFromCloudFont: true,
    videoDimensions: {},
    enableAutoResizing: true,
    autoPlay: false,
    metric: null,
    videoPlayMetric: null,
};
