import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
// import './Intro.scss';
import MessageCard from '../../QuestionCards/MessageCard/MessageCard';

export default class Intro extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {};
    }

    static propTypes = {
        img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        buttonStyle: PropTypes.string,
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        isAnimated: PropTypes.bool,
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        getCurrentStep: PropTypes.func,
    };
    static defaultProps = {
        buttonStyle: 'purple',
        buttonData: [
            {
                title: 'Start',
                value: true,
            },
        ],
        img: {
            src: '/icons/start_2x',
            alt: '',
        },
        isAnimated: false,
        onAnswer: () => {},
    };

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Intro">
                <MessageCard
                    {...this.props}
                    onClick={() => {
                        EmitMetric({
                            misc_event_type: 'job-review-started',
                            misc_event_count: 1,
                        });
                        this.props.getCurrentStep(this.props.nextStep);
                        this.props.setSurveyCookie();
                    }}
                />
            </div>
        );
    }
}
