/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Consumer as CommunityContextConsumer } from '../../../contexts/CommunityContext';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import HoverCallToActionWrapperComponent from '../../CallToAction/HoverCallToAction/HoverCallToActionWrapperComponent/HoverCallToActionWrapperComponent';
import UserLayout from '../../CallToAction/HoverCallToAction/UserLayout/UserLayout';

import UserImage from '../../Content/User/UserDisplay/UserImage/UserImage';
import { userImageSizeProptype, userProptype } from '../../Includes/userProps';
import CandidateLayout from '../../CallToAction/HoverCallToAction/CandidateLayout/CandidateLayout/CandidateLayout';

export default class UserIconWithOverlay extends React.PureComponent {
    mysteryWomanImage = {
        src: '/v1/avatar_black_160_vtg7lw.svg',
        alt: 'Job candidate',
    };

    constructor(props) {
        super(props);

        this.state = {
            targetUser: props.targetUser,
            standAloneHoverRef: null,
        };

        this.contentHash = `user-overlay-${Math.random()
            .toString(36)
            .substring(2, 15)}`;
    }

    componentDidUpdate() {
        this.updateTargetUser();
    }

    updateTargetUser = () => {
        const { targetUser } = this.props;
        this.setState({ targetUser });
    };

    updateConnectStatus = () => {
        const { targetUser } = this.state;
        const newTargetUser = { ...targetUser };
        newTargetUser.relation.connectionStatus = 'requested';
        newTargetUser.userCommunityData.connectionCount += 1;
        this.setState({
            targetUser: newTargetUser,
        });
    };

    setNodeRef = (ref) => {
        this.setState({
            standAloneHoverRef: ref,
        });
    };

    render() {
        const {
            userIconSize,
            closeHoverTimeout,
            className,
            noLocalOverlay,
            showVipBadge,
        } = this.props;
        const { targetUser, standAloneHoverRef } = this.state;
        const newTargetUser = { ...targetUser };

        return (
            <div
                className={`component-Elements-UserIconWithOverlay ${className}`}
            >
                <PageConsumer>
                    {({ pageProps }) => (
                        <CommunityContextConsumer>
                            {({
                                requestConnection,
                                followUser,
                                setHoverComponent,
                                closeHoverComponent,
                                hoveredComponent,
                                setStandaloneHoverOverlay,
                            }) => {
                                const handleRequestConnection = async (
                                    userId
                                ) => {
                                    const res = await requestConnection(userId);
                                    const isReqSuccess =
                                        res && res.data && res.data.data;
                                    if (isReqSuccess) {
                                        this.updateConnectStatus();
                                    }
                                };

                                const handleFollowUser = async (userId) => {
                                    const res = await followUser(userId);
                                    const isReqSuccess =
                                        res && res.data && res.data.data;
                                    if (isReqSuccess) {
                                        newTargetUser.relation.isFollowing = true;
                                        newTargetUser.userCommunityData.followerCount += 1;
                                        this.setState({
                                            targetUser: newTargetUser,
                                        });
                                    }
                                };
                                let LayoutComponent;
                                let props;
                                switch (this.props.layoutType) {
                                    case 'candidate':
                                        LayoutComponent = CandidateLayout;
                                        props = {
                                            candidate: targetUser,
                                        };
                                        break;
                                    default:
                                        LayoutComponent = UserLayout;
                                        props = {
                                            requestConnection: handleRequestConnection,
                                            followUser: handleFollowUser,
                                            creator: targetUser,
                                            user:
                                                pageProps && pageProps.session,
                                            updateConnectStatus: this
                                                .updateConnectStatus,
                                        };
                                        break;
                                }

                                const image =
                                    this.props.layoutType === 'candidate'
                                        ? this.mysteryWomanImage
                                        : targetUser.image;
                                return (
                                    <HoverCallToActionWrapperComponent
                                        contentHash={this.contentHash}
                                        contextHoverFunction={setHoverComponent}
                                        contextCloseHover={() =>
                                            closeHoverComponent(
                                                closeHoverTimeout
                                            )
                                        }
                                        contextHoveredComponent={
                                            hoveredComponent
                                        }
                                        type="author"
                                        noLocalOverlay={noLocalOverlay}
                                        standAloneHoverRef={standAloneHoverRef}
                                        setStandaloneHoverOverlay={
                                            setStandaloneHoverOverlay
                                        }
                                        hoverComponent={
                                            <LayoutComponent {...props} />
                                        }
                                    >
                                        <UserImage
                                            image={image}
                                            iconSize={userIconSize}
                                            setNodeRef={this.setNodeRef}
                                            isVip={
                                                showVipBadge &&
                                                this.props.layoutType !==
                                                    'candidate' &&
                                                !!(
                                                    targetUser.userCommunityData &&
                                                    targetUser.userCommunityData
                                                        .isVip
                                                )
                                            }
                                        />
                                    </HoverCallToActionWrapperComponent>
                                );
                            }}
                        </CommunityContextConsumer>
                    )}
                </PageConsumer>
            </div>
        );
    }
}

UserIconWithOverlay.propTypes = {
    /**
     * the user being displayed on this component
     */
    targetUser: userProptype.isRequired,
    /**
     * string of user icon size that is going to be an parameter of cloudinary image path
     */
    userIconSize: userImageSizeProptype,
    /**
     * the number of ms the tooltip will be displayed after mouse is no longer hovering the component
     */
    closeHoverTimeout: PropTypes.number,
    /**
     * css class that would override the default stylings
     */
    className: PropTypes.string,
    /**
     * should VIP badge be shown if applicable
     */
    showVipBadge: PropTypes.bool,
    /**
     * Determines whether to show the semi-sanitized professional profile version of the icon, which is the Mystery Woman, and the overlay with full, real name, not the username and account details.
     */
    layoutType: PropTypes.oneOf(['user', 'candidate']),
    noLocalOverlay: PropTypes.bool,
};

UserIconWithOverlay.defaultProps = {
    userIconSize: 'small',
    closeHoverTimeout: 1000,
    className: '',
    showVipBadge: true,
    layoutType: 'user',
    noLocalOverlay: false,
};
