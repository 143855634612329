import React from 'react';

const usePrevious = (value) => {
    const currRef = React.useRef();
    const prevRef = React.useRef();

    prevRef.current = currRef.current;
    currRef.current = value;

    return prevRef.current;
};

export default usePrevious;
