import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import MoreDetailsTextAreaCard from '../../QuestionCards/MoreDetailsTextAreaCard/MoreDetailsTextAreaCard';

import './TellOtherWomenCard.scss';

export default class TellOtherWomenCard extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            comment: props.comment,
        };
    }

    static propTypes = {
        questionName: PropTypes.string,
        payloadKey: PropTypes.string,
        onAnswer: PropTypes.func,
        placeholder: PropTypes.string,
        errors: PropTypes.string,
        isPartTwo: PropTypes.bool,
        nextStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'comment',
        payloadKey: 'comment',
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        const CommentObject = {};
        if (this.props.comment) {
            CommentObject.comment = this.props.comment;
        }
        this.props.comment
            ? this.props.onAnswer(
                  this.props.questionName,
                  CommentObject,
                  CAN_SUBMIT,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    onChange = (comment) => {
        let nextAction = CANT_SUBMIT;
        if (comment && comment.length) {
            nextAction = CAN_SUBMIT;
        }
        const payloadObject = {};
        payloadObject.comment = comment;
        this.setState(payloadObject);
        this.props.onAnswer(
            this.props.questionName,
            payloadObject,
            nextAction,
            false,
            this.props.nextStep
        );
    };

    render() {
        const props = {
            placeholder: this.props.placeholder,
            onChange: this.onChange,
            errorMessage: this.props.errors
                ? 'Your opinions are valuable so we ask for a minimum of 10 words.'
                : null,
            defaultValue: this.state.comment,
            name: 'tellOtherWomen',
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-TellOtherWomenCard">
                <MoreDetailsTextAreaCard {...props} />
            </div>
        );
    }
}
