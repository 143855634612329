import PropTypes from 'prop-types';
import React from 'react';
import ButtonWithChildren from '../../../../Input/ButtonWithChildren/ButtonWithChildren';
import { buildImagePath } from '../../../../JobComponents/Company/Image/logo';

import './FacebookButton.scss';

export default class FacebookButton extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * onclick function
         */
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const iconSrc = buildImagePath(
            '/v1541017251/Login/facebook.png',
            'button'
        );
        return (
            <div className="component-Login-LoginLayoutComponent-SocialMediaButtons-FacebookButton">
                <ButtonWithChildren
                    onClick={() => this.props.onClick('facebook')}
                >
                    <div className="button-content-container">
                        <img src={iconSrc} />
                        <div className="call-to-action-button-text">
                            Continue with Facebook
                        </div>
                    </div>
                </ButtonWithChildren>
            </div>
        );
    }
}
