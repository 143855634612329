import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../contexts/PageContext';
import Pixel, {
    EmitMetric,
} from '../Analytics/VisibilityPixel/VisibilityPixel';
import { buildImagePath } from '../Content/User/UserDisplay/UserImage/UserImage';
import FGBLabel from '../Elements/FGBLabel/FGBLabel';
import Close from '../Icons/Close';
import SVGIconWrapper from '../Icons/SVGIconWrapper/SVGIconWrapper';
import {
    emailInviteSchema,
    groupBatchEmailInviteSchema,
    groupUserInviteSchema,
    userIdInviteSchema,
    employerBatchEmailInviteSchema,
} from '../Includes/FormSchema/ContentSchema';
import { ValidateForm } from '../Includes/FormValidation';
import ChooseLayout from './ChooseLayout/ChooseLayout';
import EmailsLayout from './EmailsLayout/EmailsLayout';
import GroupLayout from './GroupLayout/GroupLayout';

import './InvitationWidget.scss';
import MessageLayout from './MessageLayout/MessageLayout';

export const INVITE_GROUP_VIA_USERID = '/api/network/group/user-invite';
export const INVITE_GROUP_VIA_EMAIL = '/api/network/group/email-invite';
export const INVITE_CONNECTION_VIA_USERID = '/api/network/connection/request';
export const INVITE_CONNECTION_VIA_EMAIL =
    '/api/network/connection/email-invite';
export const INVITE_CONNECTION_VIA_USERID_EMPLOYERS = '/api/employer/invite';

export const getInviteConnectionsImages = (imageSrc, size) => {
    const inviteConnectionsInjectedPaths = {
        icon: 'c_scale,f_auto,q_auto,w_60,h_60',
    };
    return buildImagePath(imageSrc, size, inviteConnectionsInjectedPaths);
};

export default class InvitationWidget extends React.Component {
    constructor(props) {
        super();
        let steps = [];
        let defaultMessage;

        if (!props.type) {
            steps.push('chooseType');
        }

        if (!(props.group && props.group.hash) && props.type === 'group') {
            steps.push('group');
        }

        if (!(props.user && props.user.userId)) {
            steps.push('emails');
        }

        if (props.messageType === 'employer') {
            defaultMessage =
                "Hi, I've invited you to join our company on Fairygodboss. Use the button below to create your account.";
        }

        steps.push('messages');
        this.state = {
            type: props.type,
            flow: {
                pages: steps,
                step: 0,
            },
            inviteEmails: null,
            message: props.message || defaultMessage || null,
            user: props.user || null,
            group: props.group || null,
            showConfirmationModal: true,
            errorinviteEmails: null,
            errormessage: null,
            erroruserId: null,
            errorcommunityGroupHash: null,
            errorgeneral: null,
            isLoading: false,
        };
    }

    static propTypes = {
        type: PropTypes.oneOf(['group', 'connection', null]),
        user: PropTypes.shape({
            username: PropTypes.string,
            userId: PropTypes.number,
            image: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            credential: PropTypes.string,
        }),
        group: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }),
        messageType: PropTypes.oneOf(['user', 'employer']),
        onSuccess: PropTypes.func,
        onCloseRedirectUrl: PropTypes.string,
        isModal: PropTypes.bool,
        modalTriggerParameters: PropTypes.shape({
            modalName: PropTypes.string,
            modalData: PropTypes.object,
        }),
        // text to override each step's heading text (i.e. add emails, send invitation, etc)
        // caution: makes all steps have same title
        stepLabelsOverrideText: PropTypes.string,
    };

    static defaultProps = {
        isModal: false,
        onCloseRedirectUrl: null,
        messageType: 'user',
        stepLabelsOverrideText: '',
    };

    //DECREMENTS STEPS
    //=========================================================
    onBack = () => {
        this.setState((prevState) => {
            const newFlow = Object.assign({}, prevState.flow);
            newFlow.step =
                prevState.flow.step > 0
                    ? prevState.flow.step - 1
                    : prevState.flow.step;
            return {
                flow: newFlow,
            };
        });
    };

    //INCREMENT STEPS
    //=========================================================
    onUpdate = (key, value, submitForm = false, modalTrigger = () => {}) => {
        const callBackFunc = submitForm
            ? () => this.submitForm(modalTrigger)
            : null;
        this.setState((prevState) => {
            const newFlow = Object.assign({}, prevState.flow);
            newFlow.step =
                prevState.flow.step < prevState.flow.pages.length - 1
                    ? prevState.flow.step + 1
                    : prevState.flow.step;
            return {
                [key]: value,
                flow: newFlow,
            };
        }, callBackFunc);
    };

    returnFormSubmissionData = () => {
        const { group, user, inviteEmails, message } = this.state;
        const groupHash = group && group.hash;
        const userId = user && user.userId;
        let APIURL;
        let formValues;
        let ValidationSchema;
        switch (true) {
            case userId && this.state.type === 'group':
                APIURL = INVITE_GROUP_VIA_USERID;
                formValues = {
                    userId: userId,
                    message: message,
                    communityGroupHash: groupHash,
                };
                ValidationSchema = groupUserInviteSchema;
                break;
            case userId && this.state.type === 'connection':
                APIURL = INVITE_CONNECTION_VIA_USERID;
                formValues = {
                    toUserId: userId,
                    message: message,
                };
                ValidationSchema = userIdInviteSchema;
                break;
            case groupHash && this.state.type === 'group':
                APIURL = INVITE_GROUP_VIA_EMAIL;
                formValues = {
                    inviteEmails: inviteEmails,
                    message: message,
                    communityGroupHash: groupHash,
                };
                ValidationSchema = groupBatchEmailInviteSchema;
                break;
            case this.props.messageType === 'employer':
                APIURL = INVITE_CONNECTION_VIA_USERID_EMPLOYERS;
                formValues = {
                    inviteEmails: inviteEmails,
                    message: message,
                };
                ValidationSchema = employerBatchEmailInviteSchema;
                break;
            default:
                APIURL = INVITE_CONNECTION_VIA_EMAIL;
                formValues = {
                    inviteEmails: inviteEmails,
                    message: message,
                };
                ValidationSchema = emailInviteSchema;
                break;
        }

        return {
            APIURL,
            formValues,
            ValidationSchema,
        };
    };

    //Submit form Function
    //=========================================================
    submitForm = async (modalTrigger) => {
        const API_POST_INFO = this.returnFormSubmissionData();

        const isValid = await ValidateForm(
            API_POST_INFO.formValues,
            API_POST_INFO.ValidationSchema,
            this.errorSetStateFunction
        );
        this.setIsLoading(true);
        const modalTriggerModalName =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalName;
        const modalTriggerModalProps =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalData
                ? Object.assign(this.props.modalTriggerParameters.modalData, {
                      userSearchMessage: this.state.message,
                      group: this.state.group,
                  })
                : null;

        const confrimationModalButtonProps = {
            buttonText: 'Continue',
            buttonType: 'purple-medium-white',
        };

        const confirmationModalProps = {
            imageSrc: 'v1560193582/Groups/confirmation-icon.svg',
            heading: 'Thanks for inviting!',
            onClose: () =>
                modalTrigger(modalTriggerModalName, modalTriggerModalProps),
            buttonMetas: [confrimationModalButtonProps],
        };

        if (isValid) {
            try {
                if (this.props.onCloseRedirectUrl) {
                    confrimationModalButtonProps.redirectUrl = this.props.onCloseRedirectUrl;
                } else {
                    confrimationModalButtonProps.onClickHandler = () =>
                        modalTrigger(
                            modalTriggerModalName,
                            modalTriggerModalProps
                        );
                }

                await axios.post(
                    API_POST_INFO.APIURL,
                    API_POST_INFO.formValues
                );

                const metricPayload = {
                    misc_event_type: 'invite-flow-success',
                    misc_event_count: 1,
                };

                if (this.state.group) {
                    metricPayload.community_group_hash = this.state.group.hash;
                }

                EmitMetric(metricPayload);

                if (this.state.type === 'connection' && this.props.user) {
                    EmitMetric({
                        misc_event_type: 'user-request-connection',
                        misc_event_count: 1,
                    });
                }

                modalTrigger('ConfirmationModal', confirmationModalProps);
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.setIsLoading();
            } catch (e) {
                console.log(e);
                const ERROR_MESSAGE_ALREADY_INVITED =
                    'You already invited this user.';
                const ERROR_MESSAGE_ALREADY_JOINED =
                    'user already joined the group';
                const errorMessages =
                    e.response &&
                    e.response.data &&
                    e.response.data.errors &&
                    e.response.data.errors.userId;

                if (
                    errorMessages &&
                    (errorMessages.includes(ERROR_MESSAGE_ALREADY_JOINED) ||
                        errorMessages.includes(ERROR_MESSAGE_ALREADY_INVITED))
                ) {
                    modalTrigger('ConfirmationModal', confirmationModalProps);
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                } else {
                    this.setState({
                        errorgeneral:
                            'There was an error processing your request.',
                    });
                }
                this.setIsLoading();
            }
        }
    };

    setIsLoading = (isLoading = false) => {
        this.setState({
            isLoading,
        });
    };

    //Form Validation
    //=========================================================
    validateForm = async (objectToValidate, ValidationSchema) => {};

    static getDerivedStateFromProps(props, state) {
        if (state.type !== props.type) {
            const groupIndex = state.flow.pages.indexOf('group');
            if (
                state.type === 'group' &&
                groupIndex === -1 &&
                !(state.group && state.group.hash)
            ) {
                state.flow.pages.splice(1, 0, 'group');
            }

            if (state.type !== 'group' && groupIndex >= 0) {
                state.flow.pages.splice(groupIndex, 1);
            }
        }
        return null;
    }

    //============================================================
    errorSetStateFunction = (errors, location) => {
        this.setState(errors);
    };

    getMessageLayoutProps = (modalTrigger) => {
        let image = null;
        let title = null;
        let description = null;
        let isFGBInvite = false;
        let isGroupInvite = false;
        if (this.state.group && this.state.type === 'group') {
            image = this.state.group.logo || null;
            title = this.state.group.name || null;
            description = null;
            isGroupInvite = true;
        } else if (this.state.user) {
            image = this.state.user.image || null;
            title = this.state.user.username || null;
            description = this.state.user.credential || null;
        }

        if (this.state.user && this.state.user.userId) {
            isFGBInvite = true;
        }

        return {
            onNext: this.onUpdate,
            onPrev: this.onBack,
            confirmationModalTrigger: modalTrigger,
            nextDisabled: this.state.isLoading,
            message: this.state.message,
            image,
            title,
            description,
            setIsLoading: this.setIsLoading,
            isFGBInvite,
            isGroupInvite,
        };
    };

    //SWITCH STATEMENTS FOR DECIDING WHICH COMPONENT TO SHOW
    //=========================================================
    getComponent = (groupsArray, modalTrigger) => {
        const allowGroupInvite = !!(groupsArray && groupsArray.length);
        const error =
            this.state.errormessage ||
            this.state.errorinviteEmails ||
            this.state.erroruserId ||
            this.state.errorcommunityGroupHash ||
            this.state.errorgeneral ||
            null;
        const hasPrevButton = !(this.state.flow.step === 0);

        switch (this.state.flow.pages[this.state.flow.step]) {
            case 'chooseType':
                return (
                    <ChooseLayout
                        allowGroupInvite={allowGroupInvite}
                        onClick={this.onUpdate}
                    />
                );
            case 'group':
                return (
                    <GroupLayout
                        group={this.state.group}
                        onNext={this.onUpdate}
                        onPrev={this.onBack}
                        groupsArray={groupsArray}
                        showPrevBtn={hasPrevButton}
                    />
                );
            case 'emails':
                return (
                    <EmailsLayout
                        onNext={this.onUpdate}
                        onPrev={this.onBack}
                        inviteEmails={this.state.inviteEmails}
                        showPrevBtn={hasPrevButton}
                    />
                );
            case 'messages':
                let messageLayoutProps = this.getMessageLayoutProps(
                    modalTrigger
                );
                return (
                    <MessageLayout
                        {...messageLayoutProps}
                        error={error}
                        showPrevBtn={hasPrevButton}
                    />
                );
            default:
                return;
        }
    };

    getLabelText = () => {
        const { stepLabelsOverrideText } = this.props;

        if (stepLabelsOverrideText) return stepLabelsOverrideText;

        switch (this.state.flow.pages[this.state.flow.step]) {
            case 'chooseType':
                return 'Send Invitations';
            case 'group':
                return 'Invite To A Group';
            case 'emails':
                return 'Add Emails';
            case 'messages':
                return 'Write A Note';
            default:
                return;
        }
    };

    getGroupArray = (groups) => {
        let groupsArray = [];

        if (this.props.user && this.props.user.userId) {
            groupsArray = groups.adminGroups || [];
        } else {
            const adminGroups = groups.adminGroups || [];
            const memberGroups = groups.memberGroups
                ? groups.memberGroups.filter(
                      (group) => group.privacySetting === 'open'
                  )
                : [];
            groupsArray = adminGroups.concat(memberGroups);
        }
        return groupsArray;
    };

    render() {
        const modalTriggerModalName =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalName;
        const modalTriggerModalProps =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalData
                ? Object.assign(this.props.modalTriggerParameters.modalData, {
                      userSearchMessage: this.state.message,
                      group: this.state.group,
                  })
                : null;

        return (
            <PageConsumer>
                {({ modalTrigger, groups }) => {
                    const groupsArray = this.getGroupArray(groups.data);
                    return (
                        <div className="component-InvitationWidget">
                            <Pixel
                                metrics={{
                                    misc_event_type: 'invite-flow-initiated',
                                    misc_event_count: 1,
                                }}
                            />
                            <FGBLabel className={'black-font'}>
                                {this.getLabelText()}
                            </FGBLabel>
                            {this.props.isModal && (
                                <SVGIconWrapper
                                    SVGWrapperCssClass={'close-icon'}
                                    handleClickSVGWrapper={() =>
                                        modalTrigger(
                                            modalTriggerModalName,
                                            modalTriggerModalProps
                                        )
                                    }
                                >
                                    {Close}
                                </SVGIconWrapper>
                            )}
                            {this.getComponent(groupsArray, modalTrigger)}
                        </div>
                    );
                }}
            </PageConsumer>
        );
    }
}
