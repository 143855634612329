import { iterateObject } from '../../../../utilities/helpers/object';

// Common steps
export const QUICK_PROFILE_STEP_NAME = 'quickProfile';
export const SELECT_TIME_STEP_NAME = 'selectTime';
export const CONFIRM_SUCCESS_STEP_NAME = 'confirmSuccess';

// Event registration flow
export const EVENT_REQUIREMENTS_INPUT_STEP_NAME = 'eventRequirementsInput';

// Single experience/education flow
export const SINGLE_EDUCATION_STEP_NAME = 'singleEducation';
export const SINGLE_EXPERIENCE_STEP_NAME = 'singleExperience';

// Diversity flow
export const GENDER_IDENTITY_STEP_NAME = 'genderIdentity';
export const RACE_ETHNICITY_STEP_NAME = 'raceEthnicity';
export const SEXUAL_ORIENTATION_STEP_NAME = 'sexualOrientation';
export const VETERAN_DISABILITY_STEP_NAME = 'veteranDisability';

export const quickExperience = {
    title: '',
    company: {
        companyId: null,
        companyName: '',
    },
    location: {
        name: '',
        lat: null,
        lng: null,
    },
    isRemote: false,
};

export const quickEducation = {
    school: {
        id: null,
        name: '',
    },
    degree: '',
    location: {
        name: '',
        lat: null,
        lng: null,
    },
    isRemote: false,
};

export const defaultStepFormData = {
    [SELECT_TIME_STEP_NAME]: {
        preferredTimeId: '',
    },
    [EVENT_REQUIREMENTS_INPUT_STEP_NAME]: {
        resumeFileName: '',
        resumeUrl: '',
        firstName: '',
        lastName: '',
        email: '',
        yearsOfExperience: '',
        isAuthorizedToWork: '',
        isRequiredSponsorship: '',
        isCurrentStudent: '',
    },
    [QUICK_PROFILE_STEP_NAME]: {
        name: '',
        education: quickEducation,
        experience: quickExperience,
        isStudent: false,
    },
    [SINGLE_EDUCATION_STEP_NAME]: {
        ...quickEducation,
        id: null,
        isRemote: false, // "online school"
        field: '',
        startYear: '',
        endYear: '',
        isCurrent: false,
        description: '', // "highlights"
    },
    [SINGLE_EXPERIENCE_STEP_NAME]: {
        ...quickExperience,
        experienceId: null,
        id: null,
        startMonth: '',
        endMonth: '',
        startYear: '',
        endYear: '',
        isCurrent: false,
        description: '', // "your key accomplishments"
    },
    [GENDER_IDENTITY_STEP_NAME]: {
        genderChoice: '',
        optionalOtherText: '',
    },
    [RACE_ETHNICITY_STEP_NAME]: {
        ethnicityChoices: [],
        optionalOtherText: '',
    },
    [SEXUAL_ORIENTATION_STEP_NAME]: {
        orientationChoices: [],
        optionalOtherText: '',
    },
    [VETERAN_DISABILITY_STEP_NAME]: {
        armedForcesChoice: '',
        disabilityChoice: '',
    },
};

export const validStepsList = Object.keys(defaultStepFormData);

export const getResetValuesForStep = (step) => defaultStepFormData[step];

export const getDefaultErrorsForStep = (step) =>
    iterateObject(defaultStepFormData[step], ([k, v]) =>
        k === 'lat' || k === 'lng' ? { [k]: v } : { [k]: '' }
    );
