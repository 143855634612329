import PropTypes from 'prop-types';
import React from 'react';
import NextArrow from '../../../../Icons/NextArrow/NextArrow';
import { buildImagePath } from '../../../../JobComponents/Company/Image/logo';

import './GroupsAdminOnboardingWelcomeScreen.scss';

export default class GroupsAdminOnboardingWelcomeScreen extends React.PureComponent {
    static propTypes = {
        handleStart: PropTypes.func,
    };

    static defaultProps = {
        handleStart: () => {},
    };

    handleClick = () => {
        this.props.handleStart();
    };

    render() {
        return (
            <div className="component-Groups-AdminOnboarding-Steps-GroupsAdminOnboardingWelcomeScreen">
                <div className="blue-circle-background">
                    <img
                        className="group-of-girls-image"
                        src={buildImagePath(
                            '/v1/group_of_girls_ksdx3c.svg',
                            'default',
                            { default: '' }
                        )}
                        alt=""
                    />
                    <p className="text-styling">Grow your groups</p>
                    <p className="description-text">
                        Find and invite new members.
                    </p>
                </div>
                <div className="button-div">
                    <button className="start-button" onClick={this.handleClick}>
                        Start <NextArrow />
                    </button>
                </div>
            </div>
        );
    }
}
