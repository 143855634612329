import PropTypes from 'prop-types';
import React from 'react';

export default class NavMenuItem extends React.PureComponent {
    render() {
        const { navbarPosition, metaData, Component } = this.props;

        if (!Component || navbarPosition !== metaData.position) {
            return null;
        }

        return <Component {...this.props} />;
    }
}

NavMenuItem.propTypes = {
    /**
     * current navbar's position
     */
    navbarPosition: PropTypes.string.isRequired,
    /**
     * position of an nav item specified in metaData
     */
    metaData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    /**
     * Component to be render if positions matched
     */
    Component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.node,
    ]).isRequired,
    loginProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

NavMenuItem.defaultProps = {};
