import PropTypes from 'prop-types';
import React from 'react';

import './ModalShell.scss';

export default class ModalShell extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        const { children, className } = this.props;

        const cn = `component-Modals-ModalParts-ModalShell ${className}`.trim();

        return <div className={cn}>{children}</div>;
    }
}
