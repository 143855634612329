import PropTypes from 'prop-types';
import React from 'react';
import MessageCard from '../../QuestionCards/MessageCard/MessageCard';

export default class Finish extends React.PureComponent {
    static propTypes = {
        img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        buttonStyle: PropTypes.string,
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        isAnimated: PropTypes.bool,
        onAnswer: PropTypes.func,
        removeSurveyCookie: PropTypes.func,
    };
    static defaultProps = {
        hasAuthWrapper: false,
        buttonStyle: 'purple',
        buttonData: [
            {
                title: 'Finish',
                value: true,
            },
        ],
        img: {
            src: '/icons/Congratulations_2x',
            alt: '',
        },
        isAnimated: true,
        onAnswer: () => {},
    };

    firingGTM = () => {
        if (typeof dataLayer !== 'undefined' && this.props.loggedIn) {
            dataLayer.push({
                event: 'leaving-review',
                category: 'jobReviewV2',
                label: 'leavingJobReview',
            });
        }
    };

    handleUpdate = async () => {
        try {
            const completed = await this.props.completeSurvey();
            completed ? this.closeSurvey() : null;
        } catch (e) {
            console.log(e);
            this.closeSurvey();
        }
    };

    closeSurvey = () => {
        this.firingGTM();
        this.props.removeSurveyCookie();
        this.props.closeSurvey();
    };

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Finish">
                <MessageCard {...this.props} onClick={this.handleUpdate} />
                {this.props.error ? (
                    <div className={'errorMessage'}>{this.props.error}</div>
                ) : null}
            </div>
        );
    }
}
