import React from 'react';
import PropTypes from 'prop-types';
import './Counter.scss';

export const Counter = ({
    countNumber = 0,
    type = 'default',
    cssClass = '',
}) => (
    <div className={`counter ${type} ${cssClass}`}>
        {countNumber >= 100 ? '99+' : countNumber}
    </div>
);

Counter.propTypes = {
    /**
     * count number
     */
    countNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * type of badge for styling
     */
    type: PropTypes.oneOf(['default', 'star']),
    /**
     * styling that would override default
     */
    cssClass: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        .isRequired,
};
