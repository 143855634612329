import Yup from 'yup';
import { USER_PROFILE_KEYS } from '../../../components/Includes/Constants/user';
import { createMaxCharError } from './ContentSchema';
import {
    processYupValidationErrors,
    prepareFormValuesForValidation,
} from '../../../utilities/helpers/form';
import { educationSchema } from './EducationSchema';
import { experienceSchema } from './ExperienceSchema';

export const nameSchema = Yup.string()
    .nullable()
    .required()
    .min(1)
    .max(100, createMaxCharError(100));

export const locationSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required()
        .min(1)
        .max(100, createMaxCharError(100))
        .nullable()
        .required('Please select your location.'),
    lat: Yup.number().nullable().required().min(-90).max(90),
    lng: Yup.number().nullable().required().min(-180).max(180),
});

export const locationWithRemoteSchema = Yup.object()
    .when('isRemote', {
        is: false,
        then: Yup.object()
            .shape({
                name: Yup.string()
                    .nullable()
                    .required()
                    .min(1)
                    .max(100, createMaxCharError(100))
                    .nullable()
                    .required('Please select your location.'),
                lat: Yup.number().nullable().required().min(-90).max(90),
                lng: Yup.number().nullable().required().min(-180).max(180),
            })
            .required('Please select your location.'),
        otherwise: Yup.object().nullable(),
    })
    .nullable();

export const desiredCompaniesSchema = Yup.array(
    Yup.object().shape({
        id: Yup.number().nullable().required('This is a required field'),
        name: Yup.string().nullable().required('This is a required field.'),
    })
)
    .min(0)
    .max(5, 'Please limit this to 5 items.')
    .ensure();

export const interestSchema = Yup.array(
    Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required(),
        parent: Yup.object().nullable(),
    })
)
    .min(0)
    .max(50, 'Please limit this to 50.')
    .ensure();

export const userProfileIdentityUpdateSchema = {
    logo: Yup.string().min(1).max(255).nullable().required(),
    name: Yup.string()
        .min(1)
        .max(100, 'Please reduce to 100 characters.')
        .nullable()
        .required('This is a required field.'),
    credential: Yup.string().min(1).max(48, createMaxCharError(48)).nullable(),
    profileBio: Yup.string()
        .min(1)
        .max(500, createMaxCharError(500))
        .nullable(),
    website: Yup.string()
        .url('Please enter a valid URL like https://www.example.com')
        .min(1)
        .max(200, createMaxCharError(200))
        .nullable(),
};

export const UserProfileSchema = {
    [USER_PROFILE_KEYS.NAME]: nameSchema,
    [USER_PROFILE_KEYS.IDENTITY]: Yup.object().shape({
        [USER_PROFILE_KEYS.NAME]: nameSchema,
    }),
    [USER_PROFILE_KEYS.EXPERIENCES]: Yup.array()
        .of(experienceSchema)
        .min(0)
        .ensure(),
    [USER_PROFILE_KEYS.EDUCATIONS]: Yup.array()
        .of(educationSchema)
        .min(0)
        .ensure(),
    [USER_PROFILE_KEYS.LOGO]: Yup.string()
        .min(1)
        .max(255)
        .nullable()
        .required(),
    [USER_PROFILE_KEYS.CREDENTIAL]: Yup.string()
        .min(1)
        .max(48, createMaxCharError(48))
        .nullable(),
    [USER_PROFILE_KEYS.ABOUT_ME]: Yup.string()
        .min(1)
        .max(200, createMaxCharError(200))
        .nullable(),
    [USER_PROFILE_KEYS.WEBSITE]: Yup.string()
        .url('Please enter a valid URL like https://www.example.com')
        .min(1)
        .max(200, createMaxCharError(200))
        .nullable(),
    [USER_PROFILE_KEYS.DESIRED_COMPANIES]: desiredCompaniesSchema,
    [USER_PROFILE_KEYS.INTERESTS]: interestSchema,
};

export const buildSchemaShape = (payload) => {
    const schemaTypes = Object.keys(payload);

    const schema = {};

    schemaTypes.forEach((type) => {
        if (Object.keys(UserProfileSchema).includes(type)) {
            schema[type] = UserProfileSchema[type];
        }
    });

    return schema;
};

export const validatePayload = async (payload) => {
    const schema = buildSchemaShape(payload);

    try {
        await Yup.object()
            .shape(schema)
            .validate(payload, { abortEarly: false });
        return {
            valid: true,
            errors: null,
        };
    } catch (error) {
        return {
            valid: false,
            errors: error.inner,
        };
    }
};

export const basicLocationSchema = Yup.object({
    name: Yup.string().when('lat', {
        is: (lat) => !lat || lat.length === 0,
        then: Yup.string()
            .min(100, 'Please enter a valid location.')
            .required('Please enter a valid location.'), // workaround to require a selection from the dropdown
        otherwise: Yup.string()
            .nullable()
            .min(1)
            .max(100, createMaxCharError(100))
            .required('Please add your location.'),
    }),
    lat: Yup.number().nullable().min(-90).max(90),
    lng: Yup.number().nullable().min(-180).max(180),
});

export const quickEducationSchema = Yup.lazy((value) => {
    if (value === null) return Yup.mixed().notRequired();
    return Yup.object({
        school: Yup.object({
            id: Yup.number().nullable(),
            name: Yup.string()
                .min(1)
                .max(100, createMaxCharError(100))
                .required('School is a required field.'),
        }),
        location: Yup.object().when('isRemote', {
            is: true,
            then: Yup.object().notRequired(),
            otherwise: basicLocationSchema,
        }),
        degree: Yup.string()
            .oneOf(
                [
                    'High School',
                    'Associate Degree',
                    "Bachelor's Degree",
                    "Master's Degree",
                    'Doctorate / PhD',
                    'Other',
                ],
                'Degree is a required field.'
            )
            .required('Degree is a required field.'),
        isRemote: Yup.boolean(),
    });
});

const commentTypeEnum = ['ethnicity', 'gender', 'orientation'];
const genderEnum = [
    'Agender',
    'Woman',
    'Genderqueer or non-binary',
    'Man',
    'Transgender Man',
    'Transgender Woman',
    'Transgender Person',
    'I prefer not to say',
    'Other',
    null,
];
const veteranDisabilityEnum = ['Yes', 'No', 'I prefer not to say', null];

export const demographicsSchema = {
    gender: Yup.string().oneOf(genderEnum).nullable(),
    ethnicity: Yup.array(Yup.number()),
    sexualOrientation: Yup.array(Yup.number()),
    veteran: Yup.string().oneOf(veteranDisabilityEnum).nullable(),
    disability: Yup.string().oneOf(veteranDisabilityEnum).nullable(),
    otherComments: Yup.array(
        Yup.object().shape({
            id: Yup.number().nullable(),
            type: Yup.string().oneOf(commentTypeEnum).required(),
            comment: Yup.string().min(1).max(200).required(),
        })
    ),
};

export const quickExperienceSchema = Yup.lazy((value) => {
    if (value === null) return Yup.mixed().notRequired();
    return Yup.object({
        title: Yup.string()
            .min(1, 'Job title must be at least 1 character')
            .max(200, createMaxCharError(200))
            .required('Please add or select a job title from the dropdown.'),
        company: Yup.object({
            companyName: Yup.string().when('companyId', {
                is: (companyId) => !companyId || companyId.length === 0,
                then: Yup.string()
                    .min(
                        100,
                        'Please add or select a company from the dropdown.'
                    )
                    .required(
                        'Please add or select a company from the dropdown.'
                    ),
                otherwise: Yup.string()
                    .min(1, 'Must be at least one character.')
                    .max(100, createMaxCharError(100))
                    .nullable()
                    .required('Company is required field.'),
            }),
            companyId: Yup.number().nullable(),
        }),
        isRemote: Yup.boolean(),
        location: Yup.object().when('isRemote', {
            is: true,
            then: Yup.object().notRequired(),
            otherwise: basicLocationSchema,
        }),
    });
});

export const quickProfileSchema = Yup.object({
    name: nameSchema.required(),
    education: quickEducationSchema,
    experience: quickExperienceSchema,
});

export const validateYupSchema = async (values, schema, context = {}) => {
    const validateData = prepareFormValuesForValidation(values);
    try {
        await schema.validate(validateData, {
            abortEarly: false,
            context,
        });
        return { valid: true, errors: null };
    } catch (error) {
        return { valid: false, errors: processYupValidationErrors(error) };
    }
};

export const validateQuickProfilePayload = async (values, context = {}) => {
    const { valid, errors } = await validateYupSchema(
        values,
        quickProfileSchema
    );
    return { valid, validationErrors: errors };
};

const diversityFlowSchema = Yup.object().shape({
    genderIdentity: Yup.object().shape({
        genderChoice: Yup.string()
            .oneOf([
                'Agender',
                'Woman',
                'Genderqueer or non-binary',
                'Man',
                'Transgender Man',
                'Transgender Woman',
                'Transgender Person',
                'I prefer not to say',
                'Other',
            ])
            .notRequired(),
        optionalOtherText: Yup.string().when('genderChoice', {
            is: (genderChoice) => genderChoice === 'Other',
            then: Yup.string().max(200, 'Please limit to 200 characters.'),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    raceEthnicity: Yup.object().shape({
        ethnicityChoices: Yup.array().of(
            Yup.string()
                .oneOf([
                    'African American / Black',
                    'Native American / Alaska Native',
                    'East Asian',
                    'Hispanic',
                    'Latinx',
                    'Middle Eastern',
                    'Pacific Islander',
                    'South Asian',
                    'Southeast Asian',
                    'White',
                    'I prefer not to say',
                    'Other',
                ])
                .notRequired()
        ),
        optionalOtherText: Yup.string().when('ethnicityChoices', {
            is: (choices) => choices.includes('Other'),
            then: Yup.string().max(200, 'Please limit to 200 characters.'),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    sexualOrientation: Yup.object().shape({
        orientationChoices: Yup.array().of(
            Yup.string()
                .oneOf([
                    'Asexual',
                    'Bisexual',
                    'Gay',
                    'Heterosexual or straight',
                    'Lesbian',
                    'Pansexual',
                    'Queer',
                    'Questioning or unsure',
                    'An identity not listed',
                    'I prefer not to say',
                    'Other',
                ])
                .notRequired()
        ),
        optionalOtherText: Yup.string().when('orientationChoices', {
            is: (orientationChoices) => orientationChoices.includes('Other'),
            then: Yup.string().max(200, 'Please limit to 200 characters.'),
            otherwise: Yup.string().notRequired(),
        }),
    }),
    veteranDisability: Yup.object().shape({
        armedForcesChoice: Yup.string()
            .oneOf(
                ['Yes', 'No', 'I prefer not to say'],
                'Please choose a valid option'
            )
            .notRequired(),
        disabilityChoice: Yup.string()
            .oneOf(
                ['Yes', 'No', 'I prefer not to say'],
                'Please choose a valid option'
            )
            .notRequired(),
    }),
});

export const validateGenderIdentityStep = async (values, context = {}) => {
    const stepSchema = Yup.reach(diversityFlowSchema, 'genderIdentity');
    const { valid, errors } = await validateYupSchema(values, stepSchema);
    return { valid, errors };
};

export const validateRaceEthnicityStep = async (values, context = {}) => {
    const stepSchema = Yup.reach(diversityFlowSchema, 'raceEthnicity');
    const { valid, errors } = await validateYupSchema(values, stepSchema);
    return { valid, errors };
};

export const validateSexualOrientationStep = async (values, context = {}) => {
    const stepSchema = Yup.reach(diversityFlowSchema, 'sexualOrientation');
    const { valid, errors } = await validateYupSchema(values, stepSchema);
    return { valid, errors };
};

export const validateVeteranDisabilityStep = async (values, context = {}) => {
    const stepSchema = Yup.reach(diversityFlowSchema, 'veteranDisability');
    const { valid, errors } = await validateYupSchema(values, stepSchema);
    return { valid, errors };
};
