import PropTypes from 'prop-types';
import React from 'react';
import NextArrow from '../../Icons/NextArrow/NextArrow';
import Button from '../../Input/Button/Button';

import './StepsControlSection.scss';

export default class StepsControlSection extends React.PureComponent {
    static propTypes = {
        /**
         * total number of steps
         */
        totalSteps: PropTypes.number.isRequired,
        /**
         * index of current selected step
         */
        selectedStepIndex: PropTypes.number.isRequired,
        /**
         * function that will trigger on click finish button at last step
         */
        handleFinish: PropTypes.func,
        /**
         * function that will trigger on click skip button
         */
        handleSkip: PropTypes.func,
        /**
         * function that will trigger on click next button
         */
        handleNext: PropTypes.func,
        /**
         * boolean to disable next button
         */
        isNextDisabled: PropTypes.bool,
        /**
         * styling that would override the defaults
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        totalSteps: undefined,
        selectedStepIndex: undefined,
        handleFinish: () => {},
        handleSkip: () => {},
        handleNext: () => {},
        nextBtnValue: 'Next',
        finalBtnValue: 'Finished',
        isNextDisabled: false,
        className: '',
    };

    render() {
        const {
            className,
            totalSteps,
            selectedStepIndex,
            handleFinish,
            handleSkip,
            handleNext,
            nextBtnValue,
            finalBtnValue,
            hasNoSkip,
            isNextDisabled,
        } = this.props;
        const isFinalScreen = selectedStepIndex === totalSteps - 1;
        const hasSteps =
            selectedStepIndex >= 0 &&
            totalSteps >= 1 &&
            selectedStepIndex <= totalSteps;

        if (isFinalScreen) {
            return (
                <div
                    className={`component-Elements-StepsControlSection final-section ${className}`}
                >
                    <Button
                        class="finish-btn"
                        inputStyle="finish-input"
                        value={finalBtnValue}
                        type="purple-medium-white"
                        onClick={handleFinish}
                    />
                </div>
            );
        }

        if (hasNoSkip) {
            return (
                <div
                    className={`component-Elements-StepsControlSection no-skip ${className}`}
                >
                    <button
                        className="onboard-next"
                        onClick={handleNext}
                        disabled={isNextDisabled}
                    >
                        {nextBtnValue}
                        <NextArrow />
                    </button>
                </div>
            );
        }

        if (hasSteps) {
            return (
                <div
                    className={`component-Elements-StepsControlSection ${className}`}
                >
                    <Button
                        class="onboard-skip"
                        inputStyle="onboard-skip-input"
                        value="SKIP"
                        type="tertiary-blue-gray"
                        onClick={handleSkip}
                    />
                    <button
                        className="onboard-next"
                        onClick={handleNext}
                        disabled={isNextDisabled}
                    >
                        Next
                        <NextArrow />
                    </button>
                </div>
            );
        }

        return null;
    }
}
