import axios from 'axios';
import * as Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import Yup from 'yup';
import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import * as commonSchemas from '../components/Includes/FormSchema/JobReviewSchema';
import Abandoned from '../components/ReviewSurvey/IndividualQuestionCards/Abandoned/Abandoned';
import {
    getParameterByName,
    removeUrlParameter,
} from '../utilities/helperFunctions';
import RULES_ENGINE from '../utilities/survey/getJobReviewProgress';

import { JobReviewContext as Context } from './ContextCreator';
import {
    flattenQuestions,
    ReviewSurveyCardsRequiredQuestions,
} from './Includes/ReviewSurveyProps';

export const SUBMIT = 'SUBMIT'; // submit answer and go to next step (autoadvance);
export const CAN_SUBMIT = 'CAN_SUBMIT'; //allow to go to the next step but don't autoadvance;
export const CANT_SUBMIT = 'CANT_SUBMIT'; //can submit but don't go to next step
export const ALLOW_SUB_STEP = 'ALLOW_SUB_STEP'; // you CAN go to substep but don't autoadvance until you hit next button
export const GO_SUB_STEP = 'GO_SUB_STEP'; //ie. hit other and automatically go to substep

export default class JobReviewProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: props.loggedIn,
            /**
             *  answerPayload: survey question values to be passed to the save/complete APIs
             */
            answerPayload: {
                fgbId: Cookies.get('FGB_ID'),
                progress: null,
                jobReview: {},
            },
            currentAnswers: {},
            skippedQuestions: [],
            stepsProgressed: [],
            substepsRequested: [],
            displayQuestionKey: null,
            currentCard: {},
            part2: false,
            nextAction: CANT_SUBMIT,
            onAnswer: this.onAnswer,
            onSubmit: this.onSubmit,
            errors: null,
            onPrev: this.onPrev,
            getCurrentStep: this.getCurrentStep,
            callRulesEngine: this.callRulesEngine,
            closeSurvey: this.props.closeSurvey,
            completeSurvey: this.completeSurvey,
            resetSurvey: this.resetSurvey,
            setSurveyCookie: this.setSurveyCookie,
            removeSurveyCookie: this.removeSurveyCookie,
            abandonSurvey: this.abandonSurvey,
            userStatus: props.jobReviewStatus,
        };
    }

    static propTypes = {
        children: PropTypes.node,
        closeSurvey: PropTypes.func,
        company: PropTypes.object,
        PAGE_PROPS: PropTypes.object,
        loggedIn: PropTypes.bool,
        onWrapperClick: PropTypes.func,
    };

    static defaultProps = {
        loggedIn: false,
        company: null,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.jobReviewStatus !== this.props.jobReviewStatus) {
            const newState = Object.assign({}, this.state);

            newState.userStatus = nextProps.jobReviewStatus;
            newState.answerPayload.jobReview = nextProps.jobReviewStatus;

            this.setState(newState, () => {
                this.getUserStatus();
            });
        }
    }

    /**
     *  loadCompanyProps: will load necessary company props on surveys that need company to be pre-populated
     */
    loadCompanyProps = () => {
        const newState = Object.assign({}, this.state);

        newState.answerPayload.jobReview.company = {
            id: this.props.company.id,
            name: this.props.company.name,
            logo:
                this.props.company.isSponsor && this.props.company.logo
                    ? this.props.company.logo
                    : null,
        };

        this.setState(newState);
    };

    /**
     *  getUserStatus: initial call of User Status API to determine if there is an existing survey or not
     */
    getUserStatus = () => {
        const newState = Object.assign({}, this.state);
        const { userStatus } = this.state;

        if (userStatus) {
            newState.answerPayload.jobReview = userStatus;
        } else {
            newState.displayQuestionKey = 'Intro';
        }

        const nextStep = userStatus
            ? () => {
                  const rulesEngine = this.callRulesEngine();
                  let step = rulesEngine;

                  if (
                      this.props.surveyCookieStatus &&
                      this.props.surveyCookieStatus === 'complete' &&
                      this.props.loggedIn
                  ) {
                      step = 'OptionalEnd';
                  } else if (
                      this.props.surveyCookieStatus &&
                      rulesEngine === 'End'
                  ) {
                      step = 'End';
                  } else {
                      step = 'Abandoned';
                  }

                  this.getCurrentStep(step);
              }
            : () =>
                  this.props.surveyCookieStatus
                      ? this.getCurrentStep('Abandoned')
                      : this.getCurrentStep('Intro');

        this.setState(newState, nextStep);
    };

    /**
     *  getCurrentStep: will get and set the values of the current step in the survey
     */
    getCurrentStep = (nextStep) => {
        const formattedReviewSurveyCards = flattenQuestions(
            ReviewSurveyCardsRequiredQuestions
        );
        const newStepsProgressed = this.state.stepsProgressed;

        if (!this.state.stepsProgressed.includes(nextStep)) {
            newStepsProgressed.push(nextStep);
        }

        // determine if company can be pre-loaded into company question
        if (
            this.props.company &&
            this.props.company.id &&
            this.props.company.name &&
            !(
                this.state.answerPayload.jobReview &&
                this.state.answerPayload.jobReview.company
            )
        ) {
            if (this.state.stepsProgressed.includes('1'))
                this.loadCompanyProps();
        }

        this.setState((prevState) => {
            return {
                answerPayload: {
                    fgbId: prevState.answerPayload.fgbId,
                    progress: prevState.answerPayload.progress,
                    jobReview: prevState.answerPayload.jobReview,
                },
                nextAction: CANT_SUBMIT,
                displayQuestionKey: nextStep,
                currentCard: formattedReviewSurveyCards[nextStep],
                stepsProgressed: newStepsProgressed,
            };
        });
    };

    /**
     *  onAnswer: called on change of question values, will call onSubmit if nextAction is set to SUBMIT or GO_SUB_STEP
     */
    onAnswer = (
        questionName,
        payload,
        nextAction,
        isPartTwo,
        nextStepNumber,
        subStep
    ) => {
        let newState = Object.assign({}, this.state);

        if (isPartTwo) {
            newState.answerPayload.jobReview[questionName] = Object.assign(
                {},
                this.state.answerPayload.jobReview[questionName],
                payload
            );
            newState.currentAnswers[questionName] = payload;
        } else {
            if (payload === [null]) payload = null;
            newState.answerPayload.jobReview[questionName] = payload;
            newState.currentAnswers[questionName] = payload;
        }

        newState.errors = null;
        newState.holdSubmit = nextAction === ALLOW_SUB_STEP;
        newState.answerPayload.progress = questionName;
        newState.nextAction = nextAction;

        this.setState(newState, () =>
            this.state.nextAction === SUBMIT ||
            this.state.nextAction === GO_SUB_STEP
                ? this.onSubmit(nextStepNumber, subStep)
                : null
        );
    };

    /**
     *  onSubmit: called for each of the question on next button, or auto-advancing questions. Will check for 2 part answers
     *            and callSaveAPI when answer is ready for submission
     */
    onSubmit = async (
        nextStepNumber,
        subStep = null,
        cardType = 'company',
        skippable = false,
        demographicNumber = null
    ) => {
        const number = demographicNumber || this.state.displayQuestionKey;
        const nextStepDecision =
            this.state.nextAction === ALLOW_SUB_STEP ||
            this.state.nextAction === GO_SUB_STEP ||
            (this.state.answerPayload.progress === 'part2' &&
                this.state.currentAnswers.part2.part2 === false)
                ? subStep
                : nextStepNumber;

        if (
            this.state.nextAction === GO_SUB_STEP ||
            this.state.nextAction === ALLOW_SUB_STEP
        ) {
            this.getCurrentStep(nextStepDecision);
            EmitMetric({
                misc_event_type: `job-review-question-completed-${cardType}-${number}`,
                misc_event_count: 1,
            });
        } else {
            const isValid = await this.validateAnswers();
            if (isValid) {
                EmitMetric({
                    misc_event_type: `job-review-question-completed-${cardType}-${number}`,
                    misc_event_count: 1,
                });
                this.callSaveAPI(this.state.answerPayload, nextStepDecision);

                if (
                    !this.state.isLoggedIn &&
                    nextStepDecision === 'OptionalEnd'
                ) {
                    // IE 11 check, thought babel would cover this
                    let event =
                        typeof Event === 'function' ? new Event('click') : null;

                    this.props.onWrapperClick(
                        event,
                        '',
                        { login_trigger: 'survey-completed' },
                        null,
                        null,
                        {
                            name: 'ReviewSurveyStatus',
                            value: 'complete',
                        }
                    );
                }
            }
        }
    };

    /**
     *  validateAnswers: validation for each of the answers against the schema
     */
    validateAnswers = async () => {
        try {
            await Yup.object()
                .shape(
                    commonSchemas[`${this.state.answerPayload.progress}Schema`]
                )
                .validate(
                    this.state.answerPayload.jobReview[
                        this.state.answerPayload.progress
                    ]
                );
            return true;
        } catch (e) {
            console.log('error', e);
            this.setState({
                errors: e.errors.pop(),
            });
            return false;
        }
    };

    /**
     *  callRulesEngine: called to return the next unanswered question in the survey if abandoned
     */
    callRulesEngine = () => {
        const nextStep = RULES_ENGINE(
            this.state.answerPayload.jobReview,
            this.state.skippedQuestions,
            this.state.stepsProgressed,
            this.state.substepsRequested
        );
        this.setState({
            nextAction: CANT_SUBMIT,
        });
        return nextStep;
    };

    /**
     *  callSaveAPI: called to save each of the survey question answers
     */
    callSaveAPI = async (payload, nextStepDecision) => {
        try {
            axios.post(`/api/job-review/save`, payload);
            this.getCurrentStep(nextStepDecision);
        } catch (e) {
            return false;
        }
    };

    /**
     *  onPrev: called when previous button in survey is triggered
     */
    onPrev = () => {
        const allSteps = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            'End',
            '14',
            '15',
            '16',
            '17',
            '18',
            'OptionalEnd',
        ];
        const displayKeyState = this.state.displayQuestionKey;
        const DISPLAYKEY = displayKeyState.match(/\d+a/)
            ? displayKeyState.substring(0, displayKeyState.length - 1)
            : displayKeyState;
        const allStepsIndex = allSteps.indexOf(DISPLAYKEY);

        let prevStep = allSteps[allStepsIndex - 1];

        if (displayKeyState === '14') {
            // skip over 'End' if you have proceeded to the optional questions
            prevStep = '13';
        } else if (allSteps.indexOf(displayKeyState) === -1) {
            // otherwise, go back one step in the array
            prevStep = allSteps[allStepsIndex];
        }

        this.getCurrentStep(prevStep);
    };

    /**
     *  completeSurvey: called when survey is completed, will only work if user is logged in
     */
    completeSurvey = async () => {
        try {
            const payload = {
                fgbId: this.state.answerPayload.fgbId,
                progress: 'complete',
                jobReview: this.state.answerPayload.jobReview,
            };
            await axios.post(`/api/job-review/submit`, payload);
            EmitMetric({
                misc_event_type: 'job-review-completed',
                misc_event_count: 1,
            });
            this.clearSurveyParams();
            location.reload(true);
            return true;
        } catch (error) {
            this.setState({ errors: error });
            return false;
        }
    };

    /**
     *  resetSurvey: resets all of the values of the survey. Called once survey is completed
     */
    resetSurvey = () => {
        const formattedReviewSurveyCards = flattenQuestions(
            ReviewSurveyCardsRequiredQuestions
        );
        const newState = Object.assign({}, this.state);

        newState.answerPayload = {
            fgbId: Cookies.get('FGB_ID'),
            progress: null,
            jobReview: {},
        };
        newState.currentAnswers = {};
        newState.skippedQuestions = [];
        newState.stepsProgressed = [];
        newState.substepsRequested = [];
        newState.displayQuestionKey = 'Intro';
        newState.currentCard = formattedReviewSurveyCards['Intro'];
        newState.errors = null;

        this.setState(newState);
    };

    /**
     *  clearSurveyParams: remove 'showSurvey' parameter if survey is completed or abandoned
     */
    clearSurveyParams = () => {
        if (getParameterByName('showSurvey')) {
            window.history.replaceState(
                {},
                document.title,
                removeUrlParameter(window.location.href, 'showSurvey')
            );
        }
    };

    /**
     *  abandonSurvey: will call API to wipe existing survey, reset survey state, and remove survey cookie
     */
    abandonSurvey = async () => {
        try {
            await axios.post(`/api/job-review/abandon`);
            EmitMetric({
                misc_event_type: 'job-review-abandoned',
                misc_event_count: 1,
            });
            this.resetSurvey();
            this.removeSurveyCookie();
            this.clearSurveyParams();
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    /**
     *  removeSurveyCookie: remove cookie to display survey overlay
     */
    removeSurveyCookie = (cookieName = 'ReviewSurveyStatus') => {
        Cookies.remove(cookieName);
    };

    /**
     *  setSurveyCookie: set cookie to display survey overlay
     *  @val string 'inProgress' or 'complete'
     *      - 'inProgress'  Will show survey with the abandoned card first if page is refreshed, then the current step
     *                      based on the rules engine.
     *      - 'complete'    Will show finish card once user had completed survey and authenticated
     */
    setSurveyCookie = (val = 'inProgress') => {
        Cookies.set('ReviewSurveyStatus', val, { expires: 1095 });
    };

    componentDidMount() {
        this.getUserStatus();
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const JobReviewConsumer = Context.Consumer;
