/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_CDN_URL } from '../../../utilities/imageCDN';
import { flatten } from '../../../utilities/helperFunctions';
import './ResponsivePicture.scss';

const TRANSFORM_PREFIXES = {
    quality: 'q',
    aspect_ratio: 'ar',
    gravity: 'g',
    crop: 'c',
    radius: 'r',
    flags: 'fl',
    background: 'b',
    format: 'f',
    width: 'w',
};

const TRANSFORMS_SHAPE = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
        // do not put width and height in transforms! We're going to take that from the breakpoint.width and breakpoint.height properties
        // Documentation on transform properties is at https://cloudinary.com/documentation/image_transformation_reference
        crop: PropTypes.oneOf([
            'scale',
            'fit',
            'limit',
            'mfit',
            'fill',
            'lfill',
            'pad',
            'lpad',
            'mpad',
        ]),
        aspect_ratio: PropTypes.string,
        quality: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        gravity: PropTypes.string,
        radius: PropTypes.string,
        flags: PropTypes.arrayOf[PropTypes.string],
        background: PropTypes.string,
    }),
]);

export const BREAKPOINT_SHAPE = PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number,
    transforms: TRANSFORMS_SHAPE,
});

const BREAKPOINT_STRINGS = {
    desktop: 'DESKTOP',
    tablet: 'TABLET',
    smallTablet: 'SMALL_TABLET',
    mobile: 'MOBILE',
};

const BREAKPOINT_CONSTANTS = {
    DESKTOP: {
        MINWIDTH: 992,
        MEDIA: '(min-width: 992px)',
        PRIORITY: 0,
    },
    TABLET: {
        MINWIDTH: 768,
        MEDIA: '(min-width: 768px)',
        PRIORITY: 1,
    },
    SMALL_TABLET: {
        MINWIDTH: 575,
        MEDIA: '(min-width: 575px)',
        PRIORITY: 2,
    },
    MOBILE: {
        MINWIDTH: 0,
        MEDIA: '(min-width: 0)',
        PRIORITY: 3,
    },
};

const JPGMIMETYPES = [['webp', 'image/webp']];

const MIMETYPEMAP = {
    jpg: JPGMIMETYPES,
    heic: JPGMIMETYPES,
    heif: JPGMIMETYPES,
    png: [['webp', 'image/webp']],
    gif: [['webp', 'image/webp']],
};

export default class ResponsivePicture extends PureComponent {
    constructor(props) {
        super(props);
        this.baseCls = 'component-Elements-Picture';

        const cloudinaryInfo = this.getCloudinaryInfo(props);

        this.state = {
            cloudinaryID: cloudinaryInfo.id,
            format: cloudinaryInfo.format,
            isAnimatedGif: cloudinaryInfo.isAnimatedGif,
            isLazyLoad: props.lazyLoad,
            isLoading: props.useShine || props.lazyLoad,
            disableHeight: props?.disableHeight,
            prevBreakpoint: null,
            containerStyle: {},
            inlineStyle: {},
            width: 0,
            height: 0,
            isSmallImage: false,
            itemProp: props?.itemProp,
        };

        this.containerRef = React.createRef();
        this.imgRef = React.createRef();
        this.vidRef = React.createRef();
        this.initTime = '';
    }

    componentDidMount = () => {
        if (this.props.forceImageSize) this.setImageSize();
        window.addEventListener('resize', this.setImageSize);
        if (
            (this.imgRef.current && this.imgRef.current.complete) ||
            (this.vidRef.current && this.vidRef.current.readyState === 4)
        ) {
            // Because of SSR, there's a chance the browser will finish downloading the picture before the onLoad event is bound.
            // This should catch that condition.
            this.handleOnLoad();
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setImageSize);
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.lazyLoad && !this.props.lazyLoad) {
            // if the lazyLoad prop changes externally, load the picture now!
            this.loadPicture();
        }

        if (prevProps.cloudinaryID !== this.props.cloudinaryID) {
            const cloudinaryInfo = this.getCloudinaryInfo();

            // TODO: this right here is garbage fire level code, causing unnecessary rerenders. FIX.
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                cloudinaryID: cloudinaryInfo.id,
                format: cloudinaryInfo.format,
                isAnimatedGif: cloudinaryInfo.isAnimatedGif,
            });
        }
    };

    getCloudinaryInfo = (props = false) => {
        const cloudinaryIdArr =
            (props && props.cloudinaryID && props.cloudinaryID.split('.')) ||
            (this.props &&
                this.props.cloudinaryID &&
                this.props.cloudinaryID.split('.')) ||
            [];
        const output = {
            id: cloudinaryIdArr[0],
            format: cloudinaryIdArr.length > 1 ? cloudinaryIdArr.pop() : false,
            isAnimatedGif:
                cloudinaryIdArr.length > 1 && cloudinaryIdArr.pop() === 'anim',
        };
        output.format = ['jpg', 'png'].includes(output.format)
            ? 'webp'
            : output.format;
        output.filename = output.format
            ? `${output.id}.${output.format}`
            : output.id;

        return output;
    };

    buildSources = (breakpoint, i) => {
        switch (this.state.format) {
            case 'gif': // NOTE: This is a NON-animated gif. Animated gifs are detected at time of upload and displayed (as transformed to video by Cloudinary) by this.buildVideoSources
            case 'png':
            case 'jpg':
            case 'heif':
            case 'heic': {
                const mimeTypes = MIMETYPEMAP[this.state.format];
                // This returns an array of source elements with mimetypes
                return mimeTypes.map((mime, h) => {
                    const sourceProps = {
                        media:
                            BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]]
                                .MEDIA,
                        priority: `${
                            BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]]
                                .PRIORITY
                        }.${h}`,
                        srcSet: this.buildSrcSet(
                            this.props.breakpoints[breakpoint],
                            mime[0]
                        ),
                        key: `${this.state.cloudinaryID}-${this.initTime}-${i}-${h}`,
                        type: mime[1],
                    };

                    return <source {...sourceProps} />;
                });
            }
            default: {
                const sourceProps = {
                    media:
                        BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]]
                            .MEDIA,
                    key: `${this.state.cloudinaryID}-${this.initTime}-${i}`,
                    priority:
                        BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]]
                            .PRIORITY,
                    srcSet: this.buildSrcSet(
                        this.props.breakpoints[breakpoint]
                    ),
                };

                return <source {...sourceProps} />;
            }
        }
    };

    buildVideoSources = (breakpoint, i) => {
        const mimeTypes = [
            ['webm', 'video/webm'],
            ['mp4', 'video/mp4'],
        ];

        const transforms = this.buildTransformsString(
            this.props.breakpoints[breakpoint]
        );

        return mimeTypes.map((mime, h) => {
            let id =
                (typeof this.state.cloudinaryID === 'string' &&
                    this.state.cloudinaryID.replace(/\/?v[0-9]*\//, '/')) ||
                '';
            id = id[0] !== '/' ? `/${id}` : id;
            const sourceProps = {
                media:
                    BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]].MEDIA,
                priority: `${
                    BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS[breakpoint]]
                        .PRIORITY
                }.${h}`,
                src: `${IMAGE_CDN_URL}/${
                    transforms ? `,${transforms}` : ''
                }/v1${id}.${mime[0]}`,
                key: `${this.state.cloudinaryID}-${this.initTime}-${i}-${h}`,
                type: mime[1],
            };
            return <source {...sourceProps} />;
        });
    };

    buildSrcSet = (breakpoint, extension = false) => {
        const transforms = this.buildTransformsString(breakpoint);
        const output = [];
        const maxDensity = this.state.isSmallImage ? 1 : this.props.maxDensity;
        for (let i = 1; i <= maxDensity; i += 1) {
            const fileFormat =
                extension ||
                (breakpoint.transforms && breakpoint.transforms.format);
            const dec =
                (!this.state.isSmallImage &&
                    i <= 2 &&
                    !this.isSpecifiedNonJpg(fileFormat) &&
                    ((i === 1 && 5) || 3)) ||
                0;
            const src = this.buildSrc(transforms, `${i}.${dec}`, extension);
            output.push(`${src} ${i}x`);
        }

        return output.join(', ');
    };

    buildSrc = (transforms = null, pixelDensity = '1.0', ext = false) => {
        // the ID is marked as a required string, but even if that's ignored for some reason we still don't want it to break the page

        let id =
            (typeof this.state.cloudinaryID === 'string' &&
                this.state.cloudinaryID.replace(/\/?v[0-9]*\//, '/')) ||
            '';
        id = id[0] !== '/' ? `/${id}` : id;
        const extension = ext || this.state.format;
        return `${IMAGE_CDN_URL}/dpr_${pixelDensity}${
            transforms ? `,${transforms}` : ''
        }/v1${id}${(extension && `.${extension}`) || ''}`;
    };

    buildFallbackSrc = () => {
        const {
            breakpoints: { desktop, tablet, smallTablet, mobile },
        } = this.props;

        const transforms =
            (desktop && this.buildTransformsString(desktop)) ||
            (tablet && this.buildTransformsString(tablet)) ||
            (smallTablet && this.buildTransformsString(smallTablet)) ||
            (mobile && this.buildTransformsString(mobile)) ||
            'w_500';
        return this.buildSrc(
            transforms,
            (!this.isSpecifiedNonJpg(transforms) && 1.5) || 1.0
        );
    };

    isSpecifiedNonJpg = (fileFormat = false) =>
        (this.state.format && this.state.format !== 'jpg') ||
        (fileFormat && fileFormat !== 'jpg');

    buildTransformsString = ({ transforms = null, width, height }) => {
        if (typeof transforms === 'string') {
            let outputTransforms = transforms;
            outputTransforms =
                width && !this.state.isSmallImage
                    ? (outputTransforms && `${outputTransforms},w_${width}`) ||
                      `w_${width}`
                    : outputTransforms;
            outputTransforms =
                height && !this.state.isSmallImage
                    ? (outputTransforms && `${outputTransforms},h_${height}`) ||
                      `h_${height}`
                    : outputTransforms;
            return outputTransforms;
        }

        let args = [];

        if (transforms) {
            const keys = Object.keys(transforms);
            args = keys.map((value) => {
                return `${TRANSFORM_PREFIXES[value]}_${transforms[value]}`;
            });
            if (keys.indexOf('quality') === -1) {
                args.push('q_auto');
            }
        } else {
            args.push('q_auto');
        }

        if (!this.state.isSmallImage) {
            if (width) args.push(`w_${width}`);
            if (height) args.push(`h_${height}`);
        }

        return args.join(',');
    };

    // To ensure we get the breakpoint we want, we need to order the sources correctly, desktop first, descending
    prioritizeSources = (a, b) => a.props.priority - b.props.priority;

    getCurrentBreakpoint = () => {
        const docWidth = document.documentElement.clientWidth;
        switch (true) {
            case docWidth >=
                BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS.desktop].MINWIDTH:
                return 'desktop';
            case docWidth >=
                BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS.tablet].MINWIDTH:
                return 'tablet';
            case docWidth >=
                BREAKPOINT_CONSTANTS[BREAKPOINT_STRINGS.smallTablet].MINWIDTH:
                return 'smallTablet';
            default:
                return 'mobile';
        }
    };

    setImageSize = (e) => {
        const container = this.containerRef.current;
        const img = this.imgRef.current;
        const video = this.vidRef.current;
        const eventType = (e && e.type) || '';

        const currentBreakpoint = this.getCurrentBreakpoint();
        if (
            (this.state.prevBreakpoint === currentBreakpoint &&
                eventType !== 'load') ||
            (img === null && video === null)
        ) {
            // no changes
            return;
        }

        if (
            (eventType === 'resize' && img && !img.complete) ||
            (video && video.readyState !== 4)
        ) {
            this.setState({
                isLoading: this.props.useShine,
                prevBreakpoint: currentBreakpoint,
                containerStyle: {},
                inlineStyle: {},
                width: 0,
                height: 0,
            });
            return;
        }

        const width = (img && img.naturalWidth) || (video && video.videoWidth);
        const height =
            (img && img.naturalHeight) || (video && video.videoHeight);

        const maxHeight = this.props.maxHeights[currentBreakpoint];
        if (
            this.props.forceImageSize ||
            width >= container.clientWidth ||
            height >= maxHeight
        ) {
            const { isAnimatedGif } = this.state;
            if (isAnimatedGif) {
                this.setState({
                    prevBreakpoint: currentBreakpoint,
                    containerStyle: {},
                    inlineStyle: isAnimatedGif
                        ? { width: `${container.clientWidth}px` }
                        : {},
                });
                return;
            }
            let scaleWidth = container.clientWidth / width;
            if (scaleWidth === 0) scaleWidth = 1;
            const scaleHeight = maxHeight / height;
            const scale = Math.min(scaleWidth, scaleHeight);
            const imgWidth = width * scale;
            const imgHeight = height * scale;
            this.setState({
                prevBreakpoint: currentBreakpoint,
                containerStyle: { height: `${imgHeight}px` },
                inlineStyle: { width: `${imgWidth}px`, height: imgHeight },
                width: imgWidth,
                height: imgHeight,
            });
            return;
        }
        this.setState({
            isSmallImage: true,
            inlineStyle: {
                width: `${width}px`,
            },
            width,
            height,
        });
    };

    handleOnLoad = (e) => {
        if (this.initTime === '') {
            this.initTime = Date.now();
        }
        if (this.props.handleOnLoad) {
            this.props.handleOnLoad();
        }
        this.setImageSize(e);
        this.setState({ isLoading: false });
    };

    render = () => {
        const {
            baseCls,
            props: { breakpoints, alt, cloudinaryID, className },
            buildSources,
            buildVideoSources,
            prioritizeSources,
            imgRef,
            vidRef,
            buildFallbackSrc,
            handleOnLoad,
            containerRef,
            state: {
                containerStyle,
                inlineStyle,
                isLoading,
                isSmallImage,
                width,
                height,
                disableHeight,
                itemProp,
            },
        } = this;

        const breakpointKeys = Object.keys(breakpoints);
        const trueWidth = Number.isNaN(width) ? 0 : width;
        const trueHeight = Number.isNaN(height) ? 0 : height;
        if (!cloudinaryID) {
            return null;
        }
        return (
            <div
                className={`${baseCls} ${className} ${
                    isLoading ? `${baseCls}--loading` : ''
                } `}
                ref={containerRef}
                style={containerStyle}
            >
                {(this.props.cloudinaryID.indexOf('anim') !== -1 && (
                    <video
                        className={`${baseCls}__picture ${baseCls}__picture--gif-video ${
                            isSmallImage ? `${baseCls}__picture--small` : ''
                        }`}
                        onPlay={handleOnLoad}
                        autoPlay
                        fetchpriority={this.state.isLazyLoad ? 'low' : 'high'}
                        loading={this.state.isLazyLoad ? 'lazy' : 'eager'}
                        loop
                        muted
                        playsInline
                        ref={vidRef}
                        style={inlineStyle}
                    >
                        {flatten(breakpointKeys.map(buildVideoSources)).sort(
                            prioritizeSources
                        )}
                    </video>
                )) || (
                    <picture className={`${baseCls}__picture`}>
                        {flatten(breakpointKeys.map(buildSources)).sort(
                            prioritizeSources
                        )}
                        <img
                            className={`${baseCls}__img`}
                            src={buildFallbackSrc()}
                            onLoad={handleOnLoad}
                            fetchpriority={
                                this.state.isLazyLoad ? 'low' : 'high'
                            }
                            loading={this.state.isLazyLoad ? 'lazy' : 'eager'}
                            ref={imgRef}
                            alt={alt}
                            style={inlineStyle}
                            width={trueWidth}
                            height={!disableHeight ? trueHeight : 'auto'}
                            {...(itemProp && {
                                itemProp: 'image',
                            })}
                        />
                    </picture>
                )}
            </div>
        );
    };
}

ResponsivePicture.propTypes = {
    /**
     * Image Source (Cloudinary Id)
     * */
    cloudinaryID: PropTypes.string.isRequired,
    /**
     * Image Alt
     * */
    alt: PropTypes.string,
    /**
     *  Specify sizes and transforms for each breakpoint
     */
    breakpoints: PropTypes.shape({
        mobile: BREAKPOINT_SHAPE,
        smallTablet: BREAKPOINT_SHAPE,
        tablet: BREAKPOINT_SHAPE,
        desktop: BREAKPOINT_SHAPE,
    }),
    /*
     *   Max height allowed. Taller images will be shrunk and centered.
     */
    maxHeights: PropTypes.shape({
        desktop: PropTypes.number.isRequired,
        tablet: PropTypes.number.isRequired,
        smallTablet: PropTypes.number.isRequired,
        mobile: PropTypes.number.isRequired,
    }),
    /**
     * If our exact sizing needs are known (such as when an image is hard coded) we don't need to do all the mockImage stuff.
     * Setting this to true will bypass it.
     */
    forceImageSize: PropTypes.bool,

    className: PropTypes.string,
    /**
     *
     */
    useShine: PropTypes.bool,
    /**
     * Set to true to defer loading this image. It will load when it appears in the viewport, unless waitForLoadProp is set to true.
     */
    lazyLoad: PropTypes.bool,
    /**
     * Set to true to trigger load image load outside this component.
     * This will prevent ResponsivePicture from using its own logic to detect when to load when it appears in the viewport.
     */
    waitForLoadProp: PropTypes.bool,
    /**
     * Max Density controls how hi-res we're going to allow our source elements to get.
     * Generally 2x is fine but in some cases we may choose to go to 3+. This prop is here to allow for that override.
     */
    maxDensity: PropTypes.number,
    handleOnLoad: PropTypes.func,
    disableHeight: PropTypes.bool,
    itemProp: PropTypes.string,
};

ResponsivePicture.defaultProps = {
    alt: '',
    breakpoints: {
        mobile: {
            width: 444,
        },
        smallTablet: {
            width: 340,
        },
        tablet: {
            width: 500,
        },
    },
    className: '',
    forceImageSize: false,
    maxHeights: {
        desktop: 750,
        tablet: 750,
        smallTablet: 750,
        mobile: 750,
    },
    lazyLoad: false,
    useShine: false,
    waitForLoadProp: false,
    maxDensity: 2,
    handleOnLoad: null,
    disableHeight: null,
    itemProp: undefined,
};
