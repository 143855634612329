/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import {
    CREATE_LAYOUT,
    FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
    FACEBOOK_REAUTHORIZATION_LAYOUT,
    LOGIN_LAYOUT,
    POPUP_MODE,
    RESET_PASSWORD_CONFIRMATION_LAYOUT,
    RESET_PASSWORD_LAYOUT,
} from '../LoginLayoutComponent/Layouts/LogInLayout/constants';
import LoginLayoutComponent from '../LoginLayoutComponent/LoginLayoutComponent';
import LoginImageContainer from './LoginImageContainer/LoginImageContainer';
import './LoginWall.scss';

const colorToRGBA = {
    white: 'rgb(255,255,255, 0.7)',
    black: 'rgba(0, 0, 0, 0.7)',
};

const propTypes = {
    /**
     * Whether or not the component will be displayed in
     */
    layoutType: PropTypes.oneOf([
        CREATE_LAYOUT,
        LOGIN_LAYOUT,
        RESET_PASSWORD_LAYOUT,
        RESET_PASSWORD_CONFIRMATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
    ]),
    /**
     * auth0 config
     */
    auth0: PropTypes.shape({
        domain: PropTypes.string,
        clientId: PropTypes.string,
        db: PropTypes.string,
    }).isRequired,
    /**
     * sign up success redirect uri
     */
    loginRedirectPath: PropTypes.string.isRequired,
    /**
     * url path header
     */
    redirectUri: PropTypes.string.isRequired,
    /**
     *  login wall data from backend
     */
    loginWallData: PropTypes.object.isRequired,
    /**
     *  function to close this loginwall
     */
    closeLoginWall: PropTypes.func,
    /**
     *  triggers email form
     */
    showLeadinPopup: PropTypes.bool,
    placementName: PropTypes.string,
    isOpened: PropTypes.bool,
};

const defaultProps = {
    isOpened: false,
    placementName: null,
    showLeadinPopup: false,
    layoutType: LOGIN_LAYOUT,
    closeLoginWall: () => {},
};

export default class LoginWall extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            showLoginWidget: false,
        };
    }

    openLoginWidget = () => {
        this.setState({ showLoginWidget: true });
        EmitMetric({
            misc_event_type: 'signup-wall-button',
            misc_event_count: 1,
            test_flag: this.props.placementName,
        });
    };

    renderLoginWall = () => {
        const {
            placementName,
            image,
            overlayColor,
            title,
            description,
            buttonText,
            allowClosing,
        } = this.props.loginWallData;
        const {
            redirectUri,
            loginRedirectPath,
            auth0,
            layoutType,
        } = this.props;

        const displayAtBoth = 'd-block d-md-block';
        const hideAtMobile = 'd-none d-md-block';
        const imageContainerCss = this.state.showLoginWidget
            ? hideAtMobile
            : displayAtBoth;
        const loginWidgetCss = this.state.showLoginWidget
            ? displayAtBoth
            : hideAtMobile;

        const { showLeadinPopup } = this.props;

        return (
            <div
                className="component-Login-LoginWall overlay"
                style={{ backgroundColor: `${colorToRGBA[overlayColor]}` }}
            >
                <div
                    className={`popup ${showLeadinPopup ? 'leadInPopup' : ''}`}
                >
                    {!showLeadinPopup && (
                        <>
                            <section className={`left ${imageContainerCss}`}>
                                <LoginImageContainer
                                    openLoginWidget={this.openLoginWidget}
                                    closeLoginWall={this.props.closeLoginWall}
                                    title={title}
                                    description={description}
                                    buttonText={buttonText}
                                    image={image}
                                    allowClosing={
                                        allowClosing === 1 || showLeadinPopup
                                    }
                                    placementName={placementName}
                                    cmsPreview={false}
                                />
                            </section>

                            <section className={`right ${loginWidgetCss}`}>
                                <LoginLayoutComponent
                                    auth0={auth0}
                                    hidePopup={this.props.closeLoginWall}
                                    displayMode={POPUP_MODE}
                                    layoutType={layoutType}
                                    loginRedirectPath={loginRedirectPath}
                                    redirectUri={redirectUri}
                                    allowClosing={allowClosing === 1}
                                    placementName={placementName}
                                    loginMetrics={{
                                        login_trigger: 'signup-wall',
                                        test_flag: placementName,
                                    }}
                                />
                            </section>
                        </>
                    )}
                    {showLeadinPopup && (
                        <div className="lead-in-login-container">
                            <LoginLayoutComponent
                                showLeadinPopup={showLeadinPopup}
                                auth0={auth0}
                                hidePopup={this.props.closeLoginWall}
                                displayMode={POPUP_MODE}
                                layoutType={layoutType}
                                loginRedirectPath={loginRedirectPath}
                                redirectUri={redirectUri}
                                allowClosing={
                                    allowClosing === 1 || showLeadinPopup
                                }
                                placementName={placementName}
                                loginMetrics={{
                                    login_trigger: 'signup-wall',
                                    test_flag: placementName,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        return this.props.isOpened ? this.renderLoginWall() : null;
    }
}

LoginWall.propTypes = propTypes;
LoginWall.defaultProps = defaultProps;
