export const uniqueAnswersTest = (ans) => {
    if (!ans) {
        return true;
    }
    let ansSet = new Set(ans);
    return [...ansSet].length === ans.length;
};

export const meetsMinWordCount = (minWordCount) => {
    return async (value) => {
        if (!value) {
            return false;
        }
        return value.trim().split(/\s+/).length >= minWordCount;
    };
};
