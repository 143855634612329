/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import { PageContext } from '../../../../contexts/ContextCreator';
import { EMPTY_STATE_IMAGES } from '../../../../pages/MyNetwork/sharedConstants';
import UserImage from '../../../Content/User/UserDisplay/UserImage/UserImage';
import { buildImagePath } from '../../../JobComponents/Company/Image/logo';
import ModalAbstraction from '../ModalAbstraction/ModalAbstraction';
import { ellipsify } from '../../../../utilities/helperFunctions';
import Button from '../../../Input/Button/Button';

import './MobileMenuModal.scss';

const MMM_JOINED_GROUPS_URL = '/my-network/my-groups';
const MMM_RECOMMENDED_GROUPS_URL = '/my-network/recommended-groups';

export default class MobileMenuModal extends React.PureComponent {
    renderProfileSection = () => {
        const {
            pageProps: {
                session: { logo, username, userCommunityData },
            },
        } = this.context;

        const image = logo ? { src: logo } : undefined;
        const isVip = (userCommunityData && userCommunityData.isVip) || false;
        return (
            <div className="profile-section">
                <a href="/users/profile">
                    <div className="user-image-container">
                        <UserImage image={image} iconSize={84} isVip={isVip} />
                    </div>
                    <h1>{ellipsify(username, 40)}</h1>
                </a>
                <a href="/users/contributions/post">
                    <Button
                        type="solid-wired-blue"
                        value="My items"
                        inputStyle="my-items-btn"
                    />
                </a>
            </div>
        );
    };

    triggerModalByType = (type) => () => {
        const { modalTrigger } = this.context;

        // scroll to Unified Post Box
        if (!type) {
            modalTrigger(null);
            if (!window || !document) return;
            const upb = document.getElementById('unified-post-box');
            if (!upb) return;

            const editor = document.querySelector(
                '.component-UnifiedPostBox-PostBoxCTA'
            );

            if (editor) {
                editor.click();
            }

            const scrollTop = upb.offsetTop;
            window.scrollTo({ top: scrollTop - 65, behavior: 'smooth' });
        }
        return modalTrigger(type);
    };

    renderMenuBody = () => {
        const { location } = this.props;
        const {
            featureFlags: { unifiedPostBox = false },
            groups: { data = {} } = {},
        } = this.context;

        const groupUrl =
            data?.memberGroups?.length || data?.adminGroups?.length
                ? MMM_JOINED_GROUPS_URL
                : MMM_RECOMMENDED_GROUPS_URL;

        let components = [
            {
                title: 'Your Groups',
                type: 'groups',
                img: EMPTY_STATE_IMAGES.JOINED_GROUPS,
                size: 'half',
                url: groupUrl,
            },
            {
                title: 'Companies',
                type: 'companies',
                img: '/v1555452000/SVGs/companies-small.svg',
                size: 'half',
                modalType: 'CompaniesModal',
            },
            {
                title: 'Job Alerts',
                type: 'jobAlerts',
                img: '/v1555452000/SVGs/job-alerts-small.svg',
                size: 'half',
                modalType: 'FollowedJobs',
            },
            {
                title: 'Saved Jobs',
                type: 'savedJobs',
                size: 'half',
                img: '/v1555452014/SVGs/saved_jobs_briefcase.svg',
                url: '/jobs/saved',
            },
        ];

        if (location === 'feed' && !this.props.currentTopic) {
            components = [
                {
                    title: `Start a post`,
                    type: 'startPost',
                    size: 'full',
                    img: '/v1555536356/SVGs/Create_Girl.svg',
                    modalType: unifiedPostBox ? null : 'CreateContentPopup',
                },
                ...components,
            ];
        }
        let counter = 0;

        return (
            <div className="menu-display">
                {components.map((component) => {
                    let direction;
                    if (component.size === 'full') {
                        counter = 0;
                        direction = 'flex-start';
                    } else {
                        direction =
                            counter % 2 === 0 ? 'flex-start' : 'flex-end';
                        counter += 1;
                    }
                    const imagePath = buildImagePath(component.img, 'default', {
                        default: '', // injectedTransforms
                    });

                    switch (component.type) {
                        case 'savedJobs':
                        case 'groups':
                            return (
                                <div
                                    key={component.type}
                                    className={`dynamic-box ${component.size} ${direction}`}
                                >
                                    <a
                                        href={component.url}
                                        className={`menu-item ${component.type}`}
                                        key={component.title}
                                        title={component.title}
                                    >
                                        <img
                                            alt={`${component.type} img`}
                                            src={imagePath}
                                        />
                                        <div className="menu-item-title">
                                            {component.title}
                                        </div>
                                    </a>
                                </div>
                            );

                        default:
                            return (
                                <div
                                    key={component.type}
                                    className={`dynamic-box ${component.size} ${component.type} ${direction}`}
                                >
                                    <div
                                        className="menu-item"
                                        key={component.title}
                                        onClick={this.triggerModalByType(
                                            component.modalType
                                        )}
                                        onKeyPress={(e) => {
                                            if (e.key !== 'Enter') return;

                                            return this.triggerModalByType(
                                                component.modalType
                                            )(e);
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <img
                                            alt={`${component.type} img`}
                                            src={imagePath}
                                        />
                                        <div className="menu-item-title">
                                            {component.title}
                                        </div>
                                    </div>
                                </div>
                            );
                    }
                })}
            </div>
        );
    };

    render() {
        const { showModal, onClose } = this.props;
        return (
            <div className="component-CallToAction-LeftRailCTA-MobileMenuModal">
                <ModalAbstraction
                    showModal={showModal}
                    onClose={onClose}
                    allowScroll={false}
                    showButton={false}
                >
                    {this.renderProfileSection()}
                    {this.renderMenuBody()}
                </ModalAbstraction>
            </div>
        );
    }
}

MobileMenuModal.defaultProps = {
    location: 'feed',
    currentTopic: undefined,
};

MobileMenuModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    location: PropTypes.string,
    currentTopic: PropTypes.shape({
        id: PropTypes.number,
        layout: PropTypes.string,
        qaDataAttr: PropTypes.string,
        sponsored: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
    }),
};

MobileMenuModal.contextType = PageContext;
