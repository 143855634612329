import { IMAGE_CDN_URL } from '../../utilities/imageCDN';

export const Colors = {
    purpleDark: '#a234b2',
    purpleRegular: '#eac9ef',
    purpleLight: '#f5ddf9',
    lilac: '#B394F2',
    darkBlueGray: '#2A4473',
    lightLilac: '#F2ECFE',

    //Blacks
    black: '#000000',
    blackRegular: '#353535',
    blackLight: '#5F5D5D',

    //Gray
    grayDark: '#999999',
    grayRegular: '#e0e0e0',
    grayLight: '#f2f2f2',
    white: '#ffffff',
};

export const ChartColors = [
    Colors.lilac,
    Colors.darkBlueGray,
    Colors.lightLilac,
];

export const imgSrcPrefix = IMAGE_CDN_URL;

export const messageTypes = Object.freeze({
    COMMUNITY: 'community-user-message',
    WEBINAR: 'webinar',
    VCF: 'vcf-booth',
    GROUP: 'group-chat-room',
    EMPLOYER: 'recruiter-chat-room',
});

export const adTypes = Object.freeze({
    LEAVE_REVIEW: 'leave-review',
    VCF: 'vcf',
    COMPANY_REVIEWS: 'company-reviews',
    SUGGESTED_JOBS: 'suggested-jobs',
    PROFILE_CTA: 'profile-cta',
});

// FRONT END Api Keys
export const algolia = {
    places: {
        appId: 'plATYMP04ZZK',
        apiKey: '241c008d75411cc4881763466b7e27fe',
    },
};

export const COMMUNITY_USER_ID = 32068;
