import PropTypes from 'prop-types';
import React from 'react';

import UserListItem from '../UserListItem/UserListItem';

export default class RecommendedUser extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * enum of connection type, only interests for now
         */
        connectionType: PropTypes.oneOf(['interests', 'vip']).isRequired,
        /**
         * string of custom css classname
         */
        className: PropTypes.string,
        /**
         * enum of clicked action of cta btn
         */
        clickedAction: PropTypes.oneOf([['FOLLOWED', 'REQUESTED']]),
        /**
         * function to call on btn click
         */
        callbackFn: PropTypes.func,
        /**
         * object of user recommended to logged in user
         */
        recommendedUser: PropTypes.object.isRequired,
        /**
         * links to groups open new tab
         */
        openLinksInNewTab: PropTypes.bool,
    };

    static defaultProps = {
        clickedAction: null,
        callbackFn: () => {},
        className: 'network-item recommendation',
        openLinksInNewTab: false,
    };

    render() {
        const {
            connectionType,
            clickedAction,
            className,
            callbackFn,
            recommendedUser,
            openLinksInNewTab,
        } = this.props;

        return (
            <li className="component-User-RecommendedUser">
                <UserListItem
                    user={recommendedUser}
                    buttonsToRender={['FOLLOW', 'CONNECT']}
                    followStatus={false}
                    connectionStatus={null}
                    className={className}
                    connectionType={connectionType}
                    clickedAction={clickedAction}
                    openLinksInNewTab={openLinksInNewTab}
                    onClickHandlers={{
                        onConnectCallback: () =>
                            callbackFn(
                                'recommendedUsers',
                                recommendedUser.userId,
                                'CONNECTED'
                            ),
                        onFollowCallback: () =>
                            callbackFn(
                                'recommendedUsers',
                                recommendedUser.userId,
                                'FOLLOWED'
                            ),
                    }}
                />
            </li>
        );
    }
}
