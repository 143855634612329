/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-object-spread */
/* eslint-disable dot-notation */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import TopicsTypeAheadDropdown from '../../../Community/TopicsTypeAheadDropdown/TopicsTypeAheadDropdown';
import CharCount from '../../../Elements/CharCount/CharCount';
import {
    legacyCommunityDiscussionYupSchema,
    DiscussionEditSchema,
} from '../../../Includes/FormSchema/ContentSchema';

import Button from '../../../Input/Button/Button';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';

import CheckBox from '../../Elements/CheckBox/CheckBox';

import './DiscussionForm.scss';

const DESCRIPTION_MAX_COUNT = 65000;
const TITLE_MAX_COUNT = 5000;

export default class DiscussionForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: (props.contentProps && props.contentProps.title) || '',
            description:
                props.contentProps && props.contentProps.description
                    ? props.contentProps.description
                    : '',
            postLocation: props.postLocation || null,
            anon: !!props.anon,
            isCreating: false,
            hasPostError: false,
            moderationOptions: props.moderationOptions || null,
            errors: {
                communityTopicId: '',
                description: '',
                title: '',
            },
        };
    }

    static propTypes = {
        /**
         * function to remove errors from UI when user edits input
         */
        clearError: PropTypes.func.isRequired,
        /**
         * boolean to determine initial state of isAnonymous
         */
        anon: PropTypes.bool,
        /**
         * array of topics for select dropdown
         */
        topics: PropTypes.array.isRequired,
        /**
         * function for handling post form success
         */
        onSuccess: PropTypes.func.isRequired,
        /**
         * function for rendering errors for UI
         */
        renderErrors: PropTypes.func.isRequired,
        /**
         * function to validate the form fields using Yup
         */
        validateForm: PropTypes.func.isRequired,
        editMode: PropTypes.bool,
        onEditSubmit: PropTypes.func,
        /**
         * function for retrieving key and value in form submission
         * key can either be communityTopicId or communityGroupHash
         */
        getPostIdKeyValue: PropTypes.func.isRequired,
        /**
            generates a label for anonymous checkbox
        */
        getCheckBoxLabel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        /**
         * isAnonymous defaulted to false
         */
        anon: false,
        editMode: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.postLocation) {
            return {
                postLocation: props.postLocation,
            };
        }
        return null;
    }

    handleSelect = (postLocation) => {
        this.setState({ postLocation });
        if (this.state.errors.postLocation)
            this.setState(this.props.clearError('postLocation'));
    };

    handleNewSelect = (selection) => {
        const newState = Object.assign({}, this.state);
        // eslint-disable-next-line dot-notation
        newState['postLocation'] = selection;
        newState.errors['oneOrTheOther'] = '';
        this.setState(newState);
    };

    handleInputChange = (key) => (value) => {
        this.setState({ [key]: value });
        if (this.state.errors[key]) this.setState(this.props.clearError(key));
    };

    handleCheckboxChange = () =>
        this.setState((previousState) => ({ anon: !previousState.anon }));

    handleSubmit = async () => {
        this.setState({ isCreating: true, hasPostError: false });
        const {
            postLocationKey,
            postLocationValue,
        } = this.props.getPostIdKeyValue(this.state.postLocation);

        const createFormValues = {
            title: this.state.title,
            description: this.state.description,
            anon: this.state.anon,
            [postLocationKey]: postLocationValue || this.state.postLocation,
        };

        const editFormValues = {
            title: this.state.title,
            description: this.state.description,
            anon: this.state.anon,
        };

        const formValues = this.props.editMode
            ? editFormValues
            : createFormValues;
        const schema = this.props.editMode
            ? DiscussionEditSchema
            : legacyCommunityDiscussionYupSchema;

        try {
            await this.props.validateForm(formValues, schema);
            this.props.editMode
                ? await this.props.onEditSubmit(
                      'community-discussion',
                      editFormValues,
                      this.props.contentProps.hash
                  )
                : await this.submitAPICall(createFormValues);
        } catch (errors) {
            return this.setState(this.props.renderErrors(errors));
        }
    };

    submitAPICall = async (formValues) => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        try {
            const result = await axios.post(
                '/api/community/community-discussions/create',
                formValues
            );
            this.setState({ isCreating: false });
            this.props.onSuccess(
                postocationId,
                `community-discussion-${result.data.data.hash}`
            );
        } catch (error) {
            this.setState({
                hasPostError:
                    'There was an error publishing your post. Please try sharing again.',
                isCreating: false,
            });
        }
    };

    hasPostLocation = () => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        return !!(postocationId && postocationId.toString().length);
    };

    hasTitle = () => !!(this.state.title && this.state.title.length);

    hasDescription = () =>
        !!(this.state.description && this.state.description.length);

    allFieldFilledOut = () =>
        !(this.hasPostLocation() && this.hasTitle() && this.hasDescription());

    setEmptyError = () => {
        this.setState({
            errors: {
                oneOrTheOther: this.hasPostLocation()
                    ? ''
                    : 'Please choose where to post to.',
                title: this.hasTitle() ? '' : 'This field is required.',
                description: this.hasDescription()
                    ? ''
                    : 'This field is required.',
            },
        });
    };

    render() {
        const checkboxLabel = this.props.getCheckBoxLabel(
            this.props.editMode,
            this.state.anon
        );
        const checkbox = (
            <CheckBox
                qaDataAttr="checkBoxLabel"
                id="ContentTempID"
                className="anonymous-checkbox"
                defaultChecked={this.state.anon}
                label={checkboxLabel}
                handleChange={this.handleCheckboxChange}
            />
        );
        const editableFields =
            this.state.moderationOptions &&
            this.state.moderationOptions.editableFields;

        return (
            <div className="component-Content-CreateContentWidgetV2-DiscussionForm">
                <div className="form">
                    <span className="error-message">
                        {this.state.hasPostError}
                    </span>
                    <span className="section-label">
                        {this.props.editMode ? 'Posted to:' : 'Post to:*'}
                    </span>
                    <TopicsTypeAheadDropdown
                        selection={this.state.postLocation}
                        selectCallback={this.handleNewSelect}
                        disabled={this.props.editMode}
                    />
                    <span className="error-message">
                        {this.state.errors.oneOrTheOther}
                    </span>
                    <div className="details-heading-row">
                        <span className="section-label">Title*</span>
                        {this.state.title.length ? (
                            <CharCount
                                length={this.state.title.length}
                                limit={TITLE_MAX_COUNT}
                            />
                        ) : null}
                    </div>
                    <TextAreaInput
                        name="title"
                        class="text-area-input title"
                        rows={1}
                        disabled={
                            this.props.editMode &&
                            !(
                                editableFields &&
                                editableFields.includes('title')
                            )
                        }
                        value={this.state.title}
                        onChange={this.handleInputChange('title')}
                    />
                    <span className="error-message">
                        {this.state.errors.title}
                    </span>

                    <div className="details-heading-row">
                        <span className="section-label">The details*</span>
                        {this.state.description.length ? (
                            <CharCount
                                length={this.state.description.length}
                                limit={DESCRIPTION_MAX_COUNT}
                            />
                        ) : null}
                    </div>
                    <TextAreaInput
                        name="details"
                        placeholder="What's on your mind?"
                        class="text-area-input description"
                        rows={3}
                        enterKeyNewLine
                        disabled={
                            this.props.editMode &&
                            !(
                                editableFields &&
                                editableFields.includes('description')
                            )
                        }
                        value={this.state.description}
                        onChange={this.handleInputChange('description')}
                    />
                    <span className="error-message">
                        {this.state.errors.description}
                    </span>
                </div>

                {checkbox}

                <Button
                    value={this.props.editMode ? 'Publish' : 'Post now'}
                    onClick={
                        this.allFieldFilledOut()
                            ? this.setEmptyError
                            : this.handleSubmit
                    }
                    type="purple-medium-white"
                    class="post-now-button"
                    inputStyle="post-now-button"
                    disabled={this.state.isCreating}
                />
            </div>
        );
    }
}
