import React from 'react';
import PropTypes from 'prop-types';
import LoginLayoutComponent from '../../Login/LoginLayoutComponent/LoginLayoutComponent';

import './AuthModal.scss';

export default class AuthModal extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        // common modal props
        closeModal: PropTypes.func.isRequired,
        modalTrigger: PropTypes.func.isRequired,
        featureFlags: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,

        // auth modal props passed in through modalTrigger
        layoutType: PropTypes.oneOf([
            'create',
            'login',
            'password',
            'password-confirmation',
            'facebook-reauthorization',
            'facebook-reauthorization-confirmation',
        ]),
        loginRedirectPath: PropTypes.string.isRequired,
        redirectUri: PropTypes.string.isRequired,
        loginMetrics: PropTypes.object,
        invitationToken: PropTypes.string,
        incompleteRegistrationId: PropTypes.string,
        inviteHash: PropTypes.string,
        groupInviteHash: PropTypes.string,
        connectionInviteHash: PropTypes.string,
        callToActionSubTitle: PropTypes.string,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        layoutType: 'login',
        loginMetrics: { login_trigger: 'generic' },
        invitationToken: null,
        incompleteRegistrationId: null,
        inviteHash: null,
        groupInviteHash: null,
        connectionInviteHash: null,
        callToActionSubTitle: null,
        onClose: null,
    };

    handleClose = () => {
        const { closeModal, onClose } = this.props;

        if (onClose) onClose();
        return closeModal();
    };

    render() {
        return (
            <div className="component-Modals-AuthModal">
                <LoginLayoutComponent
                    displayMode="popup"
                    hidePopup={this.handleClose}
                    {...this.props}
                />
            </div>
        );
    }
}
