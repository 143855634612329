import PropTypes from 'prop-types';
import React from 'react';

import CharCount from '../../Elements/CharCount/CharCount';
import ErrorLabel from '../../Elements/ErrorLabel/ErrorLabel';
import FieldLabel from '../../Elements/FieldLabel/FieldLabel';

import './FormElementWithLabel.scss';

export default class FormElementWithLabel extends React.PureComponent {
    static propTypes = {
        /**
         * object of props for FieldLabel
         */
        fieldLabelProps: PropTypes.shape({
            /**
             * string label for text area input
             */
            label: PropTypes.string.isRequired,
            /**
             * boolean to show tooltip or not
             */
            shouldShowTooltip: PropTypes.bool,
            /**
             * string of text in tooltip
             */
            tooltipText: PropTypes.string,
        }),
        elementValue: PropTypes.string,
        /**
         * number of max characters count for text element
         */
        inputMaxLength: PropTypes.number,
        /**
         * string of error
         */
        error: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        haveErrorSpace: PropTypes.bool,
    };

    static defaultProps = {
        error: '',
        inputMaxLength: 0,
        children: null,
        elementValue: '',
        className: '',
        haveErrorSpace: false,
    };

    render() {
        const { className } = this.props;
        const valueLength = this.props.elementValue
            ? this.props.elementValue.length
            : 0;

        const defaultErrorCn =
            className && className.length > 0
                ? `${className.split(' ').join('-')}-error-message`
                : 'error-message';

        return (
            <div
                className={`component-Input-FormElementWithLabel ${this.props.className}`}
            >
                <label
                    className={`component-Input-FormElementWithLabel__label`}
                >
                    <div className="label-and-counter">
                        <FieldLabel {...this.props.fieldLabelProps} />
                        <CharCount
                            length={valueLength}
                            limit={this.props.inputMaxLength}
                        />
                    </div>
                </label>
                {this.props.children}
                {this.props.error || this.props.haveErrorSpace ? (
                    <ErrorLabel
                        error={this.props.error}
                        cssClass={defaultErrorCn}
                    />
                ) : null}
            </div>
        );
    }
}
