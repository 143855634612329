import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../Input/Button/Button';

import './CloudspongeAddressComponent.scss';

export default class CloudspongeAddressComponent extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        // in order to use this component, make sure you add the class 'cloudsponge-contacts' to the component you want to load emails into
        /**
         * onClick function
         */
        onClick: PropTypes.func.isRequired,
        /**
         * onChange handler
         */
        onChange: PropTypes.func.isRequired,
        cssFilePathFromPage: PropTypes.string,
    };

    static defaultProps = {
        buttonText: 'Add your contacts',
    };

    componentDidMount() {
        this.loadCloudSpongeScript();
    }

    loadCloudSpongeScript = () => {
        let script = document.createElement('script');
        script.src = `https://api.cloudsponge.com/widget/vFJReqiusmuZZ5X3ZfBCZg.js`;
        script.addEventListener('load', this.cloudspongeInit);
        script.async = true;
        document.head.appendChild(script);
    };

    cloudspongeInit = () => {
        cloudsponge.init({
            afterSubmitContacts: this.populateTextarea,
            sources: [
                'gmail',
                'yahoo',
                'outlook',
                'linkedin',
                'icloud',
                'addressbook',
                'aol',
                'windowslive',
                'csv',
                'plaxo',
                'mail_ru',
                'uol',
                'bol',
                'terra',
                'rediff',
                'mail126',
                'mail163',
                'mail_yeah_net',
                'gmx',
                'qip_ru',
                'sapo',
                'mailcom',
                'yandex_ru',
            ],
        });
    };

    populateTextarea = (contacts) => {
        const selectedContactInfo = contacts.map(
            (contact) => contact.__selectedMail__
        );
        this.props.onChange(selectedContactInfo.join('\n'));
    };

    render() {
        return (
            <div className="component-Form-CloudspongeAddressComponent">
                <a className="cloudsponge-launch">
                    <Button
                        type={'solid'}
                        value={this.props.buttonText}
                        qaAttr={'add contacts'}
                        onClick={this.props.onClick}
                    />
                </a>
            </div>
        );
    }
}
