import PropTypes from 'prop-types';
import React from 'react';

import './DotNavigation.scss';

export default class DotNavigation extends React.PureComponent {
    constructor(props) {
        super();
        this.dotArray =
            props.totalDots > 0 ? Array(props.totalDots).fill('item') : [];
    }

    static propTypes = {
        /**
         * styling that would override the default
         */
        className: PropTypes.string,
        /**
         * the number of dot the navigation going to display
         */
        totalDots: PropTypes.number.isRequired,
        /**
         * the index of selected dot
         */
        selectedDotIndex: PropTypes.number.isRequired,
        /**
         * function that is going to trigger on click dot navigation
         * @returns {integer} the index of clicked dot
         */
        handleClick: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        totalDots: 0,
        selectedDotIndex: 0,
        handleClick: () => {},
    };

    handleDotClick = (index) => {
        const { handleClick } = this.props;
        handleClick(index);
    };

    render() {
        const { selectedDotIndex, className, totalDots } = this.props;

        if (totalDots <= 0) {
            return null;
        }

        return (
            <div className={`component-Elements-DotNavigation ${className}`}>
                {this.dotArray.map((item, index) => (
                    <button
                        key={`dot-btn-${index}`}
                        data-test={`dot-btn-${index}`}
                        className={`dot-btn ${
                            index === selectedDotIndex ? 'dot-btn-active' : ''
                        }`}
                        onClick={() => this.handleDotClick(index)}
                    />
                ))}
            </div>
        );
    }
}
