import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../../../../Content/User/UserDisplay/UserImage/UserImage';
import Close from '../../../../Icons/Close';

import SVGIconWrapper from '../../../../Icons/SVGIconWrapper/SVGIconWrapper';
import './TopBar.scss';
import UserDetails from './UserDetails/UserDetails';

const DEFAULT_FGB_LOGO = buildImagePath(
    'v1554481785/logos/white/white-fgb-logo-v2.png',
    'size150'
);

export default class TopBar extends React.PureComponent {
    renderTopBar = () => {
        switch (this.props.drawerType) {
            case 'nav-bar':
                return this.renderNavDrawer();
            case 'message':
                return this.renderMessageDrawer();
            default:
                break;
        }
    };

    renderMessageDrawer = () => {
        const {
            closeIcon,
            handleClose,
            headerText,
            profileImage,
            profileLink,
            userRole,
        } = this.props;
        return (
            <React.Fragment>
                <UserDetails
                    drawerType="message"
                    profileImage={profileImage}
                    profileLink={profileLink}
                    username={headerText}
                    userRole={userRole}
                />

                <div className="close-return">
                    <SVGIconWrapper
                        handleClickSVGWrapper={handleClose}
                        SVGWrapperCssClass="svg-close-icon"
                    >
                        {closeIcon}
                    </SVGIconWrapper>
                    <span className="return-to-messages">
                        <a href="/messages">Go to messages</a>
                    </span>
                </div>
            </React.Fragment>
        );
    };

    renderNavDrawer = () => {
        const {
            closeIcon,
            handleClose,
            headerText,
            logoSrc,
            profileImage,
            profileLink,
            shouldShowLogo,
        } = this.props;

        return (
            <React.Fragment>
                {profileImage && (
                    <UserDetails
                        type="nav-bar"
                        profileImage={profileImage}
                        profileLink={profileLink}
                        username={headerText}
                    />
                )}

                {!profileImage && headerText && (
                    <span className="heading-text">{headerText}</span>
                )}
                {shouldShowLogo && (
                    <img
                        height="100px"
                        width="150px"
                        src={logoSrc}
                        alt="logo"
                    />
                )}
                <SVGIconWrapper
                    handleClickSVGWrapper={handleClose}
                    SVGWrapperCssClass="svg-close-icon"
                >
                    {closeIcon}
                </SVGIconWrapper>
            </React.Fragment>
        );
    };

    render() {
        const whiteThemeCssClass = this.props.shouldUseWhiteTheme
            ? 'white'
            : '';

        return (
            <div
                className={`component-Layout-Header-Modules-TopBar ${whiteThemeCssClass} ${this.props.drawerType}`}
            >
                {this.renderTopBar()}
            </div>
        );
    }
}

TopBar.propTypes = {
    /**
     * optional close icon
     */
    closeIcon: PropTypes.element,
    /**
     * type of drawer of enum nav-bar or message
     */
    drawerType: PropTypes.oneOf(['nav-bar', 'message']),
    /**
     * a function to close the drawer on close icon's onClick
     */
    handleClose: PropTypes.func.isRequired,
    /**
     * either username for user or heading e.g. Alerts
     */
    headerText: PropTypes.string,
    /**
     * URL path of logo if not the default white logo
     */
    logoSrc: PropTypes.string,
    /**
     * URL path of profile image
     */
    profileImage: PropTypes.string,
    /**
     * URL path to user profile
     */
    profileLink: PropTypes.string,
    /**
     * boolean to check whether logo should show
     * defaulted to false
     */
    shouldShowLogo: PropTypes.bool,
    /**
     * boolean to check if the white theme should be used
     * defaulted to false
     */
    shouldUseWhiteTheme: PropTypes.bool,
    /**
     * string of user's role
     */
    userRole: PropTypes.string,
};

TopBar.defaultProps = {
    closeIcon: Close,
    drawerType: 'nav-bar',
    logoSrc: DEFAULT_FGB_LOGO,
    profileLink: '/users/profile',
    shouldShowLogo: false,
    shouldUseWhiteTheme: false,
    headerText: null,
    profileImage: null,
    userRole: null,
};
