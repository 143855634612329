import PropTypes from 'prop-types';
import React from 'react';

import './Overlay.scss';

export default class Overlay extends React.PureComponent {
    static propTypes = {
        /**
         * custom css class for styling overlay
         */
        cssClass: PropTypes.string,
        /**
         * function to close the overlay
         */
        handleClose: PropTypes.func.isRequired,
        /**
         * children to be rendered inside overlay
         * is optional
         */
        children: PropTypes.element,
        /**
         * type dark or transparent for overlay style
         */
        type: PropTypes.oneOf(['dark', 'transparent']),
        /**
         * drawer type
         */
        drawerType: PropTypes.oneOf(['nav-bar', 'message']),
    };

    static defaultProps = {
        type: 'dark',
        drawerType: 'nav-bar',
    };

    componentDidMount() {
        document.querySelector('body').classList.add('overlay-open');
        if (this.props.drawerType === 'message')
            document.querySelector('body').classList.add('message-drawer');
    }

    componentWillUnmount() {
        document
            .querySelector('body')
            .classList.remove('overlay-open', 'message-drawer');
    }

    render() {
        const { children, cssClass, handleClose, type } = this.props;

        return (
            <div
                className={`component-Overlay ${type} ${cssClass}`}
                onClick={handleClose}
            >
                {children}
            </div>
        );
    }
}
