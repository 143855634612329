import tabbable from './tabbable';

// elements (ie loaded from external script) that we need to be able to
// bypass focus trap to interact with
const whitelistedElements = ['.pac-container'];

let activeFocusDelay;

const activeFocusTraps = (function () {
    let trapQueue = [];
    return {
        activateTrap: (trap) => {
            if (trapQueue.length > 0) {
                const activeTrap = trapQueue[trapQueue.length - 1];
                if (activeTrap !== trap) {
                    activeTrap.pause();
                }
            }

            const trapIndex = trapQueue.indexOf(trap);

            if (trapIndex === -1) {
                trapQueue.push(trap);
            } else {
                trapQueue.splice(trapIndex, 1);
                trapQueue.push(trap);
            }
        },
        deactivateTrap: (trap) => {
            const trapIndex = trapQueue.indexOf(trap);
            if (trapIndex !== -1) {
                trapQueue.splice(trapIndex, 1);
            }

            if (trapQueue.length > 0) {
                trapQueue[trapQueue.length - 1].unpause();
            }
        },
    };
})();

function createFocusTrap(element, userOptions) {
    let doc = document;
    let container =
        typeof element === 'string' ? doc.querySelector(element) : element;

    const config = extend(
        {
            returnFocusOnDeactivate: true,
            escapeDeactivates: true,
            clickOutsideDeactivates: false,
            allowOutsideClick: bypassPreventClick,
        },
        userOptions
    );

    const state = {
        firstTabbableNode: null,
        lastTabbableNode: null,
        nodeFocusedBeforeActivation: null,
        mostRecentlyFocusedNode: null,
        active: false,
        paused: false,
    };

    const trap = {
        activate: activate,
        deactivate: deactivate,
        pause: pause,
        unpause: unpause,
    };

    return trap;

    function activate(activateOptions) {
        if (state.active) return;

        updateTabbableNodes();

        state.active = true;
        state.paused = false;
        state.nodeFocusedBeforeActivation = doc.activeElement;

        const onActivate =
            activateOptions && activateOptions.onActivate
                ? activateOptions.onActivate
                : config.onActivate;

        if (onActivate) {
            onActivate();
        }

        addListeners();
        return trap;
    }

    function deactivate(deactivateOptions) {
        if (!state.active) return;

        clearTimeout(activeFocusDelay);

        removeListeners();

        state.active = false;
        state.paused = false;

        activeFocusTraps.deactivateTrap(trap);

        const onDeactivate =
            deactivateOptions && deactivateOptions.onDeactivate !== undefined
                ? deactivateOptions.onDeactivate
                : config.onDeactivate;

        if (onDeactivate) {
            onDeactivate();
        }

        const returnFocus =
            deactivateOptions && deactivateOptions.returnFocus !== undefined
                ? deactivateOptions.returnFocus
                : config.returnFocusOnDeactivate;

        if (returnFocus) {
            delay(() => {
                tryFocus(state.nodeFocusedBeforeActivation);
            });
        }

        return trap;
    }

    function pause() {
        if (state.paused || !state.active) return;
        state.paused = true;
        removeListeners();
    }

    function unpause() {
        if (!state.paused || !state.active) return;
        state.paused = false;
        updateTabbableNodes();
        addListeners();
    }

    function addListeners() {
        if (!state.active) return;
        activeFocusTraps.activateTrap(trap);

        activeFocusDelay = delay(() => {
            tryFocus(getInitialFocusNode());
        });

        doc.addEventListener('focusin', checkFocusIn, true);
        doc.addEventListener('mousedown', checkPointerDown, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('touchstart', checkPointerDown, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('click', checkClick, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('keydown', checkKey, {
            capture: true,
            passive: false,
        });

        return trap;
    }

    function removeListeners() {
        if (!state.active) return;

        doc.removeEventListener('focusin', checkFocusIn, true);
        doc.removeEventListener('mousedown', checkPointerDown, true);
        doc.removeEventListener('touchstart', checkPointerDown, true);
        doc.removeEventListener('click', checkClick, true);
        doc.removeEventListener('keydown', checkKey, true);

        return trap;
    }

    function getNodeForOption(optionName) {
        const optionValue = config[optionName];
        let node = optionValue;
        if (!optionValue) {
            return null;
        }

        if (typeof optionValue === 'string') {
            node = doc.querySelector(optionValue);

            if (!node) {
                throw new Error(`${optionName} refers to no known node`);
            }
        }

        if (typeof optionValue === 'function') {
            node = optionValue();

            if (!node) {
                throw new Error(`${optionName} did not return a node`);
            }
        }

        return node;
    }

    function getInitialFocusNode() {
        let node;

        if (getNodeForOption('initialFocus') !== null) {
            node = getNodeForOption('initialFocus');
        } else if (container.contains(doc.activeElement)) {
            node = doc.activeElement;
        } else {
            node = state.firstTabbableNode || getNodeForOption('fallbackFocus');
        }

        if (!node) {
            throw new Error(
                `Cannot trap focus without at least one focusable element`
            );
        }

        return node;
    }

    function checkPointerDown(e) {
        if (container.contains(e.target)) return;
        if (config.clickOutsideDeactivates) {
            deactivate({
                returnFocus: !tabbable.isFocusable(e.target),
            });
            return;
        }

        if (config.allowOutsideClick && config.allowOutsideClick(e)) {
            return;
        }

        e.preventDefault();
    }

    function checkFocusIn(e) {
        if (container.contains(e.target) || e.target instanceof Document)
            return;

        e.stopImmediatePropagation();
        tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode());
    }

    function checkKey(e) {
        if (config.escapeDeactivates !== false && isEscapeEvent(e)) {
            e.preventDefault();
            deactivate();
            return;
        }

        if (isTabEvent(e)) {
            checkTab(e);
            return;
        }
    }

    function checkTab(e) {
        updateTabbableNodes();
        if (e.shiftKey && e.target === state.firstTabbableNode) {
            e.preventDefault();
            tryFocus(state.lastTabbableNode);
            return;
        }

        if (!e.shiftKey && e.target === state.lastTabbableNode) {
            e.preventDefault();
            tryFocus(state.firstTabbableNode);
            return;
        }
    }

    function checkClick(e) {
        if (config.clickOutsideDeactivates) return;
        if (container.contains(e.target)) return;
        if (config.allowOutsideClick && config.allowOutsideClick(e)) return;
        e.preventDefault();
        e.stopImmediatePropagation();
    }

    function updateTabbableNodes() {
        const tabbableNodes = tabbable(container);
        state.firstTabbableNode = tabbableNodes[0] || getInitialFocusNode();
        state.lastTabbableNode =
            tabbableNodes[tabbableNodes.length - 1] || getInitialFocusNode();
    }

    function tryFocus(node) {
        if (node === doc.activeElement) return;
        if (!node || !node.focus) {
            tryFocus(getInitialFocusNode());
            return;
        }

        node.focus();

        state.mostRecentlyFocusedNode = node;

        if (isSelectableInput(node)) {
            node.select();
        }
    }
}

function bypassPreventClick(e) {
    // allows clicking elements outside focus trapped element when true
    const clickableElementList = whitelistedElements.join(' ');
    const clickableNodes = document.querySelectorAll(clickableElementList);
    if (clickableNodes.length <= 0) return false; // no whitelisted elements found

    // iterate through our nodes and check if each was clicked
    clickableNodes.forEach((node) => {
        if (node.contains(e.target)) return true;
    });

    return false;
}

function isSelectableInput(node) {
    return (
        node.tagName &&
        node.tagName.toLowerCase() === 'input' &&
        typeof node.select === 'function'
    );
}

function isEscapeEvent(e) {
    return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;
}

function isTabEvent(e) {
    return e.key === 'Tab' || e.keyCode === 9;
}

function delay(fn) {
    return setTimeout(fn, 0);
}

const hasOwnProperty = Object.prototype.hasOwnProperty;

function extend() {
    let target = {};

    for (let i = 0; i < arguments.length; i++) {
        const source = arguments[i];

        for (let key in source) {
            if (hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

export default createFocusTrap;
