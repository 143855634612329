import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ModalContainer from '../ModalParts/ModalContainer/ModalContainer';
import LocationSelect from './LocationSelect/LocationSelect';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import EducationInput from './EducationInput/EducationInput';
import ExperienceInput from './ExperienceInput/ExperienceInput';
import Confirmation from './Confirmation/Confirmation';
import { validatePayload } from '../../Includes/FormSchema/UserProfileSchema';

import './QuickApplyModal.scss';

const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const cleanPath = (path) =>
    path.includes('experiences') || path.includes('educations')
        ? path.split('.').slice(1)[0]
        : path.split('.')[0];

const cleanValidationError = (error) => {
    const cleanError = {
        path: cleanPath(error.path),
        message: capitalize(error.message),
        type: error.type,
    };

    return cleanError;
};

const defaultLocation = {
    name: '',
    lat: null,
    lng: null,
};

const locationError = 'Please select a location from the dropdown';

const initialErrorState = {
    jobLocationSelect: '',
    name: '',
    school: '',
    degree: '',
    location: '',
    title: '',
    company: '',
};

const initialExperienceState = {
    name: '',
    title: '',
    company: {
        companyId: null,
        companyName: '',
    },
    location: {
        name: '',
        lat: null,
        lng: null,
    },
    isRemote: false,
};

const initialEducationState = {
    name: '',
    school: {
        id: null,
        name: '',
    },
    degree: '',
    location: {
        name: '',
        lat: null,
        lng: null,
    },
    isRemote: false,
};

const experienceErrors = ['name', 'title', 'company', 'location'];
const educationErrors = ['name', 'school', 'degree', 'location'];

export default class QuickApplyModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            isStudent: false,
            selectedLocationId: '',
            experience: initialExperienceState,
            education: initialEducationState,
            errors: initialErrorState,
        };
    }

    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        modalTrigger: PropTypes.func.isRequired,
        featureFlags: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        session: PropTypes.object,

        // for quick apply
        jobLocations: PropTypes.arrayOf(
            PropTypes.exact({
                jobLocationId: PropTypes.number.isRequired,
                locationName: PropTypes.string.isRequired,
            })
        ),
        jobId: PropTypes.number.isRequired,
        applyToJob: PropTypes.func.isRequired,
        defaultLocationId: PropTypes.number,
        applyJobCallback: PropTypes.func,
        skipAddDetails: PropTypes.bool,
        onlyShowConfirmation: PropTypes.bool,
    };

    static defaultProps = {
        session: null,
        jobLocations: [],
        defaultLocationId: null,
        applyJobCallback: null,
        skipAddDetails: false,
        onlyShowConfirmation: false,
    };

    componentDidMount = () => {
        const { jobLocations, session, onlyShowConfirmation } = this.props;

        if (onlyShowConfirmation) return this.goToConfirmation();

        if (session && session.name) this.setSessionName(session.name);

        if (jobLocations.length > 1) return this.goToLocationSelect();

        return this.goToAddDetails();
    };

    goToLocationSelect = () => this.goToStep(0);

    goToAddDetails = async () => {
        const { skipAddDetails } = this.props;

        if (skipAddDetails) {
            this.goToConfirmation();

            const jobApplyStatus = await this.handleJobApplyApiCall();

            return;
        }

        EmitMetric({
            misc_event_count: 1,
            misc_event_type: 'quick-profile-modal-impression',
        });
        return this.goToStep(1);
    };

    goToConfirmation = () => this.goToStep(2);

    setSessionName = (name) => {
        this.updateEducationField('name')(name);
        this.updateExperienceField('name')(name);
    };

    clearAllErrors = () => this.setState({ errors: initialErrorState });

    clearSingleError = (fieldName) =>
        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [fieldName]: '',
            },
        }));

    getSingleError = (errorField) => {
        const { errors } = this.state;

        if (!errors[errorField]) return '';

        return errors[errorField];
    };

    setSingleError = (errorField, text) =>
        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [errorField]: text,
            },
        }));

    batchSetError = (errors) => {
        const cleanErrors = errors.map((e) => cleanValidationError(e));
        const filteredErrors = cleanErrors.filter(
            (e) =>
                !e.message.toLowerCase().includes('educations') &&
                !e.message.toLowerCase().includes('experiences')
        );
        const requiredErrors = filteredErrors.filter(
            (e) => e.type === 'required'
        );
        const requiredPaths = requiredErrors.map((e) => e.path);
        const otherErrors = filteredErrors.filter((e) => e.type !== 'required');

        requiredErrors.map((e) => this.setSingleError(e.path, e.message));

        return otherErrors
            .filter((e) => !requiredPaths.includes(e.path))
            .map((e) => this.setSingleError(e.path, e.message));
    };

    getErrorsByType = (type = 'experience') => {
        const { errors } = this.state;

        if (type === 'experience')
            return experienceErrors.reduce(
                (acc, errorField) => ({
                    ...acc,
                    [errorField]: this.getSingleError(errorField),
                }),
                {}
            );

        if (type === 'education')
            return educationErrors.reduce(
                (acc, errorField) => ({
                    ...acc,
                    [errorField]: this.getSingleError(errorField),
                }),
                {}
            );

        // default to all errors
        return errors;
    };

    goToStep = (step) => this.setState({ step });

    toggleStudentStatus = () => {
        const { session } = this.props;

        return this.setState(
            (prevState) => ({
                isStudent: !prevState.isStudent,
                experience: initialExperienceState,
                education: initialEducationState,
                errors: initialErrorState,
            }),
            () => session && session.name && this.setSessionName(session.name)
        );
    };

    handleJobLocationSelect = (locationId) => {
        EmitMetric({
            misc_event_type: 'quick-apply-location-select-click',
            misc_event_count: 1,
        });
        this.setState({ selectedLocationId: locationId }, () =>
            this.clearSingleError('jobLocationSelect')
        );
    };

    handleJobLocationConfirm = async () => {
        const { jobId, applyToJob, skipAddDetails } = this.props;
        const { selectedLocationId } = this.state;

        if (!selectedLocationId || selectedLocationId.length === 0)
            return this.setSingleError('jobLocationSelect', locationError);

        EmitMetric({
            misc_event_type: 'quick-apply-location-submit-click',
            misc_event_count: 1,
        });

        if (skipAddDetails) {
            try {
                await applyToJob(jobId, selectedLocationId);
                return this.goToConfirmation();
            } catch (error) {
                this.setSingleError(
                    'jobLocationSelect',
                    'There was a problem applying for this job'
                );
                return this.goToLocationSelect();
            }
        }

        this.clearSingleError('jobLocationSelect');

        return this.goToAddDetails();
    };

    handleProfileUpdateApiCall = async (payload) => {
        EmitMetric({
            misc_event_count: 1,
            misc_event_type: 'quick-profile-modal-submit-click',
        });
        const endpoint = '/api/user/profile';

        try {
            const response = await axios.patch(endpoint, payload);
            const { data } = response;
            if (data.meta.success) {
                return true;
            }
        } catch (error) {
            console.log('Profile Update API Request Error: ', error);
            return false;
        }
    };

    handleJobApplyApiCall = async () => {
        const {
            applyToJob,
            jobId,
            applyJobCallback,
            jobLocations,
            defaultLocationId,
        } = this.props;
        const { selectedLocationId } = this.state;

        if (!selectedLocationId && jobLocations.length > 1) {
            this.goToLocationSelect();
            return this.setSingleError('jobLocationSelect', locationError);
        }

        try {
            const locationId = selectedLocationId || defaultLocationId;
            await applyToJob(jobId, locationId);

            if (applyJobCallback) applyJobCallback();
            return true;
        } catch (error) {
            console.log('JOB APPLY API ERROR: ', error);
            return false;
        }
    };

    validateInputs = async (payload) => {
        const { valid, errors } = await validatePayload(payload);

        if (valid) return true;

        this.batchSetError(errors);

        return false;
    };

    getProfileUpdatePayload = () => {
        const { isStudent, experience, education } = this.state;

        if (isStudent) {
            const { name, ...restEducation } = education;
            return {
                name,
                experiences: null,
                educations: [restEducation],
                interests: null,
                dreamCompanies: null,
            };
        }

        const { name, ...restExperience } = experience;
        return {
            name,
            experiences: [restExperience],
            educations: null,
            interests: null,
            dreamCompanies: null,
        };
    };

    handleSaveAndApply = async () => {
        this.clearAllErrors();
        const profileUpdatePayload = this.getProfileUpdatePayload();

        // validate inputs - if invalid it will set errors
        const validStatus = await this.validateInputs(profileUpdatePayload);

        if (!validStatus) return;

        const profileUpdateStatus = await this.handleProfileUpdateApiCall(
            profileUpdatePayload
        );

        if (!profileUpdateStatus) return;

        // successfully updated profile - apply to job
        const jobApplyStatus = await this.handleJobApplyApiCall();

        if (!jobApplyStatus) return;

        return this.goToConfirmation();
    };

    clearExperienceLocation = () =>
        this.setState((prevState) => ({
            experience: {
                ...prevState.experience,
                location: defaultLocation,
            },
        }));

    handleRemoteToggle = () =>
        this.setState((prevState) => ({
            experience: {
                ...prevState.experience,
                isRemote: !prevState.experience.isRemote,
            },
        }));

    updateExperienceField = (fieldName) => (value) => {
        if (fieldName === 'isRemote') {
            this.clearExperienceLocation();
            return this.handleRemoteToggle();
        }

        if (fieldName === 'company')
            return this.setState((prevState) => ({
                experience: {
                    ...prevState.experience,
                    company: {
                        companyId: value.id || value.companyId,
                        companyName: value.companyName || value.name,
                    },
                },
            }));

        if (fieldName === 'location') {
            const newLocation = {
                name: value.value,
                lat: value.coordinates.lat,
                lng: value.coordinates.lng,
            };

            return this.setState((prevState) => ({
                experience: {
                    ...prevState.experience,
                    location: newLocation,
                },
            }));
        }

        return this.setState((prevState) => ({
            experience: {
                ...prevState.experience,
                [fieldName]: value,
            },
        }));
    };

    updateEducationField = (fieldName) => (value) => {
        if (fieldName === 'school')
            return this.setState((prevState) => ({
                education: {
                    ...prevState.education,
                    school: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }));

        if (fieldName === 'location') {
            const newLocation = {
                name: value.value,
                lat: value.coordinates.lat,
                lng: value.coordinates.lng,
            };

            return this.setState((prevState) => ({
                education: {
                    ...prevState.education,
                    location: newLocation,
                },
            }));
        }

        return this.setState((prevState) => ({
            education: {
                ...prevState.education,
                [fieldName]: value,
            },
        }));
    };

    getAddDetailsSection = () => {
        const { isStudent, education, experience } = this.state;

        const experienceErrorObj = this.getErrorsByType('experience');
        const educationErrorObj = this.getErrorsByType('education');

        return (
            <div className="add-details-section">
                <p className="main-header">
                    We need a few more details to complete your registration.
                </p>
                <p className="sub-header">
                    (You&apos;ll only have to do this once.)
                </p>
                {isStudent ? (
                    <EducationInput
                        errors={educationErrorObj}
                        updateEducation={this.updateEducationField}
                        {...education}
                    />
                ) : (
                    <ExperienceInput
                        errors={experienceErrorObj}
                        updateExperience={this.updateExperienceField}
                        {...experience}
                    />
                )}
                <button
                    type="button"
                    className={`student-toggle-btn ${
                        isStudent ? 'edu-margin' : 'exp-margin'
                    }`}
                    onClick={this.toggleStudentStatus}
                >
                    {isStudent ? "I'm not a student" : "I'm a student"}
                </button>
                <button
                    type="button"
                    className="save-apply-button"
                    onClick={this.handleSaveAndApply}
                >
                    Save & Apply
                </button>
            </div>
        );
    };

    getModalFlow = () => {
        const { selectedLocationId, errors, step } = this.state;
        const { jobLocations } = this.props;

        const { jobLocationSelect: jobLocationSelectError } = errors;

        switch (step) {
            case 1:
                return this.getAddDetailsSection();

            case 2:
                return <Confirmation />;

            default:
                return (
                    <LocationSelect
                        locations={jobLocations}
                        handleJobLocationSelect={this.handleJobLocationSelect}
                        handleJobLocationConfirm={this.handleJobLocationConfirm}
                        currentSelectionId={selectedLocationId}
                        jobLocationSelectError={jobLocationSelectError}
                    />
                );
        }
    };

    render() {
        const { closeModal } = this.props;

        const modalFlow = this.getModalFlow();

        return (
            <ModalContainer showButton={false} closeModal={closeModal}>
                <div className="component-Modals-QuickApplyModal">
                    {modalFlow}
                </div>
            </ModalContainer>
        );
    }
}
