import React from 'react';
import PropTypes from 'prop-types';

import './CheckGroup.scss';
import { constructCn } from '../../../../../utilities/helperFunctions';

export const CheckGroup = ({
    groupName,
    checkedValues,
    onChange,
    children,
    checkboxCn,
    labelCn,
}) => {
    const onCheckboxChange = React.useCallback(
        (checkboxValue) => (event) => {
            if (event.target.checked) {
                const newCheckedValues = [...checkedValues, checkboxValue];
                return onChange(newCheckedValues);
            }

            const filteredCheckedValues = checkedValues.filter(
                (v) => v !== checkboxValue
            );
            return onChange(filteredCheckedValues);
        },
        [checkedValues, onChange]
    );

    const Checkbox = (checkboxProps) => {
        const { value: checkboxValue, disabled, ...rest } = checkboxProps;

        const checked =
            checkedValues && checkedValues.length > 0
                ? checkedValues.includes(checkboxValue)
                : false;

        const cbCn = constructCn('checkbox-display', checkboxCn);

        const boxId = `${groupName}[${checkboxValue}]`;

        return (
            <div className="checkbox-element">
                <input
                    {...rest}
                    id={boxId}
                    type="checkbox"
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    onChange={onCheckboxChange(checkboxValue)}
                    value={checkboxValue}
                />
                <label className={labelCn} htmlFor={boxId}>
                    <div className={cbCn} />
                    {checkboxValue}
                </label>
            </div>
        );
    };

    return (
        <div className="component-Modals-ModalParts-Inputs-CheckGroup">
            {children(Checkbox)}
        </div>
    );
};

CheckGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node.isRequired,
        PropTypes.func.isRequired,
    ]).isRequired,
    groupName: PropTypes.string.isRequired,
    checkedValues: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    checkboxCn: PropTypes.string,
    labelCn: PropTypes.string,
};

CheckGroup.defaultProps = {
    checkedValues: [],
    checkboxCn: 'check-group-box',
    labelCn: 'check-group-box-label',
};

CheckGroup.displayName = 'CheckGroup';

export default React.memo(CheckGroup);
