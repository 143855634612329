export const boolDropdownOptions = [
    {
        value: 0,
        label: 'No',
    },
    {
        value: 1,
        label: 'Yes',
    },
];

export const yearsOfExperienceOptions = [
    'less than 2',
    '2-5',
    '6-10',
    '11+',
].map((v) => ({ value: v, label: `${v} years` }));

export const emailLabel = 'Email address for recruiters to contact you*';
export const firstNameLabel = 'Your First Name *';
export const lastNameLabel = 'Your Last Name *';
export const workAuthLabel =
    'Are you authorized to work in the United States?*';
export const sponsorshipLabel = 'Do you require sponsorship?*';
export const studentLabel = 'Are you currently a student?*';
export const yearsExpLabel = 'Years of experience*';

export const buildRegistrationPayload = (requirements, responses) => {
    const {
        email,
        isAuthorizedToWork,
        isCurrentStudent,
        isRequiredSponsorship,
        resumeFileName,
        resumeUrl,
        yearsOfExperience,
        firstName,
        lastName,
    } = responses;

    let registrationResponses = {};

    if (requirements.requireEmail) {
        registrationResponses = { ...registrationResponses, email };
    }

    if (requirements.requireResume) {
        registrationResponses = {
            ...registrationResponses,
            resumeUrl /* if a new file is uploaded, the data url captured from the upload should be passed thru to event registration API */,
            resumeFileName,
        };
    }

    if (requirements.requireWorkAuthQuestion) {
        registrationResponses = {
            ...registrationResponses,
            isAuthorizedToWork,
        };
    }

    if (requirements.requireSponsorQuestion) {
        registrationResponses = {
            ...registrationResponses,
            isRequiredSponsorship,
        };
    }

    if (requirements.requireStudentQuestion) {
        registrationResponses = { ...registrationResponses, isCurrentStudent };
    }

    if (requirements.requireYearExpDropdown) {
        registrationResponses = { ...registrationResponses, yearsOfExperience };
    }
    if (firstName)
        registrationResponses = {
            ...registrationResponses,
            firstName: firstName?.trim(),
        };
    if (lastName)
        registrationResponses = {
            ...registrationResponses,
            lastName: lastName?.trim(),
        };

    return { registrationResponses };
};
