import PropTypes from 'prop-types';
import React from 'react';
import { constructCn } from '../../../utilities/helperFunctions';

import './FGBLabel.scss';

function FGBLabel({ children, className, as: Wrapper }) {
    const cn = constructCn('component-Elements-FGBLabel', className);

    return <Wrapper className={cn}>{children}</Wrapper>;
}

FGBLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
    className: PropTypes.string,
    as: PropTypes.string,
};
FGBLabel.defaultProps = {
    className: '',
    as: 'div',
};
export default FGBLabel;
