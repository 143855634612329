import React from 'react';

import { useViewport } from '@fairygodboss/ui';
import { ModalContext } from '../../../../contexts/ContextCreator';

import FlowShell from '../FlowShell/FlowShell';
// eslint-disable-next-line import/no-cycle
import SelectTime from '../Steps/SelectTime/SelectTime';
import { ConfirmSuccessV2 } from '../Steps/ConfirmSuccessV2/ConfirmSuccessV2';
// eslint-disable-next-line import/no-cycle
import QuickProfile from '../Steps/QuickProfile/QuickProfile';
// eslint-disable-next-line import/no-cycle
import EventRequirementsInput from '../Steps/EventRequirementsInput/EventRequirementsInput';
// eslint-disable-next-line import/no-cycle
import { buildRegFlow } from './constants';
// eslint-disable-next-line import/no-cycle
import buttonNavBox from './navButtons';

export const eventRegistrationFlowType = 'event-registration-flow';

export const EventRegistrationFlow = () => {
    const { generalModalProps, closeModal } = React.useContext(ModalContext);
    const { isXS } = useViewport();

    const {
        event,
        startStep = '',
        requiredSteps = {},
        eventPreferredTimes = [],
        setUserAsRegistered,
        toggleJobProfileSnippetComplete,
        sourceType,
    } = generalModalProps;

    const initializeOptions = {
        event,
        startStep,
        requiredSteps,
        eventPreferredTimes,
        setUserAsRegistered,
        toggleJobProfileSnippetComplete,
        sourceType,
    };

    const { flowTree, initialStep, initialFlowData } = buildRegFlow(
        initializeOptions
    );

    const confirmSuccessStepProps = {
        mainText: 'You are registered for the event.',
        linkUrl: null,
        buttonText: 'Continue',
        subtext: 'We look forward to seeing you there!',
    };

    if (
        sourceType === 'vfairs' ||
        (sourceType === 'hopin vcf' &&
            event.eventType === 'Virtual Recruiting Event')
    ) {
        confirmSuccessStepProps.linkUrl =
            '/users/profile?LAC=vre-registration&LAT=vre-registration';
        confirmSuccessStepProps.buttonText = 'Go to your profile';
        confirmSuccessStepProps.subtext =
            'Tip: Add more details to your profile to really stand out.';
    }

    const externalAPIComponents = (
        <React.Fragment>
            <QuickProfile buttonNavBox={buttonNavBox} />
            <EventRequirementsInput />
            <SelectTime />
            <ConfirmSuccessV2
                {...confirmSuccessStepProps}
                sourceType={sourceType}
                event={event}
            />
        </React.Fragment>
    );

    const noRequirementsComponents = (
        <React.Fragment>
            <QuickProfile buttonNavBox={buttonNavBox} />
            <ConfirmSuccessV2 {...confirmSuccessStepProps} event={event} />
        </React.Fragment>
    );

    const regFlowComponents =
        sourceType === 'vfairs' || sourceType === 'hopin vcf'
            ? externalAPIComponents
            : noRequirementsComponents;

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={closeModal}
            className={!isXS ? 'flow-container-small' : ''}
        >
            {regFlowComponents}
        </FlowShell>
    );
};

EventRegistrationFlow.displayName = 'EventRegistrationFlow';

export default React.memo(EventRegistrationFlow);
