import PropTypes from 'prop-types';
import React from 'react';
import RatingSelectComponent from '../../../Form/RatingSelectComponent/RatingSelectComponent';
import FormCheckboxWithDescription from '../../Components/FormCheckboxWithDescription/FormCheckboxWithDescription';
import RatingShield from '../../Components/RatingShield/RatingShield';
import RatingWatch from '../../Components/RatingWatch/RatingWatch';
import RatingWing from '../../Components/RatingWing/RatingWing';
import './RatingsCard.scss';

export default class RatingsCard extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        //Whether it's RatingWing, RatingShield,  or RatingWatch
        ratingType: PropTypes.string,
        handleRating: PropTypes.func,
        handleCheck: PropTypes.func,
        descriptions: PropTypes.shape({
            low: PropTypes.string,
            mid: PropTypes.string,
            high: PropTypes.string,
        }),
        showCheckbox: PropTypes.bool,
        defaultChecked: PropTypes.bool,
    };

    static defaultProps = {
        descriptions: {
            low: 'Very Disatisfied',
            mid: 'Neutral',
            high: 'Very Satisfied',
        },
        showCheckbox: false,
    };

    selectRating = (rating) => {
        this.props.handleRating(rating);
    };

    handleCheckBox = (checkBox) => {
        this.props.handleCheck(checkBox);
    };

    render() {
        const components = {
            RatingWing,
            RatingShield,
            RatingWatch,
        };
        return (
            <div className="component-ReviewSurvey-QuestionCards-RatingsCard">
                <RatingSelectComponent
                    component={components[this.props.ratingType]}
                    onClick={this.selectRating}
                    selected={this.props.rating}
                />
                <div className="number-display">
                    <div className="number-box">
                        <div className="number">1</div>
                        <div className="description">{`${this.props.descriptions.low}`}</div>
                    </div>
                    <div className="number-box">
                        <div className="number">2</div>
                    </div>
                    <div className="number-box">
                        <div className="number">3</div>
                        <div className="description">{`${this.props.descriptions.mid}`}</div>
                    </div>
                    <div className="number-box">
                        <div className="number">4</div>
                    </div>
                    <div className="number-box">
                        <div className="number">5</div>
                        <div className="description">{`${this.props.descriptions.high}`}</div>
                    </div>
                </div>
                {this.props.showCheckbox ? (
                    <FormCheckboxWithDescription
                        onClick={this.handleCheckBox}
                        defaultChecked={this.props.defaultChecked}
                    />
                ) : null}
            </div>
        );
    }
}
