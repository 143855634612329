import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as CommunityConsumer } from '../../contexts/CommunityContext';
import { MessageConsumer } from '../../contexts/MessageContext';
import DrawerLayout from '../Layout/Header/Layout/DrawerLayout/DrawerLayout';
import './MessageDrawer.scss';
import Messages from './Messages/Messages';

export class MessageDrawer extends React.PureComponent {
    componentDidUpdate(preProp) {
        if (
            (preProp.currentChannel &&
                this.props.currentChannel &&
                preProp.currentChannel.key !== this.props.currentChannel.key) ||
            (preProp.currentChannel && !this.props.currentChannel)
        ) {
            this.onClose();
        }
    }

    static propTypes = {
        /**
         * enum of drawer type
         */
        drawerType: PropTypes.oneOf(['message', 'nav-bar']),
        /**
         * enum of overlay type
         */
        overlayType: PropTypes.oneOf(['transparent', 'dark']),
        /**
         * string of css class
         */
        overlayCssClass: PropTypes.string,
    };

    static defaultProps = {
        cssClass: '',
        drawerType: 'message',
        overlayType: 'transparent',
        overlayCssClass: 'messages-overlay',
    };

    onClose = () => {
        if (this.props.updateAuthorRelationsToUser)
            this.props.updateAuthorRelationsToUser();
        if (this.props.closeMessageDrawer) this.props.closeMessageDrawer();
    };

    render() {
        const {
            closeMessageDrawer,
            isLoggedInUser,
            isMessageDrawerOpen,
            currentChannel,
            chatClient,
            cssClass,
        } = this.props;
        if (!currentChannel) {
            return <div></div>;
        }

        const members = currentChannel.members || {};
        const remainingUser = members
            ? Object.keys(members).filter(
                  (userId) => userId !== chatClient.userID
              )
            : null;
        const { username: receiverUsername, credential, logo } =
            (members[remainingUser] && members[remainingUser].user) || {};

        return (
            <DrawerLayout
                cssClass={`message-drawer ${cssClass}`}
                profileLink={null}
                profileImage={logo}
                headerText={receiverUsername}
                userRole={credential}
                isDrawerOpen={isMessageDrawerOpen}
                handleClose={closeMessageDrawer}
                direction="bottom"
                drawerType={this.props.drawerType}
                overlayType={this.props.overlayType}
                overlayCssClass={this.props.overlayCssClass}
            >
                {isMessageDrawerOpen && (
                    <Messages
                        className="drawer-message-input"
                        isLoggedInUser={isLoggedInUser}
                        currentChannel={currentChannel}
                        enableHoverOver={false}
                        key={'message-drawer'}
                        isInDrawer={true}
                        notDisplayOverlay={true}
                    />
                )}
            </DrawerLayout>
        );
    }
}

export default (props) => {
    return (
        <CommunityConsumer>
            {({ updateAuthorRelationsToUser }) => (
                <MessageConsumer>
                    {({
                        closeMessageDrawer,
                        isLoggedInUser,
                        isMessageDrawerOpen,
                        getCurrentChannelInfo,
                        chatClient,
                    }) =>
                        props.isLoggedIn ? (
                            <MessageDrawer
                                {...props}
                                closeMessageDrawer={closeMessageDrawer}
                                isLoggedInUser={isLoggedInUser}
                                isMessageDrawerOpen={isMessageDrawerOpen}
                                currentChannel={getCurrentChannelInfo()}
                                chatClient={chatClient}
                                updateAuthorRelationsToUser={
                                    updateAuthorRelationsToUser
                                }
                            />
                        ) : null
                    }
                </MessageConsumer>
            )}
        </CommunityConsumer>
    );
};
