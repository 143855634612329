import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import FlexPopup from '../../Elements/FlexPopup/FlexPopup';
import InvitationWidget from '../InvitationWidget';

import './InvitationWidgetPopup.scss';

export default class InvitationWidgetPopup extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * whether modal is being displayed or not
         */
        showModal: PropTypes.bool,
        modalTriggerParameters: PropTypes.shape({
            modalName: PropTypes.string,
            modalData: PropTypes.object,
        }),
        message: PropTypes.string,
    };

    static defaultProps = {
        showModal: false,
    };

    render() {
        const {
            showModal,
            type,
            group,
            user,
            onSuccess,
            onCloseRedirectUrl,
            modalTriggerParameters,
            message,
        } = this.props;
        const InviteWidgetProps = {
            type,
            group,
            user,
            onSuccess,
            onCloseRedirectUrl,
            modalTriggerParameters,
            message,
        };
        const modalTriggerModalName =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalName;
        const modalTriggerModalProps =
            this.props.modalTriggerParameters &&
            this.props.modalTriggerParameters.modalData;

        return (
            <PageConsumer>
                {({ modalTrigger }) => {
                    return (
                        <div className="component-InvitationWidget-InvitationWidgetPopup">
                            <FlexPopup
                                hidden={!showModal}
                                onClose={() =>
                                    modalTrigger(
                                        modalTriggerModalName,
                                        modalTriggerModalProps
                                    )
                                }
                            >
                                <aside className="controlled-width">
                                    <InvitationWidget
                                        {...InviteWidgetProps}
                                        isModal={true}
                                    />
                                </aside>
                            </FlexPopup>
                        </div>
                    );
                }}
            </PageConsumer>
        );
    }
}
