import PropTypes from 'prop-types';
import React from 'react';
import { GroupPropTypesShape, memberTypeProptype } from './GroupPropsShape';
import { jobOnboardProfilePropType } from './userProps';

export const JOB_PROPTYPES = {
    /**
     * hash key
     */
    hash: PropTypes.string,
    /**
     * compnay logo
     */
    companyLogo: PropTypes.shape({
        src: PropTypes.string,
    }),
    /**
     * Job Title
     */
    title: PropTypes.string,
    /**
     * Company
     */
    company: PropTypes.object,
    /**
     * review object, this includes rating and url
     */
    review: PropTypes.object,
    /**
     * location for job posting
     */
    locationName: PropTypes.string,
    /**
     * locations meta data
     */
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * location id
             */
            jobLocationId: PropTypes.string,
            /**
             * location name
             */
            locationName: PropTypes.string,
        })
    ),
    /**
     * link to the job
     */
    url: PropTypes.string,
    /**
     * apply url without the tracker
     */
    originalApplyUrl: PropTypes.string,
    /**
     * google analytic function
     */
    onClick: PropTypes.func,
    /**
     *  show message if job is expired
     */
    showExpired: PropTypes.bool,
};

export const DEFAULT_JOB_PROPS = {
    hash: '411d45f764d857715bdb2819228d058d',
    companyLogo: {
        src: '/v1513324784/production/companies/27513/logo.jpg',
    },
    title: 'IBM Consultant',
    company: 'IBM',
    review: {
        rating: '4.0',
        url: '/',
    },
    location: 'Somewhere over the rainbow, KS',
    url: '/',
    originalApplyUrl: '/',
    onClick: PropTypes.func,
    showExpired: PropTypes.bool,
};

export const DEFAULT_JOB_PROPS2 = {
    hash: '3f91b13040395037e0da7ac34e585393',
    companyLogo: {
        src: '/v1513324784/production/companies/27513/logo.jpg',
    },
    title: 'IBM Consultant',
    company: 'IBM',
    review: {
        rating: '4.0',
        url: '/',
    },
    location: 'Somewhere over the rainbow, KS',
    url: '/',
    originalApplyUrl: '/',
    onClick: PropTypes.func,
    showExpired: PropTypes.bool,
};

export const DEFAULT_JOB_PROPS3 = {
    hash: 'b07f94a3471f5f0665452e49c8049818',
    companyLogo: {
        src: '/v1513324784/production/companies/27513/logo.jpg',
    },
    title: 'IBM Consultant',
    company: 'IBM',
    review: {
        rating: '4.0',
        url: '/',
    },
    location: 'Somewhere over the rainbow, KS',
    url: '/',
    originalApplyUrl: '/',
    onClick: PropTypes.func,
    showExpired: PropTypes.bool,
};

export const jobOnboardingPropType = PropTypes.shape({
    /**
     * Whether user has completed job seeker onboarding or not
     */
    completed: PropTypes.bool,
    /**
     * list of job alerts
     */
    jobAlerts: PropTypes.array,
    /**
     * meta data of user profile for onboarding
     */
    profile: jobOnboardProfilePropType,
    /**
     * meta data related to group
     */
    group: PropTypes.shape({
        /**
         * meta data of group
         */
        group: GroupPropTypesShape,
        /**
         * user relationship with the group
         */
        userRelation: PropTypes.shape({
            /**
             * user relationship with the group, nullable
             */
            role: PropTypes.oneOfType([memberTypeProptype, PropTypes.number]),
        }),
    }),
});

export const jobDetailsPropType = PropTypes.shape({
    /**
     * id of job item
     */
    id: PropTypes.number,
    /**
     * id for single job quick apply request
     */
    jobRequestId: PropTypes.string,
    /**
     * job's slug
     */
    slug: PropTypes.string,
    /**
     * job title
     */
    title: PropTypes.string,
    /**
     * if user has applied to the job
     */
    hasUserApplied: PropTypes.bool,
    /**
     * if user has applied to any job of the company
     */
    hasUserApplyToCompany: PropTypes.bool,
    /**
     * url that route to company apply site
     */
    applyUrl: PropTypes.string,
    /**
     * name of job location
     */
    locationName: PropTypes.string,
    /**
     * markdown description that render as html
     */
    htmlDescription: PropTypes.string,
    /**
     * description, use as fallback if `htmlDescription` is unavailable
     */
    description: PropTypes.string,
    /**
     * data needed for social bar
     */
    reactionBar: PropTypes.object,
});
