import PropTypes from 'prop-types';
import React from 'react';
import ErrorLabel from '../../../../Elements/ErrorLabel/ErrorLabel';
import ThreeRowModalLayout from '../../../../Modals/Layout/ThreeRowModalLayout/ThreeRowModalLayout';

import './AdminOnboardingModalContent.scss';

export default class AdminOnboardingModalContent extends React.PureComponent {
    static propTypes = {
        /**
         * index of selected step user is on, start from 0
         */
        selectedStepIndex: PropTypes.number.isRequired,
        /**
         * handler for click on start button
         */
        handleStart: PropTypes.func.isRequired,
        /**
         * error message of api call
         */
        apiError: PropTypes.string,
        /**
         * handler for submitting form in modal
         */
        handleSubmitModalForm: PropTypes.func.isRequired,
        /**
         * descriptions of individual steps
         */
        stepDefinition: PropTypes.object.isRequired,
        keyword: PropTypes.string,
        location: PropTypes.object,
    };

    static defaultProps = {
        handleStart: () => {},
        apiError: null,
        handleSubmitModalForm: () => {},
        stepDefinition: {},
    };

    setRef = (node) => {
        this.currentComponent = node;
    };

    render() {
        const {
            selectedStepIndex,
            handleStart,
            apiError,
            handleSubmitModalForm,
            stepDefinition,
            group,
            adminGroup,
            setIsLoading,
        } = this.props;

        const StepComponent = stepDefinition.component;

        return (
            <ThreeRowModalLayout.Content className="component-Groups-AdminOnboarding-AdminOnboardingModal-AdminOnboardingModalContent">
                {!!StepComponent && (
                    <StepComponent
                        ref={this.setRef}
                        selectedStepIndex={selectedStepIndex}
                        handleStart={handleStart}
                        apiError={apiError}
                        group={group}
                        handleSubmitModalForm={handleSubmitModalForm}
                        adminGroup={adminGroup}
                        setIsLoading={setIsLoading}
                        userSearchKeyword={this.props.userSearchKeyword}
                        userSearchlocation={this.props.userSearchlocation}
                        userSearchPageNumber={this.props.userSearchPageNumber}
                        userSearchMessage={this.props.userSearchMessage}
                        handleJoinAdminGroup={this.props.handleJoinAdminGroup}
                    />
                )}
                <ErrorLabel error={apiError} />
            </ThreeRowModalLayout.Content>
        );
    }
}
