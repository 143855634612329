import React from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import { buildImagePath } from '../../../JobComponents/Company/Image/logo';
import ThreeRowModalLayout from '../../../Modals/Layout/ThreeRowModalLayout/ThreeRowModalLayout';
import CloseModalIcon from '../../../Modals/ModalParts/CloseModalIcon/CloseModalIcon';

import './VfairRegisterModal.scss';

export default class VfairRegisterModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.jobseekerProfileImgUrl = 'events/jobseeker_profile.svg';
    }

    emitClickMetric = () => {
        EmitMetric({
            misc_event_type: 'job-profile-modal-click',
            misc_event_count: 1,
        });
    };

    render() {
        return (
            <ThreeRowModalLayout className="component-Events-EventRegisterButton-VfairRegisterModal">
                <ThreeRowModalLayout.Top className="component-Events-EventRegisterButton-VfairRegisterModal__top">
                    <CloseModalIcon />
                </ThreeRowModalLayout.Top>

                <ThreeRowModalLayout.Content className="component-Events-EventRegisterButton-VfairRegisterModal__content">
                    <img
                        className="component-Events-EventRegisterButton-VfairRegisterModal__content__img"
                        alt="vcf register image"
                        src={buildImagePath(
                            this.jobseekerProfileImgUrl,
                            'default',
                            { default: '' }
                        )}
                    />
                    <h1 className="component-Events-EventRegisterButton-VfairRegisterModal__content__title">
                        Professional profile required!
                    </h1>
                    <p className="component-Events-EventRegisterButton-VfairRegisterModal__content__description">
                        To complete registration, companies attending this event
                        require your submission of a resume and profile.
                    </p>
                    <a
                        className="component-Events-EventRegisterButton-VfairRegisterModal__content__link"
                        href="/users/job-profile"
                        onClick={this.emitClickMetric}
                    >
                        Go to profile
                    </a>
                </ThreeRowModalLayout.Content>

                <ThreeRowModalLayout.Bottom />
            </ThreeRowModalLayout>
        );
    }
}
