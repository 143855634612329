import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../../../../Analytics/VisibilityPixel/VisibilityPixel';
import ArrowPagination from '../../../../Elements/ArrowPagination/ArrowPagination';
import Button from '../../../../Input/Button/Button';
import FormElementWithLabel from '../../../../Input/FormElementWithLabel/FormElementWithLabel';
import LocationInput from '../../../../Input/LocationInput/LocationInput';
import UserListItem from '../../../../Users/UserListItem/UserListItem';

import './GroupsAdminOnboardingUserSearch.scss';

export const USER_SEARCH_API = '/api/user/search';
export default class GroupsAdminOnboardingUserSearch extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            startNum: 0,
            endNum: 0,
            page: props.userSearchPageNumber || 1,
            keyword: props.userSearchKeyword || '',
            location: props.userSearchlocation || '',
            suggestedUsers: null,
            startedSearch: false,
        };
        this.keywordSearchTimeout = null;
    }

    static propTypes = {
        /**
         * group prop for group
         */
        group: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }).isRequired,
        userSearchPageNumber: PropTypes.number,
        userSearchKeyword: PropTypes.string,
        userSearchlocation: PropTypes.object,
        userSearchMessage: PropTypes.string,
    };

    static defaultProps = {
        /**
         * group prop for group
         */
        // group: {
        //     logo: {
        //         src: "/groups/group_logos/oghr3waojoacdn7ka1pj",
        //         alt: "/",
        //     },
        //     name:"Fake Group",
        //     id: 4,
        //     hash: "SJJjsXmNB" ,
        //     description: "This is a description",
        // }
    };

    componentDidMount() {
        if (this.props.userSearchKeyword || this.props.userSearchlocation) {
            this.getSuggestions(this.props.userSearchPageNumber);
        }
    }

    handleChange = (type, value) => {
        this.setState({
            [type]: value,
        });
        clearTimeout(this.keywordSearchTimeout);
        this.keywordSearchTimeout = setTimeout(
            () => this.getSuggestions(this.state.page),
            500
        );
    };

    getSuggestions = async (page) => {
        const SearchObject = {
            resultsPerPage: 5,
            page,
            keyword: this.hasKeyword() ? this.state.keyword : null,
            location: this.hasLocation()
                ? {
                      name: this.state.location && this.state.location.value,
                      latitude:
                          this.state.location.coordinates &&
                          this.state.location.coordinates.lat,
                      longitude:
                          this.state.location.coordinates &&
                          this.state.location.coordinates.lng,
                  }
                : null,
        };

        if (this.hasKeyword() || this.hasLocation()) {
            try {
                const result = await axios.post(USER_SEARCH_API, SearchObject);
                const data = result && result.data && result.data.data;
                let suggestedUsers = data && data.results;
                if (
                    !(this.state.keyword && this.state.keyword.length) &&
                    !(
                        this.state.location &&
                        this.state.location.value &&
                        this.state.location.value.length
                    )
                ) {
                    suggestedUsers = [];
                }

                EmitMetric({
                    misc_event_type: 'admin-onboarding-search',
                    misc_event_count: 1,
                });

                this.setState({
                    suggestedUsers,
                    startedSearch: true,
                    endNum: data.endNum,
                    startNum: data.startNum,
                    total: data.total,
                    page,
                });
            } catch (e) {
                return console.error(e);
            }
        } else {
            this.setState({
                suggestedUsers: null,
                startedSearch: false,
            });
        }
    };

    hasKeyword = () => {
        return !!(this.state.keyword && this.state.keyword.length);
    };

    hasLocation = () => {
        return !!(
            this.state.location &&
            this.state.location.value &&
            this.state.location.value.length &&
            this.state.location.coordinates.lat &&
            this.state.location.coordinates.lng
        );
    };

    onReset = () => {
        this.setState({
            page: 1,
            keyword: '',
            location: '',
            suggestedUsers: null,
            startedSearch: false,
        });
    };

    loadUsers = () => {
        const modalTriggerParameters = {
            modalName: 'adminOnboarding',
            modalData: {
                selectedStepIndex: 1,
                adminGroup: this.props.adminGroup,
                userSearchKeyword: this.state.keyword,
                userSearchlocation: this.state.location,
                userSearchPageNumber: this.state.page,
            },
        };
        switch (true) {
            case this.state.startedSearch &&
                this.state.suggestedUsers &&
                this.state.suggestedUsers.length >= 1:
                return this.state.suggestedUsers.map((suggestedUser, idx) => {
                    if (suggestedUser) {
                        const removeBottomBorder =
                            idx === this.state.suggestedUsers.length - 1
                                ? 'no-bottom-border'
                                : '';
                        return (
                            <UserListItem
                                openLinksInNewTab={true}
                                key={`${idx}-${suggestedUser.userId}`}
                                user={suggestedUser}
                                buttonsToRender={['INVITE_TO_GROUP_SOLID']}
                                className={removeBottomBorder}
                                group={this.props.group}
                                onClickHandlers={() => {}}
                                modalTriggerParameters={modalTriggerParameters}
                                userSearchMessage={this.props.userSearchMessage}
                            />
                        );
                    }
                });
            case this.state.startedSearch &&
                this.state.suggestedUsers &&
                this.state.suggestedUsers.length === 0:
                return (
                    <p className="zero-result-message">
                        0 results. Try broadening your search for more results.
                    </p>
                );
            default:
                return (
                    <p className="intro-message">
                        Find members by keywords in their profiles.
                    </p>
                );
        }
    };

    loadPagination = () => {
        const paginationCss =
            this.state.total <= 5 ? 'no-pagination-arrows' : 'has-pagination';
        return (
            <ArrowPagination
                numberRange={this.getNumberRange()}
                total={this.state.total}
                prevPage={this.handleGoPrevious()}
                nextPage={this.handleGoNext()}
                hideArrowIfNoPagination={true}
                className={paginationCss}
            />
        );
    };

    getNumberRange = () => {
        const { startNum, endNum } = this.state;
        const zeroPagination = '0 - 0';

        return startNum && endNum ? `${startNum} - ${endNum}` : zeroPagination;
    };

    handleGoPrevious = () => {
        const { page } = this.state;
        const fetchPreviousPage = () => this.getSuggestions(page - 1);
        return page > 1 ? fetchPreviousPage : null;
    };

    handleGoNext = () => {
        const { total, page } = this.state;
        const canGoNext = page * 5 < total;
        const fetchNextPage = () => this.getSuggestions(page + 1);
        return canGoNext ? fetchNextPage : null;
    };

    render() {
        const KeywordsLabelProps = {
            label: 'Search by keywords:',
            shouldShowTooltip: false,
        };

        const LocationLabelProps = {
            label: 'Search by location:',
            shouldShowTooltip: false,
        };

        return (
            <div className="component-Groups-AdminOnboarding-Steps-GroupsAdminOnboardingUserSearch">
                <form onReset={this.onReset}>
                    <FormElementWithLabel fieldLabelProps={KeywordsLabelProps}>
                        <input
                            className="keyword-input"
                            value={this.state.keyword}
                            onChange={(e) =>
                                this.handleChange('keyword', e.target.value)
                            }
                            placeholder={'Sales and marketing'}
                        />
                    </FormElementWithLabel>
                    <FormElementWithLabel fieldLabelProps={LocationLabelProps}>
                        <LocationInput
                            onChange={(value) =>
                                this.handleChange('location', value)
                            }
                            value={
                                this.state.location &&
                                typeof this.state.location === 'object' &&
                                this.state.location !== null
                                    ? this.state.location.value
                                    : this.state.location
                            }
                        />
                    </FormElementWithLabel>
                    <div className="button-div">
                        <Button
                            type={'secondary-blue-gray'}
                            value={'Clear'}
                            formAction={'reset'}
                            className={'reset-button'}
                        />
                    </div>
                </form>
                <section>
                    {this.loadUsers()}
                    {this.state.suggestedUsers &&
                        this.state.suggestedUsers.length >= 1 &&
                        this.loadPagination()}
                </section>
            </div>
        );
    }
}
