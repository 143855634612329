import PropTypes from 'prop-types';
import React from 'react';
import { getCloudinaryImagePathById } from '../../../../utilities/images';
import Button from '../../../Input/Button/Button';

import './LoginImageContainer.scss';

const displayAtBothBreakPoints = 'd-block d-md-block';
const hideAtDesktopBreakPoint = 'd-block d-md-none';

export default class LoginImageContainer extends React.PureComponent {
    renderCloseBtn = () => {
        const closeImageUrl = getCloudinaryImagePathById(
            '/v1/Close_X_sw0ud2.png',
            'customStyle',
            { customStyle: 'c_scale,f_auto,q_auto,w_22,h_22' }
        );
        const { cmsPreview, allowClosing } = this.props;
        const closeBtnCss = cmsPreview
            ? displayAtBothBreakPoints
            : hideAtDesktopBreakPoint;

        return cmsPreview || allowClosing ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
                className={`close ${closeBtnCss}`}
                src={closeImageUrl}
                onClick={this.props.closeLoginWall}
                alt="close"
                onKeyPress={() => null}
            />
        ) : null;
    };

    handleClick = () => {
        const { cmsPreview, clickThroughUrl, openLoginWidget } = this.props;

        if (cmsPreview && clickThroughUrl) {
            window.location.href = clickThroughUrl;
        } else {
            openLoginWidget();
        }
    };

    render() {
        const bgImageUrl = this.props.image
            ? getCloudinaryImagePathById(this.props.image, 'customStyle', {
                  customStyle: 'ar_4:3,c_crop/c_fit,w_400',
              })
            : '';
        const { title, description, buttonText, cmsPreview } = this.props;
        const buttonCss = cmsPreview
            ? displayAtBothBreakPoints
            : hideAtDesktopBreakPoint;

        return (
            <div className="component-Login-LoginWall-LoginImageContainer">
                <div className="wall-background">
                    <div
                        className="bg-image"
                        style={{ backgroundImage: `url(${bgImageUrl})` }}
                    />
                    <div className="bg-color" />
                </div>
                <div className="wall-content">
                    {this.renderCloseBtn()}
                    <div className="msg-box">
                        <div className="title">{title}</div>
                        <div className="description">{description}</div>
                    </div>
                    <Button
                        type="purple-medium-white"
                        value={buttonText}
                        onClick={this.handleClick}
                        class={`wall-button ${buttonCss}`}
                        inputStyle="inputStyle"
                        qaAttr={`${buttonText}-btn`}
                    />
                </div>
            </div>
        );
    }
}

LoginImageContainer.defaultProps = {
    buttonText: 'Get Started!',
    openLogin: () => {},
    closeLoginWall: () => {},
    cmsPreview: false,
    title: '',
    description: '',
    image: '',
    allowClosing: false,
    clickThroughUrl: '',
    openLoginWidget: () => null,
};

LoginImageContainer.propTypes = {
    /**
     *  title inside popup
     */
    title: PropTypes.string,
    /**
     *  description inside popup
     */
    description: PropTypes.string,
    /**
     *  cloudinary id to be used background image of the top area
     *  ex) '/v1533833875/production/employer/21610/header-1533833875.jpg'
     */
    image: PropTypes.string,
    /**
     *  text for the button
     */
    buttonText: PropTypes.string,
    /**
     *  onclick function fired when button is clicked
     */
    // eslint-disable-next-line react/no-unused-prop-types
    openLogin: PropTypes.func,
    /**
     *  calllback function to close the Login Wall Widget
     */
    closeLoginWall: PropTypes.func,
    /**
     *  true: show the close-button, false: do not show the close-button
     */
    allowClosing: PropTypes.bool,
    /**
     *  a special purpose view for CMS
     *  true: show the mobile version, which displays close & button.
     *  false: show the normal version, which displays up to breakpoints.
     */
    cmsPreview: PropTypes.bool,
    /**
     *  a url to redirect
     */
    clickThroughUrl: PropTypes.string,
    openLoginWidget: PropTypes.func,
};
