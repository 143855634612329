import PropTypes from 'prop-types';
import React from 'react';
import ButtonWithChildren from '../../../../Input/ButtonWithChildren/ButtonWithChildren';
import { buildImagePath } from '../../../../JobComponents/Company/Image/logo';

import './GoogleButton.scss';

export default class GoogleButton extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * Google button handler
         */
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const iconSrc = buildImagePath(
            '/v1541017251/Login/google.png',
            'button'
        );
        return (
            <div className="component-Login-LoginLayoutComponent-SocialMediaButtons-GoogleButton">
                <ButtonWithChildren
                    onClick={() => {
                        this.props.onClick('google');
                    }}
                >
                    <div className="button-content-container">
                        <img src={iconSrc} />
                        <div className="call-to-action-button-text">
                            Continue with Google
                        </div>
                    </div>
                </ButtonWithChildren>
            </div>
        );
    }
}
