import { PAGE_DISPLAY_MODE } from '../../../pages/constants';
import {
    FOOTER_TYPES,
    fullFooterMenu,
    userDesktopFooterDefaultMenuKeys,
    userDrawerFooterDefaultMenuKeys,
    userLeftRailFooterDefaultMenuKeys,
    employerDesktopFooterDefaultMenuKeys,
    employerDrawerFooterDefaultMenuKeys,
    employerLeftRailFooterDefaultMenuKeys,
} from './constants';

export const getFooterMenu = (pageDisplayMode, footerType) => {
    const footerMenuItemKeys = getRequiredFooterMenuItemKeys(
        pageDisplayMode,
        footerType
    );
    const footerMenu = constructFooterMenuWithKeys(
        footerMenuItemKeys,
        fullFooterMenu
    );

    return footerMenu;
};

export const getRequiredFooterMenuItemKeys = (pageDisplayMode, footerType) => {
    if (pageDisplayMode === PAGE_DISPLAY_MODE.USER_DEFAULT) {
        if (footerType === FOOTER_TYPES.DESKTOP_FOOTER)
            return userDesktopFooterDefaultMenuKeys;
        if (footerType === FOOTER_TYPES.DRAWER_FOOTER)
            return userDrawerFooterDefaultMenuKeys;
        if (footerType === FOOTER_TYPES.LEFT_RAIL_FOOTER)
            return userLeftRailFooterDefaultMenuKeys;
    }

    if (pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT) {
        if (footerType === FOOTER_TYPES.DESKTOP_FOOTER)
            return employerDesktopFooterDefaultMenuKeys;
        if (footerType === FOOTER_TYPES.DRAWER_FOOTER)
            return employerDrawerFooterDefaultMenuKeys;
        if (footerType === FOOTER_TYPES.LEFT_RAIL_FOOTER)
            return employerLeftRailFooterDefaultMenuKeys;
    }

    return [];
};

export const constructFooterMenuWithKeys = (
    footerMenuItemKeys = [],
    footerMenu = fullFooterMenu
) => {
    if (!Array.isArray(footerMenuItemKeys)) {
        return [];
    }

    const constructedUserMenu = footerMenuItemKeys.map((footerMenuItemKey) => {
        const menuItem = footerMenu.filter(
            (filteredMenuItem) => filteredMenuItem.key === footerMenuItemKey
        );

        return menuItem.pop();
    });

    return constructedUserMenu;
};
