import PropTypes from 'prop-types';
import React from 'react';
import { IMAGE_CDN_URL } from '../../../utilities/imageCDN';

import './SVGFromCloudinary.scss';

const DIGIT_REGEX = /\/?v[0-9]*\//;

export default class SVGFromCloudinary extends React.PureComponent {
    render() {
        const baseCls = 'component-Elements-SVGFromCloudinary';
        const transforms = this.props.transformString || '';
        const imageSize = this.props.size;
        const src = `${IMAGE_CDN_URL}${transforms}/v1${this.props.cloudinaryID.replace(
            DIGIT_REGEX,
            '/'
        )}`;
        const component = imageSize ? (
            <img
                className={`${baseCls} ${this.props.className}`}
                src={src}
                alt={this.props.alt}
                width={imageSize.width}
                height={imageSize.height}
            />
        ) : (
            <img
                className={`${baseCls} ${this.props.className}`}
                src={src}
                alt={this.props.alt}
            />
        );

        return component;
    }
}

SVGFromCloudinary.defaultProps = {
    alt: '',
    transformString: '',
    className: '',
    size: undefined,
};

SVGFromCloudinary.propTypes = {
    /**
     * Image Source (Cloudinary Id)
     * */
    cloudinaryID: PropTypes.string.isRequired,
    className: PropTypes.string,
    /**
     * Image Alt
     * */
    alt: PropTypes.string,
    transformString: PropTypes.string,
    size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};
