import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../Content/User/UserDisplay/UserImage/UserImage';
// components
import ErrorLabel from '../Elements/ErrorLabel/ErrorLabel';
import Button from '../Input/Button/Button';
import InputField from '../Input/InputField/InputField';
import Overlay from '../Overlay/Overlay';
// styling
import './ConfirmationPopup.scss';

export default class ConfirmationPopup extends React.PureComponent {
    static propTypes = {
        /**
         * array of objects with button meta
         */
        buttonMetas: PropTypes.arrayOf(
            PropTypes.shape({
                onClickHandler: PropTypes.func,
                buttonText: PropTypes.string.isRequired,
                cssClass: PropTypes.string,
                redirectUrl: PropTypes.string,
                buttonType: PropTypes.oneOf([
                    'purple-medium-white',
                    'tertiary-blue-gray',
                    'secondary-blue-gray',
                ]).isRequired,
            })
        ),
        /**
         * custom css to style popup
         */
        cssClass: PropTypes.string,
        /**
         * heading text, can be more than one line
         */
        heading: PropTypes.string,
        /**
         * image above heading + subheading
         */
        imageSrc: PropTypes.string,
        /**
         * function for input change
         */
        inputOnChange: PropTypes.func,
        /**
         * function to close confirmation popup
         */
        onClose: PropTypes.func.isRequired,
        /**
         * subheading text, can be more than one line
         */
        subheading: PropTypes.string,
    };

    static defaultProps = {
        buttonMetas: [],
        cssClass: '',
        heading: 'Success!',
        imageSrc: 'v1560193582/Groups/confirmation-icon.svg',
        subheading: '',
    };

    handleButtonClick = (onClickHandler, redirectUrl) => (event) => {
        if (!redirectUrl) {
            event.preventDefault();
        }

        if (onClickHandler) {
            onClickHandler();
            return;
        }

        this.props.onClose();
    };

    renderButton = ({
        onClickHandler,
        buttonText,
        redirectUrl,
        buttonType,
        cssClass = '',
    }) => {
        return (
            <a
                key={buttonText}
                href={redirectUrl}
                onClick={this.handleButtonClick(onClickHandler, redirectUrl)}
            >
                <Button
                    type={buttonType}
                    class={`popup-button ${cssClass}`}
                    value={buttonText}
                />
            </a>
        );
    };

    render() {
        const {
            buttonMetas,
            cssClass,
            heading,
            imageSrc,
            inputOnChange,
            onClose,
            subheading,
        } = this.props;

        return (
            <div className={`component-ConfirmationPopup ${cssClass}`}>
                <Overlay handleClose={onClose}>
                    <div className="modal-scroll">
                        <div className="popup-container">
                            <div
                                className="popup-content flex-align-justify-center"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <img src={buildImagePath(imageSrc, 'xLarge')} />
                                <span className="heading flex-align-justify-center">
                                    {heading}
                                </span>
                                <span className="subheading flex-align-justify-center">
                                    {subheading}
                                </span>

                                {inputOnChange && (
                                    <InputField
                                        classname="popup-input"
                                        onChange={inputOnChange}
                                    />
                                )}
                                <ErrorLabel error={this.props.inputError} />

                                <div className="button-row">
                                    {buttonMetas.map(this.renderButton)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>
            </div>
        );
    }
}
