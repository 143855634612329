import PropTypes from 'prop-types';
import React from 'react';

import './ErrorLabel.scss';

export default class ErrorLabel extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * string of error
         */
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /**
         * string of css class
         */
        cssClass: PropTypes.string,
    };

    static defaultProps = {
        error: '',
        cssClass: '',
    };

    render() {
        return (
            <div
                className={`component-Elements-ErrorLabel ${this.props.cssClass}`}
            >
                {this.props.error}
            </div>
        );
    }
}
