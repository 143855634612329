import PropTypes from 'prop-types';
import React from 'react';

import './TopBannerController.scss';
import { GroupConsumer } from '../../../contexts/GroupContext';
import { JobContextConsumer } from '../../../contexts/JobContext';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import Pixel, {
    EmitMetric,
} from '../../Analytics/VisibilityPixel/VisibilityPixel';
import AnnouncementTopBanner from '../../Elements/AnnouncementTopBanner/AnnouncementTopBanner';
import { groupActions } from '../../Includes/GroupActionTypes';
import { PAGE_DISPLAY_MODE } from '../../../pages/constants';
import TopBannerProfileCta from '../../CallToAction/OnSiteProfileCTAs/TopBannerProfileCta/TopBannerProfileCta';

export class TopBannerController extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            adminGroup: props.adminOnboardingProps
                ? props.adminOnboardingProps.adminGroup
                : {},
            topProfileBannerMetrics: { impressionMetric: '', clickMetric: '' },
        };
    }

    static propTypes = {
        /**
         * feature flags
         */
        featureFlags: PropTypes.object,
        /**
         * check if current user is logged in
         */
        isLoggedIn: PropTypes.func,
        /**
         * whether community onboarding is completed or not
         */
        communityOnboardingCompleted: PropTypes.bool,
        /**
         * list of existing top banner that should be excluded
         */
        topBannerExclusionList: PropTypes.arrayOf(
            PropTypes.oneOf(['community-onboard'])
        ),
        /**
         * list of existing top banner that should be only shown in
         */
        topBannerOnlyShownList: PropTypes.arrayOf(
            PropTypes.oneOf([
                'profile-cta',
                'admin-onboard',
                'job-seeker-profile',
                'ann-taylor-community',
            ])
        ),
        /**
         * cta for triggering community onboarding
         */
        onboardingCallToActionCallbackFunction: PropTypes.func,
        /**
         * handler for managing modal
         */
        modalTrigger: PropTypes.func,
        /**
         * props required by admin onboarding banner
         */
        adminOnboardingProps: PropTypes.object,
        /**
         * check if user has permission for group's action
         */
        hasPermission: PropTypes.func,
        /**
         * group's meta data
         */
        group: PropTypes.object,
        /**
         * snippet data of user job profile
         */
        jobProfileSnippet: PropTypes.object,
    };

    static defaultProps = {
        featureFlags: {},
        topBannerExclusionList: [],
        checkIsBannerOnlyShownIn: [],
        isLoggedIn: () => {},
        communityOnboardingCompleted: true,
        onboardingCallToActionCallbackFunction: () => {},
        modalTrigger: () => {},
        group: {},
        hasPermission: () => {},
        adminOnboardingProps: {},
        jobProfileSnippet: {},
    };

    componentDidMount() {
        if (this.showProfileCta() && this.props.featureFlags.onSiteCTA) {
            this.setState({
                topProfileBannerMetrics: this.getTopBannerProfileMetrics(),
            });
        }
    }

    /**
     * check if the banner is in exclusion list
     * @param {string} bannerKey
     * @param {boolean} [featureFlag=true]
     * @returns {boolean}
     */
    checkIsBannerExcluded = (bannerKey) =>
        Array.isArray(this.props.topBannerExclusionList) &&
        !this.props.topBannerExclusionList.includes(bannerKey);

    /**
     * check if the banner is in only inclusion list
     * @param {string} bannerKey
     * @param {boolean} [featureFlag=true]
     * @returns {boolean}
     */
    checkIsBannerOnlyShownIn = (bannerKey) =>
        Array.isArray(this.props.topBannerOnlyShownList) &&
        this.props.topBannerOnlyShownList.includes(bannerKey);

    /**
     * `admin-onboard` should be mounted when condition is satisfied
     * 1. banner key is in `topBannerOnlyShownList`
     * 2. showBanner is true
     * 3. user has permission of group admin
     */
    showAdminOnboarding = () =>
        this.checkIsBannerOnlyShownIn('admin-onboard') &&
        this.props.adminOnboardingProps &&
        this.props.adminOnboardingProps.showBanner &&
        this.props.hasPermission(groupActions.SHOW_ADMIN_ONBOARDING);

    /**
     * `profile-cta` should be mounted when condition is satisfied
     * 1. banner key is in `topBannerOnlyShownList`
     * 2. jobProfileSnippet.isCompleted is falsey
     */
    showProfileCta = () =>
        this.checkIsBannerOnlyShownIn('profile-cta') &&
        this.props.jobProfileSnippet &&
        !this.props.jobProfileSnippet.isCompleted;

    handleJobSeekrProfileClick = () => {
        EmitMetric({
            misc_event_type: 'professional-profile-banner-click',
            misc_event_count: 1,
        });
    };

    handleCommunityOnboardingClick = () => {
        const { onboardingCallToActionCallbackFunction } = this.props;
        EmitMetric({
            misc_event_type: 'community-onboarding-banner-get-started-click',
            misc_event_count: 1,
        });
        onboardingCallToActionCallbackFunction();
    };

    handleAdminOnboardingClick = () => {
        const { adminGroup } = this.state;
        const { modalTrigger, group } = this.props;

        EmitMetric({
            misc_event_type: 'admin-onboarding-banner-start-click',
            misc_event_count: 1,
        });

        modalTrigger('adminOnboarding', {
            group,
            adminGroup,
            handleJoinAdminGroup: this.handleJoinAdminGroup,
        });
    };

    handleJoinAdminGroup = () => {
        const { adminGroup } = this.state;
        const { modalTrigger } = this.props;

        if (
            adminGroup &&
            adminGroup.userRelation &&
            !adminGroup.userRelation.role
        ) {
            const newAdminGroup = {
                group: { ...this.state.adminGroup.group },
                userRelation: { role: 'requested' },
            };
            this.setState({ adminGroup: newAdminGroup });
            modalTrigger('adminOnboarding', {
                selectedStepIndex: 5,
                adminGroup: newAdminGroup,
            });
        }
    };

    getTopBannerProfileMetrics = () => {
        if (!(window && window.location && window.location.pathname)) {
            return {
                impressionMetric: '',
                clickMetric: '',
            };
        }
        const pathName = window.location.pathname;
        let impressionMetric = '';
        let clickMetric = '';

        switch (true) {
            case pathName.startsWith('/jobs/filter'): //JObs Landing
                impressionMetric =
                    'jobs-landing-top-banner-profile-cta-impression';
                clickMetric = 'jobs-landing-top-banner-profile-cta-click';
                break;
            case pathName.startsWith('/jobs/find'): //JObs Results
                impressionMetric =
                    'jobs-results-top-banner-profile-cta-impression';
                clickMetric = 'jobs-results-top-banner-profile-cta-click';
                break;
            case pathName.startsWith('/company/jobs/'): //Company Jobs
                impressionMetric =
                    'company-profile-jobs-top-banner-profile-cta-impression';
                clickMetric =
                    'company-profile-jobs-top-banner-profile-cta-click';
                break;
            case pathName.startsWith('/company-reviews/'): //Company Reviews
                impressionMetric =
                    'company-profile-reviews-top-banner-profile-cta-impression';
                clickMetric =
                    'company-profile-reviews-top-banner-profile-cta-click';
                break;
            case pathName.startsWith('/community/feed'): //
                impressionMetric =
                    'community-feed-top-banner-profile-cta-impression';
                clickMetric = 'community-feed-top-banner-profile-cta-click';
                break;
            case pathName.startsWith('/community-'):
            case pathName.startsWith('/articles/'): //
            case pathName.startsWith('/career-topics/'): //
                impressionMetric =
                    'single-content-top-banner-profile-cta-impression';
                clickMetric = 'single-content-top-banner-profile-cta-click';
                break;
            default:
                impressionMetric = '';
                clickMetric = '';
                console.error('No component found for given type:' + pathName);
        }

        return {
            impressionMetric,
            clickMetric,
        };
    };
    render() {
        if (this.showProfileCta() && this.props.featureFlags.onSiteCTA) {
            return (
                <TopBannerProfileCta
                    LAT={'top-banner'}
                    LAC={'top-banner'}
                    {...this.state.topProfileBannerMetrics}
                    className="profile-cta-banner"
                />
            );
        }

        if (this.showAdminOnboarding()) {
            return (
                <React.Fragment>
                    <AnnouncementTopBanner
                        annoucementText="Grow your groups!"
                        ctaButton={{
                            text: 'Get started. >',
                            handleClick: this.handleAdminOnboardingClick,
                        }}
                    />
                    <Pixel
                        metrics={{
                            misc_event_type:
                                'admin-onboarding-banner-impression',
                            misc_event_count: 1,
                        }}
                    />
                </React.Fragment>
            );
        }

        return null;
    }
}

export default (props) => (
    <PageConsumer>
        {({
            pageProps,
            isLoggedIn,
            communityOnboardingCompleted,
            onboardingCallToActionCallbackFunction,
            modalTrigger,
        }) => (
            <GroupConsumer>
                {({ group, hasPermission }) => (
                    <JobContextConsumer>
                        {({ isJobProfileIncomplete }) => (
                            <TopBannerController
                                {...props}
                                featureFlags={
                                    pageProps && pageProps.featureFlags
                                }
                                jobProfileSnippet={
                                    pageProps &&
                                    pageProps.headerProps &&
                                    pageProps.headerProps.jobProfileSnippet
                                }
                                isLoggedIn={isLoggedIn}
                                communityOnboardingCompleted={
                                    communityOnboardingCompleted
                                }
                                onboardingCallToActionCallbackFunction={
                                    onboardingCallToActionCallbackFunction
                                }
                                modalTrigger={modalTrigger}
                                hasPermission={hasPermission}
                                group={group}
                                isJobProfileIncomplete={isJobProfileIncomplete}
                            />
                        )}
                    </JobContextConsumer>
                )}
            </GroupConsumer>
        )}
    </PageConsumer>
);
