export const eventEndPointBaseUrl = '/events?';

export const registrationSourceTypes = {
    ZOOM: 'zoom',
    HOPIN: 'hopin vcf',
};

export const eventTypes = {
    WEBINAR: 'Webinar',
    VIRTUAL_RECRUITING_EVENT: 'Virtual Recruiting Event',
    FACEBOOK_LIVE: 'Facebook Live',
    PARTNER_EVENT: 'Partner Event',
};

export const eventStatusTypes = {
    NOT_STARTED: 'NOT_STARTED',
    STARTING_SOON: 'STARTING_SOON',
    IN_PROGRESS: 'IN_PROGRESS',
    EVENT_OVER: 'EVENT_OVER',
};

export const userEventStatusTypes = {
    USER_NOT_REGISTERED: 'USER_NOT_REGISTERED',
    USER_REGISTERED: 'USER_REGISTERED',
    NOT_REGISTERED_INPROGRESS: 'NOT_REGISTERED_INPROGRESS',
    USER_REGISTERED_EVENT_OPEN: 'USER_REGISTERED_EVENT_OPEN',
    REGISTERED_IN_PROGRESS: 'REGISTERED_IN_PROGRESS',
    REGISTERED_STARTING_SOON: 'REGISTERED_STARTING_SOON',
    NOT_REGISTERED_STARTING_SOON: 'NOT_REGISTERED_STARTING_SOON',
    REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',
    EVENT_OVER: 'EVENT_OVER',
    DEACTIVE: 'DEACTIVE',
};

export const dropdownEventsTypeList = Object.keys(eventTypes).map((k) => ({
    text: eventTypes[k],
    queryParams: [
        {
            queryName: 'eventType',
            queryValue: eventTypes[k],
        },
    ],
}));

export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
