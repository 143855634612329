import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../Input/Button/Button';
import InputField from '../../../../Input/InputField/InputField';
import { CREATE_LAYOUT } from '../LogInLayout/constants';
import './EmailLoginForm.scss';

class EmailLoginForm extends React.PureComponent {
    static onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onClick();
        }
    };

    render() {
        let buttonDisabled = !this.props.loaded;
        if (this.props.showUsernameField) {
            const nameFieldIsValid = this.props.name && this.props.name.length;
            buttonDisabled = !(
                this.props.email &&
                this.props.email.length &&
                this.props.password &&
                this.props.password.length &&
                this.props.loaded &&
                nameFieldIsValid
            );
        } else if (
            !(
                this.props.email &&
                this.props.email.length &&
                this.props.password &&
                this.props.password.length &&
                this.props.loaded
            )
        ) {
            buttonDisabled = true;
        }
        const buttonText = !this.props.loaded ? 'Processing' : 'Submit';
        const showNameField = this.props.location === CREATE_LAYOUT;
        const buttonClass =
            showNameField && this.props.passwordError
                ? 'actionButtonError'
                : 'actionButton';

        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-EmailLoginForm">
                <div className="input-section email-login-input-section email-field">
                    <div className="form-label">
                        Email{' '}
                        <span className="errorMessage ml-1">
                            {this.props.emailError}
                        </span>
                    </div>
                    <InputField
                        name="email-input-reset-password"
                        qaDataAttr="email"
                        onChange={this.props.handleEmailInput}
                        onBlur={this.props.handleEmailInputBlur}
                        value={this.props.email}
                    />
                </div>
                {showNameField && (
                    <div className="input-section username-login-input-section username-field">
                        <div className="form-label">
                            Your name{' '}
                            <span className="errorMessage ml-1">
                                {this.props.nameError}
                            </span>
                        </div>
                        <InputField
                            type="text"
                            name="username-input-reset-password"
                            qaDataAttr="name"
                            onChange={this.props.handleNameInput}
                            onBlur={this.props.handleNameInputBlur}
                            value={this.props.name}
                        />
                    </div>
                )}
                <div className="input-section passowrd-login-input-section password-field">
                    <div className="form-label">Password </div>
                    {this.props.location === CREATE_LAYOUT && (
                        <div className="password-info">
                            Password must contain at least 8 characters, 1
                            uppercase and lowercase letter, 1 number, and 1
                            special character
                        </div>
                    )}
                    <InputField
                        type="password"
                        name="email-input-reset-password"
                        qaDataAttr="password"
                        onKeyPress={this.onKeyPress}
                        onChange={this.props.handlePasswordInput}
                        onBlur={this.props.handlePasswordInputBlur}
                        value={this.props.password}
                    />
                    {this.props.location !== CREATE_LAYOUT && (
                        <span className="errorMessage ">
                            {this.props.passwordError}
                        </span>
                    )}
                    {this.props.location === CREATE_LAYOUT &&
                        this.props.passwordError && (
                            <div className="errorMessage-createLayout">
                                <ul>
                                    {this.props.passwordError.map((error) => (
                                        <li>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                </div>
                <div className="errorMessage">{this.props.generalError}</div>
                {this.props.location !== CREATE_LAYOUT &&
                !this.props.emailError &&
                !this.props.passwordError &&
                !this.props.generalError ? (
                    <div className="actionButton">
                        <Button
                            type="solid"
                            qaAttr={buttonText}
                            value={buttonText}
                            disabled={buttonDisabled}
                            onClick={this.props.onClick}
                        />
                    </div>
                ) : null}
                {this.props.location === CREATE_LAYOUT && (
                    <div className={buttonClass}>
                        <Button
                            type="solid"
                            qaAttr={buttonText}
                            value={buttonText}
                            disabled={buttonDisabled}
                            onClick={this.props.onClick}
                        />
                    </div>
                )}
            </div>
        );
    }
}

EmailLoginForm.propTypes = {
    /**
     * submit button onClick function
     */
    onClick: PropTypes.func.isRequired,
    /**
     * function to handle email input
     */
    handleEmailInput: PropTypes.func.isRequired,
    /**
     * function to handle email input blur
     */
    handleEmailInputBlur: PropTypes.func.isRequired,
    /**
     * function to handle password input
     */
    handlePasswordInput: PropTypes.func.isRequired,
    /**
     * function to handle password input blur
     */
    handlePasswordInputBlur: PropTypes.func.isRequired,
    /**
     * email value
     */
    email: PropTypes.string,
    /**
     * password value
     */
    password: PropTypes.string,
    /**
     * email error
     */
    emailError: PropTypes.string,
    /**
     * password error
     */
    passwordError: PropTypes.string,
    /**
     * general error
     */
    generalError: PropTypes.string,
    /**
     * state passed down on whether the call has finished processing
     */
    loaded: PropTypes.bool,
    /**
     * location of form
     */
    location: PropTypes.oneOf(['create', 'login']),
    /**
     * whether to use the username form
     */
    showUsernameField: PropTypes.bool,
    name: PropTypes.string,
    nameError: PropTypes.string,
    handleNameInput: PropTypes.func,
    handleNameInputBlur: PropTypes.func.isRequired,
};

EmailLoginForm.defaultProps = {
    showUsernameField: false,
    email: undefined,
    password: undefined,
    emailError: undefined,
    passwordError: undefined,
    generalError: undefined,
    loaded: undefined,
    location: undefined,
    name: undefined,
    nameError: undefined,
    handleNameInput: undefined,
};

export default EmailLoginForm;
