import PropTypes from 'prop-types';
import React from 'react';
import FlexPopup from '../../../Elements/FlexPopup/FlexPopup';
import ColorCalendar from '../../../Icons/EventsIcons/ColorCalendar';
import Button from '../../../Input/Button/Button';

import './RegisterSuccessModal.scss';

export default class RegisterSuccessModal extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * whether modal is being displayed or not
         */
        showModal: PropTypes.bool.isRequired,
        /**
         * function for closing modal
         */
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        showModal: false,
        onClose: () => {},
    };

    render() {
        const { showModal, onClose } = this.props;
        return (
            <div className="component-Events-EventRegisterButton-RegisterSuccessModal">
                <FlexPopup hidden={!showModal} onClose={onClose}>
                    <div className="modal-body-content">
                        <div className="heading">You are registered!</div>
                        <div className="icon">{ColorCalendar}</div>
                        <div className="details">
                            You'll receive an email with a link to the event:
                            <div className="notifications">
                                <span>• 1 hour before the event</span>
                                <span>• 1 day before the event</span>
                                <span>• 1 week before the event</span>
                            </div>
                        </div>
                        <div className="bottom">
                            <Button
                                type="purple-medium-white"
                                class="confirm-btn"
                                inputStyle="confirm-btn-input"
                                value="Got it!"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                </FlexPopup>
            </div>
        );
    }
}
