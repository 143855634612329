import PropTypes from 'prop-types';
import React from 'react';
import './PageSection.scss';

export default class PageSection extends React.PureComponent {
    static propTypes = {
        /**
         * The Child components to be the content of the PageSection
         */
        header: PropTypes.node,
        /**
         * The Child components to be the content of the PageSection
         */
        children: PropTypes.node.isRequired,
        /**
         * preset layout styling
         */
        pageLayoutStyle: PropTypes.oneOf([
            '',
            'default',
            'employer-default-layout',
        ]),
        className: PropTypes.string,
        refs: PropTypes.func,
    };

    static defaultProps = {
        pageLayoutStyle: '',
        className: '',
        header: null,
    };

    render() {
        const { pageLayoutStyle, className } = this.props;

        return (
            <div
                ref={this.props.refs}
                className={`component-Layout-PageSection ${pageLayoutStyle} ${className}`}
            >
                {this.props.header ? (
                    <div className="header-section">{this.props.header}</div>
                ) : null}
                <div className="content pageContentChildren">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
