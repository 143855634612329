import PropTypes from 'prop-types';
import React from 'react';
import CommunityUserCallToActions from '../../CallToAction/CommunityUserCallToActions/CommunityUserCallToActions';
import MessageBubble from '../../Elements/MessageBubble/MessageBubble';
import TruncatableText from '../../Elements/TruncatableText/TruncatableText';
import GroupJoinButton from '../../Groups/Elements/GroupJoinButton/GroupJoinButton';
import { userProptype } from '../../Includes/userProps';
import UserOverviewTag from '../UserOverviewTag/UserOverviewTag';

import './PendingUserRequest.scss';

export default class PendingUserRequest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayTextTruncated: true,
        };
    }

    static propTypes = {
        /**
         * user's data
         */
        user: userProptype.isRequired,
        /**
         * string of text in message bubble
         * can be user's answer to invite question for joining group
         * or group's message to invite user
         */
        bubbleMessage: PropTypes.string,
        /**
         * fetch for processing join request, ManageMember's state will be updated to reflects the change
         */
        handleRequest: PropTypes.func,
        /**
         * boolean to show tooltip on user overview tag hover
         */
        shouldShowTooltip: PropTypes.bool,
        /**
         * string of custom css class
         */
        className: PropTypes.string,
        /**
         * string enum of clicked action
         */
        clickedAction: PropTypes.oneOf(['ACCEPTED', 'DENIED']),
        /**
         * function for setting clicked action
         */
        setClickedAction: PropTypes.func,
    };

    static defaultProps = {
        user: {},
        bubbleMessage: '',
        handleRequest: () => {},
        shouldShowTooltip: true,
        className: '',
        clickedAction: null,
        requestType: 'GROUP_MEMBER',
        setClickedAction: () => {},
    };

    handleShowMoreOrLessClick = () => {
        this.setState({
            isDisplayTextTruncated: !this.state.isDisplayTextTruncated,
        });
    };

    handleCtaClick = (networkItems, id, clickedAction) => {
        this.props.setClickedAction(networkItems, id, clickedAction);
    };

    renderCtaButtons = () => {
        if (this.props.requestType === 'GROUP_MEMBER') {
            return (
                <CommunityUserCallToActions
                    user={this.props.user}
                    buttonsToRender={['IGNORE', 'ACCEPT']}
                    onClickHandlers={{
                        handleConnect: () =>
                            this.props.handleRequest(
                                this.props.user.userId,
                                true
                            ),
                        handleIgnore: () =>
                            this.props.handleRequest(
                                this.props.user.userId,
                                false
                            ),
                    }}
                />
            );
        }

        if (this.props.requestType === 'USER_CONNECTION') {
            return (
                <CommunityUserCallToActions
                    user={this.props.user}
                    buttonsToRender={['IGNORE', 'ACCEPT']}
                    connectionStatus="invited"
                    onClickHandlers={{
                        onConnectCallback: () =>
                            this.handleCtaClick(
                                'pendingRequests',
                                this.props.user.userId,
                                'ACCEPTED'
                            ),
                        onDenyCallback: () =>
                            this.handleCtaClick(
                                'pendingRequests',
                                this.props.user.userId,
                                'DENIED'
                            ),
                    }}
                    clickedAction={this.props.clickedAction}
                />
            );
        }

        const { clickedAction, groupHash } = this.props;
        const group = { hash: groupHash };

        const shouldShowAccept = !clickedAction || clickedAction === 'ACCEPTED';
        const shouldShowDeny = !clickedAction || clickedAction === 'DENIED';

        return (
            <div className="group-cta-buttons">
                {shouldShowDeny && (
                    <GroupJoinButton
                        actionType="DENY"
                        group={group}
                        onClick={this.handleCtaClick}
                        clickedAction={clickedAction}
                    />
                )}
                {shouldShowAccept && (
                    <GroupJoinButton
                        actionType="ACCEPT"
                        group={group}
                        onClick={this.handleCtaClick}
                        clickedAction={clickedAction}
                    />
                )}
            </div>
        );
    };

    render() {
        const { isDisplayTextTruncated } = this.state;
        const { user, bubbleMessage } = this.props;
        return (
            <div
                className={`component-Users-PendingUserRequest ${this.props.className}`}
            >
                <UserOverviewTag
                    cssClass={'profile-position'}
                    user={user}
                    showToolTipOnHover={this.props.shouldShowTooltip}
                />
                {!!bubbleMessage && (
                    <MessageBubble
                        bubbleBackgroundColor={'purple-bubble'}
                        bubbleTipVerticalPosition="top"
                        bubbleTipHorizontalPosition={'left'}
                        cssClass={'request-user-bubble'}
                    >
                        <TruncatableText
                            displayText={bubbleMessage}
                            isDisplayTextTruncated={isDisplayTextTruncated}
                            handleShowMoreOrLessClick={
                                this.handleShowMoreOrLessClick
                            }
                            showMoreBtnText={'See more'}
                            showLessBtnText={null}
                            truncatedAtIndex={51}
                        />
                    </MessageBubble>
                )}
                {this.renderCtaButtons()}
            </div>
        );
    }
}
