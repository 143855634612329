import Axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../../../contexts/PageContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import Button from '../../../Input/Button/Button';
import { joinMeta } from './constants';
import './GroupJoinButton.scss';

export class GroupJoinButton extends React.PureComponent {
    state = {
        isJoinButtonClicked: false,
    };

    static propTypes = {
        /**
         * enum of which type of group cta button to render
         */
        actionType: PropTypes.oneOf([
            'JOIN',
            'ACCEPT',
            'LEAVE',
            'DENY',
            'LEAVE_DISABLED',
        ]).isRequired,
        /**
         * enum of privacy setting for joining a group
         */
        groupPrivacySetting: PropTypes.oneOf(['closed', 'open', 'secret']),
        /**
         * string of custom css
         */
        className: PropTypes.string,
        /**
         * enum of clicked button to keep btn state persistent
         */
        clickedAction: PropTypes.oneOf([
            'ACCEPTED',
            'DENIED',
            'JOINED',
            'REQUESTED',
        ]), // for group pending requests from same group
        /**
         * function of callback when button is clicked
         */
        onClick: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        onClick: () => {},
        groupPrivacySetting: null,
        clickedAction: null,
        isLoggedIn: () => {},
        wrapAuth: () => {},
    };

    handleLeave = async () => {
        const API_LEAVE_GROUP = `/api/groups/leave/${this.props.group.hash}`;

        try {
            await Axios.post(API_LEAVE_GROUP);
            EmitMetric({
                misc_event_type: 'leave-group-click',
                misc_event_count: 1,
                community_group_hash: this.props.group.hash,
            });
            this.setState({ isJoinButtonClicked: true });
        } catch (e) {
            console.error(e);
        }
    };

    handlePendingGroupRequest = (_, isAccepting) => {
        if (isAccepting) {
            this.handleAccept();
        } else {
            this.handleDeny();
        }
    };

    // accept click handler
    handleAccept = async () => {
        const API_ACCEPT_GROUP_INVITATION =
            '/api/network/group/accept-invitation';
        const payload = { communityGroupHash: this.props.group.hash };

        try {
            await Axios.post(API_ACCEPT_GROUP_INVITATION, payload);
            this.handleSuccess('pendingGroupRequests', 'ACCEPTED');
        } catch (e) {
            console.error(e);
        }
    };

    // deny click handler
    handleDeny = async () => {
        const API_DENY_GROUP_INVITATION =
            '/api/network/group/ignore-invitation';
        const payload = { communityGroupHash: this.props.group.hash };
        try {
            await Axios.post(API_DENY_GROUP_INVITATION, payload);
            this.handleSuccess('pendingGroupRequests', 'DENIED');
        } catch (e) {
            console.error(e);
        }
    };

    // join/request click handler
    handleJoin = () => {
        const payload = {
            group: this.props.group,
            onSuccess: () => this.handleSuccess('recommendedGroups', 'JOINED'),
            modalTriggerParameters: this.props.modalTriggerParameters,
        };

        this.props.modalTrigger('GroupJoin', payload);
    };

    handleSuccess = (networkItems, clickedAction) => {
        this.props.onClick(networkItems, this.props.group.hash, clickedAction);
        this.setState({ isJoinButtonClicked: true });
    };

    getJoinBtnProperties = () => {
        const { isJoinButtonClicked } = this.state;
        const { groupPrivacySetting, clickedAction } = this.props;

        switch (this.props.actionType) {
            case 'JOIN': {
                const { states: joinStates } = joinMeta['JOIN'];
                const { states: requestStates } = joinMeta['REQUEST'];
                const isJoinClicked = clickedAction === 'JOINED';

                if (groupPrivacySetting === 'open') {
                    return isJoinButtonClicked || isJoinClicked
                        ? joinStates.joined
                        : joinStates.join;
                }

                return isJoinButtonClicked || isJoinClicked
                    ? requestStates.requested
                    : requestStates.request;
            }
            case 'ACCEPT': {
                const { states: acceptStates } = joinMeta['ACCEPT'];
                const isAcceptClicked = clickedAction === 'ACCEPTED';

                return isJoinButtonClicked || isAcceptClicked
                    ? acceptStates.accepted
                    : acceptStates.accept;
            }
            case 'DENY': {
                const { states: denyStates } = joinMeta['DENY'];
                const isDenyClicked = clickedAction === 'DENIED';
                return isJoinButtonClicked || isDenyClicked
                    ? denyStates.denied
                    : denyStates.deny;
            }
            case 'LEAVE': {
                const { states: leaveStates } = joinMeta['LEAVE'];
                return isJoinButtonClicked
                    ? leaveStates.left
                    : leaveStates.leave;
            }
            case 'LEAVE_DISABLED': {
                const { states: leaveStates } = joinMeta['LEAVE_DISABLED'];
                return leaveStates.leave;
            }
            default: {
                return null;
            }
        }
    };

    getJoinBtnClickHandler = () => {
        switch (this.props.actionType) {
            case 'JOIN':
                return this.handleJoin;
            case 'ACCEPT':
                return this.handleAccept;
            case 'DENY':
                return this.handleDeny;
            case 'LEAVE':
                return this.handleLeave;
            default:
                return () => {};
        }
    };

    render() {
        const joinButtonMeta = this.getJoinBtnProperties();
        const joinButtonClickHandler = this.props.isLoggedIn()
            ? this.getJoinBtnClickHandler()
            : null;

        const { className, isDisabled, text, inputStyle = '' } = joinButtonMeta;

        const ButtonComponent = (
            <Button
                type={className}
                disabled={isDisabled}
                value={text}
                inputStyle={inputStyle}
                onClick={joinButtonClickHandler}
                className={this.props.className}
            />
        );

        return (
            <div className="component-Groups-Elements-GroupJoinButton">
                {this.props.wrapAuth('join-group', ButtonComponent, {
                    login_trigger: 'join-group',
                })}
            </div>
        );
    }
}

export default (props) => (
    <PageConsumer>
        {({ modalTrigger, isLoggedIn, wrapAuth }) => (
            <GroupJoinButton
                {...props}
                modalTrigger={modalTrigger}
                isLoggedIn={isLoggedIn}
                wrapAuth={wrapAuth}
            />
        )}
    </PageConsumer>
);
