import PropTypes from 'prop-types';
import React from 'react';
import { truncateWithEllipsis } from '../../../../../utilities/ui_utilities';
import Button from '../../../../Input/Button/Button';
import InputField from '../../../../Input/InputField/InputField';

import './ResetPasswordLayout.scss';

export default class ResetPasswordLayout extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * email error
         */
        emailError: PropTypes.string,
        /**
         * email value
         */
        email: PropTypes.string,
        /**
         * email value
         */
        onClick: PropTypes.func.isRequired,
        /**
         * function to handle email input
         */
        handleEmailInput: PropTypes.func.isRequired,
        /**
         * The secondary subtitle text
         */
        callToActionSubTitle: PropTypes.string,
        /**
         * state passed down on whether the call has finished processing
         */
        loaded: PropTypes.bool,
    };

    static defaultProps = {
        callToActionSubTitle:
            "Please enter your email and we'll send you instructions to reset your password.",
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onClick();
        }
    };

    render() {
        const buttonDisabled =
            !(this.props.email && this.props.email.length) ||
            !this.props.loaded;
        const buttonText = !this.props.loaded ? 'Processing' : 'Submit';
        return (
            <div className="component-Login-LoginLayoutComponent-Layouts-ResetPasswordLayout">
                <div className="login-subText-call-to-action-component">
                    {truncateWithEllipsis(this.props.callToActionSubTitle, 100)}
                </div>
                <div className="input-section reset-password-input-field email-field">
                    <div className="form-label reset-password-input-field-label">
                        Email
                    </div>
                    <InputField
                        name={'email-input-reset-password'}
                        qaDataAttr={'email'}
                        onChange={this.props.handleEmailInput}
                        onKeyPress={this.onKeyPress}
                        value={this.props.email}
                    />
                    <div className="errorMessage">{this.props.emailError}</div>
                </div>
                <div>
                    <Button
                        type={'solid'}
                        qaAttr={buttonText}
                        value={buttonText}
                        disabled={buttonDisabled}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}
