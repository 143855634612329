export const buildQueryString = (queryParams = {}) => {
    const keys = Object.keys(queryParams);
    if (keys.length === 0) {
        return '';
    } else {
        let firstParam = keys[0];
        let queryString = `?${firstParam}=${queryParams[firstParam]}`;

        if (keys.length > 1) {
            for (let i = 1; i < keys.length; i++) {
                let currentParam = keys[i];
                queryString += `&${currentParam}=${queryParams[currentParam]}`;
            }
        }
        return queryString;
    }
};
