import PropTypes from 'prop-types';
import React from 'react';

import './Tooltip.scss';

const propTypes = {
    tooltipText: PropTypes.string.isRequired,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
    multiLine: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

class Tooltip extends React.PureComponent {
    render() {
        const bottom = this.props.bottom ? 'fgb-tooltip--bottom' : '';
        const multiLine = this.props.multiLine ? 'fgb-tooltip--multiline' : '';
        const right = this.props.right ? 'fgb-tooltip--right-mobile' : '';

        return (
            <div
                className={`component-Wrappers-Tooltip ${this.props.className}`}
            >
                <div
                    className={`fgb-tooltip ${bottom} ${multiLine} ${right}`}
                    aria-label={this.props.tooltipText}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Tooltip.propTypes = propTypes;

Tooltip.defaultProps = {
    bottom: false,
    right: false,
    multiLine: false,
    className: '',
};

export default Tooltip;
