import PropTypes from 'prop-types';
import React from 'react';

export default class InfiniteScrollWrapper extends React.PureComponent {
    static propTypes = {
        /**
         * React Children wrapped inside InfiniteScrollWrapper
         */
        children: PropTypes.node.isRequired,
        /**
         * Name of the element that the component should render as
         */
        element: PropTypes.node,
        /**
         * Whether there are more items to be loaded. Event listeners are removed if false
         */
        hasMore: PropTypes.bool,
        /**
         * Whether the component should load the first set of items
         */
        initialLoad: PropTypes.bool,
        /**
         * Whether new items should be loaded when user scrolls to the top of the scrollable area
         */
        isReverse: PropTypes.bool,
        /**
         * A React component to render while more items are loading. The parent component must have a unique key prop
         */
        loader: PropTypes.node,
        /**
         * A callback when more items are requested by the user. Receives a single parameter specifying the page to load e.g. function handleLoadMore(page) { //load more items here } }
         */
        loadMore: PropTypes.func.isRequired,
        /**
         * Whether load more is in fetching state. Event listeners are removed if false
         */
        isLoading: PropTypes.bool.isRequired,
        /**
         * The number of the first page to load, With the default of 0, the first page is 1
         */
        pageStart: PropTypes.number,
        /**
         * custom ref for infiniteScrollWrapper
         */
        ref: PropTypes.func,
        /**
         * The distance in pixels before the end of the items that will trigger a call to loadMore
         */
        threshold: PropTypes.number,
        /**
         * Add scroll listeners to the window, or else, the component's parentNode
         */
        useWindow: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        children: <span />,
        element: 'div',
        hasMore: false,
        isLoading: false,
        initialLoad: false,
        pageStart: 0,
        ref: null,
        threshold: 100,
        useWindow: true,
        isReverse: false,
        loader: null,
        loadMore: () => {},
        className: '',
    };

    componentDidMount() {
        this.pageLoaded = this.props.pageStart;
        this.options = this.eventListenerOptions();
        this.attachScrollListener();
    }

    componentDidUpdate() {
        if (this.props.isReverse && this.loadMore) {
            const parentElement = this.getParentElement(this.scrollComponent);
            parentElement.scrollTop =
                parentElement.scrollHeight -
                this.beforeScrollHeight +
                this.beforeScrollTop;
            this.loadMore = false;
        }
        this.attachScrollListener();
    }

    componentWillUnmount() {
        this.detachScrollListener();
        this.detachMousewheelListener();
    }

    mousewheelListener = (e) => {
        // Prevents Chrome hangups
        // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
        if (e.deltaY === 1 && !this.isPassiveSupported()) {
            e.preventDefault();
        }
    };

    isPassiveSupported = () => {
        let passive = false;
        const testOptions = {
            get passive() {
                passive = true;
            },
        };
        try {
            document.addEventListener('test', null, testOptions);
            document.removeEventListener('test', null, testOptions);
        } catch (e) {
            // ignore
        }
        return passive;
    };

    eventListenerOptions = () => {
        let options = false;
        if (this.isPassiveSupported()) {
            options = {
                passive: true,
            };
        }
        return options;
    };

    getParentElement = (el) => {
        return el && el.parentNode;
    };

    /**
     * suppress React's warning on recognizing props as custom DOM attribute when using React.createElement
     */
    filterProps = (props) => {
        return props;
    };

    attachScrollListener = () => {
        const parentElement = this.getParentElement(this.scrollComponent);
        if (!this.props.hasMore || this.props.isLoading || !parentElement) {
            return;
        }

        let scrollEl = window;
        if (this.props.useWindow === false) {
            scrollEl = parentElement;
        }

        scrollEl.addEventListener(
            'mousewheel',
            this.mousewheelListener,
            this.options
        );
        scrollEl.addEventListener('scroll', this.scrollListener, this.options);
        scrollEl.addEventListener('resize', this.scrollListener, this.options);

        if (this.props.initialLoad) {
            this.scrollListener();
        }
    };

    detachMousewheelListener = () => {
        let scrollEl = window;
        if (this.props.useWindow === false) {
            scrollEl = this.scrollComponent.parentNode;
        }

        scrollEl.removeEventListener(
            'mousewheel',
            this.mousewheelListener,
            this.options
        );
    };

    detachScrollListener = () => {
        let scrollEl = window;
        if (this.props.useWindow === false) {
            scrollEl = this.getParentElement(this.scrollComponent);
        }

        scrollEl.removeEventListener(
            'scroll',
            this.scrollListener,
            this.options
        );
        scrollEl.removeEventListener(
            'resize',
            this.scrollListener,
            this.options
        );
    };

    scrollListener = () => {
        const el = this.scrollComponent;
        const scrollEl = window;
        const parentNode = this.getParentElement(el);

        let offset;
        if (this.props.useWindow) {
            const doc =
                document.documentElement ||
                document.body.parentNode ||
                document.body;
            const scrollTop =
                scrollEl.pageYOffset !== undefined
                    ? scrollEl.pageYOffset
                    : doc.scrollTop;
            if (this.props.isReverse) {
                offset = scrollTop;
            } else {
                offset = this.calculateOffset(el, scrollTop);
            }
        } else if (this.props.isReverse) {
            offset = parentNode.scrollTop;
        } else {
            offset =
                el.scrollHeight -
                parentNode.scrollTop -
                parentNode.clientHeight;
        }

        // check if the element is visible as well as checking the offset
        if (
            offset < Number(this.props.threshold) &&
            el &&
            el.offsetParent !== null
        ) {
            this.detachScrollListener();
            this.beforeScrollHeight = parentNode.scrollHeight;
            this.beforeScrollTop = parentNode.scrollTop;
            // Call loadMore after detachScrollListener to allow for non-async loadMore functions
            if (typeof this.props.loadMore === 'function') {
                this.props.loadMore((this.pageLoaded += 1));
                this.loadMore = true;
            }
        }
    };

    calculateOffset = (el, scrollTop) => {
        if (!el) {
            return 0;
        }
        return (
            this.calculateTopPosition(el) +
            (el.offsetHeight - scrollTop - window.innerHeight)
        );
    };

    calculateTopPosition = (el) => {
        if (!el) {
            return 0;
        }
        return el.offsetTop + this.calculateTopPosition(el.offsetParent);
    };

    render() {
        const renderProps = this.filterProps(this.props);
        const {
            children,
            element,
            hasMore,
            isLoading,
            initialLoad,
            pageStart,
            ref,
            threshold,
            useWindow,
            isReverse,
            loader,
            loadMore,
            ...props
        } = renderProps;

        props.ref = (node) => {
            this.scrollComponent = node;
            if (ref) {
                ref(node);
            }
        };

        const childrenArray = [children];
        if (isLoading) {
            if (loader) {
                isReverse
                    ? childrenArray.unshift(loader)
                    : childrenArray.push(loader);
            } else if (this.defaultLoader) {
                isReverse
                    ? childrenArray.unshift(this.defaultLoader)
                    : childrenArray.push(this.defaultLoader);
            }
        }
        return React.createElement(
            element,
            Object.assign(props, { className: this.props.className }),
            childrenArray
        );
    }
}
