import React from 'react';

export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
    >
        <path
            fill="#C4C8CC"
            fillRule="nonzero"
            d="M21.597 2.403A1.375 1.375 0 0 0 19.653.458L11 9.111 2.347.458A1.375 1.375 0 1 0 .403 2.403l8.652 8.653-8.652 8.652a1.375 1.375 0 0 0 1.944 1.945L11 13l8.653 8.653a1.375 1.375 0 1 0 1.944-1.945l-8.652-8.652 8.652-8.653z"
        />
    </svg>
);
