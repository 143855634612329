import React, { useEffect, useState, memo } from 'react';
import useHubSpot from '../../../utilities/hooks/useHubSpot';
import './HubSpotFrom.scss';

const errorMessage = (
    <p className="component-Form-MarketoForm__error">
        We&apos;re having trouble loading the form.
        <br />
        If you are using an adBlocker, please pause it and refresh the page to
        try again.
    </p>
);

const HubSpotForm = ({ isHidden }) => {
    const [hasError, setHasError] = useState(false);
    const [loadError] = useHubSpot();
    useEffect(() => {
        if (loadError) {
            setHasError(true);
        }
    }, [loadError]);

    if (isHidden) return null;

    if (hasError) return errorMessage;

    if (isHidden) return null;
    return (
        <>
            <div id="mkto_embed_form" className="mkto_embed_form">;</div>
        </>
    );
};

export default memo(HubSpotForm);