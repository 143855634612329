import { componentTypes } from '../components/Includes/componentContentTypes';

export const loadRightRailContent = (rightRailProps) => {
    let rightRailContent = {};

    if (rightRailProps) {
        rightRailProps.forEach((contentType) => {
            rightRailContent[contentType.type] = contentType.data;
        });
    }

    return rightRailContent;
};

export const loadRightRailLayout = (page, isSponsor = false, featureFlags) => {
    //@TODO - remove feature flags parameter once events calendar launches
    let layout = null;
    const DEFAULT_RIGHT_RAIL_LAYOUT = [
        componentTypes.SUGGESTED_JOBS,
        componentTypes.COMPANY_REVIEWS,
        componentTypes.TRENDING_ARTICLES,
    ];

    switch (page) {
        case 'MasterFeed':
            layout = [
                componentTypes.SUGGESTED_JOBS,
                componentTypes.UPCOMING_EVENTS,
                componentTypes.COMPANY_REVIEWS,
                componentTypes.TRENDING_ARTICLES,
            ];
            break;
        case 'Topic':
            layout = [
                componentTypes.SUGGESTED_JOBS,
                componentTypes.COMPANY_REVIEWS,
                componentTypes.TRENDING_ARTICLES,
                componentTypes.SPONSORED_COMPANY,
            ];
            break;
        case 'EditorialContent':
            layout = [
                componentTypes.SUGGESTED_JOBS,
                componentTypes.COMPANY_REVIEWS,
                componentTypes.TRENDING_ARTICLES,
            ];
            if (!isSponsor) {
                layout.push(componentTypes.SPONSORED_COMPANY);
            }
            break;
        case 'Jobs':
            layout = [componentTypes.SPONSORED_COMPANY];
            break;
        case 'JobsSearch':
            layout = [
                componentTypes.JOB_FILTERS,
                componentTypes.SPONSORED_COMPANY,
            ];
            break;
        case 'JobActivity':
            layout = [
                componentTypes.SUGGESTED_JOBS,
                componentTypes.COMPANY_REVIEWS,
            ];
            break;
        default:
            layout = DEFAULT_RIGHT_RAIL_LAYOUT;
    }

    return layout;
};
