/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import React from 'react';
import deepmerge from 'deepmerge';

import { ModalContext } from '../ContextCreator';

import { CreateContentPopup } from '../../components/Content/CreateContentWidgetV2/CreateContentPopup/CreateContentPopup';
import CompaniesModal from '../../components/CallToAction/LeftRailCTA/CompaniesModal/CompaniesModal';
import RegisterSuccessModal from '../../components/Events/EventRegisterButton/RegisterSuccessModal/RegisterSuccessModal';
import FollowedJobs from '../../components/CallToAction/LeftRailCTA/Layouts/FollowedJobs/FollowedJobs';
import MobileMenuModal from '../../components/CallToAction/LeftRailCTA/MobileMenuModal/MobileMenuModal';
import InvitationWidgetPopup from '../../components/InvitationWidget/InvitationWidgetPopup/InvitationWidgetPopup';
import GroupJoinModal from '../../components/Groups/Join/GroupJoinModal/GroupJoinModal';
import ConfirmationPopup from '../../components/ConfirmationPopup/ConfirmationPopup';
import EmployerClaimForm from '../../components/CompanyPages/Sections/EmployerClaimForm/EmployerClaimForm';
import AdminOnboardingModal from '../../components/Groups/AdminOnboarding/AdminOnboardingModal/AdminOnboardingModal';
import VfairRegisterModal from '../../components/Events/EventRegisterButton/VfairRegisterModal/VfairRegisterModal';
import EventRegistrationIncompleteModal from '../../components/Events/EventRegistrationIncompleteModal/EventRegistrationIncompleteModal';
import EventRegistrationSuccessModal from '../../components/Events/EventRegistrationSuccessModal/EventRegistrationSuccessModal';
import QuickApplyModal from '../../components/Modals/QuickApplyModal/QuickApplyModal';
import InviteModal from '../../components/Modals/InviteModal/InviteModal';
import JobSeekerModal from '../../components/Modals/JobSeekerModal/JobSeekerModal';
import AuthModal from '../../components/Modals/AuthModal/AuthModal';
import ModalContainer from '../../components/Modals/ModalParts/ModalContainer/ModalContainer';
import ProfileIdentityModal from '../../components/Modals/User/ProfileIdentityModal/ProfileIdentityModal';
import EventRegistrationFlow, {
    eventRegistrationFlowType,
} from '../../components/Modals/Flows/EventRegistration/EventRegistration';
import SingleEducationFlow, {
    singleEducationFlowType,
} from '../../components/Modals/Flows/SingleEducation/SingleEducation';
import SingleExperienceFlow, {
    singleExperienceFlowType,
} from '../../components/Modals/Flows/SingleExperience/SingleExperience';
import QuickProfileOnboardingFlow, {
    quickProfileOnboardingFlowType,
} from '../../components/Modals/Flows/QuickProfileOnboarding/QuickProfileOnboarding';
import DiversityInputFlow, {
    diversityInputFlowType,
} from '../../components/Modals/Flows/DiversityInput/DiversityInput';
import ProfileEnrichmentFlow, {
    profileEnrichmentFlowType,
} from '../../components/Modals/Flows/ProfileEnrichment/ProfileEnrichment';

import {
    validStepsList,
    defaultStepFormData,
} from '../../components/Modals/Flows/Steps/constants';
import { isEmpty } from '../../utilities/helperFunctions';
import { filter, iterateObject } from '../../utilities/helpers/object';
import GroupsMenuMobileModal from '../../components/Groups/Modals/GroupsMenuMobileModal/GroupsMenuMobileModal';
import ForceResetPasswordModal from '../../components/Modals/ForceResetPasswordModal/ForceResetPasswordModal';

// not currently doing anything with legacy or static types
// they are handled in modal manager
export const modalComponentMap = {
    legacy: {
        CreateContentPopup,
        CompaniesModal,
        RegisterSuccessModal, // TODO: deprecate on completion of FGB-4009
        FollowedJobs,
        MobileMenuModal,
        GroupsMobileModal: GroupsMenuMobileModal,
        Invitation: InvitationWidgetPopup, // TODO: maybe deprecate with v2.0/aria inviteModal?
        GroupJoin: GroupJoinModal,
        ConfirmationModal: ConfirmationPopup,
    },
    static: {
        Container: ModalContainer,
        EmployerClaimForm,
        adminOnboarding: AdminOnboardingModal,
        vfairRegisterModal: VfairRegisterModal,
        VCFEventSuccess: EventRegistrationSuccessModal,
        EventRegistrationIncompleteModal,
        JobSeekerSubsectionModal: JobSeekerModal,
        inviteModal: InviteModal,
        quickApply: QuickApplyModal,
        authModal: AuthModal,
        userProfileIdentity: ProfileIdentityModal,
        forceResetPasswordModal: ForceResetPasswordModal,
    },
    flow: {
        [eventRegistrationFlowType]: EventRegistrationFlow,
        [singleEducationFlowType]: SingleEducationFlow,
        [singleExperienceFlowType]: SingleExperienceFlow,
        [quickProfileOnboardingFlowType]: QuickProfileOnboardingFlow,
        [diversityInputFlowType]: DiversityInputFlow,
        [profileEnrichmentFlowType]: ProfileEnrichmentFlow,
    },
};

export const legacyModalTypes = Object.keys(modalComponentMap.legacy);
export const flowModalTypes = Object.keys(modalComponentMap.flow);
export const staticModalTypes = Object.keys(modalComponentMap.static);
export const allModalTypes = [
    ...legacyModalTypes,
    ...flowModalTypes,
    ...staticModalTypes,
];

export const getVariantFromType = (type) => {
    if (!type) return '';
    const variants = Object.keys(modalComponentMap);
    for (const variant of variants) {
        const subTypes = Object.keys(modalComponentMap[variant]);
        if (subTypes.includes(type)) return variant;
    }
};

export const getComponent = (type) => {
    if (!allModalTypes.includes(type)) return null;
    const variant = getVariantFromType(type);
    const typeMap = modalComponentMap[variant];
    return typeMap[type];
};

export const useGetStepFormProps = (step) => {
    if (!validStepsList.includes(step)) {
        if (!process.env.NODE_ENV === 'production') {
            throw new Error(`${step} is not a valid step`);
        }
    }

    const {
        formValues,
        updateFormValues,
        updateFormValueAtPath,
        clearStepErrors,
        updateFormValueAtPathSelector,
    } = React.useContext(ModalContext);

    const stepValues = formValues[step];
    const stepErrors =
        formValues.errors && formValues.errors[step]
            ? formValues.errors[step]
            : {};

    const values = {
        stepFormProps: stepValues,
        updateFormValues,
        updateFormValueAtPath,
        updateFormValueAtPathSelector,
        stepErrors,
        clearStepErrors,
    };

    return values;
};

export const buildInitialFormState = (steps = [], initializeData = {}) => {
    const formState = steps.reduce((acc, step) => {
        const defaultStepData = defaultStepFormData[step];
        const initializeStepData = initializeData[step];

        // if we don't have any default or populate data for a given step, remove it
        if (!initializeStepData && !defaultStepData) return { ...acc };

        // if no populate data for a step, return default step data
        if (!initializeStepData || isEmpty(initializeStepData)) {
            return {
                ...acc,
                [step]: defaultStepFormData[step],
            };
        }

        // remove properties with values so they can be overwritten by defaults in deepmerge
        const filteredInitializeData = filter(
            initializeStepData,
            (k, v) => v !== null
        );

        // merge
        const merged = deepmerge(
            defaultStepFormData[step],
            filteredInitializeData
        );

        return {
            ...acc,
            [step]: merged,
        };
    }, {});

    const errorState = iterateObject(formState, ([key, _]) => ({ [key]: '' }));

    return { ...formState, errors: errorState };
};
