import axios from 'axios';
import { SINGLE_EDUCATION_STEP_NAME } from '../Steps/constants';
import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';
import { validateSingleEducationPayload } from '../../../Includes/FormSchema/EducationSchema';

const flowTree = {
    [SINGLE_EDUCATION_STEP_NAME]: [],
};

export const buildDefaultSingleEducationState = (data = {}) =>
    buildInitialFormState(['singleEducation'], data);

export const buildSingleEducationFlow = (initializeOptions = {}) => {
    const validators = {
        singleEducation: validateSingleEducationPayload,
    };

    return {
        flowTree,
        initialStep: SINGLE_EDUCATION_STEP_NAME,
        initialFlowData: {
            ...initializeOptions,
            ...initializeOptions,
            validators,
        },
    };
};

const filterListById = (id, list) =>
    list.filter((item) => item.educationId !== id);

const apiEndpoint = '/api/user/profile';

const handleApiRequest = async (payload) => {
    try {
        const response = await axios.patch(apiEndpoint, {
            educations: payload,
        });

        const { meta, errors, data } = response.data;

        const { educations } = data;

        const updatedEducationIds = educations.map((idObj) => idObj.id);

        if (!meta.success) {
            return {
                ok: false,
                updatedIds: null,
                apiErrors: errors,
            };
        }

        return {
            ok: true,
            updatedIds: updatedEducationIds, // ['123', '456', '789'];
            apiErrors: null,
        };
    } catch (error) {
        return {
            ok: false,
            updatedIds: null,
            apiErrors: error,
        };
    }
};

export const getEducationId = (education) => {
    if (education.educationId) return education.educationId;
    if (education.id) return education.id;
    return null;
};

export const educationUpdateApi = {
    addSingleEducation: async (education, currentEducations = []) => {
        // If we're editing one education, find the index by id and replace it. Otherwise add it to the current educations
        const editIndex = currentEducations.findIndex(
            (edu) => edu.educationId === education.educationId
        );

        const addingNewEducation = editIndex === -1;

        const newEducationPayload = addingNewEducation
            ? [...currentEducations, education] // adding new - add to users current list
            : currentEducations.map((edu, i) =>
                  i === editIndex ? education : edu
              ); // editing - replace edu in users list with our current edu

        const { ok, updatedIds, apiErrors } = await handleApiRequest(
            newEducationPayload
        );

        if (ok && updatedIds) {
            if (addingNewEducation) {
                // need to set the null id for the education we just created with the new id from the response ids
                const currentIds = newEducationPayload.reduce((acc, item) => {
                    const entityId = getEducationId(item);
                    if (entityId) return [...acc, entityId];
                    return [...acc];
                }, []);

                const updateId = updatedIds.filter(
                    (id) => !currentIds.includes(id)
                )[0];
                const newEducations = newEducationPayload.map((e) => {
                    if (e.id === null)
                        return { ...e, id: updateId, educationId: updateId }; // our new education without an id gets the updateId
                    const entityId = getEducationId(e);
                    return { ...e, id: entityId, educationId: entityId }; // set all ids and educationIds to maintain consistency
                });

                return { ok: true, newEducations };
            }

            // if we're just editing, we don't need to add any new ids and can use the success payload
            // don't trust the id/educationId thing elsewhere so it's being set here again for consistency
            const editedEducations = newEducationPayload.map((item) => {
                const entityId = getEducationId(item);
                return { ...item, id: entityId, educationId: entityId };
            });

            return { ok: true, newEducations: editedEducations };
        }

        return { ok: false, apiErrors };
    },
    deleteSingleEducation: async (educationId, currentEducations = []) => {
        const newEducationPayload = filterListById(
            educationId,
            currentEducations
        );

        const { ok, updatedIds, apiErrors } = await handleApiRequest(
            newEducationPayload
        );

        if (ok && updatedIds)
            return { ok: true, newEducations: newEducationPayload };

        return { ok: false, errors: apiErrors };
    },
};
