import PropTypes from 'prop-types';
import React from 'react';

import './Button.scss';

const Button = ({
    onClick,
    class: customClass,
    className,
    inputStyle,
    type: componentType,
    disabled,
    formAction,
    name,
    accessibilityLabel,
    isLoading,
    hover,
    qaAttr,
    value: inputValue,
}) => {
    const type = formAction === '' ? 'button' : formAction;
    const disabledStyle = disabled ? 'disabled-styling' : '';
    const label = accessibilityLabel || inputValue;
    const componentClass = className || customClass;
    const value = isLoading ? '' : inputValue;
    const hoverStyle = hover || '';

    const renderLoadingState = () => {
        if (isLoading) {
            return (
                <div className="loading-state">
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                </div>
            );
        }
    };

    return (
        <div
            className={`component-Input-Button ${componentClass} ${disabledStyle}`}
        >
            {renderLoadingState()}
            <input
                className={`${componentType} ${hoverStyle} ${inputStyle}`}
                type={type}
                name={name}
                value={value}
                disabled={disabled}
                onClick={onClick}
                aria-label={label}
                data-qa={qaAttr}
            />
            <div className="hover-state" />
        </div>
    );
};

Button.defaultProps = {
    onClick: () => {},
    class: '',
    className: '',
    inputStyle: '',
    type: '',
    disabled: false,
    formAction: 'button',
    name: 'search',
    accessibilityLabel: '',
    isLoading: false,
    hover: '',
    qaAttr: '',
};

Button.propTypes = {
    /**
     * Previously we enforced one of the following strings, but this was confusing as it was just mapped to a className on the input element,
     * and was just a way of triggering a css style. It had nothing to do with input type, and there was ALSO props.inputStyle which does the same thing!
     * These strings can still be used, but it is no longer required.
     * [
     *   "solid",
     *    "wired",
     *    "wired-white",
     *    "solid-grey",
     *    "wired-gray",
     *    "primary-blue-gray",
     *    "tertiary-blue-gray",
     *    "secondary-blue-gray",
     *    "white-purple",
     *    "purple-medium-white",
     *    "wired-blue",
     *    "solid-wired-blue"
     *    "generic"
     *]
     *
     */
    type: PropTypes.string,
    /**
     * Text to be displayed inside a button
     */
    value: PropTypes.string.isRequired,
    /**
     * Button onClick function
     */
    onClick: PropTypes.func,
    /**
     * Disable a button? Value can be true or false
     */
    disabled: PropTypes.bool,
    /**
     * attribute for QA
     */
    qaAttr: PropTypes.string,
    /**
     * DEPRECATED
     * specific Class
     */
    class: PropTypes.string,
    /**
     * specific Class
     */
    className: PropTypes.string,
    /**
     * input style
     */
    inputStyle: PropTypes.string,
    /**
     * Maps to the input element Type attribute, indicates to the browser whether it should be a "button", "submit" or "reset" button. Empty string defaults to "button".
     */
    formAction: PropTypes.string,
    /**
     * Allows name to be specified. Defaults to search.
     */
    name: PropTypes.string,
    /**
     * If we want different text for the aria-label, this will be used instead
     */
    accessibilityLabel: PropTypes.string,
    /**
     * hover style for opacity
     */
    hover: PropTypes.string,
    /**
     * Indicates if the button should be in a loading state
     */
    isLoading: PropTypes.bool,
};

export default Button;
