import PropTypes from 'prop-types';
import React from 'react';
import VisibilityWrapper from '../../Wrappers/VisibilityWrapper/VisibilityWrapper';
import './HoverCallToAction.scss';

export default class HoverCallToAction extends React.PureComponent {
    getVisibilityStyle = ({
        isVisible,
        topOverflow,
        bottomOverflow,
        leftOverflow,
        rightOverflow,
    }) => {
        if (isVisible) {
            return {};
        }

        const visibilityStyle = {};
        if (leftOverflow > 0) visibilityStyle.left = `${leftOverflow + 20}px`;
        if (rightOverflow > 0)
            visibilityStyle.right = `${rightOverflow + 20}px`;
        if (bottomOverflow > 0)
            visibilityStyle.bottom = `${bottomOverflow + 10}px`;
        if (topOverflow > 0) visibilityStyle.top = `${topOverflow + 10}px`;

        return visibilityStyle;
    };

    render() {
        const callToActionClass = this.props.callToActionSection
            ? 'has-padding'
            : '';
        const { mainContent, callToActionSection } = this.props;
        return (
            <div
                className={`component-CallToAction-HoverCallToAction ${this.props.className}`}
            >
                <VisibilityWrapper>
                    {(visibilityProps) => {
                        const visibilityStyle = this.getVisibilityStyle(
                            visibilityProps
                        );
                        return (
                            <div className="box" style={visibilityStyle}>
                                {mainContent && (
                                    <div className="hover-call-to-action-main-content-section">
                                        {mainContent}
                                    </div>
                                )}
                                {callToActionSection && (
                                    <div
                                        className={`blue-call-to-action-section ${callToActionClass} `}
                                    >
                                        {callToActionSection}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </VisibilityWrapper>
            </div>
        );
    }
}

HoverCallToAction.propTypes = {
    /**
     * main content loaded
     */
    mainContent: PropTypes.node,
    /**
     * call to actions
     */
    callToActionSection: PropTypes.node,
    className: PropTypes.string,
};

HoverCallToAction.defaultProps = {
    mainContent: null,
    callToActionSection: null,
    className: '',
};
