import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
    GO_SUB_STEP,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

import './Recommendation.scss';

export default class Recommendation extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonType: 'single',
        buttonData: [
            {
                title: 'Yes',
                subtitle: null,
                value: 'yes',
            },
            {
                title: 'No',
                subtitle: null,
                value: 'no',
            },
            {
                title: 'Maybe',
                subtitle: '(Please explain.)',
                isExclusive: true,
                value: 'maybe',
            },
        ],
    };

    handleUpdate = (data) => {
        const response = typeof data[0] === 'undefined' ? null : data[0];
        const RecommendObject = { recommend: response };

        let nextAction = CANT_SUBMIT;
        if (this.props.recommendComment) {
            RecommendObject.recommendComment = this.props.recommendComment;
        }

        if (response === 'yes' || response === 'no') {
            if (this.props.recommendComment)
                delete RecommendObject.recommendComment;
            nextAction = SUBMIT;
        } else if (response === 'maybe') {
            nextAction = GO_SUB_STEP;
        }

        this.props.onAnswer(
            'recommendation',
            RecommendObject,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: 'job-review-question-started-company-5',
            misc_event_count: 1,
        });
        const RecommendObject = { recommend: this.props.recommend };

        let nextAction = CAN_SUBMIT;

        if (this.props.recommendComment) {
            RecommendObject.recommendComment = this.props.recommendComment;
            nextAction = ALLOW_SUB_STEP;
        } else if (RecommendObject.recommend === 'maybe') {
            RecommendObject.recommendComment = null;
            nextAction = ALLOW_SUB_STEP;
        }

        this.props.recommend !== undefined
            ? this.props.onAnswer(
                  'recommendation',
                  RecommendObject,
                  nextAction,
                  false,
                  this.props.nextStep
              )
            : null;
    }

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Recommendation">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={this.props.recommend}
                />
            </div>
        );
    }
}
