import PropTypes from 'prop-types';
import React from 'react';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import './MoreDetailsTextAreaCard.scss';

export default class MoreDetailsTextAreaCard extends React.Component {
    constructor(props) {
        super();
        // this.state = {
        //     textAreaValue: props.defaultValue && props.defaultValue.length ? props.defaultValue : null,
        // }
    }

    static propTypes = {
        errorMessage: PropTypes.string,
        onChange: PropTypes.func,
        defaultValue: PropTypes.string,
        placeholder: PropTypes.string,
        clearTextArea: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
    };

    handleTextAreaInput = (textAreaValue) => {
        this.setState(
            {
                textAreaValue,
            },
            this.props.onChange(textAreaValue)
        );
    };

    render() {
        return (
            <div className="component-ReviewSurvey-QuestionCards-MoreDetailsTextAreaCard">
                <TextAreaInput
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.handleTextAreaInput}
                    defaultValue={this.props.defaultValue}
                />
                <div className="errorMessage">{this.props.errorMessage}</div>
            </div>
        );
    }
}
