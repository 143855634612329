const COMPANY_REVIEWS = 'companyReviews';
const JOB_FILTERS = 'jobFilters';
const SAVED_JOBS = 'savedJobs';
const SPONSORED_COMPANY = 'sponsoredCompany';
const SUGGESTED_JOBS = 'suggestedJobs';
const RECOMMENDED_CONNECTIONS = 'recommendedConnections';
const TRENDING_ARTICLES = 'trendingArticles';
const UPCOMING_EVENTS = 'upcomingEvents';
const RECOMMENDED_GROUPS = 'recommendedGroups';

export const componentTypes = {
    COMPANY_REVIEWS,
    JOB_FILTERS,
    RECOMMENDED_CONNECTIONS,
    SAVED_JOBS,
    SPONSORED_COMPANY,
    SUGGESTED_JOBS,
    TRENDING_ARTICLES,
    UPCOMING_EVENTS,
    RECOMMENDED_GROUPS,
};

export const componentMap = {
    [COMPANY_REVIEWS]: {
        type: 'job',
        path: '/RightRail/EmployerReviewsBox/EmployerReviewsBox',
    },
    [SUGGESTED_JOBS]: {
        type: 'companyReview',
        path: '/RightRail/JobsBox/JobsBox',
    },
    [TRENDING_ARTICLES]: {
        type: 'article',
        path: '/RightRail/TrendingContentBox/TrendingContentBox',
    },
};
