import React from 'react';
import PropTypes from 'prop-types';

import CharCount from '../../../../Elements/CharCount/CharCount';

import './InputLabel.scss';

export const inputTypes = ['text', 'textarea', 'select', 'check', 'radio'];

export const InputLabel = ({ text, type, htmlFor, charCount, maxChars }) => {
    const labelText = !text ? '' : text;
    const labelClassName = `component-Modals-ModalParts-Elements-InputLabel ${type}-input-label`.trim();

    const charCounter = maxChars ? (
        <CharCount length={charCount} limit={maxChars} />
    ) : null;

    return (
        <label htmlFor={htmlFor} className={labelClassName}>
            {labelText}
            {charCounter}
        </label>
    );
};

InputLabel.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(inputTypes),
    htmlFor: PropTypes.string.isRequired,
    charCount: PropTypes.number,
    maxChars: PropTypes.number,
};

InputLabel.defaultProps = {
    text: '',
    type: 'text',
    charCount: 0,
    maxChars: null,
};

InputLabel.displayName = 'InputLabel';

export default React.memo(InputLabel);
