import React from 'react';
import PropTypes from 'prop-types';
import { FlowContext } from './shared';

/**
 * This is a hook that exposes the current flow step, possible next destinations, current flow data, and the tree object
 * being used. Destinations is an object where the keys are possible destinations and each value is a
 * function to set the next step to its' value (i.e. move to that step).
 */

export const useFlowControls = () => {
    const { getFlowControls, step, tree, flowData } = React.useContext(
        FlowContext
    );

    return {
        step,
        tree,
        destinations: getFlowControls(),
        flowData: flowData || {},
    };
};

/**
 * FlowControl React Component
 *
 * Exposes the same data as the hook above but used as regular component.
 * it's children should be a function that takes in the controls above and returns some JSX
 * Props exposed are used to navigate to different steps in the flow
 * ex:
 * <FlowControl>
 *     { ({ step, tree, destinations, flowData }) => {
 *          return (
 *              <button onClick={() => destinations.step2({ step2Data: 'abc' })} />
 *          );
 *     }}
 * </FlowControl>
 *
 */

export const FlowControl = ({ children }) => {
    const flowControls = useFlowControls();
    return <React.Fragment>{children({ ...flowControls })}</React.Fragment>;
};

FlowControl.propTypes = {
    children: PropTypes.node.isRequired,
};
