import PropTypes from 'prop-types';
import React from 'react';

import './SVGIconWrapper.scss';

export default class SVGIconWrapper extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * children components wrapped inside SVGIconWrapper should be an svg image component,
         */
        children: PropTypes.node,
        /**
         * Function that SVGIconWrapper would triggered on click event
         */
        handleClickSVGWrapper: PropTypes.func,
        /**
         * Function that SVGIconWrapper would triggered on mouse enter event
         */
        handleMouseEnter: PropTypes.func,
        /**
         * Function that SVGIconWrapper would triggered on mouse leave event
         */
        handleMouseLeave: PropTypes.func,
        /**
         * css class that would take effect in SVGIconWrapper
         */
        SVGWrapperCssClass: PropTypes.string,
        /**
         * css class that would take effect in svg tag, there's example in SVGIconWrapper.md
         */
        SVGCssClass: PropTypes.string,
        /**
         * css class that would take effect in g tag, there's example in SVGIconWrapper.md
         */
        gCssClass: PropTypes.string,
        /**
         * addition css class that would take effect in g tag if there are different styling for more than one g tag, there's example in SVGIconWrapper.md
         */
        gAdditionalCssClass: PropTypes.string,
    };

    static defaultProps = {
        children: React.createElement('div', null),
        handleClickSVGWrapper: () => {},
        handleMouseEnter: () => {},
        handleMouseLeave: () => {},
        SVGWrapperCssClass: '',
    };

    render() {
        const {
            children,
            handleClickSVGWrapper,
            handleMouseEnter,
            handleMouseLeave,
            SVGWrapperCssClass,
            SVGCssClass,
            gCssClass,
            gAdditionalCssClass,
        } = this.props;

        const element = children ? React.Children.only(children) : <svg></svg>;

        return (
            <span
                role="img"
                className={`component-Icons-SVGIconWrapper ${SVGWrapperCssClass}`}
                onClick={handleClickSVGWrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {React.cloneElement(element, {
                    svgcssclass: SVGCssClass,
                    gcssclass: gCssClass,
                    gadditionalcssclass: gAdditionalCssClass,
                })}
            </span>
        );
    }
}
