import PropTypes from 'prop-types';
import React from 'react';
import {
    ALLOW_SUB_STEP,
    CAN_SUBMIT,
    CANT_SUBMIT,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonWithInputCard from '../../QuestionCards/ButtonWithInputCard/ButtonWithInputCard';

import './ParentalLeaveCard.scss';

export default class ParentalLeaveCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            offered: props.offered !== undefined ? props.offered : null,
            checkboxActive: !!props.parentalLeaveComment,
            paid: props.paid >= 0 ? props.paid : null,
            unpaid: props.unpaid >= 0 ? props.unpaid : null,
            buttonSelect: null,
            showInputFields:
                props.offered === true && props.paid >= 0 && props.unpaid >= 0,
        };
    }

    static propTypes = {
        paid: PropTypes.number,
        unpaid: PropTypes.number,
        questionName: PropTypes.string,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        // paid: null,
        // unpaid: null,
        questionName: 'parentalLeave',
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        let ParentalLeaveObject = { offered: this.props.offered };
        let nextAction = CAN_SUBMIT;

        if (
            this.props.offered === true &&
            this.props.paid >= 0 &&
            this.props.unpaid >= 0
        ) {
            ParentalLeaveObject.paid = this.props.paid;
            ParentalLeaveObject.unpaid = this.props.unpaid;
            if (this.props.parentalLeaveComment) {
                nextAction = ALLOW_SUB_STEP;
                ParentalLeaveObject.parentalLeaveComment = this.props.parentalLeaveComment;
            }
            this.props.onAnswer(
                this.props.questionName,
                ParentalLeaveObject,
                nextAction,
                false,
                this.props.nextStep,
                this.props.subStep
            );
        } else if (this.props.offered === false) {
            if (this.props.paid) delete ParentalLeaveObject.paid;
            if (this.props.unpaid) delete ParentalLeaveObject.unpaid;
            if (this.props.parentalLeaveComment)
                delete ParentalLeaveObject.parentalLeaveComment;
            this.props.onAnswer(
                this.props.questionName,
                ParentalLeaveObject,
                nextAction,
                false,
                this.props.nextStep,
                this.props.subStep
            );
        }
    }

    handlePaid = (paid) => {
        const newState = Object.assign({}, this.state);
        newState.paid = paid && paid.length ? Number(paid) : null;
        this.setState(newState, this.onAnswer);
    };

    handleUnpaid = (unpaid) => {
        const newState = Object.assign({}, this.state);
        newState.unpaid = unpaid && unpaid.length ? Number(unpaid) : null;
        this.setState(newState, this.onAnswer);
    };

    handleCheckbox = () => {
        const newState = Object.assign({}, this.state);
        newState.checkboxActive = !this.state.checkboxActive;
        this.setState(newState, this.onAnswer);
    };

    handleOffered = (offered) => {
        this.setState(
            {
                showInputFields: offered[0],
                offered: offered[0],
            },
            this.onAnswer
        );
    };

    onAnswer = () => {
        const { offered, paid, unpaid } = this.state;
        let nextAction = CANT_SUBMIT;
        const payload = {
            offered,
            paid,
            unpaid,
        };
        const numberRegex = /^([0-9]|[1-4][0-9]|5[0-2])$/;

        if (offered !== null && offered === false) {
            delete payload.paid;
            delete payload.unpaid;
            nextAction = SUBMIT;
        } else if (this.state.offered === true) {
            nextAction = CANT_SUBMIT;
            if (
                typeof paid === 'number' &&
                numberRegex.test(paid) &&
                typeof unpaid === 'number' &&
                numberRegex.test(unpaid)
            ) {
                nextAction = CAN_SUBMIT;
                if (this.state.checkboxActive) {
                    nextAction = ALLOW_SUB_STEP;
                    if (this.props.parentalLeaveComment) {
                        payload.parentalLeaveComment = this.props.parentalLeaveComment;
                    }
                }
            }
        }

        this.props.onAnswer(
            this.props.questionName,
            payload,
            nextAction,
            false,
            this.props.nextStep,
            this.props.subStep
        );
    };

    render() {
        const props = {
            schema: 'parentalLeaveSchema',
            buttonData: [
                {
                    title: 'Yes',
                    subtitle: null,
                    value: true,
                },
                {
                    title: 'No / Unsure',
                    subtitle: null,
                    value: false,
                },
            ],
            firstInputHandler: this.handlePaid,
            secondInputHandler: this.handleUnpaid,
            handleCheckbox: this.handleCheckbox,
            paidValue: this.props.paid,
            unpaidValue: this.props.unpaid,
            showInputFields: this.state.showInputFields,
            handleClick: this.handleOffered,
            defaultChecked: this.state.checkboxActive,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-ParentalLeaveCard">
                <ButtonWithInputCard
                    {...props}
                    selectedButtonValues={this.props.offered}
                />
                {this.props.errors ? (
                    <div className={'errorMessage pt-1'}>
                        {'Please enter a number between 0-52'}
                    </div>
                ) : null}
            </div>
        );
    }
}
