import React from 'react';

// Context creation hoisted to separate file to allow for use as static contextType in class components

export const CommunityContext = React.createContext({});
export const CompanyContext = React.createContext({});
export const PageContext = React.createContext({});
export const GroupContext = React.createContext({});
export const JobContext = React.createContext({});
export const JobSearchContext = React.createContext({});
export const JobReviewContext = React.createContext({});
export const MessageContext = React.createContext({});
export const PromotionalAdContext = React.createContext({});
export const JobApplyContext = React.createContext({});
export const ModalContext = React.createContext({});
export const AnalyticsContext = React.createContext({});
