export const calculateIsNewJob = (age) => {
    if (typeof age !== 'number') return false;
    return age <= 14;
};

export const calculateIsActivelyHiring = (jobCount) => {
    if (typeof jobCount !== 'number') return false;
    return jobCount >= 100;
};

export const buildMetrics = ({
    type = 'click',
    originalApplyUrl,
    category,
    company,
    hash,
    title,
    location,
    jobSearchQueryID,
    position,
    miscEventType,
    ctsName,
    ctsEvent,
}) => {
    return {
        apply_url: originalApplyUrl,
        company_id: [company.id],
        company_name: [company.name],
        job_category: category,
        job_slug: hash,
        job_title: title,
        location_name: location,
        position,
        search_id: jobSearchQueryID,
        [type]: 1,
        misc_event_type: miscEventType,
        jobs: [ctsName],
        ctsEvent,
    };
};

export const buildJobImpressionsMetrics = ({
    hash,
    component = 'tile',
    company,
    tilePosition,
    jobSearchQueryID,
    click = false,
}) => {
    const companyId = company?.id ? Number(company.id) : null;
    return {
        component,
        tileType: 'job',
        searchId: jobSearchQueryID,
        position: 'main',
        isExternal: false,
        companyId,
        pageType: 'collection',
        pageSection: 'company_profile',
        pageTab: 'company_jobs',
        tileEntityId: hash,
        isSponsored: false,
        tileOrder: tilePosition,
        tileLocation: 'company_profile',
        ...(click && {
            targetPageSection: 'job_detail',
        }),
    };
};

export const exampleStoryProps1 = {
    active: true,
    category: 'Sales',
    company: {
        id: 27899,
        jobCount: 787,
        name: 'Salesforce',
        url: 'https://fairygodboss.com/company/jobs/salesforce',
    },
    companyLogo: {
        src: '/v1513324814/production/companies/27899/logo.jpg',
        alt: 'salesforce-logo',
    },
    daysSinceCreation: 10,
    hash: 'solution-engineer-7e74f53663cebcd5d2f2e7af6049248f',
    hideReactions: false,
    isRemote: true,
    jobPageUrl:
        'https://fairygodboss.com/jobs/salesforce/solution-engineer-7e74f53663cebcd5d2f2e7af6049248f',

    location: 'Seoul, South Korea',
    originalApplyUrl:
        'https://salesforce.wd1.myworkdayjobs.com/en-US/External_Career_Site/job/Korea-Republic-of---Seoul/---Solution-Engineer-_JR68428',
    onClick: () => {},
    shortConvincer: {
        badge: {
            image: {
                src: 'v1/icons/convincer-shield-grey.svg',
                alt: 'badge-icon',
            },
        },
        text: 'Top Rated - Tech',
    },
    showExpired: false,
    title: 'Solutions Engineer',
    userLiked: true,
};

export const exampleStoryProps2 = {
    active: true,
    category: null,
    company: {
        id: 27301,
        jobCount: 586,
        name: '3M',
        url: 'https://fairygodboss.com/company/jobs/3m',
    },
    companyLogo: {
        src: '/companyLogo/hvvicocwtccjxnctrohl',
        alt: '3m-logo',
    },
    daysSinceCreation: 18,
    hash: 'regulatory-affairs-c70dd93b00fdf916c656929110302460',
    hideReactions: false,
    isRemote: false,
    jobPageUrl:
        'https://fairygodboss.com/jobs/3m/regulatory-affairs-c70dd93b00fdf916c656929110302460',
    location: 'Tokyo, Japan',
    originalApplyUrl: 'https://3m.recsolu.com/jobs/lyTA9Mvaq1AMl9fGzq0GCQ',
    onClick: () => {},
    shortConvincer: {
        badge: {
            image: {
                src: 'v1/icons/convincer-wing-grey.svg',
                alt: 'badge-icon',
            },
        },
        text: 'Highly rated - Job Satisfaction',
    },
    showExpired: false,
    title: 'Regulatory Affairs Officer',
    userLiked: false,
};
