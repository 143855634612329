import PropTypes from 'prop-types';
import React from 'react';
import PendingUserRequest from '../../../Users/PendingUserRequest/PendingUserRequest';
import GroupJoinButton from '../../Elements/GroupJoinButton/GroupJoinButton';
import MemberCountDisplay from '../../MemberCountDisplay/MemberCountDisplay';

import GroupOverviewTag from '../GroupOverviewTag/GroupOverviewTag';

import './GroupItem.scss';

export default class GroupItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLeaveButtonClicked: false,
            isJoinButtonClicked: false,
        };
    }

    static propTypes = {
        /**
         * object of group for rendering overview
         */
        group: PropTypes.shape({
            /**
             * string of group hash
             */
            hash: PropTypes.string.isRequired,
            /**
             * object of logo
             */
            logo: PropTypes.shape({
                /**
                 * string image src
                 */
                src: PropTypes.string,
                /**
                 * string image alt
                 */
                alt: PropTypes.string,
            }),
            /**
             * string group name
             */
            name: PropTypes.string.isRequired,
            /**
             * string group description
             */
            description: PropTypes.string.isRequired,
            /**
             * number of members in group
             */
            memberCount: PropTypes.number,
            /**
             * array of preview members
             */
            previewMembers: PropTypes.arrayOf(
                PropTypes.shape({
                    /**
                     * string image src
                     */
                    src: PropTypes.string,
                    /**
                     * string image alt
                     */
                    alt: PropTypes.string,
                })
            ),
        }).isRequired,
        /**
         * object of user relation to determine if user role to group
         */
        userRelation: PropTypes.shape({
            role: PropTypes.oneOf([
                'owner',
                'admin',
                'moderator',
                'member',
                'invited',
                'requested',
                null,
            ]),
        }),
        /**
         * object of user
         */
        user: PropTypes.object,
        /**
         * boolean to show tooltip over user icon hover
         */
        shouldShowTooltip: PropTypes.bool,
        /**
         * string of message to render in message bubble
         */
        bubbleMessage: PropTypes.string,
        /**
         * string enum of PENDING, CURRENT_GROUPS, RECOMMENDED
         */
        groupNetworkType: PropTypes.oneOf([
            'PENDING',
            'JOINED_GROUPS',
            'RECOMMENDED',
        ]).isRequired,
        /**
         * string of custom css class
         */
        className: PropTypes.string,
        /**
         * boolean to show cta buttons or not
         */
        shouldShowCtaButtons: PropTypes.bool,
        /**
         * string of clicked action of button
         */
        clickedAction: PropTypes.string,
        /**
         * function callback for setting clicked action or update count
         */
        onClick: PropTypes.func,
        /**
         * links to groups open new tab
         */
        openLinksInNewTab: PropTypes.bool,
        /**
         * disables leave button for group members
         */
        disableLeave: PropTypes.bool,
    };

    static defaultProps = {
        shouldShowTooltip: false,
        bubbleMessage: null,
        className: '',
        shouldShowCtaButtons: true,
        userRelation: { role: null },
        openLinksInNewTab: false,
        disableLeave: false,
    };

    renderPendingGroupInvites = () => {
        return (
            <PendingUserRequest
                clickedAction={this.props.clickedAction}
                user={this.props.user}
                bubbleMessage={this.props.bubbleMessage}
                shouldShowTooltip={false}
                className="pending-group-invites"
                requestType="GROUP_REQUEST"
                groupHash={this.props.group.hash}
                setClickedAction={this.props.onClick}
            />
        );
    };

    renderGroupsUserIsIn = () => {
        if (this.props.userRelation.role === 'owner') return null;

        let actionType = 'LEAVE';

        if (this.props.disableLeave) {
            actionType = 'LEAVE_DISABLED';
        }

        return (
            <GroupJoinButton
                actionType={actionType}
                group={this.props.group}
                onClick={this.props.onClick}
            />
        );
    };

    renderRecommendedGroups = () => {
        const { userRelation } = this.props;

        if (userRelation.role === 'owner') {
            return null;
        }

        let actionType,
            clickedAction = this.props.clickedAction;

        switch (userRelation.role) {
            case 'member':
            case 'moderator':
            case 'admin':
                actionType = this.props.disableLeave
                    ? 'LEAVE_DISABLED'
                    : 'LEAVE';
                break;
            case 'invited':
                actionType = 'ACCEPT';
                break;
            case 'requested':
                clickedAction = 'JOINED';
            default:
                actionType = 'JOIN';
        }

        return (
            <GroupJoinButton
                actionType={actionType}
                message={this.props.message}
                groupPrivacySetting={this.props.group.privacySetting}
                className="join-group-btn"
                group={this.props.group}
                onClick={this.props.onClick}
                clickedAction={clickedAction}
                modalTriggerParameters={this.props.modalTriggerParameters}
            />
        );
    };

    renderCTASection = () => {
        if (!this.props.shouldShowCtaButtons) {
            return null;
        }

        switch (this.props.groupNetworkType) {
            case 'PENDING':
                return this.renderPendingGroupInvites();
            case 'JOINED_GROUPS':
                return this.renderGroupsUserIsIn();
            case 'RECOMMENDED':
                return this.renderRecommendedGroups();
            default:
                return null;
        }
    };

    renderMemberDisplayComponent = () => {
        const { group, userRelation } = this.props;
        const { memberCount, previewMembers } = group;

        if (memberCount) {
            return (
                <MemberCountDisplay
                    count={memberCount}
                    previewMembers={previewMembers}
                />
            );
        }

        if (
            !userRelation ||
            !userRelation.role ||
            userRelation.role === 'invited' ||
            userRelation.role === 'requested'
        ) {
            return <div className="member-placeholder"></div>;
        }

        return null;
    };

    renderGroupOverview = () => {
        const { openLinksInNewTab, group } = this.props;
        const { logo, name, description, groupUrl } = group;
        return (
            <div className="group-overview">
                <GroupOverviewTag
                    logo={logo}
                    name={name}
                    description={description}
                    groupUrl={groupUrl}
                    openLinksInNewTab={openLinksInNewTab}
                />
                {this.renderMemberDisplayComponent()}
            </div>
        );
    };

    render() {
        return (
            <div
                className={`component-Groups-Network-GroupItem ${this.props.className}`}
            >
                {this.renderGroupOverview()}
                {this.renderCTASection()}
            </div>
        );
    }
}
