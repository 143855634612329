import PropTypes from 'prop-types';
import React from 'react';

import UserListItem from '../../Users/UserListItem/UserListItem';

export default class InvitedUser extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        invitedUser: PropTypes.object.isRequired,
        className: PropTypes.string,
        callbackFn: PropTypes.func,
    };

    static defaultProps = {
        className: 'network-item',
        callbackFn: () => {},
    };

    render() {
        const { invitedUser, className, callbackFn } = this.props;
        return (
            <li className="component-Network-InvitedUser">
                <UserListItem
                    user={invitedUser}
                    buttonsToRender={['CANCEL']}
                    connectionStatus="connected"
                    className={className}
                    onClickHandlers={{ onCancelCallback: callbackFn }}
                />
            </li>
        );
    }
}
