import React from 'react';

const LinkCopiedIcon = ({
    width = '32px',
    height = '32px',
    color = '#fff',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={{
            fill: 'rgb(255, 255, 255)',
            width,
            height,
        }}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm4.8 7.6a1 1 0 10-1.6-1.2l-5.308 7.078-2.185-2.185a1 1 0 00-1.414 1.414l3 3A1 1 0 0010.8 16.6l6-8z"
            clipRule="evenodd"
        />
    </svg>
);

export default LinkCopiedIcon;
