import axios from 'axios';
import {
    GENDER_IDENTITY_STEP_NAME,
    RACE_ETHNICITY_STEP_NAME,
    SEXUAL_ORIENTATION_STEP_NAME,
    VETERAN_DISABILITY_STEP_NAME,
    CONFIRM_SUCCESS_STEP_NAME,
} from '../Steps/constants';

import {
    validateGenderIdentityStep,
    validateRaceEthnicityStep,
    validateSexualOrientationStep,
    validateVeteranDisabilityStep,
} from '../../../Includes/FormSchema/UserProfileSchema';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';

export const ethnicityChoices = [
    'African American / Black',
    'Native American / Alaska Native',
    'East Asian',
    'Hispanic',
    'Latinx',
    'Middle Eastern',
    'Pacific Islander',
    'South Asian',
    'Southeast Asian',
    'White',
    'I prefer not to say',
    'Other',
];

export const genderChoices = [
    'Agender',
    'Woman',
    'Genderqueer or non-binary',
    'Man',
    'Transgender Man',
    'Transgender Woman',
    'Transgender Person',
    'I prefer not to say',
    'Other',
];

export const sexualOrientationChoices = [
    'Asexual',
    'Bisexual',
    'Gay',
    'Heterosexual or straight',
    'Lesbian',
    'Pansexual',
    'Queer',
    'Questioning or unsure',
    'An identity not listed',
    'I prefer not to say',
    'Other',
];

const flowTree = {
    [GENDER_IDENTITY_STEP_NAME]: [RACE_ETHNICITY_STEP_NAME],
    [RACE_ETHNICITY_STEP_NAME]: [
        GENDER_IDENTITY_STEP_NAME,
        SEXUAL_ORIENTATION_STEP_NAME,
    ],
    [SEXUAL_ORIENTATION_STEP_NAME]: [
        RACE_ETHNICITY_STEP_NAME,
        VETERAN_DISABILITY_STEP_NAME,
    ],
    [VETERAN_DISABILITY_STEP_NAME]: [
        SEXUAL_ORIENTATION_STEP_NAME,
        CONFIRM_SUCCESS_STEP_NAME,
    ],
    [CONFIRM_SUCCESS_STEP_NAME]: [],
};

export const buildDefaultDiversityFlowState = (data = {}) =>
    buildInitialFormState(Object.keys(flowTree), data);

export const buildDiversityInputFlow = (initializeOptions = {}) => {
    const validators = {
        genderIdentityValidator: validateGenderIdentityStep,
        raceEthnicityValidator: validateRaceEthnicityStep,
        sexualOrientationValidator: validateSexualOrientationStep,
        veteranDisabilityValidator: validateVeteranDisabilityStep,
    };

    return {
        flowTree,
        initialStep: GENDER_IDENTITY_STEP_NAME,
        initialFlowData: { ...initializeOptions, validators },
    };
};

export const getIdFromOptionList = (referenceList) => (textOpt) => {
    for (const optionObj of referenceList) {
        if (optionObj.title === textOpt) return optionObj.id;
    }
};

const convertResponsesForApiPayload = (responses = {}) => {
    // 11 is the ID for the option corresponding to "Other", since the ethnicityChoices and orientationChoices
    // arrays are ids we need to use this instead of the text "Other"
    const idForArrayOtherOption = 11;
    const convertedOtherComments = Object.keys(responses).reduce(
        (comments, stepName) => {
            const stepResponses = responses[stepName];
            if (
                stepName === 'genderIdentity' &&
                stepResponses.genderChoice === 'Other' &&
                stepResponses.optionalOtherText.length > 0
            ) {
                const genderComment = {
                    id: null,
                    type: 'gender',
                    comment: stepResponses.optionalOtherText,
                };
                // return our comments + the new comment
                return [...comments, genderComment];
            }

            if (
                stepName === 'raceEthnicity' &&
                stepResponses.ethnicityChoices.includes(
                    idForArrayOtherOption
                ) &&
                stepResponses.optionalOtherText.length > 0
            ) {
                const ethnicityComment = {
                    id: null,
                    type: 'ethnicity',
                    comment: stepResponses.optionalOtherText,
                };

                return [...comments, ethnicityComment];
            }

            if (
                stepName === 'sexualOrientation' &&
                stepResponses.orientationChoices.includes(
                    idForArrayOtherOption
                ) &&
                stepResponses.optionalOtherText.length > 0
            ) {
                const orientationComment = {
                    id: null,
                    type: 'orientation',
                    comment: stepResponses.optionalOtherText,
                };

                return [...comments, orientationComment];
            }

            // otherwise just return our comments
            return [...comments];
        },
        []
    );

    const {
        genderIdentity,
        raceEthnicity,
        sexualOrientation,
        veteranDisability,
    } = responses; // each step has responses

    // convert steps to shape consumable by API

    return {
        gender:
            genderIdentity.genderChoice.length > 0
                ? genderIdentity.genderChoice
                : null,
        ethnicity: raceEthnicity.ethnicityChoices,
        sexualOrientation: sexualOrientation.orientationChoices,
        veteran:
            veteranDisability.armedForcesChoice.length > 0
                ? veteranDisability.armedForcesChoice
                : null,
        disability:
            veteranDisability.disabilityChoice.length > 0
                ? veteranDisability.disabilityChoice
                : null,
        otherComments: convertedOtherComments,
    };
};

export const diversityInputFlowAPI = {
    submitDiversityInput: async (responses = {}) => {
        const payload = convertResponsesForApiPayload(responses);
        const endpoint = '/api/user/demographics';

        try {
            const response = await axios.patch(endpoint, payload);
            const { data } = response;
            if (data.meta.success) {
                EmitMetric({
                    misc_event_type: 'demographic-info-submitted',
                    misc_event_count: 1,
                });

                return { ok: true, data: data.data, errors: null };
            }
        } catch (error) {
            return { ok: false, errors: error };
        }
    },
};

export const confirmSuccessStepProps = {
    mainText: 'Thank you.',
    subtext: "You're helping us become a more diverse and inclusive community.",
    linkUrl: '/users/profile', // TODO: check for actual destination
    buttonText: 'Done',
    layout: 'diversity',
};

export const checkIsDemoFormFilled = (rawDemoInfo) => {
    return !!(
        rawDemoInfo.ethnicity.length > 0 ||
        rawDemoInfo.sexualOrientation.length > 0 ||
        rawDemoInfo.gender ||
        rawDemoInfo.veteran ||
        rawDemoInfo.disability
    );
};

/**
 * Serializer for Demographics Info
 */

/**
 * @param {array} comments
 * @param {string} type
 * @returns {string}
 */
export const getCommentByType = (comments = [], type = '') => {
    if (!type || !Array.isArray(comments)) {
        return '';
    }

    const foundComment = comments.filter((comment) => comment.type === type);
    if (foundComment.length === 0) {
        return '';
    }

    const foundCommentText = foundComment[0].comment;

    return foundCommentText;
};

/**
 * @param {array} ids
 * @param {array} options
 * @returns {array}
 */
export const getChoiceTextByIds = (ids = [], options = []) => {
    if (!Array.isArray(ids) || !Array.isArray(options)) {
        return [];
    }

    const selectedOptions = options.filter((option) => ids.includes(option.id));
    const selectedOptionsTexts = selectedOptions.map((option) => option.title);

    return selectedOptionsTexts;
};

/**
 * @param {object} rawDemoInfo
 * @returns {object}
 */
export const serializeRawDemoInfoToFlowState = (rawDemoInfo = {}) => {
    if (!rawDemoInfo) {
        return {
            [GENDER_IDENTITY_STEP_NAME]: {
                genderChoice: '',
                optionalOtherText: '',
            },
            [RACE_ETHNICITY_STEP_NAME]: {
                ethnicityChoices: [],
                optionalOtherText: '',
            },
            [SEXUAL_ORIENTATION_STEP_NAME]: {
                optionalOtherText: '',
                orientationChoices: [],
            },
            [VETERAN_DISABILITY_STEP_NAME]: {
                armedForcesChoice: '',
                disabilityChoice: '',
            },
        };
    }

    const serializedDemoInfo = {
        [GENDER_IDENTITY_STEP_NAME]: {
            genderChoice: rawDemoInfo.gender ? rawDemoInfo.gender : '',
            optionalOtherText: getCommentByType(
                rawDemoInfo.otherComments,
                'gender'
            ),
        },
        [RACE_ETHNICITY_STEP_NAME]: {
            ethnicityChoices: getChoiceTextByIds(
                rawDemoInfo.ethnicity,
                rawDemoInfo.ethnicityOptions
            ),
            optionalOtherText: getCommentByType(
                rawDemoInfo.otherComments,
                'ethnicity'
            ),
        },
        [SEXUAL_ORIENTATION_STEP_NAME]: {
            orientationChoices: getChoiceTextByIds(
                rawDemoInfo.sexualOrientation,
                rawDemoInfo.orientationOptions
            ),
            optionalOtherText: getCommentByType(
                rawDemoInfo.otherComments,
                'orientation'
            ),
        },
        [VETERAN_DISABILITY_STEP_NAME]: {
            armedForcesChoice: rawDemoInfo.veteran ? rawDemoInfo.veteran : '',
            disabilityChoice: rawDemoInfo.disability
                ? rawDemoInfo.disability
                : '',
        },
    };

    return serializedDemoInfo;
};
