import React from 'react';
import PropTypes from 'prop-types';

import './JobSeekerEducationLayout.scss';
import Button from '../../../Input/Button/Button';
import FormElementWithLabel from '../../../Input/FormElementWithLabel/FormElementWithLabel';
import InputField from '../../../Input/InputField/InputField';
import LocationInput from '../../../Input/LocationInput/LocationInput';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import BasicDropdown from '../../../Input/BasicDropdown/BasicDropdown';
import CheckBox from '../../../Content/Elements/CheckBox/CheckBox';
import { generateYearsArray } from '../../../Utilities/TimeFunctions';
import { renderYearsOptions } from '../JobSeekerModal';
import { jobSeekerEducationSchema } from '../../../Includes/FormSchema/JobProfileSchema';
import SchoolsTypeahead from '../../../Input/SchoolsTypeahead/SchoolsTypeahead';
import {
    hasLocationCheck,
    getLocationProps,
} from '../../../Utilities/LocationFunctions';

export const degreeOptions = [
    {
        value: 'High School',
        label: 'High School',
    },
    {
        value: 'Associate Degree',
        label: 'Associate Degree',
    },
    {
        value: "Bachelor's Degree",
        label: "Bachelor's Degree",
    },
    {
        value: "Master's Degree",
        label: "Master's Degree",
    },
    {
        value: 'Doctorate / PhD',
        label: 'Doctorate / PhD',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

export default class JobSeekerEducationLayout extends React.Component {
    constructor(props) {
        super(props);

        const checkIfOneOfValue = (array, valueToCheck) => {
            return !!(
                array.filter((arrayValue) => arrayValue.label === valueToCheck)
                    .length > 0
            );
        };

        this.state = {
            school: props.school || null,
            location: props.location || null,
            isRemote: props.isRemote || false,
            degree: checkIfOneOfValue(degreeOptions, props.degree)
                ? props.degree
                : null,
            field: props.field || null,
            startYear: props.startYear || null,
            endYear: props.endYear || null,
            isCurrent: props.isCurrent || false,
            description: props.description || null,
            error: props.error || {},
        };
    }

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
        school: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        location: PropTypes.shape({
            name: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        isRemote: PropTypes.bool,
        degree: PropTypes.string,
        field: PropTypes.string,
        startYear: PropTypes.number,
        endYear: PropTypes.number,
        isCurrent: PropTypes.bool,
        description: PropTypes.string,
        error: PropTypes.object,
        getLocationProps: PropTypes.func,
    };

    static defaultProps = {};

    getFieldLabelProps = (type) => {
        let label;
        let shouldShowTooltip = false;
        let tooltipText = null;
        let isRequired = false;
        switch (type) {
            case 'school':
                label = 'School';
                isRequired = true;
                break;
            case 'location':
                label = 'Location';
                isRequired = true;
                break;
            case 'degree':
                label = 'Degree';
                isRequired = true;
                break;
            case 'field-of-study':
                label = 'Field of study';
                break;
            case 'start-year':
                label = 'Start year';
                isRequired = false;
                break;
            case 'end-year':
                label = 'End year';
                isRequired = false;
                break;
            case 'description':
                label = 'Description';
                break;
            default:
                label = '';
        }
        return {
            label,
            shouldShowTooltip,
            tooltipText,
            isRequired,
        };
    };

    handleChange = (key, value) => {
        const newState = Object.assign({}, this.state);
        const newErrorState = Object.assign({}, this.state.error);
        newState[key] = value;
        newState.error = newErrorState;

        if (key === 'isRemote' && value === true) {
            newState.location = '';
            if (this.state.error && this.state.error.location) {
                newErrorState.location = '';
            }
        }

        if (key === 'isCurrent' && value === true) {
            newState.endYear = null;
            if (this.state.error && this.state.error.endYear) {
                newErrorState.endYear = '';
            }
        }

        if (this.props.hasError(key, this.state.error)) {
            newErrorState[key] = null;
        }

        this.setState(newState);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const {
            school,
            location,
            isRemote,
            degree,
            field,
            startYear,
            endYear,
            isCurrent,
            description,
        } = this.state;
        const locationProps = getLocationProps(location);

        const educationObject = {
            school,
            location:
                hasLocationCheck(this.state.location) && !isRemote
                    ? {
                          name: locationProps.value,
                          lat: locationProps.lat,
                          lng: locationProps.lng,
                      }
                    : null,
            isRemote,
            degree,
            field,
            isCurrent,
            startYear:
                typeof startYear === 'string' ? parseInt(startYear) : startYear,
            endYear: typeof endYear === 'string' ? parseInt(endYear) : endYear,
            description,
        };

        let error = Object.assign({}, this.state.error);
        const isValid = await this.props.validateForm(
            educationObject,
            jobSeekerEducationSchema,
            error,
            this.setErrorState
        );

        if (!isValid) {
            return;
        }
        this.props.handleSubmit(educationObject);
    };

    setErrorState = (error) => {
        this.setState({
            error,
        });
    };

    render() {
        const {
            school,
            location,
            isRemote,
            degree,
            field,
            startYear,
            endYear,
            isCurrent,
            description,
        } = this.state;
        const locationValue =
            location && (location.value || location.name || '');
        return (
            <form
                className="component-Modals-JobSeekerModal-JobSeekerEducationLayout"
                onSubmit={(e) => this.handleSubmit(e)}
            >
                <SchoolsTypeahead
                    items={school ? [school] : []}
                    error={this.props.renderErrorString(
                        'school',
                        this.state.error
                    )}
                    onUpdate={(value) => this.handleChange('school', value)}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('school', this.state.error)
                    )}`}
                    allowAddNewItem={true}
                    isRequired={true}
                />
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('location')}
                    error={this.props.renderErrorString(
                        'location',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('location', this.state.error)
                    )}  ${this.props.disabledStylingCheck(isRemote)}`}
                    haveErrorSpace={false}
                >
                    <LocationInput
                        value={locationValue}
                        onChange={(value) =>
                            this.handleChange('location', value)
                        }
                        disabled={isRemote}
                    />
                </FormElementWithLabel>
                <CheckBox
                    id={'remote-checkbox'}
                    handleChange={(value) =>
                        this.handleChange('isRemote', value)
                    }
                    defaultChecked={isRemote}
                    label={'Remote'}
                />
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('degree')}
                    error={this.props.renderErrorString(
                        'degree',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('degree', this.state.error)
                    )} degree-field`}
                >
                    <BasicDropdown
                        options={degreeOptions}
                        defaultLabel={"Ex. Bachelor's"}
                        defaultValueIsNull={true}
                        selectedValue={degree}
                        onChange={(value) => this.handleChange('degree', value)}
                        shouldDisableDefault={true}
                        qaDataAttr={'degree'}
                    />
                </FormElementWithLabel>
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('field-of-study')}
                    error={this.props.renderErrorString(
                        'field',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('field', this.state.error)
                    )}`}
                >
                    <InputField
                        name={'field-of-study'}
                        inputValue={field}
                        onChange={(value) => this.handleChange('field', value)}
                    />
                </FormElementWithLabel>
                <div className="double-form-element-styling">
                    <FormElementWithLabel
                        fieldLabelProps={this.getFieldLabelProps('start-year')}
                        error={this.props.renderErrorString(
                            'startYear',
                            this.state.error
                        )}
                        className={`${this.props.errorStylingCheck(
                            this.props.hasError('startYear', this.state.error)
                        )}`}
                        haveErrorSpace={false}
                    >
                        <BasicDropdown
                            options={renderYearsOptions(
                                generateYearsArray(60, 0).reverse()
                            )}
                            defaultLabel={'Year'}
                            defaultValueIsNull={true}
                            selectedValue={startYear}
                            onChange={(value) =>
                                this.handleChange('startYear', value)
                            }
                            shouldDisableDefault={true}
                            qaDataAttr={'start year'}
                        />
                    </FormElementWithLabel>
                    <FormElementWithLabel
                        fieldLabelProps={this.getFieldLabelProps('end-year')}
                        error={this.props.renderErrorString(
                            'endYear',
                            this.state.error
                        )}
                        className={`${this.props.errorStylingCheck(
                            this.props.hasError('endYear', this.state.error)
                        )}  ${this.props.disabledStylingCheck(isCurrent)}`}
                        haveErrorSpace={false}
                    >
                        <BasicDropdown
                            options={renderYearsOptions(
                                generateYearsArray(60, 10).reverse()
                            )}
                            defaultLabel={'Year'}
                            defaultValueIsNull={true}
                            selectedValue={endYear}
                            disabled={isCurrent}
                            onChange={(value) =>
                                this.handleChange('endYear', value)
                            }
                            shouldDisableDefault={true}
                            qaDataAttr={'end year'}
                        />
                    </FormElementWithLabel>
                </div>
                <CheckBox
                    id={'education-remote'}
                    handleChange={(value) =>
                        this.handleChange('isCurrent', value)
                    }
                    defaultChecked={isCurrent}
                    label={'I am currently attending this school'}
                />
                <FormElementWithLabel
                    fieldLabelProps={this.getFieldLabelProps('description')}
                    elementValue={description}
                    inputMaxLength={2000}
                    error={this.props.renderErrorString(
                        'description',
                        this.state.error
                    )}
                    className={`${this.props.errorStylingCheck(
                        this.props.hasError('description', this.state.error)
                    )}`}
                >
                    <TextAreaInput
                        name={'description'}
                        value={description}
                        onChange={(value) =>
                            this.handleChange('description', value)
                        }
                    />
                </FormElementWithLabel>
                <div className="bottom-button-container">
                    <div className="delete-button-container">
                        <Button
                            value={'Delete'}
                            inputStyle={'wired-blue'}
                            onClick={this.props.handleDelete}
                        />
                    </div>
                    <div className="done-button-container">
                        <Button
                            value={'Done'}
                            inputStyle="solid"
                            formAction={'submit'}
                        />
                    </div>
                </div>
            </form>
        );
    }
}
