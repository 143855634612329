import React from 'react';
import PropTypes from 'prop-types';

import './Hamburger.scss';
import IconTitleItemLayout from '../../../Layout/IconTitleItemLayout/IconTitleItemLayout';

export default class Hamburger extends React.PureComponent {
    static propTypes = {
        /**
         * meta data of a navbar item
         */
        metaData: PropTypes.object.isRequired,
        /**
         * handler function for setting an action on navbar
         */
        setActionState: PropTypes.func.isRequired,
        /**
         * handler function for emitting a metric event
         */
        handleMetricEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        metaData: {},
        setActionState: () => {},
        handleMetricEvent: () => {},
    };

    render() {
        const { metaData, setActionState, handleMetricEvent } = this.props;
        const handleClick = () =>
            setActionState(
                'isLeftDrawerOpen',
                true,
                handleMetricEvent(metaData.metricEvent)
            );

        return (
            <IconTitleItemLayout
                itemKey={metaData.key}
                className={`component-Layout-Header-Modules-NavbarItems-Hamburger ${metaData.className}`}
                url={metaData.url}
                title={metaData.title}
                imgSrc={metaData.imgSrc}
                handleClick={handleClick}
            />
        );
    }
}
