import PropTypes from 'prop-types';
import React from 'react';
import SocialMediaIcons from '../../../../Icons/SocialMediaIcons/SocialMediaIcons';

import './SocialMediaDisplay.scss';

export default class SocialMediaDisplay extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        socialIcons: PropTypes.array.isRequired,
        socialLikes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        socialIcons: [],
        socialLikes: {},
    };

    render() {
        return (
            <div className="component-Layout-Footer-Components-SocialMediaDisplay">
                {this.props.socialIcons.map((icon) => {
                    const iconInfo = {
                        type: icon.type,
                        url: icon.url,
                        count: this.props.socialLikes[icon.type],
                    };
                    return <SocialMediaIcons {...iconInfo} key={icon.type} />;
                })}
            </div>
        );
    }
}
