import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { PASSWORD_MATCH } from '../../../validation/constants';
import { resetPasswordSchema } from '../../../validation/resetPasswordSchema';
import Button from '../../Input/Button/Button';
import InputField from '../../Input/InputField/InputField';
import { errorMessagesMap } from './constants';
import './ForceResetPasswordModal.scss';
import Alert from '../../Messages/Alert';
import FlexPopup from '../../Elements/FlexPopup/FlexPopup';
import { PageContext } from '../../../contexts/ContextCreator';

const ForceResetPasswordModal = (props) => {
    const { closeModal, setSystemPasswordReseted } = useContext(PageContext);
    let invalid = false;
    const fromEmail = props && typeof props.isValidToken !== 'undefined';
    if (fromEmail && !props.isValidToken) {
        invalid = true;
    }
    const [title, setTitle] = useState(invalid ? 'Error' : '');
    const [updated, setUpdated] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState([]);
    const [confirmPasswordError, setConfirmPasswordError] = useState([]);

    useEffect(() => {
        if (!invalid) {
            setTitle(updated ? 'Password updated' : 'Reset your password');
        }
    }, [updated, invalid]);

    const validateForm = async (pass, confirmPass) => {
        const yupSchema = await resetPasswordSchema();
        try {
            await yupSchema.validate(
                {
                    password: pass,
                    confirmPassword: confirmPass,
                },
                { abortEarly: false }
            );
            setPasswordError([]);
            setConfirmPasswordError([]);
        } catch (error) {
            const passwordErrors = [];
            const confirmErrors = [];
            error.errors.forEach((element) => {
                if (element === PASSWORD_MATCH) {
                    confirmErrors.push(errorMessagesMap[element]);
                } else {
                    passwordErrors.push(errorMessagesMap[element]);
                }
            });
            if (pass !== '') setPasswordError(passwordErrors);
            if (confirmPass !== '') setConfirmPasswordError(confirmErrors);
            return false;
        }
        return true;
    };

    const handleSave = async (event) => {
        event.preventDefault();
        if (await validateForm(password, confirmPassword)) {
            const newPasswordChangeRequest = {
                password,
                confirmPassword,
            };
            // case when the system request to reset the password (no token needed)
            let url = '/auth-v2/password-reset';
            if (props.token) {
                // case when the user request to reset the password (token received)
                newPasswordChangeRequest.token = props.token;
                url = '/api/auth-v2/reset-password';
            }
            try {
                const result = await axios.post(url, newPasswordChangeRequest);
                if (
                    result.data.success ||
                    (result.data.meta && result.data.meta.success)
                ) {
                    setUpdated(true);
                    if (!fromEmail) {
                        setSystemPasswordReseted(true);
                    }
                }
            } catch (error) {
                setPasswordError([error]);
            }
        }
    };

    const successMessage = invalid
        ? 'Oops, that link has expired.'
        : "You've successfully changed your password.";

    const disableButon = password.length === 0 || confirmPassword.length === 0;

    return (
        <FlexPopup hidden={false} closeOnOutsideClick={false}>
            <div className="modal-container">
                <div className="modal-container-header">{title}</div>
                {!updated && !invalid && (
                    <div>
                        {!fromEmail && (
                            <div className="modal-container-description">
                                {`We've updated our password requirements to keep your information
            safe. Click save to reset your password.`}
                            </div>
                        )}
                        <section className="reset-password-input-section passwordInputSection">
                            <div className="reset-password-input-label">
                                New password:
                            </div>
                            <div className="reset-password-input-rules">
                                In order to protect your account, make sure your
                                password:
                                <ul>
                                    <li>Is at least 8 characters</li>
                                    <li>
                                        Contains at least 1 uppercase and
                                        lowercase letter
                                    </li>
                                    <li>Contains at least 1 number</li>
                                    <li>
                                        Contains at least 1 special character
                                    </li>
                                </ul>
                            </div>
                            <InputField
                                name="new-password-input"
                                value={password}
                                type="password"
                                onChange={(value) => {
                                    setPasswordError([]);
                                    setConfirmPasswordError([]);
                                    setPassword(value);
                                }}
                                onBlur={() =>
                                    validateForm(password, confirmPassword)
                                }
                            />
                            <div className="error-message">
                                <ul>
                                    {passwordError.map((error) => (
                                        <li>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                        <section className="reset-password-input-section confirmPasswordInputSection">
                            <div className="reset-password-input-label">
                                Confirm password:
                            </div>
                            <InputField
                                name="password-confirm-input"
                                value={confirmPassword}
                                type="password"
                                onChange={(value) => {
                                    setConfirmPasswordError([]);
                                    setConfirmPassword(value);
                                }}
                                onBlur={() =>
                                    validateForm(password, confirmPassword)
                                }
                            />
                            <div className="error-message errorMessage">
                                {confirmPasswordError}
                            </div>
                        </section>
                        <section className="reset-password-action-section">
                            <Button
                                type="solid"
                                value="Save"
                                disabled={disableButon}
                                onClick={(event) => handleSave(event)}
                            />
                        </section>
                    </div>
                )}
                {(updated || invalid) && (
                    <div className="modal-container-body">
                        <Alert
                            message={successMessage}
                            cloudinaryID="/SVGs/check_icon.svg"
                        />
                        <div className="modal-container-success-action">
                            <Button
                                type="solid"
                                value="Continue"
                                className="modal-container-success-button"
                                onClick={() => {
                                    if (fromEmail) {
                                        window.location.href = '/login';
                                    } else {
                                        closeModal();
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </FlexPopup>
    );
};

export default ForceResetPasswordModal;
