const candidateSelectors = [
    'input',
    'select',
    'textarea',
    'a[href]',
    'button',
    '[tabindex]',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])',
];

const candidateSelector = candidateSelectors.join(',');

const matches =
    typeof Element === 'undefined'
        ? function () {}
        : Element.prototype.matches ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;

function tabbable(el, options) {
    options = options || {};

    let regularTabbables = [];
    let orderedTabbables = [];

    let candidates = el.querySelectorAll(candidateSelector);

    if (options.includeContainer) {
        if (matches.call(el, candidateSelector)) {
            candidates = Array.prototype.slice.apply(candidates);
            candidates.unshift(el);
        }
    }

    let candidate;
    let candidateTabIndex;

    for (let i = 0; i < candidates.length; i++) {
        candidate = candidates[i];

        if (!isNodeMatchingSelectorTabbable(candidate)) continue;

        candidateTabIndex = getTabIndex(candidate);
        if (candidateTabIndex === 0) {
            regularTabbables.push(candidate);
        } else {
            orderedTabbables.push({
                documentOrder: i,
                tabIndex: candidateTabIndex,
                node: candidate,
            });
        }
    }

    const tabbableNodes = orderedTabbables
        .sort(sortOrderedTabbables)
        .map((a) => a.node)
        .concat(regularTabbables);

    return tabbableNodes;
}

tabbable.isTabbable = isTabbable;
tabbable.isFocusable = isFocusable;

function isNodeMatchingSelectorTabbable(node) {
    if (
        !isNodeMatchingSelectorFocusable(node) ||
        isNonTabbableRadio(node) ||
        getTabIndex(node) < 0
    ) {
        return false;
    }
    return true;
}

function isTabbable(node) {
    if (!node) throw new Error('No node provided');
    if (matches.call(node, candidateSelector) === false) return false;
    return isNodeMatchingSelectorTabbable(node);
}

function isNodeMatchingSelectorFocusable(node) {
    if (node.disabled || isHiddenInput(node) || isHidden(node)) {
        return false;
    }
    return true;
}

let focusableCandidateSelector = candidateSelectors.concat('iframe').join(',');

function isFocusable(node) {
    if (!node) throw new Error('No node provided');
    if (matches.call(node, focusableCandidateSelector) === false) return false;
    return isNodeMatchingSelectorFocusable(node);
}

function getTabIndex(node) {
    const tabIndexAttr = parseInt(node.getAttribute('tabindex'), 10);
    if (!isNaN(tabIndexAttr)) return tabIndexAttr;
    if (isContentEditable(node)) return 0;
    return node.tabIndex;
}

function sortOrderedTabbables(a, b) {
    return a.tabIndex === b.tabIndex
        ? a.documentOrder - b.documentOrder
        : a.tabIndex - b.tabIndex;
}

function isContentEditable(node) {
    return node.contentEditable === 'true';
}

function isInput(node) {
    return node.tagName === 'INPUT';
}

function isHiddenInput(node) {
    return isInput(node) && node.type === 'hidden';
}

function isRadio(node) {
    return isInput(node) && node.type === 'radio';
}

function isNonTabbableRadio(node) {
    return isRadio(node) && !isTabbleRadio(node);
}

function getCheckedRadio(nodes) {
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
            return nodes[i];
        }
    }
}

function isTabbleRadio(node) {
    if (!node.name) return true;
    let radioSet = node.ownerDocument.querySelectorAll(
        'input[type="radio"][name="' + node.name + '"]'
    );
    let checked = getCheckedRadio(radioSet);
    return !checked || checked === node;
}

function isHidden(node) {
    return (
        node.offsetParent === null ||
        getComputedStyle(node).visibility === 'hidden'
    );
}

export default tabbable;
