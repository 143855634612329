/* eslint-disable */
// TODO: this context is fundamentally broken and unsound. REFACTOR REQUIRED.

import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
// TODO: emit metrics for actions taken
import { groupActions } from '../components/Includes/GroupActionTypes';
import { PAGE_PROPS } from '../pages/defaultProps';

import { GroupContext as Context } from './ContextCreator';

export class GroupProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: props.group,
            /**
             * user relationship to the group: null if not attributed
             *  role: "member", "owner", "admin", "moderator", "requested" or null
             */
            userRelation: props.userRelation,
            /**
             * group members info
             */
            memberResults: {
                members: [],
                page: 1,
                resultsPerPage: 24,
                startNum: 0,
                endNum: 0,
                total: 0,
            },
            setGroup: (group) => this.setState({ group: group }),
            setUserRelation: (userRelation) => this.setState({ userRelation: userRelation }),
            getUserRelation: () => this.state.userRelation,
            /**
             * checks if logged in user if a member (any type of member) of a groups or superadmin of our site
             * if not logged in, returns false
             * @return {boolean}
             */
            isMember: this.isMember,
            /**
             * checks if logged in user has permission to perform given action
             * @param{string} action: "editGroup", "deleteGroup", "manageMembers", "viewContents", "postContent", "showAdminMenu"
             */
            hasPermission: this.hasPermission,
            /**
             * calls the get member list api to get the members of the group for given page
             * @param {integer} page
             * @param {integer} resultsPerPage
             * @return {void}
             */
            getMemberList: this.getMemberList,
            /**
             * a function return object contains two function to get the previous and next page member results
             * if there's no previous or next page, it will return null instead of a function
             * @return {object}
             */
            getMemberListPagination: this.getMemberListPagination,
            /**
             * call the join request api to request to join a group with/without questions
             * if success set the role in userRelation state to "requested"
             * else handles errors
             * @param {string} inviteQuestionAnswer: nullable if not require to answer a question
             * @return {object}
             */
            requestToJoin: this.requestToJoin,
            /**
             * function for user to accept group invitation
             * if success set the role in userRelation state to "member"
             * @return {string} "accepted"
             */
            acceptInvitation: this.acceptInvitation,
            /**
             * function for group admin to approve or reject an join request
             * @param {integer} userId
             * @return {string} "approved" or "rejected"
             */
            processJoinRequest: this.processJoinRequest,
            /**
             * get a list of user join request getMemberList,
             * supports pagination
             * @param {integer} page, default 1
             * @param {integer} resultsPerPage, default 24
             * @return {object}
             */
            getRequestList: this.getRequestList,
            /**
             * call update member api to update a member's ModerationPermission
             * @param {integer} userId
             * @param {string} memberType: "admin", "moderator" or "member"
             * @return {object}
             */
            updateMember: this.updateMember,
            /**
             * call remove member api to remove a member from group
             * @param {integer} userId
             * @return {object}
             */
            removeMember: this.removeMember,
            /**
             * check if user is superadmin of our site
             * @return {boolean}
             */
            isSuperadmin: this.isSuperadmin,
        };
    }

    isSuperadmin = () =>
        this.props.PAGE_PROPS.session &&
        this.props.PAGE_PROPS.session.additionalRoles
            ? this.props.PAGE_PROPS.session.additionalRoles.includes(
                  'superadmin'
              )
            : false;

    isMember = () =>
        this.isSuperadmin() ||
        (this.state.userRelation &&
            ['owner', 'admin', 'moderator', 'member'].includes(
                this.state.userRelation.role
            ));

    _getMemberType = () =>
        this.state.userRelation ? this.state.userRelation.role : null;

    hasPermission = (action) => {
        if (action !== groupActions.GROUP_CHAT && this.isSuperadmin()) {
            return true;
        }

        const { contentIsPrivate, privacySetting } = this.state.group;
        const memberType = this._getMemberType();
        switch (action) {
            case groupActions.DELETE_GROUP:
                return ['owner'].includes(memberType);
            case groupActions.EDIT_GROUP:
                return ['owner', 'admin'].includes(memberType);
            case groupActions.SHOW_ADMIN_MENU:
                return ['owner', 'admin'].includes(memberType);
            case groupActions.SHOW_ADMIN_ONBOARDING:
                return ['owner', 'admin'].includes(memberType);
            case groupActions.MANAGE_MEMBERS:
                return ['owner', 'admin'].includes(memberType);
            case groupActions.VIEW_CONTENTS:
                return contentIsPrivate
                    ? ['owner', 'admin', 'moderator', 'member'].includes(
                          memberType
                      )
                    : true;
            case groupActions.POST_CONTENTS:
                return ['owner', 'admin', 'moderator', 'member'].includes(
                    memberType
                );
            case groupActions.GROUP_CHAT:
                return ['owner', 'admin', 'moderator', 'member'].includes(
                    memberType
                );
            case groupActions.CAN_INVITE:
                if (
                    privacySetting === 'closed' ||
                    privacySetting === 'secret'
                ) {
                    return ['owner', 'admin'].includes(memberType);
                }
                return ['owner', 'admin', 'moderator', 'member'].includes(
                    memberType
                );
            default:
                return false;
        }
    };

    getMemberList = async (
        page = 1,
        resultsPerPage = 24,
        updateState = true
    ) => {
        try {
            const res = await axios.get(
                `/api/groups/list-members/${this.state.group.hash}?page=${page}&resultsPerPage=${resultsPerPage}`
            );

            if (updateState) {
                const { results, startNum, endNum, total } = res.data;
                this.setState({
                    memberResults: {
                        members: results,
                        page,
                        resultsPerPage,
                        startNum,
                        endNum,
                        total,
                    },
                });
            }

            return res.data;
        } catch (e) {
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to get member list at the moment.'],
                },
            };
        }
    };

    getMemberListPagination = () => {
        const { page, total, resultsPerPage } = this.state.memberResults;
        let goToPrevPage = null;
        let goToNextPage = null;

        if (page > 1) {
            goToPrevPage = () => this.getMemberList(page - 1, resultsPerPage);
        }

        if (page * resultsPerPage < total) {
            goToNextPage = () => this.getMemberList(page + 1, resultsPerPage);
        }

        return { goToPrevPage, goToNextPage };
    };

    // request to join a group, if group privacy setting is "open", user will be auto-accepted
    // if success, set state for updated group and user relation props returned from api
    requestToJoin = async (inviteQuestionAnswer = null) => {
        try {
            const res = await axios.post(
                `/api/groups/join-request/${this.state.group.hash}`,
                {
                    inviteQuestionAnswer,
                }
            );

            const { group, userRelation } = res.data.data;
            this.setState({ group, userRelation });

            return {
                meta: {
                    success: true,
                },
                data: {
                    status: 'requested',
                },
                errors: {},
            };
        } catch (e) {
            if (e.response.status === 400) {
                // validation errors
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to perform this action at the moment.'],
                },
            };
        }
    };

    acceptInvitation = async () => {
        try {
            const res = await axios.post(
                '/api/network/group/accept-invitation',
                {
                    communityGroupHash: this.state.group.hash,
                }
            );

            this.setState({ userRelation: { role: 'member' } });

            return {
                meta: {
                    success: true,
                },
                data: {
                    status: 'accepted',
                },
                errors: {},
            };
        } catch (e) {
            if (e.response.status === 400) {
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to perform this action at the moment.'],
                },
            };
        }
    };

    processJoinRequest = async (userId, approve) => {
        try {
            await axios.post(
                `/api/groups/approve-member/${this.state.group.hash}`,
                {
                    userId,
                    approve,
                }
            );

            return {
                meta: {
                    success: true,
                },
                data: {
                    status: approve ? 'approved' : 'rejected',
                },
                errors: {},
            };
        } catch (e) {
            if (e.response.status === 400) {
                // validation errors
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to perform this action at the moment.'],
                },
            };
        }
    };

    getRequestList = async (page = 1, resultsPerPage = 24) => {
        let res;
        try {
            res = await axios.get(
                `/api/groups/member-requests/${this.state.group.hash}?page=${page}&resultsPerPage=${resultsPerPage}`
            );
            return res.data;
        } catch (e) {
            if (e.response.status === 400) {
                // validation errors
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to get the request list at the moment.'],
                },
            };
        }
    };

    updateMember = async (userId, memberType) => {
        try {
            await axios.post(
                `/api/groups/update-member/${this.state.group.hash}`,
                {
                    userId,
                    memberType,
                }
            );

            return {
                meta: {
                    success: true,
                },
                data: {
                    updatedMemberType: memberType,
                },
                errors: {},
            };
        } catch (e) {
            if (e.response.status === 400) {
                // validation errors
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to update member at the moment.'],
                },
            };
        }
    };

    removeMember = async (userId) => {
        try {
            await axios.post(
                `/api/groups/remove-member/${this.state.group.hash}`,
                {
                    userId,
                }
            );

            return {
                meta: {
                    success: true,
                },
                data: {},
                errors: {},
            };
        } catch (e) {
            if (e.response.status === 400) {
                // validation errors
                return e.response.data;
            }
            return {
                meta: {
                    success: false,
                },
                data: {},
                errors: {
                    _general: ['Unable to remove member at the moment.'],
                },
            };
        }
    };

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const GroupConsumer = Context.Consumer;

GroupProvider.propTypes = {
    PAGE_PROPS,
    group: PropTypes.object.isRequired,
    userRelation: PropTypes.shape({
        role: PropTypes.string,
    }),
};

GroupProvider.defaultProps = {
    PAGE_PROPS: {
        session: null,
    },
    userRelation: null,
};
