import React from 'react';
import PropTypes from 'prop-types';

import FlowStep from '../../FlowStep';
import CheckGroup from '../../../ModalParts/Inputs/CheckGroup/CheckGroup';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';

import usePrevious from '../../../../../utilities/hooks/usePrevious';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import { RACE_ETHNICITY_STEP_NAME } from '../constants';
import { ethnicityChoices } from '../../DiversityInput/constants';

import './RaceEthnicity.scss';

export const shouldDisableRestCheckboxes = (checkedValues = []) =>
    checkedValues.length === 1 && checkedValues.includes('I prefer not to say');

const RaceEthnicity = ({ buttonNavBox }) => {
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
        stepErrors,
    } = useGetStepFormProps(RACE_ETHNICITY_STEP_NAME);

    const handleCheckSelect = React.useCallback(
        (checkedValues) => {
            updateFormValueAtPath('raceEthnicity.ethnicityChoices')(
                checkedValues
            );
        },
        [updateFormValueAtPath]
    );

    const currentEthnicityChoices = stepFormProps.ethnicityChoices;
    const prevEthnicityChoices = usePrevious(currentEthnicityChoices) || [];

    // if use selects "I prefer not to say", remove all other selections and disable rest of checkboxes
    React.useEffect(() => {
        if (
            currentEthnicityChoices.includes('I prefer not to say') &&
            !prevEthnicityChoices.includes('I prefer not to say')
        ) {
            return updateFormValueAtPath('raceEthnicity.ethnicityChoices')([
                'I prefer not to say',
            ]);
        }
    }, [currentEthnicityChoices, prevEthnicityChoices, updateFormValueAtPath]);

    const restCheckboxesAreDisabled = shouldDisableRestCheckboxes(
        currentEthnicityChoices
    );

    // Reset optionalOtherText when user selects Other, types text, then de-selects Other
    React.useEffect(() => {
        if (
            prevEthnicityChoices.includes('Other') &&
            !currentEthnicityChoices.includes('Other')
        ) {
            return updateFormValueAtPath('raceEthnicity.optionalOtherText')('');
        }
    }, [currentEthnicityChoices, prevEthnicityChoices, updateFormValueAtPath]);

    return (
        <FlowStep name={RACE_ETHNICITY_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-RaceEthnicity">
                <div className="primary-step-text">
                    What is your racial or ethnic identity? (Select all that
                    apply.)
                </div>
                <CheckGroup
                    groupName={'raceEthnicity.ethnicityChoices'}
                    checkedValues={stepFormProps.ethnicityChoices}
                    onChange={handleCheckSelect}
                >
                    {(Checkbox) =>
                        ethnicityChoices.map((ec) => {
                            if (ec === 'I prefer not to say')
                                return <Checkbox key={ec} value={ec} />;

                            return (
                                <Checkbox
                                    key={ec}
                                    value={ec}
                                    disabled={restCheckboxesAreDisabled}
                                />
                            );
                        })
                    }
                </CheckGroup>
                <div className="optional-other-box">
                    {stepFormProps.ethnicityChoices.includes('Other') ? (
                        <LongTextInput
                            inputName="raceEthnicity.optionalOtherText"
                            value={stepFormProps.optionalOtherText}
                            placeholder="Please specify..."
                            type="textarea"
                            error={stepErrors.optionalOtherText}
                            handleChange={updateFormValues}
                            rows={1}
                            maxChars={200}
                        />
                    ) : null}
                </div>
            </div>
            {buttonNavBox}
        </FlowStep>
    );
};

RaceEthnicity.propTypes = {
    buttonNavBox: PropTypes.node.isRequired,
};

RaceEthnicity.defaultProps = {};

export default React.memo(RaceEthnicity);
