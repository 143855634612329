import { triggerSnowplowImpressionEvent } from '@themuse/snowplow-js-client';
import { pagesToUse } from './snowPlowPages';

const MEDIA_TYPE_VIDEO = 'video';

export const SnowplowImpressionTracker = ({
    component,
    tileType = null,
    modalType = null,
    modalScreenOrder = null,
    modalScreenName = null,
    buttonType = null,
    insertType = null,
    cardType = null,
    impressionCardType = false,
    position = 'main',
    searchId = null,
    isExternal = false,
    companyId = null,
    companyName = null,
    pageSection = null,
    pageTab = null,
    pageType = null,
    mediaType = null,
    tileEntityId = null,
    isSponsored = false,
    tileEntityUuid = null,
    tileOrder = 0,
    tileLocation = null,
    cardEntityId = null,
    cardEntityUuid = null,
    cardOrder = 0,
    cardLocation = null,
    cardPosition = null,
    fromCompanyJobsPage = false,
    mediaId = null,
    providerKey = null,
    providerName = null,
    pageEntityId = null,
    pageUUID = null,
}) => {
    const parsedCompanyId = companyId ? String(companyId) : null;
    const trueCardType = impressionCardType ? null : cardType;
    const baseJson = {
        sp_impression_component: component,
        sp_impression_tile_type: tileType,
        sp_impression_card_type:
            mediaType === MEDIA_TYPE_VIDEO ? null : trueCardType,
        sp_impression_insert_type: insertType,
        sp_impression_modal_type: modalType,
        sp_impression_position: position,
        sp_impression_search_id: searchId,
        sp_impression_button_type: buttonType,
        // TILE CONTENT INFO
        ...(mediaType === MEDIA_TYPE_VIDEO
            ? {
                  sp_media_type: mediaType,
                  sp_media_name: `${companyName} - Job Video`,
                  sp_media_id: mediaId,
                  sp_media_provider_key: providerKey,
                  sp_media_provider_name: providerName,
              }
            : {
                  sp_tile_type: tileType,
                  sp_modal_screen_order: modalScreenOrder,
                  sp_modal_type: modalType,
                  sp_modal_screen_name: modalScreenName,
                  sp_tile_entity_id: tileEntityId,
                  sp_tile_is_sponsored: isSponsored,
                  sp_tile_entity_uuid: tileEntityUuid,
                  sp_tile_order: tileOrder,
                  sp_tile_location: tileLocation,
                  sp_tile_company_id: parsedCompanyId,
              }),
        // unnecessary for COMPANY JOBS PAGE
        ...(!fromCompanyJobsPage && {
            sp_card_type: cardType,
            sp_card_is_sponsored: isSponsored,
            sp_card_entity_id: cardEntityId,
            sp_card_entity_uuid: cardEntityUuid,
            sp_card_order: cardOrder,
            sp_card_location: cardLocation,
            sp_card_company_id: parsedCompanyId,
            sp_card_position: cardPosition,
        }),
    };
    const userId = window.globalPageProps?.PAGE_PROPS?.session?.id;
    const index = window.dataLayer.findIndex((obj) => 'sp_page_section' in obj);
    const pageData = {
        // PAGE INFO
        sp_page_entity_id: pageEntityId,
        sp_page_uuid: pageUUID,
        sp_page_is_external: isExternal,
        sp_page_type: pageType,
        sp_page_section: pageSection,
        sp_page_tab: pageTab,
        // USER INFO
        sp_user_id: userId,
        sp_user_logged_in: !!userId,
        // populating necessary info for event COMPANY JOBS PAGE
        ...(fromCompanyJobsPage || mediaType === MEDIA_TYPE_VIDEO
            ? {
                  sp_page_page_company_id:
                      mediaType === MEDIA_TYPE_VIDEO ? null : parsedCompanyId,
              }
            : {
                  sp_page_sponsor_id: parsedCompanyId,
              }),
    };
    if (index >= 0) {
        window.dataLayer[index] = pageData;
    } else {
        window.dataLayer.push(pageData);
    }

    const element = document.createElement('div');
    element.setAttribute('data-tracking-props', JSON.stringify(baseJson));

    triggerSnowplowImpressionEvent(element);
};

export const convertMetricToSnowPlowImpressionTracker = (snowPlow) => {
    const page = window.location.pathname;
    // if the page is not in the pages to use return null
    if (!pagesToUse.includes(page)) return null;
    SnowplowImpressionTracker({
        ...snowPlow,
    });
};
