import React from 'react';
import { Consumer as CommunityContextConsumer } from '../../../contexts/CommunityContext';
/* Contexts */
import { MessageConsumer } from '../../../contexts/MessageContext';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
/* Components */
import InfiniteScrollWrapper from '../../Layout/InfiniteScrollWrapper/InfiniteScrollWrapper';
import MessageBlock from '../MessageBlock/MessageBlock';
import TypingMessage from '../TypingMessage/TypingMessage';
import MessageInput from './MessageInput/MessageInput';
import MessageItem from './MessageItem/MessageItem';
/* Stylesheets */
import './Messages.scss';
import { PAGE_DISPLAY_MODE } from '../../../pages/constants';

export class Messages extends React.PureComponent {
    constructor(props) {
        super(props);
        this.messageBody = React.createRef();
        this.timer = null;
        this.state = {
            channelKey: '',
            hasSentMessage: false,
            hasUserScrolledUp: false,
            isMessageSending: false,
            isUserScrolling: false,
            textAreaError: null,
            messages: [],
            // eslint-disable-next-line react/no-unused-state
            userTyping: null,
            text: '',
        };
    }

    componentDidMount() {
        this.messageBody.current.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(previousProps, previousState) {
        const {
            isMessageBoxActive: previousIsMessageBoxActive,
        } = previousProps;

        const { key: channelKey, messages, typing } =
            this.props.currentChannelInfo || {};
        const userTyping =
            typing && !!Object.keys(typing).length && Object.keys(typing)[0];

        const {
            hasSentMessage: previousHasSentMessage,
            channelKey: previousChannelKey,
            messages: previousMessages,
        } = previousState;

        if (previousChannelKey !== channelKey) {
            // eslint-disable-next-line react/no-did-update-set-state,react/no-unused-state
            this.setState({ messages, userTyping, channelKey });
        }

        const isChannelSelectedOnMobile =
            !previousIsMessageBoxActive && this.props.isMessageBoxActive;
        // @TODO: Previous messages is never empty and never updates, so it appears the first part of this check is useless, however I'm not removing it until I can talk to the original author. Messages are updated in props, not state.
        const hasMessagesLoaded =
            (!previousMessages && messages) ||
            !!(
                previousProps.currentChannelInfo &&
                previousProps.currentChannelInfo.messages &&
                previousProps.currentChannelInfo.messages.length < messages &&
                messages.length
            );
        const hasSentMessage =
            previousHasSentMessage && this.state.hasSentMessage;
        const hasSwitchedChannel =
            !this.props.isMessageDrawerOpen &&
            previousChannelKey !== this.state.channelKey;

        if (hasSwitchedChannel) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ text: '' });
        }

        if (
            !!isChannelSelectedOnMobile ||
            hasMessagesLoaded ||
            hasSentMessage ||
            hasSwitchedChannel ||
            (userTyping && !this.state.hasUserScrolledUp)
        ) {
            this.scrollToBottomOfChat();
        }
    }

    componentWillUnmount() {
        this.messageBody.current.removeEventListener(
            'scroll',
            this.handleScroll
        );
    }

    handleScroll = () => {
        const messageBodyElement = this.messageBody.current;

        if (!this.state.isUserScrolling)
            this.setState({ isUserScrolling: true, hasSentMessage: false });
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.setState({ isUserScrolling: false });
        }, 200);

        if (
            !this.state.hasUserScrolledUp &&
            Math.floor(
                messageBodyElement.scrollHeight -
                    messageBodyElement.scrollTop -
                    1
            ) > messageBodyElement.clientHeight
        ) {
            this.setState({ hasUserScrolledUp: true });
        } else if (
            this.state.hasUserScrolledUp &&
            Math.floor(
                messageBodyElement.scrollHeight -
                    messageBodyElement.scrollTop -
                    1
            ) <= messageBodyElement.clientHeight
        ) {
            this.setState({ hasUserScrolledUp: false });
        }
    };

    scrollToBottomOfChat = () => {
        // scrolls to most recent messages at the bottom of div
        const messageBodyDivs = document.querySelectorAll('.messages-body');

        if (messageBodyDivs.length) {
            // eslint-disable-next-line array-callback-return
            [...messageBodyDivs].map((divElement) => {
                // eslint-disable-next-line no-param-reassign
                divElement.scrollTop = divElement.scrollHeight;
            });
        }
    };

    handleSendMessage = async () => {
        const { sendMessage } = this.props;
        if (!this.state.textAreaError && !this.state.isMessageSending) {
            this.setState({ isMessageSending: true });
            await sendMessage(this.state.text);
            this.setState({
                hasSentMessage: true,
                isMessageSending: false,
                text: '',
            });
        }
    };

    handleTextAreaChange = async (event) => {
        const { onTyping } = this.props;
        this.setState({
            textAreaError:
                event.target.value.length > 5000
                    ? 'There is a limit of 5000 characters.'
                    : null,
            text: event.target.value,
        });

        await onTyping(event);
    };

    handleLoadMore = (loadMoreMessagesForCurrentChannel) => () => {
        loadMoreMessagesForCurrentChannel();
    };

    renderTypingIndicatorText = (firstTyperData, isMultiplePeopleTyping) => {
        if (isMultiplePeopleTyping) {
            return 'Several people are typing...';
        }

        return `${firstTyperData.username} is typing...`;
    };

    checkComponentVisible = (
        { top, left, bottom, right },
        windowWidth,
        windowHeight
    ) => {
        const topThreshold = 0;
        const leftThreshold = 0;

        const topOverflow = top >= topThreshold ? 0 : topThreshold - top;
        const bottomOverflow =
            bottom <= windowHeight ? 0 : bottom - windowHeight;
        const leftOverflow = left >= leftThreshold ? 0 : leftThreshold - left;
        const rightOverflow = right <= windowWidth ? 0 : right - windowWidth;
        const isVisible =
            topOverflow === 0 &&
            bottomOverflow === 0 &&
            leftOverflow === 0 &&
            rightOverflow === 0;

        return isVisible;
    };

    getOverlayVisibilityStyle = () => {
        let visibilityStyle = {};

        if (this.props.standAloneOverlayComponent) {
            const html = document.documentElement;
            const windowWidth = window.innerWidth || html.clientWidth;
            const windowHeight = window.innerHeight || html.clientHeight;

            const hoverPosition = this.props.getStandAloneHoverPosition();
            const yOffset = windowWidth < 576 ? 0 : window.scrollY;
            const componentNotRendered =
                hoverPosition.top +
                    hoverPosition.right +
                    hoverPosition.bottom +
                    hoverPosition.left ===
                0;
            const isComponentVisible = this.checkComponentVisible(
                hoverPosition,
                windowWidth,
                windowHeight
            );

            visibilityStyle = {
                position: 'absolute',
                top: `${hoverPosition.bottom + yOffset}px`,
                left: `${hoverPosition.left}px`,
            };
            if (componentNotRendered || !isComponentVisible) {
                visibilityStyle = {
                    display: 'none',
                };
            }
        }

        return visibilityStyle;
    };

    render() {
        const {
            className,
            isLoggedInUser,
            currentChannelInfo,
            getUsersInfoById,
            getHasMoreMessages,
            loadingMoreMessages,
            loadMoreMessagesForCurrentChannel,
            setCurrentChannel,
            messageType,
            enableHoverOver,
            standAloneOverlayComponent,
            notDisplayOverlay,
            isRecruiterChatCandidate,
            pageDisplayMode,
        } = this.props;

        const {
            key,
            messages,
            typing,
            type,
            channelData: { recruiterChatInfo: { candidate } = {} } = {},
        } = currentChannelInfo || {};

        const { professionalUsername } = candidate || {};

        const otherTypings = typing
            ? Object.keys(typing).filter((userId) => !isLoggedInUser(userId))
            : [];
        const userTyping = otherTypings[0];
        const typingUserData = typing && typing[userTyping];
        const hasMessages = messages && messages.length;
        const isMultipleUsersTyping = otherTypings.length > 1;
        const drawerClassName = this.props.isInDrawer ? 'drawer' : '';
        const overlayVisibilityStyle = this.getOverlayVisibilityStyle();
        const messageKeys = [];
        if (messages) {
            for (let i = 0; i < messages.length; i += 1) {
                messageKeys.push(`message-${i}`);
            }
        }
        const recruiterClass =
            pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT
                ? 'component-Messages-Messages--recruiter-chat'
                : '';
        return (
            <div
                className={`component-Messages-Messages ${drawerClassName} ${className} ${recruiterClass}`}
            >
                <div
                    className={`messages-container ${
                        this.state.isUserScrolling ? 'scrolling' : ''
                    }`}
                >
                    <div
                        className={`messages-body ${
                            !hasMessages ? 'no-message-body' : ''
                        }`}
                        ref={this.messageBody}
                    >
                        {hasMessages ? (
                            <InfiniteScrollWrapper
                                pageStart={0}
                                loadMore={this.handleLoadMore(
                                    loadMoreMessagesForCurrentChannel || {}
                                )}
                                hasMore={getHasMoreMessages()}
                                isLoading={loadingMoreMessages}
                                useWindow={false}
                                threshold={100}
                                isReverse
                            >
                                {messages.map((message, index) => (
                                    <MessageItem
                                        key={`${messageKeys[index]}`}
                                        message={message}
                                        index={index}
                                        isLoggedInUser={isLoggedInUser}
                                        getUsersInfoById={getUsersInfoById}
                                        messageType={messageType}
                                        enableHoverOver={enableHoverOver}
                                        isRecruiterChatCandidate={
                                            isRecruiterChatCandidate
                                        }
                                        candidateUserName={professionalUsername}
                                        pageDisplayMode={pageDisplayMode}
                                    />
                                ))}
                                {!!userTyping &&
                                    !isLoggedInUser(userTyping) && (
                                        <MessageBlock
                                            key={typingUserData.updated_at}
                                            cssClass="typing-indicator"
                                            username={this.renderTypingIndicatorText(
                                                typingUserData,
                                                isMultipleUsersTyping
                                            )}
                                            userTitle=""
                                            messageContent={<TypingMessage />}
                                            src={typingUserData.logo}
                                            isMultiplePeopleTyping={
                                                isMultipleUsersTyping
                                            }
                                            shouldDisableDelete
                                            user={getUsersInfoById(
                                                typingUserData.id
                                            )}
                                            enableHoverOver={false}
                                            messageType={messageType}
                                            isLoggedInUser={isLoggedInUser}
                                            isRecruiterChatCandidate={
                                                isRecruiterChatCandidate
                                            }
                                            candidateUserName={
                                                professionalUsername
                                            }
                                            pageDisplayMode={pageDisplayMode}
                                        />
                                    )}
                            </InfiniteScrollWrapper>
                        ) : (
                            'No messages yet'
                        )}
                    </div>
                </div>
                <div className="error">{this.state.textAreaError}</div>
                <MessageInput
                    text={this.state.text}
                    handleTextAreaChange={this.handleTextAreaChange}
                    handleSendMessage={this.handleSendMessage}
                    textAreaError={this.state.textAreaError}
                    isMessageSending={this.state.isMessageSending}
                    setCurrentChannel={() => setCurrentChannel(key, type)}
                />
                {!notDisplayOverlay &&
                    standAloneOverlayComponent &&
                    React.cloneElement(standAloneOverlayComponent, {
                        style: overlayVisibilityStyle,
                    })}
            </div>
        );
    }
}

// @TODO: Remove this and add back "default" to Messages
export default (props) => (
    <CommunityContextConsumer>
        {({ standAloneOverlayComponent, getStandAloneHoverPosition }) => (
            <PageConsumer>
                {/* eslint-disable-next-line no-unused-vars */}
                {({ pageProps, pageProps: { pageDisplayMode } }) => {
                    return (
                        <MessageConsumer>
                            {({
                                isLoggedInUser,
                                getCurrentChannelInfo,
                                sendMessage,
                                onTyping,
                                getHasMoreMessages,
                                loadingMoreMessages,
                                loadMoreMessagesForCurrentChannel,
                                setCurrentChannel,
                                getUsersInfoById,
                                isMessageDrawerOpen,
                                isRecruiterChatCandidate,
                            }) => {
                                const { currentChannel } = props;

                                // TODO: This is where to tell it they're talking to a recruiter to make the bubbles blue
                                const currentChannelInfo = !currentChannel
                                    ? getCurrentChannelInfo()
                                    : currentChannel;

                                const messageType = currentChannelInfo
                                    ? currentChannelInfo.type
                                    : null;

                                return (
                                    <Messages
                                        {...props}
                                        isLoggedInUser={isLoggedInUser}
                                        currentChannelInfo={currentChannelInfo}
                                        sendMessage={sendMessage}
                                        onTyping={onTyping}
                                        getHasMoreMessages={getHasMoreMessages}
                                        loadingMoreMessages={
                                            loadingMoreMessages
                                        }
                                        loadMoreMessagesForCurrentChannel={
                                            loadMoreMessagesForCurrentChannel
                                        }
                                        setCurrentChannel={setCurrentChannel}
                                        getUsersInfoById={getUsersInfoById}
                                        isMessageDrawerOpen={
                                            isMessageDrawerOpen
                                        }
                                        messageType={messageType}
                                        getStandAloneHoverPosition={
                                            getStandAloneHoverPosition
                                        }
                                        standAloneOverlayComponent={
                                            standAloneOverlayComponent
                                        }
                                        isRecruiterChatCandidate={
                                            isRecruiterChatCandidate
                                        }
                                        pageDisplayMode={pageDisplayMode}
                                    />
                                );
                            }}
                        </MessageConsumer>
                    );
                }}
            </PageConsumer>
        )}
    </CommunityContextConsumer>
);
