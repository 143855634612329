import PropTypes from 'prop-types';
import React from 'react';
import SVGIconWrapper from '../../Icons/SVGIconWrapper/SVGIconWrapper';

import './Tag.scss';

export default class Tag extends React.PureComponent {
    static propTypes = {
        tagCssClass: PropTypes.string,
        svgCssClass: PropTypes.string,
        onRemove: PropTypes.func,
        shouldAllowDelete: PropTypes.bool,
    };

    static defaultProps = {
        tagCssClass: '',
        svgCssClass: '',
        shouldAllowDelete: true,
    };

    render() {
        return (
            <span
                className={`component-Elements-Tag ${this.props.tagCssClass}`}
            >
                {this.props.children}

                {this.props.shouldAllowDelete && (
                    <SVGIconWrapper
                        handleClickSVGWrapper={this.props.onRemove}
                        SVGWrapperCssClass={this.props.svgCssClass}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                        >
                            <g
                                fill="none"
                                fillRule="evenodd"
                                transform="translate(-78 -8)"
                            >
                                <rect
                                    className={'cancel-image-box'}
                                    width="101"
                                    height="30"
                                    x=".5"
                                    y=".5"
                                    fill="#DFE5F2"
                                    stroke="#B5BDD5"
                                    rx="6"
                                />
                                <path
                                    fill="#8E9DB9"
                                    d="M86 15.057l1.652-1.652c.26-.26.68-.258.94.002a.664.664 0 0 1 .003.941L86.943 16l1.652 1.652c.259.26.26.679-.002.94a.665.665 0 0 1-.941.003L86 16.943l-1.652 1.652a.665.665 0 0 1-.94-.002.664.664 0 0 1-.003-.941L85.057 16l-1.652-1.652a.664.664 0 0 1 .002-.94.665.665 0 0 1 .941-.003L86 15.057zm0 7.61a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334zm0-1.334a5.333 5.333 0 1 0 0-10.666 5.333 5.333 0 0 0 0 10.666z"
                                />
                            </g>
                        </svg>
                    </SVGIconWrapper>
                )}
            </span>
        );
    }
}
