import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { areEqualShallow, throttle } from '../../../utilities/helperFunctions';
import CustomPropTypes from '../../Includes/CustomPropTypes';

export default class VisibilityWrapper extends React.PureComponent {
    static propTypes = {
        /**
         * throttle interval for rate limit
         */
        throttleInterval: CustomPropTypes.positiveInt,
        /**
         * components that would be tracked with visibility and number of overflowing pixel if any
         */
        children: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]),
        /**
         * Css class that could override default classes
         */
        className: PropTypes.string,
        /**
         * Exposed for testing but allows node other than internal wrapping <div /> to be tracked
         * for visibility
         */
        nodeRef: PropTypes.object,
        /**
         * Enable visibility calculation on a parent container's viewscreen instead of default window viewscreen
         */
        containerRef: PropTypes.object,
        /**
         * Define a custom element
         */
        element: PropTypes.string,
    };

    static defaultProps = {
        throttleInterval: 150,
        element: 'div',
        className: '',
        nodeRef: undefined,
        containerRef: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            isContainerListenerAttached: false,
            isVisible: false,
            topOverflow: 0,
            bottomOverflow: 0,
            leftOverflow: 0,
            rightOverflow: 0,
        };
        this.throttleCb = throttle(
            this.isComponentVisible,
            this.props.throttleInterval
        );
        this.ownProps = Object.keys(VisibilityWrapper.propTypes);
        props.nodeRef && this.setNodeRef(props.nodeRef);
    }

    componentDidMount() {
        this.attachListener();
        this.isComponentVisible();
    }

    componentDidUpdate(prevProps) {
        const isUpdated = !areEqualShallow(
            this.getChildProps(this.props),
            this.getChildProps(prevProps)
        );

        if (isUpdated) {
            this.isComponentVisible();
        }

        if (!this.state.isContainerListenerAttached) {
            this.attachContainerListener();
        }
    }

    componentWillUnmount() {
        this.removeListener();
    }

    attachListener() {
        window.addEventListener('scroll', this.throttleCb);
        window.addEventListener('resize', this.throttleCb);
    }

    removeListener() {
        window.removeEventListener('scroll', this.throttleCb);
        window.removeEventListener('resize', this.throttleCb);

        if (this.state.isContainerListenerAttached) {
            ReactDOM.findDOMNode(this.props.containerRef).removeEventListener(
                'scroll',
                this.throttleCb
            );
        }
    }

    attachContainerListener = () => {
        const { containerRef } = this.props;

        if (containerRef && containerRef.getBoundingClientRect) {
            ReactDOM.findDOMNode(containerRef).addEventListener(
                'scroll',
                this.throttleCb
            );
            this.setState({ isContainerListenerAttached: true });
        }
    };

    setNodeRef = (ref) => {
        this.nodeRef = ref;
    };

    getChildProps(props = this.props) {
        const childProps = {};
        Object.keys(props).forEach((key) => {
            if (this.ownProps.indexOf(key) === -1) {
                childProps[key] = props[key];
            }
        });
        return childProps;
    }

    getComponentPosition = () => this.nodeRef.getBoundingClientRect();

    getContainerPosition = () => {
        const { containerRef } = this.props;

        if (!containerRef || !containerRef.getBoundingClientRect) {
            const html = document.documentElement;
            const windowWidth = window.innerWidth || html.clientWidth;
            const windowHeight = window.innerHeight || html.clientHeight;
            const windowViewScreen = {
                top: 0,
                right: windowWidth,
                bottom: windowHeight,
                left: 0,
            };

            return windowViewScreen;
        }

        const containerBoundingClient = containerRef.getBoundingClientRect();
        const containerViewScreen = {
            top: containerBoundingClient.top,
            right: containerBoundingClient.right,
            bottom: containerBoundingClient.bottom,
            left: containerBoundingClient.left,
        };

        return containerViewScreen;
    };

    calculateVisibility = (component = {}, container = {}) => {
        if (
            component.top +
                component.right +
                component.bottom +
                component.left ===
            0
        ) {
            return false;
        }

        const topOverflow =
            component.top >= container.top ? 0 : container.top - component.top;
        const bottomOverflow =
            component.bottom <= container.bottom
                ? 0
                : component.bottom - container.bottom;
        const leftOverflow =
            component.left >= container.left
                ? 0
                : container.left - component.left;
        const rightOverflow =
            component.right <= container.right
                ? 0
                : component.right - container.right;

        const isVisible =
            topOverflow === 0 &&
            bottomOverflow === 0 &&
            leftOverflow === 0 &&
            rightOverflow === 0;

        return {
            isVisible,
            topOverflow,
            bottomOverflow,
            leftOverflow,
            rightOverflow,
        };
    };

    isComponentVisible = () => {
        setTimeout(() => {
            if (!this.nodeRef || !this.nodeRef.getBoundingClientRect) return;

            const componentPosition = this.getComponentPosition();
            const containerPosition = this.getContainerPosition();
            const visibilityProps = this.calculateVisibility(
                componentPosition,
                containerPosition
            );

            this.setState({ ...visibilityProps });
        }, 0);
    };

    render() {
        const { className, children, nodeRef, element: Wrapper } = this.props;
        return (
            <Wrapper ref={!nodeRef && this.setNodeRef} className={className}>
                {typeof children === 'function'
                    ? children({
                          ...this.getChildProps(),
                          ...this.state,
                      })
                    : React.Children.map(children, (child) =>
                          React.cloneElement(child, {
                              ...this.getChildProps(),
                              ...this.state,
                          })
                      )}
            </Wrapper>
        );
    }
}
