import axios from 'axios';

export const USER_PROFILE_KEYS = Object.freeze({
    NAME: 'name',
    LOGO: 'logo',
    CREDENTIAL: 'credential',
    ABOUT_ME: 'aboutMe',
    WEBSITE: 'website',
    EXPERIENCES: 'experiences',
    EDUCATIONS: 'educations',
    INTERESTS: 'interests',
    DESIRED_COMPANIES: 'desiredCompanies',
});

export const PROFILE_FIELDS = {
    JOB_TITLE: 'jobTitle',
    COMPANY: 'company',
    SCHOOL: 'school',
    DEGREE: 'degree',
};

export const USER_PROFILE_API_URL = '/api/user/profile';

export const updateProfile = async (updateKey, updateValue) => {
    try {
        axios.patch(USER_PROFILE_API_URL, {
            [USER_PROFILE_KEYS.INTERESTS]: updateValue,
        });
    } catch (err) {
        return err;
    }
};
