import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../../../../Content/User/UserDisplay/UserImage/UserImage';
import Overlay from '../../../../Overlay/Overlay';
import TopBar from '../../Modules/TopBar/TopBar';

import './DrawerLayout.scss';

const DEFAULT_DRAWER_SVG = buildImagePath(
    'v1555442942/SVGs/group-28.svg',
    'drawerSize',
    { drawerSize: 'c_scale,g_center,r_max,w_142,h_242,z_0.6' }
);

export default class Drawer extends React.PureComponent {
    static propTypes = {
        /**
         * children, HTML elements rendered beneath the top bar component
         */
        children: PropTypes.node.isRequired,
        /**
         * direction drawer should slide out from
         * defaulted to left
         */
        direction: PropTypes.oneOf(['left', 'right', 'bottom']),
        /**
         * type of drawer of enum nav-bar or message
         */
        drawerType: PropTypes.oneOf(['nav-bar', 'message']),
        /**
         * sliding animation duration, units in seconds
         */
        durationInSeconds: PropTypes.number,
        /**
         * a function passed in to close the drawer
         */
        handleClose: PropTypes.func.isRequired,
        /**
         * image to be displayed at the bottom of drawer
         */
        image: PropTypes.string,
        /**
         * boolean to check if drawer should display or not
         */
        isDrawerOpen: PropTypes.bool.isRequired,
        /**
         * boolean to show image or not, defaulted to false
         */
        showShowImage: PropTypes.bool,
        /**
         * boolean to display overlay or not, defaulted to true
         */
        shouldShowOverlay: PropTypes.bool,
        /**
         * speed curve of transition effect
         * e.g. ease-in, ease-in-out, linear, cubic-bezier()
         */
        timingFunction: PropTypes.string,
    };

    static defaultProps = {
        direction: 'left',
        drawerType: 'nav-bar',
        durationInSeconds: 0.5,
        image: DEFAULT_DRAWER_SVG,
        shouldShowOverlay: true,
        timingFunction: 'ease-in',
    };

    getSlidingDirection = () => {
        if (
            this.props.direction === 'left' ||
            this.props.direction === 'right'
        ) {
            if (this.props.isDrawerOpen) return 'slide-In';
            else return 'slide-Out';
        } else {
            if (this.props.isDrawerOpen) return 'slide-Up';
            else return 'slide-Down';
        }
    };

    render() {
        const {
            cssClass,
            direction,
            drawerType,
            durationInSeconds,
            children,
            image,
            isDrawerOpen,
            overlayType,
            shouldShowOverlay,
            timingFunction,
            ...topBarProps
        } = this.props;
        const isVisible = this.getSlidingDirection();
        return (
            <React.Fragment>
                <div
                    className={`component-Layout-Header-Layout-DrawerLayout ${isVisible} ${direction} ${cssClass}`}
                    style={{
                        transitionDuration: `${durationInSeconds}s`,
                        transitionTimingFunction: timingFunction,
                    }}
                >
                    <TopBar {...topBarProps} drawerType={drawerType} />
                    {children}
                </div>
                {shouldShowOverlay && isDrawerOpen && (
                    <Overlay
                        cssClass={this.props.overlayCssClass}
                        handleClose={this.props.handleClose}
                        isOpen={isDrawerOpen}
                        type={overlayType}
                        drawerType={drawerType}
                    />
                )}
            </React.Fragment>
        );
    }
}
