import PropTypes from 'prop-types';
import React from 'react';

import './AnnouncementTopBanner.scss';

export default class AnnouncementTopBanner extends React.PureComponent {
    static propTypes = {
        /**
         * display text before cta button
         */
        annoucementText: PropTypes.string.isRequired,
        /**
         * display text after cta button
         */
        annoucementEndingText: PropTypes.string,
        /**
         * data of cta button
         */
        ctaButton: PropTypes.shape({
            text: PropTypes.string.isRequired,
            href: PropTypes.string,
            handleClick: PropTypes.func,
        }).isRequired,
    };

    static defaultProps = {
        annoucementText: '',
        annoucementEndingText: '',
        ctaButton: {
            text: '',
            href: null,
            handleClick: () => {},
        },
    };

    render() {
        const {
            annoucementText,
            annoucementEndingText,
            ctaButton,
        } = this.props;

        return (
            <div className={`component-Elements-AnnouncementTopBanner`}>
                <p className="banner-text">
                    {annoucementText}
                    {ctaButton.href ? (
                        <a
                            className="cta-btn"
                            href={ctaButton.href}
                            onClick={ctaButton.handleClick}
                        >{` ${ctaButton.text} `}</a>
                    ) : (
                        <span
                            className="cta-btn"
                            onClick={ctaButton.handleClick}
                        >{` ${ctaButton.text}`}</span>
                    )}
                    {annoucementEndingText}
                </p>
            </div>
        );
    }
}
