/* eslint import/no-cycle: 0 */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import CommunityInviteButton from '../../../Community/CommunityInviteButton/CommunityInviteButton';
import FlexPopup from '../../../Elements/FlexPopup/FlexPopup';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';
import { groupActions } from '../../../Includes/GroupActionTypes';
import AdminMenuSection, {
    ButtonTypes,
} from '../AdminMenuSection/AdminMenuSection'; // eslint-disable-line import/no-named-as-default

import './GroupsMenuMobileModal.scss';
import GenericButton from '../../Elements/GenericButton/GenericButton';
import GroupStartPostButton from '../../Elements/GroupStartPostButton/GroupStartPostButton';
import { PageContext } from '../../../../contexts/ContextCreator';

export const GroupsMenuMobileModal = (props) => {
    const { pageProps, modalTrigger } = useContext(PageContext);
    const onClose = props.onClose || modalTrigger;
    const { group, hasPermission } = props.groupContext;

    const baseUrl = props.isReactRouterLink ? '' : `/groups/${group?.hash}`;

    const renderModalBtn = () => (
        <SVGIconWrapper
            handleClickSVGWrapper={onClose}
            SVGWrapperCssClass="svg-close-icon"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#C4C8CC"
                    fillRule="nonzero"
                    d="M21.597 2.403A1.375 1.375 0 0 0 19.653.458L11 9.111 2.347.458A1.375 1.375 0 1 0 .403 2.403l8.652 8.653-8.652 8.652a1.375 1.375 0 0 0 1.944 1.945L11 13l8.653 8.653a1.375 1.375 0 1 0 1.944-1.945l-8.652-8.652 8.652-8.653z"
                />
            </svg>
        </SVGIconWrapper>
    );

    const handleClick = (eventType) => {
        EmitMetric({
            misc_event_type: `group-${eventType}-click`,
            misc_event_count: 1,
            community_group_hash: group?.hash,
            user_id: pageProps.session.id,
        });
    };

    const onGroupChatClick = (eventType) => {
        EmitMetric({
            misc_event_type: `user-activates-message`,
            misc_event_count: 1,
            community_group_hash: group?.hash,
            user_id: pageProps.session.id,
        });
        EmitMetric({
            misc_event_type: `group-${eventType}-click`,
            misc_event_count: 1,
            community_group_hash: group?.hash,
            user_id: pageProps.session.id,
        });
    };

    const StartPostBtn = hasPermission(groupActions.POST_CONTENTS) && (
        <GroupStartPostButton closeModalHandler={onClose} />
    );
    const AboutGroupBtn = {
        title: 'About this group',
        url: `${baseUrl}/about`,
        onClick: () => handleClick('about'),
        className: 'about-group',
        key: 'about-group',
    };
    const GroupRulesBtn = {
        title: 'Group rules',
        url: `${baseUrl}/rules`,
        onClick: () => handleClick('rules'),
        className: 'group-rules',
        key: 'group-rules',
    };
    const MembersBtn = {
        title: 'Members',
        url: `${baseUrl}/members`,
        onClick: () => handleClick('members'),
        className: 'view-members',
        key: 'view-members',
    };
    const InviteButton = hasPermission(groupActions.CAN_INVITE) && (
        <CommunityInviteButton
            modalTrigger={modalTrigger}
            value="Invite others to this group"
            buttonType="generic"
            inviteType="group"
            group={group}
            metricEventType="invite-to-group-click-group-homepage"
        />
    ); // @TODO this needs ixing with the modal trigger
    const GroupChatButton = {
        title: 'Group chat room',
        url: `${baseUrl}/group-chat`,
        onClick: () => onGroupChatClick('group-chat-room'),
        className: 'view-group-chat-room',
        key: 'view-group-chat-room',
    };
    const AdminSettingsSection = hasPermission(
        groupActions.SHOW_ADMIN_MENU
    ) && (
        <AdminMenuSection
            groupHash={group?.hash}
            buttonType={
                props.isReactRouterLink
                    ? ButtonTypes.REACTROUTERLINK
                    : ButtonTypes.NORMAL
            }
        />
    );
    return (
        <div className="component-Groups-Modals-GroupsMenuMobileModal">
            <FlexPopup hidden={false} onClose={onClose} closeOnOutsideClick>
                <aside className="modal-body-box" role="dialog">
                    {renderModalBtn()}
                    <div className="controlled-width-container">
                        {props.showPostButton && StartPostBtn}
                        <GenericButton
                            {...AboutGroupBtn}
                            samePage
                            hash={group.hash}
                            isReactRouterLink={props.isReactRouterLink}
                        />
                        {group.rules && (
                            <GenericButton
                                {...GroupRulesBtn}
                                samePage
                                hash={group?.hash}
                                isReactRouterLink={props.isReactRouterLink}
                            />
                        )}
                        {hasPermission(groupActions.VIEW_CONTENTS) && (
                            <GenericButton
                                {...MembersBtn}
                                samePage
                                hash={group?.hash}
                                isReactRouterLink={props.isReactRouterLink}
                            />
                        )}
                        {InviteButton}
                        {hasPermission(groupActions.POST_CONTENTS) && (
                            <GenericButton
                                {...GroupChatButton}
                                samePage
                                hash={group?.hash}
                                isReactRouterLink={props.isReactRouterLink}
                            />
                        )}
                        {AdminSettingsSection}
                    </div>
                </aside>
            </FlexPopup>
        </div>
    );
};

GroupsMenuMobileModal.propTypes = {
    // On close function
    onClose: PropTypes.func,
    // modalTrigger function
    modalTrigger: PropTypes.func.isRequired,
    isReactRouterLink: PropTypes.bool,
    groupContext: PropTypes.shape({
        hasPermission: PropTypes.func.isRequired,
        group: PropTypes.shape({
            hash: PropTypes.string,
            rules: PropTypes.string,
        }).isRequired,
    }).isRequired,
    showPostButton: PropTypes.bool,
};

GroupsMenuMobileModal.defaultProps = {
    onClose: null,
    isReactRouterLink: true,
    showPostButton: false,
};

export default React.memo(GroupsMenuMobileModal);
