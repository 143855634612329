import PropTypes from 'prop-types';
import React from 'react';
import './ArrowPagination.scss';

export default class ArrowPagination extends React.PureComponent {
    static propTypes = {
        /**
         * url to go to previous job results
         */
        prevPage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        /**
         * url to go to next job results
         */
        nextPage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        /**
         * number range text
         */
        numberRange: PropTypes.string,
        /**
         * total results
         */
        total: PropTypes.number,
        /**
         * hide arrow buttons when there are no next/prev pages
         */
        hideArrowIfNoPagination: PropTypes.bool,
        /**
         * boolean to hide entire pagination if no pages
         */
        hidePaginationIfNoPages: PropTypes.bool,
        /**
         * string for custom css
         */
        className: PropTypes.string,
        metricOnClick: PropTypes.func,
    };

    static defaultProps = {
        numberRange: '0 - 0',
        total: 0,
        prevPage: null,
        nextPage: null,
        hideArrowIfNoPagination: false,
        hidePaginationIfNoPages: false,
        className: '',
        metricOnClick: () => {},
    };

    renderBtn = (value, onClick) => {
        const prev = (
            <div className="pagination-btn d-inline-block left-arrow">
                <i className="material-icons">keyboard_arrow_left</i>
            </div>
        );

        const next = (
            <div className="pagination-btn d-inline-block right-arrow">
                <i className="material-icons">keyboard_arrow_right</i>
            </div>
        );

        const arePaginationsNull = !this.props.prevPage && !this.props.nextPage;

        if (this.props.hideArrowIfNoPagination && arePaginationsNull) {
            return null;
        }

        if (onClick === null && value === 'Prev') {
            return prev;
        }

        if (onClick === null && value === 'Next') {
            return next;
        }

        const anchorProps = {};

        if (typeof onClick === 'string') {
            anchorProps.href = onClick;
            anchorProps.onClick = this.props.metricOnClick;
        } else {
            anchorProps.onClick = onClick;
        }

        return (
            <div className="pagination-btn d-inline-block">
                <a {...anchorProps}>{value === 'Next' ? next : prev}</a>
            </div>
        );
    };

    renderPagination() {
        if (this.props.hidePaginationIfNoPages) {
            if (!this.props.prevPage && !this.props.nextPage) {
                return null;
            }
        }

        return (
            <div className="pagination-number-display">
                {`${this.props.numberRange} of ${this.props.total}`}
            </div>
        );
    }

    render() {
        return (
            <div
                className={`component-Elements-ArrowPagination ${this.props.className}`}
            >
                {this.renderPagination()}
                {this.renderBtn('Prev', this.props.prevPage)}
                {this.renderBtn('Next', this.props.nextPage)}
            </div>
        );
    }
}
