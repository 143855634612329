import PropTypes from 'prop-types';
import constants from './Constants/jobSeeker';

export const JobProfilePropShape = {
    complete: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.shape({
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    willingToRelocate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    jobTitle: PropTypes.string,
    professionalSummary: PropTypes.string,
    yearsOfExperience: PropTypes.oneOf(
        Object.values(constants.YEARS_OF_EXPERIENCE)
    ),
    highestDegreeEarned: PropTypes.oneOf(
        Object.values(constants.EDUCATION_DEGREES)
    ),
    isCurrentStudent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    linkedinUrl: PropTypes.string,
    requiresSponsorship: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    race: PropTypes.oneOf(Object.values(constants.RACES)),
    gender: PropTypes.oneOf(Object.values(constants.GENDERS)),
    genderCustomText: PropTypes.string,
    authorizedToWorkInUS: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
};

export const JobProfilePropShapeV2 = {
    isComplete: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    location: PropTypes.shape({
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    willingToRelocate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    jobTitle: PropTypes.string,
    professionalSummary: PropTypes.string,
    yearsOfExperience: PropTypes.oneOf(
        Object.values(constants.YEARS_OF_EXPERIENCE)
    ),
    highestDegreeEarned: PropTypes.oneOf(
        Object.values(constants.EDUCATION_DEGREES)
    ),
    race: PropTypes.oneOf(Object.values(constants.RACES)),
    gender: PropTypes.oneOf(Object.values(constants.GENDERS)),
    experience: PropTypes.array,
    education: PropTypes.array,
};

export const VcfEventPropShape = {
    image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
    url: PropTypes.string,
    userRegistered: PropTypes.bool.isRequired,
    registrationClosed: PropTypes.bool.isRequired,
    eventHash: PropTypes.string,
    eventTimes: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        })
    ),
};

export const ResumeDataPropShape = {
    fileName: PropTypes.string,
    uploadedAt: PropTypes.string,
    status: PropTypes.bool,
};

export const VCFFormPropShape = {
    requiresSponsorship: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    isCurrentStudent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    authorizedToWorkInUS: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    email: PropTypes.string,
};
