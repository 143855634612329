/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import { Modal, ModalBody } from 'reactstrap';
import shortid from 'shortid';
import { debounce } from 'throttle-debounce';

import {
    getBreakpointOnResize,
    AUTH_WRAPPER_COOKIE,
    API_FETCH_LOGIN_WALL,
    defaultJobProfileSnippet,
    getRegwallValues,
} from './Includes/pageContextHelpers';

import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import CreateContentPopupV2 from '../components/Content/CreateContentWidgetV2/CreateContentPopup/CreateContentPopup';
import {
    LOGIN_DESCRIPTIONS,
    LOGIN_LAYOUT_DEFAULTS,
} from '../components/Login/Login_Descriptions';
import {
    CREATE_LAYOUT,
    LOGIN_LAYOUT,
} from '../components/Login/LoginLayoutComponent/Layouts/LogInLayout/constants';
import LoginWall from '../components/Login/LoginWall/LoginWall';
import ModalManager, {
    v1ModalTypes,
} from '../components/Modals/ModalManager/ModalManager';
import ReviewSurveyCardShell from '../components/ReviewSurvey/ReviewSurveyCardShell/ReviewSurveyCardShell';
import { PAGE_PROPS as pagePropShape } from '../pages/defaultProps';
import {
    FGBLocalStorage,
    getParameterByName,
} from '../utilities/helperFunctions';
import { loadRightRailContent } from '../utilities/loadPageContent';
import sentryClient from '../utilities/Sentry';
import { PageContext as Context } from './ContextCreator';
import JobReviewProvider from './JobReviewContext';
import { PAGE_DISPLAY_MODE } from '../pages/constants';
import ModalProvider from './ModalContext';
import { quickProfileOnboardingFlowType } from '../components/Modals/Flows/QuickProfileOnboarding/QuickProfileOnboarding';
import { buildQuickProfileOnboardingDefaultState } from '../components/Modals/Flows/QuickProfileOnboarding/constants';
import { fireGTMMetric, PAGE_VIEW_EVENT } from '../components/Analytics/Google';

import '../utilities/FrontendSentry';

const FGB_DOMAIN = 'fairygodboss.com';

export class Provider extends React.Component {
    constructor(props) {
        super(props);

        const { PAGE_PROPS } = this.props;
        const { headerProps, leftRail, leadInLogin } = PAGE_PROPS;

        if (axios?.defaults?.headers?.common) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] =
                PAGE_PROPS.csrfToken;
        }
        const user = PAGE_PROPS.session?.username
            ? {
                  username: PAGE_PROPS.session.username,
                  logo: PAGE_PROPS.session.logo,
                  credential: PAGE_PROPS.session?.credential,
              }
            : null;

        const userFullGroupList = PAGE_PROPS.session?.groups?.adminGroups?.concat(
            PAGE_PROPS.session?.groups?.memberGroups
        );

        this.state = {
            delaySeconds: 1,
            placementName: '',
            isDirectApplyJobSearchOpen: false,
            setDirectApplyJobSearchOpen: this.setIsDirectApplyJobSearchOpen,
            articleTest: PAGE_PROPS.articleTest,
            leadInLogin,
            setLeadLoginInPopup: this.setLeadLoginInPopup,
            leadInEmailReturn: false,
            showLeadinPopup: false,
            getLoginWallData: this.getLoginWallData,
            setLoginWallWithDescriptionText: this
                .setLoginWallWithDescriptionText,
            fetchLoginWallData: this.fetchLoginWallData,
            pageProps: PAGE_PROPS,
            featureFlags: PAGE_PROPS.featureFlags || {},
            pageDisplayMode:
                PAGE_PROPS.pageDisplayMode || PAGE_DISPLAY_MODE.USER_DEFAULT,
            userProps: loadRightRailContent(PAGE_PROPS.rightRail),
            loginWidget: {
                hash: null,
                isOpened: false,
                metrics: {},
                customLoginMessage: null,
                layout: null,
                cookie: null,
                redirectUrl: null,
            },
            loginWall: {
                isOpened: false,
                data: null,
            },
            user,
            jobProfileSnippet: headerProps?.jobProfileSnippet
                ? headerProps.jobProfileSnippet
                : defaultJobProfileSnippet,
            toggleJobProfileSnippetComplete: this
                .toggleJobProfileSnippetComplete,
            userIsFGB: false,
            notifications: headerProps?.notifications
                ? headerProps.notifications
                : {},
            badgeCountData: headerProps?.personalized
                ? headerProps.personalized
                : {},
            isGlobalNavSticky: true,
            setGlobalNavSticky: this.setGlobalNavSticky,
            contentWidget: null,
            showOnboardingWidget: false,
            jobReviewStatus:
                headerProps && headerProps.jobReview
                    ? headerProps.jobReview
                    : null,
            showJobReviewSurvey: false,
            toggleJobReviewSurvey: this.toggleJobReviewSurvey,
            totalPageViews: 0,
            lastSignupWallPageView: 0,
            openCreateContent: (topics, currentTopic = null) => {
                this.openCreateContent(topics, currentTopic);
            },
            closeCreateContent: this.closeCreateContent,
            getPageProps: () => {
                return this.state.pageProps;
            },
            isLoggedIn: () => {
                return this.state.user !== null;
            },
            getUserStatus: this.getUserStatus,
            getUser: () => {
                return this.state.user;
            },
            wrapAuth: (
                hash,
                Component,
                metrics,
                customLoginMessage,
                layout,
                cookie,
                redirectUrl
            ) => {
                return this.getAuthRequiredWrapper(
                    hash,
                    Component,
                    metrics,
                    customLoginMessage,
                    layout,
                    cookie,
                    redirectUrl
                );
            },
            onWrapperClick: (...args) => {
                return this.onWrapperClick(...args);
            },
            isAdmin: this.isAdmin(),
            onboardingCallToActionCallbackFunction: this
                .onboardingCallToActionCallbackFunction,
            removeCallToAction: this.removeCallToAction,
            surveyCookieStatus: null,
            topics:
                headerProps && headerProps.notifications
                    ? headerProps.notifications.topics
                    : [],
            subscribedTopics: leftRail ? leftRail.subscribedTopics : [],
            followingCompanies: leftRail ? leftRail.followingCompanies : [],
            jobNotifications: leftRail ? leftRail.jobNotifications : [],
            fetchJobNotifications: async () => {
                if (this.state.isLoggedIn()) {
                    await axios
                        .get(`/api/user/jobs`)
                        .then((res) => {
                            this.setState({
                                jobNotifications: res.data.notifications,
                            });
                        })
                        .catch((err) =>
                            this.state.sentry.captureException(err)
                        );
                }
            },
            joinedGroups: leftRail ? leftRail.joinedGroups : [],
            userFullGroupList,
            addToUserFullGroupList: (group) => {
                this.setState((prevState) => ({
                    userFullGroupList: [...prevState.userFullGroupList, group],
                    joinedGroups: {
                        ...prevState.joinedGroups,
                        groups: [...prevState.joinedGroups.groups, group],
                        groupCount: prevState.joinedGroups.groupCount + 1,
                    },
                }));
            },
            singleContentPage: false,
            isSingleContentPage: this.isSingleContentPage,
            modals: {
                type: null,
                data: null,
                stepPopulateProps: {},
                generalModalProps: {},
            },
            onUpdate: this.onUpdate,
            getPromotionalAd: this.getPropmotionalAd,
            pageKey: null,
            isMobile: false,
            getPageKey: () => this.state.pageKey,
            responsive: {
                breakpoint: null,
                atLeast: this.atLeastBreakPoint,
                atMost: this.atMostBreakPoint,
            },
            groups: {
                data: PAGE_PROPS.session?.groups ?? {
                    adminGroups: [],
                    memberGroups: [],
                },
                isGroupAdmin: this.isGroupAdmin,
                isGroupAdminOf: this.isGroupAdminOf,
                isMemberOf: this.isMemberOf,
            },
            modalTrigger: this.modalTrigger,
            triggerFlowModal: this.triggerFlowModal,
            closeModal: this.closeModal,
            modalActive: false,
            setModalActive: this.setModalActive,
            setCommunityOnboardCompleted: this.setCommunityOnboardCompleted,
            communityOnboardingCompleted:
                headerProps && headerProps.communityOnboarding
                    ? headerProps.communityOnboarding.completed
                    : true,
            sentry: sentryClient(PAGE_PROPS.sentry),
            systemPasswordReseted: false,
            setSystemPasswordReseted: (value) => {
                this.setState({ systemPasswordReseted: value });
            },
            getUserGTMMetricsMetadata: this.getUserGTMMetricsMetadata,
        };
    }

    componentDidMount() {
        this.generatePageKey();
        this.onFireSignUpMetric();
        this.onLoginMetric();
        this.isUserFGB();
        this.isObtPage(this.props.PAGE_PROPS);
        this.fetchLoginWallData();
        this.checkSurveyCookie();
        this.checkDisplaySurveyWithQueryString();
        this.checkShouldShowQuickProfileOnboarding();
        try {
            this.fireGTMPageViewMetric();
        } catch (error) {
            // This might be fired in tests for the context, this covers that scenario
        }

        // fetch initially user status
        this.getUserStatus();

        // 30 seconds after initial load, periodically update user status every 30 seconds
        if (this.props.PAGE_PROPS?.session?.username) {
            setTimeout(
                setInterval(() => this.getUserStatus(false), 30000),
                30000
            );
        }

        this.setCurrentBreakpoint();

        window.addEventListener('resize', this.onResizeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeWindow);
    }

    setCommunityOnboardCompleted = () =>
        this.setState({ communityOnboardingCompleted: true });

    isGroupAdmin = () => this.state.groups.data.adminGroups.length > 0;

    isGroupAdminOf = (communityGroupId) =>
        !!this.state.groups.data.adminGroups.find(
            (g) => g.id === communityGroupId
        );

    isMemberOf = (communityGroupId) =>
        !!this.state.groups.data.memberGroups.find(
            (g) => g.id === communityGroupId
        );

    setCurrentBreakpoint = () => {
        if (window && document) {
            const w = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth
            );

            if (typeof w !== 'number') return;

            const { breakpoint, isMobile } = getBreakpointOnResize(w);

            this.setState((prevState) => ({
                responsive: { ...prevState.responsive, breakpoint },
                isMobile,
            }));

            return breakpoint;
        }
    };

    onResizeWindow = () => debounce(150, true, this.setCurrentBreakpoint);

    atLeastBreakPoint = (breakpoint) => {
        return this.state.responsive.breakpoint >= breakpoint;
    };

    atMostBreakPoint = (breakpoint) => {
        return this.state.responsive.breakpoint <= breakpoint;
    };

    triggerLoginModalAfterDirectApply = () => {
        setTimeout(() => {
            this.setState((prevState) => ({
                loginWall: {
                    ...prevState.loginWall,
                    isOpened: !prevState.isDirectApplyJobSearchOpen,
                },
            }));
            if (!this.state.isDirectApplyJobSearchOpen) {
                this.setLastSignupWallPageView();
                EmitMetric({
                    misc_event_type: 'signup-wall-viewed',
                    misc_event_count: 1,
                    test_flag: this.state.placementName,
                });
            }
        }, 1000 * this.state.delaySeconds);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { loginWidget, modals } = this.state;
        const { hash, metrics } = loginWidget;

        const { auth0, redirectUri, url } = this.props.PAGE_PROPS;
        const { PAGE_PROPS } = this.props;

        if (
            prevState.isDirectApplyJobSearchOpen !==
                this.state.isDirectApplyJobSearchOpen &&
            !this.state.isDirectApplyJobSearchOpen
        ) {
            this.triggerLoginModalAfterDirectApply();
        }

        if (
            PAGE_PROPS.session?.shouldChangePassword &&
            modals.type === null &&
            !this.state.systemPasswordReseted
        ) {
            return this.modalTrigger('forceResetPasswordModal', {});
        }
        if (hash === null) return null;

        const layoutType = this.getLoginLayout();
        const callToActionSubTitle = this.getLoginCTA();
        const loginRedirectPath = this.getLoginRedirectPath();

        const loginWidgetProps = {
            auth0,
            layoutType,
            loginMetrics: metrics,
            redirectUri,
            currentPath: url.path,
            loginRedirectPath,
            callToActionSubTitle,
        };

        if (
            prevState.loginWidget.isOpened !== loginWidget.isOpened &&
            loginWidget.isOpened
        ) {
            return this.modalTrigger('authModal', { ...loginWidgetProps });
        }

        if (prevState.modals.type === 'authModal' && modals.type === null) {
            return this.closeLogin();
        }

        return null;
    };

    /**
     *  User Status API:
     *      contains status for notifications, onboarding, job reviews, and topics
     */
    getUserStatus = async (updateJobReviewStatus = true) => {
        try {
            if (
                this.state.pageDisplayMode ===
                PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT
            ) {
                const response = await axios.get('/api/employer/status');
                const { notifications, personalized } = response.data;

                this.setState({
                    notifications,
                    badgeCountData: personalized,
                });
            } else {
                const response = await axios.get('/api/user/status');
                const userStatus = response.data;
                const {
                    personalized,
                    notifications,
                    communityOnboarding,
                    jobReview,
                } = userStatus;

                const newState = {
                    badgeCountData: personalized,
                    notifications,
                    topics: notifications && notifications.topics,
                    communityOnboardingCompleted:
                        communityOnboarding && communityOnboarding.completed,
                };

                if (updateJobReviewStatus) {
                    newState.jobReviewStatus = jobReview;
                }

                this.setState(newState);
            }
        } catch (e) {
            this.state.sentry.captureException(e, {
                message: `Why is user status api 404?`,
                extra: {
                    user: this.state.user,
                },
            });
            return false;
        }
    };

    toggleJobProfileSnippetComplete = () =>
        this.setState((prevState) => ({
            jobProfileSnippet: {
                ...prevState.jobProfileSnippet,
                isCompleted: !prevState.jobProfileSnippet.isCompleted,
            },
        }));

    isAdmin = () => {
        const { PAGE_PROPS } = this.props;

        if (
            PAGE_PROPS.session?.role === 'superadmin' ||
            PAGE_PROPS.session?.additionalRoles?.includes('superadmin')
        ) {
            return true;
        }

        return false;
    };

    getUserGTMMetricsMetadata = () => {
        const userMetadata = {};
        const { pageProps } = this.state;
        userMetadata.is_fgb = pageProps.headerProps?.session?.email?.includes(
            FGB_DOMAIN
        );
        if (pageProps?.session?.userCommunityData) {
            userMetadata.is_vip = !!pageProps.session.userCommunityData.isVip;
        }
        if (pageProps?.session) {
            userMetadata.user_id = pageProps.session.id;
        }
        userMetadata.fgb_id = Cookies.get('FGB_ID');
        if (pageProps) {
            userMetadata.is_bot = !!pageProps.isbot;
        }
        return userMetadata;
    };

    fireGTMPageViewMetric = () => {
        const userGTMMetadata = this.getUserGTMMetricsMetadata();
        const pageTitle = this.state.pageProps.meta.title;
        const pagePath = this.state.pageProps.url.original;
        fireGTMMetric(PAGE_VIEW_EVENT, pagePath, pageTitle, userGTMMetadata);
    };

    closeLogin = () => {
        this.setState({ loginWidget: { hash: null, isOpened: false } });
        Cookies.set(AUTH_WRAPPER_COOKIE, '0', { expires: 1095 });
    };

    onWrapperClick = (
        e,
        hash,
        metrics = {},
        customLoginMessage = null,
        layout = null,
        cookie = null,
        redirectUrl = null
    ) => {
        if (this.state.user === null && !this.state.isOpened) {
            if (cookie) {
                Cookies.set(cookie.name, cookie.value, { expires: 1093 });
            }

            this.setState({
                loginWidget: {
                    hash,
                    isOpened: true,
                    metrics,
                    customLoginMessage,
                    layout,
                    cookie,
                    redirectUrl,
                },
            });
            Cookies.set(AUTH_WRAPPER_COOKIE, '1', { expires: 1095 });

            if (window) {
                window.__disableSumome = 'disableSumome';
            }

            this.toggleJobReviewSurvey(false);

            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            return false;
        }
        return true;
    };

    isSingleContentPage = () => this.state.singleContentPage;

    getLoginCTA = () => {
        const { loginWidget } = this.state;
        const { customLoginMessage, metrics } = loginWidget;

        if (customLoginMessage) return customLoginMessage;
        if (
            metrics?.login_trigger &&
            LOGIN_DESCRIPTIONS[metrics.login_trigger]
        ) {
            return LOGIN_DESCRIPTIONS[metrics.login_trigger];
        }

        return '';
    };

    getLoginLayout = () => {
        const { loginWidget } = this.state;
        const { metrics, layout } = loginWidget;

        if (
            metrics?.login_trigger &&
            LOGIN_LAYOUT_DEFAULTS[metrics.login_trigger]
        )
            return LOGIN_LAYOUT_DEFAULTS[metrics.login_trigger];
        if (layout) return layout;

        return CREATE_LAYOUT;
    };

    getLoginRedirectPath = () => {
        const {
            loginWidget: { hash, redirectUrl },
        } = this.state;
        const {
            PAGE_PROPS: { url },
        } = this.props;

        if (redirectUrl) return redirectUrl;

        return `${url.original}#${hash}`;
    };

    getAuthRequiredWrapper = (
        hash,
        Component,
        loginMetrics,
        customLoginMessage = null,
        layout = null,
        cookie = () => {},
        redirectUrl = null
    ) => {
        return (
            <div
                role="button"
                aria-label="Sign Up"
                tabIndex="0"
                className="wrap-auth-div"
                style={{
                    outline: 'none',
                    textDecoration: 'none !important',
                    WebkitUserSelect: 'none !important' /* Safari */,
                    MozUserSelect: 'none !important' /* Firefox */,
                    msUserSelect: 'none !important' /* IE10+/Edge */,
                    userSelect: 'none !important' /* Standard */,
                    WebkitTapHighlightColor: 'transparent',
                    'not(.touch):hover': {
                        color: 'transparent',
                    },
                }}
                onClick={(e) => {
                    return this.onWrapperClick(
                        e,
                        hash,
                        loginMetrics,
                        customLoginMessage,
                        layout,
                        cookie,
                        redirectUrl
                    );
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        return this.onWrapperClick(
                            e,
                            hash,
                            loginMetrics,
                            customLoginMessage,
                            layout,
                            cookie,
                            redirectUrl
                        );
                    }
                }}
            >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    id={hash}
                    style={{
                        position: 'absolute',
                        transform: 'translateY(-50vh)',
                        textDecoration: 'none !important',
                        WebkitUserSelect: 'none !important' /* Safari */,
                        MozUserSelect: 'none !important' /* Firefox */,
                        msUserSelect: 'none !important' /* IE10+/Edge */,
                        userSelect: 'none !important' /* Standard */,
                        WebkitTapHighlightColor: 'transparent',
                        'not(.touch):hover': {
                            color: '#FFF',
                        },
                    }}
                    name={hash}
                />
                {Component}
            </div>
        );
    };

    isUserFGB = () => {
        const { PAGE_PROPS } = this.props;
        if (PAGE_PROPS.headerProps?.session?.email?.includes(FGB_DOMAIN)) {
            this.setState({ userIsFGB: true });
        }
    };

    isObtPage = (pageProps) => {
        // check to see if obt=true is in the  query param url
        const urlParams = new URLSearchParams(window.location.search);
        const obt = urlParams.get('obt');
        if (obt === 'true') {
            EmitMetric({
                misc_event_type: 'obtest',
                misc_event_count: 1,
                referrer: window.referrer,
                user_id: pageProps?.session?.id,
            });
        }
    };

    fetchNotification = async (initialLoad = false) => {
        if (this.state.isLoggedIn()) {
            await axios
                .get(
                    `/api/community/feed/notifications?isInitialLoad=${initialLoad}`
                )
                .then((res) => {
                    this.setState({ notifications: res.data });
                })
                .catch((err) => this.state.sentry.captureException(err));
        }
    };

    onboardingCallToActionCallbackFunction = () =>
        this.setState({ showOnboardingWidget: true });

    removeCallToAction = () => this.setState({ showOnboardingWidget: false });

    openCreateContent = (topics, currentTopic) => {
        this.setState({ contentWidget: { topics, currentTopic } });
    };

    closeCreateContent = () => {
        this.setState({ contentWidget: null });
    };

    getCreateContent = () => {
        if (this.state.contentWidget === null) {
            return null;
        }
        return (
            <CreateContentPopupV2
                currentTopic={this.state.contentWidget.currentTopic}
                topics={this.state.contentWidget.topics}
                toggle={this.closeCreateContent}
                modal
            />
        );
    };

    closeLoginWall = () => {
        const { showLeadinPopup } = this.state;
        if (showLeadinPopup) {
            this.setLeadLoginInPopup(false);
            this.setState({
                leadInEmailReturn: false,
            });
        }

        const loginWall = Object.assign(this.state.loginWall, {
            isOpened: false,
        });
        this.setState(loginWall);
        EmitMetric({
            misc_event_type: 'signup-wall-closed',
            misc_event_count: 1,
            test_flag: this.state.loginWall.data.placementName,
        });
    };

    getLoginWall = () => {
        const {
            leadInLogin,
            showLeadinPopup,
            setLeadLoginInPopup,
        } = this.state;

        if (leadInLogin && !showLeadinPopup) {
            return null;
        }
        // HERE USE the FGB ID TO TRACK NON LOGGED IN USER
        let isEmailReturn = false;

        const hash = this.state.loginWidget?.hash || '';
        const isLoginWidgetOpened = this.state.loginWidget?.isOpened;

        if (this.state.loginWall.data === null) {
            return null;
        }

        try {
            const urlParams = new URLSearchParams(window.location.search);
            isEmailReturn = urlParams.get('utm_medium') === 'email';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        if (!isLoginWidgetOpened && !this.state.showJobReviewSurvey) {
            return (
                <LoginWall
                    setLeadLoginInPopup={setLeadLoginInPopup}
                    showLeadinPopup={showLeadinPopup}
                    layoutType={
                        isEmailReturn || this.state.leadInEmailReturn
                            ? LOGIN_LAYOUT
                            : CREATE_LAYOUT
                    }
                    auth0={this.props.PAGE_PROPS.auth0}
                    loginRedirectPath={`${this.props.PAGE_PROPS.url.original}#${hash}`}
                    redirectUri={this.props.PAGE_PROPS.redirectUri}
                    url={this.props.PAGE_PROPS.url}
                    setLastSignupWallPageView={this.setLastSignupWallPageView}
                    closeLoginWall={this.closeLoginWall}
                    loginWallData={this.state.loginWall.data}
                    isOpened={this.state.loginWall.isOpened}
                />
            );
        }
    };

    setLoginLayout = (layout) => {
        this.setState((prevState) => ({
            loginWidget: {
                ...prevState.loginWidget,
                loginLayout: layout,
            },
        }));
    };

    getLoginWallData = () => {
        let isEmailReturn = false;

        const hash = this.state.loginWidget?.hash || '';
        const isLoginWidgetOpened = this.state.loginWidget?.isOpened;
        if (this.state.loginWall.data === null) {
            return null;
        }

        try {
            const urlParams = new URLSearchParams(window.location.search);
            isEmailReturn = urlParams.get('utm_medium') === 'email';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        if (!isLoginWidgetOpened && !this.state.showJobReviewSurvey) {
            return {
                leadInLogin: this.state.leadInLogin,
                layoutType:
                    isEmailReturn || this.state.leadInEmailReturn
                        ? LOGIN_LAYOUT
                        : CREATE_LAYOUT,
                auth0: this.props.PAGE_PROPS.auth0,
                loginRedirectPath: `${this.props.PAGE_PROPS.url.original}#${hash}`,
                redirectUri: this.props.PAGE_PROPS.redirectUri,
                url: this.props.PAGE_PROPS.url,
                setLastSignupWallPageView: this.setLastSignupWallPageView,
                closeLoginWall: this.closeLoginWall,
                loginWallData: this.state.loginWall.data,
                isOpened: this.state.loginWall.isOpened,
            };
        }
        return null;
    };

    incrementPageViews = () => {
        const currentTime = Date.now();
        let totalPageViews = FGBLocalStorage.getItem('totalPageViews');

        if (totalPageViews) {
            const diffHours = Math.floor(
                (currentTime - totalPageViews.updated) / 1000 / 60 / 60
            );

            if (diffHours >= 2) {
                // reset every two hours
                this.clearPageViewsAndSignupWallPageView();
                totalPageViews = { updated: currentTime, count: 1 };
            } else {
                totalPageViews.count += 1;
            }
        } else {
            totalPageViews = { updated: currentTime, count: 1 };
        }

        this.setState({ totalPageViews: totalPageViews.count });
        FGBLocalStorage.setItem('totalPageViews', totalPageViews);

        this.setLastSignupWallPageView(true);
    };

    setLastSignupWallPageView = (initial = false) => {
        let pageView;

        if (initial) {
            pageView = FGBLocalStorage.getItem('lastSignupWallPageView');
        } else {
            pageView = FGBLocalStorage.getItem('totalPageViews');
        }
        this.setState({
            lastSignupWallPageView: pageView ? pageView.count : 0,
        });
        FGBLocalStorage.setItem('lastSignupWallPageView', pageView);
    };

    clearPageViewsAndSignupWallPageView = () => {
        FGBLocalStorage.setItem('totalPageViews', null);
        FGBLocalStorage.setItem('lastSignupWallPageView', null);
    };

    fetchLoginWallData = async (forceModal = false) => {
        const { leadInLogin } = this.state;
        const showLoginWallFromProps = this.props.PAGE_PROPS.showLoginWall;

        if (!showLoginWallFromProps && !forceModal) {
            return null;
        }
        /**
         * do not show the loginwall if the reference is google
         */
        if (window?.document?.referrer.includes('google') && !forceModal) {
            return null;
        }

        /**
         * if it's a CMS test, skip the session check
         */
        if (this.state.isLoggedIn() && !this.isLoginWallCMSTesting()) {
            return null;
        }

        /**
         * if ?fgbWallBypass=true, skip the wall
         */
        if (this.getParamValue('fgbWallBypass') === 'true') {
            return null;
        }

        try {
            this.incrementPageViews();
        } catch (e) {
            this.state.sentry.captureException(e);
        }

        let totalPageViews;
        let lastSignupWallPageView;
        let useCache;

        if (this.isLoginWallCMSTesting()) {
            totalPageViews = this.getParamValue('totalPageViews');
            lastSignupWallPageView = this.getParamValue(
                'lastSignupWallPageView'
            );
            useCache = false;
        } else {
            totalPageViews = this.state.totalPageViews;
            lastSignupWallPageView = this.state.lastSignupWallPageView;
            useCache = true;
        }

        try {
            const result = await axios.post(API_FETCH_LOGIN_WALL, {
                totalPageViews,
                lastSignupWallPageView,
                useCache,
                url: this.props.PAGE_PROPS.url.path,
            });

            if (result.data.data) {
                const { placementName, delaySeconds } = result.data.data;
                const getLoginData = () => {
                    this.setState((prevState) => ({
                        loginWall: {
                            ...prevState.loginWall,
                            isOpened: !prevState.isDirectApplyJobSearchOpen,
                            data: result.data.data,
                        },
                        delaySeconds,
                        placementName,
                    }));
                    if (!this.state.isDirectApplyJobSearchOpen) {
                        this.setLastSignupWallPageView();
                        EmitMetric({
                            misc_event_type: 'signup-wall-viewed',
                            misc_event_count: 1,
                            test_flag: placementName,
                        });
                    }
                };
                if (!leadInLogin) {
                    setTimeout(() => {
                        getLoginData();
                    }, 1000 * delaySeconds);
                } else {
                    getLoginData();
                }
            }

            // If a param is appended, add login wall
            if (this.getParamValue('regwall')) {
                const { pathname } = new URL(window.location.href);
                const urlValue = this.getParamValue('regwall');
                const loginData = getRegwallValues(urlValue, pathname);
                if (loginData) {
                    this.setState((prevState) => ({
                        loginWall: {
                            ...prevState.loginWall,
                            isOpened: true,
                            data: loginData,
                        },
                    }));
                    return;
                }
            }

            return result?.data?.data;
        } catch (e) {
            this.state.sentry.captureException(e);
        }
    };

    getParamValue = (key) => {
        const { searchParams } = new URL(window.location.href);
        return searchParams.get(key);
    };

    isLoginWallCMSTesting = () =>
        !!(
            this.getParamValue('lastSignupWallPageView') &&
            this.getParamValue('totalPageViews')
        );

    // move status API up
    getJobReviewSurvey = () => {
        const user = this.props.PAGE_PROPS.session || null;
        const company = this.props.PAGE_PROPS.company
            ? {
                  id: this.props.PAGE_PROPS.company.companyId,
                  name: this.props.PAGE_PROPS.company.companyName,
                  logo: this.props.PAGE_PROPS.company.logo,
                  isSponsor: this.props.PAGE_PROPS.company.isSponsor,
              }
            : null;

        return (
            <JobReviewProvider
                user={user}
                company={company}
                closeSurvey={this.toggleJobReviewSurvey}
                surveyCookieStatus={this.state.surveyCookieStatus}
                loggedIn={this.state.isLoggedIn()}
                onWrapperClick={this.state.onWrapperClick}
                jobReviewStatus={this.state.jobReviewStatus}
            >
                <Modal
                    wrapClassName="external-styles"
                    isOpen={this.state.showJobReviewSurvey}
                    style={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                        margin: '0 auto',
                    }}
                >
                    <ModalBody className="p-0">
                        <ReviewSurveyCardShell
                            PAGE_PROPS={this.props.PAGE_PROPS}
                        />
                    </ModalBody>
                </Modal>
            </JobReviewProvider>
        );
    };

    /*
    Get login wall with custom text
    */

    setLoginWallWithDescriptionText = async ({ title, description }) => {
        let delaySeconds = 0;
        if (!this.state.loginWall?.data) {
            const response = await this.fetchLoginWallData({
                forceModal: true,
            });
            if (response?.delaySeconds) {
                delaySeconds = response.delaySeconds;
            }
        }
        setTimeout(() => {
            this.setState((prevState) => ({
                loginWall: {
                    ...prevState.loginWall,
                    isOpened: true,
                    data: {
                        ...prevState?.loginWall?.data,
                        title,
                        description,
                    },
                },
            }));
        }, 1000 * delaySeconds);
    };

    toggleJobReviewSurvey = (state = false, company = null) => {
        this.setState({
            showJobReviewSurvey: state,
            company,
        });
    };

    checkDisplaySurveyWithQueryString = () => {
        const hasParam = getParameterByName('showSurvey');

        if (hasParam) {
            const shouldToggleSurvey = hasParam === 'true';
            this.toggleJobReviewSurvey(shouldToggleSurvey);
        }
    };

    checkSurveyCookie = (val = 'ReviewSurveyStatus') => {
        const status = Cookies.get(val);
        this.setState({
            surveyCookieStatus: status,
        });

        if (status && status.length) this.toggleJobReviewSurvey(true);
    };

    checkShouldShowQuickProfileOnboarding = () => {
        const { pageProps, isLoggedIn, showJobReviewSurvey } = this.state;
        const SHOW_QUICK_REGISTRATION_KEY = 'quickRegistrationModal';
        const hasQuickRegistrationOnboardingCookie = Cookies.get(
            SHOW_QUICK_REGISTRATION_KEY
        );
        const shouldShowQuickRegistration =
            hasQuickRegistrationOnboardingCookie && !showJobReviewSurvey;

        if (!shouldShowQuickRegistration || !isLoggedIn()) return null;

        const closeOptions = { backgroundExits: false, escapeExits: false };

        const modalData = {
            stepPopulateProps: buildQuickProfileOnboardingDefaultState({
                quickProfile: {
                    name:
                        pageProps &&
                        pageProps.session &&
                        pageProps.session.name,
                },
            }),
            generalModalProps: {
                toggleJobProfileSnippetComplete: this
                    .toggleJobProfileSnippetComplete,
                closeOptions,
                showSkipButton: false,
            },
        };

        return this.triggerFlowModal(quickProfileOnboardingFlowType, modalData);
    };

    onFireSignUpMetric = () => {
        const signUpTrigger = Cookies.get('REGISTRATION-TRIGGER');
        let loginMetrics = {};
        if (window) {
            loginMetrics = FGBLocalStorage.getItem('last_login_metrics') || {};
        }
        if (signUpTrigger && signUpTrigger !== 'OFF') {
            const loginCookie = {};
            if (Cookies.get('LOGIN-TRIGGER')) {
                loginCookie.login_trigger = Cookies.get('LOGIN-TRIGGER');
            }
            EmitMetric({
                registration_source: signUpTrigger,
                user_registered: 1,
                ...loginMetrics,
                ...loginCookie,
            });

            if (this.state.isLoggedIn()) {
                const id = `${this.props.PAGE_PROPS.session?.id ?? ''}`;
                // eslint-disable-next-line no-undef
                fireGTMMetric(
                    'user-registered',
                    '',
                    '',
                    {
                        userId: id,
                        userRegistration: '1',
                        label: 'registration',
                        category: 'userRegistered',
                    },
                    'dataLayer'
                );
                Cookies.set('REGISTRATION-TRIGGER', 'OFF');
            }
        }
    };

    onLoginMetric = () => {
        const loginSource = Cookies.get('LOGIN-SOURCE');
        if (loginSource && this.state.isLoggedIn()) {
            const id = `${this.props.PAGE_PROPS.session?.id ?? ''}`;
            EmitMetric({
                user_id: id,
                login_success: 1,
                registration_source: loginSource,
                login_trigger: Cookies.get('LOGIN-TRIGGER') || 'generic',
                url: this.props.PAGE_PROPS.url,
            });

            Cookies.remove('LOGIN-SOURCE');
        }
    };

    /**
     * function to trigger the modal
     *
     * @param {string} type - type of modal
     * @param {object} data - data (props) to pass to modal component TODO: deprecate in favor of modalProps
     * @param {object} modalProps - props to pass to modal component
     * @param {object} modalProps.stepPopulateProps - initial values to pass to modal steps i.e. { selectTime: { preferredTimeId: '' } }
     * @param {object} modalProps.generalModalProps - object with general data for modal i.e. { appointmentTimeOptions: [] }
     * @returns {void}
     */
    modalTrigger = (type = null, data = null, modalProps = {}) => {
        const { stepPopulateProps = {}, generalModalProps = {} } = modalProps;
        if (type)
            return this.setState({
                modalActive: true,
                modals: { type, data, stepPopulateProps, generalModalProps },
            });
        return this.setState({
            modalActive: false,
            modals: {
                type: null,
                data: null,
                stepPopulateProps,
                generalModalProps,
            },
        });
    };

    /**
     * function to set whether or not a modal is currently active
     * use with legacy modals not handled by ModalManager or modal context
     * to let rest of page consumers know a modal is showing
     *
     * @param {boolean} isActive - modal currently active or not
     * @returns {void}
     */
    setModalActive = (isActive = false) =>
        this.setState({ modalActive: isActive });

    triggerFlowModal = (type = null, modalProps = {}) =>
        this.modalTrigger(type, null, modalProps);

    closeModal = () => this.modalTrigger(null);

    getCommonModalProps = () => ({
        modalTrigger: this.modalTrigger,
        triggerFlowModal: this.triggerFlowModal,
        closeModal: this.closeModal,
        session: this.state.pageProps.session,
        featureFlags: this.state.pageProps.featureFlags,
        jobProfileSnippet: this.state.jobProfileSnippet,
    });

    onUpdate = (key, value) => {
        this.setState({ [key]: value });
    };

    setGlobalNavSticky = (isSticky = false) => {
        this.setState({
            isGlobalNavSticky: isSticky,
        });
    };

    setIsDirectApplyJobSearchOpen = (val) => {
        this.setState({
            isDirectApplyJobSearchOpen: val,
        });
    };

    setLeadLoginInPopup = (showLeadinPopup, emailReturn) => {
        this.setState({ showLeadinPopup });

        if (emailReturn) {
            this.setState({
                leadInEmailReturn: true,
            });
        }

        if (showLeadinPopup) {
            const loginWall = Object.assign(this.state.loginWall, {
                isOpened: true,
            });
            this.setState(loginWall);
        }
    };

    generatePageKey = () => this.setState({ pageKey: shortid.generate() });

    getApplicationNode = () => document.getElementById('root');

    setLoggedIn = (isLoggedIn) => this.setState({ loggedIn: isLoggedIn });

    getModalComponent = () => {
        const { modals } = this.state;
        const { type, stepPopulateProps, generalModalProps, data } = modals;

        const commonModalProps = this.getCommonModalProps();

        if (type === null) return null;

        if (v1ModalTypes.includes(type)) {
            return <ModalManager />;
        }

        return (
            <ModalProvider
                type={type}
                getApplicationNode={this.getApplicationNode}
                commonProps={commonModalProps}
                stepPopulateProps={stepPopulateProps}
                data={data}
                generalModalProps={generalModalProps}
            />
        );
    };

    render() {
        const { showJobReviewSurvey } = this.state;
        const { children } = this.props;

        const modalComponent = this.getModalComponent();
        return (
            <Context.Provider value={this.state}>
                {children}
                {!showJobReviewSurvey ? this.getLoginWall() : null}
                {this.getCreateContent()}
                {this.getJobReviewSurvey()}
                {modalComponent}
            </Context.Provider>
        );
    }
}

Provider.propTypes = {
    PAGE_PROPS: pagePropShape.isRequired,
    children: PropTypes.node.isRequired,
};

Provider.defaultProps = {};

// eslint-disable-next-line prefer-destructuring
export const Consumer = Context.Consumer;
