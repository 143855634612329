import PropTypes from 'prop-types';
import React from 'react';

import './ToolTip.scss';

export default class ToolTip extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
    }

    show(event) {
        this.setState({ show: true });
        event.preventDefault();
        event.stopPropagation();
    }

    hide = (event) => {
        this.setState({ show: false });
        window.removeEventListener('mouseover', this.hide);
        window.removeEventListener('touchstart', this.hide);
    };

    componentDidMount() {
        window.addEventListener('mouseover', this.hide);
        window.addEventListener('touchstart', this.hide);
    }

    componentWillUnmount() {
        window.removeEventListener('mouseover', this.hide);
        window.removeEventListener('touchstart', this.hide);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.show !== nextProps.show) {
            this.setState({ show: nextProps.show });
        }
    }

    static propTypes = {
        /**
         * whether to show the tooltip
         */
        show: PropTypes.bool,
        /**
         * function to close the tooltip
         */
        close: PropTypes.func,
        /**
         * title of the tooltip
         */
        title: PropTypes.string,
        /**
         * body of tooltip
         */
        children: PropTypes.node,
        /**
         * custom width
         */
        width: PropTypes.string,
        /**
         * custom height
         */
        height: PropTypes.string,
        /**
         * arrow direction
         */
        direction: PropTypes.oneOf(['up', 'down']),
        /**
         * type of toolTip
         */
        type: PropTypes.oneOf(['', 'tiny-black']),
    };

    static defaultProps = {
        title: null,
        width: null,
        height: null,
        direction: 'up',
        close: null,
        type: '',
    };

    render() {
        if (!this.state.show) {
            return null;
        }

        let style = {};
        if (this.props.width) style.width = this.props.width;
        if (this.props.height) style.height = this.props.height;

        let bgArrowClass = 'large-arrow-bg-' + this.props.direction;
        let fgArrowClass = 'large-arrow-fg-' + this.props.direction;

        return (
            <div
                className={`component-Elements-ToolTip ${this.props.type}`}
                style={style}
            >
                <div className={bgArrowClass} />
                <div className={fgArrowClass} />

                {this.props.close ? (
                    <div className="close" onClick={this.props.close} />
                ) : null}

                {this.props.title ? (
                    <div className="tooltip-header">{this.props.title}</div>
                ) : null}

                <div className="tooltip-body">{this.props.children}</div>
            </div>
        );
    }
}
