const educationDegrees = {
    highschool: 'High School',
    associate: 'Associate Degree',
    bachelor: "Bachelor's Degree",
    master: "Master's Degree",
    doctorate: 'Doctorate / PhD',
};

const yearsOfExperience = {
    'less than 2': 'less than 2',
    '2-5': '2-5',
    '6-10': '6-10',
    '11+': '11+',
};

const races = {
    caucasian: 'Caucasian (Non-Hispanic)',
    black: 'Black',
    hispanic: 'Hispanic',
    asian: 'Asian or Pacific Islander',
    native: 'Native American or Alaska Native',
    mixed: 'Mixed',
    other: 'Other',
    undisclosed: 'Prefer not to say',
};

const genders = {
    male: 'Male',
    female: 'Female',
    undisclosed: 'Prefer not to say',
    custom: 'Custom',
};

const statuses = {
    complete: 'complete',
    incomplete: 'incomplete',
    rejected: 'rejected',
    applied: 'applied',
    archived: 'archived',
    inactive: 'inactive',
};

const constants = Object.freeze({
    EDUCATION_DEGREES: Object.freeze(educationDegrees),
    YEARS_OF_EXPERIENCE: Object.freeze(yearsOfExperience),
    RACES: Object.freeze(races),
    GENDERS: Object.freeze(genders),
    STATUSES: Object.freeze(statuses),
});

export default constants;
