import PropTypes from 'prop-types';
import React from 'react';
import SVGFromCloudinary from '../../../../Elements/SVGFromCloudinary/SVGFromCloudinary';

import './IconTitleItemLayout.scss';
import UserImage from '../../../../Content/User/UserDisplay/UserImage/UserImage';

const IconImage = ({ itemKey, imgSrc, title, size }) => {
    const isImageSvg = imgSrc && imgSrc.endsWith('.svg');

    return isImageSvg ? (
        <SVGFromCloudinary
            className="component-Layout-Header-Layout-IconTitleItemLayout__icon-wrapper__img"
            alt={`${title || itemKey} icon`}
            cloudinaryID={imgSrc}
            size={size || { width: 24, height: 24 }}
        />
    ) : (
        <UserImage
            className="component-Layout-Header-Layout-IconTitleItemLayout__icon-wrapper__img__user-image"
            image={{
                alt: `${title || itemKey} icon`,
                src: imgSrc,
            }}
            iconSize={24}
        />
    );
};

const IconTitleItem = ({ itemKey, title, imgSrc, children, size }) => (
    <React.Fragment>
        {imgSrc && (
            <span className="component-Layout-Header-Layout-IconTitleItemLayout__icon-wrapper">
                <IconImage
                    imgSrc={imgSrc}
                    title={title}
                    itemKey={itemKey}
                    size={size}
                />
            </span>
        )}

        {title && (
            <p className="component-Layout-Header-Layout-IconTitleItemLayout__title">
                {title}
            </p>
        )}

        {children && children}
    </React.Fragment>
);

export default class IconTitleItemLayout extends React.PureComponent {
    render() {
        const {
            url,
            handleClick,
            className,
            title,
            itemKey,
            imgSrc,
            children,
            size,
        } = this.props;
        const onClick =
            typeof handleClick === 'function' ? handleClick : () => {};

        if (url) {
            return (
                <a
                    className={`component-Layout-Header-Layout-IconTitleItemLayout anchor ${className}`}
                    onClick={onClick}
                    href={url}
                >
                    <IconTitleItem
                        itemKey={itemKey}
                        title={title}
                        imgSrc={imgSrc}
                        size={size}
                    >
                        {children}
                    </IconTitleItem>
                </a>
            );
        }

        return (
            <button
                type="button"
                className={`component-Layout-Header-Layout-IconTitleItemLayout button ${className}`}
                onClick={onClick}
            >
                <IconTitleItem
                    itemKey={itemKey}
                    title={title}
                    imgSrc={imgSrc}
                    size={size}
                >
                    {children}
                </IconTitleItem>
            </button>
        );
    }
}

IconTitleItemLayout.defaultProps = {
    itemKey: '',
    url: null,
    handleClick: () => {},
    className: '',
    title: '',
    imgSrc: '',
    children: null,
    size: undefined,
};

IconTitleItemLayout.propTypes = {
    /**
     * key of item
     */
    itemKey: PropTypes.string,
    /**
     * url for routing
     */
    url: PropTypes.string,
    /**
     * handler on click action
     */
    handleClick: PropTypes.func,
    /**
     * additional styling classes
     */
    className: PropTypes.string,
    /**
     * item's title
     */
    title: PropTypes.string,
    /**
     * item's image
     */
    imgSrc: PropTypes.string,
    /**
     * children to be render along side with the icons & titles
     */
    children: PropTypes.node,
    size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};
