export const getRandomIndex = (arrayLength) => {
    return Math.floor(Math.random() * arrayLength);
};

export const defaultUserImages = [
    '/v1525721195/userImages/blurred-avatar-01.png',
    '/v1525721195/userImages/blurred-avatar-02.png',
    '/v1525721196/userImages/blurred-avatar-03.png',
    '/v1525721196/userImages/blurred-avatar-04.png',
    '/v1525721195/userImages/blurred-avatar-05.png',
    '/v1525721195/userImages/blurred-avatar-06.png',
    '/v1525721195/userImages/blurred-avatar-07.png',
    '/v1525721195/userImages/blurred-avatar-08.png',
    '/v1525721196/userImages/blurred-avatar-09.png',
    '/v1525721196/userImages/blurred-avatar-11.png',
    '/v1525721196/userImages/blurred-avatar-12.png',
    '/v1525721197/userImages/blurred-avatar-13.png',
    '/v1525721196/userImages/blurred-avatar-14.png',
    '/v1525721196/userImages/blurred-avatar-15.png',
    '/v1525721197/userImages/blurred-avatar-16.png',
    '/v1525721197/userImages/blurred-avatar-17.png',
    '/v1525721197/userImages/blurred-avatar-18.png',
    '/v1525721197/userImages/blurred-avatar-19.png',
    '/v1525721197/userImages/blurred-avatar-20.png',
    '/v1525721198/userImages/blurred-avatar-21.png',
    '/v1525721198/userImages/blurred-avatar-22.png',
    '/v1525721196/userImages/blurred-avatar-23.png',
    '/v1525721197/userImages/blurred-avatar-24.png',
    '/v1525721198/userImages/blurred-avatar-25.png',
    '/v1525721198/userImages/blurred-avatar-26.png',
    '/v1525721198/userImages/blurred-avatar-27.png',
    '/v1525721198/userImages/blurred-avatar-28.png',
    '/v1525721198/userImages/blurred-avatar-29.png',
    '/v1525721198/userImages/blurred-avatar-30.png',
    '/v1525721198/userImages/blurred-avatar-31.png',
    '/v1525721199/userImages/blurred-avatar-32.png',
    '/v1525721198/userImages/blurred-avatar-33.png',
    '/v1525721199/userImages/blurred-avatar-34.png',
    '/v1525721199/userImages/blurred-avatar-35.png',
    '/v1525721199/userImages/blurred-avatar-36.png',
    '/v1525721199/userImages/blurred-avatar-37.png',
];
