import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '../../Groups/InfoIcon/InfoIcon';

import ToolTip from '../../Wrappers/Tooltip/Tooltip';

import './FieldLabel.scss';

export default class FieldLabel extends React.PureComponent {
    static propTypes = {
        /**
         * string of label
         */
        label: PropTypes.string.isRequired,
        /**
         * boolean to show tooltip or not
         */
        shouldShowTooltip: PropTypes.bool,
        /**
         * string of text in tooltip
         */
        tooltipText: PropTypes.string,
        /**
         * boolean to show asterisk for required fields
         */
        isRequired: PropTypes.bool,
    };

    static defaultProps = {
        shouldShowTooltip: true,
        tooltipText: '',
        isRequired: false,
    };

    renderTooltip = () =>
        this.props.shouldShowTooltip && (
            <ToolTip
                tooltipText={this.props.tooltipText}
                multiLine={true}
                className="field-label-tooltip"
            >
                <InfoIcon />
            </ToolTip>
        );

    renderAsterisk = () => this.props.isRequired && '*';

    render() {
        return (
            <span className="component-Elements-FieldLabel">
                {this.props.label}
                {this.renderTooltip()}
                {this.renderAsterisk()}
            </span>
        );
    }
}
