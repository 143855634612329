import axios from 'axios';
import { FGBLocalStorage, getRandomInt } from '../../utilities/helperFunctions';

const API_FETCH_BANNER = '/api/company/get-company-for-ad';

export const getHeadlineAndTestflag = (
    companyName,
    jobLocation,
    userLocation
) => {
    let headlinesAndFlags = {
        'near-you-text': `${companyName} is hiring near you`,
        'near-your-location-text': userLocation
            ? `${companyName} is hiring in ${userLocation}`
            : null,
        'is-hiring-no-location': `${companyName} is hiring`,
        'in-company-location-text': jobLocation
            ? `${companyName} is hiring in ${jobLocation}`
            : null,
    };
    const testFlags = Object.keys(headlinesAndFlags).filter(
        (k) => headlinesAndFlags[k]
    );
    const headlines = testFlags.map((k) => headlinesAndFlags[k]);

    const randomInt = getRandomInt(headlines.length);

    return { headline: headlines[randomInt], testFlag: testFlags[randomInt] };
};

export const fetchCompanyAds = async () => {
    const savedCompanyIds = FGBLocalStorage.getItem('companyAds');
    const res = await axios.post(API_FETCH_BANNER, {
        companyIds: savedCompanyIds,
    });
    const {
        jobLocation,
        userLocation,
        companyId,
        companyName,
        companyLogo,
        stickyText,
        clickThroughUrl,
    } = res.data.data;

    const headlineAndFlag = getHeadlineAndTestflag(
        companyName,
        jobLocation,
        userLocation
    );

    FGBLocalStorage.setItem('companyAds', companyId);

    return {
        jobLocation,
        userLocation,
        companyId,
        companyName,
        companyLogo,
        stickyText,
        clickThroughUrl,
        headline: headlineAndFlag.headline,
        testFlag: headlineAndFlag.testFlag,
    };
};
