import PropTypes from 'prop-types';
import React from 'react';
import InputField from '../../../Input/InputField/InputField';
import ButtonGrid from '../../Components/ButtonGrid/ButtonGrid';
import FormCheckboxWithDescription from '../../Components/FormCheckboxWithDescription/FormCheckboxWithDescription';

import './ButtonWithInputCard.scss';

export default class ButtonWithInputCard extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        //Button Data, default so to [{title: "Yes", value: true}, {title: "No", value: false}],
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]).isRequired,
            })
        ).isRequired,
        handleClick: PropTypes.func.isRequired,
        //Button to handle paid input
        firstInputHandler: PropTypes.func.isRequired,
        //Button to handle unpaid input
        secondInputHandler: PropTypes.func.isRequired,
        //Button to handle clicking of checkbox
        handleCheckbox: PropTypes.func.isRequired,
        //paid Value if already been filled before
        paidValue: PropTypes.number,
        //unpaid Value if already been filled before
        unpaidValue: PropTypes.number,
        // function to autoadvance
        firstInputLabel: PropTypes.string,
        secondInputLable: PropTypes.string,
        defaultChecked: PropTypes.bool,
        min: PropTypes.number,
    };

    static defaultProps = {
        paidValue: null,
        unpaidValue: null,
        firstInputLabel: 'Paid, in weeks',
        secondInputLable: 'Unpaid, in weeks',
        min: 0,
        max: 52,
    };

    renderInputs = () => {
        return (
            <React.Fragment>
                <div className={'input-section first-input'}>
                    <div className="label firstLabel">
                        {this.props.firstInputLabel}
                    </div>
                    <InputField
                        name={'paid'}
                        type={'number'}
                        onChange={this.props.firstInputHandler}
                        value={this.props.paidValue}
                        placeholder={'Enter a number between 0 and 52'}
                        min={this.props.min}
                        max={this.props.max}
                    />
                </div>
                <div className={'input-section second-input'}>
                    <div className="label secondLabel">
                        {this.props.secondInputLable}
                    </div>
                    <InputField
                        name={'unpaid'}
                        type={'number'}
                        onChange={this.props.secondInputHandler}
                        value={this.props.unpaidValue}
                        placeholder={'Enter a number between 0 and 52'}
                        min={this.props.min}
                        max={this.props.max}
                    />
                </div>
                <FormCheckboxWithDescription
                    onClick={this.props.handleCheckbox}
                    defaultChecked={this.props.defaultChecked}
                />
            </React.Fragment>
        );
    };
    render() {
        return (
            <div className="component-ReviewSurvey-QuestionCards-ButtonWithInputCard">
                <div className="controlled-height">
                    <ButtonGrid
                        buttonData={this.props.buttonData}
                        buttonType={'single'}
                        onClick={this.props.handleClick}
                        selectedItems={this.props.selectedButtonValues}
                    />
                </div>
                {this.props.showInputFields ? this.renderInputs() : null}
            </div>
        );
    }
}
