import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
// import './Intro.scss';
import MessageCard from '../../QuestionCards/MessageCard/MessageCard';

export default class Continue extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        buttonStyle: PropTypes.string,
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        isAnimated: PropTypes.bool,
        onAnswer: PropTypes.func,
        questionName: PropTypes.string,
        payloadKey: PropTypes.string,
        hasAuthWrapper: PropTypes.bool,
        nextStep: PropTypes.string,
        subStep: PropTypes.string,
        handleOptionalQuestionDecision: PropTypes.func,
    };

    static defaultProps = {
        hasAuthWrapper: true,
        buttonStyle: 'normal',
        buttonData: [
            {
                title: 'No, thanks',
                subtitle: 'Submit my anonymous review.',
                value: false,
            },
            {
                title: 'Yes',
                subtitle: 'Continue',
                value: true,
            },
        ],
        img: {
            src: '/v1551974344/icons/Transition_2x.png',
            alt: '',
        },
        isAnimated: false,
        onAnswer: (...args) => console.log(...args),
        questionName: 'part2',
        payloadKey: 'part2',
    };

    handleUpdate = (data) => {
        const response = data[0] || false;
        if (response === true) {
            this.props.getCurrentStep('14');
        } else if (response === false) {
            EmitMetric({
                misc_event_type: 'job-review-demographics-skipped',
                misc_event_count: 1,
            });
            this.props.getCurrentStep('OptionalEnd');
        }
    };

    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-Continue">
                <MessageCard {...this.props} onClick={this.handleUpdate} />
            </div>
        );
    }
}
