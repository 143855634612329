import moment from 'moment-timezone';
import striptags from 'striptags';

const ics = require('ics');

export const REGISTER_NOW_BUTTON = 'Register now';
export const JOIN_LIVE_EVENT_BUTTON = 'Join live event';
export const REGISTER_TO_JOIN = 'Register to join';

export const EVENT_OVER_BANNER = 'This event is over.';
export const REGISTRATIONG_CLOSED_BANNER =
    'Registration for this event is closed.';
export const EVENT_OVER_NO_VIDEO_BANNER =
    'This event is over. A recording will be available soon.';
export const EVENT_OVER_WITH_VIDEO_BANNER =
    'This event is over. A recording is available below.';
export const EVENT_IN_PROGRESS_BANNER = 'This event is currently in progress.';

export const CALENDARS = {
    GOOGLE: {
        id: 'google',
        itemText: 'Google Calendar',
    },
    APPLE: {
        id: 'apple',
        itemText: 'Apple Calendar',
    },
    OUTLOOK: {
        id: 'outlook',
        itemText: 'Outlook Calendar',
    },
    YAHOO: {
        id: 'yahoo',
        itemText: 'Yahoo! Calendar',
    },
};

export const getRegistrationText = ({
    userRegistered,
    eventStarted,
    eventEnded,
    eventType,
    registrationExpired,
    earlyStart,
}) => {
    if (
        registrationExpired &&
        eventType === 'Virtual Recruiting Event' &&
        !userRegistered
    ) {
        return null;
    }
    if (earlyStart && !eventEnded) {
        if (userRegistered) {
            return JOIN_LIVE_EVENT_BUTTON;
        }
        if (eventType === 'Webinar') {
            return REGISTER_TO_JOIN;
        }
        return null;
    }
    if (!eventStarted && !eventEnded) {
        if (!userRegistered) {
            return REGISTER_NOW_BUTTON;
        }
        return null;
    }
    return null;
};

export const getBannerText = ({
    userRegistered,
    eventEnded,
    eventType,
    video,
    registrationExpired,
    earlyStart,
    eventStarted,
}) => {
    if (eventEnded && eventType === 'Virtual Recruiting Event') {
        return EVENT_OVER_BANNER;
    }
    if (
        registrationExpired &&
        eventType === 'Virtual Recruiting Event' &&
        !userRegistered
    ) {
        return REGISTRATIONG_CLOSED_BANNER;
    }

    if (earlyStart && !eventEnded) {
        if (eventType === 'Webinar' || userRegistered) {
            return EVENT_IN_PROGRESS_BANNER;
        }
    }

    if (eventStarted && !eventEnded) {
        return REGISTRATIONG_CLOSED_BANNER;
    }
    if (eventEnded) {
        if (eventType === 'Webinar') {
            if (!video) {
                return EVENT_OVER_NO_VIDEO_BANNER;
            }
            return EVENT_OVER_WITH_VIDEO_BANNER;
        }
    }
    return null;
};

export const countDown = (startISOString, currentTimeISOstring = null) => {
    // Convert start time to 'America/New_York' time zone
    const eventStartTime = moment
        .tz(startISOString, 'America/New_York')
        .valueOf();

    // Get current time in 'America/New_York' time zone
    const currentTime = currentTimeISOstring
        ? moment.tz(currentTimeISOstring, 'America/New_York').valueOf()
        : moment.tz('America/New_York').valueOf();
    const distance = eventStartTime - currentTime;
    // get days, hours, minutes from event start time to current time

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (distance < 0) {
        return null;
    }

    return {
        days,
        hours,
        minutes,
    };
};

export const isInThePast = ({ date }) => {
    // use moment to check to see if the date is before today and account for is day light saving time
    const today = moment().tz('America/New_York');
    const eventDate = moment(date).tz('America/New_York');
    return eventDate.isBefore(today);
};

export const getFormattedDateString = (time) => {
    const momentStartTime = moment(new Date(moment.tz(time, 'UTC'))).tz(
        'America/New_York'
    );

    return momentStartTime.format('dddd, MMMM D, YYYY');
};

export const getFormattedTimeString = (time) => {
    const momentStartTime = moment(new Date(moment.tz(time, 'UTC'))).tz(
        'America/New_York'
    );
    const timeFormatted = momentStartTime.format('h:mm A');
    return timeFormatted;
};

export const arrayToSentence = (arr) => {
    const last = arr.pop();
    return `${arr.join(', ')} and ${last}`;
};

export const isOverflown = ({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight,
}) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

const formatEventDescriptionContent = (content, maxChars = 200) => {
    let cleanContent = striptags(content);
    cleanContent = cleanContent.replace('&amp;', '&');
    cleanContent = cleanContent.replace('&nbsp;', ' ');
    cleanContent = cleanContent.replace('&nbsp;', ' ');
    cleanContent = cleanContent.replace('&#39t;', "'");
    cleanContent = cleanContent.replace('&mdash;', ' ');
    const termination = cleanContent.length > 200 ? '...' : '';
    return `${cleanContent.slice(0, maxChars)} ${termination}`;
};

export const getAddToCalendarLinks = (event) => {
    const { viewUrl } = event;
    const startTime = moment(
        new Date(moment.tz(event.startTime, 'America/New_York'))
    ).tz('UTC');
    const endTime = moment(
        new Date(moment.tz(event.endTime, 'America/New_York'))
    ).tz('UTC');

    const googleStartTime = startTime.format('YYYYMMDDTHHmmss');
    const googleEndTime = endTime.format('YYYYMMDDTHHmmss');

    const outlookStartTime = encodeURIComponent(
        startTime.format('YYYY-MM-DDTHH:mm:ssZ')
    );
    const outlookEndTime = encodeURIComponent(
        endTime.format('YYYY-MM-DDTHH:mm:ssZ')
    );

    const eventDescription = encodeURIComponent(
        `${formatEventDescriptionContent(
            event.description
        )} \nJoin the event by clicking here: https://fairygodboss.com${viewUrl}`
    );
    const eventTitle = encodeURIComponent(`FGB Event: ${event.title}`);

    return {
        google: `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${googleStartTime}Z/${googleEndTime}Z&details=${eventDescription}&location=&trp=true`,
        outlook: `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&enddt=${outlookEndTime}&body=${eventDescription}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${outlookStartTime}&subject=${eventTitle}`,
        yahoo: `https://calendar.yahoo.com/?dur=&et=${googleEndTime}Z&st=${googleStartTime}Z&title=${eventTitle}&desc=${eventDescription}&v=60`,
    };
};

export const createIcsFile = (event) => {
    const url = `https://fairygodboss.com${event.viewUrl}`;
    const eventDescription = `${formatEventDescriptionContent(
        event.description
    )} \nJoin the event by clicking here: ${url}`;
    const startTime = moment(
        new Date(moment.tz(event.startTime, 'America/New_York'))
    ).tz('UTC');
    const endTime = moment(
        new Date(moment.tz(event.endTime, 'America/New_York'))
    ).tz('UTC');
    const parsedEvent = {
        start: [
            parseInt(startTime.format('YYYY'), 10),
            parseInt(startTime.format('MM'), 10),
            parseInt(startTime.format('D'), 10),
            parseInt(startTime.format('HH'), 10),
            parseInt(startTime.format('mm'), 10),
        ],
        startInputType: 'utc',
        end: [
            parseInt(endTime.format('YYYY'), 10),
            parseInt(endTime.format('MM'), 10),
            parseInt(endTime.format('D'), 10),
            parseInt(endTime.format('HH'), 10),
            parseInt(endTime.format('mm'), 10),
        ],
        endInputType: 'utc',
        title: `FGB Event: ${event.title}`,
        alarms: [
            {
                action: 'display',
                description: 'Reminder',
                trigger: { hours: 0, minutes: 10, before: true },
                repeat: 2,
                attachType: 'VALUE=URI',
                attach: 'Glass',
            },
        ],
        productId: 'fairygodboss.com',
        description: eventDescription,
        location: url,
        url,
        status: 'CONFIRMED',
        busyStatus: 'BUSY',
        // htmlContent: event.description,
        organizer: { name: 'Fairygodboss', email: 'events@fairygodboss.com' },
    };
    ics.createEvent(parsedEvent, (error, value) => {
        if (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return;
        }
        window.open(`data:text/calendar;charset=utf8,${escape(value)}`);
    });
};
