import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from '../../Elements/Tag/Tag';
import shortid from 'shortid';

import './Typeahead.scss';

const ENTER_KEY = 13;
const UP_KEY = 38;
const DOWN_KEY = 40;
const ITEM_ALREADY_ADDED_ERROR = `You've already selected this. Please select another one.`;

const setUserInputDefault = ({ id, name, parent }) => ({
    id,
    name,
    option: name,
    parent: parent || null,
});

export default class Typeahead extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            filteredSuggestions: [],
            selectedSuggestionIndex: 0,
            shouldShowSuggestions: false,
            userInput: ((props) => {
                if (
                    props.isMulti ||
                    !(props.selectedSuggestions && props.selectedSuggestions[0])
                ) {
                    return '';
                }

                if (props.selectedSuggestions[0].parent) {
                    return props.selectedSuggestions[0].parent.name;
                }

                return props.selectedSuggestions[0].name;
            })(props),
        };
    }

    static propTypes = {
        /**
         * boolean to check if allow user to add new item
         */
        allowAddNewItem: PropTypes.bool,
        /**
         * enum off or on for showing autocomplete
         */
        autoComplete: PropTypes.oneOf(['off', 'on']),
        /**
         * custom css class for styling
         */
        cssClass: PropTypes.string,
        /**
         * string of API call to make for creating new item
         */
        createNewItemApi: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
        ]),
        /**
         * function to serialize newly added item
         */
        createPayloadForCreation: PropTypes.func,
        /**
         * the api to fetch suggestions on typeahead
         */
        fetchSuggestionsApi: PropTypes.string.isRequired,
        /**
         * number for max character length for input
         */
        inputMaxLength: PropTypes.number,
        /**
         * input clears on multiple select but not single select
         */
        isMulti: PropTypes.bool,
        /**
         * callback once item is selected
         */
        onSelect: PropTypes.func.isRequired,
        /**
         * function to update state of parent with item removed
         */
        onRemoveSuggestion: PropTypes.func,
        /**
         * placeholder text for typeahead input
         */
        placeholderText: PropTypes.string,
        /**
         * add option key to returned data to make rendering generic
         */
        setUserInput: PropTypes.func.isRequired,
        /**
         * a function for setting errors in parent
         */
        onError: PropTypes.func,
        /**
         * array of selected items sent in to render tags
         */
        selectedSuggestions: PropTypes.array,
        /**
         * function callback for input keystrokes
         */
        onChange: PropTypes.func,
        /**
         * option to render suggestions as tags
         */
        withTags: PropTypes.bool,
        /**
         * contains array of key codes and key values that act like a enter key
         */
        enterKeys: PropTypes.object,
        /**
         * qa data attribute
         */
        //qaDataAttr: PropTypes.string
        // id value for input element
        inputId: PropTypes.string,
    };

    static defaultProps = {
        allowAddNewItem: false,
        autoComplete: 'off',
        createPayloadForCreation: (userInput) => ({ name: userInput }),
        cssClass: '',
        enterKeys: { values: [] },
        inputMaxLength: Infinity,
        isMulti: false,
        onChange: () => {},
        onError: () => {},
        onSelect: () => {},
        selectedSuggestions: [],
        setUserInput: setUserInputDefault,
        withTags: true,
        inputId: shortid(),
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    // for clicking outside of dropdown
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = () => {
        if (
            this.state.shouldShowSuggestions &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.setState({ shouldShowSuggestions: false });
        }
    };

    // set filteredSuggestions according to how parent wrapper wants suggestion obj to be
    mapKeysInSuggestion = (suggestions) =>
        suggestions && suggestions.map(this.props.setUserInput);

    handleChange = async (event) => {
        if (
            this.props.enterKeys &&
            this.props.enterKeys.values.includes(event.target.value)
        ) {
            return;
        }

        const userInput = event.target.value;

        // clears typeahead error on new input change
        this.props.onError();

        this.props.onChange(userInput);

        if (userInput.length > this.props.inputMaxLength) {
            this.setState({ filteredSuggestions: [] });
            this.props.onError(
                `Input exceeded max length of ${this.props.inputMaxLength}`
            );
        }

        if (this.props.fetchSuggestionsApi) {
            try {
                this.setState({ userInput });

                // cancel the previous request
                if (typeof this._source !== typeof undefined) {
                    this._source.cancel(
                        'Operation canceled due to new request.'
                    );
                }

                // save the new request for cancellation
                this._source = axios.CancelToken.source();
                const cancelToken = { cancelToken: this._source.token };

                const { data } = userInput.length
                    ? await axios.get(
                          `${this.props.fetchSuggestionsApi}/${userInput}`,
                          cancelToken
                      )
                    : {};
                const mappedSuggestions = this.mapKeysInSuggestion(data);

                this.setState({
                    selectedSuggestionIndex: 0,
                    filteredSuggestions: mappedSuggestions || [],
                    shouldShowSuggestions: !!userInput,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    handleSelectSuggestion = (selectedSuggestion) => {
        const newObject = {
            ...selectedSuggestion,
            option: selectedSuggestion.parent
                ? selectedSuggestion.parent.name
                : selectedSuggestion.name,
        };

        this.setState(
            {
                selectedSuggestionIndex: 0,
                filteredSuggestions: [],
                userInput: this.props.isMulti ? '' : newObject,
                shouldShowSuggestions: false,
            },
            () => this.handleSelectMultiple(newObject)
        );
    };

    handleSelectMultiple = (newObject) => {
        this.props.onChange(''); //clears state of input field's character counter managed in parent component

        if (this.props.isMulti) {
            const isItemAddedAlready = this.props.selectedSuggestions.some(
                ({ id }) => id === newObject.id
            );

            if (!isItemAddedAlready) {
                const updatedSelectedSuggestions = [
                    ...this.props.selectedSuggestions,
                    newObject,
                ];
                this.props.onSelect(updatedSelectedSuggestions);
            } else {
                this.props.onError(ITEM_ALREADY_ADDED_ERROR);
            }

            return;
        }

        this.props.onSelect(newObject);
    };

    handleKeyDown = async (event) => {
        const { keyCode } = event;
        const { selectedSuggestionIndex, filteredSuggestions } = this.state;

        const enterKeys = this.props.enterKeys.codes || [ENTER_KEY];
        if (enterKeys.includes(keyCode)) {
            // item selected is add new item
            if (selectedSuggestionIndex >= filteredSuggestions.length) {
                await this.handleAddNewItem();
                return;
            }

            const selectedItem = filteredSuggestions[selectedSuggestionIndex];
            this.handleSelectSuggestion(selectedItem);
            event.preventDefault();
        } else if (keyCode === UP_KEY) {
            if (selectedSuggestionIndex === 0) return;

            this.setState(
                (previousState) => ({
                    selectedSuggestionIndex:
                        previousState.selectedSuggestionIndex - 1,
                }),
                this.handleScroll
            );
        } else if (keyCode === DOWN_KEY) {
            if (selectedSuggestionIndex === filteredSuggestions.length) return;

            this.setState(
                (previousState) => ({
                    selectedSuggestionIndex:
                        previousState.selectedSuggestionIndex + 1,
                }),
                this.handleScroll
            );
        }
    };

    handleScroll = () => {
        const selection = document.querySelector('.suggestion-active');

        if (selection) {
            selection.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    };

    handleMouseOver = (index) => () => {
        this.setState({ selectedSuggestionIndex: index });
    };

    handleAddNewItem = async () => {
        if (this.state.userInput.length > this.props.inputMaxLength) {
            return;
        }

        const payload = this.props.createPayloadForCreation(
            this.state.userInput
        );
        const { createNewItemApi } = this.props;

        if (!createNewItemApi) {
            this.handleSelectSuggestion(this.props.setUserInput(payload));
            return;
        }

        try {
            const { data: result } =
                (await axios.post(createNewItemApi, payload)) || {};
            const { data: newItem } = result || {};

            this.handleSelectSuggestion(this.props.setUserInput(newItem));
        } catch (error) {
            this.props.onError('Something went wrong. Please try again.');
        }
    };

    handleRemoveItem = (item) => {
        const updatedItems = this.props.selectedSuggestions.filter(
            ({ id }) => id !== item.id
        );
        this.props.onSelect([...updatedItems]);
    };

    // whether to show add new option if input is not in suggestions already
    isInputInFilteredSuggestions = (input) => {
        return this.state.filteredSuggestions.every(
            ({ option }) => option !== input
        );
    };

    renderSuggestions = () => {
        const input = this.state.userInput.option || this.state.userInput;

        const showAddNewOption =
            this.props.allowAddNewItem &&
            this.isInputInFilteredSuggestions(input);
        const addNewOptionActiveCss =
            this.state.filteredSuggestions.length ===
            this.state.selectedSuggestionIndex
                ? 'suggestion-active'
                : '';

        return (
            <section className="suggestion-box">
                {this.state.filteredSuggestions.map(
                    this.renderSuggestionOption
                )}

                {showAddNewOption && (
                    <div
                        className={`suggestion-option add-selection ${addNewOptionActiveCss}`}
                        onClick={this.handleAddNewItem}
                        onMouseOver={this.handleMouseOver(
                            this.state.filteredSuggestions.length
                        )}
                    >
                        Add{' '}
                        {this.state.userInput.option || this.state.userInput}
                    </div>
                )}
            </section>
        );
    };

    renderSuggestionOption = (suggestion, index) => {
        const activeCssClass =
            index === this.state.selectedSuggestionIndex
                ? 'suggestion-active'
                : '';

        return (
            <div
                className={`suggestion-option ${activeCssClass}`}
                key={`suggestion-${index}`}
                onClick={() => this.handleSelectSuggestion(suggestion)}
                onMouseOver={this.handleMouseOver(index)}
            >
                {suggestion.option}
            </div>
        );
    };

    renderTags = () => {
        const { withTags, selectedSuggestions } = this.props;

        return (
            withTags &&
            !!selectedSuggestions.length && (
                <div className="tags-container">
                    {this.props.selectedSuggestions.map((item, index) => (
                        <Tag
                            key={`item-${index}`}
                            onRemove={() => this.handleRemoveItem(item)}
                            tagCssClass="item-tags"
                            svgCssClass="item-remove-icon"
                        >
                            {item.option}
                        </Tag>
                    ))}
                </div>
            )
        );
    };

    preventSubmit = (e) => {
        const enterKeys = this.props.enterKeys.codes || [ENTER_KEY];
        if (enterKeys.includes(e.keyCode)) {
            e.preventDefault();
        }
    };

    render() {
        const { inputId } = this.props;
        return (
            <div className="component-Input-Typeahead" ref={this.setWrapperRef}>
                <input
                    name="typeahead"
                    onChange={this.handleChange}
                    placeholder={this.props.placeholderText}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.preventSubmit}
                    value={this.state.userInput.option || this.state.userInput}
                    autoComplete={this.props.autoComplete}
                    data-qa={this.props.qaDataAttr}
                    className={this.props.cssClass}
                    id={inputId}
                />
                {this.state.shouldShowSuggestions && this.renderSuggestions()}

                {this.renderTags()}
            </div>
        );
    }
}
