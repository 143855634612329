import PropTypes from 'prop-types';
import React from 'react';

import './RatingWing.scss';

export default class RatingWing extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        size: PropTypes.string,
        active: PropTypes.bool.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        number: PropTypes.number.isRequired,
    };

    static defaultProps = {
        size: 'large',
    };

    onMouseOver = () => {
        this.props.onMouseOver(this.props.number);
    };

    onClick = () => {
        this.props.onClick(this.props.number);
    };

    render() {
        const activeClass = this.props.active ? 'active' : '';
        return (
            <div
                className={`component-ReviewSurvey-Components-RatingWing ${this.props.size} ${activeClass} ${this.props.number}`}
                data-ratings={this.props.number}
                onMouseOver={this.onMouseOver}
                onClick={this.onClick}
            >
                <div className="size-container">
                    <svg
                        className={'wing-svg'}
                        viewBox="0 0 52 61"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        data-rating={this.props.number}
                    >
                        <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                                className="wing"
                                transform="translate(-5.000000, 3.000000)"
                                fillRule="nonzero"
                            >
                                <path
                                    d="M39.1064998,14.0666562 C26.631112,16.1405377 13.4220734,13.2566469 6.8265856,26.2255853 C0.783684176,38.1058677 6.92658292,51.3528668 6.92658292,51.3528668 C11.9229806,44.6659319 23.3154495,50.0352283 32.4098586,46.4900277 C31.1114831,45.7805187 27.7496372,43.3968922 26.7500975,41.9778743 C30.1484168,43.5993578 36.2081671,44.7671648 40.7058788,42.943069 C38.6069467,42.8418362 33.4159367,41.068131 32.6162472,40.054777 C36.8142559,41.068131 45.4025736,39.7017134 47.1015164,37.7762383 C46.002124,37.7762383 38.9246141,36.8374242 37.5255187,35.7228374 C42.4219191,34.7094835 49.200593,33.2163652 52.0992147,27.5427843 C50.5996884,28.6573711 44.417163,28.8855284 41.9185306,29.0879941 C45.6169863,28.1760195 53.7972905,22.6797987 54.6969773,18.7277621 C52.6987651,19.9437283 50.6999748,20.0449611 48.1010561,20.8557029 C52.7977509,17.9170203 58.3952885,13.7622251 60.4930646,5.35286687 C57.2954625,7.48080765 51.2995252,12.0406807 39.1064998,14.0666562 Z"
                                    id="Fill-1"
                                    transform="translate(32.329799, 28.352867) rotate(-12.000000) translate(-32.329799, -28.352867)"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}
