import PropTypes from 'prop-types';
import React from 'react';
import UserIconWithOverlay from '../../Elements/UserIconWithOverlay/UserIconWithOverlay';
import { userProptype } from '../../Includes/userProps';
import UnreadCount from '../../Messages/ChannelList/Channel/UnreadCount/UnreadCount';

import './UserOverviewTag.scss';
import UserImage from '../../Content/User/UserDisplay/UserImage/UserImage';

export default class UserOverviewTag extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * the user data of a member
         */
        user: userProptype.isRequired,
        /**
         * whether it is display on message channel or not
         */
        isDisplayedOnMessage: PropTypes.bool,
        /**
         * whether show user profile tooltip when hovering on user icon
         */
        showToolTipOnHover: PropTypes.bool,
        /**
         * css class that will override the default style
         */
        cssClass: PropTypes.string,
        /**
         * time stamp display on message channel
         */
        timeLabel: PropTypes.string,
        /**
         * recruter's label display on message channel
         */
        recruiterLabel: PropTypes.string,
        /**
         * the number of ms the tooltip will be displayed after mouse is no longer hovering the component
         */
        closeHoverTimeout: PropTypes.number,
        /**
         * count of unread message in a channel
         */
        unread: PropTypes.number,
    };

    static defaultProps = {
        isDisplayedOnMessage: false,
        showToolTipOnHover: false,
        cssClass: '',
        user: {
            image: {
                src: '/v1/avatar_black_160_vtg7lw.svg',
                alt: 'user profile image',
            },
            username: '',
            credential: null,
        },
        timeLabel: '',
        recruiterLabel: '',
        closeHoverTimeout: 1000,
    };

    render() {
        const {
            isDisplayedOnMessage,
            showToolTipOnHover,
            user,
            cssClass,
            timeLabel,
            recruiterLabel,
            closeHoverTimeout,
            unread,
            featureFlags,
        } = this.props;

        return (
            <div className={`component-Users-UserOverviewTag ${cssClass}`}>
                <div className="profile-img-wrapper">
                    {showToolTipOnHover ? (
                        <UserIconWithOverlay
                            targetUser={user}
                            userIconSize={36}
                            closeHoverTimeout={closeHoverTimeout}
                        />
                    ) : (
                        <UserImage
                            image={user.image}
                            iconSize={36}
                            className={'profile-img'}
                        />
                    )}
                </div>
                <div className="meta-wrapper">
                    <div className="meta-item meta-header">
                        <span className="meta-header-username">
                            {user.username}
                            {user.credential ? ', ' : ''}
                        </span>
                        <span>{user.credential}</span>
                    </div>
                    {isDisplayedOnMessage && (
                        <React.Fragment>
                            <span className="meta-item meta-timestamp">
                                {timeLabel}
                            </span>
                            {recruiterLabel && (
                                <span className="meta-item meta-recruiter-credential">
                                    {recruiterLabel}
                                </span>
                            )}
                            <UnreadCount unread={unread} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
