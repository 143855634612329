/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React from 'react';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import TextInput from '../../../ModalParts/Inputs/TextInput/TextInput';
import SchoolsTypeahead from '../../../../Input/SchoolsTypeahead/SchoolsTypeahead';
import LocationSelector from '../../../ModalParts/Inputs/LocationSelector/LocationSelector';
import LongTextInput from '../../../ModalParts/Inputs/LongTextInput/LongTextInput';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import CheckBox from '../../../../Content/Elements/CheckBox/CheckBox';
import {
    yearDropdownOpts,
    filterYearDropdownOpts,
} from '../../../ModalParts/Inputs/Dropdown/constants';
import { degreeDropdownOpts } from '../QuickProfile/constants';
import { getFieldName, convertDropdownValue } from './constants';
import { constructCn } from '../../../../../utilities/helperFunctions';

const EduVariant = ({ stepName }) => {
    const [extraFieldsVisible, setExtraFieldVisible] = React.useState(false);
    const {
        stepFormProps,
        updateFormValues,
        updateFormValueAtPath,
    } = useGetStepFormProps(stepName);

    const showExtraFields = React.useCallback(
        () => setExtraFieldVisible(true),
        [setExtraFieldVisible]
    );

    const { values, errors } = stepFormProps;

    React.useEffect(() => {
        if (values.isRemote) {
            const locationUpdater = updateFormValueAtPath(
                getFieldName(stepName, 'location')
            );
            locationUpdater({ name: '', lat: null, lng: null });
        }

        if (values.isCurrent) {
            const endYearUpdater = updateFormValueAtPath(
                getFieldName(stepName, 'endYear')
            );
            endYearUpdater('');
        }
    }, [values.isRemote, values.isCurrent, updateFormValueAtPath, stepName]);

    React.useEffect(() => {
        if (errors.description || errors.startYear || errors.endYear) {
            setExtraFieldVisible(true);
        }
    }, [
        errors.description,
        errors.startYear,
        errors.endYear,
        setExtraFieldVisible,
    ]);

    const handleSchoolType = React.useCallback(
        (v) => {
            const schoolUpdater = updateFormValueAtPath(
                getFieldName(stepName, 'school')
            );
            schoolUpdater({ name: v, id: null });
        },
        [stepName, updateFormValueAtPath]
    );

    const handleSchoolSelect = React.useCallback(
        (v) => {
            const schoolUpdater = updateFormValueAtPath(
                getFieldName(stepName, 'school')
            );
            schoolUpdater({ name: v.name, id: v.id });
        },
        [stepName, updateFormValueAtPath]
    );

    const schoolInputCn = constructCn(
        'education-school-typeahead',
        errors.school.name && 'validation-error'
    );

    return (
        <div className="enrichment-variant edu">
            <div className="field-wrapper school-input">
                <SchoolsTypeahead
                    error={errors.school.name}
                    handleSelect={handleSchoolSelect}
                    setTypeaheadInput={handleSchoolType}
                    className={schoolInputCn}
                    items={values.school ? [values.school] : []}
                    allowAddNewItem
                    isRequired
                />
            </div>
            <div className="field-wrapper">
                <Dropdown
                    inputName={getFieldName(stepName, 'degree')}
                    className="education-dropdown degree"
                    options={degreeDropdownOpts}
                    value={values.degree}
                    label="Degree*"
                    error={errors.degree}
                    handleChange={updateFormValues}
                />
            </div>
            <div className="field-wrapper">
                <TextInput
                    inputName={getFieldName(stepName, 'field')}
                    value={values.field}
                    label="Field of study"
                    placeholder="Ex. Business"
                    error={errors.field}
                    handleChange={updateFormValues}
                />
            </div>
            <div className="field-wrapper">
                <LocationSelector
                    inputName={getFieldName(stepName, 'location')}
                    label="School location*"
                    placeholder="City, State"
                    error={errors.location.name}
                    value={values.location.name}
                    handleChange={updateFormValueAtPath}
                    disabled={values.isRemote}
                />
            </div>
            <div className="field-wrapper checkbox">
                <CheckBox
                    id="#isRemote-toggle"
                    defaultChecked={values.isRemote}
                    label="Remote"
                    handleChange={updateFormValueAtPath(
                        getFieldName(stepName, 'isRemote')
                    )}
                    className="remote-select"
                />
            </div>
            {!extraFieldsVisible && (
                <button
                    type="button"
                    onClick={showExtraFields}
                    className="extra-fields-toggle"
                >
                    Show additional fields &gt;
                </button>
            )}
            {extraFieldsVisible && (
                <React.Fragment>
                    <div className="field-wrapper">
                        <div className="year-select-wrapper">
                            <Dropdown
                                inputName={getFieldName(stepName, 'startYear')}
                                handleChange={updateFormValues}
                                className="education-start-year"
                                options={yearDropdownOpts}
                                value={convertDropdownValue(values.startYear)}
                                defaultOptionText="Year"
                                label="Start year"
                                error={errors.startYear}
                            />
                            <span className="year-dropdown-divider" />
                            <Dropdown
                                inputName={getFieldName(stepName, 'endYear')}
                                handleChange={updateFormValues}
                                className="education-end-year"
                                defaultOptionText="Year"
                                options={filterYearDropdownOpts(
                                    values.startYear
                                )}
                                disabled={values.isCurrent}
                                value={convertDropdownValue(values.endYear)}
                                label="End year (or expected)"
                                error={errors.endYear}
                            />
                        </div>
                    </div>
                    <div className="field-wrapper checkbox">
                        <CheckBox
                            id="#isCurrent-toggle"
                            defaultChecked={values.isCurrent}
                            label="I am currently attending this school"
                            handleChange={updateFormValueAtPath(
                                getFieldName(stepName, 'isCurrent')
                            )}
                            className="current-select"
                        />
                    </div>
                    <div className="field-wrapper">
                        <LongTextInput
                            inputName={getFieldName(stepName, 'description')}
                            value={values.description}
                            label="Highlights"
                            type="textarea"
                            error={errors.description}
                            handleChange={updateFormValues}
                            className="education-highlights"
                            rows={6}
                            maxChars={2000}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

EduVariant.displayName = 'EduVariant';

export default React.memo(EduVariant);
