import React from 'react';

import FlowStep from '../../FlowStep';
import Dropdown from '../../../ModalParts/Inputs/Dropdown/Dropdown';
import { useFlowControls } from '../../FlowShell/controls';
import { useGetStepFormProps } from '../../../../../contexts/Includes/modalContextHelpers';
import SVGFromCloudinary from '../../../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import { SELECT_TIME_STEP_NAME } from '../constants';
import { registrationFlow } from '../../EventRegistration/constants';

import './SelectTime.scss';

const { registrationFlowAPI } = registrationFlow;

const clockImageId = 'v1590593984/icons/clock-icon.svg';

const PreferredTimeButton = (props) => {
    // TODO: validate inputs, handle api calls, determine next step, go to step
    const { destinations, flowData } = useFlowControls();
    const { event, setUserAsRegistered } = flowData;

    const { hash } = event;

    const {
        stepFormProps: formResponses,
        updateFormValueAtPath,
    } = useGetStepFormProps(SELECT_TIME_STEP_NAME);

    const errorHandler = updateFormValueAtPath(
        'errors.selectTime.preferredTimeId'
    );

    const handleTimeSelect = React.useCallback(async () => {
        const errorMsg = 'Please select an event time.';
        // validate data
        if (!formResponses.preferredTimeId) return errorHandler(errorMsg);

        const timeSelectSuccess = await registrationFlowAPI.submitPreferredTime(
            formResponses.preferredTimeId,
            hash
        );

        if (!timeSelectSuccess) return;

        setUserAsRegistered();
        return destinations.confirmSuccess();
    }, [
        errorHandler,
        setUserAsRegistered,
        destinations,
        hash,
        formResponses.preferredTimeId,
    ]);

    return (
        <button
            type="button"
            className="select-time-done-button"
            onClick={handleTimeSelect}
        >
            Done
        </button>
    );
};

export const SelectTime = () => {
    const { stepFormProps, stepErrors, updateFormValues } = useGetStepFormProps(
        SELECT_TIME_STEP_NAME
    );
    const { flowData } = useFlowControls();

    const eventPreferredTimes =
        flowData.preferredTimeOptions || flowData.eventPreferredTimes || [];

    const { preferredTimeId } = stepFormProps;

    return (
        <FlowStep name={SELECT_TIME_STEP_NAME}>
            <div className="component-Modals-Flows-Steps-SelectTime">
                <SVGFromCloudinary
                    cloudinaryID={clockImageId}
                    className="clock-icon"
                    alt="clock-icon"
                />
                <Dropdown
                    inputName="selectTime.preferredTimeId"
                    className="select-time-dropdown"
                    options={eventPreferredTimes}
                    value={preferredTimeId}
                    label="Choose an appointment time"
                    error={stepErrors.preferredTimeId}
                    handleChange={updateFormValues}
                />
                <PreferredTimeButton />
            </div>
        </FlowStep>
    );
};

SelectTime.propTypes = {};

SelectTime.displayName = 'SelectTime';

export default React.memo(SelectTime);
