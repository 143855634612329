import PropTypes from 'prop-types';
import React from 'react';
import ResponsivePicture from '../../Elements/ResponsivePicture/ResponsivePicture';
import Button from '../../Input/Button/Button';

import './EventRegistrationIncompleteModal.scss';

export default class EventRegistrationIncompleteModal extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {};

    render() {
        const breakpoints = {
            mobile: {
                width: 260,
                height: 254,
            },
        };
        return (
            <div className="component-Events-EventRegistrationIncompleteModal">
                <ResponsivePicture
                    cloudinaryID={
                        '/v1575491402/Save_progress_modal_image_2x_fsck62.png'
                    }
                    breakpoints={breakpoints}
                    forceImageSize={true}
                />
                <h3 className="event-registration-incomplete-modal-title">
                    Your profile progress has been saved.
                </h3>
                <p className="event-registration-incomplete-modal-description">
                    To complete registration for virtual recruiting events, you
                    must submit a complete profile. You can access and complete
                    your Job Seeker profile any time from your{' '}
                    <a href={'/users/profile'}>user profile.</a>
                </p>
                <div className="done-button">
                    <a href={this.props.onClick()}>
                        <Button type={'solid'} value={'Done'} />
                    </a>
                </div>
            </div>
        );
    }
}
