import PropTypes from 'prop-types';
import React from 'react';

import './ArrowSVG.scss';

export default class ArrowSVG extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        src: PropTypes.string,
        direction: PropTypes.oneOf(['up', 'down', 'right', 'left']).isRequired,
        viewBox: PropTypes.string,
        /**
         * custom styling to override default
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        let transform;
        let width;
        let height;
        let svg;
        let viewBox;

        switch (this.props.direction) {
            case 'up':
                transform =
                    'translate(3.000000, 2.000000) scale(-1, 1) rotate(-180.000000) translate(-3.000000, -2.000000)';
                width = this.props.width || '6px';
                height = this.props.height || '4px';
                viewBox = this.props.viewBox || '0 0 6 4';
                break;
            case 'down':
                transform =
                    'translate(3.000000, 2.000000) scale(-1, 1) translate(-3.000000, -2.000000)';
                width = this.props.width || '6px';
                height = this.props.height || '4px';
                viewBox = this.props.viewBox || '0 0 6 4';
                break;
            case 'right':
                transform =
                    'translate(2.000000, 3.000000) rotate(-90.000000) translate(-2.000000, -3.000000) translate(-1.000000, 1.000000)';
                width = this.props.width || '4px';
                height = this.props.height || '6px';
                viewBox = this.props.viewBox || '0 0 4 6';
                break;
            case 'left':
                transform =
                    'translate(2.000000, 3.000000) scale(-1, 1) rotate(-90.000000) translate(-2.000000, -3.000000) translate(-1.000000, 1.000000)';
                width = this.props.width || '4px';
                height = this.props.height || '6px';
                viewBox = this.props.viewBox || '0 0 4 6';

                break;
            default:
                transform =
                    'translate(3.000000, 2.000000) scale(-1, 1) translate(-3.000000, -2.000000)';
                break;
        }

        return (
            <div className={`component-SVGs-ArrowSVG ${this.props.className}`}>
                <svg
                    width={width}
                    height={height}
                    viewBox={viewBox}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                    >
                        <g
                            className={`expand-arrow expand-arrow-${this.props.direction}`}
                            fillRule="nonzero"
                        >
                            <g transform={transform} id="Path">
                                <polygon points="5.295 0.295 3 2.585 0.705 0.295 0 1 3 4 6 1" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
