import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../../Elements/InputLabel/InputLabel';
import FieldError from '../../Elements/FieldError/FieldError';
import LocationInput from '../../../../Input/LocationInput/LocationInput';
import { constructCn } from '../../../../../utilities/helperFunctions';

import './LocationSelector.scss';

const handleFilterSelectValues = (handler) => (values) => {
    const { name, lat, lng } = values;

    if (!lat || !lng)
        return handler({
            name,
            lat: null,
            lng: null,
        });

    return handler({
        name,
        lat,
        lng,
    });
};

export const LocationSelector = ({
    inputName,
    label,
    placeholder,
    error,
    value,
    handleChange,
    className,
    disabled,
}) => {
    const idName = `#modal-loc-select-${inputName.split('.').slice(-1)[0]}`;

    const selectHandler = handleChange(inputName);

    const filteredHandler = handleFilterSelectValues(selectHandler);

    const inputCn = constructCn(
        'component-Modals-ModalParts-Inputs-LocationSelector',
        error && 'input-error'
    );

    return (
        <div className={inputCn}>
            <InputLabel text={label} type="text" htmlFor={idName} />
            <LocationInput
                idValue={idName}
                placeholder={placeholder}
                classname={className}
                name={inputName}
                value={value}
                onChange={filteredHandler}
                disabled={disabled}
            />
            <FieldError text={error} />
        </div>
    );
};

LocationSelector.propTypes = {
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

LocationSelector.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    error: '',
    className: 'modal-location-selector',
    disabled: false,
};

LocationSelector.displayName = 'LocationSelector';

export default React.memo(LocationSelector);
