import PropTypes from 'prop-types';
import React from 'react';
import Pixel from '../../Analytics/VisibilityPixel/VisibilityPixel';
import CharCount from '../../Elements/CharCount/CharCount';
import TextAreaInput from '../../Input/TextAreaInput/TextAreaInput';
import PaginationNav from '../../ReviewSurvey/Components/PaginationNav/PaginationNav';
import { getInviteConnectionsImages } from '../InvitationWidget';

import './MessageLayout.scss';

export default class MessageLayout extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            message: props.message || '',
            error: props.error || null,
        };
    }

    static propTypes = {
        onNext: PropTypes.func,
        onPrev: PropTypes.func,
        confirmationModalTrigger: PropTypes.func,
        image: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        title: PropTypes.string,
        description: PropTypes.string,
        error: PropTypes.string,
        showPrevBtn: PropTypes.bool,
        setIsLoading: PropTypes.func,
        isFGBInvite: PropTypes.bool,
        isGroupInvite: PropTypes.bool,
        message: PropTypes.string,
    };

    static defaultProps = {
        showPrevBtn: true,
        isFGBInvite: false,
    };

    handleMessageInput = (message) => {
        this.setState(
            {
                message,
                error: null,
            },
            () => this.props.setIsLoading(false)
        );
    };

    onNext = () => {
        const message = this.state.message && this.state.message.trim();
        if (message && message.length <= 300) {
            this.props.onNext(
                'message',
                this.state.message,
                true,
                this.props.confirmationModalTrigger
            );
        } else {
            let errorMessage = 'Please enter in a note before sending!';
            if (message.length && message.length > 300) {
                errorMessage = 'Please keep your note to 300 characters.';
            }
            this.setState({
                error: errorMessage,
            });
        }
    };

    getPlaceholder = () => {
        switch (true) {
            case this.props.isFGBInvite && this.props.isGroupInvite:
                return 'Use this space to explain why they should check out your group! It is an opportunity to share the benefits of your group.';
            case this.props.isFGBInvite:
                return 'Use this space to let others know why you want to connect. Do you want to add them to your FGB network, or are you trying to get their advice?';
            default:
                return 'Use this space to tell others why they should check out Fairygodboss. If your username will be difficult to recognize, this is a good spot to explain who you are!';
        }
    };

    render() {
        const src =
            this.props.image && this.props.image.src
                ? this.props.image.src
                : '/v1/SVGs/invite_mail.svg';
        const buttonSectionProps = {
            showPrevBtn: this.props.showPrevBtn,
            showNextBtn: true,
            PrevText: `Back`,
            NextText: this.props.nextDisabled ? 'Loading' : 'Send >',
            prevDisabled: false,
            nextDisabled: this.props.nextDisabled,
            onNext: this.onNext,
            onPrev: this.props.onPrev,
        };
        return (
            <div className="component-InvitationWidget-MessageLayout">
                <Pixel
                    metrics={{
                        misc_event_type: 'invite-message-screen-viewed',
                        misc_event_count: 1,
                    }}
                />

                <div className="invitation-widget-content-container">
                    <section className="subject-section">
                        <img
                            src={getInviteConnectionsImages(src, 'icon')}
                            alt=""
                        />
                        {this.props.title && (
                            <div className="title-text">{this.props.title}</div>
                        )}
                        {this.props.description && (
                            <div className="title-description">
                                {this.props.description}
                            </div>
                        )}
                    </section>
                    <section className="note-section">
                        <div className="label-and-char-coutn">
                            <div className="label">Your note*</div>
                            <CharCount
                                length={this.state.message.length}
                                limit={300}
                            />
                        </div>
                        <TextAreaInput
                            name={'note'}
                            onChange={this.handleMessageInput}
                            value={this.state.message}
                            placeholder={this.getPlaceholder()}
                        />
                        <div className={'errorMessage'}>
                            {this.props.error || this.state.error}
                        </div>
                    </section>
                </div>
                <PaginationNav {...buttonSectionProps} />
            </div>
        );
    }
}
