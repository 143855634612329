import Yup from 'yup';

export const ValidateForm = async (
    objectToValidate,
    yupSchema,
    stateSetCallBackFunction
) => {
    const schema = Yup.object().shape(yupSchema);
    try {
        await schema.validate(objectToValidate, { abortEarly: false });
        return true;
    } catch (errors) {
        showErrors(errors, stateSetCallBackFunction);
        return false;
    }
};

const showErrors = (error, stateSetCallBackFunction) => {
    if (error.inner) {
        error.inner.map((err) => {
            let errorMsg = err.message || 'This is a required field';
            let path = err.path
                .replace(/]/gi, '')
                .replace(/\[/gi, '.')
                .split('.');
            _addErrorToPath(errorMsg, path, stateSetCallBackFunction);
        });
    }
};

const _addErrorToPath = (errMsg, path, stateSetCallBackFunction) => {
    const myError = {};
    myError[`error${path}`] = errMsg;
    stateSetCallBackFunction(myError);
};
