import React from 'react';

import { ModalContext } from '../../../../contexts/ContextCreator';

import FlowShell from '../FlowShell/FlowShell';
import SingleEducation from '../Steps/SingleEducation/SingleEducation';
import { buildSingleEducationFlow, educationUpdateApi } from './constants';
import { useFlowControls } from '../FlowShell/controls';
import { SINGLE_EDUCATION_STEP_NAME } from '../Steps/constants';

export const singleEducationFlowType = 'single-education-flow';

export const SingleEducationFlow = (props) => {
    const { closeModal, generalModalProps } = React.useContext(ModalContext);

    const { educations, updateHistoryList } = generalModalProps;

    const initializeOptions = {
        educations,
        closeModal,
        updateHistoryList,
    };

    const { flowTree, initialStep, initialFlowData } = buildSingleEducationFlow(
        initializeOptions
    );

    return (
        <FlowShell
            tree={flowTree}
            initialStep={initialStep}
            initialFlowData={initialFlowData}
            closeModal={closeModal}
            flowTitle="education"
        >
            <SingleEducation />
        </FlowShell>
    );
};

SingleEducationFlow.displayName = 'SingleEducationFlow';

export default React.memo(SingleEducationFlow);

export const EducationSubmitButton = React.memo(
    ({ formValues, errorHandler, clearErrors }) => {
        const { flowData } = useFlowControls();
        const {
            validators,
            educations,
            closeModal,
            updateHistoryList,
        } = flowData;
        const { singleEducation: singleEducationValidator } = validators;

        const handleDoneClick = React.useCallback(async () => {
            clearErrors(SINGLE_EDUCATION_STEP_NAME);

            const { valid, errors } = await singleEducationValidator(
                formValues
            );

            if (!valid) {
                return errorHandler(errors);
            }

            // since are handling done click, we are adding an education
            const {
                ok,
                newEducations,
                apiErrors,
            } = await educationUpdateApi.addSingleEducation(
                formValues,
                educations
            );

            if (!ok) {
                console.log('API ERRORS: ', apiErrors); // TODO: remove after testing
                return false; // TODO: set errors?
            }

            // call state setting function with updated education array
            // close modal
            updateHistoryList(newEducations);
            return closeModal();
        }, [
            formValues,
            errorHandler,
            clearErrors,
            singleEducationValidator,
            educations,
            closeModal,
            updateHistoryList,
        ]);

        return (
            <button
                type="button"
                className="education-submit-button"
                onClick={handleDoneClick}
            >
                Done
            </button>
        );
    }
);

export const EducationDeleteButton = React.memo(
    ({ educationId, errorHandler }) => {
        const { flowData } = useFlowControls();
        const { educations, closeModal, updateHistoryList } = flowData;

        const handleDeleteClick = React.useCallback(async () => {
            if (!educationId) return closeModal();

            // since are handling delete click, we are removing an education
            const {
                ok,
                newEducations,
                apiErrors,
            } = await educationUpdateApi.deleteSingleEducation(
                educationId,
                educations
            );

            if (!ok) {
                console.log('API ERRORS: ', apiErrors); // TODO: remove after testing
                return; // TODO: set errors?
            }

            // call state setting function with updated education array
            // close modal
            updateHistoryList(newEducations);
            return closeModal();
        }, [educationId, educations, closeModal, updateHistoryList]);

        return (
            <button
                type="button"
                className="education-delete-button"
                onClick={handleDeleteClick}
            >
                Delete
            </button>
        );
    }
);
