import React from 'react';

import { getCloudinaryImagePathById } from '../../../utilities/images';
import { truncateWithEllipsis } from '../../../utilities/ui_utilities';
import VisibilityPixel, {
    EmitMetric,
} from '../../Analytics/VisibilityPixel/VisibilityPixel';
import WhiteCompanyIcon from '../../Icons/CompanyIcon/WhiteCompanyIcon';
import Button from '../../Input/Button/Button';
import { fetchCompanyAds } from '../companyAdsFunctions';

import './BottomBanner.scss';

export default class BottomBanner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            jobLocation: null,
            userLocation: null,
            companyId: null,
            companyName: null,
            companyLogo: null,
            stickyText: null,
            clickThroughUrl: null,
            openBanner: false,
            headline: null,
            testFlag: null,
        };
    }

    async componentDidMount() {
        try {
            const result = await fetchCompanyAds();
            this.setState({ ...result, openBanner: true }, () => {
                EmitMetric({
                    misc_event_type: 'job-banner-ad',
                    impression: 1,
                    test_flag: this.state.testFlag,
                    company_id: [this.state.companyId],
                });
            });
        } catch (err) {
            this.setState({ openBanner: false });
        }
    }

    handleClick = () => {
        VisibilityPixel.setLastActionParams(
            'job-bottom-banner',
            this.state.companyName
        );
        EmitMetric({
            misc_event_type: 'job-banner-ad',
            click: 1,
            test_flag: this.state.testFlag,
            company_id: [this.state.companyId],
        });
        return true;
    };

    render() {
        const companyLogo = this.state.companyLogo ? (
            <img
                src={getCloudinaryImagePathById(
                    this.state.companyLogo,
                    'xSmall'
                )}
            />
        ) : null;

        return (
            <React.Fragment>
                {this.state.openBanner ? (
                    <div className="component-CompanyAds-BottomBanner sticky external-styles">
                        <a
                            className="banner-wrap row m-0"
                            href={this.state.clickThroughUrl}
                            onClick={this.handleClick}
                        >
                            <div className="col-1 p-0 bg-navy" />
                            <div className="left-banner bg-navy col-3 p-0 d-flex align-items-center justify-content-end">
                                <div className="banner-icon d-flex align-items-center">
                                    <WhiteCompanyIcon />
                                </div>
                                <div className="banner-desc-top d-flex flex-column align-items-center pl-4 pr-4">
                                    EXPLORE MORE
                                    <span className="banner-desc-bottom">
                                        JOBS
                                    </span>
                                </div>
                            </div>
                            <div className="right-banner col-8 p-0 d-flex align-items-center bg-white">
                                <div className="job-icon d-flex justify-content-center">
                                    {companyLogo}
                                </div>
                                <div className="job-info">
                                    <div className="job-loc">
                                        <div>
                                            {truncateWithEllipsis(
                                                this.state.headline,
                                                35
                                            )}
                                        </div>
                                    </div>
                                    <div className="job-desc pt-1">
                                        "
                                        {truncateWithEllipsis(
                                            this.state.stickyText,
                                            80
                                        )}
                                        "
                                    </div>
                                </div>
                                <div className="job-button pl-4 pr-4">
                                    <Button
                                        type="purple-medium-white"
                                        value="See the jobs"
                                    />
                                </div>
                            </div>
                        </a>
                        <a
                            className="banner-wrap-mobile m-0 row"
                            href={this.state.clickThroughUrl}
                            onClick={this.handleClick}
                        >
                            <div className="top-banner bg-navy row m-0 d-flex align-items-center">
                                <div className="banner-desc-top col-4 p-0 pl-4 d-flex align-items-center">
                                    EXPLORE MORE
                                    <span className="banner-desc-bottom pl-3">
                                        JOBS
                                    </span>
                                </div>
                                <div className="job-loc col-6 d-md-block d-none">
                                    <div>
                                        {truncateWithEllipsis(
                                            this.state.headline,
                                            35
                                        )}
                                    </div>
                                </div>
                                <div className="job-loc col-6 d-md-none d-block">
                                    <div>
                                        {truncateWithEllipsis(
                                            this.state.headline,
                                            21
                                        )}
                                    </div>
                                </div>
                                <div className="job-button col-2 bg-purple">
                                    <Button
                                        type="purple-medium-white"
                                        value="See the jobs"
                                    />
                                </div>
                            </div>
                            <div className="bottom-banner row m-0 bg-white">
                                <div className="job-desc d-md-block d-none">
                                    "
                                    {truncateWithEllipsis(
                                        this.state.stickyText,
                                        63
                                    )}
                                    "
                                </div>
                                <div className="job-desc d-md-none d-block">
                                    "
                                    {truncateWithEllipsis(
                                        this.state.stickyText,
                                        44
                                    )}
                                    "
                                </div>
                            </div>
                        </a>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}
