import PropTypes from 'prop-types';
import React from 'react';
import NoSSR from 'react-no-ssr';
import * as Cookies from 'js-cookie';
import { MessageProvider } from '../../contexts/MessageContext';
import { EmitPage } from '../Analytics/VisibilityPixel/VisibilityPixel';
import BottomBanner from '../CompanyAds/BottomBanner/BottomBanner';
import MessageDrawer from '../Messages/MessageDrawer';
import DesktopFooter from './Footer/DesktopFooter/DesktopFooter';
import Header from './Header/Header';
import './Layout.scss';
import TopBannerController from './TopBannerController/TopBannerController';
import { FOOTER_TYPES, socialIcons } from './Footer/constants';
import { PageContext } from '../../contexts/ContextCreator';
import { serializeHeaderProps, serializeFooterProps } from './utils';
import CookieBanner from './CookieBanner/CookieBanner';
import { CookiesBanner } from './CookieBanner/CookieBannerV2';
import { PAGE_PROPS as DEFAULT_PAGE_PROPS } from '../../pages/defaultProps';

export default class Layout extends React.Component {
    /**
     * You should define any and all default props here
     * @type {{children: XML}}
     */
    constructor(props) {
        super(props);
        this.state = {
            hasCookie: false,
        };
        this.baseClassName = 'component-Layout';
    }

    componentDidMount() {
        EmitPage();
        this.setCookies();
    }

    setCookies = () => {
        const cookieName = 'FGBAllowCookies';
        const hasCookie = Cookies.get(cookieName);
        this.setState({ hasCookie });
    };

    setCookieYes = () => {
        Cookies.set('FGBAllowCookies', '1', { expires: 1095 });
        if (!this.context?.leadInLogin) {
            window.location.reload();
        } else {
            this.setCookies();
        }
    };

    renderMessageDrawer = (context) => {
        const { isLoggedIn, pageProps } = context;
        const { url } = pageProps || {};
        const path = url ? url.path : '';
        const onGroupChatPage = path.split('/').indexOf('group-chat') !== -1;
        const onUserProfilePage = path.split('/').indexOf('profile') !== -1;

        if (isLoggedIn()) {
            if (onGroupChatPage) {
                return (
                    <MessageDrawer
                        isLoggedIn={context.isLoggedIn}
                        drawerType="nav-bar"
                        overlayType="dark"
                        overlayCssClass=""
                    />
                );
            }

            /**
             * new layout on User Profile page
             * so has to center message drawer on
             * screens 992px+
             */
            if (onUserProfilePage) {
                return (
                    <MessageDrawer
                        isLoggedIn={context.isLoggedIn}
                        cssClass="center-drawer"
                    />
                );
            }

            if (path !== '/messages') {
                return <MessageDrawer isLoggedIn={context.isLoggedIn} />;
            }
        }
    };

    /**
     * Renders the bottom content section
     * @returns {*}
     */

    /**
     * The render function's top tag must have a properly formated class name
     * The className format being component-{PATH-TO-Component}-{ComponentName}
     * Eg. This Layout component is in component is in ~/components/Layout/*files* so its className is just "Component-Layout"
     * This className must be the top level wrapper in your SCSS file (see Layout.scss)
     * @returns {XML}
     */
    render() {
        const {
            PAGE_PROPS,
            hasChangingContainer,
            fullScreenStyling,
            displayDesktopFooter,
            children,
            className,
            hasBottomBanner,
            topBannerExclusionList,
            topBannerOnlyShownList,
            messageProps,
            useLowDistractionModal,
            useNewCookiesBanner,
            removeCookieBanner,
        } = this.props;
        const { hasCookie } = this.state;
        const fullScreenLayout = fullScreenStyling ? 'fullScreen' : '';
        const changingContainerStyle = hasChangingContainer
            ? ' changingContainer container '
            : '';
        const stickyFooterCssClass = displayDesktopFooter
            ? 'layout-sticky-footer'
            : '';
        const serializedHeaderProps = serializeHeaderProps({
            ...this.context,
            footerType: FOOTER_TYPES.DRAWER_FOOTER,
            socialIcons,
        });
        const serializedFooterProps = serializeFooterProps({
            ...this.context.pageProps,
            footerType: FOOTER_TYPES.DESKTOP_FOOTER,
            socialIcons,
        });

        return (
            <div
                className={`${this.baseClassName} ${className} ${fullScreenLayout} ${stickyFooterCssClass}`}
            >
                <MessageProvider
                    PAGE_PROPS={PAGE_PROPS}
                    messageTypes={['community-user-message']}
                    getUserStatus={this.context.getUserStatus}
                    autoInitChat={false}
                    setNextChannelAsCurrent={false}
                    {...messageProps}
                >
                    <Header
                        {...serializedHeaderProps}
                        useLowDistractionModal={useLowDistractionModal}
                    />

                    <TopBannerController
                        topBannerExclusionList={topBannerExclusionList}
                        topBannerOnlyShownList={topBannerOnlyShownList}
                        adminOnboardingProps={this.props.adminOnboardingProps}
                        pageDisplayMode={this.context.pageDisplayMode}
                    />

                    {/** DO NOT REMOVE, hack for error placement of dom element on job activity page with job profile */}
                    <span />

                    <div
                        className={`${this.baseClassName}__content-wrapper ${changingContainerStyle}`}
                    >
                        {children}
                    </div>

                    {this.renderMessageDrawer(this.context)}

                    {displayDesktopFooter && (
                        <DesktopFooter {...serializedFooterProps} />
                    )}
                    {!hasCookie &&
                    !removeCookieBanner &&
                    !useNewCookiesBanner ? (
                        <CookieBanner setYes={this.setCookieYes} />
                    ) : null}
                    {!hasCookie &&
                    !removeCookieBanner &&
                    useNewCookiesBanner ? (
                        <CookiesBanner
                            acceptCookies={this.setCookieYes}
                            closeBanner={this.setCookieYes}
                        />
                    ) : null}
                    {hasBottomBanner && <div style={{ height: '90px' }} />}

                    {hasBottomBanner && <BottomBanner />}

                    <NoSSR>
                        <div
                            style={{ display: 'none' }}
                            data-qa-attr="jsIsReady"
                        >
                            JS Ready!
                        </div>
                    </NoSSR>
                </MessageProvider>
            </div>
        );
    }
}

Layout.defaultProps = {
    PAGE_PROPS: { url: { path: '/' } },
    hasBottomBanner: false,
    hasChangingContainer: true,
    fullScreenStyling: false,
    displayDesktopFooter: true,
    className: '',
    messageProps: {},
    topBannerExclusionList: [],
    topBannerOnlyShownList: [],
    adminOnboardingProps: {},
    useLowDistractionModal: false,
    useNewCookiesBanner: false,
    removeCookieBanner: false,
};

Layout.propTypes = {
    /**
     * global page props
     */
    PAGE_PROPS: DEFAULT_PAGE_PROPS,
    /**
     * additional styling
     */
    className: PropTypes.string,
    fullScreenStyling: PropTypes.bool,
    /**
     * The Child components to be the content of the layout
     */
    children: PropTypes.node.isRequired,
    /**
     * Bottom Banner Information
     */
    hasBottomBanner: PropTypes.bool,
    /**
     * whether it's has changing container
     */
    hasChangingContainer: PropTypes.bool,
    /**
     * whether displaying or hidding desktop footer
     */
    displayDesktopFooter: PropTypes.bool,
    /**
     * props passed in from CommunityMessage.js
     */
    messageProps: PropTypes.shape({
        selectedMessageType: PropTypes.string,
        initialChannel: PropTypes.shape({
            channelKey: PropTypes.string,
            useFirstChannel: PropTypes.bool,
        }),
        communityManagerChannelKey: PropTypes.string,
        messageTypes: PropTypes.arrayOf(PropTypes.string),
        setNextChannelAsCurrent: PropTypes.bool,
    }),
    /**
     * list of existing top banner that should be excluded
     */
    topBannerExclusionList: PropTypes.arrayOf(
        PropTypes.oneOf(['community-onboard'])
    ),
    /**
     * list of existing top banner that should be only shown in
     */
    topBannerOnlyShownList: PropTypes.arrayOf(
        PropTypes.oneOf([
            'profile-cta',
            'admin-onboard',
            'job-seeker-profile',
            'ann-taylor-community',
        ])
    ),
    /**
     * props required by admin onboarding banner
     */
    adminOnboardingProps: PropTypes.shape({
        showBanner: PropTypes.bool,
    }),
    useLowDistractionModal: PropTypes.bool,
    useNewCookiesBanner: PropTypes.bool,
    removeCookieBanner: PropTypes.bool,
};

Layout.contextType = PageContext;
