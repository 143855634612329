import PropTypes from 'prop-types';
import React from 'react';

export default class CloudinaryImageUpload extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cloudinary = null;
        this.widget = null;

        this.state = {
            /**
             * Is the cloudinary widget Opened
             */
            opened: false,
            uploadedImage: null,
        };
    }

    static propTypes = {
        /**
         * function called on successful upload
         */
        successCallback: PropTypes.func.isRequired,
        /**
         * function to handle upload failure errors
         */
        onUploadFailure: PropTypes.func,
        className: PropTypes.string,
        /**
         * Array of upload sources to allow e.g. instagram, dropbox, etc
         */
        uploadSources: PropTypes.arrayOf(PropTypes.string),
        /**
         * Array of whitelisted file types e.g. ['svg', 'jpeg', 'png']
         */
        clientAllowedFormats: PropTypes.arrayOf(PropTypes.string),
        showSkipCropButton: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        uploadSources: [
            'local',
            'url',
            'camera',
            'dropbox',
            'facebook',
            'instagram',
        ],
        clientAllowedFormats: ['jpeg', 'png', 'heif', 'webp'],
        className: '',
        uploadPreset: 'fnn7bukf',
        cloudinaryWidgetOptions: {},
        forceAspectRatio: null,
        imageMinWidth: 50,
        imageMinHeight: 50,
        imageMaxWidth: null,
        imageMaxHeight: null,
        resourceType: 'image',
        showSkipCropButton: false,
        disabled: false,
    };

    componentDidMount() {
        this.loadCloudinaryWidget();
    }

    /**
     * Quick script that uploads the cloudinary script and then preps the widget
     */
    loadCloudinaryWidget = () => {
        let wd = window.document;
        let loader = {};
        if (!wd._LOADERS) {
            wd._LOADERS = {};
        }
        if (!wd.cloudinary && wd._LOADERS['cloudinary'] === undefined) {
            loader['cloudinary'] = false;
            const script = wd.createElement('script');
            script.src = '//widget.cloudinary.com/v2.0/global/all.js';
            script.async = true;
            script.onload = (evt) => {
                loader['cloudinary'] = true;
                this.cloudinary = window.cloudinary;
                this.cloudinary.setCloudName('fairygodboss');
                this.setupWidget();
            };

            wd.body.appendChild(script);
        }
    };

    /**
     * Set up the widget with options
     */
    setupWidget = () => {
        let settings = {
            cloudName: 'fairygodboss',
            uploadPreset: this.props.uploadPreset,
            clientAllowedFormats: this.props.clientAllowedFormats,
            sources: this.props.uploadSources,
            multiple: false,
            cropping: true,
            show_powered_by: false,
            resource_type: this.props.resourceType,
            min_image_width: this.props.imageMinWidth,
            min_image_height: this.props.imageMinHeight,
            max_image_width: this.props.imageMaxWidth,
            max_image_height: this.props.imageMaxHeight,
            cropping_aspect_ratio: this.props.forceAspectRatio,
            showSkipCropButton: this.props.showSkipCropButton,
            cropping_show_back_button: true,
            cropping_validate_dimensions: true,
            styles: {
                palette: {
                    window: '#F3F5F8',
                    sourceBg: '#FFFFFF',
                    windowBorder: '#BDBDD5',
                    tabIcon: '#2A4473',
                    inactiveTabIcon: '#8E9DB9',
                    menuIcons: '#566D96',
                    link: '#982EA9',
                    action: '#982EA9',
                    inProgress: '#8E9DB9',
                    complete: '#8E9DB9',
                    error: '#c43737',
                    textDark: '#353535',
                    textLight: '#FFFFFF',
                },
                fonts: {
                    default: null,
                    "'Fira Sans', sans-serif": {
                        url:
                            'https://fonts.googleapis.com/css?family=Fira+Sans',
                        active: true,
                    },
                },
            },
            text: {
                en: {
                    queue: {
                        retry_failed: 'Upload failed',
                    },
                },
            },
        };

        settings = Object.assign(settings, this.props.cloudinaryWidgetOptions);

        this.widget = this.cloudinary.createUploadWidget(
            {
                ...settings,
            },
            (error, result) => {
                if (this.state.opened) {
                    if (error) {
                        // if onUploadFailure callback provided, call it and close widget
                        // bypasses default cloudinary in-widget error handling
                        if (this.props.onUploadFailure) {
                            this.props.onUploadFailure(error, result);
                            this.closeWidget();
                        }
                    } else if (result.event === 'success') {
                        this.widgetComplete(result);
                    } else if (result.event === 'close') {
                        this.closeWidget();
                    }
                }
            }
        );
    };

    /**
     * Open Da cloudinary widget
     */
    openWidget = () => {
        if (this.widget && !this.props.disabled) {
            this.widget.open();
            this.setState({ opened: true });
        }
    };

    /**
     * Fires when cloudinary completes an upload
     * @param data - the cloudinary data
     */
    widgetComplete = (data) => {
        let src = '/' + data.info.public_id;

        this.setState({
            opened: false,
            uploadedImage: src,
        });

        this.props.successCallback(src);
        this.closeWidget();
    };

    /**
     * Fires when the widget closes
     */
    closeWidget = () => {
        this.setState({ opened: false });
        this.widget.close();
    };

    render() {
        return (
            <div
                className={`component-CloudinaryImageUpload ${this.props.className}`}
            >
                <div className="d-inline-block" onClick={this.openWidget}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
