/* eslint no-console: 0 */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { EmitMetric } from '../components/Analytics/VisibilityPixel/VisibilityPixel';
import AbstractContextProvider, {
    LOAD_TYPE_INFINITE_SCROLL,
    LOAD_TYPE_PAGINATION,
} from './AbstractContext';
import { JobContext as Context } from './ContextCreator';
import { JOB_SEARCH_FILTER_TYPES } from '../components/Includes/Constants/jobSearch';
import { buildQueryString } from '../components/Utilities/UrlFunctions';
import wrapJobContextWithPageContext from './WrapJobContextWithPageContext'; // eslint-disable-line import/no-cycle

const API_FOLLOW_JOB = '/api/user/alerts/job/follow';
const API_SYNC_LOCAL_SEARCHES = '/api/community/jobbing/sync-local-searches';
const API_GET_SAVED_JOBS = '/api/community/jobbing/saved-jobs';
const API_GET_SAVED_JOBS_TOTAL_COUNT =
    '/api/community/jobbing/saved-jobs-total';
const API_FOLLOW_COMPANY = '/api/user/alerts/company/follow';
const API_UNFOLLOW_JOB = '/api/user/alerts/job/unfollow';
const API_SEARCH_JOBS = '/api/community/jobbing/search';
const API_JOB_ONBOARDING_COMPLETION =
    '/api/user-profile/job-onboarding-complete';
const API_GET_SUGGESTED_JOBS = '/api/community/jobbing/suggested-jobs';

export class PureJobContextProvider extends AbstractContextProvider {
    constructor(props) {
        super(props);

        this.state = Object.assign(this.state, {
            pageContext: null,
            /**
             * number of jobs user saved
             */
            savedJobsTotalCount: 0,
            /**
             * array of jobs user saved (check job contract in the docs)
             */
            savedJobs: this.props.savedJobs,
            /**
             * array of jobs user followed (aka added job alerts)
             */
            followedJobAlerts:
                this.props.jobNotifications ||
                (this.props.jobOnboarding &&
                    this.props.jobOnboarding.jobAlerts) ||
                [],
            /**
             * function to follow current search as job alert
             */
            followSearchJobAlert: this.followSearchJobAlert,
            /**
             * function to follow any job alert
             */
            followJobAlert: this.followJobAlert,
            /**
             * function for user to unfollow a job alert
             */
            unfollowJobAlert: this.unfollowJobAlert,
            /**
             * function for user to follow a company
             */
            followCompany: this.followCompany,
            followedCompanies: [],
            /**
             * array of user progress status
             */
            userStatusOfCompleteness: this.props.userStatusOfCompleteness,
            /**
             * function to mark user's job onboarding status as complete
             */
            markOnboardingComplete: this.markOnboardingComplete,
            /**
             * function to get total number of jobs user saved
             */
            getSavedJobsTotalCount: this.getSavedJobsTotalCount,
            /**
             * function to get array of user saved jobs
             */
            getSavedJobs: this.getSavedJobs,
            /**
             * function to save visitor's job search to local storage
             */
            saveLocalJobSearch: this.saveLocalJobSearch,
            /**
             * function to sync visitor's local job search history to druid after she signup or login
             */
            syncLocalSearchesToDruid: this.syncLocalSearchesToDruid,
            /**
             * function to get user seesion
             */
            session: this.props.session,
            loggedIn: !!this.props.session.username,
            /**
             * object of filters returned from search (FACETS)
             */
            filters: this.props.filters,
            /**
             * active filters - object of filters the user has selected
             */
            activeFilters: this.props.activeFilters,
            /**
             * function to search jobs
             */
            filterJobs: this.filterJobs, // will be deprecated
            /**
             * function to search jobs (UPDATED)
             */
            searchJobs: this.searchJobs,
            /**
             * total count of jobs resulting from the current search
             */
            jobsTotal: this.props.jobsTotal,
            /**
             * pagination object returned from the current search
             */
            pagination: this.props.pagination,
            /**
             * prepares url and redirects to jobs search page
             */
            searchForJobs: this.searchForJobs,
            /**
             * does user follow a given job search (keyword, location)
             */
            isJobSearchFollowed: this.isJobSearchFollowed,
            /**
             * meta data for job onboarding including user profile, job alerts and group relationship
             */
            jobOnboarding: this.props.jobOnboarding,
            /**
             * meta data for featured company's ads
             */
            featuredCompany: this.props.featuredCompany,
            /**
             * whether user job seeker profile is completed or not
             */
            isJobProfileIncomplete: this.props.userJobProfile
                ? !this.props.userJobProfile.isCompleted
                : true,
            /**
             * update isJobProfileIncomplete
             */
            setIsJobProfileIncomplete: this.setIsJobProfileIncomplete,
            getSuggestedJobs: this.getSuggestedJobs,
            /**
             * array of zip recruiter jobs
             */
            zipRecruiterJobs: [],
            /**
             * page num of zip recruiter results user is on
             */
            zipRecruiterPageNum: 0,
            /**
             * total number zip recruiter jobs for related search
             */
            zipRecruiterJobTotal: null,
            /**
             * function to do a search on zip recruiter jobs
             */
            searchForZipRecruiterJobs: this.searchForZipRecruiterJobs,
            /**
             * loading state for zip recruiter
             */
            isZipRecruiterLoading: false,
            /*
             * whether job search should be around user's ip
             */
            enableAroundRadius: this.props.enableAroundRadius,
            /**
             * pageNumber for pagination
             */
            page: this.props.page || 1,
            /**
             * number limit for search
             */
            limit: this.props.limit || 20,
            /**
             * Whether loading new jobs will be handled via pagination or infinte scroll
             */
            renderType: this.props.renderType,
            /**
             * Whether new jobs are loading
             */
            isLoading: false,
            /**
             * Function to toggle a job like for a given hash
             */
            toggleJobLike: this.toggleJobLike,
            /**
             * A flag to indicate if the job is shown from Company Job page
             */
            fromCompanyJobsPage: this.props.fromCompanyJobsPage,
            /**
             * Storing the visited pages in the current search
             */
            visitedPages: [],
        });

        /**
         * key for visitor's job searches in local storage
         * @type {string}
         */
        this.key = 'fgb-user-local-job-searches';
    }

    toggleJobLike = async (hash) => this.vote(hash, 'like');

    /**
     * marks the user's job onboarding status as complete
     */
    markOnboardingComplete = async () => {
        try {
            if (
                this.state.jobOnboarding &&
                !this.state.jobOnboarding.completed
            ) {
                await axios.post(API_JOB_ONBOARDING_COMPLETION);
                this.setState({
                    jobOnboarding: {
                        ...this.state.jobOnboarding,
                        completed: true,
                    },
                });
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * api to follow a new company
     * @param companyId
     * @returns {Promise<void>}
     */
    followCompany = async (companyId, callback = () => {}) => {
        try {
            const res = await axios.post(API_FOLLOW_COMPANY, {
                companyId,
            });
            this.setState(
                {
                    followedCompanies: [
                        ...this.state.followedCompanies,
                        res.data.data,
                    ],
                },
                callback
            );
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * get signed in user's saved jobs total count
     * @returns {Promise<void>}
     */
    getSavedJobsTotalCount = async () => {
        try {
            const res = await axios.get(API_GET_SAVED_JOBS_TOTAL_COUNT);
            this.setState({ savedJobsTotalCount: res.data.total });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * get signed in user's saved jobs
     * @param page {int}
     * @param limit{int}
     * @returns {Promise<void>}
     */
    getSavedJobs = async (page = 0, limit = 20) => {
        try {
            const res = await axios.get(
                `${API_GET_SAVED_JOBS}?page=${page}&limit=${limit}`
            );
            this.setState({ savedJobs: res.data });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * function to follow any job alert
     * @param jobAlert
     * @returns jobAlertId
     */
    followJobAlert = async (jobAlert) => {
        if (jobAlert.jobSearchTerm && jobAlert.jobLocation) {
            try {
                const resp = (await axios.post(API_FOLLOW_JOB, jobAlert)).data
                    .data;

                this.setState({
                    followedJobAlerts: [
                        ...this.state.followedJobAlerts,
                        jobAlert,
                    ],
                });

                return {
                    jobNotificationId: resp.id,
                };
            } catch (e) {
                if (e.response.status === 400) {
                    const errors =
                        e.response && e.response.data && e.response.data.errors;
                    const errorType = Object.keys(errors)[0];
                    const errorMessage = errors[errorType][0];

                    return {
                        error: errorMessage,
                    };
                }
                return {
                    error: 'Something went wrong',
                };
            }
        }
    };

    /**
     * function to unfollow any job alert
     * @param notificationId
     * @returns {Promise<void>}
     */
    unfollowJobAlert = async (notificationId) => {
        if (notificationId) {
            try {
                await axios.post(API_UNFOLLOW_JOB, { notificationId });
                const updatedFollowedJobAlerts = this.state.followedJobAlerts.filter(
                    (jobAlert) => jobAlert.jobNotificationId !== notificationId
                );
                this.setState({ followedJobAlerts: updatedFollowedJobAlerts });
            } catch (e) {
                if (e.response.status === 400) {
                    const errors =
                        e.response && e.response.data && e.response.data.errors;
                    const errorType = Object.keys(errors)[0];
                    const errorMessage = errors[errorType][0];

                    return {
                        error: errorMessage,
                    };
                }
                return {
                    error: 'Something went wrong',
                };
            }
        }
    };

    isJobSearchFollowed = () => {
        if (
            this.props.jobSearchBar &&
            this.props.jobSearchBar.keywords &&
            this.props.jobSearchBar.location
        ) {
            // TODO: refactor this. mostly likely want to use something like array.every
            // TODO: instead of a "for" loop here.
            /* eslint-disable */
            for (const alert of this.state.followedJobAlerts) {
                if (
                    alert.jobSearchTerm.toLowerCase() ===
                        this.props.jobSearchBar.keywords.toLowerCase() &&
                    alert.jobLocation.toLowerCase() ===
                        this.props.jobSearchBar.location.toLowerCase()
                ) {
                    return true;
                }
            }
            /* eslint-enable */
        }

        return false;
    };

    /**
     * function to follow current job search as job alert
     * @returns {Promise<void>}
     */
    followSearchJobAlert = () => {
        if (this.isJobSearchFollowed()) {
            return;
        }

        const search = this.props.jobSearchBar;
        const jobAlert = {
            jobSearchTerm: search.keywords,
            jobLocation: search.location,
            jobLng: search.lng,
            jobLat: search.lat,
        };
        return this.followJobAlert(jobAlert);
    };

    // if numberOfJobs < 0, return all the saved job searches, otherwise, return {numberOfJobs} of most recent job searches
    fetchLocalJobSearches = (numberOfJobs = 4) => {
        if (!window) {
            return [];
        }

        const searches = window.localStorage.getItem(this.key);
        if (numberOfJobs >= 0) {
            return searches ? JSON.parse(searches).slice(0, numberOfJobs) : [];
        }
        return searches ? JSON.parse(searches) : [];
    };

    /**
     * save un-signed in user's job search history in local storage, and refreshes the recentJobSearch list
     * only save the none duplicate ones
     * @param jobSearchTerm
     * @param jobLocation
     * @param jobLat
     * @param jobLng
     */
    saveLocalJobSearch = (
        jobSearchTerm,
        jobLocation,
        jobLat = null,
        jobLng = null,
        link
    ) => {
        if (!window || !jobSearchTerm || !jobLocation) {
            return;
        }

        const myStorage = window.localStorage;
        const savedJobSearches = this.fetchLocalJobSearches(-1);
        const newJobSearch = {
            jobSearchTerm,
            jobLocation,
            jobLat,
            jobLng,
            link,
        };
        const existed = savedJobSearches.some(
            (search) =>
                search.jobSearchTerm &&
                search.jobSearchTerm.toLowerCase() ===
                    jobSearchTerm.toLowerCase() &&
                search.jobLocation &&
                search.jobLocation.toLowerCase() === jobLocation.toLowerCase()
        );

        if (!existed) {
            savedJobSearches.unshift(newJobSearch);
            myStorage.setItem(this.key, JSON.stringify(savedJobSearches));
        }
    };

    /**
     * Updates filters selection, searches jobs, and updates jobs content
     * @param String type
     * @param Object filter
     */
    filterJobs = async (type = null, filter = null) => {
        let newActiveFilters = {
            [JOB_SEARCH_FILTER_TYPES.COMPANY]: [],
            [JOB_SEARCH_FILTER_TYPES.LOCATION]: [],
            [JOB_SEARCH_FILTER_TYPES.REMOTE]: [],
        };
        const refreshCompanyFilters = false;

        this.setState(
            (prevState) => {
                if (Object.values(JOB_SEARCH_FILTER_TYPES).includes(type)) {
                    if (prevState.activeFilters[type].includes(filter.value)) {
                        newActiveFilters = {
                            ...prevState.activeFilters,
                            [type]: prevState.activeFilters[type].filter(
                                (activeFilter) => activeFilter !== filter.value
                            ),
                        };
                    } else {
                        newActiveFilters = {
                            ...prevState.activeFilters,
                            [type]: [
                                ...prevState.activeFilters[type],
                                filter.value,
                            ],
                        };
                    }
                }

                return { activeFilters: newActiveFilters, page: 1 };
            },
            async () => {
                const { baseUrl } = this.props;
                const API_URL = this.buildSearchUrl(API_SEARCH_JOBS);
                const browserUrl = this.buildSearchUrl(baseUrl);
                try {
                    const res = await axios.get(API_URL);
                    const { jobs, total, pagination } = res.data;

                    const updatedFilters = res.data.facets;
                    const newRefs = {};
                    this.createHashRef(jobs, newRefs);

                    this.setState(
                        {
                            content: jobs,
                            refs: newRefs,
                            jobsTotal: total,
                            filters: refreshCompanyFilters
                                ? updatedFilters
                                : this.state.filters,
                            pagination,
                        },
                        this.attribution
                    );
                    this.searchForZipRecruiterJobs(true);
                    window.history.replaceState({}, '', browserUrl);
                } catch (e) {
                    console.error(e);
                    throw e;
                }
            }
        );
    };

    searchJobs = async (searchPageNumber = 1) => {
        const url = '/api/community/job/search';
        const { keywords, location, lng, lat } = this.props.jobSearchBar;
        const { limit, enableAroundRadius, isLoading } = this.state;
        this.setIsLoading(true);
        const filters = {};
        if (
            this.state.activeFilters &&
            this.state.activeFilters.company_id &&
            this.state.activeFilters.company_id.length > 0
        ) {
            filters.filters_cmp = this.state.activeFilters.company_id;
        }
        if (
            this.state.activeFilters &&
            this.state.activeFilters.locations &&
            this.state.activeFilters.locations.length > 0
        ) {
            filters.filters_loc = this.state.activeFilters.locations;
        }
        if (
            this.state.activeFilters &&
            this.state.activeFilters.remote &&
            this.state.activeFilters.remote.length > 0
        ) {
            filters.filters_remote = this.state.activeFilters.remote[0] === 1;
        }

        const payload = {
            keywords,
            location: {
                name: location,
                lat,
                lng,
                isRemote: false,
            },
            filters,
            page: searchPageNumber,
            limit,
            enableAroundRadius,
        };

        try {
            if (!isLoading) {
                const res = await axios.post(url, payload);
                const { jobs, total, page, filters: _filters } = res.data;
                const newRefs = {};

                this.setState(
                    (prevState) => {
                        let content = jobs;
                        if (
                            this.props.renderType === LOAD_TYPE_INFINITE_SCROLL
                        ) {
                            content = prevState.content.concat(jobs);
                        }
                        this.createHashRef(content, newRefs);

                        return {
                            content,
                            refs: newRefs,
                            jobsTotal: total,
                            activeFilters: _filters,
                            page,
                            isLoading: false,
                            visitedPages: this.state.visitedPages.includes(
                                this.state.page
                            )
                                ? [...this.state.visitedPages]
                                : [...this.state.visitedPages, this.state.page],
                        };
                    },
                    () => {
                        this.attribution();
                        const { baseUrl } = this.props;

                        const newUrl = this.buildSearchUrl(baseUrl);
                        window.history.replaceState({}, '', newUrl);
                        if (this.props.renderType === LOAD_TYPE_PAGINATION) {
                            window.scrollTo(0, 0);
                        }
                    }
                );
            }
        } catch (e) {
            this.setIsLoading();
            console.error(e);
            throw e;
        }
    };

    setIsLoading = (isLoading = false) => {
        this.setState({
            isLoading,
        });
    };

    buildSearchUrl = (baseUrl) => {
        const { keywords, location, lng, lat } = this.props.jobSearchBar;
        const url = `${baseUrl}`;
        const queryParams = {};
        const encodedCompanyFilters =
            this.state.activeFilters &&
            encodeURIComponent(this.state.activeFilters.company_id.join('+'));
        const encodedLocationFilters =
            this.state.activeFilters &&
            encodeURIComponent(this.state.activeFilters.locations.join('+'));
        const remoteFilter =
            (this.state.activeFilters && this.state.activeFilters.remote[0]) ||
            0;

        if (keywords) {
            queryParams.keywords = keywords;
        }
        if (location) {
            queryParams.location = location;
        }

        if (lat && lng) {
            queryParams.lat = lat;
            queryParams.lng = lng;
        }

        if (this.state.page && this.props.renderType === LOAD_TYPE_PAGINATION) {
            queryParams.page = this.state.page;
        }

        queryParams.filters_remote = remoteFilter;
        queryParams.filters_cmp = encodedCompanyFilters;
        queryParams.filters_loc = encodedLocationFilters;

        const query = buildQueryString(queryParams);
        return url + query;
    };

    getSuggestedJobs = async (SuggestedJobsPage = 1) => {
        const url = `${API_GET_SUGGESTED_JOBS}/?page=${SuggestedJobsPage}&limit=${5}`;
        try {
            const res = await axios.get(url);
            const newRefs = {};
            this.createHashRef(res.data, newRefs);
            this.setState(
                { content: res.data, refs: newRefs },
                this.attribution
            );
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * sync job search history from local storage to druid after user logged in (signed up)
     * @returns {Promise<void>}
     */
    syncLocalSearchesToDruid = async () => {
        if (window) {
            const jobSearches = this.fetchLocalJobSearches(-1);
            if (jobSearches && jobSearches.length > 0) {
                await axios.post(API_SYNC_LOCAL_SEARCHES, {
                    jobSearches,
                });
                window.localStorage.removeItem(this.key);
            }
        }
    };

    emitContentMetric = async (content, uniqueMetrics) => {
        const metrics = {
            job_slug: content.hash,
        };

        if (this.props.location) {
            metrics.community_content_location = this.props.location;
        }

        const sendMetrics = { ...metrics, ...uniqueMetrics };

        EmitMetric(sendMetrics);
    };

    /**
     * constructs and redirects to the targeted search url
     * @param keywords {String}
     * @param location {Object}
     * @param companyUrl {String}
     */
    searchForJobs = (keywords = '', location = {}, companyUrl = '') => {
        const _keywords = keywords ? encodeURIComponent(keywords) : keywords;

        const locationName = location.value || '';
        const { lat, lng } = (location && location.coordinates) || {};
        const baseurl = companyUrl || '/jobs/find';
        let url = `${baseurl}?keywords=${_keywords}&location=${locationName}`;

        if (lat && lng) {
            url += `&lat=${lat}&lng=${lng}`;
        }

        if (
            location.remote ||
            (locationName &&
                locationName.toLowerCase().trim() === 'remote' &&
                !lat &&
                !lng)
        ) {
            url += '&remote=true';
        }

        if (!this.state.loggedIn) {
            this.saveLocalJobSearch(_keywords, locationName, lat, lng, url);
        }

        if (window) {
            window.open(url, '_self');
        }
    };

    /**
     * This is the function specifically for zip recruiter jobs. if parameter isReplacingStateSet set to false, it will
     * concatate the new results from the api call to the previous set of results. if set to true, it will replace the old set with a completely new set.
     * @param isReplacingStateSet
     * @returns {Promise<void>}
     */
    searchForZipRecruiterJobs = async (isReplacingStateSet = false) => {
        const apiUrl = '/api/community/ziprecruiter-job/search';
        const { keywords, location, lng, lat } = this.props.jobSearchBar;
        let locationToSearch = location;
        let latToSearch = lat;
        let lngToSearch = lng;
        this.setState({ isZipRecruiterLoading: true });
        if (
            this.state.activeFilters &&
            this.state.activeFilters.locations &&
            this.state.activeFilters.locations.length > 0
        ) {
            locationToSearch = this.state.activeFilters.locations[
                this.state.activeFilters.locations.length - 1
            ];
            latToSearch = null;
            lngToSearch = null;
        }

        const payload = {
            keywords,
            location: {
                name: locationToSearch,
                lat: latToSearch,
                lng: lngToSearch,
                isRemote: false,
            },
            page: isReplacingStateSet ? 1 : this.state.zipRecruiterPageNum + 1,
            limit: 20,
        };

        try {
            const res = (await axios.post(apiUrl, payload)) || {};
            const { jobs, total, page } = res.data || {};

            this.setState((prevState) => ({
                zipRecruiterJobTotal: total,
                isZipRecruiterLoading: false,
                zipRecruiterJobs: isReplacingStateSet
                    ? [...jobs]
                    : [...prevState.zipRecruiterJobs, ...jobs],
                zipRecruiterPageNum: page,
            }));
        } catch (e) {
            this.sentry.captureMessage(
                `Error in retrieving ZipRecruiter jobs for keyword: ${keywords} and location: ${location}`
            );
        }
    };

    setIsJobProfileIncomplete = (isJobProfileIncomplete = true) => {
        this.setState({ isJobProfileIncomplete });
    };

    componentDidMount() {
        super.componentDidMount();
        if (this.state.loggedIn) {
            this.syncLocalSearchesToDruid().catch(console.error);
        }
    }

    render() {
        // we inject the two values passed by the backend into the
        // jobs context here, at the provider. We do not need them in
        // state, so we pass the prop directly.
        return (
            <Context.Provider
                value={{
                    ...this.state,
                    searchQueryId: this.props.searchQueryId,
                    searchIndexName: this.props.searchIndexName,
                }}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const JobContextProvider = wrapJobContextWithPageContext(
    PureJobContextProvider
);

export const JobContextConsumer = Context.Consumer;

// TODO: define shapes for the .array and .object prop-types below
PureJobContextProvider.propTypes = {
    hiringCompanies: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    jobSearchBar: PropTypes.shape({
        keywords: PropTypes.string,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        location: PropTypes.string,
        page: PropTypes.string,
        searchBarTitle: PropTypes.string,
        searchTermPlaceholder: PropTypes.string,
    }),
    userStatusOfCompleteness: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    session: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    activeFilters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    jobsTotal: PropTypes.number,
    pagination: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    jobOnboarding: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    featuredCompany: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /**
     * the location of job content (for metrics purposes)
     * this can be used to attribute a location to any metric fired associated with a given job
     */
    location: PropTypes.oneOf([
        'single-jobs-page',
        'singleContent',
        'masterFeed',
    ]),
    /**
     * whether job search should be around user's ip
     */
    enableAroundRadius: PropTypes.bool,
    /**
     * pageNumber for pagination
     */
    page: PropTypes.number,
    /**
     * number limit for search
     */
    limit: PropTypes.number,
    /**
     * Whether loading new jobs will be handled via pagination or infinte scroll
     */
    renderType: PropTypes.oneOf([
        LOAD_TYPE_PAGINATION,
        LOAD_TYPE_INFINITE_SCROLL,
    ]),
    /**
     * baseUrl for when search is done so it can update the url
     */
    baseUrl: PropTypes.string,
    fromCompanyJobsPage: PropTypes.bool,
};

PureJobContextProvider.defaultProps = {
    hiringCompanies: [],
    jobSearchBar: {},
    userStatusOfCompleteness: [],
    session: {},
    filters: {
        [JOB_SEARCH_FILTER_TYPES.COMPANY]: [],
        [JOB_SEARCH_FILTER_TYPES.LOCATION]: [],
        [JOB_SEARCH_FILTER_TYPES.REMOTE]: [1],
    },
    activeFilters: {
        [JOB_SEARCH_FILTER_TYPES.COMPANY]: [],
        [JOB_SEARCH_FILTER_TYPES.LOCATION]: [],
        [JOB_SEARCH_FILTER_TYPES.REMOTE]: [],
    },
    jobsTotal: 0,
    pagination: {},
    jobOnboarding: {},
    featuredCompany: {},
    location: null,
    baseUrl: '/',
    fromCompanyJobsPage: false,
};
