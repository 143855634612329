import * as yup from 'yup';
import { validateYupSchema } from './UserProfileSchema';
import { createMaxCharError } from './ContentSchema';

yup.addMethod(yup.number, 'nullIfEmpty', function () {
    return this.transform((v, o) =>
        (o + '').trim() === '' ? null : v
    ).nullable();
});

export const experienceSchema = yup.object({
    title: yup
        .string()
        .min(1, 'Must be at least 1 character.')
        .max(200, createMaxCharError(200))
        .required('Title is a required field.'),
    startMonth: yup
        .number()
        .nullIfEmpty()
        .test('startMonth-test', 'Please add a start month.', function (value) {
            const isCurrentJob = this.resolve(this.parent.isCurrent);
            const startYear = this.resolve(this.parent.startYear);

            if (startYear && !value) return false;
            if (isCurrentJob && !value) return false;
            return true;
        }),
    endMonth: yup
        .number()
        .nullIfEmpty()
        .test('endMonth-test', 'Please add an end month.', function (value) {
            const startYear = this.resolve(this.parent.startYear);
            const startMonth = this.resolve(this.parent.startMonth);
            const isCurrentJob = this.resolve(this.parent.isCurrent);

            if ((startYear || startMonth) && !isCurrentJob && !value)
                return false;
            return true;
        })
        .test(
            'month-after-check',
            'End date must be after start date.',
            function (value) {
                const startYear = this.resolve(this.parent.startYear);
                const startMonth = this.resolve(this.parent.startMonth);
                const endYear = this.resolve(this.parent.endYear);

                if (startYear && endYear && startYear === endYear) {
                    // same year
                    // check months
                    if (startMonth > value) return false;
                }

                return true;
            }
        ),
    startYear: yup
        .number()
        .nullIfEmpty()
        .test('startYear-test', 'Please add a start year.', function (value) {
            const startMonth = this.resolve(this.parent.startMonth);
            const isCurrentJob = this.resolve(this.parent.isCurrent);
            if (isCurrentJob && !value) return false;
            if (isCurrentJob && !startMonth && !value) return false;
            return true;
        }),
    endYear: yup
        .number()
        .nullIfEmpty()
        .test('endYear-test', 'Please add an end year.', function (value) {
            const startYear = this.resolve(this.parent.startYear);
            const startMonth = this.resolve(this.parent.startMonth);
            const endMonth = this.resolve(this.parent.endMonth);
            const isCurrentJob = this.resolve(this.parent.isCurrent);

            if (
                (startYear || startMonth || endMonth) &&
                !isCurrentJob &&
                !value
            )
                return false;
            return true;
        })
        .test(
            'year-after-check',
            'End year must be after start year.',
            function (value) {
                const startYear = this.resolve(this.parent.startYear);

                if (startYear && value && startYear > value) return false;

                return true;
            }
        ),
    isCurrent: yup.boolean(),
    company: yup.object().shape({
        companyName: yup.string().when('companyId', {
            is: (companyId) => !companyId || companyId.length === 0,
            then: yup
                .string()
                .min(100, 'Please add or select a company from the dropdown.')
                .required('Please add or select a company from the dropdown.'),
            otherwise: yup
                .string()
                .min(1, 'Must be at least one character.')
                .max(100, createMaxCharError(100))
                .nullable()
                .required('Company is required field.'),
        }),
        companyId: yup.number().nullable(),
    }),
    isRemote: yup.boolean(),
    excluded: yup.boolean(),
    location: yup.object().when('isRemote', {
        is: false,
        then: yup
            .object()
            .shape({
                name: yup.string().when('lat', {
                    is: (lat) => !lat || lat.length === 0,
                    then: yup
                        .string()
                        .min(
                            100,
                            'Please select a valid location from the dropdown.'
                        )
                        .required('Location is a required field.'),
                    otherwise: yup
                        .string()
                        .nullable()
                        .min(1)
                        .max(100, createMaxCharError(100))
                        .required('Location is a required field.'),
                }),
                lat: yup.number().nullable().min(-90).max(90),
                lng: yup.number().nullable().min(-180).max(180),
            })
            .required(),
        otherwise: yup.object().nullable().notRequired(),
    }),
    description: yup.string().max(2000, createMaxCharError(2000)).nullable(),
});

export const validateSingleExperiencePayload = async (payload) => {
    const { valid, errors } = await validateYupSchema(
        payload,
        experienceSchema
    );

    return {
        valid,
        errors,
    };
};
