import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { buildImagePath } from '../../../JobComponents/Company/Image/logo';
import { LOGIN_LAYOUT } from '../../../Login/LoginLayoutComponent/Layouts/LogInLayout/constants';
import { PageContext } from '../../../../contexts/ContextCreator';
import './GroupStartPostButton.scss';

const GroupStartPostButton = ({ closeModalHandler }) => {
    const pageContext = useContext(PageContext);

    const injectedTransforms = {
        default: 'c_lpad,f_auto,q_auto,w_54,h_70',
    };

    const imagePath = buildImagePath(
        '/v1564074715/SVGs/painter.svg',
        'default',
        injectedTransforms
    );

    const handleStartPostInteraction = () => {
        if (!window || !document) return;
        const upb = document.getElementById('unified-post-box');
        if (!upb) return;

        closeModalHandler();

        const editor = document.querySelector(
            '.component-UnifiedPostBox-PostBoxCTA'
        );

        if (editor) {
            editor.click();
        }

        const scrollTop = upb.offsetTop;

        window.scrollTo({
            top: scrollTop - 65,
            behavior: 'smooth',
        });
    };

    return pageContext.wrapAuth(
        'CreateContent',
        <button
            type="button"
            tabIndex={0}
            className=" component-Groups-Elements-GroupStartPostButton item-button groups-mobile-menu-post-now"
            onClick={() => handleStartPostInteraction()}
        >
            <div>Start a post</div>
            <img
                className="post-now-svg"
                src={imagePath}
                alt="start-post-svg"
            />
        </button>,
        { login_trigger: 'create-content' },
        null,
        LOGIN_LAYOUT
    );
};

GroupStartPostButton.propTypes = {
    closeModalHandler: PropTypes.func.isRequired,
};

GroupStartPostButton.defaultProps = {};

GroupStartPostButton.displayName = 'GroupStartPostButton';

export default React.memo(GroupStartPostButton);
