import PropTypes from 'prop-types';
import React from 'react';
import UserIconWithOverlay from '../../../Elements/UserIconWithOverlay/UserIconWithOverlay';
import { memberTypeProptype } from '../../../Includes/GroupPropsShape';
import { userProptype } from '../../../Includes/userProps';
import MemberTypeBadge from './MemberTypeBadge/MemberTypeBadge';

import './UserIcon.scss';

export default class UserIcon extends React.PureComponent {
    static propTypes = {
        /**
         * css class that could override default classes
         */
        className: PropTypes.string,
        /**
         * width for UserIcon
         */
        widthStyle: PropTypes.object,
        /**
         * type/role of the member
         */
        groupMemberType: memberTypeProptype,
        /**
         * user meta data
         */
        user: userProptype,
        /**
         * should VIP badge be shown if applicable
         */
        showVipBadge: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        user: {},
        groupMemberType: null,
        widthStyle: { width: '25%' },
        showVipBadge: true,
    };

    render() {
        const {
            user,
            groupMemberType,
            className,
            widthStyle,
            showVipBadge,
        } = this.props;

        return (
            <span
                className={`component-Users-UserIconList-UserIcon ${className}`}
                style={widthStyle}
            >
                <UserIconWithOverlay
                    targetUser={user}
                    userIconSize={56}
                    showVipBadge={showVipBadge}
                />
                {groupMemberType && (
                    <MemberTypeBadge groupMemberType={groupMemberType} />
                )}
            </span>
        );
    }
}
