/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-object-spread */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import CloudinaryImageUpload from '../../../CloudinaryImageUpload/CloudinaryImageUpload';
import TopicsTypeAheadDropdown from '../../../Community/TopicsTypeAheadDropdown/TopicsTypeAheadDropdown';
import CharCount from '../../../Elements/CharCount/CharCount';
import {
    legacyCommunityPhotoYupSchema,
    PhotoEditSchema,
} from '../../../Includes/FormSchema/ContentSchema';
import Button from '../../../Input/Button/Button';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import CheckBox from '../../Elements/CheckBox/CheckBox';
import {
    getImageFormat,
    getCloudinaryImagePathById,
} from '../../../../utilities/images';
import './PhotoForm.scss';

const cloudinaryWidgetOptions = {
    defaultCoundinaryID: 'icons/user-default',
    uploadPreset: 'community-photo',
    showSkipCropButton: true,
    clientAllowedFormats: ['jpeg', 'png', 'heif', 'webp', 'gif'],
};

const formatAllowedFileTypes = (fileTypes) =>
    fileTypes
        .slice(0, -1)
        .map((fileType) => `.${fileType}`)
        .join(', ')
        .concat(`, or .${fileTypes.slice(-1)}`);

const GENERIC_UPLOAD_ERROR =
    'There was an error uploading your image. Please try again.';

export default class PhotoForm extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            cloudinaryId:
                props?.contentProps?.image?.src ||
                props?.contentProps?.image ||
                null,
            title:
                props?.contentProps?.description ||
                props?.contentProps?.title ||
                '',
            anon: !!props.anon,
            postLocation: props.postLocation || null,
            errors: {
                communityTopicId: '',
                title: '',
                cloudinaryId: '',
                anon: '',
            },
            moderationOptions: props.moderationOptions || null,
            isCreating: false,
        };
    }

    static propTypes = {
        contentProps: PropTypes.object,
        editMode: PropTypes.bool,
        /**
         * function for retrieving key and value in form submission
         * key can either be communityTopicId or communityGroupHash
         */
        getPostIdKeyValue: PropTypes.func.isRequired,
        /**
            generates a label for anonymous checkbox
        */
        getCheckBoxLabel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        editMode: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.postLocation) {
            return {
                postLocation: props.postLocation,
            };
        }
        return null;
    }

    handleCloudinaryUpdate = async (idValue) => {
        const format = await getImageFormat(`/${idValue}`);

        if (format && typeof format.ext === 'string') {
            this.handleInputChange(
                'cloudinaryId',
                `${idValue}.${
                    format.animated ? `anim.${format.ext}` : format.ext
                }`
            );
            return;
        }

        this.handleInputChange('cloudinaryId', idValue);
    };

    handleInputChange = (key, value) => {
        const errorObject = Object.assign({}, this.state.errors);
        const errorKey = key === 'postLocation' ? 'oneOrTheOther' : key;
        errorObject[errorKey] = '';

        this.setState({
            [key]: value,
            errors: errorObject,
        });
    };

    handleCheckboxChange = () =>
        this.setState((previousState) => ({ anon: !previousState.anon }));

    setError = (key, error) => {
        const errors = Object.assign({}, this.state.errors);
        errors[key] = error;
        this.setState({
            errors,
        });
    };

    handleSubmit = async () => {
        this.setState({ isCreating: true, hasPostError: false });

        const {
            postLocationKey,
            postLocationValue,
        } = this.props.getPostIdKeyValue(this.state.postLocation);

        const createFormValues = {
            title: this.state.title,
            cloudinaryId: this.state.cloudinaryId,
            anon: this.state.anon,
            [postLocationKey]: postLocationValue,
        };

        const editFormValues = {
            title: this.state.title,
            anon: this.state.anon,
        };

        const formValues = this.props.editMode
            ? editFormValues
            : createFormValues;
        const schema = this.props.editMode
            ? PhotoEditSchema
            : legacyCommunityPhotoYupSchema;

        try {
            await this.props.validateForm(formValues, schema);
            this.props.editMode
                ? await this.props.onEditSubmit(
                      'community-photo',
                      editFormValues,
                      this.props.contentProps.hash
                  )
                : await this.submitAPICall(createFormValues);
        } catch (errors) {
            return this.setState(this.props.renderErrors(errors));
        }
    };

    submitAPICall = async (formValues) => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        try {
            const result = await axios.post(
                '/api/community/community-photo/create',
                formValues
            );
            this.setState({ isCreating: false });
            this.props.onSuccess(
                postocationId,
                `community-photo-${result.data.data.hash}`
            );
        } catch (error) {
            this.setState({
                hasPostError:
                    'There was an error publishing your post. Please try sharing again.',
                isCreating: false,
            });
        }
    };

    handleUploadFailure = (e) => {
        // make sure error object has statusText property and set error
        if (e && e.statusText) {
            const allowedFormatStr = formatAllowedFileTypes(
                cloudinaryWidgetOptions.clientAllowedFormats
            );
            const errMsg = `${e.statusText}: please use one of ${allowedFormatStr}`;
            return this.setError('cloudinaryId', errMsg);
        }

        // otherwise use generic upload error message
        return this.setError('cloudinaryId', GENERIC_UPLOAD_ERROR);
    };

    loadPhotoButton = () => {
        if (this.props.editMode) {
            return null;
        }
        let photoButton = (
            <CloudinaryImageUpload
                {...cloudinaryWidgetOptions}
                successCallback={(cloudinaryId) =>
                    this.handleCloudinaryUpdate(cloudinaryId)
                }
                onUploadFailure={this.handleUploadFailure}
            >
                <div className="upload-button-div">
                    <Button type="secondary-blue-gray" value="Upload" />
                </div>
            </CloudinaryImageUpload>
        );
        if (this.state.cloudinaryId && this.state.cloudinaryId.length) {
            photoButton = (
                <div className="delete-button-div">
                    <Button
                        type="tertiary-blue-gray"
                        value="Delete"
                        onClick={() =>
                            this.handleInputChange('cloudinaryId', null)
                        }
                    />
                </div>
            );
        }
        return photoButton;
    };

    hasPostLocation = () => {
        const postocationId =
            this.state.postLocation && this.state.postLocation.id
                ? this.state.postLocation.id
                : this.state.postLocation;
        return !!(postocationId && postocationId.toString().length);
    };

    hasTitle = () => !!(this.state.title && this.state.title.length);

    hasCloudinaryId = () =>
        !!(this.state.cloudinaryId && this.state.cloudinaryId.length);

    allFieldFilledOut = () =>
        !(this.hasPostLocation() && this.hasTitle() && this.hasCloudinaryId());

    setEmptyError = () => {
        this.setState({
            errors: {
                oneOrTheOther: this.hasPostLocation()
                    ? ''
                    : 'Please choose where to post to.',
                cloudinaryId: this.hasCloudinaryId()
                    ? ''
                    : 'This field is required.',
                title: this.hasTitle() ? '' : 'This field is required.',
            },
        });
    };

    render() {
        const checkboxLabel = this.props.getCheckBoxLabel(
            this.props.editMode,
            this.state.anon
        );
        const injectedTransform = {
            default: 'w_300,c_limit',
        };

        const checkbox = (
            <CheckBox
                qaDataAttr="checkBoxLabel"
                id="ContentTempID"
                className="anonymous-checkbox"
                defaultChecked={this.state.anon}
                label={checkboxLabel}
                handleChange={this.handleCheckboxChange}
            />
        );
        const editableFields =
            this.state.moderationOptions &&
            this.state.moderationOptions.editableFields;

        return (
            <div className="component-Content-CreateContentWidgetV2-PhotoForm">
                <div className="form">
                    <span className="error-message">
                        {this.state.hasPostError}
                    </span>
                    <span className="section-label">
                        {this.props.editMode ? 'Posted to:' : 'Post to:*'}
                    </span>
                    <TopicsTypeAheadDropdown
                        selection={this.state.postLocation}
                        selectCallback={(postLocation) =>
                            this.handleInputChange('postLocation', postLocation)
                        }
                        disabled={this.props.editMode}
                    />
                    <span className="error-message">
                        {this.state.errors.oneOrTheOther}
                    </span>
                    <div className="caption-title-section">
                        <span className="section-label">The caption*</span>
                        {this.state.title && this.state.title.length && (
                            <CharCount
                                length={this.state.title.length}
                                limit={5000}
                            />
                        )}
                    </div>
                    <TextAreaInput
                        name="caption"
                        class="text-area-input"
                        rows={2}
                        disabled={
                            this.props.editMode &&
                            !(
                                editableFields &&
                                editableFields.includes('title')
                            )
                        }
                        value={this.state.title}
                        placeholder="What do you want to say about this?"
                        onChange={(title) =>
                            this.handleInputChange('title', title)
                        }
                    />
                    <span className="error-message">
                        {this.state.errors.title}
                    </span>
                    <span className="image-section-title section-label">
                        Image (e.g. .jpg, .png)*
                    </span>
                    <section className="image-section">
                        {this.state.cloudinaryId &&
                        this.state.cloudinaryId.length ? (
                            <img
                                className="community-photo"
                                alt="uploaded-photo"
                                src={getCloudinaryImagePathById(
                                    this.state.cloudinaryId,
                                    'default',
                                    injectedTransform
                                )}
                            />
                        ) : null}
                        {this.loadPhotoButton()}
                    </section>
                    <span className="error-message">
                        {this.state.errors.cloudinaryId}
                    </span>

                    {checkbox}

                    <Button
                        value={this.props.editMode ? 'Publish' : 'Post now'}
                        onClick={
                            this.allFieldFilledOut()
                                ? this.setEmptyError
                                : this.handleSubmit
                        }
                        type="purple-medium-white"
                        class="post-now-button"
                        inputStyle="post-now-button"
                        disabled={this.state.isCreating}
                    />
                </div>
            </div>
        );
    }
}
