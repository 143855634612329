import PropTypes from 'prop-types';
import React from 'react';
import ArrowSVG from '../../../../SVGs/ArrowSVG/ArrowSVG';

import './LeftRailExpandableMenuItem.scss';

export default class LeftRailExpandableMenuItem extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            expanded: false,
        };
    }

    static propTypes = {
        parentItem: PropTypes.string.isRequired,
        submenuItems: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
            })
        ).isRequired,
        /**
         * styling that would override the default
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        submenuItems: [],
        className: '',
    };

    handleExpand = () => {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));
    };

    renderSubmenu = () => {
        return (
            <div className="submenu">
                {this.props.submenuItems.map((item) => {
                    let emailClassName = '';
                    let target = '_self';

                    if (item.key === 'contact us email') {
                        emailClassName = 'item-link--email';
                        target = '_blank';
                    }

                    return (
                        <a
                            className={`item-link ${emailClassName}`}
                            href={item.url}
                            target={target}
                        >
                            {item.title}
                        </a>
                    );
                })}
            </div>
        );
    };

    render() {
        const arrowDirection = this.state.expanded ? 'down' : 'right';
        return (
            <div
                className={`component-Layout-Footer-Components-LeftRailExpandableMenuItem ${this.props.className}`}
            >
                <div className="titleSection" onClick={this.handleExpand}>
                    <div className={'title-text'}>{this.props.parentItem}</div>
                    <ArrowSVG direction={arrowDirection} />
                </div>
                {this.state.expanded ? this.renderSubmenu() : null}
            </div>
        );
    }
}
