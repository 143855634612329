import { IMAGE_CDN_URL } from '../../../../utilities/imageCDN';

/* TODO: Move this to utilities/images helper functions */

export const buildImagePath = (cloudinaryId, size, injectedTransforms) => {
    const src = `${IMAGE_CDN_URL}/`;
    const regex = /\/?v[0-9]+\//;
    const defaultTransforms = {
        button: 'c_scale,f_auto,q_auto,h_22',
        xxSmall: 'c_scale,f_auto,q_auto,h_24',
        xSmall: 'c_scale,f_auto,q_auto,w_60',
        small: 'c_scale,f_auto,q_auto,w_70',
        default: 'c_scale,f_auto,q_auto,h_300',
        fullScreen: 'c_scale,f_auto,q_auto,w_1500',
        'small-lPad': 'c_lpad,f_auto,q_auto,w_60,h_60',
    };

    const formattedUrl = cloudinaryId.startsWith('/')
        ? `/v1${cloudinaryId.replace(regex, '/').replace('.anim', '')}`
        : `/v1/${cloudinaryId.replace(regex, '/').replace('.anim', '')}`;
    const transforms = injectedTransforms || defaultTransforms;
    return src + transforms[size] + formattedUrl;
};
