import { isPlainObject, setIn, getIn } from './object';

export const copyArray = (source, array) => {
    let index = -1;
    const length = source.length;

    array || (array = new Array(length));

    while (++index < length) {
        array[index] = source[index];
    }

    return array;
};

export const map = (array, iteratee) => {
    let index = -1;

    const length = array == null ? 0 : array.length;
    const result = new Array(length);

    while (++index < length) {
        result[index] = iteratee(array[index], index, array);
    }
    return result;
};

const toString = Object.prototype.toString;
const INFINITY = 1 / 0;

export const getTag = (value) => {
    if (value == null)
        return value === undefined ? '[object Undefined]' : '[object Null]';
    return toString.call(value);
};

export const isSymbol = (value) => {
    const type = typeof value;
    return (
        type == 'symbol' ||
        (type === 'object' &&
            value != null &&
            getTag(value) == '[object Symbol]')
    );
};

export const toKey = (value) => {
    if (typeof value === 'string' || isSymbol(value)) return value;

    const result = `${value}`;

    return result == '0' && 1 / value == -INFINITY ? '-0' : result;
};
const MAX_MEMOIZE_SIZE = 500;

const memoize = (func, resolver) => {
    if (
        typeof func !== 'function' ||
        (resolver != null && typeof resolver !== 'function')
    ) {
        throw new TypeError('Expected a function');
    }
    const memoized = function (...args) {
        const key = resolver ? resolver.apply(this, args) : args[0];
        const cache = memoized.cache;

        if (cache.has(key)) {
            return cache.get(key);
        }
        const result = func.apply(this, args);
        memoized.cache = cache.set(key, result) || cache;
        return result;
    };
    memoized.cache = new (memoize.Cache || Map)();
    return memoized;
};

memoize.Cache = Map;

const memoizeCapped = (func) => {
    const result = memoize(func, (key) => {
        const { cache } = result;
        if (cache.size === MAX_MEMOIZE_SIZE) {
            cache.clear();
        }
        return key;
    });

    return result;
};

const charCodeOfDot = '.'.charCodeAt(0);
const reEscapeChar = /\\(\\)?/g;
const rePropName = new RegExp(
    // Match anything that isn't a dot or bracket.
    '[^.[\\]]+' +
        '|' +
        // Or match property names within brackets.
        '\\[(?:' +
        // Match a non-string expression.
        '([^"\'][^[]*)' +
        '|' +
        // Or match strings (supports escaping characters)
        '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' +
        ')\\]' +
        '|' +
        // Or match "" as the space between consecutive dots or empty brackets.
        '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))',
    'g'
);

export const stringToPath = memoizeCapped((string) => {
    const result = [];
    if (string.charCodeAt(0) === charCodeOfDot) {
        result.push('');
    }
    string.replace(rePropName, (match, expression, quote, subString) => {
        let key = match;
        if (quote) {
            key = subString.replace(reEscapeChar, '$1');
        } else if (expression) {
            key = expression.trim();
        }
        result.push(key);
    });
    return result;
});

export const toPath = (value) => {
    if (Array.isArray(value)) {
        return map(value, toKey);
    }
    return isSymbol(value) ? [value] : copyArray(stringToPath(value));
};

export const buildFileDataUrl = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

export const prepareFormValuesForValidation = (values) => {
    let data = Array.isArray(values) ? [] : {};
    for (const k in values) {
        if (Object.prototype.hasOwnProperty.call(values, k)) {
            const key = String(k);
            if (Array.isArray(values[key]) === true) {
                data[key] = values[key].map((value) => {
                    if (Array.isArray(value) === true || isPlainObject(value)) {
                        return prepareFormValuesForValidation(value);
                    }

                    return value !== '' ? value : undefined;
                });
            } else if (isPlainObject(values[key])) {
                data[key] = prepareFormValuesForValidation(values[key]);
            } else {
                data[key] = values[key] !== '' ? values[key] : undefined;
            }
        }
    }
    return data;
};

export const processYupValidationErrors = (yupError) => {
    let errors = {};

    if (yupError.inner) {
        if (yupError.inner.length === 0)
            return setIn(errors, yupError.path, yupError.message);

        for (const err of yupError.inner) {
            if (!getIn(errors, err.path)) {
                errors = setIn(errors, err.path, err.message);
            }
        }
    }

    return errors;
};
