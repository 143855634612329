import PropTypes from 'prop-types';
import React from 'react';
import { getCloudinaryImagePathById } from '../../../../utilities/images';
import ButtonGrid from '../../Components/ButtonGrid/ButtonGrid';

import './MessageCard.scss';

const ButtonStyles = {
    PURPLE: 'purple',
    NORMAL: 'normal',
};

export default class MessageCard extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         *  image, src and alt, nullable
         */
        img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        /**
         *  buttonData, array of objects containing display title and value
         */
        buttonData: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.bool,
                    PropTypes.number,
                ]),
            })
        ),
        /*
         ** layout & color styling
         */
        buttonStyle: PropTypes.oneOf([
            ButtonStyles.PURPLE,
            ButtonStyles.NORMAL,
        ]),
        /**
         *  isAnimated, determines if image is animated
         */
        isAnimated: PropTypes.bool,
        /**
         *  onClick, will fire onAnswer
         */
        onClick: PropTypes.func,
        hasAuthWrapper: PropTypes.bool,
        questionName: PropTypes.string,
    };

    static defaultProps = {
        hasAuthWrapper: false,
        buttonStyle: ButtonStyles.NORMAL,
        img: {
            src: '/icons/Congratulations_2x',
            alt: '',
        },
        buttonData: [
            {
                title: 'Ok',
                value: 'ok',
            },
            {
                title: 'Cancel',
                subtitle: 'Click to cancel',
                value: 'cancel',
            },
        ],
        isAnimated: false,
    };

    getImageSrc = () => {
        const size = this.props.isAnimated ? '240' : '120';
        const transform = `c_lpad,f_auto,q_auto,w_${size},h_${size}`;
        return getCloudinaryImagePathById(this.props.img.src, 'icon', {
            icon: transform,
        });
    };

    getAdditionalClasses = () => {
        return `${this.props.buttonStyle} ${
            this.props.isAnimated ? 'isAnimated' : ''
        }`;
    };

    render() {
        return (
            <div
                className={`component-ReviewSurvey-QuestionCards-MessageCard ${this.getAdditionalClasses()}`}
            >
                {this.props.img && (
                    <div className={`icon-row`}>
                        <img
                            className="icon"
                            src={this.getImageSrc()}
                            alt={this.props.img.alt}
                        />
                    </div>
                )}
                <div className="button-row">
                    <ButtonGrid
                        questionName={this.props.questionName}
                        hasAuthWrapper={this.props.hasAuthWrapper}
                        buttonData={this.props.buttonData}
                        buttonType={'single'}
                        buttonStyle={this.props.buttonStyle}
                        onClick={this.props.onClick}
                        gridStyle={'single-row'}
                    />
                </div>
            </div>
        );
    }
}
