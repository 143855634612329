import React from 'react';
import useEffectOnce from '../useEffectOnce';

const useMount = (fn = () => {}) => {
    useEffectOnce(() => {
        fn();
    });
};

export default useMount;
