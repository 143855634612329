import PropTypes from 'prop-types';
import React from 'react';

/**
 * A "Base" Component Class that should be attached to any component that acts like a form element
 * @abstract
 */
export default class FormComponent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * The name of the component
         */
        name: PropTypes.string.isRequired,
        /**
         * The Redux Form Store
         */
        form: PropTypes.object.isRequired,
    };

    /**
     *
     * @param value - the value the element will be set to
     * @param isValid - if the element is valid
     * @param errors  - an array of string of errors
     * @param label - if the components "Name/Label" is not the same as its value
     *
     * Update the form element within the store
     */
    updateFormElement = (value, isValid = true, errors = [], label = null) => {
        this.props.updateFormElement(
            this.props.name,
            isValid,
            value,
            errors,
            label
        );
    };

    /**
     *
     * @param elementSet - an array of {value, isValid, errors, label} objects, used for forms that are an "array" of items
     * @param isValid - if the element is valid
     * @param errors  - an array of string of errors
     * @param label - if the components "Name/Label" is not the same as its value
     *
     * Update the form element within the store
     */
    updateFormElementSet(
        elementSet,
        isValid = true,
        errors = [],
        label = null
    ) {
        this.props.updateFormElementSet(
            this.props.name,
            isValid,
            elementSet,
            errors,
            label
        );
    }

    /**
     *
     * @param childName - the name of the child form element
     * @param value - the value of the child form element
     * @param isValid - this the child valid?
     * @param errors - what errors our on the child
     * @param label = does the child have a name?
     *
     * Use this function if you wish to update the single sub-form of a complex form component, eg Person:firstname
     */
    updateFormElementChild(
        childName,
        value,
        isValid = true,
        errors = [],
        label = []
    ) {
        this.props.updateFormElement(
            this.props.name + ':' + childName,
            isValid,
            value,
            errors,
            label
        );
    }

    /**
     * @param childName - the name of the child form element
     * @param elementSet - an array of {value, isValid, errors, label} objects, used for forms that are an "array" of items
     * @param isValid - if the element is valid
     * @param errors  - an array of string of errors
     * @param label - if the components "Name/Label" is not the same as its value
     *
     * Use this if you wish to update a whole set of children. get Person:phonenumbers
     */
    updateFormElementChildSet(
        childName,
        elementSet,
        isValid = true,
        errors = [],
        label = null
    ) {
        this.props.updateFormElementSet(
            this.props.name + ':' + childName,
            isValid,
            elementSet,
            errors,
            label
        );
    }

    /**
     * WARNING...WIPE the current form element from the store!
     * Use this if you wish to wipe the element completely and rebuild it entirely
     */
    removeFormElement() {
        this.props.removeFormElement(this.props.name);
    }

    /**
     * @param childName - the name of the child element in the store
     *
     * Use this if you wish to remove either:
     * - a item from the end of a  list Person:Numbers:1...or
     * - a item from a complex object Person:Height
     */
    removeFormElementChild(childName) {
        this.props.removeFormElement(this.props.name + ':' + childName);
    }

    /**
     * Get the internal representation of a element (or a child element)
     * @param childName - name of the child element
     * @returns {{}}
     */
    _getStoreSource(childName) {
        let source = this.props.form[this.props.name]
            ? this.props.form[this.props.name]
            : {};
        if (typeof childName === 'undefined') {
            return source;
        }
        let trace = source;
        let childPices = childName.toString().split(':');
        while (true) {
            let piece = childPices.shift();
            if (!trace._children || !trace._children[piece]) {
                throw new Error('No child with given name is found');
            }
            trace = trace._children[piece];
            if (childPices.length === 0) {
                return trace;
            }
        }
    }

    /**
     * Get the label value of the element (or child element)
     * @param childName - name of the child element
     */
    getStoreLabel(childName) {
        return this._getStoreSource(childName)._label;
    }

    /**
     * Get the value  of the element (or child element)
     * @param childName - name of the child element
     * @param returnNullOnException - if we get a node not found exception just return null
     */
    getStoreValue(childName, returnNullOnException = true) {
        try {
            if (Object.keys(this._getStoreSource(childName)).length === 0) {
                return null;
            }
            return this._getStoreSource(childName)._value;
        } catch (e) {
            if (returnNullOnException) {
                return null;
            }
            throw e;
        }
    }

    /**
     * Get the array of errors  of the element (or child element)
     * @param childName - name of the child element
     */
    getStoreErrors(childName) {
        let source = this._getStoreSource(childName);
        if (!source._errors) {
            return [];
        }
        return source._errors;
    }

    /**
     * Returns an array of all the errors of the current form and all child forms
     * @returns []
     */
    getAllErrors() {
        let errors = this.getStoreErrors();
        let _recurseErrors = (children) => {
            if (!children) {
                return;
            }

            if (!Array.isArray(children)) {
                children = Object.values(children);
            }

            children.map((child) => {
                if (child._errors && Array.isArray(child._errors)) {
                    child._errors.map((error) => {
                        errors.push(error);
                    });
                }
                if (child._children) {
                    _recurseErrors(child._children);
                }
            });
        };
        _recurseErrors(this.getChildren());
        return errors;
    }

    /**
     * Get the child elemenets of the element (or child element)
     * @param childName - name of the child element
     */
    getChildren(childName) {
        if (this._getStoreSource(childName) === {}) {
            return null;
        }

        return this._getStoreSource(childName)._children;
    }

    /**
     * Returns the number of children under this form components name
     * @param childName
     * @returns {*}
     */
    getChildCount(childName) {
        let children = this.getChildren(childName);
        if (!children) {
            return 0;
        }
        return Array.isArray(children)
            ? children.length
            : Object.keys(children).length;
    }
}
