import PropTypes from 'prop-types';
import React from 'react';

import './CardHeader.scss';

export default class CardHeader extends React.PureComponent {
    static propTypes = {
        /**
         * className of CSS class that will override the existing styling
         */
        cssClass: PropTypes.string.isRequired,
    };

    static defaultProps = {
        cssClass: '',
    };

    render() {
        const { children, cssClass } = this.props;
        return (
            <div className="component-Groups-CardHeader">
                <h2 className={`header ${cssClass}`}>{children}</h2>
            </div>
        );
    }
}
