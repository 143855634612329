import React from 'react';
import SVGFromCloudinary from '../../Elements/SVGFromCloudinary/SVGFromCloudinary';
import './alert.scss';

const Alert = ({ message, cloudinaryID }) => {
    return (
        <div className="alert-container">
            <SVGFromCloudinary cloudinaryID={cloudinaryID} alt="check_icon" />
            <div className="alert-text">{message}</div>
        </div>
    );
};

export default Alert;
