import * as React from 'react';

const SuccessIllustration = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={104}
        height={110}
        fill="none"
        {...props}
    >
        <circle cx={52} cy={62} r={48} fill="#F2ECFE" />
        <path
            fill="#E3C5E7"
            d="m65.809 2 6.308 2.185-2.234 6.171-6.308-2.185z"
        />
        <path
            d="M21.192 11.82C22.406 7.67 27.229-.004 36.806 2.486"
            stroke="#982EA9"
            strokeWidth={2.414}
            strokeLinecap="round"
        />
        <path
            fill="#B394F2"
            d="m98.15 35.346 4.107-1.705L104 37.658l-4.107 1.705z"
        />
        <path
            fill="#BD79C8"
            d="m45.73 13.525 4.106-1.706 1.743 4.017-4.107 1.706zM91.458 55.703l6.347-2.077 2.123 6.208-6.346 2.077z"
        />
        <path
            fill="#982EA9"
            d="m0 48.125 7.677-1.392 1.423 7.51-7.677 1.392z"
        />
        <path fill="#B394F2" d="m0 27.359 4.732-4.63 4.733 4.63-4.733 4.63z" />
        <path
            d="M87.163 9.637c3.859 2.091 10.418 8.415 5.785 16.983"
            stroke="#B394F2"
            strokeWidth={2.414}
            strokeLinecap="round"
        />
        <path
            d="M15.171 53.245h71.031v45.038a3.162 3.162 0 0 1-3.161 3.162H18.332a3.162 3.162 0 0 1-3.161-3.162V53.245Z"
            fill="#C4CBD8"
        />
        <path
            d="M27.855 55.782h58.347v43.126a2.537 2.537 0 0 1-2.537 2.537H30.392a2.537 2.537 0 0 1-2.537-2.537V55.782Z"
            fill="#9DA9BF"
            fillOpacity={0.6}
        />
        <path
            d="M27.855 53.245h58.347v22.831c0 13.319 6.342 22.832 6.342 22.832H34.197c-5.253-5.33-6.342-9.513-6.342-22.832V53.245Z"
            fill="#F9F9FB"
        />
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={12}
            y={18}
            width={68}
            height={71}
        >
            <path
                d="M79.86 46.269c0 23.467-19.024 42.491-42.492 42.491C13.901 88.76 12 69.736 12 46.27 12 22.8 26.585 18.364 50.052 18.364c23.468 0 29.808 4.437 29.808 27.905Z"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#a)">
            <path
                d="M27.855 53.245h58.347v22.831c0 13.319 6.342 22.832 6.342 22.832H34.197c-5.253-5.33-6.342-9.513-6.342-22.832V53.245Z"
                fill="#fff"
            />
        </g>
        <rect
            x={13.903}
            y={32.95}
            width={73.568}
            height={22.831}
            rx={2.108}
            fill="#1B2B4A"
        />
        <path
            d="M27.855 35.058c0-1.164.944-2.108 2.108-2.108h55.4c1.164 0 2.107.944 2.107 2.108v18.616a2.108 2.108 0 0 1-2.107 2.108H27.855V35.058Z"
            fill="#2A4473"
        />
        <circle cx={73.518} cy={45.935} r={5.074} fill="#22385E" />
        <circle cx={44.979} cy={45.935} r={5.074} fill="#22385E" />
        <circle cx={44.979} cy={44.033} r={5.074} fill="#131F35" />
        <circle cx={73.518} cy={44.033} r={5.074} fill="#131F35" />
        <path
            d="M33.182 32.948h-5.386s.007-4.718 3.864-8.576c2.875-2.874 7.342-3.224 10.148-2.537 2.805.688 4.667 1.903 6.342 3.806 1.268 1.441 2.843 4.623 2.843 7.99 0 10.105-4.855 12.59-4.855 12.59-2.3.672-4.332-1.655-3.356-3.843l.026-.058s2.366-2.076 2.366-8.69c0-3.044-1.615-6.58-5.936-6.58-1.676 0-3.358.743-4.41 1.994-1.213 1.44-1.646 3.904-1.646 3.904Z"
            fill="#C4CBD8"
        />
        <mask
            id="b"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={27}
            y={21}
            width={24}
            height={26}
        >
            <path
                d="M33.182 32.948h-5.386s.007-4.718 3.864-8.576c2.875-2.874 7.342-3.224 10.148-2.537 2.805.688 4.667 1.903 6.342 3.806 1.268 1.441 2.843 4.623 2.843 7.99 0 10.105-4.855 12.59-4.855 12.59-2.3.672-4.332-1.655-3.356-3.843l.026-.058s2.366-2.076 2.366-8.69c0-3.044-1.627-6.586-5.948-6.586-1.676 0-3.346.75-4.399 2-1.212 1.44-1.645 3.904-1.645 3.904Z"
                fill="#C4CBD8"
            />
        </mask>
        <g mask="url(#b)" fill="#9DA9BF">
            <rect
                x={27.855}
                y={27.056}
                width={17.124}
                height={11.174}
                rx={5.587}
            />
            <path d="M50.993 33.63c0 10.106-4.855 12.592-4.855 12.592-2.3.671-4.332-1.656-3.356-3.844l.026-.058s1.17-1.027 1.865-3.936c2.591 0 5.076-1.495 6.32-4.753Z" />
        </g>
        <path
            d="M61.573 32.948h-5.386s.007-4.718 3.865-8.576c2.874-2.874 7.342-3.224 10.147-2.537 2.806.688 4.668 1.903 6.342 3.806 1.269 1.441 2.844 4.623 2.844 7.99 0 10.105-4.856 12.59-4.856 12.59-2.3.672-4.331-1.655-3.355-3.843l.026-.058s2.366-2.076 2.366-8.69c0-3.044-1.616-6.58-5.937-6.58-1.676 0-3.357.743-4.41 1.994-1.212 1.44-1.646 3.904-1.646 3.904Z"
            fill="#C4CBD8"
        />
        <mask
            id="c"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={56}
            y={21}
            width={24}
            height={26}
        >
            <path
                d="M61.573 32.948h-5.386s.007-4.718 3.865-8.576c2.874-2.874 7.342-3.224 10.147-2.537 2.806.688 4.668 1.903 6.342 3.806 1.269 1.441 2.844 4.623 2.844 7.99 0 10.105-4.856 12.59-4.856 12.59-2.3.672-4.331-1.655-3.355-3.843l.026-.058s2.366-2.076 2.366-8.69c0-3.044-1.628-6.586-5.949-6.586-1.676 0-3.345.75-4.398 2-1.212 1.44-1.646 3.904-1.646 3.904Z"
                fill="#C4CBD8"
            />
        </mask>
        <g mask="url(#c)" fill="#9DA9BF">
            <rect
                x={56.246}
                y={27.056}
                width={17.124}
                height={11.174}
                rx={5.587}
            />
            <path d="M79.385 33.63c0 10.106-4.856 12.592-4.856 12.592-2.3.671-4.331-1.656-3.355-3.844l.025-.058s1.17-1.027 1.865-3.936c2.591 0 5.076-1.495 6.32-4.753Z" />
        </g>
        <rect
            x={36.734}
            y={63.392}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#C4CBD8"
        />
        <rect
            x={51.955}
            y={63.392}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#C4CBD8"
        />
        <rect
            x={67.176}
            y={78.613}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#C4CBD8"
        />
        <rect
            x={51.955}
            y={78.613}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#C4CBD8"
        />
        <rect
            x={36.734}
            y={78.613}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#C4CBD8"
        />
        <rect
            x={67.176}
            y={63.392}
            width={11.416}
            height={11.416}
            rx={1.268}
            fill="#982EA9"
        />
    </svg>
);

export default SuccessIllustration;
