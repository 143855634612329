import PropTypes from 'prop-types';
import React from 'react';
import './HoverCallToActionWrapperComponent.scss';

export default class HoverCallToActionWrapperComponent extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * main content
         */
        children: PropTypes.node,
        /**
         * the component to show when hovered
         */
        hoverComponent: PropTypes.node,
        /**
         * function from context to cause to hover
         */
        contextHoverFunction: PropTypes.func,
        /**
         * content hash of component
         */
        contentHash: PropTypes.string,
        /**
         * which component is hovered on according to context
         */
        contextHoveredComponent: PropTypes.string,
        /**
         * context function to close content
         */
        contextCloseHover: PropTypes.func,
        type: PropTypes.string,
        /**
         * context function for rendering overlay element based on hover element's position
         */
        setStandaloneHoverOverlay: PropTypes.func,
        /**
         * reference of stand alone hover element
         */
        standAloneHoverRef: PropTypes.object,
    };

    showHoverHandler = (e) => {
        this.props.contextHoverFunction(
            this.props.contentHash,
            this.props.type
        );
        if (typeof this.props.setStandaloneHoverOverlay === 'function') {
            this.props.setStandaloneHoverOverlay(
                this.props.hoverComponent,
                this.props.standAloneHoverRef
            );
        }
    };

    hideHoverHandler = () => {
        this.props.contextCloseHover();
    };

    render() {
        return (
            <div
                className="component-CallToAction-HoverCallToAction-HoverCallToActionWrapperComponent"
                onTouchStart={this.showHoverHandler}
                onMouseOver={this.showHoverHandler}
                onMouseLeave={this.hideHoverHandler}
                onTouchEnd={this.hideHoverHandler}
            >
                {this.props.children}
                {/* uncomment the next line to make hovers persist, and comment out the line following it*/}
                {/*{this.props.hoverComponent}*/}
                {this.props.contentHash ===
                    this.props.contextHoveredComponent &&
                !this.props.noLocalOverlay
                    ? this.props.hoverComponent
                    : null}
            </div>
        );
    }
}
