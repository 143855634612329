import PropTypes from 'prop-types';
import React from 'react';
import { PAGE_DISPLAY_MODE } from '../../../pages/constants';
import MessageBubble from '../../Elements/MessageBubble/MessageBubble';
import UserIconWithOverlay from '../../Elements/UserIconWithOverlay/UserIconWithOverlay';
import { messageTypes } from '../../Includes/variables';
import './MessageBlock.scss';
import UserImage from '../../Content/User/UserDisplay/UserImage/UserImage';

const emojiRegex = require('emoji-regex');
const emojiTextRegex = require('emoji-regex/text.js');

export default class MessageBlock extends React.PureComponent {
    constructor() {
        super();
        this.emojiRegex = emojiRegex();
        this.emojiTextRegex = emojiTextRegex();
    }

    addLineBreaks = (messageContent, text, i) => {
        return [...messageContent, text, <br key={`${text}-${i}`} />];
    };

    wrapEmoji = (messageContent, text, i, regex) => {
        const output = [];

        let startIndex = 0;
        let result = regex.exec(text);
        while (regex.lastIndex) {
            // protect against false positives caused by numbers.
            if (!/[0-9]/.test(result[0])) {
                output.push(text.slice(startIndex, result.index));
                output.push(
                    <span className="emoji" key={`emoji-${result[0]}-${i}`}>
                        {result[0]}
                    </span>
                );
                startIndex = regex.lastIndex;
            }
            result = regex.exec(text);
        }
        if (output.length) {
            output.push(text.slice(startIndex)); // append remainder of text
            return [...messageContent, ...output];
        }

        return [...messageContent, text];
    };

    findEmoji = (messageContent, text, i) =>
        this.wrapEmoji(messageContent, text, i, this.emojiRegex);

    findTextEmoji = (messageContent, text, i) =>
        this.wrapEmoji(messageContent, text, i, this.emojiTextRegex);

    renderMessageContent = () => {
        const { messageContent } = this.props;

        if (typeof messageContent === 'string') {
            const contentWithLineBreaks = messageContent
                .split('\n')
                .reduce(this.addLineBreaks, [])
                .reduce(this.findEmoji, [])
                .reduce(this.findTextEmoji, []);

            contentWithLineBreaks.pop(); // removes line break at the end
            return contentWithLineBreaks;
        }

        return messageContent;
    };

    renderUserProfile = (isCandidate) => {
        const {
            user,
            isMultiplePeopleTyping,
            src,
            enableHoverOver,
        } = this.props;

        if (isMultiplePeopleTyping) {
            return <div className="empty-logo-placeholder" />;
        }

        const layout = isCandidate ? 'candidate' : 'user';
        if (user && enableHoverOver) {
            return (
                <div className="user-icon-container icon-group-chat-style">
                    <UserIconWithOverlay
                        targetUser={user}
                        userIconSize={24}
                        className="user-icon"
                        noLocalOverlay
                        showVipBadge={false}
                        layoutType={layout}
                    />
                </div>
            );
        }
        if (src && !isCandidate) {
            return (
                <div className="user-icon-container icon-group-chat-style">
                    <UserImage
                        image={{
                            src,
                            alt: 'user-logo',
                        }}
                        iconSize={22}
                    />
                </div>
            );
        }

        return (
            <div className="user-icon-container icon-group-chat-style">
                <UserImage
                    image={{
                        src: '/v1/avatar_black_160_vtg7lw.svg',
                        alt: 'user-logo',
                    }}
                    iconSize={22}
                />
            </div>
        );
    };

    getMessageBodyColor = (
        isUserYourself,
        messageType,
        isCandidate,
        isRecruiterView
    ) => {
        if (isUserYourself) return 'gray-bubble';
        if (messageType === messageTypes.EMPLOYER) {
            if (isRecruiterView && !isCandidate) {
                // Could be multiple recruiters in the chat, all of whom get the self-message treatment.
                return 'gray-bubble';
            }

            return 'blue-bubble';
        }
        if (messageType === messageTypes.GROUP) return 'pink-bubble';
        if (messageType === messageTypes.COMMUNITY) return 'purple-bubble';
        return 'purple-bubble';
    };

    getHorizontalPosition = (isUserYourself, isRecruiterView, isCandidate) => {
        if (isRecruiterView) {
            if (isCandidate) {
                return 'left';
            }
            return 'right';
        }

        if (isUserYourself) {
            return 'right';
        }
        return 'left';
    };

    render() {
        const {
            cssClass,
            username,
            timeStamp,
            messageId,
            authorId,
            shouldDisableDelete,
            messageType,
            isLoggedInUser,
            isRecruiterChatCandidate,
            user,
            pageDisplayMode,
            candidateUserName,
        } = this.props;

        const isUserYourself = isLoggedInUser(authorId);
        const isCandidate = user && isRecruiterChatCandidate(user.userId);
        const isRecruiterView =
            pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT;
        const messageBodyColor = this.getMessageBodyColor(
            isUserYourself,
            messageType,
            isCandidate,
            isRecruiterView
        );

        // TODO: update when groupChatRoom is ready
        const horizontalPosition = this.getHorizontalPosition(
            isUserYourself,
            isRecruiterView,
            isCandidate
        );
        const flaggedHorizontalPosition = `flag-${horizontalPosition}`;
        const _username = user?.isCommunityManager
            ? 'FGB Community Manager'
            : (isCandidate && candidateUserName) || username;
        return (
            <div
                className={`component-Messages-MessageBlock ${horizontalPosition} ${flaggedHorizontalPosition}`}
            >
                {this.renderUserProfile(isCandidate)}
                <div className="content-title">
                    <div className="message-meta">
                        <span className="username">{_username}</span>
                        <span className="user-title">&nbsp;{timeStamp}</span>
                    </div>

                    <MessageBubble
                        cssClass={`bubble-max-width ${cssClass}`}
                        bubbleBackgroundColor={messageBodyColor}
                        bubbleTipVerticalPosition="bottom"
                        bubbleTipHorizontalPosition={horizontalPosition}
                        authorId={authorId}
                        messageId={messageId}
                        shouldDisableDelete={shouldDisableDelete}
                    >
                        <span>{this.renderMessageContent()}</span>
                    </MessageBubble>
                </div>
            </div>
        );
    }
}

MessageBlock.propTypes = {
    /**
     * custom css class
     */
    cssClass: PropTypes.string,
    /**
     * username of user's message
     */
    username: PropTypes.string,
    /**
     * user's role
     */
    user: PropTypes.shape({
        isCommunityManager: PropTypes.bool,
        userId: PropTypes.string,
    }),
    /**
     * content of message in block, can be string or element
     */
    messageContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /**
     * src of user logo
     */
    src: PropTypes.string,
    /**
     * string of messageId
     */
    messageId: PropTypes.string,
    /**
     * string of authorId
     */
    authorId: PropTypes.string,
    /**
     * boolean whether it's a group typing and multiple people are typing
     */
    isMultiplePeopleTyping: PropTypes.bool,
    /**
     * boolean to disable delete functionality for right rail preview
     */
    shouldDisableDelete: PropTypes.bool,
    /**
     * flag to enable user icon hoverover
     */
    enableHoverOver: PropTypes.bool,
    /**
     * Function to determine whether user is a job candidate, requires user ID as an arg
     */
    isRecruiterChatCandidate: PropTypes.func,
    /**
     * Determines whether we're in a recruiter chat room
     */
    pageDisplayMode: PropTypes.string,
    /**
     * professionalUsername from channelData, if this is a recruiter chat
     */
    candidateUserName: PropTypes.string,
    messageType: PropTypes.string,
    isLoggedInUser: PropTypes.func,
    timeStamp: PropTypes.string,
};

MessageBlock.defaultProps = {
    cssClass: '',
    messageContent: '',
    username: 'Anonymous',
    user: null,
    isMultiplePeopleTyping: false,
    shouldDisableDelete: false,
    src: null,
    messageId: null,
    authorId: null,
    messageType: '',
    candidateUserName: null,
    pageDisplayMode: null,
    enableHoverOver: true,
    isLoggedInUser: () => {},
    isRecruiterChatCandidate: () => false,
    timeStamp: null,
};
