import { rangeInclusive } from '../../../../../utilities/helpers/number';

const currentYear = new Date().getFullYear();

export const yearDropdownOpts = rangeInclusive(1960, currentYear)
    .reverse()
    .map((v) => ({ value: v, label: v.toString() }));

export const filterYearDropdownOpts = (startYear = '') => {
    if (!startYear) return yearDropdownOpts;

    const intYear =
        typeof startYear === 'string' ? parseInt(startYear, 10) : startYear;

    return rangeInclusive(intYear, currentYear)
        .reverse()
        .map((v) => ({ value: v, label: v.toString() }));
};

export const monthDropdownOpts = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
].map((month, i) => ({ value: i + 1, label: month }));
