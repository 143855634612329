import PropTypes from 'prop-types';
import React from 'react';

import './CommonCardShell.scss';

export default class CommonCardShell extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
        as: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        className: '',
        as: 'section',
        style: {},
    };

    render() {
        const Container = this.props.as;
        return (
            <Container
                className={`component-Layout-CommonCardShell ${this.props.className}`}
                style={this.props.style}
            >
                {this.props.children}
            </Container>
        );
    }
}
