import PropTypes from 'prop-types';
import React from 'react';
import {
    CAN_SUBMIT,
    CANT_SUBMIT,
    SUBMIT,
} from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import ButtonCard from '../../QuestionCards/ButtonCard/ButtonCard';

import './CeoGenderDiversity.scss';

export default class CeoGenderDiversity extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        onAnswer: PropTypes.func,
        nextStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        onAnswer: () => {},
        buttonData: [
            {
                title: 'Yes',
                value: 'yes',
            },
            {
                title: 'No',
                value: 'no',
            },
            {
                title: 'Unsure',
                value: 'unsure',
            },
        ],
    };

    handleUpdate = (data) => {
        const response = data[0];
        const answer = {
            ceoGenderDiversity: response,
        };

        const answerAction =
            typeof response !== 'undefined' ? SUBMIT : CANT_SUBMIT;
        this.props.onAnswer(
            'ceoGenderDiversity',
            answer,
            answerAction,
            false,
            this.props.nextStep
        );
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        if (typeof this.props.ceoGenderDiversity !== 'undefined') {
            const answer = {
                ceoGenderDiversity: this.props.ceoGenderDiversity,
            };

            this.props.onAnswer(
                'ceoGenderDiversity',
                answer,
                CAN_SUBMIT,
                false,
                this.props.nextStep
            );
        }
    }
    render() {
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-CeoGenderDiversity">
                <ButtonCard
                    {...this.props}
                    onClick={this.handleUpdate}
                    selectedItems={this.props.ceoGenderDiversity}
                />
            </div>
        );
    }
}
