import PropTypes from 'prop-types';
import React from 'react';
import { truncateWithEllipsis } from '../../../utilities/ui_utilities';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';

import './DropdownWithIcon.scss';

export const injectedTransform = {
    icon: 'c_lpad,w_36,h_36',
};

export default class DropdownWithIcon extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            showDropdown: false,
            hoveredSelection: 0,
            selection: props.selection || null,
            options: props.items || [],
        };
    }

    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                logo: PropTypes.shape({
                    src: PropTypes.string,
                    alt: PropTypes.string,
                }),
                name: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
            })
        ),
        onSelect: PropTypes.func,
        selection: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        }),
    };

    static defaultProps = {
        placeholder: 'Select...',
    };

    toggleDropdown = () => {
        this.setState((prevState) => {
            return { showDropdown: !prevState.showDropdown };
        });
    };

    handleSelect = (selection) => {
        this.setState(
            {
                selection,
                showDropdown: false,
            },
            () => this.props.onSelect(this.state.selection)
        );
    };

    renderOptionsSection = (item) => {
        const { logo } = item;
        return (
            <button
                className="suggestion-option"
                onClick={() => this.handleSelect(item)}
                key={item.name}
            >
                {logo && logo.src && (
                    <img
                        className={'selection-icon'}
                        alt={logo.alt}
                        src={buildImagePath(
                            logo.src,
                            'icon',
                            injectedTransform
                        )}
                    />
                )}
                <div className="option-text">{item.name}</div>
            </button>
        );
    };

    render() {
        const disabledStyling = this.props.disabled ? 'disabled-styling' : '';
        const ArrowClass =
            this.state.selection &&
            this.state.selection.name &&
            this.state.selection.length
                ? 'no-arrow-mobile'
                : '';
        const selectedText =
            (this.state.selection && this.state.selection.name) ||
            this.props.placeholder;
        const icon = this.state.selection && this.state.selection.logo;
        return (
            <div
                className={`component-Input-DropdownWithIcon ${ArrowClass} ${disabledStyling}`}
            >
                <button
                    onClick={this.toggleDropdown}
                    className={`selection-display`}
                    disabled={this.props.disabled}
                >
                    {icon && icon.src && (
                        <img
                            className={'selection-icon'}
                            alt={icon.alt}
                            src={buildImagePath(
                                icon.src,
                                'icon',
                                injectedTransform
                            )}
                        />
                    )}
                    <div className="option-text">
                        {truncateWithEllipsis(selectedText, 50)}
                    </div>
                </button>
                {this.state.showDropdown ? (
                    <section className="options-section">
                        {this.state.options.map((item) =>
                            this.renderOptionsSection(item)
                        )}
                    </section>
                ) : null}
            </div>
        );
    }
}
