import React from 'react';
import PropTypes from 'prop-types';

import { useFlowControls } from './FlowShell/controls';

export const FlowStep = ({ children, name }) => {
    const { step, tree } = useFlowControls();

    // when rendered, ensure this step is defined in the schema tree
    React.useEffect(() => {
        if (!Object.keys(tree).includes(name))
            console.warn(
                `Step component with name ${name} is not found in controller step tree schema!`
            );
    }, [name, tree]);

    return <React.Fragment>{step === name ? children : null}</React.Fragment>;
};

FlowStep.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

FlowStep.displayName = 'FlowStep';

export default React.memo(FlowStep);
