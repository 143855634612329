import PropTypes from 'prop-types';
import React from 'react';

import './FlexPopup.scss';

export default class FlexPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
        };
    }

    static propTypes = {
        /**
         * contents to be rendered as popup content
         */
        children: PropTypes.node,
        /**
         * display logic for popup
         */
        hidden: PropTypes.bool,
        /**
         * function to handle popup close
         */
        onClose: PropTypes.func,
        /**
         * function to handle popup open
         */
        onOpen: PropTypes.func,
        /**
         * on/off switch for modal scroll
         */
        allowScroll: PropTypes.bool,
        closeOnOutsideClick: PropTypes.bool,
    };

    static defaultProps = {
        closeOnOutsideClick: true,
        allowScroll: false,
        children: <span></span>,
        hidden: true,
        onClose: null,
        onOpen: () => {},
    };

    componentDidMount() {
        if (!this.props.hidden && !this.props.allowScroll) {
            document.querySelector('body').classList.add('flex-popup-open');
        }

        this.setPositionTop();
        this.props.onOpen();
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('flex-popup-open');
    }

    setPositionTop = () => {
        const offsetY = 100; //@TODO this should probably be calculated based on viewport dimensions
        const top = window.pageYOffset + offsetY;

        if (!this.props.hidden && this.props.allowScroll) {
            this.setState({
                top: top,
            });
        }
    };

    render() {
        const hiddenClass = this.props.hidden ? 'hidden' : '';
        let style = {};

        if (this.props.allowScroll) {
            const top = this.state.top;
            style = { top: `${top}px` };
        }

        return (
            <div className={`component-Elements-FlexPopup ${hiddenClass}`}>
                <div className="child">
                    <div
                        className="modal-scroll"
                        onClick={
                            this.props.closeOnOutsideClick
                                ? this.props.onClose
                                : () => {}
                        }
                    >
                        <div
                            className="modal-dialog-box"
                            onClick={(event) => event.stopPropagation()}
                        >
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
