import PropTypes from 'prop-types';
import React from 'react';

import './TruncatableText.scss';

export default class TruncatableText extends React.PureComponent {
    constructor(props) {
        super(props);

        this.baseClassName = 'component-Elements-TruncatableText';
    }
    static propTypes = {
        /**
         * whether displayText is truncated or full
         */
        isDisplayTextTruncated: PropTypes.bool.isRequired,
        /**
         * text that will be displayed as main content
         */
        displayText: PropTypes.string.isRequired,
        /**
         * function that triggers on clicking "show more" and "show less" button/text
         */
        handleShowMoreOrLessClick: PropTypes.func.isRequired,
        /**
         * text that will be displayed on "show more" button, the button will not be render if the value is falsey
         */
        showMoreBtnText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        /**
         * text that will be displayed on "show less" button, the button will not be render if the value is falsey
         */
        showLessBtnText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        /**
         * index position of where displayText will be truncated
         */
        truncatedAtIndex: PropTypes.number,
        /**
         * text to be displayed after truncation
         */
        textAfterTruncation: PropTypes.string,
        /**
         * styling that will override default
         */
        className: PropTypes.string,
    };

    static defaultProps = {
        isDisplayTextTruncated: true,
        displayText: '',
        handleShowMoreOrLessClick: () => {},
        showMoreBtnText: 'Read more',
        showLessBtnText: '',
        truncatedAtIndex: 479,
        textAfterTruncation: ' ...',
        className: '',
    };

    renderTruncatableText = () => {
        const {
            displayText,
            truncatedAtIndex,
            textAfterTruncation,
        } = this.props;
        if (displayText.length > truncatedAtIndex) {
            /*
             * truncate display text at truncatedAtIndex if the length of first word is longer than truncatedAtIndex,
             * otherwise truncated at the last word without punctuations before truncatedAtIndex.
             */
            const lastSpaceIndex = displayText
                .slice(0, truncatedAtIndex)
                .lastIndexOf(' ');
            const isFirstWordLongerThanTruncatedAtIndex =
                lastSpaceIndex === -1 || lastSpaceIndex > truncatedAtIndex;
            return displayText
                .slice(
                    0,
                    isFirstWordLongerThanTruncatedAtIndex
                        ? truncatedAtIndex
                        : lastSpaceIndex
                )
                .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]\s*$/, '')
                .concat(textAfterTruncation);
        }
        return displayText;
    };

    render() {
        const {
            isDisplayTextTruncated,
            handleShowMoreOrLessClick,
            truncatedAtIndex,
            showMoreBtnText,
            showLessBtnText,
            displayText,
            className,
        } = this.props;
        const showMoreOrLessBtn = displayText.length > truncatedAtIndex;
        return (
            <div className={`${this.baseClassName} ${className}`}>
                <span className={`${this.baseClassName}__main-content`}>
                    {isDisplayTextTruncated
                        ? this.renderTruncatableText()
                        : displayText}
                </span>
                {showMoreOrLessBtn && (
                    <span
                        className={`${this.baseClassName}__show-hide-text`}
                        onClick={handleShowMoreOrLessClick}
                    >
                        {isDisplayTextTruncated
                            ? showMoreBtnText
                            : showLessBtnText}
                    </span>
                )}
            </div>
        );
    }
}
