import React from 'react';
import Pixel from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import EmailsLayout from '../../../InvitationWidget/EmailsLayout/EmailsLayout';
import MessageLayout from '../../../InvitationWidget/MessageLayout/MessageLayout';
import GroupsAdminOnboardingConfirmationScreen from './GroupsAdminOnboardingConfirmationScreen/GroupsAdminOnboardingConfirmationScreen';
import GroupsAdminOnboardingSocial from './GroupsAdminOnboardingSocial/GroupsAdminOnboardingSocial';
import GroupsAdminOnboardingUserSearch from './GroupsAdminOnboardingUserSearch/GroupsAdminOnboardingUserSearch';
import GroupsAdminOnboardingWelcomeScreen from './GroupsAdminOnboardingWelcomeScreen/GroupsAdminOnboardingWelcomeScreen';

export default [
    {
        hasClose: true,
        hasDotNavigation: false,
        description: '',
        component: (rest) => (
            <React.Fragment>
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
                <GroupsAdminOnboardingWelcomeScreen {...rest} />
            </React.Fragment>
        ),
        hasSkip: false,
        hasNext: false,
        hasFinish: false,
    },

    {
        hasClose: true,
        hasDotNavigation: true,
        description: "Find FGB'ers to invite to your group.",
        component: (rest) => (
            <React.Fragment>
                <GroupsAdminOnboardingUserSearch {...rest} />
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
            </React.Fragment>
        ),
        hasSkip: true,
        hasNext: true,
        hasFinish: false,
    },

    {
        hasClose: true,
        hasDotNavigation: true,
        description: 'Invite people to your group via email.',
        component: React.forwardRef((rest, ref) => (
            <React.Fragment>
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
                <EmailsLayout
                    {...rest}
                    onNext={rest.handleSubmitModalForm}
                    ref={ref}
                />
            </React.Fragment>
        )),
        hasSkip: true,
        hasNext: true,
        hasFinish: false,
    },

    {
        hasClose: true,
        hasDotNavigation: true,
        description: 'Write a note.',
        component: React.forwardRef((rest, ref) => (
            <React.Fragment>
                <MessageLayout
                    {...rest}
                    onNext={rest.handleSubmitModalForm}
                    ref={ref}
                    image={rest.group.logo}
                    title={rest.group.name}
                />
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
            </React.Fragment>
        )),
        hasSkip: true,
        hasNext: true,
        hasFinish: false,
    },

    {
        hasClose: true,
        hasDotNavigation: true,
        description: 'Other ways to share your group.',
        component: (rest) => (
            <React.Fragment>
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
                <GroupsAdminOnboardingSocial {...rest} />
            </React.Fragment>
        ),
        hasSkip: true,
        hasNext: true,
        hasFinish: false,
    },

    {
        hasClose: true,
        hasDotNavigation: true,
        description: "You're done! Join other group leaders.",
        component: (rest) => (
            <React.Fragment>
                <GroupsAdminOnboardingConfirmationScreen {...rest} />
                <Pixel
                    metrics={{
                        misc_event_type: `admin-onboarding-step-${rest.selectedStepIndex}-impression`,
                        misc_event_count: 1,
                    }}
                />
            </React.Fragment>
        ),
        hasSkip: false,
        hasNext: false,
        hasFinish: true,
    },
];
