import React from 'react';
import PropTypes from 'prop-types';

import ReactRouterLink from '../../ReactRouterLink';
import './GenericButton.scss';

// TODO break into individual components
const GenericButton = ({
    title,
    url,
    onClick,
    className,
    key,
    samePage,
    hash,
    isReactRouterLink,
}) => {
    if (url) {
        if (isReactRouterLink && samePage) {
            return (
                <ReactRouterLink to={url} groupHash={hash} key={key}>
                    <div
                        className={`item-button ${className} component-Groups-Elements-GenericButton`}
                        key={key}
                        onClick={onClick}
                        onKeyPress={onClick}
                        role="button"
                        tabIndex={0}
                    >
                        {title}
                    </div>
                </ReactRouterLink>
            );
        }
        return (
            <a
                className="component-Groups-Elements-GenericButton item-button-link"
                key={key}
                href={url}
                onClick={onClick}
            >
                <div className={`item-button ${className}`}>{title}</div>
            </a>
        );
    }

    if (onClick) {
        return (
            <button
                type="button"
                className={`component-Groups-Elements-GenericButton item-button ${className}`}
                key={key}
                onClick={onClick}
            >
                {title}
            </button>
        );
    }

    return null;
};

GenericButton.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    samePage: PropTypes.bool.isRequired,
    hash: PropTypes.string.isRequired,
    isReactRouterLink: PropTypes.bool,
};

GenericButton.defaultProps = {
    isReactRouterLink: true,
};

GenericButton.displayName = 'GenericButton';

export default React.memo(GenericButton);
