import React from 'react';
import JoinedGroup from '../../components/Groups/JoinedGroup/JoinedGroup';
import RecommendedGroup from '../../components/Groups/RecommendedGroup/RecommendedGroup';

import ConnectedUser from '../../components/Network/ConnectedUser/ConnectedUser';
import FollowedUser from '../../components/Network/FollowedUser/FollowedUser';
import InvitedUser from '../../components/Network/InvitedUser/InvitedUser';
import PendingGroupRequest from '../../components/Network/PendingGroupRequest/PendingGroupRequest';
import PendingUserRequest from '../../components/Users/PendingUserRequest/PendingUserRequest';
import RecommendedUser from '../../components/Users/RecommendedUser/RecommendedUser';
import UserIcon from '../../components/Users/UserIconList/UserIcon/UserIcon';

export const API_CALLS = {
    GET_JOINED_GROUPS: '/api/network/group/group-memberships',
    GET_PENDING_REQUESTS: '/api/network/connection/pending-connections',
    GET_INVITATIONS: '/api/network/connection/invitations',
    GET_FOLLOWED_USERS: '/api/network/connection/following-users',
    GET_CONNECTED_USERS: '/api/network/connection/connections',
    GET_RECOMMENDED_USERS: '/api/recommendation/connections',
    GET_PENDING_GROUP_REQUESTS: '/api/network/group/pending-invitations',
    GET_RECOMMENDED_GROUPS: '/api/network/group/recommended-groups',
    GET_CONNECTION_SEARCH: '/api/network/connection/search/',
};

export const NETWORK_CARD_TYPE = {
    CONNECTED_USERS: 'CONNECTED_USERS',
    JOINED_GROUPS: 'JOINED_GROUPS',
    INVITED_USERS: 'INVITED_USERS',
    FOLLOWED_USERS: 'FOLLOWED_USERS',
    PENDING_REQUESTS: 'PENDING_REQUESTS',
    PENDING_GROUP_REQUESTS: 'PENDING_GROUP_REQUESTS',
    RECOMMENDED_USERS: 'RECOMMENDED_USERS',
    RECOMMENDED_GROUPS: 'RECOMMENDED_GROUPS',
};

export const buildQueryParams = (resultsPerPage = 3, pageNumber = 1) => {
    return `?resultsPerPage=${resultsPerPage}&page=${pageNumber}`;
};

export const EMPTY_STATE_IMAGES = {
    [NETWORK_CARD_TYPE.CONNECTED_USERS]: 'v1565816245/Network/members.svg',
    [NETWORK_CARD_TYPE.JOINED_GROUPS]: 'v1565816245/Network/groups.svg',
    [NETWORK_CARD_TYPE.INVITED_USERS]: 'v1565816245/Network/requests.svg',
    [NETWORK_CARD_TYPE.FOLLOWED_USERS]: 'v1565816245/Network/following.svg',
};

/**
 * @param {object} connectedUser
 * @param {integer} index
 */
export const renderConnectedUser = (updateRealTimeCount) => (
    connectedUser,
    index
) => {
    return (
        <ConnectedUser
            key={`${index}-${connectedUser.userId}`}
            connectedUser={connectedUser.user}
            index={index}
            callbackFn={updateRealTimeCount}
        />
    );
};

/**
 * @param {object} connectedUser
 * @param {integer} index
 */
export const renderConnectedUserIcons = () => (connectedUser, index) => {
    return (
        <UserIcon
            key={`connectedUserIcon-${index}`}
            user={connectedUser.user}
        />
    );
};

/**
 * @param {function} handlePendingRequest
 * @param {object} request
 * @param {integer} index
 */
export const renderPendingRequest = (callbackFn) => (request, index) => {
    return (
        <li key={`request-${index}`}>
            <PendingUserRequest
                user={request.user}
                bubbleMessage={request.message}
                shouldShowTooltip={true}
                className="network-item"
                requestType="USER_CONNECTION"
                setClickedAction={callbackFn}
                clickedAction={request.clickedAction}
                haveHoverComponent={true}
            />
        </li>
    );
};

/**
 * @param {object} invitation
 * @param {integer} index
 */
export const renderInvitedUser = (updateRealTimeCount) => (
    invitation,
    index
) => {
    return (
        <InvitedUser
            key={`invitation-${index}`}
            invitedUser={invitation.user}
            callbackFn={updateRealTimeCount}
        />
    );
};

/**
 * @param {object} followedUser
 * @param {integer} index
 */
export const renderFollowedUser = (updateRealTimeCount) => (
    followedUser,
    index
) => {
    return (
        <FollowedUser
            key={`followedUser-${index}`}
            callbackFn={updateRealTimeCount}
            followedUser={followedUser}
        />
    );
};

/**
 * @param {boolean} shouldShowCtaButtons
 * @param {object} group
 * @param {integer} index
 */
export const renderJoinedGroup = (
    shouldShowCtaButtons,
    updateRealTimeCount
) => (joinedGroup, index) => {
    return (
        <JoinedGroup
            key={`group-${index}`}
            joinedGroup={joinedGroup.group}
            userRelation={joinedGroup.userRelation}
            shouldShowCtaButtons={shouldShowCtaButtons}
            callbackFn={updateRealTimeCount}
        />
    );
};

/**
 * @param {object} request
 * @param {integer} index
 */
export const renderPendingGroupRequest = (callbackFn) => (request, index) => {
    return (
        <PendingGroupRequest
            key={`group-request-${index}`}
            pendingGroup={request.group}
            user={request.user}
            message={request.message}
            callbackFn={callbackFn}
            clickedAction={request.clickedAction}
        />
    );
};

/**
 * @param {object} recommendation
 * @param {integer} index
 */
export const renderRecommendedUser = (callbackFn) => (
    recommendation,
    index
) => {
    return (
        <RecommendedUser
            key={`recommendedUser-${index}`}
            recommendedUser={recommendation.user}
            connectionType={recommendation.type}
            clickedAction={recommendation.clickedAction}
            callbackFn={callbackFn}
        />
    );
};

/**
 * @param {function} callbackFn
 * @param {object} recommendation
 * @param {integer} index
 */
export const renderRecommendedGroup = (callbackFn) => (
    recommendation,
    index
) => {
    return (
        <RecommendedGroup
            key={`recommendation-${index}`}
            recommendedGroup={recommendation}
            callbackFn={callbackFn}
            clickedAction={recommendation.clickedAction}
            userRelation={recommendation.userRelation}
        />
    );
};
