import axios from 'axios';
import {
    EVENT_REQUIREMENTS_INPUT_STEP_NAME,
    CONFIRM_SUCCESS_STEP_NAME,
    QUICK_PROFILE_STEP_NAME,
    SELECT_TIME_STEP_NAME,
    defaultStepFormData,
} from '../Steps/constants';
// eslint-disable-next-line import/no-cycle
import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';
import { isEmpty } from '../../../../utilities/helperFunctions';
import { validateQuickProfilePayload } from '../../../Includes/FormSchema/UserProfileSchema';
import { validateEventRegistrationForm } from '../../../Includes/FormSchema/EventSchema';

const flowTree = {
    [QUICK_PROFILE_STEP_NAME]: [
        EVENT_REQUIREMENTS_INPUT_STEP_NAME,
        SELECT_TIME_STEP_NAME,
        CONFIRM_SUCCESS_STEP_NAME,
    ],
    [EVENT_REQUIREMENTS_INPUT_STEP_NAME]: [
        SELECT_TIME_STEP_NAME,
        CONFIRM_SUCCESS_STEP_NAME,
    ],
    [SELECT_TIME_STEP_NAME]: [CONFIRM_SUCCESS_STEP_NAME],
    [CONFIRM_SUCCESS_STEP_NAME]: [],
};

const webinarFlowTree = {
    [QUICK_PROFILE_STEP_NAME]: [CONFIRM_SUCCESS_STEP_NAME],
    [CONFIRM_SUCCESS_STEP_NAME]: [],
};

const stepList = Object.keys(flowTree);

const defaultFlowState = stepList.map((step) => defaultStepFormData[step]);

const buildDefaultRegFlowState = (stepData = defaultFlowState) =>
    buildInitialFormState(stepList, stepData);

export const checkHasRequirements = (obj = null) => {
    if (!obj || isEmpty(obj)) return false;
    // determine if any properties in requirements object are true/truthy
    return Object.entries(obj).some(
        // eslint-disable-next-line no-unused-vars
        ([k, v]) => v !== null && (v || v.length > 0)
    );
};

export const buildRegFlow = (initializeOptions) => {
    const { startStep, sourceType } = initializeOptions;

    const validators = {
        quickProfile: validateQuickProfilePayload,
        eventRequirementsInput: validateEventRegistrationForm,
    };

    // use requiredsteps to prune default flow tree?

    return {
        flowTree:
            sourceType === 'vfairs' || sourceType === 'hopin vcf'
                ? flowTree
                : webinarFlowTree,
        initialStep: startStep,
        initialFlowData: { validators, ...initializeOptions },
    };
};

export const formatPreferredTimeProps = (times) =>
    times === null
        ? null
        : times.map((t) => ({
              label: t.option_text,
              value: t.id,
          }));

const registrationFlowAPI = {
    submitQuickProfile: async (payload = {}) => {
        const endpoint = '/api/user/profile';

        try {
            const response = await axios.patch(endpoint, payload);
            const { data } = response;
            if (data.meta.success) return { ok: true, errors: null };
        } catch (error) {
            return { ok: false, errors: error };
        }
    },
    submitEventRegistration: async ({ eventHash, registerPayload = {} }) => {
        if (!eventHash)
            return {
                ok: false,
                data: null,
                error: 'no event registration hash',
            };

        const url = `/api/event/${eventHash}`;

        try {
            const response = await axios.post(url, { ...registerPayload });
            const { joinUrl, eventPreferredTimes } = response.data;
            return {
                ok: true,
                data: {
                    joinUrl,
                    eventPreferredTimes: formatPreferredTimeProps(
                        eventPreferredTimes
                    ),
                },
                error: null,
            };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
    submitPreferredTime: async (id, eventHash) => {
        const endpoint = `/api/event/${eventHash}/set-preferred-time`;

        try {
            await axios.post(endpoint, { eventPreferredTimeId: id });
            return true;
        } catch (e) {
            return false;
        }
    },
};

export const registrationFlow = {
    flowTree,
    stepList,
    defaultFlowState,
    buildDefaultRegFlowState,
    registrationFlowAPI,
};
