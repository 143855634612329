import PropTypes from 'prop-types';
import React from 'react';
import {
    Consumer as CompanyConsumer,
    Provider as CompanyProvider,
} from '../../../../contexts/CompanyContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';

import Button from '../../../Input/Button/Button';
import ListItem from '../../../Layout/ListSection/ListItem/ListItem';
import ListSection from '../../../Layout/ListSection/ListSection';
import TypeaheadInputCard from '../../../ReviewSurvey/QuestionCards/TypeaheadInputCard/TypeaheadInputCard';
import ModalAbstraction from '../ModalAbstraction/ModalAbstraction';
import './CompaniesModal.scss';

export default class CompaniesModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            companyTypeaheadValue: null,
            reset: false,
        };
    }

    static propTypes = {
        /**
         * companies a user follows
         */
        companies: PropTypes.arrayOf(
            PropTypes.shape({
                notificationId: PropTypes.number,
                companyName: PropTypes.string,
                companyUrl: PropTypes.string,
            })
        ),
        /**
         * a total company count
         */
        companyCount: PropTypes.number,
        /**
         * display logic for modal
         */
        showModal: PropTypes.bool,
        /**
         * function to handle updates to collowed companies
         */
        onUpdate: PropTypes.func,
        /**
         * function to handle popup close
         */
        onClose: PropTypes.func,
    };

    static defaultProps = {
        companies: [],
        companyCount: 0,
        showModal: false,
        onUpdate: null,
        onClose: null,
    };

    clearInput = () => {
        this.setState({
            companyTypeaheadValue: '',
            reset: true,
        });
    };

    isValidInput = () => {
        return (
            this.state.companyTypeaheadValue &&
            this.state.companyTypeaheadValue.companyId &&
            this.state.companyTypeaheadValue.companyName
        );
    };

    followCompanyHandler = async (context) => {
        let { companies, companyCount } = this.props;
        const company = this.state.companyTypeaheadValue;

        if (this.isValidInput()) {
            const newCompanyNotification = await context.followCompany(
                company.companyId,
                null,
                false
            );

            const addedCompany = {
                notificationId: newCompanyNotification.notificationId,
                companyName: newCompanyNotification.companyName,
                companyUrl: newCompanyNotification.companyUrl,
            };

            companies = [...this.props.companies, addedCompany];
            companyCount++;
            this.clearInput();
            EmitMetric({
                misc_event_type: 'follow-company',
                misc_event_count: 1,
            });
        }

        const followingCompanies = {
            companies,
            companyCount,
        };
        this.props.onUpdate('followingCompanies', followingCompanies);
    };

    companyTypeaheadHandler = (companyTypeaheadValue) => {
        this.setState({
            companyTypeaheadValue,
            reset: false,
        });
    };

    renderCompaniesList = (context) => {
        let { companies, companyCount } = this.props;
        return (
            <ListSection title="companies you follow">
                {companyCount > 0 ? (
                    companies.map((company) => {
                        const companyProps = {
                            title: {
                                text: company.companyName,
                                url: company.companyUrl,
                            },
                            onClick: () => {
                                const unfollowedId = company.notificationId;
                                context.unfollowCompany(unfollowedId, null);
                                companies = this.props.companies.filter(
                                    (company) =>
                                        company.notificationId !== unfollowedId
                                );
                                companyCount--;
                                EmitMetric({
                                    misc_event_type: 'unfollow-company',
                                    misc_event_count: 1,
                                });
                                const followingCompanies = {
                                    companies,
                                    companyCount,
                                };
                                this.props.onUpdate(
                                    'followingCompanies',
                                    followingCompanies
                                );
                            },
                            button: {
                                text: 'Unfollow',
                            },
                        };
                        return (
                            <ListItem
                                {...companyProps}
                                key={company.notificationId}
                            />
                        );
                    })
                ) : (
                    <div className="not-following-div">
                        You're not following any companies.
                    </div>
                )}
            </ListSection>
        );
    };

    render() {
        const props = {
            inputType: 'default',
            defaultImg: {
                src: '/v1553090788/icons/company_2x.png',
                alt: '',
            },
            handleSelected: this.companyTypeaheadHandler,
            addOption: false,
            resetInput: this.state.reset,
        };
        return (
            <div className="component-CallToAction-LeftRailCTA-CompaniesModal">
                <ModalAbstraction
                    title="Your Companies"
                    description="Get alerted when there are new employee reviews."
                    showModal={this.props.showModal}
                    onClose={this.props.onClose}
                    allowScroll={true}
                >
                    <CompanyProvider>
                        <CompanyConsumer>
                            {(context) => {
                                return (
                                    <React.Fragment>
                                        <div className="input-section">
                                            <div className="label">
                                                Company Name*
                                            </div>
                                            <TypeaheadInputCard {...props} />
                                            <div className="button-follow-div">
                                                <Button
                                                    type="solid"
                                                    value="Follow"
                                                    onClick={() =>
                                                        this.followCompanyHandler(
                                                            context
                                                        )
                                                    }
                                                    disabled={
                                                        !this.isValidInput()
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {this.renderCompaniesList(context)}
                                    </React.Fragment>
                                );
                            }}
                        </CompanyConsumer>
                    </CompanyProvider>
                </ModalAbstraction>
            </div>
        );
    }
}
