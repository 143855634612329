import PropTypes from 'prop-types';
import React from 'react';

import './TextAreaInput.scss';

export default class TextAreaInput extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        /**
         * Name of InputField
         */
        name: PropTypes.string.isRequired,
        /**
         * border style: css border style object
         */
        customStyle: PropTypes.object,
        /**
         * Text to be displayed inside a button
         */
        placeholder: PropTypes.string,
        /**
         * onChange handler
         */
        onChange: PropTypes.func,
        /**
         * row number
         */
        rows: PropTypes.number,
        /**
         * col number
         */
        cols: PropTypes.number,
        /**
         * text to be displayed
         */
        value: PropTypes.string,
        /**
         * value for qa data attribute
         */
        qaDataAttr: PropTypes.string,
        /**
         * on focus function
         */
        onFocus: PropTypes.func,
        /**
         * refs
         */
        refs: PropTypes.func,
        /**
         * class
         */
        class: PropTypes.string,
        /**
         * id
         */
        id: PropTypes.string,
        /**
         * on blur functino
         */
        onBlur: PropTypes.func,
        defaultValue: PropTypes.string,
        /**
         * whether to use event or event.target.value for onChange
         */
        shouldUseEvent: PropTypes.bool,
        /**
         * registering submit on enter key
         */
        onKeyDown: PropTypes.func,
        enterKeyNewLine: PropTypes.bool,
        disabled: PropTypes.bool,
        hasError: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        customStyle: {},
        placeholder: '',
        onChange: () => {},
        rows: 3,
        cols: 50,
        shouldUseEvent: false,
        enterKeyNewLine: true,
        hasError: false,
    };

    handleKeyDown = (event) => {
        const keysNotPressed =
            !event.shiftKey && !event.ctrlKey && !event.altKey;
        if (event.key === 'Enter' && keysNotPressed) {
            if (!this.props.enterKeyNewLine) event.preventDefault();
            if (this.props.onKeyDown) this.props.onKeyDown();
        }
    };

    onChange = (e) => {
        if (this.props.shouldUseEvent) {
            this.props.onChange(e);
            return;
        }

        this.props.onChange(e.target.value);
    };

    render() {
        const { class: cn } = this.props;
        const disabledStyling = this.props.disabled ? 'disabled-styling' : '';
        const errorStyling = this.props.hasError ? ' hasError' : '';
        const textareaClassName = `${cn} ${errorStyling}`.trim();

        return (
            <div className={`component-Input-TextAreaInput ${disabledStyling}`}>
                <textarea
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    className={textareaClassName}
                    data-gramm_editor="false"
                    data-qa={this.props.qaDataAttr}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    style={this.props.customStyle}
                    rows={this.props.rows}
                    cols={this.props.cols}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    onFocus={this.props.onFocus}
                    ref={this.props.refs}
                    onKeyDown={this.handleKeyDown}
                />
            </div>
        );
    }
}
