import React from 'react';
import PropTypes from 'prop-types';

import InputField from '../../../Input/InputField/InputField';
import CharCount from '../../../Elements/CharCount/CharCount';
import LocationInput from '../../../Input/LocationInput/LocationInput';
import { CompanyTypeahead } from '../../../Input/CompanyTypeahead/CompanyTypeahead';
import CheckBox from '../../../Content/Elements/CheckBox/CheckBox';
import ErrorLabel from '../../../Elements/ErrorLabel/ErrorLabel';

import './ExperienceInput.scss';

export default class ExperienceInput extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        name: PropTypes.string,
        title: PropTypes.string,
        company: PropTypes.exact({
            companyId: PropTypes.number,
            companyName: PropTypes.string,
        }),
        location: PropTypes.exact({
            name: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        isRemote: PropTypes.bool,
        errors: PropTypes.exact({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            location: PropTypes.string,
        }).isRequired,
        updateExperience: PropTypes.func.isRequired,
    };

    static defaultProps = {
        name: '',
        title: '',
        company: {
            companyId: null,
            companyName: '',
        },
        location: {
            name: '',
            lat: null,
            lng: null,
        },
        isRemote: false,
    };

    render() {
        const {
            name,
            title,
            company,
            isRemote,
            errors,
            updateExperience,
            location,
        } = this.props;
        const { companyName } = company;

        return (
            <div className="component-Modals-QuickApplyModal-ExperienceInput">
                <section className="input-group">
                    <div className="input-group-label">
                        <label htmlFor="name" className="input-label">
                            Your name*
                        </label>
                        <CharCount
                            length={name ? name.length : 0}
                            limit={100}
                        />
                    </div>
                    <InputField
                        idValue="name"
                        autoComplete="off"
                        classname="name-input-field"
                        name="name-input"
                        inputValue={name}
                        onChange={updateExperience('name')}
                    />
                    <ErrorLabel error={errors.name} cssClass="field-error" />
                </section>
                <section className="input-group">
                    <div className="input-group-label">
                        <label htmlFor="title" className="input-label">
                            Most recent job title*
                        </label>
                        <CharCount
                            length={title ? title.length : 0}
                            limit={200}
                        />
                    </div>
                    <InputField
                        idValue="title"
                        autoComplete="off"
                        classname="job-title-input-field"
                        name="jobTitle-input"
                        inputValue={title}
                        onChange={updateExperience('title')}
                    />
                    <ErrorLabel error={errors.title} cssClass="field-error" />
                </section>
                <section className="input-group">
                    <div className="input-group-label-simple">
                        <label htmlFor="name" className="input-label">
                            Most recent company*
                        </label>
                    </div>
                    <CompanyTypeahead
                        placeholder="Start typing to select a company or add a new one"
                        items={companyName ? [companyName] : []}
                        handleSelect={updateExperience('company')}
                        addOption
                    />
                    <ErrorLabel error={errors.company} cssClass="field-error" />
                </section>
                <section className="input-group">
                    <div className="input-group-label-simple">
                        <label htmlFor="location" className="input-label">
                            Most recent job location*
                        </label>
                    </div>
                    <LocationInput
                        idValue="location"
                        placeholder="City, State"
                        classname="location-input-field"
                        name="location-input"
                        value={location.name}
                        disabled={isRemote}
                        onChange={updateExperience('location')}
                    />
                    <ErrorLabel
                        error={errors.location}
                        cssClass="field-error"
                    />
                </section>
                <CheckBox
                    id="isRemote"
                    defaultChecked={isRemote}
                    label="Remote"
                    handleChange={updateExperience('isRemote')}
                    className="remote-select"
                />
            </div>
        );
    }
}
