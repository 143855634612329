import PropTypes from 'prop-types';
import React from 'react';
import SVGIconWrapper from '../../../Icons/SVGIconWrapper/SVGIconWrapper';
import TextAreaInput from '../../../Input/TextAreaInput/TextAreaInput';
import Icons from '../../Icons/Icons';

import './MessageInput.scss';

export default class MessageInput extends React.PureComponent {
    static propTypes = {
        /**
         * value of text input
         */
        text: PropTypes.string.isRequired,
        /**
         * text area change handler
         */
        handleTextAreaChange: PropTypes.func.isRequired,
        /**
         * send message handler
         */
        handleSendMessage: PropTypes.func.isRequired,
        /**
         * error related to textarea
         */
        textAreaError: PropTypes.string,
        /**
         * whether is message is sending or not
         */
        isMessageSending: PropTypes.bool.isRequired,
        /**
         * set the current channel
         */
        setCurrentChannel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        text: '',
        handleTextAreaChange: () => {},
        handleSendMessage: () => {},
        textAreaError: null,
        isMessageSending: false,
        setCurrentChannel: () => {},
    };

    render() {
        const {
            text,
            handleTextAreaChange,
            handleSendMessage,
            textAreaError,
            isMessageSending,
            setCurrentChannel,
        } = this.props;

        return (
            <div className="component-Messages-Messages-MessageInput">
                <TextAreaInput
                    name="message-input"
                    placeholder="Write a message..."
                    class="message-textarea"
                    value={text}
                    shouldUseEvent={true}
                    onChange={handleTextAreaChange}
                    rows={3}
                    onKeyDown={handleSendMessage}
                    enterKeyNewLine={false}
                    onFocus={setCurrentChannel}
                />
                <SVGIconWrapper
                    handleClickSVGWrapper={handleSendMessage}
                    SVGWrapperCssClass={`send-message-icon ${
                        textAreaError || isMessageSending ? 'disabled' : ''
                    }`}
                >
                    <Icons name="send-message" />
                </SVGIconWrapper>
            </div>
        );
    }
}
