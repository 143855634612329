import PropTypes from 'prop-types';
import React from 'react';
import { Consumer as PageConsumer } from '../../../contexts/PageContext';
import { EmitMetric } from '../../Analytics/VisibilityPixel/VisibilityPixel';
import Button from '../../Input/Button/Button';

export default class CommunityInviteButton extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        /**
         * Invite type, determines resulting flow
         */
        inviteType: PropTypes.oneOf(['group', 'connection', null]),
        /**
         * User data, can be null, so is not marked as required
         */
        user: PropTypes.shape({
            username: PropTypes.string,
            userId: PropTypes.number,
            image: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            credential: PropTypes.string,
        }),
        /**
         * Group data. Can be null, so is not marked as required
         */
        group: PropTypes.shape({
            logo: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
            name: PropTypes.string,
            id: PropTypes.number,
            hash: PropTypes.string,
            description: PropTypes.string,
        }),
        /**
         * Function to be called on successful invite, if any
         */
        onSuccess: PropTypes.func,
        /**
         * Url to be redirected to after invite, if any
         */
        onCloseRedirectUrl: PropTypes.string,
        /**
         * css class to be added to button
         */
        className: PropTypes.string,
        /**
         * Text to display inside button, defaults to "Invite"
         */
        value: PropTypes.string,
        /**
         * Button style type
         */
        buttonType: PropTypes.string,
        /**
         * Text style type
         */
        inputStyle: PropTypes.string,
        /**
         * OnClick handler
         */
        onClick: PropTypes.func,
        /**
         * Disabled state
         */
        disabled: PropTypes.bool,
        /**
         * ModalTrigger: this is here in case we need to override the function supplied by the pageContext.
         * For testing or whatever the future requires.
         */
        modalTrigger: PropTypes.func,
        /**
         * Props for the Auth wrapper
         */
        callToActionHash: PropTypes.string,
        /**
         * misc event type metric to fire on click
         */
        metricEventType: PropTypes.string,
        loginTrigger: PropTypes.string,
        modalTriggerParameters: PropTypes.shape({
            modalName: PropTypes.string,
            modalData: PropTypes.object,
        }),
        userSearchMessage: PropTypes.string,
    };

    static defaultProps = {
        inviteType: null,
        user: null,
        group: null,
        onClick: null,
        disabled: false,
        onCloseRedirectUrl: null,
        className: '',
        value: 'Invite',
        buttonType: 'solid',
        inputStyle: 'community',
        onSuccess: () => {},
        modalTrigger: null,
        metricEventType: '',
    };

    handleClick(pageContext) {
        if (!pageContext.isLoggedIn()) {
            return null;
        }

        if (this.props.onClick !== null) {
            // if we've been given a different onclick handler, do that instead.
            // This essentially falls back to normal Button component behavior.
            this.props.onClick();
            return;
        }

        if (!pageContext.pageProps.session) {
            // if user is not logged in, do nothing.
            return;
        }

        const inviteData = {
            type: this.props.inviteType,
            user: this.props.user,
            group: this.props.group,
            onSuccess: this.props.onSuccess,
            onCloseRedirectUrl: this.props.onCloseRedirectUrl,
            modalTriggerParameters: this.props.modalTriggerParameters,
            message: this.props.userSearchMessage,
        };

        const { metricEventType } = this.props;

        if (metricEventType) {
            const metricPayload = {
                misc_event_type: metricEventType,
                misc_event_count: 1,
            };

            if (this.props.group) {
                metricPayload.community_group_hash = this.props.group.hash;
            }

            EmitMetric(metricPayload);
        }

        this.modalTrigger('Invitation', inviteData, pageContext);
    }

    modalTrigger(modalType, data, pageContext) {
        if (this.props.modalTrigger !== null) {
            return this.props.modalTrigger(modalType, data);
        }

        return pageContext.modalTrigger(modalType, data);
    }

    render() {
        const { className, value, buttonType, inputStyle } = this.props;

        return (
            <PageConsumer>
                {(pageContext) => {
                    return pageContext.wrapAuth(
                        this.props.callToActionHash,
                        <Button
                            className={`component-Community-CommunityInviteButton ${className}`}
                            value={value}
                            type={buttonType}
                            inputStyle={inputStyle}
                            onClick={() => this.handleClick(pageContext)}
                            disabled={this.props.disabled}
                        />,
                        { login_trigger: this.props.loginTrigger }
                    );
                }}
            </PageConsumer>
        );
    }
}
