import PropTypes from 'prop-types';
import React from 'react';
import FGBLogoCopyrightDisplay from '../Components/FGBLogoCopyrightDisplay/FGBLogoCopyrightDisplay';
import LeftRailExpandableMenuItem from '../Components/LeftRailExpandableMenuItem/LeftRailExpandableMenuItem';
import SocialMediaDisplay from '../Components/SocialMediaDisplay/SocialMediaDisplay';
import { PageContext } from '../../../../contexts/ContextCreator';
import { FOOTER_TYPES, socialIcons as rawSocialIcons } from '../constants';
import { serializeFooterProps } from '../../utils';
import { PAGE_DISPLAY_MODE } from '../../../../pages/constants';

import './LeftRailFooter.scss';

export default class LeftRailFooter extends React.PureComponent {
    constructor(props, context) {
        super(props);
        this.serializedFooterProps = serializeFooterProps({
            ...context.pageProps,
            pageDisplayMode: context.pageDisplayMode,
            footerType: FOOTER_TYPES.LEFT_RAIL_FOOTER,
            socialIcons: rawSocialIcons,
        });
    }

    static contextType = PageContext;

    render() {
        const {
            footerMenuItems,
            socialIcons,
            socialLikes,
        } = this.serializedFooterProps;
        const { pageDisplayMode } = this.context;
        const hideSocialMedia =
            pageDisplayMode === PAGE_DISPLAY_MODE.EMPLOYER_DEFAULT;
        const logoSpaceStyle = hideSocialMedia
            ? 'left-rail-logo-divider--top-space'
            : '';

        return (
            <div className="component-Layout-Footer-LeftRailFooter">
                {footerMenuItems.map((item) => {
                    if (item.children) {
                        return (
                            <LeftRailExpandableMenuItem
                                className="left-rail-footer-expandable-menu"
                                key={item.title}
                                parentItem={item.title}
                                submenuItems={item.children}
                            />
                        );
                    }
                    return (
                        <a
                            key={item.title}
                            href={item.url}
                            className={'menu-link'}
                        >
                            {item.title}
                        </a>
                    );
                })}

                {!hideSocialMedia && (
                    <SocialMediaDisplay
                        socialIcons={socialIcons}
                        socialLikes={socialLikes}
                    />
                )}

                <FGBLogoCopyrightDisplay
                    className={`left-rail-logo-divider ${logoSpaceStyle}`}
                />
            </div>
        );
    }
}
