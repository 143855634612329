import PropTypes from 'prop-types';
import React from 'react';
import { buildImagePath } from '../../JobComponents/Company/Image/logo';

import './KarmaScoreDisplay.scss';

export default class KarmaScoreDisplay extends React.PureComponent {
    render() {
        const injectedTransforms = {
            xSmall: 'c_crop',
        };
        const starPath = buildImagePath(
            '/v1559319835/SVGs/Star_karma.svg',
            'xSmall',
            injectedTransforms
        );
        return (
            <div
                className="component-Users-KarmaScoreDisplay"
                onMouseEnter={this.props.onMouseEnter}
            >
                <img width="12" height="18" alt="star-svg" src={starPath} />
                <div className="score-text">{this.props.karmaScore}</div>
            </div>
        );
    }
}

KarmaScoreDisplay.propTypes = {
    karmaScore: PropTypes.string,
    onMouseEnter: PropTypes.func,
};

KarmaScoreDisplay.defaultProps = {
    karmaScore: '10',
    onMouseEnter: () => {},
};
