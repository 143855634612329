import PropTypes from 'prop-types';
import { userProptype } from './userProps';

/**
 * Privacy setting for the group
 */
export const privacySettingPropTypes = PropTypes.oneOf([
    'open',
    'closed',
    'secret',
]);

export const GroupPropTypesShape = {
    /**
     * Long description of the group
     */
    aboutGroup: PropTypes.string.isRequired,
    /**
     * Whether or not contents are hidden from non-members
     */
    contentIsPrivate: PropTypes.bool,
    /**
     * Short description of the group
     */
    description: PropTypes.string.isRequired,
    /**
     * Hash of the group
     */
    hash: PropTypes.string.isRequired,
    /**
     * Banner image displayed in header
     */
    headerImage: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }).isRequired,
    /**
     * List of interests associated with the group
     */
    interests: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })
    ),
    /**
     * Question asked to users requesting to join the group
     */
    inviteQuestion: PropTypes.string,
    /**
     * Whether or not the group is located-based
     */
    isLocationBased: PropTypes.bool,
    /**
     * List of locations the group is based in
     */
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            longitude: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        })
    ),
    /**
     * Group logo
     */
    logo: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
    /**
     * # of members in the group
     */
    memberCount: PropTypes.number,
    /**
     * Name of the group
     */
    name: PropTypes.string.isRequired,
    /**
     * List of select group member profile pictures
     */
    previewMembers: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        })
    ),
    /**
     * Privacy setting for the group (open, closed, secret)
     */
    privacySetting: privacySettingPropTypes,
    /**
     * Rules for group membership
     */
    rules: PropTypes.string,
};

/**
 * type/role of the member
 */
export const memberTypeProptype = PropTypes.oneOf([
    'member',
    'owner',
    'admin',
    'moderator',
    'requested',
]);

/**
 * The list of group's member data
 */
export const memberProptype = PropTypes.shape({
    /**
     * the user data of a member
     */
    user: userProptype,
    /**
     * type/role of the member
     */
    groupMemberType: memberTypeProptype,
});

/**
 * The list of group's member data
 */
export const requestUserProptype = PropTypes.shape({
    /**
     * the user data of a member
     */
    user: userProptype,
    /**
     * user's answer to invite question for joining group
     */
    inviteQuestionAnswer: PropTypes.string,
});

/**
 * data of group members regarding paginations and member list
 */
export const memberResultsProptype = PropTypes.shape({
    /**
     * The list of group's member data
     */
    members: PropTypes.arrayOf(memberProptype),
    /**
     * starting index number of currently displaying member
     */
    startNum: PropTypes.number,
    /**
     * ending index number of currently displaying member
     */
    endNum: PropTypes.number,
    /**
     * total number of members in the group
     */
    total: PropTypes.number,
    /**
     * max number of members per page
     */
    resultsPerPage: PropTypes.number,
    /**
     * page number relative to the resultsPerPage
     */
    page: PropTypes.number,
});

/**
 * data of requesting to join user regarding paginations and member list
 */
export const requestUserResultsProptype = PropTypes.shape({
    /**
     * The list of requesting to join user data
     */
    users: PropTypes.arrayOf(requestUserProptype),
    /**
     * starting index number of currently displaying member
     */
    startNum: PropTypes.number,
    /**
     * ending index number of currently displaying member
     */
    endNum: PropTypes.number,
    /**
     * total number of members in the group
     */
    total: PropTypes.number,
    /**
     * max number of members per page
     */
    resultsPerPage: PropTypes.number,
    /**
     * page number relative to the resultsPerPage
     */
    page: PropTypes.number,
});

/**
 * object that contains both next page and prev page function/null
 */
export const memberListPaginationProptype = PropTypes.shape({
    /**
     * The list of member that
     */
    goToPrevPage: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    /**
     * starting index number of currently displaying member
     */
    goToNextPage: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
});

/**
 * object of a condensed group prop shape for content card
 */
export const GroupCondensedPropShape = {
    /**
     * string of short description
     */
    description: PropTypes.string,
    /**
     * string of group url to redirect user
     */
    groupUrl: PropTypes.string,
    /**
     * string of group hash
     */
    hash: PropTypes.string,
    /**
     * object of group logo
     */
    logo: PropTypes.shape({
        /**
         * string of alt
         */
        alt: PropTypes.string,
        /**
         * string of src
         */
        src: PropTypes.string,
    }),
    /**
     * string of name
     */
    name: PropTypes.string,
    /**
     * enum of open, closed, or secret
     */
    privacySetting: privacySettingPropTypes,
};
