import PropTypes from 'prop-types';
import React from 'react';
import { CAN_SUBMIT, CANT_SUBMIT } from '../../../../contexts/JobReviewContext';
import { EmitMetric } from '../../../Analytics/VisibilityPixel/VisibilityPixel';
import MoreDetailsTextAreaCard from '../../QuestionCards/MoreDetailsTextAreaCard/MoreDetailsTextAreaCard';

import './CommentCard.scss';

export default class CommentCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            comment: props[props.payloadKey],
            parentalLeaveComment: props.parentalLeaveComment,
            recommendComment: props.recommendComment,
            makeBetterComment: props.makeBetterComment,
            flexibilityComment: props.flexibilityComment,
            managerComment: props.managerComment,
            benefitComment: props.benefitComment,
        };
    }

    static propTypes = {
        questionName: PropTypes.string,
        payloadKey: PropTypes.string,
        onAnswer: PropTypes.func,
        placeholder: PropTypes.string,
        errors: PropTypes.string,
        isPartTwo: PropTypes.bool,
        nextStep: PropTypes.string,
        displayQuestionKey: PropTypes.string,
    };

    static defaultProps = {
        questionName: 'comment',
        payloadKey: 'comment',
        isPartTwo: true,
    };

    componentDidMount() {
        EmitMetric({
            misc_event_type: `job-review-question-started-company-${this.props.displayQuestionKey}`,
            misc_event_count: 1,
        });
        const CommentObject = {};
        if (this.props[this.props.payloadKey]) {
            CommentObject[this.props.payloadKey] = this.props[
                this.props.payloadKey
            ];
        }
        this.props[this.props.payloadKey]
            ? this.props.onAnswer(
                  this.props.questionName,
                  CommentObject,
                  CAN_SUBMIT,
                  this.props.isPartTwo,
                  this.props.nextStep
              )
            : null;
    }

    isOnlyWhiteSpace = (comment) => {
        return !!/^\s+$/.test(comment);
    };

    onChange = (comment) => {
        let nextAction = CANT_SUBMIT;
        if (comment && comment.length) {
            nextAction = CAN_SUBMIT;
        }
        const payloadObject = {};

        payloadObject[this.props.payloadKey] = comment;
        this.setState(payloadObject);

        if (this.isOnlyWhiteSpace(comment)) {
            nextAction = CANT_SUBMIT;
        }

        this.props.onAnswer(
            this.props.questionName,
            payloadObject,
            nextAction,
            this.props.isPartTwo,
            this.props.nextStep
        );
    };

    render() {
        const props = {
            placeholder: this.props.placeholder,
            onChange: this.onChange,
            errorMessage: this.props.errors,
            defaultValue: this.state[this.props.payloadKey],
            name: `${this.props.payloadKey}-text`,
        };
        return (
            <div className="component-ReviewSurvey-IndividualQuestionCards-CommentCard">
                <MoreDetailsTextAreaCard
                    {...props}
                    key={this.props.payloadKey}
                />
            </div>
        );
    }
}
