import PropTypes from 'prop-types';
import React from 'react';

import './AriaModal.scss';
import displace from './helpers/displace';
import FocusTrap from './helpers/FocusTrapComponent';
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from './helpers/scrollLock';

class AriaModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.bodyLockTarget = null;

        if (!this.props.titleText && !this.props.titleId) {
            throw new Error(
                'Modal instances should have a `titleText` or `titleId`'
            );
        }
    }

    static propTypes = {
        /**
         * @property {Function} onEnter - called in componentDidMount to perform actions after the modal activates
         */
        onEnter: PropTypes.func,
        /**
         * @property {Function} onExit - required function to handle the state change of exiting (or deactivating) the modal
         */
        onExit: PropTypes.func.isRequired,
        /**
         * @property {string} titleText - string to use as the modal's accessible title, passed to modals aria-label attribute
         */
        titleText: PropTypes.string.isRequired,
        /**
         * @property {HTMLElement} applicationNode - main application node - receives attribute aria-hidden = "true" when modal is active
         */
        applicationNode: PropTypes.node,
        /**
         * @property {Function} getApplicationNode - function to get main application node as an alternative to applicationNode
         */
        getApplicationNode: PropTypes.func,
        /**
         * @property {object} underlayStyle - object of custom props to style underlay inline
         */
        underlayStyle: PropTypes.object,
        /**
         * @property {string} underlayColor - custom underlay color (default rgba(0,0,0,0.5))
         */
        underlayColor: PropTypes.string,
        /**
         * @property {string} dialogId - id of the modal dialog
         */
        dialogId: PropTypes.string,
        /**
         * @property {string} dialogClass - className for custom styling the modal dialog
         */
        dialogClass: PropTypes.string,
        /**
         * @property {boolean} underlayClickExits - deactivate modal on underlay click (default true)
         */
        underlayClickExits: PropTypes.bool,
        /**
         * @property {boolean} escapeExits - deactivate modal on escape keydown (default true)
         */
        escapeExits: PropTypes.bool,
        /**
         * @property {boolean} verticallyCenter - should the modal be vertically and horizontally centered on the underlay (default true)
         */
        verticallyCenter: PropTypes.bool,
        /**
         * @property {boolean} includeDefaultStyles - use default styles (default true)
         */
        includeDefaultStyles: PropTypes.bool,
        /**
         * @property {boolean} scrollDisabled - disable scroll while modal is active (default true)
         */
        scrollDisabled: PropTypes.bool,
        /**
         * @property {boolean} focusDialog - Set initial focus to dialog itself instead of first focusable child (default false)
         */
        focusDialog: PropTypes.bool,
    };

    static defaultProps = {
        underlayProps: {},
        dialogId: 'modal-dialog',
        underlayClickExits: true,
        escapeExits: true,
        underlayColor: 'rgba(0,0,0,0.75)',
        includeDefaultStyles: true,
        focusTrapPaused: false,
        scrollDisabled: true,
        verticallyCenter: true,
    };

    componentDidMount = () => {
        if (this.props.onEnter) {
            this.props.onEnter();
        }

        this.bodyLockTarget = document.getElementById(this.props.dialogId);

        const applicationNode = this.getApplicationNode();
        setTimeout(() => {
            if (applicationNode) {
                applicationNode.setAttribute('aria-hidden', 'true');
            }
        }, 0);

        if (this.props.escapeExits) {
            this.addKeyDownListener();
        }

        if (this.props.scrollDisabled) {
            const bodyLockOpts = {
                reserveScrollBarGap: true,
                allowTouchMove: (el) => {
                    if (
                        el &&
                        el !== document.body &&
                        this.dialogNode.contains(el)
                    )
                        return true;
                    if (el.tagName === 'TEXTAREA') return true;
                    return false;
                },
            };
            disableBodyScroll(this.bodyLockTarget, bodyLockOpts);
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.scrollDisabled && !this.props.scrollDisabled) {
            enableBodyScroll(this.bodyLockTarget);
        } else if (!prevProps.scrollDisabled && this.props.scrollDisabled) {
            const bodyLockOpts = {
                reserveScrollBarGap: true,
                allowTouchMove: (el) => {
                    if (
                        el &&
                        el !== document.body &&
                        this.dialogNode.contains(el)
                    )
                        return true;
                    if (el.tagName === 'TEXTAREA') return true;
                    return false;
                },
            };
            disableBodyScroll(this.bodyLockTarget, bodyLockOpts);
        }

        if (this.props.escapeExits && !prevProps.escapeExits) {
            this.addKeyDownListener();
        } else if (!this.props.escapeExits && prevProps.escapeExits) {
            this.removeKeyDownListener();
        }
    };

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
        const applicationNode = this.getApplicationNode();

        if (applicationNode) {
            applicationNode.setAttribute('aria-hidden', 'false');
        }

        this.removeKeyDownListener();
    };

    addKeyDownListener = () => {
        setTimeout(() => {
            document.addEventListener('keydown', this.checkDocumentKeyDown);
        });
    };

    removeKeyDownListener = () => {
        setTimeout(() => {
            document.removeEventListener('keydown', this.checkDocumentKeyDown);
        });
    };

    getApplicationNode = () => {
        if (this.props.getApplicationNode)
            return this.props.getApplicationNode();
        return this.props.applicationNode;
    };

    checkUnderlayClick = (event) => {
        const modalClicked =
            this.dialogNode && this.dialogNode.contains(event.target);
        // ignore click if modal or scrollbar is clicked
        if (
            modalClicked ||
            event.pageX > event.target.ownerDocument.documentElement.offsetWidth
        )
            return;

        this.deactivateModal(event);
    };

    checkDocumentKeyDown = (event) => {
        if (
            this.props.escapeExits &&
            (event.key === 'Escape' ||
                event.key === 'Esc' ||
                event.keyCode === 27)
        ) {
            this.deactivateModal(event);
        }
    };

    deactivateModal = (event) => {
        if (this.props.onExit) {
            this.props.onExit(event);
        }
    };

    render() {
        const props = this.props;

        let style = {};

        if (props.includeDefaultStyles) {
            style = {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1050,
                overflowX: 'hidden',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                textAlign: 'center',
            };

            if (props.underlayColor) {
                style.background = props.underlayColor;
            }

            if (props.underlayClickExits) {
                style.cursor = 'pointer';
            }
        }

        if (props.underlayStyle) {
            for (const key in props.underlayStyle) {
                if (!props.underlayStyle.hasOwnProperty(key)) continue;
                style[key] = props.underlayStyle[key];
            }
        }

        const underlayProps = {
            className: props.underlayClass,
            style: style,
        };

        if (props.underlayClickExits) {
            underlayProps.onMouseDown = this.checkUnderlayClick;
        }

        for (const prop in this.props.underlayProps) {
            underlayProps[prop] = this.props.underlayProps[prop];
        }

        let verticalCenterStyle = {};
        if (props.includeDefaultStyles) {
            verticalCenterStyle = {
                display: 'inline-block',
                height: '100%',
                verticalAlign: 'middle',
            };
        }

        const verticalCenterHelperProps = {
            key: 'a',
            style: verticalCenterStyle,
        };

        let dialogStyle = {};
        if (props.includeDefaultStyles) {
            dialogStyle = {
                display: 'inline-block',
                textAlign: 'left',
                top: 0,
                maxWidth: '100%',
                cursor: 'default',
                outline: props.focusDialog ? 0 : null,
            };

            if (props.verticallyCenter) {
                dialogStyle.verticalAlign = 'middle';
                dialogStyle.top = 0;
            }
        }

        if (props.dialogStyle) {
            for (const key in props.dialogStyle) {
                if (!props.dialogStyle.hasOwnProperty(key)) continue;
                dialogStyle[key] = props.dialogStyle[key];
            }
        }

        const dialogProps = {
            key: 'b',
            ref: (el) => {
                this.dialogNode = el;
            },
            role: 'dialog',
            id: props.dialogId,
            className: props.dialogClass,
            style: dialogStyle,
        };

        if (props.titleId) {
            dialogProps['aria-labelledby'] = props.titleId;
        } else if (props.titleText) {
            dialogProps['aria-label'] = props.titleText;
        }

        if (props.focusDialog) {
            dialogProps.tabIndex = '-1';
        }

        for (let key in props) {
            if (/^(data-|aria-)/.test(key)) {
                dialogProps[key] = props[key];
            }
        }

        const childrenArray = [
            React.createElement('div', dialogProps, props.children),
        ];

        if (props.verticallyCenter) {
            childrenArray.unshift(
                React.createElement('div', verticalCenterHelperProps)
            );
        }

        const focusTrapOptions = props.focusTrapOptions || {};

        if (props.focusDialog || props.initialFocus) {
            focusTrapOptions.initialFocus = props.focusDialog
                ? `#${this.props.dialogId}`
                : props.initialFocus;
        }

        focusTrapOptions.escapeDeactivates = props.escapeExits;

        return React.createElement(
            FocusTrap,
            { focusTrapOptions, paused: props.focusTrapPaused },
            React.createElement('div', underlayProps, childrenArray)
        );
    }
}

const Modal = displace(AriaModal);

Modal.renderTo = (input) => displace(AriaModal, { renderTo: input });

export default Modal;
