export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;

export const rangeInclusive = (start, stop, step = 1) => {
    if (stop == null) {
        stop = start || 0;
        start = 0;
    }

    const length = Math.max(Math.ceil((stop - start) / step), 0);
    const range = new Array(length);

    for (let idx = 0; idx <= length; idx++, start += step) {
        range[idx] = start;
    }

    return range;
};
