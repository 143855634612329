import PropTypes from 'prop-types';
import React from 'react';
import { IMAGE_CDN_URL } from '../../../../../utilities/imageCDN';
import './UserImage.scss';
import ResponsivePicture from '../../../../Elements/ResponsivePicture/ResponsivePicture';
import SVGFromCloudinary from '../../../../Elements/SVGFromCloudinary/SVGFromCloudinary';

export const defaultReplaceSlash = (url) => {
    let formattedUrl = url;
    if (url.charAt(0) !== '/') {
        formattedUrl = `/${url}`;
    }
    return formattedUrl;
};

export const buildImagePath = (image, size, injectedTransforms) => {
    const regex = /\/?v[0-9]*\//;
    const defaultTransforms = {
        xSmall: 'c_scale,g_center,r_max,w_22,h_22,z_0.6',
        size24: 'c_scale,g_center,r_max,w_24,h_24,z_0.6',
        small: 'c_scale,g_center,r_max,w_36,h_36,z_0.6',
        size40: 'c_scale,g_center,r_max,w_40,h_40,z_0.6',
        medium: 'c_scale,g_center,r_max,w_48,h_48,z_0.6',
        size56: 'c_scale,g_center,r_max,w_56,h_56,z_0.6',
        56: 'c_scale,g_center,r_max,w_56,h_56,z_0.6',
        size72: 'c_scale,g_center,r_max,w_72,h_72,z_0.6',
        large: 'c_scale,g_center,r_max,w_74,h_74,z_0.6',
        size84: 'c_scale,g_center,r_max,w_84,h_84,z_0.6,q_84',
        xLarge: 'c_scale,g_center,r_max,w_90,h_90,z_0.6',
        xxLarge: 'c_scale,g_center,r_max,w_106,h_106,z_0.6',
        size150: 'c_scale,g_center,r_max,w_150,h_100,z_0.6',
        size136: 'c_scale,g_center,r_max,w_115,h_136,z_0.6',
        size160: 'c_scale,g_center,r_max,w_160,h_160,z_0.6',
    };

    let formattedUrl = image.replace(regex, '/');
    formattedUrl = defaultReplaceSlash(formattedUrl);
    formattedUrl = `/v1${formattedUrl}`;
    const transforms = injectedTransforms || defaultTransforms;
    const userImagePath = `${IMAGE_CDN_URL}/${transforms[size]}${formattedUrl}`;
    return userImagePath;
};

export default class UserImage extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            iconSize:
                typeof props.iconSize === 'number'
                    ? props.iconSize
                    : parseInt(props.iconSize, 10),
        };
    }

    getBreakPointsFromProps = () => {
        return {
            mobile: {
                width: this.state.iconSize,
                height: this.state.iconSize,
                transforms: {
                    crop: 'scale',
                    gravity: 'center',
                    radius: 'max',
                },
            },
        };
    };

    getDefaultWingSizes = () => {
        switch (this.state.iconSize) {
            case 22:
                return 8;
            case 56:
            case 72:
                return 16;
            case 74:
            case 84:
                return 20;
            case 90:
                return 25;
            case 160:
                return 40;
            default:
                return 12;
        }
    };

    render() {
        const wingSize = this.props.wingSize || this.getDefaultWingSizes();
        const wingInjectedTransforms = `/c_scale,g_center,r_max,w_${wingSize},h_${wingSize}`;

        return (
            <div
                style={{
                    width: this.state.iconSize,
                    height: this.state.iconSize,
                }}
                ref={this.props.setNodeRef}
                className={`component-Content-User-UserDisplay-UserImage ${this.props.className}`}
            >
                <ResponsivePicture
                    className="userImage"
                    forceImageSize
                    cloudinaryID={this.props.image.src}
                    breakpoints={this.getBreakPointsFromProps()}
                />
                {this.props.isVip ? (
                    <div
                        className="vipWing"
                        style={{
                            width: this.getDefaultWingSizes(),
                            height: this.getDefaultWingSizes(),
                        }}
                    >
                        <SVGFromCloudinary
                            cloudinaryID="/v1/SVGs/VIP_LARGE.svg"
                            transformString={wingInjectedTransforms}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

UserImage.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    image: PropTypes.shape({
        /**
         * Image Source (Cloudinary Id)
         * */
        src: PropTypes.string,
        /**
         * Image Alt
         * */
        alt: PropTypes.string,
    }),
    iconSize: PropTypes.number,
    wingSize: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * whether user is vip
     * */
    isVip: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * for setting reference return from ref attribute
     */
    setNodeRef: PropTypes.func,
};

UserImage.defaultProps = {
    iconSize: 36,
    image: {
        src: '/v1/avatar_black_160_vtg7lw.svg',
        alt: 'user profile image',
    },
    wingSize: false,
    className: false,
    isVip: false,
    setNodeRef: () => {},
};
