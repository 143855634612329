import axios from 'axios';
import { SINGLE_EXPERIENCE_STEP_NAME } from '../Steps/constants';
import { buildInitialFormState } from '../../../../contexts/Includes/modalContextHelpers';
import { validateSingleExperiencePayload } from '../../../Includes/FormSchema/ExperienceSchema';

const flowTree = {
    [SINGLE_EXPERIENCE_STEP_NAME]: [],
};

export const buildDefaultSingleExperienceState = (data = {}) =>
    buildInitialFormState(['singleExperience'], data);

export const buildSingleExperienceFlow = (initializeOptions = {}) => {
    const validators = {
        // singleExperienceValidator: (payload) => console.log('VALIDATE PAYLOAD: ', payload)
        singleExperienceValidator: validateSingleExperiencePayload,
    };

    return {
        flowTree,
        initialStep: SINGLE_EXPERIENCE_STEP_NAME,
        initialFlowData: {
            ...initializeOptions,
            ...initializeOptions,
            validators,
        },
    };
};

const filterListById = (id, list) =>
    list.filter((item) => item.experienceId !== id);

const apiEndpoint = '/api/user/profile';

const handleApiRequest = async (payload) => {
    try {
        const response = await axios.patch(apiEndpoint, {
            experiences: payload,
        });

        const { meta, errors, data } = response.data;

        const { experiences } = data;

        const updatedExperienceIds = experiences.map((idObj) => idObj.id);

        if (!meta.success) {
            return {
                ok: false,
                updatedIds: null,
                apiErrors: errors,
            };
        }

        return {
            ok: true,
            updatedIds: updatedExperienceIds, // ['123', '456', '789'];
            apiErrors: null,
        };
    } catch (error) {
        return {
            ok: false,
            updatedIds: null,
            apiErrors: error,
        };
    }
};

export const getExperienceId = (experience) => {
    if (experience.experienceId) return experience.experienceId;
    if (experience.id) return experience.id;
    return null;
};

export const experienceUpdateApi = {
    addSingleExperience: async (experience, currentExperiences = []) => {
        // If we're editing one experience, find the index by id and replace it. Otherwise add it to the current experiences
        const editIndex = currentExperiences.findIndex(
            (exp) => exp.experienceId === experience.experienceId
        );

        const addingNewExperience = editIndex === -1;

        const newExperiencePayload = addingNewExperience
            ? [...currentExperiences, experience] // adding new - add to users current list
            : currentExperiences.map((exp, i) =>
                  i === editIndex ? experience : exp
              ); // editing - replace edu in users list with our current edu

        const { ok, updatedIds, apiErrors } = await handleApiRequest(
            newExperiencePayload
        );

        if (ok && updatedIds) {
            if (addingNewExperience) {
                // need to set the null id for the experience we just created with the new id from the response ids
                const currentIds = newExperiencePayload.reduce((acc, item) => {
                    const entityId = getExperienceId(item);
                    if (entityId) return [...acc, entityId];
                    return [...acc];
                }, []);

                const updateId = updatedIds.filter(
                    (id) => !currentIds.includes(id)
                )[0];
                const newExperiences = newExperiencePayload.map((e) => {
                    if (e.id === null)
                        return { ...e, id: updateId, experienceId: updateId }; // our new experience without an id gets the updateId
                    const entityId = getExperienceId(e);
                    return { ...e, id: entityId, experienceId: entityId }; // set all ids and experienceIds to maintain consistency
                });

                return { ok: true, newExperiences };
            }

            // if we're just editing, we don't need to add any new ids and can use the success payload
            // don't trust the id/experienceId thing elsewhere so it's being set here again for consistency
            const editedExperiences = newExperiencePayload.map((item) => {
                const entityId = getExperienceId(item);
                return { ...item, id: entityId, experienceId: entityId };
            });

            return { ok: true, newExperiences: editedExperiences };
        }

        return { ok: false, apiErrors };
    },
    deleteSingleExperience: async (experienceId, currentExperiences = []) => {
        const newExperiencePayload = filterListById(
            experienceId,
            currentExperiences
        );

        const { ok, updatedIds, apiErrors } = await handleApiRequest(
            newExperiencePayload
        );

        if (ok && updatedIds)
            return { ok: true, newExperiences: newExperiencePayload };

        return { ok: false, errors: apiErrors };
    },
};
