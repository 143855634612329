const ampmCasing = {
    lowercase: {
        true: 'pm',
        false: 'am',
    },
    uppercase: {
        true: 'PM',
        false: 'AM',
    },
};

/**
 * @deprecated
 */
export const formatHourInAMPMFormat = (ISOstring, ampmCase = 'lowercase') => {
    const dateFormatted = new Date(ISOstring);
    const hoursFromDate = dateFormatted.getHours();
    const minutesFromDate = dateFormatted.getMinutes();
    const ampm = ampmCasing[ampmCase][hoursFromDate >= 12];
    const standardHoursFormat = hoursFromDate % 12;
    const minutes =
        minutesFromDate < 10 ? `0${minutesFromDate}` : minutesFromDate;
    const hour = standardHoursFormat || 12;
    return minutes === '00' ? `${hour}${ampm}` : `${hour}:${minutes}${ampm}`;
};

export const formatMinuteFromISO = () => {};

const monthsArray = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
];

export const getMonthByNumber = (number) => {
    return monthsArray[number - 1];
};

export const getMonthFromISO = (ISOstring) => {
    const dateFormatted = new Date(ISOstring);
    const month = dateFormatted.getMonth();
    return monthsArray[month];
};

export const getDateFromISO = (ISOstring) => {
    const dateFormatted = new Date(ISOstring);
    return dateFormatted.getDate();
};

export const getNumerizedMonthFromISO = (ISOstring) => {
    const dateFormatted = new Date(ISOstring);
    const month = dateFormatted.getMonth() + 1;

    return month;
};

export const getDayOfWeekFullNameFromISO = (ISOstring) => {
    const daysArray = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    const dateFormatted = new Date(ISOstring);
    const dateNumber = dateFormatted.getDay();
    return daysArray[dateNumber];
};

export const getUserLocalTimeZone = () => {
    const d = new Date();
    const timezone = d
        .toLocaleString('en', { timeZoneName: 'short' })
        .split(' ')
        .pop();

    // in ie11, toLocaleString doesn't return timezone
    // in windows safari 5, the time gets returned
    // so AM or PM or HH:MM:SS gets popped - return empty string
    const isAmPm = timezone === 'PM' || timezone === 'AM';
    const isTime = timezone.indexOf(':') !== -1;

    if (isAmPm || isTime) {
        return '';
    }

    return timezone;
};

export const getFourDigetYearFromISO = (ISOstring) => {
    const d = new Date(ISOstring);
    return d.getFullYear();
};

export const extendTimeToTwoDigit = (time) => {
    if (time < 10) {
        return `0${time}`;
    }

    return time;
};

export const getMMDDYYTimestamp = (dateTime, yearLength = 2) => {
    const month = extendTimeToTwoDigit(getNumerizedMonthFromISO(dateTime));
    const date = extendTimeToTwoDigit(getDateFromISO(dateTime));
    const year = getFourDigetYearFromISO(dateTime)
        .toString()
        .substr(yearLength);

    return `${month}/${date}/${year}`;
};

export const getFormattatedDayTimestamp = (dateTime) => {
    const date = new Date(dateTime);
    const month = date.toLocaleString('default', {
        month: 'long',
    });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = formatHourInAMPMFormat(dateTime, 'uppercase');
    const timezone = getUserLocalTimeZone();

    return `${month} ${day},${year} at ${time} ${timezone}`;
};
export const getFormattedTimestamp = (dateTime) => {
    const date = getMMDDYYTimestamp(dateTime);
    const time = formatHourInAMPMFormat(dateTime, 'uppercase');
    const timezone = getUserLocalTimeZone();

    return `${date} at ${time} ${timezone}`;
};

export const generateYearsArray = (startYearRange = 60, endYearRange = 0) => {
    const years = [];
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - startYearRange;
    const endYear = currentYear + endYearRange;
    // eslint-disable-next-line no-plusplus
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }
    return years;
};
